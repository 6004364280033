<div class="qs_container">
    <div class="qs_spinner qs_icon" *ngIf="configurationStep !== 'done' && configurationStep !== 'error'" [inlineSVG]="'assets/images/refresh_fill.svg'"></div>
    <div class="qs_icon" *ngIf="configurationStep === 'done'" [inlineSVG]="'assets/images/success.svg'"></div>
    <div class="qs_icon" *ngIf="configurationStep === 'error'" [inlineSVG]="'assets/images/delete.svg'"></div>
    <div class="qs_mainText">
        <div class="separator32"></div>
        <div *ngIf="configurationStep !== 'done'">{{trans('configurators.statuses.writing')}}</div>
        <div *ngIf="configurationStep === 'done'">{{trans('general.titleSuccess')}}</div>
    </div>
    <div class="qs_statusText" *ngIf="configurationStep !== 'done'">
        <div class="separator16"></div>
        <div *ngIf="configurationStep === 'connect'">{{trans('systems.statuses.connecting')}}</div>
        <div *ngIf="configurationStep === 'read'">{{trans('configurators.statuses.preparing')}}</div>
        <div *ngIf="configurationStep === 'write'">{{trans('general.writing')}}</div>
        <div *ngIf="configurationStep === 'find'">{{trans('configurators.statuses.restarting')}}</div>
        <div *ngIf="configurationStep === 'error'">{{trans('general.failed')}}</div>
    </div>
    <div class="qs_advanced" *ngIf="configurationStep === 'done'">
        <app-button type="filled-secondary" *ngIf="us.currentUser?.permissions.permissions.sys_advanced_settings.view && shouldShowAdvancedSettings" (click)="openAdvancedConfig()">{{trans('systems.buttons.startProgramming')}}</app-button>
        <div class="separator16"></div>
        <app-button type="filled-secondary" *ngIf="us.currentUser?.permissions.permissions.system.create || us.currentUser?.permissions.permissions.systems.create" (click)="addAsSystem()">{{trans('configurators.buttons.addSystem')}}</app-button>
    </div>
</div>
