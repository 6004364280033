import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { NewSystemService } from 'src/app/services/new-system.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-system-location',
  templateUrl: './system-location.component.html',
  styleUrls: ['./system-location.component.scss'],
})
export class SystemLocationComponent extends LanguageAware implements OnInit {
  public systemAddress = '';
  private workingWithActiveSystem = false;
  public homeMarker = null;
  public startUpLat = 0.0;
  public startUpLon = 0.0;
  private userEditedAddress = false;

  constructor(cdRef: ChangeDetectorRef, private ns: NewSystemService, private router: Router, private http: HttpClient) {
    super(cdRef);
    this.tag = 'Location';
    this.workingWithActiveSystem = this.ns.getUID() === '';
    if (this.workingWithActiveSystem) {
      this.headerBar.showHeader({
        headerText: this.trans('systems.titles.systemLocation'),
        backUrl: '/system-information',
      });
    } else {
      this.headerBar.showHeader({
        headerText: this.trans('systems.titles.systemLocation'),
        backUrl: '/add-system-details',
      });
    }
    this.footerBar.showFooter(this.trans('users.buttons.confirm'), '', true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onConfirm();
    };
    this.background.setGray();
    if (this.workingWithActiveSystem) {
      this.systemAddress = this.systems.activeSystem.address;
    } else {
      this.systemAddress = this.ns.getAddress();
    }
    if (this.systemAddress !== '') {
      this.userEditedAddress = true; // Jeigu jau buvo adresas, tai traktuojam, kad vartotojas jį įvedė
    }

    if ((this.workingWithActiveSystem && this.systems.activeSystem.coordinates !== '') || (!this.workingWithActiveSystem && this.ns.hasCoordinates())) {
      this.setMarkerFromString();
    } else {
      this.centerMapFromDeviceLocation();
    }
  }

  ngOnInit() {}

  public onConfirm() {
    this.log('Saugom pasirinkimą. ', this.homeMarker);
    if (this.workingWithActiveSystem) {
      this.systems.activeSystem.address = this.systemAddress;
      if (this.homeMarker !== null) {
        this.systems.activeSystem.coordinates = this.homeMarker.lat.toString() + ' ' + this.homeMarker.lon.toString();
      } else {
        this.systems.activeSystem.coordinates = '';
      }
      this.log('naujas adresas nustatytas: ', this.systems.activeSystem.address);
      this.router.navigate(['/system-information']);
    } else {
      this.ns.setAddress(this.systemAddress);
      if (this.homeMarker !== null) {
        this.ns.setCoordinates(this.homeMarker.lat, this.homeMarker.lon);
      }
      this.router.navigate(['/add-system-details']);
    }
  }

  private getCurrentLocation() {
    // navigator.geolocation.getCurrentPosition(
    //   (data: Position) => {
    //     this.log('Kazka gavom', { lat: data.coords.latitude, lon: data.coords.longitude });
    //     this.startUpLat = data.coords.latitude;
    //     this.startUpLon = data.coords.longitude;
    //   },
    //   (err: PositionError) => {
    //     this.log('Klaida', {
    //       code: err.code,
    //       msg: err.message,
    //       perm: err.PERMISSION_DENIED,
    //       nopos: err.POSITION_UNAVAILABLE,
    //       tm: err.TIMEOUT
    //     });
    //   }
    // );
  }

  private setMarkerFromString() {
    this.log('Sistema turi koordinates, tai reikia pagal jas paišyt markerį');
    const parts = this.workingWithActiveSystem ? this.systems.activeSystem.coordinates.split(' ') : this.ns.getCoordinates().split(' ');
    if (parts.length !== 2) {
      this.centerMapFromDeviceLocation();
      return;
    }

    this.startUpLat = Number.parseFloat(parts[0]);
    this.startUpLon = Number.parseFloat(parts[1]);
    this.homeMarker = {
      lat: this.startUpLat,
      lon: this.startUpLon,
    };
  }

  private centerMapFromDeviceLocation() {
    this.log('Sistema neturi koordinačių. Bandom nustatyt pagal įrenginį.');

    window.phoneStateJsi = {
      locationPermissionGranted: () => {
        this.getCurrentLocation();
      },
    };

    if (this.platform.isAndroid()) {
      this.platform.androidHandler().getLocationPermission();
    } else {
      this.getCurrentLocation();
    }
  }

  public onAddressChanged(newAddress: string) {
    this.systemAddress = newAddress;
    this.userEditedAddress = newAddress !== '';
    this.log('Vartotojas ivede naują adresą ' + newAddress);
  }
}
