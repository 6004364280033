import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SystemData } from 'src/api/v3/common';

/**
 * `version-update`
 */
export interface RtcMessageVersionUpdate {
  major: number;
  minor: number;
  build: number;
  date: number;
}

/**
 * `events-channel`
 */
export interface RtcMessageEvent {
  system_id: number;
  event_id: number;
  company_id: number;
}

/**
 * `auto-reload-channel`
 */
export interface RtcMessageAreaStatus {
  /** Area id */
  id: number;
  alarm_in_memory: boolean;
  name: string;
  queue_no: number;
  status: number;
  status_time: number;
  system_id: number;
  last_person_name: string;
  alarm_time: number;
  alarm_type: number;
}

/**
 * Sent only when `on` or `enabled` fields have changed.
 */
interface RtcMessagePgm1 {
  system_id: number;
  id: number;
  queue_no: number;
  enabled: 0 | 1;
  on: 0 | 1;
  icon_number: number;
}

/**
 * Sent only when `pulse_start_time` fields changed
 */
interface RtcMessagePgm2 {
  system_id: number;
  id: number;
  pulse_start_time: string | number;
}

/**
 * `pgm-channel`
 */
export type RtcMessagePgm = RtcMessagePgm1 | RtcMessagePgm2;

interface RtcMessageUserSystem1 {
  id: number;
  user_id: number;
  system_id: number;
  rights: number;
  access: number;
}

interface RtcMessageUserSystem2 {
  user_id: number;
  system_id: number;
  system_data: SystemData;
}

interface RtcMessageUserSystem3 {
  user_id: number;
  system_id: number;
  system_reread: true;
}

/**
 * `user-system:{userId}`
 */
export type RtcMessageUserSystem = RtcMessageUserSystem1 | RtcMessageUserSystem2 | RtcMessageUserSystem3;

/**
 * `system-status-reload`
 */
export interface RtcMessageSystemStatusReload {
  system_id: number;
  system_status: string;
  signal_level: number;
  company_id: number;
}

/**
 * `configuration-event:{userId}`
 */
export interface RtcMessageConfigurationEvent {
  user_id: number;
  /** IMEI */
  uid: string;
  cur: number;
  max: number;
}

export interface RtcMessageUserAccessControl {
  system_id: number;
  present: boolean;
  /** Module user ID */
  user_id: number;
  protegus_user_id: number;
}

@Injectable()
export abstract class RtcService {
  public readonly systemStatusChange: Observable<RtcMessageSystemStatusReload>;
  public readonly versionUpdate: Observable<RtcMessageVersionUpdate>;
  public readonly events: Observable<RtcMessageEvent>;
  public readonly areaStatus: Observable<RtcMessageAreaStatus>;
  public readonly pgm: Observable<RtcMessagePgm>;
  public readonly userSystem: Observable<RtcMessageUserSystem>;
  public readonly configurationEvent: Observable<RtcMessageConfigurationEvent>;
  public readonly userAccessControl: Observable<RtcMessageUserAccessControl>;

  public abstract updateIntrests(systemIds: number[]): void;
  public abstract connect(): void;
  public abstract close(): void;

  constructor() {}
}
