import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import requests from 'src/api/v3/requests';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.scss'],
})
export class AdvancedSettingsComponent extends LanguageAware implements OnInit {
  public doNotShow = false;
  public shouldShowContent = true;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    const ref = localStorage.getItem('ref');
    this.headerBar.showHeader({
      backUrl: ref === 'conf' ? '../system-configuration' : '*',
      headerText: this.trans('systems.menu.advancedSettings'),
      activatedRoute: this.r,
    });
    this.background.setGray();
    const savedDoNowShow = localStorage.getItem('do_not_show_adv_' + this.us.currentUser.id + '_' + this.systems.activeSystem.id);
    this.shouldShowContent = savedDoNowShow === null;
    this.doNotShow = savedDoNowShow !== null;
    if (!this.shouldShowContent) {
      this.openConfigurator();
    }
  }

  ngOnInit(): void {}

  public openConfigurator() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    if (this.doNotShow) {
      localStorage.setItem('do_not_show_adv_' + this.us.currentUser.id + '_' + this.systems.activeSystem.id, '1');
    }
    requests.config.info({
      systemUid: this.systems.activeSystem.imei,
      mpass: this.systems.activeSystem.mpass,
    }, false).subscribe(
      (result) => {
        if (result.success) {
          // !!! pridedant naujus modulius, juos reikia prideti ir i systems.service prie supports.advancedSettings
          if (result.data.hwId === '3B') {
            // SP3
            this.g.configurableDevice = result.data;
            this.g.configurableDevice.mpass = this.systems.activeSystem.mpass;
            this.performLanguageUpdate('sp3');
          } else if (result.data.hwId === '24' || result.data.hwId === '37') {
            this.g.configurableDevice = result.data;
            this.g.configurableDevice.mpass = this.systems.activeSystem.mpass;
            this.performLanguageUpdate('gv17');
          } else if (['3A', '3C', '42', '43', '44', '45', '46', '47', '48'].includes(result.data.hwId)) {
            this.g.configurableDevice = result.data;
            this.g.configurableDevice.mpass = this.systems.activeSystem.mpass;
            this.performLanguageUpdate('g16');
          } else if (result.data.hwId === '51') {
            // FC
            this.g.configurableDevice = result.data;
            this.g.configurableDevice.mpass = this.systems.activeSystem.mpass;
            this.performLanguageUpdate('fc');
          } else {
            this.toaster.postError(this.trans('systems.titles.notSupported'));
          }
        } else {
          this.toaster.postError(result.error);
        }
        this.miniStatus.hide();
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }

  private performLanguageUpdate(device: 'sp3' | 'g16' | 'gv17' | 'fc') {
    const currentVersion = localStorage.getItem('lang_version');
    const currentVersionDev = localStorage.getItem('lang_version_' + device);
    if (currentVersion === currentVersionDev) {
      this.log(device + ' Config vertimu naujinti nereikia.');
      this.router.navigate(['/configure/' + device]);
      return;
    }
    this.log('Naujinam ' + device + ' vertimus...');
    const currentLanguage = localStorage.getItem('lang');
    this.api.post('/translations', {
      language: currentLanguage === null ? '' : currentLanguage,
      config: device,
      version: currentVersionDev,
    }, true).subscribe(
      (result) => {
        if (result.success) {
          this.ls.applyTranslations(result.translations, result.version, result.code);
          localStorage.setItem('lang_version_' + device, currentVersion);
          this.router.navigate(['/configure/' + device]);
        } else {
          this.log('Nepavyko atnaujinti vertimu. ' + result.error);
          this.router.navigate(['/configure/' + device]);
        }
      },
      (error) => {
        this.log('Nepavyko atnaujinti vertimu. Vidine serverio klaida.');
        this.router.navigate(['/configure/' + device]);
      }
    );
  }
}
