export enum ExpanderType {
  None = 0x00,
  iO = 0x1e,
  iO_WL = 0x1f,
  TM17 = 0x23,
  iO_MO = 0x25,
  iO_LO = 0x26,
  iO8 = 0x29,
  RF_SH = 0x2d,
  E485 = 0x36,
  T16 = 0x2a,
  SF485 = 0x34,
  iO8_LORA = 0x4a,
  RF_HW = 0x4b,
  PB_LORA = 0x4c,
  REL_LORA = 0x4d,
  RF_LORA = 0x28,
}
