import { Component, OnInit } from '@angular/core';
import { SystemsService } from 'src/app/services/systems.service';

@Component({
  selector: 'app-active-system',
  templateUrl: './active-system.component.html',
  styleUrls: ['./active-system.component.scss']
})
export class ActiveSystemComponent implements OnInit {

  constructor(public systems: SystemsService) { }

  ngOnInit(): void {
  }

}
