import { ChangeDetectorRef, Component, HostListener, Injector, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Chip, PermissionRule } from 'src/api/v3/common';
import { AuthService } from 'src/app/api/auth.service';
import { PermissionService } from 'src/app/api/permission.service';
import { UserService } from 'src/app/api/user.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { UResolvable } from 'src/app/guards/UResolvable';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';
import { DataTableGetter } from '../../components/data-table/data-table.component';
import { FilterCriteria } from '../company-users/company-users.component';

@Component({
  selector: 'app-permission-list-desktop',
  templateUrl: './permission-list-desktop.component.html',
  styleUrls: ['./permission-list-desktop.component.scss']
})
export class PermissionListDesktopComponent extends LanguageAware implements OnInit, UResolvable<typeof PermissionListDesktopComponent> {
  public canAdd = this.us.currentUser.permissions?.permissions.roles.create && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
  public canDelete = this.us.currentUser.permissions?.permissions.roles.delete && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
  public filterCriteria: FilterCriteria = { searchPhrase: '', searchFields: [], currentPage: 0, lastPage: 0 };
  filterChips: Chip[];
  public searchVisible = false;
  public isSearchLoading = false;
  private isManuallySubmitted = false;
  private typingTimer = null;
  private doneTypingInterval = 500;
  private resultsFiltered = false;
  public columns = this.persistenceService.bind('permission_list_columns', [
    'fake:roleIcon',
    'name',
    'role',
    'description',
    this.us.currentUser.company_id === 0 ? 'company' : null,
    'tags',
    this.canDelete ? 'fake:delete' : null
  ]);
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('permissions.labels.roleIcon'), value: 'fake:roleIcon' },
    { name: this.trans('permissions.labels.name'), value: 'name' },
    { name: this.trans('permissions.labels.role'), value: 'role' },
    { name: this.trans('permissions.labels.description'), value: 'description' },
    this.us.currentUser.company_id === 0 ? { name: this.trans('users.labels.company'), value: 'company' } : null,
    { name: this.trans('permissions.labels.tags'), value: 'tags' },
    { name: this.trans('permissions.labels.default'), value: 'default' },
    this.canDelete ? { name: this.trans('general.delete'), value: 'fake:delete' } : null,
  ];
  loader: DataTableGetter<PermissionRule>;

  constructor(
    cdRef: ChangeDetectorRef
  ) {
    super(cdRef);
    this.loader = this.permissionService.getPermissionsGetter(null);
    this.filterChips = [
      { name: 'name', label: this.trans('permissions.labels.name'), value: false, modifiableColumn: true },
      { name: 'company', label: this.trans('users.labels.company'), value: false, modifiableColumn: true },
      { name: 'role', label: this.trans('permissions.labels.role'), value: false, modifiableColumn: true },
      { name: 'description', label: this.trans('permissions.labels.description'), value: false, modifiableColumn: true },
      { name: 'tags', label: this.trans('permissions.labels.tags'), value: false, modifiableColumn: true },
      { name: 'default', label: this.trans('permissions.labels.default'), value: false, modifiableColumn: false },
    ];
  }

  ngOnInit(): void {
  }

  private resetFilterCriteria(): void {
    this.filterCriteria = {
      searchPhrase: '',
      searchFields: [],
      currentPage: 0,
      lastPage: 0
    };
    this.filterChips.map(chip => chip.value = false);
  }

  public hideSearch(): void {
    if(!this.searchVisible) { return; }
    this.searchVisible = false;
    this.isSearchLoading = false;
    this.resetFilterCriteria();
    if(this.resultsFiltered) {
      this.loader = this.permissionService.getPermissionsGetter(null);
      this.resultsFiltered = false;
    };
    const searchInputField = document.getElementById('searchInputField');
    searchInputField.blur();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.hideSearch();
  }

  public showSearch(): void {
    this.searchVisible = true;
  }

  public onChipPress(event: any, chip: Chip): void {
    if(this.filterCriteria.searchFields.includes(chip.name) && !chip.value) {
      const index = this.filterCriteria.searchFields.indexOf(chip.name);
      this.filterCriteria.searchFields.splice(index, 1);
    } else if (!this.filterCriteria.searchFields.includes(chip.name) && chip.value) {
      this.filterCriteria.searchFields.push(chip.name);
    }
    this.onSearchSubmit(event);
  };

  public onSearchSubmit(event: any): void {
    clearTimeout(this.typingTimer);
    if(event.key === 'Enter' || event.type === 'click') {
      this.isManuallySubmitted = true;
      this.initializeSearch();
    } else {
      this.isManuallySubmitted = false;
      this.typingTimer = setTimeout(() => {
        this.initializeSearch();
      }, this.doneTypingInterval);
    }
  }

  private async initializeSearch(): Promise<void> {
    this.resetPagesCount();
    if(this.filterCriteria.searchPhrase.length >= (this.isManuallySubmitted ? 2 : 3)) {
      this.isSearchLoading = true;
      await this.setFilteredPermissionRules().then(() => {
        this.resultsFiltered = true;
        this.isSearchLoading = false;
      });
    } else if ( this.filterCriteria.searchPhrase.length === 0 && this.filterCriteria.searchFields.includes('default') ) {
      await this.setFilteredPermissionRules().then(() => {
        this.resultsFiltered = true;
        this.isSearchLoading = false;
      });
    } else if (this.resultsFiltered && this.filterCriteria.searchPhrase.length === 0) {
      this.loader = this.permissionService.getPermissionsGetter(null);
      this.resultsFiltered = false;
    }
  }

  private setFilteredPermissionRules(): Promise<void> {
    this.filterActiveSearchFields();
    return new Promise((resolve) => {
      this.loader = this.permissionService.getPermissionsGetter(this.filterCriteria);
      resolve();
    });
  }

  private filterActiveSearchFields(): void {
    this.filterCriteria.searchFields.map(searchFieldName => {
      if (!this.columns.value.includes(searchFieldName) && searchFieldName !== 'default') {
        const index = this.filterCriteria.searchFields.indexOf(searchFieldName);
        this.filterCriteria.searchFields.splice(index, 1);
        const inactiveChip = this.filterChips.find(chip => chip.name === searchFieldName);
        inactiveChip.value = false;
      }
    });
  }

  private resetPagesCount(): void {
    this.filterCriteria.currentPage = -1;
    this.filterCriteria.lastPage = 0;
  }

  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot, param?: string): Promise<void> {
    const user = injector.get(UserService);
    if (!user.currentUser) {
      const auth = injector.get(AuthService);
      await auth.loadUserData();
    }
    const perm = injector.get(PermissionService);
    if ( perm.permissionsRules.size === 0 ) {
      await perm.getAllPermissions();
    }
    const ruleId = parseInt(param, 10);
    if (ruleId && !isNaN(ruleId)) {
      const found = perm.permissionsRules.get(ruleId);
      if ( !found ) {
        const rule = await perm.loadPermissionById(ruleId);
        if ( rule && rule.parent_id && !perm.permissionsRules.get(rule.parent_id)) {
          await perm.loadPermissionById(rule.parent_id);
        }
      }
    }
  }
}
