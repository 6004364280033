<div class="account-switcher" [ngClass]="[forgetMode ? 'forgetMode' : '']">
  <div class="account" *ngFor="let a of forgetMode ? auth.availableAccounts : auth.mergedRegionAccounts" role="button" tabindex="0" (click)="click(a)" [ngClass]="a.active ? 'active' : ''">
    <span class="account-icon">
      <div class="userIcon"></div>
    </span>
    <span class="name">{{ a.name }}</span>
    <span class="email">{{ a.email }}</span>
    <span class="loginState">{{ a.token ? '' : trans('auth.labels.loggedOut') }}</span>
    <span class="region">{{ a.region.name }}</span>
    <span class="role">{{ trans('permissions.roles.' + a.role) }}</span>
  </div>
</div>
