<div *ngIf="desktopView" class="header">
  <h4 class="title">{{trans('general.help')}}</h4>
</div>
<div *ngIf='shouldShowLoggerbutton()' id='log-icon-container' (click)='l.showLogView = !l.showLogView; logResolution()'>
    <div id='log-icon' [inlineSVG]="'assets/images/log.svg'"></div>
</div>
<div class="flex flex-col">
  <div class="grid" id='help-grid'>
    <app-button-single-line-arrow id='help-ticket' [icon]="'assets/images/help.svg'" (click)="clickTicket()">{{trans('help.supportTicket')}}</app-button-single-line-arrow>
  </div>
  <div class="flex-1">
    <div class="app-version-container app-version-container-white">
      <h2 class="text-dark">{{getTitle()}}</h2>
      <p>{{trans('help.appVersion').toLowerCase()}} <b class="text-dark">{{getCurrentVersion()}}</b></p>
      <p>{{trans('help.appBuild').toLowerCase()}} <b class="text-dark">{{getBuild()}}</b></p>
      <br/>
      <p>{{this.trans('settings.labels.lastUpdated')}}: <b class="text-dark">{{dt.formatDateTimeFromNumber(getDate() * 1000)}}</b></p>
      <p *ngIf="getLastTimeCheckedDate() !== ''">{{this.trans('settings.labels.lastUpdateChecked')}}: <b class="text-dark">{{getLastTimeCheckedDate()}}</b></p>
      <br/>
      <h2 *ngIf="newerVersion !== null" class="text-dark">{{trans('help.appNewVersionAvailable')}}</h2>
      <p *ngIf="newerVersion !== null">v{{newerVersion.major}}.{{newerVersion.minor}} ({{newerVersion.build}}) - {{dt.formatDateTimeFromNumber(newerVersion.date * 1000)}}</p>
      <br/>
      <p *ngIf="newerVersion !== null && platform.isMobile()" class="apv_update">{{trans('help.updateForMobile')}}</p>
      <ng-container *ngIf="newerVersion !== null && platform.isDesktop(); else mobileUpdate">
        <app-button type="filled-secondary" (click)="clickUpdate()">{{this.trans('settings.buttons.update')}}</app-button>
      </ng-container>
      <ng-template #mobileUpdate>
        <div *ngIf="newerVersion !== null && platform.isMobile()" class="apv_update">{{trans('help.updateForMobile')}}</div>
      </ng-template>
    </div>
  </div>
  <div class="company-information-container">
    <div class="information">
      <p>{{getAddress()}}</p>
      <div class="information-link">
        <div class="information-link-icon" [inlineSVG]="'assets/images/email2.svg'"></div>
        <a (click)='clickEmail()'>{{getEmail()}}</a>
      </div>
      <div class="information-link">
        <div class="information-link-icon" [inlineSVG]="'assets/images/phone.svg'"></div>
        <a (click)='clickPhone()'>{{getPhone()}}</a>
      </div>
    </div>
    <p><b [innerHTML]="getCopyright()"></b></p>
  </div>
</div>
