import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'src/shim';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';
import { AssistingInstallerComponent } from '../assisting-installer/assisting-installer.component';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';

@Component({
  selector: 'app-request-assistance',
  templateUrl: './request-assistance.component.html',
  styleUrls: ['./request-assistance.component.scss'],
})
export class RequestAssistanceComponent extends LanguageAware implements OnInit {
  @ViewChild('assistingInstaller') assistingInstaller;
  @ViewChild('systemToAssist') systemToAssist;
  public periodTime = 1;
  public formContent = '';
  public originalInstallerId: number | undefined = undefined;
  private originalInstallerEmail: string | undefined = undefined;
  public editMode = false;
  public hoursLeft = '';
  public readonly canChangeInstaller = this.us.currentUser.permissions.permissions.users.view;
  private hasInstallerInParams = false;

  constructor(
    public cdRef: ChangeDetectorRef,
    private pp: PopupService,
    private router: Router,
    private r: ActivatedRoute,
    private msg: MessageboxService,
  ) {
    super(cdRef);
    this.originalInstallerId = this.systems.activeSystem.assistedById;
    this.originalInstallerEmail = this.systems.activeSystem.assistedByEmail;
    if ( this.us.installerAssistance ) {
      this.periodTime = this.us.installerAssistance.time;
      this.formContent = this.us.installerAssistance.issue;
      this.us.installerAssistance = undefined;
    }

    const installer = this.r.snapshot.paramMap.get('installer');
    if (installer !== null && isNumeric(installer)) {
      this.hasInstallerInParams = true;
      const chosenInstaller = parseInt(installer, 10);
      const found = this.us.companyInstallers.find((i) => i.id === chosenInstaller);
      if (found !== undefined) {
        this.systems.activeSystem.assistedById = chosenInstaller;
        this.systems.activeSystem.assistedByEmail = found.email;
      }
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.assistance'),
      backUrl: '*',
    });
    this.headerBar.onBackClicked = () => {
      this.returnOriginalData();
      if ( this.hasInstallerInParams ) {
        history.back(); // workaround. antras back, kadangi prieš tai jau buvom užėję į change-installer, o iš ten išėjus pasirinkus installerį history lieka "request-assitance" vienas įrašas.
      }
    };
    this.background.setGray();
    if (this.systems.activeSystem.assistedById !== undefined) {
      this.getAssistanceData();
    }
  }

  ngOnInit(): void {}

  public changePeriod() {
    if (this.miniStatus.isVisible()) {
      return;
    }
    const hours: DropdownItem<number>[] = [{ value: 1, label: '1' }];
    for (let i = 1; i < 16; i++) {
      hours.push({
        value: i * 3,
        label: (i * 3).toString(),
      });
    }
    this.pp.showSlideout<DropdownItem<number>>({
        headerText: this.trans('systems.labels.accessPeriod'),
        items: hours,
        onSubmit: (res) => {
          this.periodTime = res.value;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public changeInstaller() {
    if (this.miniStatus.isVisible()) {
      return;
    }
    this.us.installerAssistance = {
      time: this.periodTime,
      issue: this.formContent,
    };
    const chosenInstaller = this.systems.activeSystem.assistedById ?? 0;
    this.returnOriginalData();
    const path = (this.hasInstallerInParams ? '../' : '') + '../change-installer';
    this.router.navigate([path, chosenInstaller], { relativeTo: this.r });
  }

  public performRequest() {
    if ( !this.systems.activeSystem.assistedById && this.systems.activeSystem.installerId === 0 ) {
      this.toaster.postError(this.trans('users.errors.assistingInstallerNotChosen'));
      return;
    }
    if ( !this.systems.activeSystem.assistedById ) {
      this.systems.activeSystem.assistedById = this.systems.activeSystem.installerId;
      this.systems.activeSystem.assistedByEmail = this.systems.activeSystem.installerEmail;
    }
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/installer-assistance', {
      id: this.systems.activeSystem.assistedById,
      systemId: this.systems.activeSystem.id,
      accessPeriod: this.periodTime,
      describeIssue: this.formContent,
    }, true).subscribe(
      (result) => {
        this.editMode = false;
        if (result.success) {
          this.toaster.postSuccess(this.trans('systems.notifications.installerAccessGranted'));
          this.rebuildHoursText(this.periodTime * 3600);
          this.originalInstallerId = this.systems.activeSystem.assistedById;
          this.originalInstallerEmail = this.systems.activeSystem.assistedByEmail;
        } else {
          this.toaster.postError(result.error);
        }
        this.miniStatus.hide();
      },
      (error) => {
        this.returnOriginalData();
        this.miniStatus.hide();
      }
    );
  }

  private returnOriginalData() {
    this.systems.activeSystem.assistedById = this.originalInstallerId;
    this.systems.activeSystem.assistedByEmail = this.originalInstallerEmail;
    this.systemToAssist.rebuildInstallerName();
  }

  public initRevoke() {
    this.msg.open({
      buttons: this.msg.buttons.OkCancel,
      headerText: this.trans('systems.notifications.areYouSure'),
      messageContent: this.trans('users.labels.revokeInstallerAccess')
    }, (m) => {
      m.okClicked.subscribe(() => { this.performRevoke(); } );
    });
  }

  public performRevoke() {
    const asins = new AssistingInstallerComponent(this.cdRef, this.msg);
    asins.installerId = this.systems.activeSystem.assistedById;
    asins.systemId = this.systems.activeSystem.id;
    this.originalInstallerEmail = undefined;
    this.originalInstallerId = undefined;
    asins.doRevoke(() => {
      this.formContent = '';
      this.systemToAssist.rebuildInstallerName();
    });
  }

  public updateIssue() {
    this.editMode = true;
  }

  private getAssistanceData() {
    this.api.get('/installer-assistance?systemId=' + this.systems.activeSystem.id, true).subscribe(
      (result) => {
        if (result.success && result.data !== null) {
          this.formContent = result.data.issue;
          this.rebuildHoursText(result.data.timeLeft);
        }
      },
      (error) => {}
    );
  }

  private rebuildHoursText(timeLeftInSeconds: number) {
    if (timeLeftInSeconds < 0) {
      this.hoursLeft = this.trans('users.labels.installerAssistanceExpiredHours');
      this.periodTime = 1;
    } else if (timeLeftInSeconds < 3600) {
      this.hoursLeft = '< 1' + this.trans('general.hoursShort');
      this.periodTime = 1;
    } else {
      this.hoursLeft = Math.ceil(timeLeftInSeconds / 3600) + this.trans('general.hoursShort');
      this.periodTime = Math.ceil(timeLeftInSeconds / 3600);
    }
  }
}
