<div class="page-wrapper">
  <div class="page">
    <h3>{{this.lang.get('general.errors.404_aww')}}</h3>
    <p>{{this.lang.get('general.errors.404_just')}}</p>
    <h1>{{this.lang.get('general.errors.404_error')}}</h1>
    <div class="lottie-wrapper">
      <ng-lottie height="20em" [options]="animationOptions"></ng-lottie>
    </div>
    <p>{{this.lang.get('general.errors.404_misplaced')}}</p>
    <app-button type="filled-secondary" (click)="navigateHome()">{{this.lang.get('general.backToHome')}}</app-button>
  </div>
</div>
