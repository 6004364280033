import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionRole, PermissionRule } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { CompanyService } from 'src/app/api/company.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { LocatorService } from 'src/app/services/locator.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { autoinject } from 'src/shim';
import { DataTableGetter } from '../data-table/data-table.component';
import { RowData } from '../users-data-table/users-data-table.component';

@Component({
  selector: 'app-permissions-data-table',
  templateUrl: './permissions-data-table.component.html',
  styleUrls: ['./permissions-data-table.component.scss']
})
export class PermissionsDataTableComponent extends LanguageAware implements OnInit, OnDestroy, OnChanges {
  public loader: DataTableGetter<PermissionRule>;
  private subscriptions: Subscription[] = [];
  @Input() searchLoader: DataTableGetter<PermissionRule>;
  @Input() public columns: string[];
  @Input() public showHeaders = true;
  @Output() public modifyColumns = new EventEmitter<void>();
  private rootRoute = this.g.getHomeUrl();
  private get esService() { return autoinject(LocatorService.injector, EditSystemService); }
  public get companyService() { return autoinject(LocatorService.injector, CompanyService); }
  public readonly PermissionRole = PermissionRole;

  constructor(
    cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService,
    private router: Router,
  ) {
    super(cdRef, true);
    this.loader = this.searchLoader;
  }

  public onRowClick(permissionRow: RowData): void {
    if(permissionRow.column === 'fake:delete') {
      if (permissionRow.item.id > 6) { this.onDeleteIconClick(permissionRow); }
      return;
    }

    if (this.platform.isDesktopView() ) {
      this.router.navigate([...this.rootRoute, 'permissions', 'edit', permissionRow.item.id]);
    } else {
      this.esService.beginComponentEdit(TEditableComponent.Permission, permissionRow.item.id, permissionRow.item);
      this.router.navigate([...this.rootRoute, 'permissions', 'edit']);
    }
  }

  public onDeleteIconClick(event: RowData): void {
    if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active ) {
      return;
    }
    if ( !this.us.currentUser.permissions.permissions.roles.delete ) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
`${this.trans('permissions.labels.deletePermission')} <strong>${event.item.name}</strong>. ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deletePermissionRule(event.item.id);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async deletePermissionRule(permissionId: number): Promise<void> {
    try {
      await requests.permission.deletePermissionRule({ id: permissionId}).toPromise();
      this.permissionService.permissionsRules.delete(permissionId);
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleSuccess'),
        messageContent: this.trans('permissions.labels.permissionIsDeletedNow'),
        iconType: this.messagebox.iconType.Success
      });
    } catch (error) { }
  }

  ngOnInit(): void {
    this.loader = this.searchLoader;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.loader = this.searchLoader;
    }
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

}
