import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IPComOutput, IPComOutputMode, IPComReceiver } from 'src/api/v3/settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { DataTableGetter } from '../data-table/data-table.component';
type RowData<T> = {
  column: string;
  item: T;
  row: number;
};

type IPComReceiverOutputUnion = IPComReceiver & IPComOutput;

@Component({
  selector: 'app-ipcom-receivers-data-table',
  templateUrl: './ipcom-receivers-data-table.component.html',
  styleUrls: ['./ipcom-receivers-data-table.component.scss']
})
export class IpcomReceiversDataTableComponent extends LanguageAware implements OnInit, OnChanges, OnDestroy {
  public loader: DataTableGetter<IPComReceiverOutputUnion>;
  private subscriptions: Subscription[] = [];
  @Input() public ipcomId: number;
  @Input() public columns: string[];
  @Input() public showHeaders = true;
  @Output() public modifyColumns = new EventEmitter<void>();
  @Output() public entryDeleted = new EventEmitter<void>();
  @Output() public beforeEdit = new EventEmitter<void>();
  public receivers = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService,
    private router: Router,
  ) {
    super(cdRef, true);
    this.renewLoader();
  }

  ngOnChanges(changes: SimpleChanges) {
    if( changes.ipcomId ) {
      this.renewLoader();
    }
  }

  private renewLoader() {
    this.receivers = this.ipcomService.ipcomSettings.get(this.ipcomId)?.receivers.filter(r => !r.deleted) ?? [];
    this.loader = async (c, cc, m) => {
      const settings = this.ipcomService.ipcomSettings.get(this.ipcomId);
      if ( !settings ) { return; }
      return settings.receivers.filter(r => !r.deleted).map<IPComReceiverOutputUnion>((r) => {
        const firstOutput = r.outputs.length > 0 ? r.outputs[0] : undefined;
        let itemToReturn: IPComReceiverOutputUnion;
        if ( firstOutput === undefined ) {
          itemToReturn = {
            ...r,
            output_id: 0,
            type: 1,
            ip: '',
            tcp_port: 0,
            com_id: 0,
            hb_enable: 'False',
            hb_interval: 30,
            active: 'False',
            out_mode: IPComOutputMode.Surgard,
            buffer_size: 1024,
            name: '',
            identificator: 0,
            encryption_enabled: 'False',
            sia_key: '',
          };
        } else {
          const outputItem = settings.outputs.find(o => o.output_id === firstOutput);
          itemToReturn = { ...r, ...outputItem};
        }
        return itemToReturn;
      });
    };
  }

  public onRowClick(row: RowData<IPComReceiverOutputUnion>): void {
    if(row.column === 'fake:delete' && row.item.receiver_id !== 0 ) {
      this.onDeleteIconClick(row);
      return;
    }

    this.beforeEdit.emit();
    this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'ipcoms', 'edit', this.ipcomId, 'receiver', row.item.receiver_id]);
  }

  public onDeleteIconClick(event: RowData<IPComReceiverOutputUnion>): void {
    if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active ) {
      return;
    }
    if ( !this.us.currentUser.permissions.permissions.ipcom_settings.delete ) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
`${this.trans('settings.ipcom.deleteReceiverInfo')} <strong>${event.item.receiver_name}</strong>. ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deleteReceiver(event.item.receiver_id);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async deleteReceiver(id: number): Promise<void> {
    try {
      const settings = this.ipcomService.ipcomSettings.get(this.ipcomId);
      const found = settings.receivers.find(r => r.receiver_id === id);
      found.deleted = true;
      if ( found.outputs.length > 0 ) {
        settings.outputs = settings.outputs.filter(o => o.output_id !== found.outputs[0]);
        found.outputs = [];
      }
      this.receivers = this.ipcomService.ipcomSettings.get(this.ipcomId)?.receivers.filter(r => !r.deleted) ?? [];
      this.entryDeleted.emit();
    } catch (error) { }
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

}
