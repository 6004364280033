import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import requests from 'src/api/v3/requests';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { IPCom } from 'src/app/models/ipcom';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { LocatorService } from 'src/app/services/locator.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { autoinject } from 'src/shim';
import { DataTableGetter } from '../data-table/data-table.component';
import { RowData } from '../users-data-table/users-data-table.component';

@Component({
  selector: 'app-ipcoms-data-table',
  templateUrl: './ipcoms-data-table.component.html',
  styleUrls: ['./ipcoms-data-table.component.scss']
})
export class IpcomsDataTableComponent extends LanguageAware implements OnInit, OnChanges, OnDestroy {
  public loader: DataTableGetter<IPCom>;
  private subscriptions: Subscription[] = [];
  @Input() searchLoader: DataTableGetter<IPCom>;
  @Input() public columns: string[];
  @Input() public showHeaders = true;
  @Output() public modifyColumns = new EventEmitter<void>();
  private get esService() { return autoinject(LocatorService.injector, EditSystemService); }

  constructor(
    cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService,
    private router: Router,
  ) {
    super(cdRef, true);
    this.loader = this.searchLoader;
  }

  public onRowClick(row: RowData): void {
    if(row.column === 'fake:delete') {
      this.onDeleteIconClick(row);
      return;
    }

    if (this.platform.isDesktopView() ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'ipcoms', 'edit', row.item.id]);
    } else {
      this.esService.beginComponentEdit(TEditableComponent.IPcom, row.item.id, row.item);
      this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'ipcoms', 'edit']);
    }
  }

  public onDeleteIconClick(event: RowData): void {
    if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active ) {
      return;
    }
    if ( !this.us.currentUser.permissions.permissions.ipcom_settings.delete || this.us.currentUser.company_id !== 0 ) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
`${this.trans('settings.ipcom.deleteIpcomInfo').replace(':ipcom', `<strong>${event.item.name}</strong>`)}. ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deleteIpcom(event.item.id);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async deleteIpcom(id: number): Promise<void> {
    try {
      await requests.settings.ipcom.delete({ id }).toPromise();
      this.ipcomService.removeIpcom(id);
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleSuccess'),
        messageContent: this.trans('settings.ipcom.ipcomDeleted'),
        iconType: this.messagebox.iconType.Success
      });
    } catch (error) { }
  }

  ngOnInit(): void {
    this.loader = this.searchLoader;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.loader = this.searchLoader;
    }
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public getRegionText(regionId: number): string {
    return this.us.currentUser.regions.find(r => r.id === regionId)?.name ?? '';
  }
}
