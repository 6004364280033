<ng-template #headerId let-class="className"><div [ngClass]="class">{{trans('general.id')}}</div></ng-template>
<ng-template #headerName let-class="className"><div [ngClass]="class">{{trans('settings.labels.nameIpcom')}}</div></ng-template>
<ng-template #headerCompany let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.companySelect')}}</div></ng-template>
<ng-template #headerPeer let-class="className"><div [ngClass]="class">{{trans('settings.labels.peerName')}}</div></ng-template>
<ng-template #headerHost let-class="className"><div [ngClass]="class">{{trans('settings.labels.host')}}</div></ng-template>
<ng-template #headerPort let-class="className"><div [ngClass]="class">{{trans('settings.labels.port')}}</div></ng-template>
<ng-template #headerUser let-class="className"><div [ngClass]="class">{{trans('settings.labels.userName')}}</div></ng-template>
<ng-template #headerOwn let-class="className"><div [ngClass]="class">{{trans('settings.labels.own')}}</div></ng-template>
<ng-template #headerRegion let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.region')}}</div></ng-template>

<ng-template #idContent let-value="item.id">
    <div class="ipcomId">{{value}}</div>
</ng-template>
<ng-template #deleteIcon>
    <div class='deleteIcon' *ngIf="this.us.currentUser.permissions.permissions.ipcom_settings.delete && this.us.currentUser.company_id === 0" [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>
<ng-template #arrowIcon>
    <div class="arrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.402 14.697">
            <path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/>
        </svg>
    </div>
</ng-template>
<ng-template #companyContent let-value="item.company_id">
    {{value === 0 ? '' : companyService.getCompany(value)?.name}}
</ng-template>
<ng-template #ownContent let-value="item.own_ipcom">
    {{value ? trans('general.yes') : &nbsp;'' }}
</ng-template>
<ng-template #regionContent let-value="item.region">
    {{ getRegionText(value) }}
</ng-template>
<app-data-table
    *ngIf="us.currentUser?.permissions.permissions.ipcom_settings.view"
    class="dataTable"
    [data]="loader"
    [slots]="{
      'header:id': headerId,
      'id': idContent,
      'header:name': headerName,
      'header:company_id': headerCompany,
      company_id: companyContent,
      'header:peer_name': headerPeer,
      'header:host': headerHost,
      'header:port': headerPort,
      'header:user_name': headerUser,
      'header:own_ipcom': headerOwn,
      own_ipcom: ownContent,
      'header:region': headerRegion,
      region: regionContent,
      'fake:delete': deleteIcon,
      'fake:arrow': arrowIcon
    }"
    [columns]="columns"
    (modifyColumns)="modifyColumns.emit()"
    (rowClick)="onRowClick($event)"
    [dataSize]="this.ipcomService.ipcoms.size"
    [showHeaders]="showHeaders"
>
</app-data-table>
