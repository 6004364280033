import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewSystemService, panelData, PanelManufacturer } from 'src/app/services/new-system.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-panel-brand-selection',
  templateUrl: './panel-brand-selection.component.html',
  styleUrls: ['./panel-brand-selection.component.scss']
})
export class PanelBrandSelectionComponent extends LanguageAware implements OnInit {
  public manufacturers = panelData;

  constructor(
    cdRef: ChangeDetectorRef,
    public ns: NewSystemService,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    if ( !this.ns.isSystemBeingCreated() ) {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.panelSelection'),
      backUrl: '*',
    });
    this.background.setGray();
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
    };
  }

  ngOnInit(): void {
  }

  public onManufacturerSelected(m: PanelManufacturer) {
    this.ns.selectedManufacturer = m;
    this.router.navigate(['../select-panel'], { relativeTo: this.r });
  }

}
