import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Company } from 'src/api/v3/company';
import requests from 'src/api/v3/requests';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { LocatorService } from 'src/app/services/locator.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { autoinject } from 'src/shim';
import { DataTableGetter } from '../data-table/data-table.component';
import { RowData } from '../users-data-table/users-data-table.component';

@Component({
  selector: 'app-companies-data-table',
  templateUrl: './companies-data-table.component.html',
  styleUrls: ['./companies-data-table.component.scss']
})
export class CompaniesDataTableComponent extends LanguageAware implements OnInit, OnDestroy, OnChanges {
  public loader: DataTableGetter<Company>;
  private subscriptions: Subscription[] = [];
  @Input() searchLoader: DataTableGetter<Company>;
  @Input() public columns: string[];
  @Input() public showHeaders = true;
  @Output() public modifyColumns = new EventEmitter<void>();
  private rootRoute = this.g.getHomeUrl();
  private get esService() { return autoinject(LocatorService.injector, EditSystemService); }

  constructor(
    cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public router: Router,
    private messagebox: MessageboxService,
  ) {
    super(cdRef, true);
    this.loader = this.searchLoader;
  }

  public onRowClick(companyRow: RowData): void {
    if(companyRow.column === 'fake:delete') {
      this.onDeleteIconClick(companyRow);
      return;
    }

    if (this.platform.isDesktopView() ) {
      this.router.navigate([...this.rootRoute, 'companies', 'edit', companyRow.item.id]);
    } else {
      this.esService.beginComponentEdit(TEditableComponent.Company, companyRow.item.id, companyRow.item);
      this.router.navigate([...this.rootRoute, 'companies', 'edit']);
    }
  }

  public onDeleteIconClick(event: RowData): void {
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
`${this.trans('companies.labels.deleteCompany')} <strong>${event.item.name} (${event.item.email})</strong>. ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deleteCompany(event.item.id);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async deleteCompany(companyId: number): Promise<void> {
    try {
      await requests.company.deleteCompany({ id: companyId}).toPromise();
      if ( this.companyService.activeCompanyId === companyId ) {
        this.companyService.activeCompanyId = 0;
      }
      this.companyService.companies.delete(companyId);
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleSuccess'),
        messageContent: this.trans('companies.labels.companyIsDeletedNow'),
        iconType: this.messagebox.iconType.Success
      });
      this.router.navigate([...this.rootRoute, 'companies']);
    } catch (error) {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleError'),
        messageContent: `${this.trans('companies.errors.failedToDeleteCompany')}: ${error.message}`,
        iconType: this.messagebox.iconType.Error
      });
    }
  }

  ngOnInit(): void {
    this.loader = this.searchLoader;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.loader = this.searchLoader;
    }
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }
}
