import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { TZoneData } from 'src/app/models/zone-data';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { NewSystemService } from 'src/app/services/new-system.service';

@Component({
  selector: 'app-add-system-zone-list',
  templateUrl: './add-system-zone-list.component.html',
  styleUrls: ['./add-system-zone-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSystemZoneListComponent extends LanguageAware implements OnInit, AfterViewInit {
  public windowName = 'zone-list';
  private controlsEnabled = true;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService,
    private ns: NewSystemService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.progressBar.showProgress();
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.zones'),
      backUrl: '../add-system-area-list',
      activatedRoute: this.r,
    });
    this.footerBar.showFooter(this.trans('general.done'), '', false, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onDone();
    };
    this.background.setGray();
    this.helper.shouldShowAddButtonAtBottom(this.systems.activeSystem.zones.length, this.windowName);
    this.helper.setContentRowHeight(true, true, true, this.windowName);
    this.es.endComponentEdit(TEditableComponent.Zone);
  }

  ngOnInit(): void {
    this.progressBar.setProgressData(6, 7);
  }

  ngAfterViewInit() {
    if (!this.helper.plusIsAtBottomNow(this.windowName)) {
      const plusButton = document.querySelector('#aszl-add-zone-button') as HTMLElement;
      const zonesBackground = document.querySelector('#aszl-background') as HTMLElement;
      this.helper.setCountForBottomButton(this.systems.activeSystem.zones.length, plusButton.offsetTop, zonesBackground.offsetHeight, true, true, false, this.windowName);
    }
    const zoneList = document.querySelector('#aszl-zone-list') as HTMLElement;
    this.helper.shouldShowFakeElement(zoneList.offsetHeight, this.windowName);
  }

  private onDone() {
    if ( this.platform.isDesktopView() ) {
      this.router.navigate(this.g.getHomeUrl());
    } else {
      this.router.navigate(['../add-system-complete'], { relativeTo: this.r, replaceUrl: true});
    }
  }

  public getZones(): TZoneData[] {
    return this.systems.activeSystem.zones.filter((z) => z.enabled && z.visible);
  }

  public getAssignedAreas(zoneNumber: number): string {
    let result = '';
    const zone = this.systems.activeSystem.zones.find((z) => z.queue_no === zoneNumber);
    if (zone === undefined) {
      return '';
    }
    const areas = zone.areas.sort();
    for (const iArea of areas) {
      const area = this.systems.activeSystem.areas.find((a) => a.queue_no === iArea);
      if (area === undefined) {
        continue;
      }
      if (result !== '') {
        result += ', ';
      }
      result += area.name;
    }
    return result;
  }

  public onZoneDelete(event, value: number) {
    event.stopPropagation();
    this.deleteZone(value);
    const zoneList = document.querySelector('#aszl-zone-list') as HTMLElement;
    this.helper.shouldShowFakeElement(zoneList.offsetHeight, this.windowName);
  }

  public onAddZone() {
    this.router.navigate(['../system-zone'], { relativeTo: this.r });
  }

  public onZoneSelect(value: TZoneData) {
    this.es.beginComponentEdit(TEditableComponent.Zone, value.queue_no, value);
    this.router.navigate(['../system-zone'], { relativeTo: this.r });
  }

  private deleteZone(zoneNumber: number) {
    const zone = this.systems.activeSystem.zones.find((z) => z.queue_no === zoneNumber);
    const activeSystemId = this.systems.activeSystem.id;
    if (zone.native) {
      zone.visible = false;
    } else {
      this.systems.activeSystem.zones = this.systems.activeSystem.zones.filter((z) => z.queue_no !== zoneNumber);
    }
    this.systems.setCurrentSystem(this.systems.activeSystem);
    const that = this;
    this.api.post('/delete/zone', { system_id: activeSystemId, queue_no: zone.queue_no }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.revertDeletion(zone, activeSystemId);
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.revertDeletion(zone, activeSystemId);
        that.toaster.postError(this.trans('systems.errors.zoneSaveFailed'));
      }
    );
  }

  private revertDeletion(zone: TZoneData, systemId: number) {
    let systemToUse = this.systems.activeSystem;
    let isActiveSystem = true;
    if (this.systems.activeSystem.id !== systemId) {
      systemToUse = this.systems.getSystem(systemId);
      isActiveSystem = false;
    }
    if (systemToUse === null) {
      return;
    }

    if (zone.native) {
      zone.visible = true;
    } else {
      systemToUse.zones.push(zone);
    }
    if (isActiveSystem) {
      this.systems.setCurrentSystem(this.systems.activeSystem);
    }
  }
}
