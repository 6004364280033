import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-system-configuration',
  templateUrl: './system-configuration.component.html',
  styleUrls: ['./system-configuration.component.scss'],
})
export class SystemConfigurationComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.configureSystem') + ' ' + this.systems.activeSystem?.name,
      backUrl: this.headerBar.isDesktop ? `/d/company/systems/${this.systems.activeSystem?.id.toString()}` : '/home',
    });
    localStorage.setItem('ref', 'conf');
  }

  ngOnInit(): void {}

  public goToTransfer() {
    const combined = Number(this.systems.activeSystemHelper.can.transferDevice())
                   + Number(this.systems.activeSystemHelper.can.transferOwnership())
                   + Number(this.systems.activeSystemHelper.can.transferToCompany());
    if ( combined > 1 ) {
      this.router.navigate(['/transfer-type']);
      return;
    }
    if ( this.systems.activeSystemHelper.can.transferOwnership() ) {
      this.router.navigate(['/system-transfer']);
    } else if ( this.systems.activeSystemHelper.can.transferDevice() ) {
      this.router.navigate(['/transfer-device']);
    } else if ( this.systems.activeSystemHelper.can.transferToCompany() ) {
      // this.router.navigate(['/transfer-to-company']);
    }
  }
}
