import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import handAnimation from 'src/lottie/notfound-hand.json';
import { Router} from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {
  public readonly animationOptions: AnimationOptions = {
    animationData: handAnimation,
  };

  constructor(private router: Router, public lang: LanguageService) {}

  ngOnInit(): void {}

  navigateHome(): void {
    this.router.navigateByUrl('/');
  }
}
