type ValidatorSupportedTypes = string | number | boolean;

type ValidatedData = Record<string, ValidatorSupportedTypes>;

type ValidationMessage<TField extends string> = string | ((field: TField, context: any) => string);

type ValidatorRuleBase<TField extends string> = {
  field: TField;
  message: ValidationMessage<TField>;
  runMode: 'always' | 'filled';
  isUsed?: (context: any) => boolean;
};

type RegextValidatorRule<TData extends ValidatedData, TField extends Extract<keyof TData, string>> = ValidatorRuleBase<TField> & {
  type: 'regex';
  regex: RegExp;
};

type RequiredValidatorRule<TData extends ValidatedData, TField extends Extract<keyof TData, string>> = ValidatorRuleBase<TField> & {
  type: 'required';
};

type InListValidatorRule<TData extends ValidatedData, TField extends Extract<keyof TData, string>> = ValidatorRuleBase<TField> & {
  type: 'inList';
  list: TData[TField][];
};

type CustomValidatorRule<TData extends ValidatedData, TField extends Extract<keyof TData, string>> = ValidatorRuleBase<TField> & {
  type: 'custom';
  validator: (value: TData[TField]) => Promise<[boolean, ValidationMessage<TField>]>;
};

type MinLengthValidatorRule<TData extends ValidatedData, TField extends Extract<keyof TData, string>> = ValidatorRuleBase<TField> & {
  type: 'minLength';
  minLength: number;
};

type MaxLengthValidatorRule<TData extends ValidatedData, TField extends Extract<keyof TData, string>> = ValidatorRuleBase<TField> & {
  type: 'maxLength';
  maxLength: number;
};

type CompareValidatorRule<
  TData extends ValidatedData,
  TField extends Extract<keyof TData, string>,
  TOtherField extends Extract<keyof TData, string>
> = ValidatorRuleBase<TField> & {
  type: 'compare';
  otherField: TOtherField;
  comparator?: (a: TData[TField], b: TData[TOtherField]) => boolean;
};

type ValidatorRule<TData extends ValidatedData> =
  | RegextValidatorRule<TData, Extract<keyof TData, string>>
  | RequiredValidatorRule<TData, Extract<keyof TData, string>>
  | InListValidatorRule<TData, Extract<keyof TData, string>>
  | CompareValidatorRule<TData, Extract<keyof TData, string>, Extract<keyof TData, string>>
  | CustomValidatorRule<TData, Extract<keyof TData, string>>
  | MinLengthValidatorRule<TData, Extract<keyof TData, string>>
  | MaxLengthValidatorRule<TData, Extract<keyof TData, string>>;

export class ValidatorBuilder<TData extends ValidatedData> {
  public rules: ValidatorRule<TData>[] = [];
  public ruleMap = new Map<string, ValidatorRule<TData>[]>();

  private lastRule?: ValidatorRule<TData>;

  constructor() {}

  public required<TField extends Extract<keyof TData, string>>(field: TField, message: ValidationMessage<TField>): this {
    this.lastRule = {
      type: 'required',
      field,
      message,
      runMode: 'always',
    };
    this.rules.push(this.lastRule);
    return this;
  }

  public regex<TField extends Extract<keyof TData, string>>(field: TField, regex: RegExp, message: ValidationMessage<TField>): this {
    this.lastRule = {
      type: 'regex',
      field,
      regex,
      message,
      runMode: 'filled',
    };
    this.rules.push(this.lastRule);
    return this;
  }

  public inList<TField extends Extract<keyof TData, string>>(field: TField, list: TData[TField][], message: ValidationMessage<TField>): this {
    this.lastRule = {
      type: 'inList',
      field,
      list,
      message,
      runMode: 'filled',
    };
    this.rules.push(this.lastRule);
    return this;
  }

  public equal<TField extends Extract<keyof TData, string>, TOtherField extends Extract<keyof TData, string>>(
    field: TField,
    otherField: TOtherField,
    message: ValidationMessage<TField>
  ): this {
    this.lastRule = {
      type: 'compare',
      field,
      otherField,
      message,
      runMode: 'filled',
    };
    this.rules.push(this.lastRule);
    return this;
  }

  public custom<TField extends Extract<keyof TData, string>>(field: TField, validator: (value: TData[TField]) => Promise<[boolean, ValidationMessage<TField>]>): this {
    this.lastRule = {
      type: 'custom',
      field,
      validator,
      message: '',
      runMode: 'filled',
    };
    this.rules.push(this.lastRule);
    return this;
  }

  public setRunMode(runMode: ValidatorRuleBase<string>['runMode']): this {
    if (this.lastRule) {
      this.lastRule.runMode = runMode;
    }
    return this;
  }

  public useOnlyWhen(isUsed: ValidatorRuleBase<string>['isUsed']): this {
    if (this.lastRule) {
      this.lastRule.isUsed = isUsed;
    }
    return this;
  }

  public build(): ValidatorInstance<TData> {
    this.ruleMap.clear();
    this.rules.forEach((rule) => {
      const rules = this.ruleMap.get(rule.field);
      if (rules) {
        rules.push(rule);
      } else {
        this.ruleMap.set(rule.field, [rule]);
      }
    });
    return new ValidatorInstance(this);
  }

  public minLength<TField extends Extract<keyof TData, string>>(field: TField, minLength: number, message: ValidationMessage<TField>): this {
    this.lastRule = {
      type: 'minLength',
      field,
      minLength,
      message,
      runMode: 'filled',
    };
    this.rules.push(this.lastRule);
    return this;
  }

  public maxLength<TField extends Extract<keyof TData, string>>(field: TField, maxLength: number, message: ValidationMessage<TField>): this {
    this.lastRule = {
      type: 'maxLength',
      field,
      maxLength,
      message,
      runMode: 'filled',
    };
    this.rules.push(this.lastRule);
    return this;
  }
}

type ValidationError = {
  field: string;
  message: string;
};

export class ValidatorInstance<TData extends ValidatedData> {
  public Errors: ValidationError[] = [];
  public ErrorMap = new Map<string, ValidationError[]>();
  public context: any = null;
  constructor(private validator: ValidatorBuilder<TData>) {}

  public hasError(field: string): boolean {
    return this.ErrorMap.get(field)?.length > 0;
  }

  public getError(field: string): string | undefined {
    return this.Errors.find((error) => error.field === field)?.message;
  }

  public mapError<T>(field: string, mapping: Record<string, T> & { _: T }): T {
    const error = this.getError(field);
    console.log({ error, mapping });
    if (error) {
      return mapping[error];
    }
    return mapping._;
  }

  public async validate(data: TData, fields?: (keyof TData)[]): Promise<boolean> {
    this.Errors = [];
    this.ErrorMap.clear();
    await Promise.all(this.validator.rules.filter((r) => (fields ? fields.includes(r.field) : true)).map((rule) => this.executeRule(rule, data)));
    return this.Errors.length === 0;
  }

  public async validateField(field: Extract<keyof TData, string>, data: TData): Promise<boolean> {
    console.log('validateField', { field, data });
    const rules = this.validator.ruleMap.get(field);
    if (!rules) {return true;}
    this.ErrorMap[field as string] = [];
    await Promise.all(rules.map((rule) => this.executeRule(rule, data)));
    // Rebuild Errors array
    this.Errors = [];
    this.ErrorMap.forEach((errors) => {
      this.Errors.push(...errors);
    });
    return this.ErrorMap[field as string].length === 0;
  }

  private async executeRule(rule: ValidatorRule<TData>, data: TData): Promise<void> {
    if (rule.runMode === 'filled' && !data[rule.field]) {return;}
    if (rule.isUsed && !rule.isUsed(this.context)) {return;}
    switch (rule.type) {
      case 'regex':
        return this.handleErrors(rule, rule.regex.test(data[rule.field] as string));
      case 'required':
        return this.handleErrors(rule, !!data[rule.field]);
      case 'inList':
        return this.handleErrors(rule, rule.list.includes(data[rule.field]));
      case 'compare':
        return this.handleErrors(rule, rule.comparator ? rule.comparator(data[rule.field], data[rule.otherField]) : data[rule.field] === data[rule.otherField]);
      case 'custom':
        return this.handleErrors(rule, ...(await rule.validator(data[rule.field])));
      case 'minLength':
        return this.handleErrors(rule, (data[rule.field] as string).length >= rule.minLength);
      case 'maxLength':
        return this.handleErrors(rule, (data[rule.field] as string).length <= rule.maxLength);
    }
  }

  public bindContext(context: any): this {
    this.context = context;
    return this;
  }

  public proxify(data: TData, fields: (keyof TData)[]): TData {
    const proxy = new Proxy(data, {
      set: (obj, prop, value) => {
        (obj as object)[prop] = value;
        if (fields.includes(prop as keyof TData)) {
          this.validate(data);
        }
        return true;
      },
    });
    return proxy;
  }
  private handleErrors(rule: ValidatorRule<TData>, test: boolean, message?: ValidationMessage<string>): void {
    if (!test) {
      this.pushError(rule.field, message ? this.resolveValidationMessage(message, rule.field) : this.resolveValidationMessage(rule.message, rule.field));
    }
  }

  private resolveValidationMessage(message: ValidationMessage<string>, field: string): string {
    if (typeof message === 'string') {return message;}
    return message(field, this.context);
  }

  public pushError(field: string, message: string): void {
    const error = {
      field,
      message,
    };
    this.Errors.push(error);
    const errors = this.ErrorMap.get(field);
    if (errors) {
      errors.push(error);
    } else {
      this.ErrorMap.set(field, [error]);
    }
  }
}

type ValidationMessageCustomPassword = ValidationMessage<'password'>;
type ValidationMessuagesCustomPassword = {
  tooShort: ValidationMessageCustomPassword;
  noUpperCase: ValidationMessageCustomPassword;
  noLowerCase: ValidationMessageCustomPassword;
  noNumber: ValidationMessageCustomPassword;
};

export const customPasswordValidatorBuilder = (messages: ValidationMessuagesCustomPassword): CustomValidatorRule<{ password: string | null }, 'password'>['validator'] =>
  async (password) => {
    if (!password) {return [true, ''];}
    if (password.length < 8) {return [false, messages.tooShort];}
    if (!/[A-Z]/.test(password)) {return [false, messages.noUpperCase];}
    if (!/[a-z]/.test(password)) {return [false, messages.noLowerCase];}
    if (!/[0-9]/.test(password)) {return [false, messages.noNumber];}
    return [true, ''];
  };
