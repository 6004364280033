import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { EventFilterCriteria } from 'src/app/api/system/event.service';
import { SystemService } from 'src/app/api/system/system.service';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';
import { UResolvable } from '../../../guards/UResolvable';
import { PersistenceService } from 'src/app/api/persistence.service';
import { LanguageAware } from 'src/app/general/language-aware';

const defaultColumns = ['fake:icon', 'title', 'areaText', 'systemId', 'fake:tag', 'time'];

@Component({
  templateUrl: './company-events.component.html',
  styleUrls: ['./company-events.component.scss'],
})
export class CompanyEventsComponent extends LanguageAware implements OnInit, UResolvable<typeof CompanyEventsComponent> {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('events.titles.icon'), value: 'fake:icon' },
    { name: this.trans('events.titles.event'), value: 'title' },
    { name: this.trans('events.titles.area2'), value: 'areaText' },
    { name: this.trans('events.titles.system'), value: 'systemId' },
    { name: this.trans('events.titles.tag'), value: 'fake:tag' },
    { name: this.trans('events.titles.timeDate'), value: 'time' },
  ];
  public columns = this.persistence.bind('events_columns', defaultColumns);
  public criteria: EventFilterCriteria = {
    systemId: -1,
    reactionId: -1,
  };
  public contextCriteria = {};

  constructor(cdRef: ChangeDetectorRef, private persistence: PersistenceService) {
    super(cdRef);
  }

  ngOnInit(): void {}

  public static async resolve(injector: Injector): Promise<void> {
    const systems = injector.get(SystemService);
    await systems.loadPartialSystemData();
  }

}
