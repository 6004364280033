<h4 *ngIf="desktopView"><app-back-button [defaultBehaviour]="false" (buttonPressed)="backButtonPressed()" [style.display]="'inline-block'"></app-back-button>{{ isNew ? trans('tags.titles.newTag') : trans('tags.titles.editTag') }}</h4>
<div class="white_round padding8">
    <app-string-input type="text" label="{{trans('tags.labels.name')}}" [(value)]="tagToEdit.name" (valueChange)="inputChanged = true" [hasError]="val.hasError('name')">
      <app-form-error *ngIf="val.hasError('name')">{{ val.getError('name') }}</app-form-error>
    </app-string-input>
    <app-string-input type="text" label="{{trans('tags.labels.description')}}" [(value)]="tagToEdit.description" (valueChange)="inputChanged = true"></app-string-input>
    <div class="colorField">
      <app-string-input type="color" label="{{trans('tags.labels.color')}}" [(value)]="tagToEdit.color" (valueChange)="inputChanged = true; changeTextColor(tagToEdit.color)"></app-string-input>
      <app-string-input type="text" label="&nbsp;" [(value)]="tagToEdit.color" (valueChange)="inputChanged = true; changeTextColor(tagToEdit.color)">
        <app-form-error *ngIf="val.hasError('color')">{{ val.getError('color') }}</app-form-error>
      </app-string-input>
    </div>
    <app-string-input *ngIf="tagToEdit.company_id !== 0 && us.currentUser.company_id === 0" type="text" label="{{trans('tags.labels.belongsToCompany')}}" [(value)]="companyText" [disabled]="true"></app-string-input>
    <ng-component *ngIf="(inputChanged || isNew) && canEdit && desktopView">
        <ng-template appTeleport="footer">
            <app-desktop-footer [buttons]="footerButtons" [loading]="isSaving"></app-desktop-footer>
        </ng-template>
    </ng-component>
</div>
