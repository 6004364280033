import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ReactionBasicData, SensorIconData } from 'src/api/v3/common';
import { ValueOf } from 'src/rxjs-tools';
import { PgmIconData } from '../models/pgm';
import { AccountStore } from './auth.service';

export type LocalStorageValues = {
  privacy_consent: boolean;
  globalAccounts: AccountStore;
  token: string;
  system_columns: string[];
  events_columns: string[];
  event_list_columns: string[];
  event_assignable_list_columns: string[];
  region_columns: string[];
  users_columns: string[];
  basicReactions: ReactionBasicData[];
  sensorIcons: SensorIconData[];
  pgmIcons: PgmIconData[];
  pgmIconsVersion: string;
  sensorIconsVersion: string;
  reactionsIconsVersion: string;
  last_system: number;
  company_list_columns: string[];
  permission_list_columns: string[];
  tag_list_columns: string[];
  dev_template_columns: string[];
  tab_control_page: string;
  ipcom_list_columns: string[];
  ipcom_receiver_list_columns: string[];
};

export type LocalStorageKeys = keyof LocalStorageValues;

export type BindObject<TValue> = {
  value: TValue;
  cleanup: () => void;
};

type BindObjectInternal<TValue> = BindObject<TValue> & {
  _value: TValue;
};

@Injectable({
  providedIn: 'root',
})
export class PersistenceService {
  private observableCache = new Map<LocalStorageKeys, Observable<ValueOf<LocalStorageValues>>>();
  private localChangeSubject = new Subject<{ key: LocalStorageKeys; newValue: ValueOf<LocalStorageValues> }>();
  private bindObjectsCache = new Map<LocalStorageKeys, BindObjectInternal<ValueOf<LocalStorageValues>>>();

  public set<K extends LocalStorageKeys, T extends LocalStorageValues[K]>(key: K, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
    this.localChangeSubject.next({ key, newValue: value });
  }

  public get<K extends LocalStorageKeys, T extends LocalStorageValues[K], TDef extends T | undefined>(
    key: K,
    defaultValue?: TDef
  ): TDef extends undefined ? T | null : T {
    const value = localStorage.getItem(key);
    if (value === null) {
      if (defaultValue !== undefined) { this.set(key, defaultValue); }
      return defaultValue ?? null;
    }
    return JSON.parse(value) as T;
  }

  public subscribe<K extends LocalStorageKeys, T extends LocalStorageValues[K]>(key: K): Observable<T> {
    if (this.observableCache.has(key)) { return this.observableCache.get(key) as Observable<T>; }
    const observable = new Observable<T>((subscriber) => {
      const handler = (event: StorageEvent) => {
        if (event.key === key) {
          if (event.newValue === null) {
            subscriber.next(null);
          } else {
            subscriber.next(JSON.parse(event.newValue) as T);
          }
        }
      };
      window.addEventListener('storage', handler);
      const localSubscription = this.localChangeSubject.subscribe((event) => {
        if (event.key === key) {
          subscriber.next(event.newValue as T);
        }
      });
      return () => {
        window.removeEventListener('storage', handler);
        localSubscription.unsubscribe();
      };
    });
    this.observableCache.set(key, observable);
    return observable;
  }

  public bind<K extends LocalStorageKeys, T extends LocalStorageValues[K]>(key: K, defaultValue: T): BindObject<T> {
    if (this.bindObjectsCache.has(key)) { return this.bindObjectsCache.get(key) as unknown as BindObject<T>; }
    const bindObject: BindObjectInternal<T> = {
      value: this.get(key, defaultValue),
      _value: this.get(key, defaultValue),
      cleanup: () => null,
    };
    Object.defineProperty(bindObject, 'value', {
      get: () => bindObject._value,
      set: (value) => {
        bindObject._value = value;
        this.set(key, value);
      },
    });
    const subscription = this.subscribe<K, T>(key).subscribe({
      next: (value) => {
        bindObject._value = value;
      },
    });
    bindObject.cleanup = () => {
      subscription.unsubscribe();
      this.bindObjectsCache.delete(key);
    };
    this.bindObjectsCache.set(key, bindObject);
    return bindObject;
  }
}
