export enum WirelessDeviceType {
  Disabled = 0x00,
  PIR = 0x31,
  Magnet = 0x32,
  FloodDetector = 0x38,
  SmokeDetector = 0x34,
  GlassDetector = 0x36,
  Vibration = 0x39,
  Combo = 0x3c,
  SmokeSFW2 = 0x44,
  Siren = 0x45,
  Keyfob = 0x33,
  ShSiren = 0x46,
  KeypadSh = 0x97,
  CameraPIR = 0x37,
  Pgm2W = 0x61,
  Pgm2WZone = 0x60,
  Repeater = 0x57,
  MagnetSquare = 0x35,
  PanicButton = 0x4C,
}
