<ng-template #headerName let-class="className"><div [ngClass]="class">{{trans('users.filterCriteria.name')}}</div></ng-template>
<ng-template #headerEmail let-class="className"><div [ngClass]="class">{{trans('users.filterCriteria.email')}}</div></ng-template>
<ng-template #headerPhone let-class="className"><div [ngClass]="class">{{trans('users.filterCriteria.phone_number')}}</div></ng-template>
<ng-template #headerSystem let-class="className"><div [ngClass]="class">{{trans('systems.labels.system')}}</div></ng-template>
<ng-template #headerActive let-class="className"><div [ngClass]="class">{{trans('users.filterCriteria.active')}}</div></ng-template>
<ng-template #headerRole let-class="className"><div [ngClass]="class">{{trans('users.filterCriteria.role')}}</div></ng-template>
<ng-template #headerRule let-class="className"><div [ngClass]="class">{{trans('users.filterCriteria.permissionRule')}}</div></ng-template>
<ng-template #headerCompany let-class="className"><div [ngClass]="class">{{trans('users.filterCriteria.company_name')}}</div></ng-template>
<ng-template #contentIcon let-value="item">
  <ng-container [ngSwitch]="getRoleType(value.access_permission_id)">
    <div *ngSwitchCase="PermissionRole.SuperAdmin" class="roleIcon superAdmin" inlineSVG="assets/images/super_admin_role.svg"></div>
    <div *ngSwitchCase="PermissionRole.Company" class="roleIcon company" inlineSVG="assets/images/company_role.svg"></div>
    <div *ngSwitchCase="PermissionRole.Installer" class="roleIcon" inlineSVG="assets/images/installer_role.svg"></div>
    <div *ngSwitchCase="PermissionRole.GeneralUser" class="roleIcon" inlineSVG="assets/images/general_role.svg"></div>
    <div *ngSwitchCase="PermissionRole.SystemMaster" class="roleIcon" inlineSVG="assets/images/owner_role.svg"></div>
    <div *ngSwitchCase="PermissionRole.SystemUser" class="roleIcon" inlineSVG="assets/images/general_role.svg"></div>
  </ng-container>
</ng-template>
<ng-template #contentActive let-value="item">{{value.active === 1 ? trans('general.yes') : null}}</ng-template>
<ng-template #contentRole let-value="item">{{getUserRole(value.access_permission_id)}}</ng-template>
<ng-template #contentCompany let-value="item">{{value.company}}</ng-template>
<ng-template #contentSystem let-value="item">
  <div *ngIf="value.systems && value.systems.length > 0">
    <ng-container *ngFor="let system of value.systems.slice(0, 1); let last = last">
      <a (click)="onUserSystemClick(system.id)">{{ system.name }}</a>
      <ng-container *ngIf="!last">, </ng-container>
      <ng-container *ngIf="last && value.systems.length > 1">, {{ trans('systems.labels.moreSystemsToShow').replace(':no', (value.systems.length - 1).toString()) }}</ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #deleteIcon let-value="item">
  <div *ngIf="value.id !== us.currentUser.id && canDelete" class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>
<ng-template #ruleContent let-value="value">
  {{permissionService.getPermission(value)?.name ?? ''}}
</ng-template>

<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.users.view"
  class="usersDatatable"
  [data]="loader"
  [slots]="{
    'header:name': headerName,
    'header:email': headerEmail,
    'header:phone_number': headerPhone,
    'header:system_name': headerSystem,
    'header:active': headerActive,
    'header:role': headerRole,
    'header:company': headerCompany,
    active: contentActive,
    role: contentRole,
    'header:access_permission_id': headerRule,
    access_permission_id: ruleContent,
    company: contentCompany,
    system_name: contentSystem,
    'fake:icon': contentIcon,
    'fake:delete': deleteIcon
  }"
  [columns]="visibleColumns"
  (modifyColumns)="modifyColumns.emit()"
  (rowClick)="onRowClick($event)"
  (rowContextClick)="onRightClick($event)"
  [dataSize]="this.user.users.size"
>
</app-data-table>

<app-context-menu-data-table *ngIf="contextMenu.isVisible"
  (contextVisibilityChange)="contextMenu.isVisible = $event"
  [headerText]="contextMenu.item?.name"
>
  <app-groupbox *ngIf="contextMenu.item?.systems.length > 0" [headerText]="trans('users.buttons.filterSystem') + ':'" [defaultStateCollapsed]="true" [iconStyle]="'solid'">
    <a *ngFor="let system of contextMenu.item?.systems; let even = even; let odd = odd;" [ngStyle]="{'background-color': even ? '#f6f6f6' : '#eaeaea'}" (click)="onContextFilter(system.name, 'system_name')">{{ system.name }}</a>
  </app-groupbox>
  <a *ngIf="permissionService.getPermission(contextMenu.item?.access_permission_id)?.role !== null" [ngClass]="{'context-text': true, 'context-text-last': contextMenu.item?.company_id === 0}" (click)="onContextFilter(permissionService.getPermission(contextMenu.item?.access_permission_id)?.role, 'role')">
    {{ trans('users.buttons.filterRole') }}:
    <p>{{ getUserRole(contextMenu.item?.access_permission_id) }}</p>
  </a>
  <a *ngIf="contextMenu.item?.company_id !== 0" class="context-text context-text-last" (click)="onContextFilter(contextMenu.item?.company, 'company')">
    {{ trans('users.buttons.filterCompany') }}:
    <p>{{ contextMenu.item?.company }}</p>
  </a>
</app-context-menu-data-table>
