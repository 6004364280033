<div class="page">
  <div class="loginPage">
    <div class="loginSection">
      <div class="protegusLogo">
        <img src="assets/images/protegus.svg" />
      </div>
      <span>{{ trans('general.signInToAccount') }}</span>
      <app-string-input
        [(value)]="email"
        [ngClass]="['loginInput', 'email']"
        [disabled]="state.state.hasTag('disableEmail')"
        [label]="trans('auth.form.email')"
        type="email"
        (focus)="state.send({ type: 'EMAIL_CHANGED' })"
      >
      </app-string-input>
      <app-string-input
        [(value)]="password"
        [ngClass]="['loginInput', 'email']"
        [label]="trans('auth.form.password')"
        type="password"
        [disabled]="state.state.hasTag('disablePassword')"
        (Focus)="state.send({ type: 'FOCUS' })"
        (Keydown.enter)="state.send({ type: 'LOGIN' })"
      ></app-string-input>
      <div class="loginFormBottom">
        <a routerLink="/" class="forgotPassword">{{ trans('auth.buttons.forgotPassword') }}</a>
        <div *ngIf="state.state.hasTag('loading')">{{ trans('general.loading') }}</div>
        <div *ngIf="state.state.hasTag('hasError')" class="tmperror">
          {{ state.state.context.message }}
        </div>
        <app-button class="loginButton" type="filled-secondary" (click)="state.send({ type: 'LOGIN' })"> {{ trans('auth.labels.login') }} </app-button>
      </div>
    </div>
    <div class="getAccountSection">
      <span>{{ trans('general.askIfNoAccount') }}</span>
      <a class="contactSales" routerLink="/">{{ trans('general.contactSales') }}</a>
    </div>
  </div>
</div>
