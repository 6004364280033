import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PermissionRole } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { AuthService } from 'src/app/api/auth.service';
import { LocaleService } from 'src/app/api/locale.service';
import { RequestService } from 'src/app/api/request.service';
import { UserService } from 'src/app/api/user.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { DeviceSetupTemplateService } from 'src/app/services/device-setup-template.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { LocatorService } from 'src/app/services/locator.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { customPasswordValidatorBuilder, ValidatorBuilder } from 'src/app/ui/validator';
import { countryList } from 'src/environments/countrylist';
import { autoinject } from 'src/shim';
import { UResolvable } from '../../../guards/UResolvable';

@Component({
  templateUrl: './company-user-edit.component.html',
  styleUrls: ['./company-user-edit.component.scss'],
})
export class CompanyUserEditComponent extends LanguageAware implements OnInit, OnDestroy, UResolvable<typeof CompanyUserEditComponent> {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly isNew = this.route.routeConfig.path === 'new';
  public readonly canSeePermission = this.us.currentUser.permissions.permissions.roles.view;
  public readonly canEditPermission = this.us.currentUser.permissions.permissions.roles.edit;
  public readonly canSeeDeviceSetupTemplate = this.us.currentUser.permissions.permissions.dev_setup_templates.view;
  public readonly canEditDeviceSetupTemplate = this.us.currentUser.permissions.permissions.dev_setup_templates.edit;
  public readonly canEditCompany = this.us.currentUser.permissions.permissions.company_settings.edit && this.us.currentUser.company_id === 0;

  private delayDuration = 2000;

  public email = '';
  public name = '';
  public phone = '';
  public companyId = 0;
  public country = 'US';
  public active = false;
  public password = null;
  public newTemplateSelected = 0;
  public isRequestLoading = false;
  public deviceSetupTemplateId = 0;
  public roleSupportsTemplates = false;
  public countryList: DropdownItem<string>[];
  public ruleList: DropdownItem<number>[] = [];
  public companyList: DropdownItem<number>[] = [];
  public templates: { id: number; name: string }[] = [];
  public permissionRuleId = this.permissionService.getDefaultPermissionFor(
    this.us.currentUser.company_id === 0 ? PermissionRole.GeneralUser : PermissionRole.Installer, this.us.currentUser.company_id
  )?.id ?? 0;
  public canEdit = (this.us.currentUser.permissions.permissions.users.edit || this.us.currentUser.permissions.permissions.users.create)
                && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);

  public val = new ValidatorBuilder<{
    name: string;
    email: string;
    phone: string;
    country: string;
    active: boolean;
    password: string | null;
    permissionRuleId: number;
  }>()
    .required('email', this.trans('general.required'))
    .regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, this.trans('auth.errors.invalidEmail'))
    .regex('phone', /^\+?[0-9]{6,15}$/, this.trans('validation.phoneInvalid'))
    .required('country', this.trans('general.required'))
    .inList('country', countryList, this.trans('validation.regex').replace(':attribute', this.trans('users.labels.country')))
    .required('permissionRuleId', this.trans('permissions.errors.permissionRuleIsRequired'))
    .custom('password', customPasswordValidatorBuilder({
      tooShort: () => this.trans('auth.errors.passwordTooShort'),
      noLowerCase: () => this.trans('auth.errors.passwordNoLowercase'),
      noUpperCase: () => this.trans('auth.errors.passwordNoUppercase'),
      noNumber: () => this.trans('auth.errors.passwordNoNumber'),
    }))
    .required('password', this.trans('general.required'))
    .useOnlyWhen((component: CompanyUserEditComponent) => component.isNew)
    .build().bindContext(this);
  public vp = this.val.proxify(this, ['name', 'email', 'phone', 'country', 'permissionRuleId', 'active', 'password']);


  public get shownRuleList(): DropdownItem<number>[] {
    this.ruleList.forEach(i => i.default = i.value === this.permissionRuleId);
    return this.ruleList;
  }

  private userIdChangeSubscription = this.route.params.subscribe((params) => {
    const userId = Number(params.userId);
    if (userId && !isNaN(userId)) {
      const user = this.us.users.get(userId);
      if (!user) {
        throw new Error('User not found');
      }
      this.email = user.email;
      this.name = user.name;
      this.phone = user.phone;
      this.country = user.country;
      this.active = !!user.active;
      this.permissionRuleId = user.access_permission_id;
      this.companyId = user.company_id;
      this.templates = user.device_setup_templates ? JSON.parse(JSON.stringify(user.device_setup_templates)) : [];
      const p = this.permissionService.getPermission(user.access_permission_id);
      this.roleSupportsTemplates = p?.permissions.dev_setup_templates.execute ?? false;

      this.val.validate(this);
      this.companyList.forEach(c => c.default = this.companyId === c.value);
    } else if ( this.permissionRuleId !== 0 ) {
      const p = this.permissionService.getPermission(this.permissionRuleId);
      this.roleSupportsTemplates = p?.permissions.dev_setup_templates.execute ?? false;
    }
  });

  public footerButtons = [
    { label: this.isNew ? this.trans('general.create') : this.trans('general.save'),
      labelLoading: this.isNew ? this.trans('general.creating') : this.trans('general.saving'),
      labelLoadingComplete: this.isNew ? this.trans('general.created') : this.trans('general.saved'),
      callback: () => this.onSubmit() },
    { label: this.trans('general.cancel'),  callback: () => this.router.navigate([companyRootRoute, 'users']) },
  ];

  constructor(
    cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private locale: LocaleService,
    private req: RequestService,
    public router: Router,
  ) {
    super(cdRef);
    const labeler = (code: string) => this.locale.getCountryName(code);
    this.countryList = countryList.map((code) => ({
      value: code,
      label: labeler,
    }));
    this.countryList.unshift({
      value: '',
      label: this.trans('users.labels.selectCountry'),
      default: true,
    });
    if ( this.canEditCompany ) {
      this.loadCompanies();
    }
    if ( this.canEditPermission && this.canSeePermission ) {
      this.permissionService.loadDropDownList().then(() => {
        this.permissionService.listStorage.forEach(i => i.default = i.value === this.permissionRuleId);
        const rolesToCheck: PermissionRole[] = [];
        if ( this.us.currentUser.company_id !== 0 ) {
          rolesToCheck.push(PermissionRole.SuperAdmin);
        }
        rolesToCheck.push(PermissionRole.SystemMaster);
        rolesToCheck.push(PermissionRole.SystemUser);
        this.ruleList = this.permissionService.listStorage.filter(i => !rolesToCheck.includes(i.extraParam as PermissionRole));
      });
    }
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.userIdChangeSubscription.unsubscribe();
  }

  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot): Promise<void> {
    const users = injector.get(UserService);
    const auth = injector.get(AuthService);
    const templateService = autoinject(LocatorService.injector, DeviceSetupTemplateService);
    const promises: Promise<any>[] = [];
    if (!users.currentUser) {
      promises.push(auth.loadUserData());
    }
    if (route.routeConfig.path !== 'new' && !users.users.size) {
      // promises.push(users.loadUser(userId));
      promises.push(users.loadAllUsers()); // Neturime veno user get api.
    }
    if ( users.currentUser.permissions.permissions.dev_setup_templates.edit ) {
      promises.push(templateService.loadDropDownList());
    }
    await Promise.all(promises);
  }

  public async onSubmit(): Promise<void> {
    if (! await this.val.validate(this)) {
      if (this.val.hasError('password') && !this.isNew) {
        this.vp.password = null;
        if (! await this.val.validate(this)) {return;}
      }
      return;
    }
    this.isRequestLoading = true;
    const userId = Number(this.route.snapshot.params.userId);
    try {
      if (this.isNew) {
        const res = await this.req.user
          .editUser({
            id: 0,
            email: this.email,
            name: this.name,
            phone: this.phone,
            country: this.country,
            active: this.active ? 1 : 0,
            password: this.password,
            access_permission_id: this.permissionRuleId,
            company_id: this.companyId,
            device_setup_templates: this.templates,
          })
          .toPromise();
        if (res.success) {
          const newId = 'newId' in res ? res.newId : 0;
          this.us.ingestUserLite({
            email: this.email,
            name: this.name,
            country: this.country,
            active: this.active ? 1 : 0,
            id: newId,
            access_permission_id: this.permissionRuleId,
            company_id: this.companyId,
            company: (this.companyId === 0 || this.companyList.length === 0) ? '' : this.companyList.filter(c => c.value === this.companyId)[0].label as string,
            device_setup_templates: this.templates,
          });
          [...this.us.users.values()].sort((a, b) => a.name.localeCompare(b.name));
          this.exitUserSettings();
        }
      } else {
        const res = await this.req.user
          .editUser({
            id: userId,
            name: this.name,
            email: this.email,
            phone: this.phone,
            country: this.country,
            active: this.active ? 1 : 0,
            changePassword: this.password ? 'on' : undefined,
            newPass: this.password ? this.password : undefined,
            access_permission_id: this.permissionRuleId,
            company_id: this.companyId,
            device_setup_templates: this.templates,
          })
          .toPromise();

        if (res.success) {
          const localUser = this.us.users.get(userId);
          if (localUser) {
            localUser.name = this.name;
            localUser.email = this.email;
            localUser.phone = this.phone;
            localUser.country = this.country;
            localUser.active = this.active ? 1 : 0;
            localUser.access_permission_id = this.permissionRuleId;
            localUser.company_id = this.companyId;
            localUser.device_setup_templates = this.templates;
          }
          this.exitUserSettings();
        }
      }
    } catch (e) { }
    this.isRequestLoading = false;
  }

  private async exitUserSettings() {
    this.isRequestLoading = false;
    await new Promise<void>((resolve) => setTimeout(() => {
      this.router.navigate([companyRootRoute, 'users']);
      resolve();
    }, this.delayDuration));
  }

  private async loadCompanies() {
    const companies = await requests.company.getCompanies({forList: true}).toPromise();
    this.companyList = companies.list.data.map<DropdownItem<number>>((c) => ({
      label: c.name,
      value: c.id,
    }));
  }

  public getUserRoleText(): string {
    const permission =  this.permissionService.getPermission(this.permissionRuleId);
    if ( !permission ) {
      const found = this.ruleList.find(li => li.value === this.permissionRuleId);
      if ( found ) {
        return this.trans('permissions.roles.' + found.extraParam);
      }
    }
    return this.trans('permissions.roles.' + permission?.role);
  }

  public onTemplateChanged(newTemplateId: number, oldTemplateId: number) {
    if ( newTemplateId === oldTemplateId ) { return; }
    if ( newTemplateId === 0 || this.templates.find(u => u.id === newTemplateId) ) {
      this.onTemplateDeleted(oldTemplateId);
      return;
    }
    const oldTemplate = this.templates.find(u => u.id === oldTemplateId);
    const newTemplate = this.deviceSetupTemplateService.listStorage.find(u => u.value === newTemplateId);
    oldTemplate.id = newTemplate.value;
    oldTemplate.name = newTemplate.label as string;
  }

  public onTemplateDeleted(templateId: number) {
    if ( templateId === 0 ) { return; }
    const template = this.templates.find(u => u.id === templateId);
    this.templates.splice(this.templates.indexOf(template), 1);
  }

  public onTemplateAdded(templateId: number) {
    if ( templateId === 0 ) { return; }
    try {
      if ( this.templates.find(u => u.id === templateId) ) { return; }
      const templateItem = this.deviceSetupTemplateService.listStorage.find(u => u.value === templateId);
      this.templates.push({
        id: templateItem.value,
        name: templateItem.label as string,
      });
    } finally {
      setTimeout(() => {
        this.newTemplateSelected = 0;
      }, 250);
    }
  }

  public onPermissionRuleChanged() {
    const p = this.permissionService.getPermission(this.permissionRuleId);
    this.roleSupportsTemplates = p?.permissions.dev_setup_templates.execute ?? false;
  }
}
