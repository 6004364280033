import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HomeBackgroundSelectionComponent } from '../general/home-background-selection/home-background-selection.component';
import { HomeComponent } from '../general/home/home.component';
import { QuickOutputsComponent } from '../general/quick-outputs/quick-outputs.component';
import { SystemZoneComponent } from '../general/system-zone/system-zone.component';
import { AreaListComponent } from '../system/area-list/area-list.component';
import { AreaViewComponent } from '../system/area-view/area-view.component';
import { AreaComponent } from '../system/area/area.component';
import { DeleteComponent } from '../system/delete/delete.component';
import { EventsComponent } from '../system/events/events.component';
import { PgmIconSelectComponent } from '../system/pgm-icon-select/pgm-icon-select.component';
import { RereadComponent } from '../system/reread/reread.component';
import { ResetFireSensorsComponent } from '../system/reset-fire-sensors/reset-fire-sensors.component';
import { SensorsListComponent } from '../system/sensors-list/sensors-list.component';
import { SystemConfigurationComponent } from '../system/system-configuration/system-configuration.component';
import { SystemInformationComponent } from '../system/system-information/system-information.component';
import { SystemPgmListComponent } from '../system/system-pgm-list/system-pgm-list.component';
import { SystemPgmComponent } from '../system/system-pgm/system-pgm.component';
import { SystemSensorComponent } from '../system/system-sensor/system-sensor.component';
import { SystemThermostatComponent } from '../system/system-thermostat/system-thermostat.component';
import { TransferFromListComponent } from '../system/transfer-from-list/transfer-from-list.component';
import { TransferComponent } from '../system/transfer/transfer.component';
import { UserListComponent } from '../system/user-list/user-list.component';
import { ZoneListComponent } from '../system/zone-list/zone-list.component';
import { ZoneStatusListComponent } from '../system/zone-status-list/zone-status-list.component';

import { CompanyComponent } from './company.component';
import { MobileToCompanyGuard } from './guards/mobile-to-company.guard';
import { CompanyUserEditComponent } from './pages/company-user-edit/company-user-edit.component';
import { CompanyEventsComponent } from './pages/company-events/company-events.component';
import { CompanyHomeComponent } from './pages/company-home/company-home.component';
import { CompanyLayoutComponent } from './pages/company-layout/company-layout.component';
import { CompanyLoginComponent } from './pages/company-login/company-login.component';
import { CompanyMobileHostComponent } from './pages/company-mobile-host/company-mobile-host.component';
import { CompanySystemsComponent } from './pages/company-systems/company-systems.component';
import { CompanyUsersComponent } from './pages/company-users/company-users.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { UResolver } from '../guards/uresolve.resolver';
import { CpPanicButtonComponent } from '../mobile-company/cp-panic-button/cp-panic-button.component';
import { CompanyAccountSettingsComponent, CompanyAccountSettingsLayoutComponent } from './pages/company-account-settings/company-account-settings.component';
import { CpReactionTypesComponent } from './pages/company-settings/cp-reaction-types/cp-reaction-types.component';
import { CpEventDescriptionsComponent } from './pages/company-settings/cp-event-descriptions/cp-event-descriptions.component';
import { CpEditEventComponent } from '../mobile-company/cp-edit-event/cp-edit-event.component';
import { CpEditReactionComponent } from '../mobile-company/cp-edit-reaction/cp-edit-reaction.component';
import { MobileAppComponent } from '../mobile-app.component';
import { UserComponent } from '../system/user/user.component';
import { AdvancedSettingsComponent } from '../general/advanced-settings/advanced-settings.component';
import { EventControlComponent } from '../system/event-control/event-control.component';
import { RequestAssistanceComponent } from '../system/request-assistance/request-assistance.component';
import { ChangeInstallerComponent } from '../system/change-installer/change-installer.component';
import { TransferTypeComponent } from '../system/transfer/transfer-type/transfer-type.component';
import { TransferDeviceComponent } from '../system/transfer/transfer-device/transfer-device.component';
import { OwnerPrivacyComponent } from '../system/owner-privacy/owner-privacy.component';
import { SuRegionComponent } from '../mobile-company/su-region/su-region.component';
import { CpRegionsComponent } from './pages/company-settings/cp-regions/cp-regions.component';
import { SuIpcomComponent } from '../mobile-company/su-ipcom/su-ipcom.component';
import { CpGlobalComponent } from './pages/company-settings/cp-global/cp-global.component';
import { CpIpcomsComponent } from './pages/company-settings/cp-ipcoms/cp-ipcoms.component';
import { CpTransferDeviceComponent } from './pages/company-settings/cp-transfer-device/cp-transfer-device.component';
import { CompanyListDesktopComponent } from './pages/company-list-desktop/company-list-desktop.component';
import { EditCompanyComponent } from '../sadmin/edit-company/edit-company.component';
import { PermissionListDesktopComponent } from './pages/permission-list-desktop/permission-list-desktop.component';
import { EditPermissionComponent } from '../sadmin/edit-permission/edit-permission.component';
import { TagListDesktopComponent } from './pages/tag-list-desktop/tag-list-desktop.component';
import { EditTagComponent } from '../sadmin/edit-tag/edit-tag.component';
import { PermissionGuard } from '../guards/permission.guard';
import { Routes } from 'src/api/v3/common';
import { SystemNotificationsComponent } from '../system/system-notifications/system-notifications.component';
import { CameraListComponent } from '../system/camera-list/camera-list.component';
import { CameraComponent } from '../system/camera-list/camera/camera.component';
import { ActiveSystemComponent } from '../system/active-system/active-system.component';
import { TransferSystemToCompanyComponent } from '../system/transfer-system-to-company/transfer-system-to-company.component';
import { QuickCamerasComponent } from '../general/quick-cameras/quick-cameras.component';
import { NewSystemComponent } from '../general/new-system/new-system.component';
import { newSystemRoutes } from '../app-routing.module';
import { HelpComponent } from '../general/help/help.component';
import { TicketComponent } from '../general/help/ticket/ticket.component';
import { MiddleColumnViewComponent } from './pages/middle-column-view/middle-column-view.component';
import { CpDevSetupTemplatesComponent } from './pages/company-settings/cp-dev-setup-templates/cp-dev-setup-templates.component';
import { CpDevSetupTemplateEditComponent } from './pages/company-settings/cp-dev-setup-templates/cp-dev-setup-template-edit/cp-dev-setup-template-edit.component';
import { EditIpcomInputOutputComponent } from '../mobile-company/su-ipcom/edit-ipcom-input-output/edit-ipcom-input-output.component';

const routes: Routes = [
  { path: '', redirectTo: 'systems', pathMatch: 'full' },
  {
    path: '', component: CompanyComponent,
    children: [
      { path: 'login', component: CompanyLoginComponent },
      { path: '', component: CompanyLayoutComponent, resolve: { u: UResolver },
        children: [
          { path: '', component: CompanyHomeComponent },
          { path: 'help', component: MiddleColumnViewComponent, children: [
            { path: '', component: MobileAppComponent, children: [
              { path: '', component: HelpComponent },
              { path: 'ticket', component: TicketComponent },
            ]},
          ]},
          { path: 'systems', component: CompanySystemsComponent, canActivate: [ PermissionGuard ], data: { rule: 'systems' }, children: [
            { path: 'new-system', component: CompanyMobileHostComponent, canDeactivate: [MobileToCompanyGuard], children: [
              { path: '', component: MobileAppComponent, children: [
                { path: '', component: NewSystemComponent, children: newSystemRoutes },
              ]},
            ]},
            { path: ':systemId', component: ActiveSystemComponent, canDeactivate: [MobileToCompanyGuard],
              children: [
                { path: '', component: CompanyMobileHostComponent, children: [
                  { path: '', component: MobileAppComponent, resolve: { u: UResolver }, children: [
                    { path: '', component: HomeComponent, data: { animation: 1 } },
                    { path: 'events', component: EventsComponent },
                    { path: 'event-control', component: EventControlComponent, data: { animation: 3 } },
                    { path: 'area', component: AreaViewComponent },
                    { path: 'area/zones', component: ZoneStatusListComponent },
                    { path: 'outputs', component: QuickOutputsComponent },
                    { path: 'settings', component: SystemConfigurationComponent },
                    { path: 'settings/system-configuration', redirectTo: 'settings', pathMatch: 'full' },
                    { path: 'settings/info', component: SystemInformationComponent },
                    { path: 'settings/system-notifications', component: SystemNotificationsComponent },
                    { path: 'settings/reread', component: RereadComponent },
                    { path: 'settings/home-background-selection', component: HomeBackgroundSelectionComponent },
                    { path: 'settings/sensors', component: SensorsListComponent },
                    { path: 'settings/system-sensor', component: SystemSensorComponent },
                    { path: 'settings/system-thermostat', component: SystemThermostatComponent },
                    { path: 'settings/users', component: UserListComponent },
                    { path: 'settings/user', component: UserComponent },
                    { path: 'settings/outputs', component: SystemPgmListComponent },
                    { path: 'settings/system-pgm', component: SystemPgmComponent },
                    { path: 'settings/pgm-icon-selection', component: PgmIconSelectComponent },
                    { path: 'settings/system-transfer', component: TransferComponent },
                    { path: 'settings/transfer-type', component: TransferTypeComponent },
                    { path: 'settings/transfer-device', component: TransferDeviceComponent },
                    { path: 'settings/transfer/list', component: TransferFromListComponent },
                    { path: 'settings/advanced', component: AdvancedSettingsComponent },
                    { path: 'settings/delete', component: DeleteComponent },
                    { path: 'settings/areas', component: AreaListComponent },
                    { path: 'settings/system-area', component: AreaComponent },
                    { path: 'settings/zones', component: ZoneListComponent },
                    { path: 'settings/system-zone', component: SystemZoneComponent },
                    { path: 'settings/fire-reset', component: ResetFireSensorsComponent },
                    { path: 'settings/camera-list', component: CameraListComponent },
                    { path: 'camera-list', redirectTo: 'settings/camera-list', pathMatch: 'full' },
                    { path: 'settings/add-camera', component: CameraComponent },
                    { path: 'add-camera', redirectTo: 'settings/add-camera', pathMatch: 'full' },
                    { path: 'settings/edit-camera', component: CameraComponent },
                    { path: 'request-assistance', component: RequestAssistanceComponent },
                    { path: 'request-assistance/:installer', component: RequestAssistanceComponent },
                    { path: 'change-installer/:installer', component: ChangeInstallerComponent },
                    { path: 'settings/owner-privacy', component: OwnerPrivacyComponent, data: { animation: 4 }, },
                    { path: 'settings/transfer-to-company', component: TransferSystemToCompanyComponent },
                    { path: 'quick-view-cameras', component: QuickCamerasComponent },

                    { path: 'configure/sp3', loadChildren: () => import('src/app/configurators/sp3/src/app/app.module').then(m => m.AppModule) },
                    { path: 'configure/fc', loadChildren: () => import('src/app/configurators/fc/src/app/app.module').then(m => m.AppModule) },
                    { path: 'configure/gv17', loadChildren: () => import('src/app/configurators/gv17/src/app/app.module').then(m => m.AppModule) },
                    { path: 'configure/g16', loadChildren: () => import('src/app/configurators/g16/src/app/app.module').then(m => m.AppModule) },
                  ] }
                ]},
              ]
            },
          ]},
          { path: 'events', component: CompanyEventsComponent, resolve: { u: UResolver }, canActivate: [ PermissionGuard ], data: { rule: 'events' } },
          { path: 'users', component: CompanyUsersComponent, canActivate: [ PermissionGuard ], data: { rule: 'users' }, children: [
            { path: 'new', component: CompanyUserEditComponent, resolve: { u: UResolver} },
            { path: 'edit/:userId', component: CompanyUserEditComponent, resolve: { u: UResolver} },
          ] },
          { path: 'profile', component: CompanyAccountSettingsLayoutComponent, children: [
            { path: '', component: CompanyAccountSettingsComponent },
          ] },
          { path: 'settings/reaction-types', component: CpReactionTypesComponent, canActivate: [ PermissionGuard ], data: { rule: 'reactions' }, children: [
            { path: 'new', component: CpEditReactionComponent, canActivate: [ PermissionGuard ], data: { rule: 'reactions', accessLevel: 'create' } },
            { path: 'edit/:reactionId', component: CpEditReactionComponent },
          ] },
          { path: 'settings/event-descriptions', component: CpEventDescriptionsComponent, canActivate: [ PermissionGuard ], data: { rule: 'reactions' }, children: [
            { path: 'new', component: CpEditEventComponent, canActivate: [ PermissionGuard ], data: { rule: 'reactions', accessLevel: 'create' } },
            { path: 'edit/:eventDescId', component: CpEditEventComponent },
          ] },
          { path: 'settings/regions', component: CpRegionsComponent, canActivate: [ PermissionGuard ], data: { rule: 'regions' }, children: [
            { path: 'new', component: SuRegionComponent, canActivate: [ PermissionGuard ], data: { rule: 'regions', accessLevel: 'create' } },
            { path: 'edit/:regionId', component: SuRegionComponent, canActivate: [ PermissionGuard ], data: { rule: 'regions' } },
          ], },
          { path: 'settings/ipcoms', component: CpIpcomsComponent, canActivate: [ PermissionGuard ], data: { rule: 'ipcom_settings' }, children: [
            { path: 'new', component: SuIpcomComponent, canActivate: [ PermissionGuard ], data: { rule: 'ipcom_settings', accessLevel: 'create' } },
            { path: 'edit/:ipcomId', component: SuIpcomComponent },
            { path: 'edit/:ipcomId/receiver/new', component: EditIpcomInputOutputComponent, canActivate: [ PermissionGuard ], data: { rule: 'ipcom_settings', accessLevel: 'create' } },
            { path: 'edit/:ipcomId/receiver/:receiverId', component: EditIpcomInputOutputComponent },
          ] },
          { path: 'settings/device-setup-templates', component: CpDevSetupTemplatesComponent, resolve: { u: UResolver }, canActivate: [ PermissionGuard ], data: { rule: 'dev_setup_templates', accessLevel: 'view' }, children: [
            { path: 'new', component: CpDevSetupTemplateEditComponent, resolve: { u: UResolver }, canActivate: [ PermissionGuard ], data: { rule: 'dev_setup_templates', accessLevel: 'create' } },
            { path: 'edit/:templateId', component: CpDevSetupTemplateEditComponent, resolve: { u: UResolver }, canActivate: [ PermissionGuard ], data: { rule: 'dev_setup_templates', accessLevel: 'view' } },
          ] },
          { path: 'settings/global', component: CpGlobalComponent, canActivate: [ PermissionGuard ], data: { rule: 'global_settings' } },
          { path: 'settings/transfer-device', component: CpTransferDeviceComponent, canActivate: [ PermissionGuard ], data: { rule: 'transfer_device', accessLevel: 'execute' } },
          { path: 'settings/panic-button', component: CpPanicButtonComponent, canActivate: [ PermissionGuard ], data: { rule: 'panic_settings' } },
          { path: 'settings/general', component: EditCompanyComponent, canActivate: [ PermissionGuard ], data: { rule: 'company_settings' } },
          { path: 'companies', component: CompanyListDesktopComponent, resolve: { u: UResolver }, canActivate: [ PermissionGuard ], data: { rule: 'company_settings' }, children: [
            { path: 'new', component: EditCompanyComponent, canActivate: [ PermissionGuard ], data: { rule: 'company_settings', accessLevel: 'create' } },
            { path: 'edit/:companyId', component: EditCompanyComponent },
          ]},
          { path: 'permissions', component: PermissionListDesktopComponent, resolve: { u: UResolver}, canActivate: [ PermissionGuard ], data: { rule: 'roles' }, children: [
            { path: 'new', component: EditPermissionComponent, canActivate: [ PermissionGuard ], data: { rule: 'roles', accessLevel: 'create' } },
            { path: 'edit/:permissionId', component: EditPermissionComponent },
          ]},
          { path: 'tags', component: TagListDesktopComponent, resolve: { u: UResolver}, canActivate: [ PermissionGuard ], data: { rule: 'tags' }, children: [
            { path: 'new', component: EditTagComponent, canActivate: [ PermissionGuard ], data: { rule: 'tags', accessLevel: 'create' } },
            { path: 'edit/:tagId', component: EditTagComponent },
          ]},
        ],
      },
      { path: '**', component: NotfoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyRoutingModule {}
