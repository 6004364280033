export class TDeviceUser {
  // id laukas system_persons (arba users_gv) lentelėje
  id: number;
  name: string;
  email: string;
  phone: string;
  // Eiles numeris modulyje "user_id", zonos numeris G moduliai, CG17, SP3
  zone_number: number;
  /** CG17, SP3, G. Eilutėje surinktos visos sritys.
   * '0101' reiškia, pirma nepriklauso, antrai sričiai priklauso, trečiai ne, ketvirtai taip
   */
  areas: string;
  // klaviaturos kodas CG17, SP3; "pin" GV17, Gator
  code: string;
  protegus_user_id: number;
  enable_data: number; // GV17, Gator
  schedule_no: number; // GV17, Gator
  pgms: number; // GV17, Gator
  present: boolean; // GV17, Gator
  isOwner: boolean; // visiems
  ownerPermissions: any; // visiems

  public constructor(init?: Partial<TDeviceUser>) {
    Object.assign(this, init);
  }
}
