<div class="white_round padding16">
    <div id="rfs_header">{{trans('systems.titles.beforeSensorReset')}}</div>
    <div id="rfs_big_text">{{trans('systems.labels.sensorResetWarning')}}</div>
    <div id="rfs_image">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 176.291 183">
            <g transform="translate(-91.854 -256)">
              <g transform="translate(-773.436 -870.494)">
                <path d="M1015.773,1141.4c-34.423-19.875-90.231-19.877-124.656,0-17.488,10.094-26.07,23.374-25.792,36.608l-.017,0,0,19.3,6.072-1.361c4.3,6.343,10.868,12.293,19.739,17.415,34.419,19.872,90.231,19.871,124.653,0,9.581-5.534,16.488-12.021,20.732-18.932l5.051,1.723.041-18.759C1041.6,1164.371,1032.992,1151.342,1015.773,1141.4Z" transform="translate(-0.012)" fill="#c1bfbf"/>
                <path d="M1015.759,1235.1c34.423,19.874,34.427,52.1,0,71.97s-90.232,19.873-124.653,0-34.425-52.1,0-71.97S981.336,1215.223,1015.759,1235.1Z" transform="translate(0 -73.551)" fill="#e5e5e5"/>
                <path d="M1047.049,1261.828c-30.272-17.478-79.355-17.476-109.627,0-20.721,11.962-27.25,29.008-19.614,44.179l11.622,23.172,16.516.2c26.536,11.433,62.847,11.781,89.986,1.06l17.968.208,12.406-23.969c8.179-15.337,1.771-32.71-19.256-44.85Z" transform="translate(-38.802 -95.945)" fill="#c1bfbf"/>
                <path d="M1492.356,1308.6a60.576,60.576,0,0,1,8.047,5.5l-7.422,25.765c-.842-.673-1.725-1.323-2.64-1.965l6.22-21.583a60.868,60.868,0,0,0-8.043-5.5q-2.187-1.262-4.534-2.406l1.009-3.535A77.947,77.947,0,0,1,1492.356,1308.6Z" transform="translate(-485.66 -140.027)" fill="gray"/>
                <path d="M1464.793,1323.725a60.862,60.862,0,0,1,8.043,5.5l-6.219,21.583q-2.029-1.433-4.3-2.744a70.775,70.775,0,0,0-8.554-4.149l6.5-22.6Q1462.6,1322.461,1464.793,1323.725Z" transform="translate(-461.935 -152.933)" fill="#666"/>
                <path d="M1004.821,1305.18l.8,2.88q-2.53,1.225-4.909,2.575a62.362,62.362,0,0,0-8.179,5.624l6.23,22.171c-.748.526-1.475,1.065-2.166,1.615l-7.2-25.6a61.21,61.21,0,0,1,8.2-5.614A78.452,78.452,0,0,1,1004.821,1305.18Z" transform="translate(-97.425 -140.264)" fill="gray"/>
                <path d="M1017.072,1318.57l6.507,23.134a71.39,71.39,0,0,0-8.669,4.215c-1.674.959-3.238,1.981-4.7,3.022l-6.23-22.172a62.361,62.361,0,0,1,8.179-5.624Q1014.538,1319.792,1017.072,1318.57Z" transform="translate(-108.872 -150.775)" fill="#666"/>
                <path d="M1084.264,1418.373c25.194,14.546,25.193,38.131,0,52.679s-66.045,14.547-91.241,0-25.191-38.129,0-52.677S1059.065,1403.825,1084.264,1418.373Z" transform="translate(-85.434 -220.555)" fill="#e5e5e5"/>
                <path d="M1298.676,1489.1c6.931,4,10.372,9.261,10.342,14.514l1.262,0c.033-5.445-3.531-10.894-10.709-15.039s-16.614-6.2-26.038-6.181v.728C1282.633,1483.114,1291.748,1485.1,1298.676,1489.1Z" transform="translate(-320.46 -279.379)" fill="#575757"/>
                <path d="M1304.634,1467.936c8.573,4.949,12.844,11.453,12.8,17.953l1.262,0c.036-6.686-4.345-13.385-13.164-18.476s-20.417-7.619-32-7.6v.726C1284.794,1460.519,1296.059,1462.985,1304.634,1467.936Z" transform="translate(-320.461 -261.652)" fill="#575757"/>
                <path d="M1310.695,1446.364c10.239,5.912,15.34,13.683,15.307,21.449l1.259,0c.039-7.957-5.189-15.92-15.677-21.975s-24.279-9.074-38.056-9.048v.726C1286.985,1437.5,1300.45,1440.446,1310.695,1446.364Z" transform="translate(-320.457 -243.578)" fill="#575757"/>
                <path d="M1211.042,1526.675c-8.386-4.842-21.984-4.841-30.367,0-4.37,2.522-6.442,5.862-6.256,9.165l.19,3.33.775-.012a13.515,13.515,0,0,0,5.289,5.049c8.389,4.843,21.984,4.841,30.369,0a12.945,12.945,0,0,0,5.561-5.608l.94-.011-.245-3.536C1217.133,1532.01,1215.064,1529,1211.042,1526.675Z" transform="translate(-242.651 -311.282)" fill="#c1bfbf"/>
                <path d="M1212,1543.2c8.387,4.842,8.389,12.7,0,17.534s-21.98,4.844-30.367,0-8.387-12.694,0-17.535S1203.613,1538.356,1212,1543.2Z" transform="translate(-243.382 -324.252)" fill="#dcdcdc"/>
                <path d="M1350.122,1524.348l-2.233,1.291-5.1-2.943,2.238-1.288Z" transform="translate(-374.828 -309.998)" fill="#9de368"/>
                <path d="M1127.074,1632.6a12.248,12.248,0,0,0-11.078,0,3.933,3.933,0,0,0-2.294,3.129l-.005.5h.037a4.154,4.154,0,0,0,2.261,2.769,12.226,12.226,0,0,0,11.08,0,4.178,4.178,0,0,0,2.267-2.781l.029,0v-.423A3.9,3.9,0,0,0,1127.074,1632.6Z" transform="translate(-194.994 -396.238)" fill="#050505"/>
                <path d="M1127.081,1634.513c3.06,1.767,3.061,4.629,0,6.4a12.257,12.257,0,0,1-11.081,0c-3.063-1.768-3.058-4.634,0-6.4A12.244,12.244,0,0,1,1127.081,1634.513Z" transform="translate(-194.998 -397.74)" fill="#383838"/>
                <path d="M1119.108,1636.292c-2.49,1.434-2.748,3.667-.786,5.274l1.73,1.372,1.152,0a12.013,12.013,0,0,0,5.9,0h1.145l1.7-1.36c1.978-1.607,1.732-3.849-.763-5.29A11.12,11.12,0,0,0,1119.108,1636.292Z" transform="translate(-197.604 -399.231)" fill="#eb5c44"/>
                <path d="M1135.25,1650.955c1.974,1.14,1.976,2.987,0,4.128a7.913,7.913,0,0,1-7.153,0c-1.972-1.139-1.972-2.994,0-4.127a7.892,7.892,0,0,1,7.151,0Z" transform="translate(-205.135 -411.017)" fill="#ff836f"/>
              </g>
              <path id="sd_arrow" d="M-1030.535-1690l22.535,13.5-22.535,13.5ZM-1065-1671.357v-10.928h34.465v10.928Z" transform="translate(1830 -626) rotate(-90)"/>
            </g>
          </svg>          
    </div>
    <div id="rfs_small_text">{{trans('systems.labels.sensorResetDescription')}}</div>
</div>
<div class="button" id="rfs_button" (click)="performReset()">{{trans('systems.buttons.sensors_reset')}}</div>