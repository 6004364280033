<ng-template #default_name let-class="className"><div [ngClass]="class">{{trans('events.settings.labels.nameEvent')}}</div></ng-template>
<ng-template #active let-class="className"><div [ngClass]="class">{{trans('general.enabled')}}</div></ng-template>
<ng-template #headerCid let-class="className"><div [ngClass]="class">{{trans('events.settings.labels.cidCode')}}</div></ng-template>
<ng-template #area_event let-class="className"><div [ngClass]="class">{{trans('events.settings.labels.areaEvent')}}</div></ng-template>
<ng-template #reaction_id let-class="className"><div [ngClass]="class">{{trans('events.settings.labels.reaction')}}</div></ng-template>

<ng-template #defaultNameContent let-value="value" let-item="item" let-column="column">
  <ng-container *ngIf="eventDescItemInEdit && eventDescItemInEdit.id === item.id && eventDescItemInEdit.column === column; then editName else defaultName"></ng-container>
  <ng-template #editName>
    <div class="contentEditWrap">
      <app-string-input #inputField [(value)]="eventDescItemToSave.default_name" ngClass="flex-1" size="small" autofocus></app-string-input>
      <div class='saveIcon' (click)="onSaveButtonClick()" [inlineSVG]="'assets/images/check-simple.svg'"></div>
      <div class='cancelIcon' (click)="onCancelButtonClick()" [inlineSVG]="'assets/images/close-simple.svg'"></div>
    </div>
  </ng-template>
  <ng-template #defaultName>
    <div class="flex">
      <a (click)="openEventForEditing(item)" [ngClass]="['linkText', this.idsWithError.has(item.id) ? 'hasError': '']">{{value}}</a>
      <ng-container *ngIf="getReaction(item.reaction_id).name !==''">
        <ng-container *ngTemplateOutlet="statusIcon; context: {item, column}"></ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #cidCodeContent let-item="item" let-column="column">
    <ng-container *ngIf="eventDescItemInEdit && eventDescItemInEdit.id === item.id && eventDescItemInEdit.column === column; then editCID else defaultCID"></ng-container>
    <ng-template #defaultCID>
      <div class="flex">
        <span [ngClass]="['secondaryText', this.idsWithError.has(item.id) ? 'hasError': '']">{{ item.qualifier + ' ' + item.event_code }}</span>
        <ng-container *ngIf="getReaction(item.reaction_id).name !==''">
          <ng-container *ngTemplateOutlet="statusIcon; context: {item, column}"></ng-container>
        </ng-container>
      </div>
    </ng-template>
    <ng-template #editCID>
      <div class="contentEditWrap">
        <div class="qualifierDropdown">
          <app-dropdown (valueChange)="newDropdownValue.qualifier = $event" [items]="[{label: 'R', value: 'R'},{label: 'E', value: 'E'},{label: '-', value: '-'}]" [(value)]="eventDescItemToSave.qualifier" size="small"></app-dropdown>
        </div>
        <div class="eventCodeInput">
          <app-string-input [(value)]="eventDescItemToSave.event_code" size="small" autofocus></app-string-input>
        </div>
        <div class='saveIcon' (click)="onSaveButtonClick()" [inlineSVG]="'assets/images/check-simple.svg'"></div>
        <div class='cancelIcon' (click)="onCancelButtonClick()" [inlineSVG]="'assets/images/close-simple.svg'"></div>
      </div>
    </ng-template>
</ng-template>

<ng-template #activeContent let-value="value" let-column="column" let-item="item">
  <app-name-value [showUnderline]="false" [isEnabled]="eventDescription.quickEditAllowed && getReaction(item.reaction_id).name !==''">
    <app-toggle-button class="{{ getReaction(item.reaction_id).name === '' ? 'is_disabled' : ''}}" [buttonId]="column + item.id" [initialState]="value" (toggled)="onToggleButtonClick(item, column, $event.checked)"></app-toggle-button>
  </app-name-value>
</ng-template>
<ng-template #areaEventContent let-value="value" let-column="column" let-item="item">
  <app-name-value [showUnderline]="false" [isEnabled]="eventDescription.quickEditAllowed && getReaction(item.reaction_id).name !==''">
    <app-toggle-button class="{{ getReaction(item.reaction_id).name === '' ? 'is_disabled' : ''}}" [buttonId]="column + item.id" [initialState]="value" (toggled)="onToggleButtonClick(item, column, $event.checked)"></app-toggle-button>
  </app-name-value>
</ng-template>
<ng-template #reactionIdContent let-item="item" let-column="column">
  <ng-container *ngIf="eventDescItemInEdit && eventDescItemInEdit.id === item.id && eventDescItemInEdit.column === column; then editReaction else defaultReaction"></ng-container>
  <ng-template #defaultReaction>
    <div class="flex">
      <ng-container *ngIf="getReaction(item.reaction_id)?.image_svg">
        <img class="reactionImageSvg" [src]="getReaction(item.reaction_id)?.image_png !== '' ? getReaction(item.reaction_id)?.image_png : getReaction(item.reaction_id)?.image_svg" />
      </ng-container>
      <a (click)="openReactionForEditing(item.reaction_id)" [ngClass]="['linkText', this.idsWithError.has(item.id) ? 'hasError': '']">{{ getReaction(item.reaction_id)?.default_name ? trans('reactionNames.reactions.' + getReaction(item.reaction_id)?.name) : getReaction(item.reaction_id)?.name }}</a>
      <ng-container *ngTemplateOutlet="statusIcon; context: {item, column}"></ng-container>
    </div>
  </ng-template>
  <ng-template #editReaction>
    <div class="contentEditWrap">
      <app-dropdown (valueChange)="newDropdownValue.reaction_id= $event" [items]="getReactionsList()" [(value)]="eventDescItemToSave.reaction_id" size="small"></app-dropdown>
      <div class='saveIcon' (click)="onSaveButtonClick()" [inlineSVG]="'assets/images/check-simple.svg'"></div>
      <div class='cancelIcon' (click)="onCancelButtonClick()" [inlineSVG]="'assets/images/close-simple.svg'"></div>
    </div>
  </ng-template>
</ng-template>

<ng-template #deleteIcon>
  <div class="deleteIcon" [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>
<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.reactions.view"
  [data]="loader"
  [slots]="{
    'header:default_name': default_name,
    default_name: defaultNameContent,
    'header:active': active,
    active: activeContent,
    'header:cid_code': headerCid,
    cid_code: cidCodeContent,
    'header:area_event': area_event,
    area_event: areaEventContent,
    'header:reaction_id': reaction_id,
    reaction_id: reactionIdContent,
    'fake:delete': deleteIcon
  }"
  [columns]="columns"
  (modifyColumns)="modifyColumns.emit()"
  (rowClick)="onRowClick($event)"
  [dataSize]="eventDescription.eventDescriptions.length"
>
</app-data-table>

<ng-template #statusIcon let-item="item" let-column="column" let-value="value">
  <ng-container *ngIf="eventDescription.quickEditAllowed">
    <div [ngSwitch]="true">
      <ng-container *ngSwitchCase="prevEventDescItemInEdit && prevEventDescItemInEdit.id === item.id && prevEventDescItemInEdit.column === column && prevEventDescItemInEdit.hasChanged && isLoading && !isSaved">
        <div class="spinner"></div>
      </ng-container>
      <ng-container *ngSwitchCase="prevEventDescItemInEdit && prevEventDescItemInEdit.id === item.id && prevEventDescItemInEdit.column === column && prevEventDescItemInEdit.hasChanged && isSaved">
        <div class='checkIcon' [inlineSVG]="'assets/images/check-simple.svg'"></div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class='editIcon' (click)="onEditButtonClick(item, column); $event.stopPropagation()" [inlineSVG]="'assets/images/edit.svg'"></div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
