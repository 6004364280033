import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-middle-column-view',
  templateUrl: './middle-column-view.component.html',
  styleUrls: ['./middle-column-view.component.scss']
})
export class MiddleColumnViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
