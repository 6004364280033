<h4 *ngIf="desktopView"><app-back-button [defaultBehaviour]="false" (buttonPressed)="backButtonPressed()" [style.display]="'inline-block'"></app-back-button>{{ isNew ? trans('permissions.titles.newPermission') : trans('permissions.titles.editPermission') }}</h4>
<div class="white_round padding8">
    <app-string-input type="text" label="{{trans('permissions.labels.name')}}" [(value)]="permissionToEdit.name" (valueChange)="inputChanged = true" [disabled]="!canEdit || (permissionToEdit.parent_id === null && us.currentUser.company_id !== 0)"></app-string-input>
    <app-string-input type="text" label="{{trans('permissions.labels.description')}}" [(value)]="permissionToEdit.description" (valueChange)="inputChanged = true" [disabled]="!canEdit || (permissionToEdit.parent_id === null && us.currentUser.company_id !== 0)"></app-string-input>
    <app-string-input type="text" label="{{trans('permissions.labels.role')}}" [(value)]="roleText" [disabled]="true"></app-string-input>
    <app-string-input *ngIf="permissionToEdit.company_id !== 0 && us.currentUser.company_id === 0" type="text" label="{{trans('permissions.labels.belongsToCompany')}}" [(value)]="companyText" [disabled]="true"></app-string-input>
    <ng-container *ngIf="permissionToEdit.parent_id">
        <app-dropdown *ngIf="desktopView" label="{{trans('permissions.labels.parentRule')}}" [items]="parentList" [(value)]="permissionToEdit.parent_id" (valueChange)="parentChanged()"></app-dropdown>
        <app-name-value *ngIf="!desktopView" [nameText]="trans('permissions.labels.parentRule')" (clicked)="selectParent()">{{parentName}}</app-name-value>
    </ng-container>
    <app-name-value [nameText]="trans('permissions.labels.default')" [isEnabled]="canEdit && permissionToEdit.parent_id !== null && permissionToEdit.company_id !== 0">
        <app-toggle-button [buttonId]="'cpep_tg'" [initialState]="permissionToEdit.default" (toggled)="permissionToEdit.default = $event.checked; inputChanged = true"></app-toggle-button>
    </app-name-value>
    <ng-container *ngIf="(canSeeTags || canEditTags) && permissionToEdit.parent_id">
        <app-tag-input #tags [label]="trans('permissions.labels.tags')" [name]="'taglist'" [initialTags]="permissionToEdit.tags" (tagsChanged)="inputChanged = true" [isReadOnly]="!canEditTags"></app-tag-input>
    </ng-container>
    <app-groupbox [headerText]="trans('permissions.labels.groups.general')">
        <div class="permission_group">
            <ng-container [ngTemplateOutlet]="header"></ng-container>
            <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{permissions: generalPermissions}"></ng-container>
        </div>
    </app-groupbox>
    <app-groupbox [headerText]="trans('permissions.labels.groups.system')">
        <div class="permission_group">
            <ng-container [ngTemplateOutlet]="header"></ng-container>
            <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{permissions: systemPermissions}"></ng-container>
        </div>
    </app-groupbox>
    <app-groupbox [headerText]="trans('permissions.labels.groups.advancedSettings')">
        <div class="permission_group">
            <ng-container [ngTemplateOutlet]="header"></ng-container>
            <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{permissions: advancedPermissions}"></ng-container>
        </div>
    </app-groupbox>
    <ng-component *ngIf="(inputChanged || isNew) && canEdit && desktopView">
        <ng-template appTeleport="footer">
            <app-desktop-footer [buttons]="footerButtons" [loading]="isSaving"></app-desktop-footer>
        </ng-template>
    </ng-component>
</div>

<ng-template #header>
    <div class="name">&nbsp;</div>
    <div class="view" [title]="trans('permissions.titles.view')" (click)="onPermissionHeaderClick(trans('permissions.titles.view'))">
        <div [inlineSVG]="'assets/images/eye.svg'"></div>
    </div>
    <div class="create" [title]="trans('permissions.titles.create')" (click)="onPermissionHeaderClick(trans('permissions.titles.create'))">
        <div [inlineSVG]="'assets/images/plus-square.svg'"></div>
    </div>
    <div class="edit" [title]="trans('permissions.titles.edit')" (click)="onPermissionHeaderClick(trans('permissions.titles.edit'))">
        <div [inlineSVG]="'assets/images/edit-square.svg'"></div>
    </div>
    <div class="delete" [title]="trans('permissions.titles.delete')" (click)="onPermissionHeaderClick(trans('permissions.titles.delete'))">
        <div [inlineSVG]="'assets/images/remove.svg'"></div>
    </div>
    <div class="execute" [title]="trans('permissions.titles.execute')" (click)="onPermissionHeaderClick(trans('permissions.titles.execute'))">
        <div [inlineSVG]="'assets/images/toggle-on.svg'"></div>
    </div>
</ng-template>
<ng-template #list let-list="permissions">
    <ng-container *ngFor="let iPerm of list; let i = index">
        <div class="name">{{trans('permissions.names.' + iPerm)}}</div>
        <div class="view" [title]="trans('permissions.titles.view')">
            <div class="{{canEdit ? '' : 'is_disabled'}}">
                <app-checkbox *ngIf="canEditAllOptions || parent.permissions[iPerm].view" [checkValue]="permissionToEdit.permissions[iPerm].view" [isRound]="false" (valueChanged)="permissionToEdit.permissions[iPerm].view = !$event; inputChanged = true" [isReadOnly]="!canEdit"></app-checkbox>
            </div>
        </div>
        <div class="create" [title]="trans('permissions.titles.create')" title="Create/Add">
            <div class="{{canEdit ? '' : 'is_disabled'}}">
                <app-checkbox *ngIf="canEditAllOptions || parent.permissions[iPerm].create" [checkValue]="permissionToEdit.permissions[iPerm].create" [isRound]="false" (valueChanged)="permissionToEdit.permissions[iPerm].create = !$event; inputChanged = true" [isReadOnly]="!canEdit"></app-checkbox>
            </div>
        </div>
        <div class="edit" [title]="trans('permissions.titles.edit')" title="Edit">
            <div class="{{canEdit ? '' : 'is_disabled'}}">
                <app-checkbox *ngIf="canEditAllOptions || parent.permissions[iPerm].edit" [checkValue]="permissionToEdit.permissions[iPerm].edit" [isRound]="false" (valueChanged)="permissionToEdit.permissions[iPerm].edit = !$event; inputChanged = true" [isReadOnly]="!canEdit"></app-checkbox>
            </div>
        </div>
        <div class="delete" [title]="trans('permissions.titles.delete')" title="Delete">
            <div class="{{canEdit ? '' : 'is_disabled'}}">
                <app-checkbox *ngIf="canEditAllOptions || parent.permissions[iPerm].delete" [checkValue]="permissionToEdit.permissions[iPerm].delete" [isRound]="false" (valueChanged)="permissionToEdit.permissions[iPerm].delete = !$event; inputChanged = true" [isReadOnly]="!canEdit"></app-checkbox>
            </div>
        </div>
        <div class="execute" [title]="trans('permissions.titles.execute')" title="Control">
            <div class="{{canEdit ? '' : 'is_disabled'}}">
                <app-checkbox *ngIf="canEditAllOptions || parent.permissions[iPerm].execute" [checkValue]="permissionToEdit.permissions[iPerm].execute" [isRound]="false" (valueChanged)="permissionToEdit.permissions[iPerm].execute = !$event; inputChanged = true" [isReadOnly]="!canEdit"></app-checkbox>
            </div>
        </div>
    </ng-container>
</ng-template>
