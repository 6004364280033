<div class="section_header padding_l16">{{trans('users.labels.hideEmail')}} ({{localUser.email}})</div>
<div class="white_round">
    <app-name-value [nameText]="trans('users.labels.forAdmins')">
        <app-toggle-button [buttonId]="'op_adm_e'" [initialState]="!privacySettings.show_email_admins" (toggled)="privacySettings.show_email_admins = !$event.checked"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('users.labels.forUsers')" [showUnderline]="false">
        <app-toggle-button [buttonId]="'op_usr_e'" [initialState]="!privacySettings.show_email_users" (toggled)="privacySettings.show_email_users = !$event.checked"></app-toggle-button>
    </app-name-value>
</div>
<div class="section_header padding_l16">{{trans('users.labels.hideName')}} ({{localUser.name === '' || localUser.name === null ? trans('general.elementNotSet') : localUser.name}})</div>
<div class="white_round">
    <app-name-value [nameText]="trans('users.labels.forAdmins')">
        <app-toggle-button [buttonId]="'op_adm_n'" [initialState]="!privacySettings.show_name_admins" (toggled)="privacySettings.show_name_admins = !$event.checked"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('users.labels.forUsers')" [showUnderline]="false">
        <app-toggle-button [buttonId]="'op_usr_n'" [initialState]="!privacySettings.show_name_users" (toggled)="privacySettings.show_name_users = !$event.checked"></app-toggle-button>
    </app-name-value>
</div>
<div class="section_header padding_l16">{{trans('users.labels.hidePhone')}} ({{localUser.phone === '' || localUser.phone === null ? trans('general.elementNotSet') : localUser.phone}})</div>
<div class="white_round">
    <app-name-value [nameText]="trans('users.labels.forAdmins')">
        <app-toggle-button [buttonId]="'op_adm_p'" [initialState]="!privacySettings.show_phone_admins" (toggled)="privacySettings.show_phone_admins = !$event.checked"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('users.labels.forUsers')" [showUnderline]="false">
        <app-toggle-button [buttonId]="'op_usr_p'" [initialState]="!privacySettings.show_phone_users" (toggled)="privacySettings.show_phone_users = !$event.checked"></app-toggle-button>
    </app-name-value>
</div>
<div class="separator32"></div>