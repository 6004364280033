import { Injectable } from '@angular/core';
import { TBackgroundType } from '../models/background-types';
import { SystemsService } from './systems.service';

@Injectable({
  providedIn: 'root',
})
export class BackgroundService {
  public currentType = TBackgroundType.White;

  constructor(private ss: SystemsService) {}

  public setUserSelected() {
    const body = document.querySelector('body');
    // body.style.backgroundColor = 'none';
    if (this.ss.activeSystem !== null && this.ss.activeSystem.theme.fullBackground !== '') {
      body.style.background = this.ss.activeSystem.theme.fullBackground;
    } else {
      body.style.background = 'linear-gradient(180deg, var(--home-background-start) 0%, var(--home-background-end) 100%)';
    }
    // body.style.backgroundRepeat = 'no-repeat';
    // body.style.backgroundAttachment = 'fixed';
    this.currentType = TBackgroundType.Gradient;
  }

  public setWhite() {
    const body = document.querySelector('body');
    body.style.backgroundColor = '#ffffff';
    body.style.backgroundImage = 'none';
    body.style.backgroundRepeat = 'no-repeat';
    body.style.backgroundAttachment = 'fixed';
    this.currentType = TBackgroundType.White;
  }

  public setLogin() {
    const body = document.querySelector('body');
    body.style.backgroundColor = 'none';
    body.style.backgroundImage = 'url(/assets/images/login-back.png)';
    body.style.backgroundRepeat = 'no-repeat';
    body.style.backgroundAttachment = 'stretch';
    body.style.backgroundSize = 'cover';
    this.currentType = TBackgroundType.Login;
  }

  public setGray() {
    const body = document.querySelector('body');
    body.style.backgroundColor = '#F6F6F6';
    body.style.backgroundImage = 'none';
    body.style.backgroundRepeat = 'no-repeat';
    body.style.backgroundAttachment = 'fixed';
    this.currentType = TBackgroundType.Gray;
  }
}
