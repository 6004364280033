<div id="sidemenu-content-row">
  <div *ngIf="systems.activeSystem !== null" class="sidemenu-system-item" routerLink="/home">
    <div
      *ngIf="systems.activeSystem.theme.fullBackground === ''"
      class="ssi-icon"
      [ngStyle]="{ background: 'linear-gradient(180deg, ' + systems.activeSystem.theme.startColor + ' 0%, ' + systems.activeSystem.theme.endColor + ' 100%)' }"
    >
      <div
        *ngIf="systems.activeSystem.areas.length === 1 && systems.activeSystemHelper.supports.areas()"
        [ngClass]="{ 'ssi-subicon': true, 'ssi-subicon-disarmed': isSystemDisarmed(systems.activeSystem), 'ssi-subicon-alarmed': isSystemAlarmed(systems.activeSystem) }"
      >
        <!-- <div [inlineSVG]="getStatusIconForSystem(systems.activeSystem)"></div> -->
      </div>
    </div>
    <div *ngIf="systems.activeSystem.theme.fullBackground !== ''" class="ssi-icon" [ngStyle]="{ background: systems.activeSystem.theme.fullBackground }">
      <div
        *ngIf="systems.activeSystem.areas.length === 1 && systems.activeSystemHelper.supports.areas()"
        [ngClass]="{ 'ssi-subicon': true, 'ssi-subicon-disarmed': isSystemDisarmed(systems.activeSystem), 'ssi-subicon-alarmed': isSystemAlarmed(systems.activeSystem) }"
      >
        <!-- <div [inlineSVG]="getStatusIconForSystem(systems.activeSystem)"></div> -->
      </div>
    </div>
    <div class="ssi-name">
      <div>{{ systems.activeSystem.name }}</div>
      <div [ngClass]="{ 'ssi-arrow': true, 'ssi-arrow-toggled': systemListShowing }"></div>
    </div>
  </div>
  <div id="sidemenu-system-select">
    <div class="sidemenu-system-item" (click)="addSystemClick()">
      <img class="ssi-add-system" src="assets/images/plus.svg" alt="" />
      <div class="ssi-name">{{ trans('systems.titles.addSystem') }}</div>
    </div>
  </div>
  <div *ngIf="systems.hasSystems() && systems.activeSystem !== null" class="sidemenu-buttons-container">
    <app-button-single-line-arrow class="sidemenu-button-item" *ngIf="systems.activeSystemHelper.can.see.events()" [icon]="'assets/images/events.svg'" routerLink="/events">{{
      trans('events.titles.eventList')
    }}</app-button-single-line-arrow>
    <app-button-single-line-arrow class="sidemenu-button-item" *ngIf="systems.activeSystemHelper.can.see.users()" [icon]="'assets/images/users.svg'" routerLink="/users">{{
      trans('systems.titles.users')
    }}</app-button-single-line-arrow>
    <app-button-single-line-arrow
      class="sidemenu-button-item"
      *ngIf="systems.activeSystemHelper.can.see.outputs() && systems.activeSystemHelper.hasEnabledOutputs()"
      [icon]="'assets/images/control.svg'"
      routerLink="/quick-view-outputs"
      >{{ trans('systems.menu.outputs') }}</app-button-single-line-arrow
    >
    <app-button-single-line-arrow
      class="sidemenu-button-item"
      *ngIf="systems.activeSystemHelper.can.see.sensors()"
      [icon]="'assets/images/sensors.svg'"
      routerLink="/system-sensor-list"
      >{{ trans('systems.titles.sensors') }}</app-button-single-line-arrow
    >
    <app-button-single-line-arrow class="sidemenu-button-item" [icon]="'assets/images/settings.svg'" routerLink="/system-configuration">{{
      trans('systems.menu.settings')
    }}</app-button-single-line-arrow>
    <app-button-single-line-arrow class="sidemenu-button-item" *ngIf="systems.activeSystemHelper.can.see.cameras()">{{
      trans('systems.menu.myCameras')
    }}</app-button-single-line-arrow>
  </div>
</div>
<div id="sidemenu-footer-row">
  <div id="sidemenu-footer-container">
    <div id="sidemenu-footer-separator" *ngIf="showHelp() || showVersion()"></div>
    <div class="sidemenu-help" *ngIf="showHelp()">
      <img class="help-icon" src="assets/images/help.svg" alt="" />
      <div class="help-text">{{ trans('general.help') }}</div>
    </div>
    <div class="sidemenu-version" *ngIf="showVersion()">
      <div class="version-text">v{{ g.getAppVersion() }}</div>
      <div class="version-new" *ngIf="newVersionAvailable"><app-dot></app-dot></div>
    </div>
  </div>
</div>
