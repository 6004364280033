import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';

@Component({
  selector: 'app-add-system-indirect',
  templateUrl: './add-system-indirect.component.html',
  styleUrls: ['./add-system-indirect.component.scss'],
})
export class AddSystemIndirectComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService, private r: ActivatedRoute) {
    super(cdRef);
    this.progressBar.showProgress();
    this.footerBar.showFooter(this.trans('auth.buttons.continue'), '', false, false);
    this.background.setGray();
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onContinue();
    };
  }

  ngOnInit(): void {
    this.progressBar.setProgressData(3, 7);
  }

  private onContinue() {
    localStorage.setItem('ref', 'new');
    this.es.beginComponentEdit(TEditableComponent.Area, 0, null);
    this.router.navigate(['../system-area'], { relativeTo: this.r, replaceUrl: true });
  }
}
