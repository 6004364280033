import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { EmailComponent as RegisterEmailComponent } from './pages/register/email/email.component';
import { EmailComponent } from './pages/email/email.component';
import { PasswordComponent } from './pages/password/password.component';
import { SetNameComponent } from './pages/register/set-name/set-name.component';
import { SetPasswordComponent } from './pages/register/set-password/set-password.component';
import { EmailComponent as PasswordResetEmailComponent } from './pages/reset-password/email/email.component';
import { CompanyRegisterComponent } from './pages/company-register/company-register.component';
import { CodeComponent } from './pages/register/code/code.component';
import { PasswordResetGuard } from './guards/password-reset.guard';
import { VerifyComponent } from './pages/reset-password/verify/verify.component';
import { ActivationGuard } from './guards/activation.guard';
import { VoidComponent } from './void.component';
import { SetPasswordComponent as SetResetPassword } from './pages/reset-password/set-password/set-password.component';
import { ReactivateGuard } from './guards/reactivate.guard';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { LoginEntryGuard } from './guards/login-entry.guard';
import { UResolver } from '../guards/uresolve.resolver';
import { TicketComponent } from '../general/help/ticket/ticket.component';
import { LoginLayoutComponent } from './login-layout.component';

const routes: Routes = [
  { path: '', component: LoginComponent, resolve: { u: UResolver }, children: [
    { path: '', canActivate: [ LoginEntryGuard ], component: VoidComponent },
    { path: 'email', component: EmailComponent },
    { path: 'accounts', component: AccountsComponent, resolve: { u: UResolver } },
    { path: 'password', component: PasswordComponent },
    { path: 'password-reset', component: SetResetPassword },
    { path: 'password-reset/email', component: PasswordResetEmailComponent },
    { path: 'password-reset/verify', component: VerifyComponent },
    { path: 'password-reset/:hash', canActivate: [ PasswordResetGuard ], component: VoidComponent },
    { path: 'register/activate/resend', canActivate: [ ReactivateGuard ], component: VoidComponent },
    { path: 'register/activate/:hash', canActivate: [ ActivationGuard ], component: VoidComponent },
    { path: 'register', component: RegisterEmailComponent },
    { path: 'register/code', component: CodeComponent },
    { path: 'register/password', component: SetPasswordComponent },
    { path: 'register/name', component: SetNameComponent },
    { path: 'register/company', component: CompanyRegisterComponent },
    { path: 'help', component: LoginLayoutComponent, children: [
      { path: '', component: TicketComponent },
    ]},
  ] }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LoginRoutingModule {}
