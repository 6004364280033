import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { LoginService } from '../../login.service';

@Component({
  selector: 'app-apple-login',
  templateUrl: './apple-login.component.html',
  styleUrls: ['./apple-login.component.scss']
})
export class AppleLoginComponent implements OnInit {

  constructor(public ls: LanguageService, private login: LoginService) { }

  ngOnInit(): void {
  }

  public appleClick() {
    this.login.handleAppleLogin();
  }
}
