<div class="white_round padding_sides16">
    <form (ngSubmit)="proceed()">
        <div class="separator8"></div>
        <app-string-input [(value)]="ns.deviceSettings.objectId" [label]="trans('systems.labels.deviceSetupSettings.objectId') + ' *'" (valueChange)="onObjectIdChanged()">
            <app-form-error *ngIf="val.hasError('objectId')">{{ val.getError('objectId') }}</app-form-error>
        </app-string-input>
        <ng-container *ngIf="gFamily.includes(ns.deviceSettings.hwId) && dualModeDevices.includes(ns.deviceSettings.hwId)">
            <app-name-value *ngIf="ns.selectedPanel.canBeControlledRemotely" [nameText]="trans('systems.labels.deviceSetupSettings.remoteControl')" [showUnderline]="false">
                <app-toggle-button [buttonId]="'dqs_pccode'" [initialState]="ns.deviceSettings.remoteControl" (toggled)="ns.deviceSettings.remoteControl = $event.checked"></app-toggle-button>
            </app-name-value>
            <app-string-input *ngIf="ns.selectedPanel.pcCodeNeeded" type="password" [(value)]="ns.deviceSettings.pcCode" [label]="trans('systems.labels.deviceSetupSettings.' + (ns.selectedManufacturer.name === 'PARADOX' ? 'pcCode' : 'pcCodeTexecom'))">
                <app-form-error *ngIf="val.hasError('pcCode')">{{ val.getError('pcCode') }}</app-form-error>
            </app-string-input>
        </ng-container>
        <app-string-input *ngIf="trk8Device" [(value)]="ns.deviceSettings.moduleId" [label]="trans('systems.labels.deviceSetupSettings.moduleId')" (valueChange)="onModuleIdChanged()">
            <app-form-error *ngIf="val.hasError('moduleId')">{{ val.getError('moduleId') }}</app-form-error>
        </app-string-input>
        <div class="separator8"></div>
    </form>
</div>