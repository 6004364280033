<div class="white_round padding_sides16" *ngIf="us.currentUser['settings'].togglable.length > 0">
  <ng-container *ngFor="let iField of us.currentUser['settings'].togglable; let i = index">
      <app-name-value [nameText]="iField.name" [showUnderline]="us.currentUser['settings'].textual.length > 0 || (i+1 < us.currentUser['settings'].togglable.length)" [isEnabled]="us.currentUser.permissions?.permissions.global_settings.edit">
          <app-toggle-button
            [buttonId]="'gstg_' + iField.field"
            [initialState]="iField.value"
            (toggled)="isDesktop ? toggleValueChange(iField.field, $event.checked) : saveToggle(iField.field, $event.checked)">
          </app-toggle-button>
      </app-name-value>
  </ng-container>
  <ng-container *ngFor="let iField of us.currentUser['settings'].textual; let i = index">
    <ng-container *ngIf="isDesktop; else isMobile">
      <app-string-input [label]="iField.field" [(value)]="iField.value" [maxLength]="255" (valueChange)="onInputChange(iField.field, iField.value)"></app-string-input>
    </ng-container>
    <ng-template #isMobile>
      <app-name-value [nameText]="iField.name" [showUnderline]="i+1 < us.currentUser['settings'].textual.length" (clicked)="saveTextual(iField.field, iField.name, iField.value)" [isEnabled]="us.currentUser.permissions?.permissions.global_settings.edit">{{iField.value}}</app-name-value>
    </ng-template>
  </ng-container>
</div>
<ng-container *ngIf="isDesktop && inputChanged">
  <ng-template appTeleport="footer">
    <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
  </ng-template>
</ng-container>
