<app-long-press-menu [topAdjustment]="-10" [id]="'pgm_' + pgm.id">
    <div slot="content" class="pgm-button-grid pgm_button_grid_{{buttonSize}}" (click)='onPgmClick()'>
        <div class="pgm-button-icon {{ flatButton ? 'pgm_button_icon_flat' : '' }}" *ngIf='!showTimer() && enableComponents' [inlineSVG]="pgms.getPgmIcon(pgm.icon, pgm.on, pgm.enabled)"></div>
        <circle-progress class="pgm-button-progress" *ngIf='showTimer() && enableComponents' [percent]='currentProgress' [title]='secondsLeft' [startFromZero]='false' ></circle-progress>
        <div class="pgm-button-icon {{ flatButton ? 'pgm_button_icon_flat' : '' }}" *ngIf='!enableComponents'>
            <app-loader-dots [width]="'32px'" [height]="'32px'"></app-loader-dots>
        </div>
        <div class="pgm-button-text" [style.color]="nameTextColor">{{pgm.name}}</div>
    </div>
    <div slot="menu">
        <div (click)='editOutput()'>
            <div><svg viewBox="0 0 19.064 19.064"><path d="M4.5,19.589v3.971H8.471L20.184,11.848,16.212,7.877ZM23.255,8.777a1.055,1.055,0,0,0,0-1.493L20.777,4.806a1.055,1.055,0,0,0-1.493,0L17.346,6.744l3.971,3.971Z" transform="translate(-4.5 -4.496)"/></svg></div>
            <span>{{lang.get('systems.buttons.edit')}}</span>
        </div>
        <div (click)='showResizePopup()'>
            <div><svg viewBox="0 0 23 23"><g transform="translate(-48.233 -96)"><g transform="translate(48.233 96)" fill="none" stroke="currentcolor" stroke-width="1" stroke-dasharray="2"><rect width="23" height="23" rx="7" stroke="none"/><rect x="0.5" y="0.5" width="22" height="22" rx="6.5" fill="none"/></g><rect width="13" height="7" rx="3" transform="translate(48.531 112)"/></g></svg></div>
            <span>{{lang.get('systems.buttons.resize')}}</span>
        </div>
    </div>
</app-long-press-menu>