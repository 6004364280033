<div class="grid add-system-indirect-grid">
    <div id='asi-header'>{{trans('systems.titles.systemIsIndirect')}}</div>
    <div id='asi-image'><svg viewBox="0 0 273 251" >
        <g transform="matrix(1,0,0,1,-32,-167.172)">
            <g transform="matrix(1,0,0,1,31.996,196)">
                <g transform="matrix(1,0,0,1,-98.04,-35.176)">
                    <path d="M217.394,38.5C229.66,33.941 244.594,37.862 257.343,43.581C297.393,61.546 326.57,95.649 349.752,130.393C358.145,142.974 366.118,156.108 369.273,170.152C375.698,198.737 358.941,228.33 330.442,238.7C320.751,242.23 310.103,243.677 299.442,244.484C280.48,245.92 260.848,245.35 242.042,240.031C228.628,236.231 215.942,230.114 203.768,223.358C169.779,204.48 138.739,179.966 116.388,150.02C106.588,136.885 98.288,122.078 98.053,106.92C97.818,91.762 107.69,76.537 123.939,73.96C130.657,72.896 137.763,74.003 144.696,75.144C154.903,76.825 165.249,78.593 175.34,77.859C185.764,77.096 199.323,73.023 201.223,63.071C203.231,52.388 205.145,43.053 217.394,38.5Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
                </g>
                <g transform="matrix(1,0,0,1,-275.129,-591.61)">
                    <path d="M341.394,800.374C341.394,800.374 354.494,799.584 352.954,808.767C353.118,809.721 352.646,810.677 351.789,811.127C350.932,811.577 351.767,810.445 350.445,810.676C349.975,810.757 349.497,810.781 349.022,810.747C348.379,810.702 347.768,810.45 347.281,810.027C346.794,809.604 343.596,808.506 342.165,802.484C342.165,802.484 341.103,801.172 341.147,800.833L343.357,801.778C342.934,802.696 342.876,803.741 343.197,804.7C343.518,805.659 343.268,801.838 343.697,801.9C343.781,801.914 344.846,802.443 344.846,802.443C344.846,802.443 343.546,805.221 344.526,807.243C344.526,807.243 344.154,803.819 345.251,802.643L346.81,803.553C346.81,803.553 345.289,806.423 346.321,808.764C346.321,808.764 346.049,805.171 347.136,803.773L348.548,804.859C348.548,804.859 347.12,807.688 347.991,809.633C347.991,809.633 348.102,805.449 348.852,805.133C349.388,805.584 349.866,806.101 350.275,806.67C350.684,807.239 349.297,808.723 349.903,809.806C349.903,809.806 350.275,807.02 350.582,807.006C351.222,807.951 351.682,809.006 351.94,810.118C351.879,808.948 351.512,807.815 350.878,806.83C351.588,806.964 352.242,807.303 352.762,807.805C353.282,808.307 352.569,806.499 350.576,806.385C350.205,805.842 349.772,805.344 349.286,804.9C350.363,804.808 351.447,804.934 352.474,805.269C353.501,805.604 351.415,804.036 348.922,804.596L347.529,803.461C348.763,803.271 350.019,803.284 351.249,803.499C352.479,803.714 349.975,802.413 347.154,803.199L345.639,802.292C346.805,802.035 348,801.932 349.193,801.985C350.386,802.038 347.793,801.23 345.193,802.047L344.107,801.561C344.107,801.561 345.736,801.241 346.217,801.189C346.698,801.137 346.717,801.007 346.717,801.007C345.683,800.834 344.622,800.951 343.651,801.346C342.869,801.096 342.113,800.771 341.394,800.374Z" />
                </g>
                <g transform="matrix(1,0,0,1,66.015,218.752)">
                    <ellipse cx="9.276" cy="1.567" rx="9.276" ry="1.567" style="fill-opacity:0.1;"/>
                </g>
                <g transform="matrix(1,0,0,1,-228.313,-575.057)">
                    <path d="M277.022,777.644C277.022,777.644 284.411,777.199 283.539,782.377C283.634,782.914 283.368,783.455 282.885,783.708C282.402,783.961 282.871,783.325 282.127,783.456C281.863,783.501 281.594,783.514 281.327,783.494C280.964,783.473 280.618,783.332 280.344,783.094C280.07,782.856 278.269,782.233 277.455,778.839C277.455,778.839 276.855,778.098 276.879,777.908L278.128,778.451C277.889,778.969 277.856,779.558 278.036,780.099C278.216,780.64 278.077,778.483 278.308,778.521C278.357,778.521 278.957,778.833 278.957,778.833C278.555,779.682 278.491,780.653 278.778,781.548C279.065,782.443 278.566,779.617 279.185,778.955L280.062,779.468C279.588,780.373 279.49,781.429 279.79,782.406C280.09,783.383 279.641,780.38 280.249,779.593L281.049,780.212C280.611,781.038 280.5,781.999 280.737,782.903C280.974,783.807 280.799,780.543 281.223,780.367C281.524,780.622 281.793,780.913 282.023,781.233C282.253,781.553 281.48,782.39 281.814,783.001C281.814,783.001 282.023,781.432 282.197,781.423C282.558,781.955 282.816,782.55 282.96,783.177C282.925,782.517 282.718,781.878 282.36,781.322C282.76,781.395 283.129,781.584 283.422,781.865C283.715,782.146 283.316,781.129 282.189,781.065C281.98,780.759 281.737,780.479 281.464,780.229C282.072,780.176 282.684,780.246 283.264,780.435C282.715,779.958 281.95,779.812 281.264,780.055L280.479,779.414C281.176,779.307 281.885,779.315 282.579,779.436C281.88,779.015 281.02,778.953 280.268,779.27L279.415,778.757C280.071,778.614 280.744,778.556 281.415,778.586C280.687,778.311 279.88,778.324 279.161,778.621L278.55,778.35C278.55,778.35 279.471,778.168 279.739,778.141C280.007,778.114 280.024,778.041 280.024,778.041C279.441,777.944 278.842,778.011 278.294,778.234C277.852,778.079 277.426,777.882 277.022,777.644Z" />
                </g>
                <g transform="matrix(1,0,0,1,48.569,208.213)">
                    <ellipse cx="5.23" cy="0.885" rx="5.23" ry="0.885" style="fill-opacity:0.1;"/>
                </g>
                <g transform="matrix(1,0,0,1,-715.898,-588.404)">
                    <path d="M946.38,795.968C946.38,795.968 955.3,795.425 954.255,801.668C954.364,802.317 954.043,802.967 953.462,803.275C952.881,803.583 953.446,802.811 952.547,802.968C952.227,803.022 951.902,803.038 951.578,803.014C951.14,802.985 950.724,802.813 950.394,802.525C950.064,802.237 947.885,801.49 946.91,797.39C946.91,797.39 946.188,796.497 946.218,796.269L947.718,796.91C947.43,797.535 947.391,798.247 947.609,798.9C947.827,799.553 947.658,796.95 947.946,797C948.006,797 948.728,797.375 948.728,797.375C948.244,798.394 948.167,799.56 948.511,800.634C948.855,801.708 948.258,798.304 949.005,797.506L950.064,798.122C949.489,799.214 949.37,800.491 949.733,801.671C950.096,802.851 949.551,799.227 950.276,798.271L951.237,799.021C951.237,799.021 950.265,800.946 950.857,802.279C950.857,802.279 950.936,799.433 951.446,799.216C951.81,799.524 952.134,799.875 952.413,800.261C952.692,800.647 951.748,801.661 952.16,802.398C952.16,802.398 952.413,800.498 952.622,800.498C953.057,801.141 953.37,801.859 953.545,802.616C953.504,801.82 953.256,801.049 952.825,800.379C953.308,800.47 953.753,800.701 954.107,801.042C954.461,801.383 953.977,800.154 952.616,800.075C952.365,799.706 952.071,799.367 951.742,799.065C952.475,799.002 953.214,799.087 953.914,799.315C953.249,798.739 952.324,798.565 951.495,798.859L950.547,798.085C951.387,797.955 952.243,797.963 953.08,798.109C953.917,798.255 952.211,797.368 950.291,797.909L949.259,797.293C950.053,797.119 950.866,797.049 951.678,797.084C950.801,796.754 949.83,796.769 948.963,797.127L948.224,796.796C948.224,796.796 949.335,796.576 949.66,796.543C949.985,796.51 950.002,796.418 950.002,796.418C949.299,796.301 948.577,796.381 947.917,796.649C947.385,796.47 946.87,796.242 946.38,795.968Z" />
                </g>
                <g transform="matrix(1,0,0,1,230.312,214.364)">
                    <ellipse cx="6.313" cy="1.067" rx="6.313" ry="1.067" style="fill-opacity:0.1;"/>
                </g>
                <g transform="matrix(1,0,0,1,-379.109,-60.438)">
                    <path d="M580.979,92.785L570.471,92.785L570.471,82.43L580.987,82.43L580.979,92.785ZM580.827,77.173L570.627,77.173C570.627,61.082 585.922,62.022 585.922,51.842C585.897,46.294 581.326,41.742 575.777,41.742C575.759,41.742 575.74,41.742 575.722,41.742C570.149,41.854 565.605,46.426 565.526,52L555.326,52C555.347,40.828 564.546,31.646 575.718,31.646C586.83,31.646 596.001,40.729 596.109,51.84C596.109,64.569 580.816,66.018 580.816,77.171L580.827,77.173Z"/>
                </g>
                <g transform="matrix(1,0,0,1,-271.162,-234.409)">
                    <path d="M347.506,338.244L342.662,338.244L342.662,333.459L347.506,333.459L347.506,338.244ZM347.433,331.044L342.733,331.044C342.733,323.631 349.78,324.063 349.78,319.368C349.77,316.81 347.662,314.711 345.104,314.711C345.096,314.711 345.088,314.711 345.08,314.711C342.518,314.771 340.432,316.873 340.393,319.436L335.693,319.436C335.691,319.353 335.69,319.269 335.69,319.186C335.69,314.029 339.933,309.786 345.09,309.786C350.246,309.786 354.49,314.029 354.49,319.186C354.49,319.245 354.489,319.304 354.488,319.363C354.473,325.228 347.427,325.896 347.427,331.034L347.433,331.044Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
                </g>
                <g transform="matrix(1,0,0,1,-369.504,-323.609)">
                    <path d="M482.528,460.683L477.684,460.683L477.684,455.912L482.528,455.912L482.528,460.683ZM482.458,453.49L477.757,453.49C477.757,446.074 484.804,446.509 484.804,441.814C484.794,439.258 482.687,437.16 480.131,437.16C480.122,437.16 480.113,437.16 480.104,437.16C477.531,437.207 475.43,439.317 475.393,441.89L470.693,441.89C470.691,441.807 470.69,441.723 470.69,441.64C470.69,436.483 474.933,432.24 480.09,432.24C485.246,432.24 489.49,436.483 489.49,441.64C489.49,441.699 489.489,441.758 489.488,441.817C489.5,447.682 482.458,448.352 482.458,453.49Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
                </g>
                <g transform="matrix(1,0,0,1,-438.095,-186.597)">
                    <path d="M573.424,264.828L569.913,264.828L569.913,261.377L573.424,261.377L573.424,264.828ZM573.373,259.612L569.967,259.612C569.967,254.241 575.075,254.553 575.075,251.153C575.066,249.3 573.539,247.78 571.686,247.78C571.681,247.78 571.675,247.78 571.67,247.78C569.808,247.818 568.291,249.345 568.265,251.207L564.86,251.207C564.86,251.198 564.86,251.189 564.86,251.18C564.86,247.444 567.934,244.369 571.67,244.369C575.396,244.369 578.466,247.427 578.481,251.153C578.475,255.4 573.373,255.889 573.373,259.612Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
                </g>
                <g transform="matrix(1,0,0,1,-608.926,-223.812)">
                    <path d="M804.222,306.915L802.237,306.915L802.237,304.96L804.222,304.96L804.222,306.915ZM804.192,303.969L802.256,303.969C802.256,300.933 805.142,301.11 805.142,299.187C805.142,299.187 805.142,299.187 805.142,299.187C805.142,298.145 804.284,297.287 803.242,297.287C803.235,297.287 803.227,297.287 803.22,297.287C802.168,297.309 801.311,298.171 801.295,299.223L799.37,299.223C799.37,299.218 799.37,299.213 799.37,299.208C799.37,297.096 801.108,295.358 803.22,295.358C805.326,295.358 807.062,297.087 807.07,299.193C807.079,301.579 804.193,301.864 804.193,303.968L804.192,303.969Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
                </g>
                <g transform="matrix(1,0,0,1,-690.367,-462.361)">
                    <path d="M916.011,634.385L914.026,634.385L914.026,632.43L916.011,632.43L916.011,634.385ZM915.994,631.439L914.056,631.439C914.056,628.4 916.945,628.58 916.945,626.657C916.937,625.608 916.069,624.751 915.02,624.757C913.968,624.777 913.109,625.641 913.095,626.693L911.17,626.693C911.17,626.688 911.17,626.683 911.17,626.678C911.17,624.566 912.908,622.828 915.02,622.828C917.126,622.828 918.862,624.557 918.87,626.663C918.881,629.063 915.994,629.335 915.994,631.439Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
                </g>
            </g>
            <g transform="matrix(1,0,0,1,59,189.257)">
                <g transform="matrix(1,0,0,1,-248.286,-251.733)">
                    <path d="M441.124,448.762C441.795,449.809 442.36,450.921 442.808,452.081C443.108,453.192 442.788,456.453 443.479,459.774C444.17,463.095 453.006,460.265 458.684,458.744C464.362,457.223 466.662,455.68 466.303,454.585C465.944,453.49 458.61,452.544 455.588,451.634C452.566,450.724 447.863,449.469 447.268,447.247L441.124,448.762Z" style="fill:rgb(242,204,174);fill-rule:nonzero;"/>
                </g>
                <g transform="matrix(1,0,0,1,-198.394,-152.314)">
                    <path d="M367.291,270.612C367.291,270.612 380.085,297.612 384.079,306.603C388.073,315.594 398.668,348.211 398.668,348.211L389.691,350.616C385.235,343.838 375.381,329.616 371.16,310.897C371.16,310.897 356.909,291.125 352.481,278.55L367.291,270.613L367.291,270.612Z" style="fill:rgb(21,32,88);fill-rule:nonzero;"/>
                </g>
                <g transform="matrix(1,0,0,1,-250.644,-255.602)">
                    <path d="M468.661,458.455C468.31,457.389 456.817,455.011 453.661,454.122C453.303,454.799 454.633,457.543 450.98,457.975C446.792,458.475 448.412,456.933 445.311,457.521C445.303,459.573 445.479,461.622 445.836,463.643C446.528,466.964 455.362,464.134 461.036,462.613C466.71,461.092 469.014,459.549 468.655,458.454" style="fill:rgb(21,32,88);fill-rule:nonzero;"/>
                </g>
                <g >
                    <g transform="matrix(1,0,0,1,33.312,189.789)">
                        <rect x="0" y="0" width="87.457" height="9.776" style="fill:rgb(0,39,0);"/>
                    </g>
                    <g transform="matrix(1,0,0,1,33.312,189.789)">
                        <rect x="0" y="0" width="87.457" height="2.283" style="fill:rgb(12,30,0);"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-42.891,-251.407)">
                        <path d="M163.661,446.669L163.661,462.318C163.661,462.617 163.415,462.863 163.116,462.863L76.749,462.863C76.45,462.863 76.204,462.617 76.204,462.318L76.204,446.669L77.95,446.669L77.95,449.25L78.038,449.25C78.841,448.217 80.077,447.612 81.385,447.612C82.693,447.612 83.929,448.217 84.732,449.25L86.519,449.25C87.322,448.217 88.558,447.612 89.866,447.612C91.174,447.612 92.41,448.217 93.213,449.25L95,449.25C95.803,448.217 97.039,447.612 98.347,447.612C99.655,447.612 100.891,448.217 101.694,449.25L103.481,449.25C104.284,448.217 105.52,447.612 106.828,447.612C108.136,447.612 109.372,448.217 110.175,449.25L111.962,449.25C112.765,448.217 114.001,447.612 115.309,447.612C116.617,447.612 117.853,448.217 118.656,449.25L120.443,449.25C121.246,448.217 122.482,447.612 123.79,447.612C125.098,447.612 126.334,448.217 127.137,449.25L128.924,449.25C129.726,448.217 130.963,447.612 132.271,447.612C133.579,447.612 134.816,448.217 135.618,449.25L137.4,449.25C138.203,448.217 139.439,447.612 140.747,447.612C142.055,447.612 143.291,448.217 144.094,449.25L145.881,449.25C146.683,448.217 147.92,447.612 149.228,447.612C150.536,447.612 151.773,448.217 152.575,449.25L154.362,449.25C155.165,448.217 156.401,447.612 157.709,447.612C159.017,447.612 160.253,448.217 161.056,449.25L161.911,449.25L161.911,446.669L163.661,446.669Z" style="fill:rgb(88,163,61);"/>
                    </g>
                    <path d="M150.952,12.617C150.949,5.697 145.255,0.003 138.335,0L12.617,0C5.697,0.003 0.003,5.697 0,12.617L0,178.606C0.003,185.526 5.697,191.22 12.617,191.223L138.335,191.223C145.255,191.22 150.949,185.526 150.952,178.606L150.952,12.617Z" style="fill:rgb(244,247,248);"/>
                    <g transform="matrix(1,0,0,1,-10.461,-11.336)">
                        <path d="M153.542,22.294C153.541,21.113 152.57,20.142 151.389,20.141L20.739,20.141C19.558,20.142 18.587,21.113 18.586,22.294L18.586,192.548C18.587,193.729 19.558,194.7 20.739,194.701L151.389,194.701C152.57,194.7 153.541,193.729 153.542,192.548L153.542,22.294Z" style="fill:rgb(231,235,238);"/>
                    </g>
                    <g transform="matrix(1,0,0,1,14.058,14.595)">
                        <rect x="0" y="0" width="122.212" height="162.599" style="fill:rgb(211,217,215);"/>
                    </g>
                    <g transform="matrix(1,0,0,1,14.533,14.865)">
                        <rect x="0" y="0" width="121.141" height="161.856" style="fill:rgb(234,237,230);"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-179.795,-111.591)">
                        <path d="M326.716,199.469C326.716,198.806 326.171,198.261 325.508,198.261L320.646,198.261C319.983,198.261 319.438,198.806 319.438,199.469L319.438,214.069C319.438,214.732 319.983,215.277 320.646,215.277L325.508,215.277C326.171,215.277 326.716,214.732 326.716,214.069L326.716,199.469Z" style="fill:rgb(74,80,90);"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-35.51,-75.761)">
                        <path d="M63.09,140.74L63.09,134.6L63.224,134.6L67.309,139.3L67.309,134.6L67.914,134.6L67.914,140.738L67.776,140.738L63.724,136.091L63.724,140.738L63.09,140.74Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-43.649,-75.761)">
                        <path d="M77.55,134.6L81.067,134.6L81.067,135.2L78.167,135.2L78.167,137.123L81.046,137.123L81.046,137.723L78.163,137.723L78.163,140.135L81.042,140.135L81.042,140.735L77.55,140.735L77.55,134.6Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-48.758,-75.761)">
                        <path d="M86.627,135.2L86.627,134.6L89.99,134.6L89.99,135.2L88.621,135.2L88.621,140.737L88,140.737L88,135.2L86.627,135.2Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-53.781,-75.761)">
                        <path d="M95.552,134.6L96.182,134.6L97.436,139.023L99.2,134.6L99.327,134.6L101.066,139.023L102.341,134.6L102.967,134.6L101.2,140.74L101.086,140.74L99.263,136.1L97.406,140.744L97.291,140.744L95.552,134.6Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-64.439,-75.562)">
                        <path d="M117.682,134.249C118.554,134.226 119.399,134.562 120.016,135.179C120.626,135.784 120.97,136.609 120.97,137.469C120.97,139.239 119.514,140.695 117.744,140.695C117.74,140.695 117.736,140.695 117.732,140.695C116.87,140.712 116.038,140.374 115.432,139.76C114.82,139.169 114.479,138.35 114.493,137.499C114.489,136.924 114.637,136.358 114.921,135.859C115.482,134.862 116.543,134.245 117.687,134.249L117.682,134.249ZM117.709,134.849C117.255,134.849 116.809,134.972 116.418,135.204C116.02,135.431 115.691,135.762 115.465,136.16C115.234,136.569 115.116,137.031 115.122,137.5C115.122,137.506 115.122,137.513 115.122,137.519C115.122,138.935 116.287,140.1 117.703,140.1C117.705,140.1 117.707,140.1 117.709,140.1C118.173,140.103 118.629,139.983 119.031,139.753C119.428,139.53 119.757,139.202 119.98,138.805C120.209,138.397 120.327,137.937 120.321,137.469C120.327,137.006 120.209,136.549 119.98,136.147C119.751,135.751 119.42,135.424 119.021,135.201C118.623,134.968 118.17,134.845 117.709,134.845L117.709,134.849Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-74.394,-75.761)">
                        <path d="M132.174,134.6L133.4,134.6C133.863,134.584 134.327,134.612 134.785,134.683C135.133,134.757 135.447,134.946 135.674,135.22C135.906,135.506 136.027,135.865 136.016,136.233C136.022,136.543 135.94,136.849 135.78,137.114C135.62,137.37 135.384,137.57 135.106,137.688C134.72,137.835 134.309,137.903 133.896,137.888L136.103,140.738L135.344,140.738L133.136,137.888L132.789,137.888L132.789,140.738L132.176,140.738L132.174,134.6ZM132.787,135.2L132.787,137.287L133.844,137.295C134.152,137.31 134.459,137.27 134.753,137.178C134.942,137.106 135.104,136.975 135.213,136.804C135.323,136.633 135.381,136.433 135.378,136.23C135.38,136.031 135.322,135.835 135.211,135.669C135.106,135.506 134.952,135.379 134.772,135.308C134.48,135.22 134.176,135.184 133.872,135.199L132.79,135.199L132.787,135.2Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-81.029,-75.761)">
                        <path d="M143.962,134.6L144.588,134.6L144.588,136.9L147.042,134.6L147.866,134.6L144.918,137.354L148.096,140.738L147.279,140.738L144.589,137.879L144.589,140.738L143.963,140.738L143.962,134.6Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-35.505,-95.073)">
                        <path d="M63.081,175.052L63.081,168.914L64.354,168.914C65.028,168.883 65.703,168.958 66.354,169.135C66.93,169.327 67.424,169.71 67.754,170.22C68.098,170.771 68.272,171.411 68.254,172.06C68.266,172.627 68.128,173.187 67.854,173.683C67.617,174.122 67.258,174.481 66.819,174.718C66.259,174.968 65.648,175.083 65.035,175.052L63.081,175.052ZM63.669,174.472L64.378,174.472C64.971,174.499 65.564,174.445 66.143,174.314C66.588,174.176 66.973,173.89 67.232,173.503C67.508,173.077 67.647,172.578 67.632,172.071C67.648,171.533 67.497,171.004 67.202,170.554C66.916,170.134 66.491,169.829 66.002,169.691C65.379,169.549 64.74,169.49 64.102,169.516L63.668,169.516L63.669,174.472Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-42.994,-95.073)">
                        <path d="M79.436,168.914L82.298,175.052L81.636,175.052L80.671,173.032L78.026,173.032L77.07,175.052L76.386,175.052L79.286,168.914L79.436,168.914ZM79.358,170.214L78.307,172.436L80.407,172.436L79.358,170.214Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-51.046,-95.073)">
                        <path d="M90.693,169.515L90.693,168.915L94.056,168.915L94.056,169.515L92.688,169.515L92.688,175.052L92.062,175.052L92.062,169.515L90.693,169.515Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-55.817,-95.073)">
                        <path d="M102.219,168.914L105.081,175.052L104.418,175.052L103.453,173.032L100.81,173.032L99.854,175.052L99.17,175.052L102.07,168.914L102.219,168.914ZM102.142,170.214L101.09,172.436L103.19,172.436L102.142,170.214Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-35.499,-114.663)">
                        <path d="M63.071,203.719L64.293,203.719C64.768,203.702 65.243,203.733 65.712,203.811C66.049,203.889 66.351,204.076 66.571,204.343C66.8,204.629 66.918,204.989 66.905,205.355C66.919,205.72 66.804,206.079 66.58,206.367C66.349,206.642 66.032,206.83 65.68,206.903C65.164,206.988 64.641,207.021 64.119,207.003L63.681,207.003L63.681,209.857L63.068,209.857L63.071,203.719ZM63.684,204.319L63.684,206.4L64.723,206.412C65.035,206.427 65.347,206.388 65.645,206.297C65.833,206.226 65.994,206.096 66.104,205.927C66.215,205.757 66.273,205.557 66.271,205.354C66.272,205.154 66.214,204.957 66.104,204.79C66,204.625 65.846,204.498 65.664,204.426C65.374,204.339 65.071,204.302 64.769,204.318L63.684,204.319Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-41.667,-114.464)">
                        <path d="M77.222,203.366C78.094,203.343 78.939,203.68 79.556,204.297C80.166,204.902 80.51,205.727 80.51,206.587C80.51,208.357 79.054,209.813 77.284,209.813C77.28,209.813 77.276,209.813 77.272,209.813C76.41,209.83 75.578,209.492 74.972,208.878C74.36,208.287 74.019,207.467 74.033,206.616C74.029,206.041 74.177,205.476 74.461,204.976C75.022,203.979 76.083,203.362 77.227,203.366L77.222,203.366ZM77.249,203.966C76.323,203.975 75.468,204.475 75.005,205.277C74.774,205.686 74.656,206.149 74.662,206.618C74.662,206.624 74.662,206.631 74.662,206.637C74.662,208.053 75.827,209.218 77.243,209.218C77.245,209.218 77.247,209.218 77.249,209.218C77.713,209.221 78.169,209.101 78.571,208.871C78.968,208.648 79.297,208.32 79.52,207.923C79.749,207.515 79.867,207.055 79.861,206.587C79.867,206.124 79.749,205.667 79.52,205.265C79.291,204.869 78.96,204.542 78.561,204.319C78.163,204.086 77.71,203.964 77.249,203.964L77.249,203.966Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-51.133,-114.663)">
                        <path d="M90.846,203.719L91.476,203.719L92.73,208.142L94.49,203.719L94.618,203.719L96.357,208.142L97.632,203.719L98.257,203.719L96.494,209.857L96.38,209.857L94.559,205.213L92.7,209.857L92.585,209.857L90.846,203.719Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-62.146,-114.663)">
                        <path d="M110.413,203.719L113.93,203.719L113.93,204.319L111.03,204.319L111.03,206.242L113.9,206.242L113.9,206.842L111.021,206.842L111.021,209.254L113.9,209.254L113.9,209.854L110.408,209.854L110.408,203.716L110.413,203.719Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-68.082,-114.663)">
                        <path d="M120.959,203.719L122.182,203.719C122.645,203.703 123.109,203.732 123.567,203.803C123.915,203.877 124.229,204.066 124.456,204.34C124.688,204.626 124.809,204.985 124.798,205.353C124.804,205.663 124.723,205.969 124.562,206.235C124.401,206.491 124.166,206.691 123.888,206.809C123.502,206.956 123.091,207.024 122.678,207.009L124.885,209.859L124.126,209.859L121.918,207.009L121.571,207.009L121.571,209.859L120.958,209.859L120.958,203.721L120.959,203.719ZM121.572,204.319L121.572,206.405L122.629,206.413C122.937,206.428 123.244,206.388 123.538,206.296C123.727,206.224 123.889,206.093 123.998,205.923C124.108,205.752 124.165,205.552 124.163,205.349C124.165,205.15 124.107,204.954 123.996,204.788C123.891,204.625 123.737,204.499 123.557,204.427C123.265,204.34 122.961,204.303 122.657,204.319L121.572,204.319Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-34.72,-133.704)">
                        <path d="M61.687,238.15L61.687,237.55L65.05,237.55L65.05,238.15L63.681,238.15L63.681,243.687L63.055,243.687L63.055,238.15L61.687,238.15Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-40.232,-133.704)">
                        <path d="M71.48,237.549L72.7,237.549C73.163,237.533 73.627,237.561 74.085,237.632C74.433,237.706 74.747,237.895 74.974,238.169C75.206,238.455 75.327,238.814 75.316,239.182C75.323,239.492 75.241,239.798 75.08,240.064C74.92,240.32 74.684,240.521 74.406,240.638C74.02,240.785 73.609,240.853 73.196,240.838L75.403,243.688L74.644,243.688L72.437,240.838L72.09,240.838L72.09,243.688L71.48,243.688L71.48,237.549ZM72.093,238.149L72.093,240.235L73.15,240.244C73.458,240.259 73.765,240.219 74.059,240.127C74.248,240.055 74.409,239.924 74.518,239.754C74.628,239.583 74.685,239.383 74.683,239.18C74.685,238.981 74.626,238.785 74.516,238.619C74.411,238.456 74.257,238.33 74.077,238.258C73.785,238.171 73.481,238.135 73.177,238.15L72.093,238.149Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-46.518,-133.506)">
                        <path d="M85.841,237.2C86.713,237.177 87.558,237.513 88.175,238.13C88.785,238.735 89.129,239.56 89.129,240.42C89.129,242.19 87.673,243.646 85.903,243.646C85.899,243.646 85.895,243.646 85.891,243.646C85.029,243.664 84.196,243.325 83.591,242.711C82.978,242.12 82.638,241.301 82.652,240.45C82.648,239.875 82.796,239.309 83.08,238.81C83.641,237.813 84.702,237.196 85.846,237.2L85.841,237.2ZM85.868,237.8C84.942,237.809 84.087,238.309 83.624,239.111C83.393,239.52 83.275,239.982 83.281,240.451C83.281,240.457 83.281,240.464 83.281,240.47C83.281,241.886 84.446,243.051 85.862,243.051C85.864,243.051 85.866,243.051 85.868,243.051C86.332,243.054 86.788,242.935 87.191,242.705C87.588,242.482 87.917,242.154 88.14,241.757C88.369,241.349 88.487,240.889 88.481,240.421C88.487,239.958 88.369,239.501 88.14,239.099C87.911,238.703 87.58,238.376 87.181,238.153C86.783,237.92 86.33,237.798 85.869,237.798L85.868,237.8Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-56.457,-133.704)">
                        <path d="M100.3,237.549L100.913,237.549L100.913,241.249C100.907,241.523 100.915,241.798 100.938,242.071C100.959,242.287 101.032,242.496 101.151,242.678C101.283,242.857 101.46,242.999 101.664,243.087C101.882,243.194 102.121,243.251 102.364,243.252C102.566,243.252 102.765,243.208 102.948,243.123C103.129,243.041 103.289,242.918 103.415,242.764C103.545,242.601 103.639,242.411 103.69,242.209C103.744,241.892 103.765,241.57 103.753,241.249L103.753,237.549L104.366,237.549L104.366,241.249C104.379,241.697 104.325,242.145 104.206,242.578C104.088,242.932 103.863,243.24 103.563,243.461C103.229,243.716 102.817,243.849 102.397,243.837C101.947,243.85 101.503,243.726 101.124,243.482C100.784,243.263 100.533,242.93 100.415,242.543C100.321,242.118 100.284,241.683 100.303,241.249L100.303,237.549L100.3,237.549Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g transform="matrix(1,0,0,1,-63.655,-133.704)">
                        <path d="M113.094,237.549L114.304,237.549C114.686,237.531 115.068,237.59 115.427,237.722C115.682,237.833 115.898,238.018 116.046,238.254C116.198,238.493 116.277,238.771 116.273,239.054C116.276,239.316 116.206,239.573 116.073,239.798C115.932,240.03 115.729,240.219 115.488,240.343C115.755,240.424 116.005,240.553 116.225,240.725C116.396,240.87 116.532,241.051 116.625,241.255C116.722,241.466 116.77,241.697 116.768,241.929C116.776,242.404 116.579,242.859 116.228,243.179C115.831,243.533 115.31,243.717 114.778,243.69L113.092,243.69L113.094,237.549ZM113.694,238.149L113.694,240.114L114.045,240.114C114.363,240.129 114.682,240.089 114.986,239.995C115.18,239.921 115.346,239.79 115.463,239.619C115.579,239.452 115.641,239.253 115.639,239.049C115.649,238.794 115.538,238.548 115.339,238.388C115.062,238.205 114.731,238.121 114.4,238.148L113.694,238.149ZM113.694,240.732L113.694,243.085L114.455,243.085C114.789,243.102 115.123,243.058 115.441,242.954C115.644,242.869 115.818,242.726 115.941,242.543C116.22,242.139 116.188,241.593 115.864,241.225C115.669,241.012 115.412,240.866 115.129,240.808C114.769,240.746 114.403,240.721 114.038,240.733L113.694,240.732Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1618" transform="matrix(1,0,0,1,-69.951,-133.704)">
                        <path d="M124.28,237.549L124.894,237.549L124.894,243.094L127.255,243.094L127.255,243.687L124.28,243.687L124.28,237.549Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1619" transform="matrix(1,0,0,1,-75.039,-133.704)">
                        <path d="M133.32,237.549L136.837,237.549L136.837,238.149L133.937,238.149L133.937,240.072L136.816,240.072L136.816,240.672L133.937,240.672L133.937,243.083L136.816,243.083L136.816,243.683L133.32,243.683L133.32,237.549Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Rectangle_2326" transform="matrix(1,0,0,1,17.65,59.009)">
                        <rect x="0" y="0" width="6.213" height="6.124" style="fill:rgb(88,102,107);"/>
                    </g>
                    <g id="Rectangle_2327" transform="matrix(1,0,0,1,17.65,74.009)">
                        <rect x="0" y="0" width="6.213" height="6.124" style="fill:rgb(88,102,107);"/>
                    </g>
                    <g id="Rectangle_2328" transform="matrix(1,0,0,1,17.65,89.056)">
                        <rect x="0" y="0" width="6.213" height="6.124" style="fill:rgb(88,102,107);"/>
                    </g>
                    <g id="Rectangle_2329" transform="matrix(1,0,0,1,17.65,104.103)">
                        <rect x="0" y="0" width="6.213" height="6.124" style="fill:rgb(88,102,107);"/>
                    </g>
                    <g id="Rectangle_2330" transform="matrix(1,0,0,1,141.34,86.67)">
                        <rect x="0" y="0" width="3.635" height="17.02" style="fill:rgb(48,48,45);"/>
                    </g>
                    <g id="Path_1620" transform="matrix(1,0,0,1,-43.788,-221.459)">
                        <path d="M80.528,393.46L80.961,393.46L79.574,396.512L79.19,396.512L77.8,393.46L78.234,393.46L79.389,395.939L80.528,393.46Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1621" transform="matrix(1,0,0,1,-43.917,-212.467)">
                        <path d="M79.309,379.18L78.027,379.18L78.027,378.78L79.309,378.78L79.309,377.489L79.709,377.489L79.709,378.78L81,378.78L81,379.18L79.714,379.18L79.714,380.471L79.314,380.471L79.309,379.18Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1622" transform="matrix(1,0,0,1,-43.031,-206.636)">
                        <path d="M80.8,370.785L76.452,370.785L76.452,369.885C76.43,369.408 76.483,368.931 76.609,368.47C76.745,368.063 77.016,367.715 77.377,367.482C77.766,367.238 78.218,367.114 78.677,367.126C79.078,367.118 79.474,367.215 79.826,367.408C80.137,367.576 80.391,367.83 80.559,368.141C80.737,368.536 80.82,368.967 80.8,369.4L80.8,370.785ZM80.39,370.368L80.39,369.868C80.409,369.449 80.371,369.028 80.278,368.619C80.18,368.304 79.978,368.032 79.704,367.848C79.402,367.654 79.049,367.556 78.69,367.567C78.309,367.555 77.934,367.659 77.615,367.867C77.318,368.069 77.102,368.369 77.004,368.715C76.904,369.156 76.862,369.607 76.88,370.059L76.88,370.366L80.389,370.366L80.39,370.368Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1623" transform="matrix(1,0,0,1,-42.89,-200.341)">
                        <path d="M77.118,355.94L77.381,356.283C77.143,356.462 76.951,356.696 76.821,356.964C76.692,357.237 76.627,357.535 76.63,357.837C76.628,358.174 76.714,358.506 76.88,358.8C77.037,359.086 77.269,359.323 77.551,359.487C77.839,359.652 78.166,359.736 78.498,359.731C78.997,359.744 79.478,359.546 79.824,359.186C80.18,358.819 80.372,358.322 80.356,357.811C80.364,357.22 80.1,356.657 79.641,356.284L79.901,355.941C80.176,356.156 80.396,356.434 80.541,356.752C80.695,357.093 80.772,357.463 80.767,357.837C80.8,358.521 80.52,359.183 80.008,359.637C79.578,360.006 79.026,360.203 78.46,360.189C77.855,360.202 77.271,359.959 76.854,359.52C76.42,359.072 76.185,358.467 76.203,357.844C76.197,357.464 76.28,357.087 76.444,356.744C76.599,356.425 76.831,356.151 77.119,355.944L77.118,355.94Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1624" transform="matrix(1,0,0,1,-54.975,-221.487)">
                        <path d="M100.4,393.511L100.833,393.511L99.446,396.563L99.063,396.563L97.67,393.511L98.103,393.511L99.258,395.99L100.4,393.511Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Rectangle_2331" transform="matrix(1,0,0,1,44.641,166.55)">
                        <rect x="0" y="0" width="0.36" height="1.625" style="fill:rgb(44,41,39);"/>
                    </g>
                    <g id="Path_1625" transform="matrix(1,0,0,1,-54.218,-206.642)">
                        <path d="M100.673,370.8L96.328,370.8L96.328,369.9C96.306,369.423 96.359,368.946 96.484,368.485C96.62,368.078 96.891,367.73 97.252,367.497C97.641,367.253 98.093,367.129 98.552,367.141C98.953,367.133 99.349,367.23 99.701,367.423C100.011,367.591 100.266,367.846 100.434,368.156C100.611,368.553 100.692,368.985 100.67,369.419L100.673,370.8ZM100.263,370.383L100.263,369.883C100.282,369.464 100.244,369.043 100.151,368.634C100.054,368.319 99.851,368.047 99.577,367.863C99.275,367.669 98.922,367.57 98.563,367.582C98.182,367.57 97.807,367.674 97.488,367.882C97.191,368.084 96.975,368.385 96.877,368.73C96.777,369.171 96.735,369.622 96.753,370.074L96.753,370.381L100.263,370.383Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1626" transform="matrix(1,0,0,1,-54.077,-200.347)">
                        <path d="M96.993,355.951L97.256,356.294C97.018,356.473 96.826,356.707 96.696,356.975C96.567,357.248 96.502,357.546 96.505,357.848C96.503,358.185 96.589,358.517 96.755,358.811C96.912,359.097 97.144,359.334 97.426,359.498C97.714,359.662 98.041,359.746 98.373,359.741C98.871,359.755 99.353,359.558 99.7,359.2C100.056,358.833 100.248,358.336 100.232,357.825C100.24,357.234 99.976,356.671 99.517,356.298L99.777,355.955C100.052,356.17 100.272,356.448 100.417,356.766C100.571,357.107 100.649,357.478 100.643,357.852C100.676,358.536 100.396,359.198 99.884,359.652C99.454,360.021 98.902,360.218 98.336,360.205C97.731,360.218 97.148,359.975 96.731,359.536C96.296,359.089 96.061,358.483 96.08,357.86C96.074,357.48 96.156,357.103 96.32,356.76C96.475,356.441 96.707,356.166 96.995,355.96L96.993,355.951Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1627" transform="matrix(1,0,0,1,-66.338,-221.487)">
                        <path d="M120.592,393.511L121.025,393.511L119.638,396.563L119.255,396.563L117.861,393.511L118.295,393.511L119.45,395.99L120.593,393.511L120.592,393.511Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1628" transform="matrix(1,0,0,1,-65.44,-210.887)">
                        <path d="M117.182,374.677L117.445,375.02C117.207,375.199 117.015,375.433 116.885,375.701C116.756,375.974 116.691,376.272 116.694,376.574C116.692,376.911 116.778,377.243 116.944,377.537C117.101,377.823 117.333,378.06 117.615,378.224C117.903,378.389 118.23,378.473 118.562,378.468C119.061,378.481 119.543,378.283 119.889,377.923C120.245,377.556 120.437,377.059 120.421,376.548C120.429,375.957 120.165,375.394 119.706,375.021L119.966,374.678C120.241,374.893 120.461,375.171 120.606,375.489C120.76,375.83 120.837,376.2 120.832,376.574C120.865,377.258 120.585,377.92 120.073,378.374C119.643,378.743 119.091,378.94 118.525,378.926C117.92,378.939 117.337,378.696 116.92,378.257C116.485,377.81 116.25,377.205 116.268,376.581C116.262,376.201 116.345,375.824 116.509,375.481C116.664,375.162 116.896,374.887 117.184,374.681L117.182,374.677Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1629" transform="matrix(1,0,0,1,-65.581,-207.053)">
                        <path d="M116.516,369.971L116.516,369.537L120.442,369.537L120.442,367.865L120.861,367.865L120.861,369.971L116.515,369.971L116.516,369.971Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1630" transform="matrix(1,0,0,1,-65.581,-202.399)">
                        <path d="M116.516,362.523L116.516,362.08L118.147,362.08L116.516,360.343L116.516,359.76L118.466,361.847L120.866,359.597L120.866,360.175L118.842,362.075L120.866,362.075L120.866,362.518L116.516,362.523Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1631" transform="matrix(1,0,0,1,-77.701,-221.487)">
                        <path d="M140.781,393.511L141.214,393.511L139.827,396.563L139.444,396.563L138.05,393.511L138.484,393.511L139.639,395.99L140.781,393.511Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1632" transform="matrix(1,0,0,1,-76.944,-211.385)">
                        <path d="M141.051,379.222L136.705,379.222L136.705,378.322C136.683,377.845 136.736,377.368 136.862,376.907C136.998,376.5 137.269,376.151 137.63,375.919C138.019,375.675 138.471,375.551 138.93,375.563C139.331,375.554 139.727,375.652 140.079,375.845C140.39,376.012 140.645,376.267 140.812,376.578C140.989,376.975 141.07,377.407 141.048,377.841L141.051,379.222ZM140.641,378.805L140.641,378.305C140.66,377.886 140.622,377.465 140.529,377.056C140.431,376.741 140.229,376.469 139.955,376.285C139.654,376.089 139.299,375.99 138.94,376C138.559,375.988 138.184,376.092 137.865,376.3C137.568,376.502 137.352,376.803 137.254,377.148C137.154,377.589 137.112,378.04 137.13,378.492L137.13,378.799L140.641,378.805Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1633" transform="matrix(1,0,0,1,-76.944,-205.406)">
                        <path d="M136.705,366.966L141.051,364.939L141.051,365.408L139.621,366.091L139.621,367.963L141.051,368.64L141.051,369.125L136.705,367.072L136.705,366.966ZM137.628,367.021L139.201,367.766L139.201,366.28L137.628,367.021Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1634" transform="matrix(1,0,0,1,-76.944,-202.028)">
                        <path d="M137.13,361.319L136.7,361.319L136.7,358.938L137.125,358.938L137.125,359.907L141.045,359.907L141.045,360.35L137.125,360.35L137.13,361.319Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1635" transform="matrix(1,0,0,1,-76.944,-196.326)">
                        <path d="M136.705,350.835L141.051,348.808L141.051,349.277L139.621,349.96L139.621,351.832L141.051,352.509L141.051,352.994L136.705,350.941L136.705,350.835ZM137.628,350.89L139.201,351.635L139.201,350.149L137.628,350.89Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1636" transform="matrix(1,0,0,1,-88.712,-221.459)">
                        <path d="M160.343,393.46L160.776,393.46L159.389,396.512L159.006,396.512L157.613,393.46L158.046,393.46L159.201,395.939L160.343,393.46Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1637" transform="matrix(1,0,0,1,-87.955,-211.174)">
                        <path d="M156.267,377.214L160.613,375.187L160.613,375.656L159.183,376.339L159.183,378.211L160.613,378.888L160.613,379.372L156.267,377.319L156.267,377.213L156.267,377.214ZM157.19,377.269L158.763,378.014L158.763,376.528L157.19,377.269Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1638" transform="matrix(1,0,0,1,-87.814,-206.879)">
                        <path d="M156.017,368.171L156.017,368.083L159.048,368.083L159.048,367.557L159.462,367.557L159.462,368.083L160.473,368.083L160.473,368.514L159.462,368.514L159.462,370.594L156.017,368.171ZM159.048,368.514L157.223,368.514L159.048,369.801L159.048,368.514Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1639" transform="matrix(1,0,0,1,-87.814,-202.62)">
                        <path d="M158.088,360.81C158.198,360.561 158.37,360.343 158.588,360.179C158.776,360.052 158.999,359.986 159.226,359.989C159.552,359.986 159.867,360.102 160.112,360.317C160.435,360.608 160.609,361.032 160.582,361.466C160.61,361.873 160.46,362.272 160.17,362.559C159.926,362.795 159.601,362.928 159.262,362.931C159.029,362.934 158.801,362.866 158.608,362.736C158.382,362.567 158.204,362.343 158.09,362.086C157.997,362.278 157.856,362.443 157.68,362.564C157.524,362.668 157.341,362.724 157.154,362.724C156.957,362.722 156.765,362.663 156.6,362.554C156.418,362.439 156.271,362.276 156.175,362.084C156.071,361.884 156.018,361.661 156.02,361.436C156.018,361.217 156.072,361 156.176,360.807C156.274,360.621 156.421,360.464 156.601,360.354C156.77,360.248 156.965,360.191 157.165,360.19C157.352,360.187 157.536,360.241 157.691,360.345C157.86,360.465 157.997,360.625 158.091,360.81L158.088,360.81ZM156.44,361.459C156.432,361.676 156.508,361.889 156.652,362.052C156.776,362.2 156.959,362.286 157.152,362.287C157.355,362.283 157.546,362.19 157.676,362.034C157.933,361.766 157.985,361.359 157.805,361.034C157.737,360.909 157.637,360.806 157.514,360.734C157.402,360.665 157.274,360.628 157.143,360.626C156.959,360.629 156.785,360.706 156.658,360.839C156.501,361.005 156.42,361.23 156.436,361.458L156.44,361.459ZM158.307,361.438C158.299,361.717 158.404,361.988 158.598,362.19C158.771,362.382 159.018,362.491 159.276,362.49C159.436,362.49 159.592,362.445 159.728,362.36C159.87,362.273 159.984,362.147 160.056,361.997C160.136,361.828 160.175,361.643 160.171,361.457C160.185,361.178 160.083,360.904 159.89,360.702C159.719,360.522 159.482,360.421 159.234,360.422C158.986,360.425 158.751,360.535 158.589,360.722C158.404,360.913 158.302,361.171 158.307,361.437L158.307,361.438Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1640" transform="matrix(1,0,0,1,-87.955,-198.378)">
                        <path d="M156.267,352.565L156.681,352.565L156.681,354.109L157.866,354.326C157.814,354.16 157.786,353.988 157.783,353.815C157.772,353.447 157.917,353.092 158.183,352.837C158.456,352.577 158.824,352.438 159.201,352.452C159.476,352.45 159.747,352.519 159.987,352.652C160.216,352.775 160.406,352.961 160.535,353.188C160.666,353.427 160.731,353.697 160.726,353.969C160.735,354.296 160.62,354.615 160.403,354.861C160.186,355.104 159.888,355.258 159.565,355.295L159.565,354.849C159.712,354.824 159.851,354.766 159.972,354.679C160.081,354.594 160.167,354.485 160.225,354.36C160.29,354.23 160.324,354.087 160.325,353.942C160.327,353.662 160.209,353.394 160.001,353.207C159.782,353.001 159.49,352.891 159.19,352.9C158.924,352.889 158.665,352.99 158.476,353.178C158.287,353.377 158.188,353.645 158.201,353.919C158.217,354.242 158.296,354.558 158.434,354.85L156.274,354.45L156.274,352.568L156.267,352.565Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1641" transform="matrix(1,0,0,1,-99.898,-221.459)">
                        <path d="M180.218,393.46L180.651,393.46L179.263,396.512L178.88,396.512L177.487,393.46L177.921,393.46L179.075,395.939L180.218,393.46Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1642" transform="matrix(1,0,0,1,-99.141,-212.703)">
                        <path d="M176.142,380.506L176.142,379.649C176.13,379.379 176.172,379.109 176.265,378.855C176.343,378.674 176.474,378.521 176.641,378.416C176.81,378.309 177.005,378.253 177.205,378.255C177.39,378.253 177.573,378.302 177.732,378.396C177.896,378.496 178.03,378.639 178.118,378.81C178.175,378.621 178.267,378.445 178.388,378.289C178.491,378.168 178.619,378.071 178.764,378.005C178.914,377.937 179.077,377.903 179.241,377.905C179.577,377.9 179.9,378.039 180.126,378.288C180.377,378.569 180.507,378.938 180.488,379.314L180.488,380.507L176.142,380.506ZM176.567,380.081L177.958,380.081L177.958,379.832C177.969,379.607 177.94,379.381 177.874,379.166C177.822,379.029 177.729,378.912 177.608,378.829C177.49,378.748 177.351,378.705 177.208,378.705C177.028,378.697 176.854,378.774 176.74,378.914C176.611,379.11 176.551,379.345 176.57,379.579L176.567,380.081ZM178.396,380.081L180.062,380.081L180.062,379.542C180.074,379.305 180.043,379.068 179.969,378.842C179.909,378.697 179.808,378.573 179.678,378.486C179.392,378.289 179.006,378.311 178.745,378.541C178.593,378.678 178.488,378.86 178.445,379.061C178.401,379.316 178.383,379.575 178.392,379.834L178.392,380.08L178.396,380.081Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1643" transform="matrix(1,0,0,1,-99.001,-208.169)">
                        <path d="M175.892,370.462L175.892,370.374L178.923,370.374L178.923,369.848L179.337,369.848L179.337,370.374L180.347,370.374L180.347,370.805L179.337,370.805L179.337,372.885L175.892,370.462ZM178.923,370.805L177.1,370.805L178.926,372.093L178.923,370.805Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1644" transform="matrix(1,0,0,1,-99.001,-203.909)">
                        <path d="M177.963,363.1C178.073,362.851 178.245,362.633 178.463,362.469C178.651,362.342 178.874,362.276 179.101,362.279C179.427,362.276 179.743,362.392 179.988,362.607C180.311,362.898 180.485,363.322 180.458,363.756C180.486,364.163 180.336,364.562 180.046,364.849C179.802,365.085 179.477,365.218 179.138,365.221C178.905,365.223 178.677,365.153 178.484,365.021C178.258,364.852 178.08,364.629 177.966,364.371C177.873,364.563 177.731,364.728 177.555,364.849C177.399,364.953 177.216,365.009 177.029,365.009C176.832,365.007 176.64,364.948 176.475,364.839C176.293,364.724 176.146,364.561 176.05,364.369C175.947,364.169 175.893,363.946 175.895,363.721C175.893,363.502 175.947,363.285 176.051,363.092C176.15,362.906 176.297,362.749 176.477,362.639C176.646,362.533 176.841,362.476 177.041,362.475C177.228,362.472 177.412,362.526 177.567,362.63C177.736,362.75 177.873,362.91 177.967,363.095L177.963,363.1ZM176.315,363.749C176.307,363.966 176.383,364.178 176.526,364.342C176.65,364.49 176.833,364.576 177.026,364.577C177.229,364.573 177.421,364.481 177.551,364.324C177.807,364.055 177.86,363.649 177.68,363.324C177.612,363.199 177.511,363.096 177.389,363.024C177.277,362.955 177.149,362.918 177.018,362.916C176.834,362.919 176.66,362.996 176.533,363.129C176.376,363.295 176.296,363.52 176.312,363.748L176.315,363.749ZM178.182,363.728C178.175,364.007 178.279,364.278 178.473,364.48C178.646,364.672 178.893,364.781 179.151,364.78C179.483,364.778 179.786,364.586 179.931,364.287C180.011,364.118 180.05,363.933 180.046,363.747C180.06,363.468 179.958,363.194 179.765,362.992C179.594,362.812 179.357,362.711 179.109,362.712C178.861,362.715 178.625,362.824 178.463,363.012C178.278,363.203 178.176,363.461 178.181,363.727L178.182,363.728Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1645" transform="matrix(1,0,0,1,-99.141,-199.667)">
                        <path d="M176.142,354.856L176.556,354.856L176.556,356.4L177.741,356.617C177.689,356.451 177.661,356.279 177.658,356.106C177.647,355.738 177.792,355.383 178.058,355.128C178.331,354.868 178.699,354.729 179.076,354.743C179.351,354.741 179.622,354.81 179.862,354.943C180.092,355.066 180.282,355.252 180.41,355.479C180.541,355.718 180.607,355.987 180.601,356.26C180.61,356.587 180.495,356.906 180.278,357.152C180.062,357.395 179.763,357.549 179.44,357.586L179.44,357.14C179.587,357.115 179.727,357.057 179.848,356.97C179.956,356.885 180.043,356.776 180.1,356.651C180.165,356.521 180.199,356.378 180.2,356.233C180.202,355.953 180.084,355.685 179.876,355.498C179.657,355.292 179.365,355.182 179.065,355.191C178.799,355.181 178.54,355.281 178.351,355.469C178.162,355.668 178.063,355.936 178.076,356.21C178.092,356.533 178.171,356.849 178.309,357.141L176.149,356.741L176.149,354.859L176.142,354.856Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1646" transform="matrix(1,0,0,1,-111.439,-221.487)">
                        <path d="M200.72,393.511L201.153,393.511L199.766,396.563L199.383,396.563L197.99,393.511L198.423,393.511L199.578,395.99L200.72,393.511Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1647" transform="matrix(1,0,0,1,-110.541,-210.887)">
                        <path d="M197.311,374.677L197.574,375.02C197.336,375.199 197.144,375.433 197.014,375.701C196.886,375.974 196.821,376.272 196.824,376.574C196.822,376.911 196.908,377.243 197.074,377.537C197.231,377.823 197.463,378.061 197.745,378.224C198.033,378.389 198.36,378.473 198.692,378.468C199.191,378.48 199.672,378.283 200.018,377.923C200.374,377.556 200.566,377.059 200.55,376.548C200.558,375.957 200.294,375.394 199.835,375.021L200.095,374.678C200.37,374.893 200.589,375.172 200.735,375.489C200.889,375.83 200.966,376.2 200.961,376.574C200.994,377.258 200.714,377.92 200.202,378.374C199.772,378.743 199.22,378.94 198.654,378.926C198.049,378.939 197.465,378.696 197.048,378.257C196.613,377.81 196.378,377.204 196.397,376.581C196.391,376.201 196.473,375.824 196.637,375.481C196.792,375.162 197.024,374.887 197.312,374.681L197.311,374.677Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1648" transform="matrix(1,0,0,1,-110.541,-204.116)">
                        <path d="M196.4,364.965C196.384,364.348 196.622,363.75 197.059,363.313C197.488,362.881 198.072,362.638 198.68,362.638C199.934,362.638 200.965,363.67 200.965,364.923C200.965,364.926 200.965,364.928 200.965,364.93C200.978,365.542 200.739,366.132 200.303,366.562C199.885,366.995 199.305,367.236 198.703,367.227C198.296,367.231 197.896,367.127 197.542,366.927C196.836,366.529 196.399,365.778 196.402,364.968L196.4,364.965ZM196.822,364.946C196.829,365.602 197.183,366.208 197.751,366.535C198.04,366.698 198.368,366.782 198.7,366.778C198.705,366.778 198.709,366.778 198.714,366.778C199.717,366.778 200.542,365.953 200.542,364.95C200.542,364.949 200.542,364.948 200.542,364.947C200.544,364.619 200.46,364.295 200.297,364.01C200.139,363.729 199.907,363.497 199.626,363.339C199.337,363.177 199.011,363.094 198.68,363.098C198.352,363.093 198.029,363.177 197.744,363.339C197.464,363.501 197.232,363.736 197.074,364.018C196.909,364.3 196.823,364.621 196.823,364.947L196.822,364.946Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1649" transform="matrix(1,0,0,1,-110.682,-196.935)">
                        <path d="M200.991,354.793L196.645,354.171L196.645,354.1L200.211,352.333L196.645,350.584L196.645,350.514L200.991,349.888L200.991,350.314L197.883,350.743L200.991,352.28L200.991,352.391L197.859,353.946L200.991,354.373L200.991,354.793Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1650" transform="matrix(1,0,0,1,-122.978,-221.487)">
                        <path d="M221.223,393.511L221.656,393.511L220.269,396.563L219.885,396.563L218.492,393.511L218.926,393.511L220.081,395.99L221.224,393.511L221.223,393.511Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Rectangle_2332" transform="matrix(1,0,0,1,94.926,167.465)">
                        <rect x="0" y="0" width="4.346" height="0.434" style="fill:rgb(44,41,39);"/>
                    </g>
                    <g id="Path_1651" transform="matrix(1,0,0,1,-122.22,-209.936)">
                        <path d="M221.492,376.4L217.146,376.4L217.146,376.306L220.478,373.414L217.146,373.414L217.146,372.986L221.492,372.986L221.492,373.086L218.202,375.955L221.492,375.955L221.492,376.4Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1652" transform="matrix(1,0,0,1,-134.165,-221.459)">
                        <path d="M241.1,393.46L241.533,393.46L240.146,396.512L239.763,396.512L238.37,393.46L238.803,393.46L239.958,395.939L241.1,393.46Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1653" transform="matrix(1,0,0,1,-133.266,-210.421)">
                        <path d="M236.771,376.166C236.755,375.549 236.993,374.951 237.43,374.514C237.859,374.082 238.443,373.839 239.051,373.839C240.305,373.839 241.336,374.871 241.336,376.124C241.336,376.127 241.336,376.129 241.336,376.131C241.349,376.743 241.109,377.333 240.674,377.763C240.256,378.196 239.676,378.437 239.074,378.428C238.667,378.432 238.267,378.328 237.913,378.128C237.207,377.731 236.77,376.98 236.773,376.17L236.771,376.166ZM237.193,376.147C237.199,376.803 237.553,377.408 238.121,377.736C238.41,377.899 238.738,377.983 239.07,377.979C239.075,377.979 239.079,377.979 239.084,377.979C240.087,377.979 240.912,377.154 240.912,376.151C240.912,376.15 240.912,376.148 240.912,376.147C240.914,375.819 240.83,375.496 240.667,375.211C240.509,374.93 240.277,374.698 239.996,374.54C239.707,374.378 239.381,374.295 239.05,374.299C238.722,374.294 238.399,374.378 238.114,374.54C237.834,374.702 237.602,374.937 237.444,375.219C237.28,375.501 237.193,375.821 237.193,376.147Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1654" transform="matrix(1,0,0,1,-133.409,-205.575)">
                        <path d="M237.024,368.116L237.024,367.682L239.647,367.682C239.841,367.687 240.035,367.681 240.229,367.665C240.382,367.65 240.53,367.598 240.659,367.514C240.786,367.421 240.886,367.295 240.948,367.151C241.024,366.997 241.064,366.828 241.065,366.657C241.065,366.514 241.034,366.373 240.974,366.243C240.916,366.115 240.829,366.001 240.72,365.912C240.604,365.82 240.47,365.753 240.327,365.717C240.102,365.679 239.875,365.664 239.647,365.673L237.024,365.673L237.024,365.238L239.647,365.238C239.965,365.229 240.282,365.267 240.588,365.352C240.838,365.435 241.057,365.594 241.213,365.807C241.394,366.044 241.488,366.335 241.479,366.633C241.488,366.951 241.4,367.265 241.228,367.533C241.072,367.773 240.837,367.95 240.563,368.033C240.262,368.1 239.955,368.127 239.647,368.113L237.024,368.113L237.024,368.116Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1655" transform="matrix(1,0,0,1,-133.409,-201.687)">
                        <path d="M237.45,360.714L237.025,360.714L237.025,358.333L237.45,358.333L237.45,359.302L241.37,359.302L241.37,359.745L237.45,359.745L237.45,360.714Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1656" transform="matrix(1,0,0,1,-133.409,-199.021)">
                        <path d="M237.024,354.449L237.024,353.595L241.37,353.595L241.37,354.026L237.45,354.026L237.45,354.708L237.024,354.449Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1657" transform="matrix(1,0,0,1,-145.527,-221.487)">
                        <path d="M261.285,393.511L261.718,393.511L260.331,396.563L259.948,396.563L258.555,393.511L258.988,393.511L260.143,395.99L261.286,393.511L261.285,393.511Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1658" transform="matrix(1,0,0,1,-144.631,-210.449)">
                        <path d="M256.963,376.217C256.947,375.6 257.185,375.002 257.622,374.565C258.051,374.133 258.635,373.889 259.244,373.889C260.497,373.889 261.528,374.92 261.528,376.173C261.528,376.176 261.528,376.179 261.528,376.182C261.541,376.794 261.302,377.384 260.866,377.814C260.448,378.248 259.868,378.489 259.266,378.479C258.859,378.483 258.459,378.379 258.105,378.179C257.399,377.782 256.962,377.031 256.964,376.221L256.963,376.217ZM257.386,376.198C257.386,376.52 257.472,376.836 257.637,377.113C257.798,377.395 258.032,377.628 258.314,377.788C258.603,377.951 258.931,378.035 259.263,378.031C259.268,378.031 259.272,378.031 259.277,378.031C260.28,378.031 261.105,377.206 261.105,376.203C261.105,376.202 261.105,376.2 261.105,376.199C261.107,375.871 261.022,375.548 260.859,375.263C260.701,374.982 260.469,374.749 260.188,374.591C259.899,374.429 259.573,374.346 259.242,374.35C258.914,374.346 258.591,374.429 258.306,374.591C258.026,374.753 257.795,374.988 257.637,375.27C257.472,375.552 257.385,375.873 257.386,376.199L257.386,376.198Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1659" transform="matrix(1,0,0,1,-144.772,-205.603)">
                        <path d="M257.212,368.167L257.212,367.733L259.835,367.733C260.029,367.737 260.223,367.731 260.417,367.715C260.57,367.7 260.718,367.649 260.847,367.565C260.974,367.471 261.073,367.346 261.136,367.201C261.212,367.048 261.252,366.879 261.253,366.708C261.253,366.565 261.222,366.424 261.162,366.294C261.104,366.166 261.017,366.052 260.908,365.963C260.793,365.871 260.658,365.804 260.515,365.768C260.291,365.729 260.063,365.714 259.835,365.723L257.212,365.723L257.212,365.289L259.835,365.289C260.153,365.28 260.47,365.318 260.776,365.403C261.026,365.487 261.244,365.646 261.401,365.858C261.582,366.095 261.676,366.386 261.667,366.684C261.676,367.002 261.588,367.316 261.416,367.584C261.26,367.824 261.025,368.001 260.751,368.084C260.45,368.15 260.143,368.177 259.835,368.164L257.212,368.167Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1660" transform="matrix(1,0,0,1,-144.772,-201.716)">
                        <path d="M257.638,360.765L257.213,360.765L257.213,358.384L257.638,358.384L257.638,359.353L261.558,359.353L261.558,359.796L257.638,359.796L257.638,360.765Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1661" transform="matrix(1,0,0,1,-144.631,-197.558)">
                        <path d="M258.431,353.428L258.431,353.845C258.036,353.847 257.656,353.692 257.375,353.415C257.103,353.147 256.953,352.779 256.962,352.397C256.962,352.383 256.961,352.37 256.961,352.356C256.961,351.643 257.548,351.056 258.261,351.056C258.262,351.056 258.262,351.056 258.262,351.056C258.504,351.055 258.743,351.116 258.955,351.233C259.272,351.428 259.565,351.658 259.831,351.919L261,353L261,351L261.42,351L261.42,353.952L259.626,352.292C259.381,352.048 259.113,351.828 258.826,351.635C258.659,351.536 258.469,351.483 258.275,351.483C258.036,351.481 257.806,351.58 257.644,351.756C257.469,351.934 257.374,352.176 257.38,352.425C257.373,352.684 257.473,352.935 257.658,353.117C257.871,353.313 258.148,353.425 258.438,353.432L258.431,353.428Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1662" transform="matrix(1,0,0,1,-46.984,-186.919)">
                        <path d="M83.475,332.644L83.475,332.1L86.275,332.1L86.275,332.376L83.748,332.376L83.748,332.816L83.475,332.644Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1663" transform="matrix(1,0,0,1,-46.894,-183.16)">
                        <path d="M84.783,327.322C84.493,327.334 84.203,327.294 83.927,327.205C83.746,327.14 83.588,327.023 83.471,326.87C83.264,326.578 83.264,326.185 83.471,325.893C83.591,325.734 83.755,325.612 83.942,325.543C84.212,325.447 84.497,325.404 84.783,325.414C85.067,325.404 85.351,325.447 85.62,325.539C85.807,325.608 85.971,325.73 86.091,325.889C86.195,326.033 86.251,326.206 86.251,326.383C86.253,326.556 86.199,326.725 86.099,326.866C85.982,327.02 85.822,327.137 85.64,327.201C85.364,327.293 85.074,327.334 84.783,327.322ZM84.783,327.058C85.024,327.067 85.266,327.04 85.499,326.976C85.645,326.93 85.774,326.842 85.869,326.723C86.034,326.52 86.034,326.226 85.869,326.023C85.771,325.908 85.643,325.823 85.499,325.778C85.266,325.705 85.023,325.671 84.779,325.678C84.546,325.671 84.313,325.703 84.09,325.771C83.936,325.816 83.8,325.905 83.697,326.028C83.617,326.127 83.573,326.251 83.572,326.378C83.572,326.505 83.616,326.628 83.697,326.725C83.79,326.844 83.918,326.931 84.063,326.974C84.298,327.041 84.542,327.07 84.786,327.058L84.783,327.058Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1664" transform="matrix(1,0,0,1,-49.079,-181.49)">
                        <rect x="87.2" y="322.448" width="0.234" height="1.047" style="fill:rgb(44,41,39);"/>
                    </g>
                    <g id="Path_1665" transform="matrix(1,0,0,1,-46.984,-179.7)">
                        <path d="M83.475,319.818L83.475,319.269L86.275,319.269L86.275,319.545L83.748,319.545L83.748,319.985L83.475,319.818Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1666" transform="matrix(1,0,0,1,-46.894,-175.951)">
                        <path d="M84.646,313.136C84.717,312.975 84.826,312.835 84.965,312.727C85.087,312.646 85.231,312.604 85.378,312.606C85.587,312.604 85.789,312.679 85.946,312.816C86.153,313.004 86.264,313.276 86.246,313.555C86.263,313.816 86.167,314.071 85.981,314.255C85.824,314.406 85.615,314.491 85.397,314.492C85.247,314.495 85.1,314.451 84.976,314.367C84.83,314.26 84.714,314.116 84.641,313.95C84.581,314.074 84.49,314.18 84.377,314.258C84.276,314.323 84.158,314.358 84.038,314.358C83.912,314.357 83.789,314.319 83.684,314.249C83.567,314.176 83.471,314.072 83.408,313.949C83.341,313.82 83.307,313.677 83.308,313.532C83.306,313.392 83.341,313.254 83.408,313.132C83.47,313.011 83.564,312.91 83.68,312.84C83.789,312.774 83.914,312.739 84.042,312.74C84.163,312.738 84.281,312.773 84.381,312.84C84.491,312.917 84.582,313.018 84.646,313.136ZM83.583,313.552C83.579,313.692 83.627,313.828 83.719,313.934C83.8,314.031 83.92,314.086 84.046,314.086C84.176,314.083 84.299,314.023 84.381,313.922C84.48,313.82 84.535,313.683 84.533,313.541C84.532,313.45 84.51,313.36 84.467,313.28C84.422,313.199 84.356,313.131 84.276,313.085C84.205,313.04 84.123,313.016 84.039,313.015C83.921,313.018 83.809,313.069 83.728,313.155C83.626,313.261 83.574,313.406 83.583,313.553L83.583,313.552ZM84.783,313.537C84.778,313.718 84.845,313.893 84.97,314.024C85.082,314.146 85.24,314.217 85.406,314.219C85.509,314.22 85.61,314.191 85.698,314.137C85.787,314.079 85.859,313.999 85.908,313.904C85.96,313.794 85.985,313.674 85.982,313.553C85.992,313.374 85.927,313.199 85.803,313.07C85.693,312.953 85.539,312.887 85.379,312.887C85.221,312.89 85.071,312.959 84.966,313.078C84.848,313.201 84.783,313.366 84.786,313.537L84.783,313.537Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1667" transform="matrix(1,0,0,1,-46.984,-171.039)">
                        <path d="M83.475,306.357L83.475,306.057L85.635,305.131L83.475,304.189L83.475,303.889L86.275,305.107L86.275,305.165L83.475,306.357Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1668" transform="matrix(1,0,0,1,-51.905,-185.937)">
                        <path d="M93.687,332.257C93.397,332.269 93.107,332.229 92.831,332.14C92.65,332.075 92.492,331.959 92.376,331.806C92.169,331.514 92.169,331.121 92.376,330.829C92.496,330.669 92.659,330.547 92.847,330.478C93.117,330.383 93.402,330.34 93.688,330.35C93.972,330.34 94.256,330.383 94.525,330.475C94.712,330.544 94.876,330.665 94.996,330.825C95.1,330.969 95.156,331.142 95.156,331.319C95.156,331.492 95.101,331.66 95,331.8C94.883,331.954 94.723,332.07 94.541,332.135C94.266,332.227 93.977,332.268 93.687,332.257ZM93.687,331.992C93.928,332.001 94.17,331.974 94.403,331.91C94.549,331.865 94.677,331.776 94.772,331.657C94.937,331.454 94.937,331.16 94.772,330.957C94.675,330.842 94.546,330.757 94.402,330.712C94.17,330.637 93.926,330.601 93.682,330.607C93.451,330.601 93.221,330.632 93,330.7C92.846,330.744 92.709,330.834 92.607,330.957C92.527,331.056 92.483,331.18 92.482,331.307C92.482,331.433 92.526,331.556 92.607,331.653C92.7,331.772 92.828,331.859 92.973,331.902C93.205,331.971 93.448,332.001 93.69,331.992L93.687,331.992Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1669" transform="matrix(1,0,0,1,-55.058,-184.718)">
                        <path d="M97.82,328.418C97.82,328.416 97.82,328.414 97.82,328.411C97.82,328.282 97.926,328.175 98.056,328.175C98.185,328.175 98.292,328.282 98.292,328.411C98.292,328.475 98.266,328.537 98.22,328.581C98.176,328.626 98.115,328.651 98.053,328.651C97.99,328.651 97.929,328.626 97.885,328.581C97.842,328.538 97.818,328.479 97.82,328.418Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1670" transform="matrix(1,0,0,1,-51.996,-181.832)">
                        <path d="M92.38,323.127L92.644,323.127L92.644,324.127L93.407,324.263C93.374,324.157 93.356,324.047 93.353,323.936C93.347,323.699 93.44,323.471 93.61,323.306C93.787,323.141 94.022,323.052 94.264,323.061C94.44,323.06 94.614,323.103 94.77,323.186C94.917,323.267 95.04,323.387 95.124,323.532C95.207,323.685 95.249,323.858 95.245,324.032C95.26,324.466 94.931,324.842 94.498,324.884L94.498,324.6C94.593,324.583 94.683,324.545 94.762,324.491C94.831,324.436 94.885,324.365 94.922,324.285C94.964,324.202 94.986,324.109 94.985,324.016C94.986,323.836 94.911,323.663 94.779,323.541C94.637,323.408 94.448,323.336 94.254,323.341C94.083,323.336 93.917,323.401 93.795,323.52C93.675,323.65 93.612,323.822 93.62,323.999C93.63,324.207 93.682,324.411 93.772,324.599L92.383,324.338L92.38,323.127Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1671" transform="matrix(1,0,0,1,-51.996,-176.635)">
                        <path d="M92.38,315.127L95.175,313.827L95.175,314.127L94.256,314.567L94.256,315.767L95.175,316.203L95.175,316.514L92.38,315.2L92.38,315.127ZM92.971,315.162L93.983,315.641L93.983,314.687L92.971,315.162Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1672" transform="matrix(1,0,0,1,-51.996,-170.793)">
                        <path d="M95.175,306.6L92.38,306.2L92.38,306.157L94.672,305.02L92.38,303.895L92.38,303.848L95.175,303.448L95.175,303.72L93.175,303.997L95.175,304.985L95.175,305.055L93.159,306.055L95.175,306.331L95.175,306.6Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1673" transform="matrix(1,0,0,1,-52.863,-167.491)">
                        <path d="M93.975,297.577L96.042,297.577L96.042,297.838L95.688,297.838C95.813,297.94 95.916,298.067 95.988,298.212C96.058,298.354 96.094,298.51 96.093,298.668C96.096,298.952 95.979,299.224 95.773,299.419C95.567,299.623 95.288,299.736 94.998,299.731C94.711,299.736 94.435,299.622 94.235,299.416C94.028,299.22 93.912,298.946 93.916,298.661C93.913,298.501 93.951,298.343 94.025,298.202C94.103,298.058 94.214,297.934 94.348,297.84L93.971,297.84L93.971,297.579L93.975,297.577ZM94.175,298.64C94.173,298.784 94.211,298.925 94.284,299.049C94.358,299.175 94.464,299.278 94.592,299.349C94.85,299.494 95.167,299.494 95.425,299.349C95.554,299.279 95.662,299.175 95.736,299.049C95.809,298.928 95.848,298.79 95.849,298.649C95.849,298.504 95.81,298.361 95.736,298.236C95.668,298.108 95.564,298.004 95.436,297.936C95.306,297.867 95.16,297.832 95.012,297.836C94.789,297.83 94.572,297.914 94.412,298.069C94.26,298.22 94.176,298.426 94.177,298.64L94.175,298.64Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1674" transform="matrix(1,0,0,1,-52.933,-164.673)">
                        <path d="M94.045,294.393L94.045,294.074L94.8,293.525L94.045,292.98L94.045,292.661L95.022,293.361L96.112,292.567L96.112,292.89L95.244,293.521L96.112,294.147L96.112,294.462L95.022,293.68L94.045,294.393Z" style="fill:rgb(44,41,39);fill-rule:nonzero;"/>
                    </g>
                    <g id="Path_1675" transform="matrix(1,0,0,1,-43.554,-162.878)">
                        <path d="M89.5,290.911C89.499,290.072 88.809,289.382 87.97,289.381L78.912,289.381C78.073,289.382 77.383,290.072 77.382,290.911L77.382,310.178C77.383,311.017 78.073,311.707 78.912,311.708L87.97,311.708C88.809,311.707 89.499,311.017 89.5,310.178L89.5,290.911Z" style="fill:none;stroke:rgb(44,41,39);stroke-width:0.92px;"/>
                    </g>
                    <g id="Rectangle_2333" transform="matrix(1,0,0,1,36.42,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2334" transform="matrix(1,0,0,1,36.841,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2335" transform="matrix(1,0,0,1,36.841,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2336" transform="matrix(1,0,0,1,36.841,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear1);"/>
                    </g>
                    <g id="Rectangle_2337" transform="matrix(1,0,0,1,44.818,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2338" transform="matrix(1,0,0,1,45.239,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2339" transform="matrix(1,0,0,1,45.239,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2340" transform="matrix(1,0,0,1,45.239,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear2);"/>
                    </g>
                    <g id="Rectangle_2341" transform="matrix(1,0,0,1,53.167,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2342" transform="matrix(1,0,0,1,53.588,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2343" transform="matrix(1,0,0,1,53.588,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2344" transform="matrix(1,0,0,1,53.588,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear3);"/>
                    </g>
                    <g id="Rectangle_2345" transform="matrix(1,0,0,1,61.883,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2346" transform="matrix(1,0,0,1,62.304,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2347" transform="matrix(1,0,0,1,62.304,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2348" transform="matrix(1,0,0,1,62.304,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear4);"/>
                    </g>
                    <g id="Rectangle_2349" transform="matrix(1,0,0,1,70.899,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2350" transform="matrix(1,0,0,1,71.321,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2351" transform="matrix(1,0,0,1,71.321,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2352" transform="matrix(1,0,0,1,71.321,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear5);"/>
                    </g>
                    <g id="Rectangle_2353" transform="matrix(1,0,0,1,79.297,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2354" transform="matrix(1,0,0,1,79.719,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2355" transform="matrix(1,0,0,1,79.719,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2356" transform="matrix(1,0,0,1,79.719,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear6);"/>
                    </g>
                    <g id="Rectangle_2357" transform="matrix(1,0,0,1,87.647,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2358" transform="matrix(1,0,0,1,88.067,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2359" transform="matrix(1,0,0,1,88.067,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2360" transform="matrix(1,0,0,1,88.067,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear7);"/>
                    </g>
                    <g id="Rectangle_2361" transform="matrix(1,0,0,1,96.363,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2362" transform="matrix(1,0,0,1,96.785,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2363" transform="matrix(1,0,0,1,96.785,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2364" transform="matrix(1,0,0,1,96.785,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear8);"/>
                    </g>
                    <g id="Rectangle_2365" transform="matrix(1,0,0,1,104.658,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2366" transform="matrix(1,0,0,1,105.08,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2367" transform="matrix(1,0,0,1,105.08,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2368" transform="matrix(1,0,0,1,105.08,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear9);"/>
                    </g>
                    <g id="Rectangle_2369" transform="matrix(1,0,0,1,113.374,207.029)">
                        <rect x="0" y="0" width="4.446" height="2.305" style="fill:rgb(0,28,0);"/>
                    </g>
                    <g id="Rectangle_2370" transform="matrix(1,0,0,1,113.796,208.713)">
                        <rect x="0" y="0" width="3.604" height="0.444" style="fill:rgb(133,181,93);"/>
                    </g>
                    <g id="Rectangle_2371" transform="matrix(1,0,0,1,113.796,207.029)">
                        <rect x="0" y="0" width="3.604" height="1.684" style="fill:rgb(30,130,0);"/>
                    </g>
                    <g id="Rectangle_2372" transform="matrix(1,0,0,1,113.796,207.029)">
                        <rect x="0" y="0" width="3.604" height="2.128" style="fill:url(#_Linear10);"/>
                    </g>
                    <g id="Ellipse_131" transform="matrix(1,0,0,1,35.123,197.648)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear11);"/>
                    </g>
                    <g id="Rectangle_2373" transform="matrix(1,0,0,1,37.741,197.648)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2374" transform="matrix(1,0,0,1,37.876,197.648)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_132" transform="matrix(1,0,0,1,43.57,197.603)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear12);"/>
                    </g>
                    <g id="Rectangle_2375" transform="matrix(1,0,0,1,46.188,197.603)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2376" transform="matrix(1,0,0,1,46.324,197.603)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_133" transform="matrix(1,0,0,1,52.017,197.603)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear13);"/>
                    </g>
                    <g id="Rectangle_2377" transform="matrix(1,0,0,1,54.635,197.603)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2378" transform="matrix(1,0,0,1,54.771,197.603)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_134" transform="matrix(1,0,0,1,60.809,197.603)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear14);"/>
                    </g>
                    <g id="Rectangle_2379" transform="matrix(1,0,0,1,63.428,197.603)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2380" transform="matrix(1,0,0,1,63.563,197.603)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_135" transform="matrix(1,0,0,1,69.257,197.648)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear15);"/>
                    </g>
                    <g id="Rectangle_2381" transform="matrix(1,0,0,1,71.875,197.648)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2382" transform="matrix(1,0,0,1,72.01,197.648)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_136" transform="matrix(1,0,0,1,77.704,197.603)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear16);"/>
                    </g>
                    <g id="Rectangle_2383" transform="matrix(1,0,0,1,80.322,197.603)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2384" transform="matrix(1,0,0,1,80.457,197.603)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_137" transform="matrix(1,0,0,1,86.151,197.603)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear17);"/>
                    </g>
                    <g id="Rectangle_2385" transform="matrix(1,0,0,1,88.769,197.603)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2386" transform="matrix(1,0,0,1,88.904,197.603)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_138" transform="matrix(1,0,0,1,94.944,197.603)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear18);"/>
                    </g>
                    <g id="Rectangle_2387" transform="matrix(1,0,0,1,97.561,197.603)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2388" transform="matrix(1,0,0,1,97.696,197.603)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_139" transform="matrix(1,0,0,1,103.39,197.603)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear19);"/>
                    </g>
                    <g id="Rectangle_2389" transform="matrix(1,0,0,1,106.009,197.603)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2390" transform="matrix(1,0,0,1,106.145,197.603)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                    <g id="Ellipse_140" transform="matrix(1,0,0,1,112.183,197.603)">
                        <circle cx="3.297" cy="3.297" r="3.297" style="fill:url(#_Linear20);"/>
                    </g>
                    <g id="Rectangle_2391" transform="matrix(1,0,0,1,114.8,197.603)">
                        <rect x="0" y="0" width="1.215" height="6.549" style="fill:rgb(205,222,215);"/>
                    </g>
                    <g id="Rectangle_2392" transform="matrix(1,0,0,1,114.935,197.603)">
                        <rect x="0" y="0" width="0.816" height="6.549" style="fill:rgb(4,26,26);"/>
                    </g>
                </g>
                <g id="Path_1676" transform="matrix(1,0,0,1,-236.898,-251.563)">
                    <path d="M421.47,446.945C421.847,448.13 422.104,449.35 422.237,450.587C422.237,451.737 421.087,454.805 420.894,458.187C420.701,461.569 429.969,461.127 435.848,461.127C441.727,461.127 444.348,460.232 444.284,459.082C444.22,457.932 437.384,455.12 434.698,453.459C432.012,451.798 427.798,449.369 427.798,447.068L421.471,446.94L421.47,446.945Z" style="fill:rgb(242,204,174);fill-rule:nonzero;"/>
                </g>
                <g id="Path_1677" transform="matrix(1,0,0,1,-213.042,-145.67)">
                    <path d="M394.865,258.809C394.865,258.809 400.233,288.209 401.765,297.918C403.297,307.627 405.088,341.884 405.088,341.884L395.8,341.884C393.25,334.184 386.822,319.85 387.589,300.678C387.589,300.678 379.532,275.935 378.509,262.642L394.865,258.809Z" style="fill:rgb(21,32,88);fill-rule:nonzero;"/>
                </g>
                <g id="Path_1678" transform="matrix(1,0,0,1,-194.597,-94.547)">
                    <path d="M359.039,167.98C358.131,168.843 357.097,169.562 355.972,170.113C354.184,170.967 347.622,173.634 346.515,177.686C345.408,181.738 345.365,187.126 347.153,193.686C349.078,200.741 348.533,221.286 348.533,221.286C348.533,221.286 353.475,227.367 363.189,227.367C368.871,227.419 374.352,225.233 378.44,221.286C382.528,217.339 376.932,204.036 376.847,197.849C376.762,191.662 378.229,181.449 377.528,176.622C376.676,170.756 367.304,168.41 367.304,168.41L359.039,167.98Z" />
                </g>
                <g id="Path_1679" transform="matrix(1,0,0,1,-210.984,-85.755)">
                    <path d="M376.576,152.563C376.483,155.328 375.898,158.054 374.85,160.614C373.802,163.174 376.385,164.257 380.15,164.577C382.163,164.895 384.085,163.517 384.432,161.509C383.634,159.479 383.392,157.272 383.732,155.118C384.243,151.285 376.574,152.562 376.574,152.562" style="fill:rgb(242,204,174);fill-rule:nonzero;"/>
                </g>
                <g transform="matrix(1,0,0,1,-212.918,-85.754)">
                    <g id="Group_1606">
                        <g id="Group_1605">
                            <path id="Path_1680" d="M384.517,162.02C385.152,162.02 385.778,161.879 386.351,161.605C386.363,161.549 386.371,161.505 386.371,161.505C385.574,159.475 385.332,157.269 385.671,155.114C386.183,151.279 378.514,152.557 378.514,152.557C378.503,153.398 378.427,154.237 378.289,155.066C379.024,157.084 381.165,162.015 384.52,162.015" style="fill:rgb(245,154,189);fill-rule:nonzero;"/>
                        </g>
                    </g>
                </g>
                <g id="Path_1681" transform="matrix(1,0,0,1,-212.752,-63.147)">
                    <path d="M392.789,117.771C392.959,118.406 393.045,119.061 393.045,119.718C393.045,123.847 389.648,127.244 385.519,127.244C381.391,127.244 377.993,123.847 377.993,119.718C377.993,116.323 380.291,113.328 383.571,112.449C387.559,111.381 391.72,113.783 392.789,117.771" style="fill:rgb(242,204,174);fill-rule:nonzero;"/>
                </g>
                <g id="Path_1682" transform="matrix(1,0,0,1,-212.107,-76.282)">
                    <path d="M391.863,135.872L391.863,142.337C391.651,145.894 388.752,148.748 385.193,148.906C381.393,148.906 378.213,145.935 377.7,143.986C377.254,141.186 376.968,138.362 376.845,135.529L391.863,135.872Z" style="fill:rgb(242,204,174);fill-rule:nonzero;"/>
                </g>
                <g id="Path_1683" transform="matrix(1,0,0,1,-236.9,-256.81)">
                    <path d="M444.285,464.334C444.222,463.213 433.736,457.942 430.919,456.268C430.398,456.829 430.973,459.824 427.332,459.296C423.159,458.696 425.122,457.625 421.974,457.39C421.435,459.37 421.074,461.395 420.896,463.439C420.705,466.826 429.97,466.379 435.849,466.379C441.728,466.379 444.349,465.484 444.285,464.334" style="fill:rgb(21,32,88);fill-rule:nonzero;"/>
                </g>
                <g id="Path_1684" transform="matrix(1,0,0,1,-191.055,-103.87)">
                    <path d="M342.654,203.06C342.04,200.768 339.443,193.495 339.443,191.304C339.443,187.595 342.495,184.544 346.203,184.544C349.912,184.544 352.964,187.595 352.964,191.304C352.964,193.039 352.612,198.61 353.058,200.273C353.238,200.944 353.382,201.624 353.49,202.31C354.016,202.119 354.561,201.947 355.126,201.795C358.842,200.795 369.091,198.238 369.091,198.238L371.767,201.687C371.767,201.687 363.417,210.723 357.913,212.198C353.005,213.513 348.424,212.754 346.943,210.54C344.806,208.526 343.313,205.922 342.654,203.06Z" style="fill:rgb(242,204,174);"/>
                </g>
                <g id="Path_1685" transform="matrix(1,0,0,1,-212.107,-99.046)">
                    <path d="M395.044,189.431C395.293,186.666 395.29,183.884 395.037,181.119C394.597,178.991 393.308,177.132 391.47,175.973C393.922,175.937 396.108,177.569 396.77,179.931C396.957,180.631 398.817,183.771 399.17,185.092C399.695,187.527 399.942,190.014 399.907,192.505C399.917,196.993 398.151,197.026 392.398,198.568C386.645,200.11 384.35,197.603 384.35,197.603L376.841,196.793C376.841,196.793 385.682,190.908 388.741,190.087C390.789,189.516 392.919,189.294 395.041,189.431L395.044,189.431Z" style="fill:rgb(242,204,174);"/>
                </g>
                <g id="Group_1607" transform="matrix(1,0,0,1,174.422,75.141)">
                    <g id="Path_1686" transform="matrix(1,0,0,1,-398.997,-171.888)">
                        <path d="M410.833,176.673C410.638,174.735 409.228,173.124 407.333,172.673C407.123,172.626 400.865,171.921 400.633,171.893L399,175.275C399.462,177.597 399.929,179.923 400.391,182.122L400.401,182.174C400.756,183.863 401.108,185.48 401.465,186.96C401.511,187.151 401.608,187.525 401.608,187.525L412.378,184.51L410.833,176.673Z" />
                    </g>
                    <g id="Path_1687" transform="matrix(1,0,0,1,-409.474,-187.202)">
                        <path d="M417.619,200.556C417.614,200.536 417.611,200.514 417.611,200.493C417.611,200.375 417.693,200.271 417.808,200.242L422.408,199.103C422.428,199.098 422.45,199.095 422.471,199.095C422.589,199.095 422.693,199.177 422.722,199.292L422.847,199.792C422.852,199.812 422.855,199.834 422.855,199.855C422.855,199.973 422.773,200.077 422.658,200.106L418.058,201.245C417.92,201.279 417.778,201.193 417.744,201.055L417.619,200.556Z"/>
                    </g>
                </g>
                <g id="Path_1688" transform="matrix(1,0,0,1,-207.965,-63.822)">
                    <path d="M372.706,131.138C378.297,131.522 374.806,125.432 374.806,125.432C374.806,125.432 376.17,127.307 376.651,127.816C377.223,127.729 377.79,127.614 378.351,127.47C378.243,120.182 376.703,117.147 384.459,117.626C385.951,117.117 386.511,112.019 377.279,113.748C373.237,114.32 370.08,117.601 369.665,121.662C369.2,125.857 369.441,130.553 372.706,131.138Z" style="fill:rgb(45,43,111);fill-rule:nonzero;"/>
                </g>
                <g id="Path_1689" transform="matrix(1,0,0,1,-213.876,-78.891)">
                    <path d="M380.014,142.443C380.166,143.816 381.499,145.274 382.397,145.174C383.295,145.074 383.072,143.974 382.934,142.597C382.843,141.688 382.934,140.371 381.914,140.197C381.024,140.041 379.791,140.431 380.014,142.446L380.014,142.443Z" style="fill:rgb(242,204,174);fill-rule:nonzero;"/>
                </g>
                <g id="Path_1690" transform="matrix(1,0,0,1,-207.462,-59.002)">
                    <path d="M369.416,116.091C369.416,116.091 374.3,113.152 376.556,112.937C378.812,112.722 386.218,111.873 386.218,111.873C386.218,111.873 385.718,105.256 380.318,104.855C374.918,104.454 365.809,108.531 369.418,116.092" />
                </g>
                <g id="Path_1691" transform="matrix(1,0,0,1,-217.156,-65.933)">
                    <path d="M397.368,122.505C397.368,122.505 411.476,120.116 407.301,118.213C401.459,115.552 392.715,118.728 393.961,118.342C396.767,117.476 385.815,120.063 385.815,120.063C385.815,120.063 396.726,118.271 397.35,122.412" style="fill-rule:nonzero;"/>
                </g>
                <g id="Path_1692" transform="matrix(1,0,0,1,-228.24,-76.949)">
                    <path d="M405.508,136.714C405.508,136.714 407.428,140.071 405.519,140.159" style="fill:none;fill-rule:nonzero;stroke:rgb(227,176,136);stroke-width:1.01px;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;"/>
                </g>
                <g id="Path_1693" transform="matrix(1,0,0,1,-227.452,-85.349)">
                    <path d="M406.459,151.638C406.459,151.638 404.977,153.528 404.108,152.138" style="fill:none;fill-rule:nonzero;stroke:rgb(227,176,136);stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;"/>
                </g>
                <g id="Group_1608" transform="matrix(1,0,0,1,148.528,78.04)">
                    <g id="Path_1694" transform="matrix(1,0,0,1,-339.764,-178.519)">
                        <path d="M339.784,186.8C339.621,184.241 340.462,182.207 342.826,180.992C343.071,180.87 347.87,178.632 348.145,178.527L353.987,181.427C354.166,184.303 354.34,187.184 354.478,189.915L354.478,189.98C354.585,192.08 354.671,194.089 354.708,195.943C354.713,196.182 354.713,196.652 354.713,196.652L340.4,196.502L339.784,186.8Z" />
                    </g>
                    <g id="Path_1695" transform="matrix(1,0,0,1,-340.334,-200.315)">
                        <path d="M353.987,217.486C353.977,217.344 353.854,217.235 353.712,217.244L341.02,218.055C340.878,218.064 340.769,218.188 340.777,218.33L340.81,218.848C340.819,218.984 340.933,219.091 341.068,219.091C341.074,219.091 341.079,219.09 341.085,219.09L353.777,218.279C353.913,218.27 354.02,218.156 354.02,218.021C354.02,218.015 354.019,218.01 354.019,218.004L353.987,217.486Z"/>
                    </g>
                </g>
                <g id="Path_1696" transform="matrix(1,0,0,1,-219.326,-95.164)">
                    <path d="M389.671,173.932C390.754,174.872 391.529,176.116 391.894,177.502C392.723,176.864 393.606,177.036 394.176,175.837C394.094,173.272 393.385,171.108 393.586,169.508L392.217,169.074C392.088,171.69 391.82,173.764 389.671,173.932Z"/>
                </g>
                <g id="Path_1697" transform="matrix(1,0,0,1,-219.177,-101.431)">
                    <path d="M389.406,180.291L390.906,187.567L391.633,187.313L389.633,180.213L389.406,180.291Z"/>
                </g>
                <g id="Path_1698" transform="matrix(1,0,0,1,-210.733,-94.878)">
                    <path d="M374.4,168.693C374.866,170.447 375.09,172.257 375.066,174.072C376.54,175.731 378.37,177.035 380.418,177.888C380.697,178.185 381.05,178.402 381.441,178.516C381.813,177.27 381.302,174.967 381.101,173.671C377.807,173.178 375.895,172.46 375.238,168.571L374.4,168.693Z"/>
                </g>
                <g id="Path_1699" transform="matrix(1,0,0,1,-233.253,-105.27)">
                    <path d="M415.837,193.954C415.171,190.901 414.69,188.721 414.414,187.062C414.428,187.052 414.441,187.041 414.453,187.029C414.489,187.053 414.522,187.08 414.553,187.11C415.234,187.791 415.911,190.728 416.939,194.017L417.002,194.211C416.604,194.177 416.212,194.09 415.837,193.954Z"/>
                </g>
                <g id="Path_1700" transform="matrix(1,0,0,1,-206.426,-112.567)">
                    <path d="M367.033,207.936C366.867,204.447 366.741,201.955 366.753,200.042C366.774,200.025 366.792,200.01 366.806,199.995C366.843,200.016 366.876,200.041 366.906,200.071C367.576,200.74 367.779,204.091 368.328,207.786L368.363,208.004C367.919,208.045 367.471,208.022 367.033,207.936Z"/>
                </g>
            </g>
        </g>
        <defs>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.439688,0,0,0.439688,-36.8437,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.436084,0,0,0.436084,-45.241,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.439688,0,0,0.439688,-53.5915,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.436084,0,0,0.436084,-62.306,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.436084,0,0,0.436084,-71.3232,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.436084,0,0,0.436084,-79.7205,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.436084,0,0,0.436084,-88.0709,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.439688,0,0,0.439688,-96.789,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.436084,0,0,0.436084,-105.082,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.436084,0,0,0.436084,-113.8,-206.982)"><stop offset="0" style="stop-color:rgb(0,28,0);stop-opacity:1"/><stop offset="0.43" style="stop-color:rgb(2,31,0);stop-opacity:0.87"/><stop offset="0.59" style="stop-color:rgb(5,38,0);stop-opacity:0.58"/><stop offset="0.73" style="stop-color:rgb(9,46,0);stop-opacity:0.27"/><stop offset="1" style="stop-color:rgb(12,53,0);stop-opacity:0"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.441798,0,0,0.441798,-35.1262,-197.649)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.441798,0,0,0.441798,-43.5732,-197.602)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.441798,0,0,0.441798,-52.0201,-197.602)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.435204,0,0,0.435204,-60.8099,-197.602)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.435204,0,0,0.435204,-69.2568,-197.649)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.435204,0,0,0.435204,-77.7037,-197.602)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.435204,0,0,0.435204,-86.1506,-197.602)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.441798,0,0,0.441798,-94.947,-197.602)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.435204,0,0,0.435204,-103.387,-197.602)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(0.435204,0,0,0.435204,-112.184,-197.602)"><stop offset="0" style="stop-color:rgb(4,16,22);stop-opacity:1"/><stop offset="0.52" style="stop-color:rgb(4,29,39);stop-opacity:1"/><stop offset="1" style="stop-color:rgb(6,71,95);stop-opacity:1"/></linearGradient>
        </defs>
    </svg>
    </div>
    <div id='asi-text-header'>{{trans('systems.labels.indirectSystemInfoHeader')}}</div>
    <div id='asi-text'>{{trans('systems.labels.indirectSystemInfoText')}}</div>
</div>