<div class="middle_column_grid">
  <div class="middle_column">
    <div class="header">
      <h4 class="title">{{trans('settings.global.title')}}</h4>
    </div>
    <div class="globalSettingsSection">
      <app-settings></app-settings>
    </div>
  </div>
</div>
