<app-base-popup #basePopup (closed)="closed.emit()">
    <div class="efp_contents padding16">
        <div id="efp_header">{{lang.get('events.filter.filterBy')}}</div>
        <app-name-value [nameText]="lang.get('events.filter.systems.label')" (clicked)="pickSystem()">{{systemText}}</app-name-value>
        <div id="efp_dates">
            <div [inlineSVG]="'assets/images/calendar.svg'" id="efp_cal_icon"></div>
            <div id="efp_fromh">{{lang.get('events.filter.date_from')}}</div>
            <div id="efp_from">
                <div (click)="changeFromDate()">{{dateFromText}}</div>
                <div [inlineSVG]="'assets/images/triangle_down.svg'"></div>
            </div>
            <div id="efp_toh">{{lang.get('events.filter.date_to')}}</div>
            <div id="efp_to">
                <div (click)="changeToDate()">{{dateToText}}</div>
                <div [inlineSVG]="'assets/images/triangle_down.svg'"></div>
            </div>
        </div>
        <div id="efp_quick_buttons">
            <div class="efp_button {{activeQuickButton === 1 ? 'efp_button_toggled' : ''}}" (click)="quickButtonClicked(1)">{{lang.get('events.filter.day')}}</div>
            <div class="efp_button {{activeQuickButton === 2 ? 'efp_button_toggled' : ''}}" (click)="quickButtonClicked(2)">{{lang.get('events.filter.week')}}</div>
            <div class="efp_button {{activeQuickButton === 3 ? 'efp_button_toggled' : ''}}" (click)="quickButtonClicked(3)">{{lang.get('events.filter.month')}}</div>
        </div>
        <div id="efp_line"></div>
        <app-name-value [nameText]="lang.get('events.filter.type.label')" (clicked)="pickReaction()">{{reactionText}}</app-name-value>
        <div id="efp_buttons">
            <div (click)="cancelClick()">{{lang.get('general.cancel')}}</div>
            <div (click)="performFilter()">{{lang.get('events.filter.submit')}}</div>
        </div>
    </div>
</app-base-popup>