import { Injectable } from '@angular/core';
import requests from 'src/api/requests/requests';
import { GlobalService } from 'src/app/services/global.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { GV17AdminUserData, GV17Device, GV17SimpleUserData, GV17UnauthorizedUserData } from '../models/types';
import { HwfwService } from './hwfw.service';

@Injectable({
  providedIn: 'root',
})
export class ConfiguratorService {
  private changes = [];
  public currentConfiguration: GV17Device = null;

  constructor(private toaster: ToasterService, private g: GlobalService, private fw: HwfwService) {}

  public hasChanges(): boolean {
    return this.changes.length > 0;
  }

  public clearChanges() {
    this.changes = [];
  }

  public setCurrentConfiguration(config: GV17Device) {
    this.currentConfiguration = config;
  }

  public readUserList(callback?: () => void) {
    const emptyUser: GV17SimpleUserData = {
      queueNo: 0,
      enabled: false,
      validFromEnabled: false,
      validToEnabled: false,
      counterEnabled: false,
      greet: false,
      schedule: 0,
      counter: 0,
      currentCounter: 0,
      canControl: {
        out1: false,
        out2: false,
        out3: false,
        out4: false,
        out5: false,
      },
      validFrom: 0,
      validUntil: 0,
      phone: '',
      email: '',
      code: '',
      name: '',
      presentInDevice: false, // musu virtualus vartotojas.
      modified: false,
    };
    if (this.fw.firmwareIsGreaterThanOrEqual('2.10') && !this.fw.isWiFiDevice()) {
      emptyUser.automaticDial = {
        out3: false,
        out4: false,
        out5: false,
      };
    }
    requests.users
      .read({
        systemUid: this.g.configurableDevice.uid,
        mpass: this.g.configurableDevice.mpass,
        adminCode: this.g.configurableDevice.adminCode,
        packet: 0,
        fwVersion: this.fw.getFw(),
      })
      .subscribe(
        (result) => {
          if (result.success) {
            this.currentConfiguration.users.users.simple = result.data;
            const usersFromDevice = this.currentConfiguration.users.users.simple;
            let index = 0;
            const usersToAdd = [];
            const noUsers = usersFromDevice.length === 0;
            for (let i = 11; i < 1011; i++) {
              if (!noUsers && index < usersFromDevice.length && usersFromDevice[index].queueNo === i) {
                index++;
                continue;
              }
              emptyUser.queueNo = i;
              usersToAdd.push({ ...emptyUser });
            }
            usersFromDevice.push(...usersToAdd);
            usersFromDevice.sort((u1, u2) => u1.queueNo - u2.queueNo);
          } else {
            this.toaster.postError(result.error);
          }
          if (callback !== undefined) {
            callback();
          }
        },
        (error) => {
          if (callback !== undefined) {
            callback();
          }
        }
      );
  }

  public isUnauthorized(u: GV17AdminUserData | GV17SimpleUserData | GV17UnauthorizedUserData): u is GV17UnauthorizedUserData {
    return u.queueNo === 10;
  }

  public isAdmin(u: GV17AdminUserData | GV17SimpleUserData | GV17UnauthorizedUserData): u is GV17AdminUserData {
    return u.queueNo < 10;
  }

  public isSimple(u: GV17AdminUserData | GV17SimpleUserData | GV17UnauthorizedUserData): u is GV17SimpleUserData {
    return u.queueNo > 10;
  }
}
