<h4>{{user.currentUser.name ? lang.get('users.settings.title2').replace(':username', user.currentUser.name) : lang.get('users.settings.title') }}</h4>
<div class="profileSection">
  <app-string-input [label]="lang.get('users.labels.email')" [(value)]="user.currentUser.email" [disabled]="true"></app-string-input>
  <app-string-input [label]="lang.get('users.labels.nameProfile')" [(value)]="userSettingsData.name" (valueChange)="onInputChange()"></app-string-input>
  <app-string-input [label]="lang.get('users.labels.phoneNumber')" [(value)]="userSettingsData.phone" (valueChange)="onInputChange()">
    <app-form-error *ngIf="val.hasError('phone')">{{ val.getError('phone') }}</app-form-error>
  </app-string-input>
  <app-dropdown [label]="lang.get('users.settings.menu.language')" [items]="languageItems" [(value)]="currentLanguage" (valueChange)="onInputChange()"></app-dropdown>
  <app-dropdown [label]="lang.get('users.labels.dateTimeFormat')" [items]="dateTimeFormats" [(value)]="userSettingsData.DateFormat" (valueChange)="onInputChange()"></app-dropdown>
  <a class="link" (click)="showChangePasswordComponent = true">{{lang.get('users.buttons.changePassword')}}</a>
  <div *ngIf="showChangePasswordComponent">
    <app-messagebox
      [buttons]="changePasswordMessageboxButtons"
      [button1Text]="lang.get('general.cancel')"
      [button2Text]="lang.get('general.save')"
      (messageboxClose)="showChangePasswordComponent = !$event"
      (button2Clicked)="passwordChange.doChange({password, newPassword})"
    >
      <p class="custom_header_text">{{lang.get('users.buttons.changePassword')}}</p>
      <app-string-input type="password" [label]="lang.get('users.labels.passwordCurrent')" [(value)]="password"></app-string-input>
      <app-string-input type="password" [label]="lang.get('users.labels.passwordNew')" [(value)]="newPassword"></app-string-input>
    </app-messagebox>
  </div>
</div>
<div class="dataTable" (click)='profileComponent.doDownloadAccount()'>
  <div class="dataItemContainer">
    <p class="settings-item">{{lang.get('users.buttons.downloadAccount')}}</p>
    <div class='icon' [inlineSVG]="'assets/images/forward.svg'"></div>
  </div>
</div>
<div class="dataTable" (click)="onDeleteProfileClick()">
  <div class="dataItemContainer">
    <p class="settings-item">{{lang.get('users.labels.deleteProfile')}}</p>
    <div class='icon' [inlineSVG]="'assets/images/forward.svg'"></div>
  </div>
</div>
<div *ngIf="inputChanged">
  <ng-template appTeleport="footer">
    <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
  </ng-template>
</div>
