import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { LoggerService } from 'src/app/api/logger.service';
import { LanguageService } from 'src/app/services/language.service';

const collapseLabel = <TValue>(label: string | ((value: TValue) => string), value: TValue) => (typeof label === 'function' ? label(value) : label);

export type DropdownItem<TValue extends string | number> = {
  label: string | ((value: TValue) => string);
  value: TValue;
  default?: boolean;
  extraParam?: number | string;
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<TValue extends string | number> implements AfterViewInit, AfterViewChecked, OnDestroy {
  public collapseLabel = collapseLabel;
  @Input() label: string;
  @Input() disabled = false;
  private _value: TValue;
  private _items: DropdownItem<TValue>[] = [];
  public get value(): TValue {
    return this._value;
  }
  @Input()
  public set value(value: TValue) {
    this._value = value;
    this.setInitialTextBoxText();
  }
  @Input()
  public set items(value: DropdownItem<TValue>[]) {
    this._items = value;
    if ( !this._textBox && this._value !== undefined ) {
      this.setInitialTextBoxText();
    }
  }
  public get items(): DropdownItem<TValue>[] {
    return this._items;
  }
  @Input() hasError = false;
  @Input() size?: 'normal' | 'small';
  @Output() valueChange = new EventEmitter<TValue>();

  private showFullList = true;
  private _textBox = '';
  private unlisten: (() => void) | null = null;
  public get textBox() {
    return this._textBox;
  }
  public set textBox(value) {
    this.showFullList = this.items.find(item => item.label === value) ? true : false;
    this._textBox = value;
    this.updateCurrentActive();
  }
  public currentActive = 0;
  public shown = false;
  public scrollDirty = false;
  constructor(private element: ElementRef, private zone: NgZone, private render: Renderer2, public lang: LanguageService) {}

  public get shownItems() {
    if (!this.textBox) {return this.items;}
    const items = this.showFullList ? this.items : this.items.filter((item) => {
      const label = collapseLabel(item.label, item.value);
      return label.toLowerCase().startsWith(this.textBox.toLowerCase());
    });
    items.sort((a, b) => {
      const aLabel = collapseLabel(a.label, a.value);
      const bLabel = collapseLabel(b.label, b.value);
      if (aLabel.toLowerCase().startsWith(this.textBox.toLowerCase())) {return -1;}
      if (bLabel.toLowerCase().startsWith(this.textBox.toLowerCase())) {return 1;}
      return 0;
    });
    if (items[0]?.default) {return this.items;}
    return items;
  }

  public updateCurrentActive(change?: number) {
    if (change) {
      this.currentActive += change;
      this.scrollDirty = true;
      this.shown = true;
    }
    if (this.currentActive < 0) {
      this.currentActive = this.shownItems.length - 1;
      this.scrollDirty = true;
    }
    if (this.currentActive >= this.shownItems.length) {
      this.currentActive = 0;
      this.scrollDirty = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.scrollDirty) {return;}
    this.scrollDirty = false;
    const el = this.element.nativeElement.querySelector('.item.active') as HTMLElement | null;
    if (!el) {return;}
    el.scrollIntoView({ block: 'nearest' });
  }

  public selectItem(itemIndex: number) {
    const val = this.shownItems[itemIndex];
    if(!val) {
      this.valueChange.emit(this.items.length === 0 ? undefined : this.items[0].value);
      this.textBox = this.items.length === 0 ? collapseLabel('', undefined) : collapseLabel(this.items[0].label, this.items[0].value);
      this.shown = false;
    } else {
      this.valueChange.emit(val.value);
      this.textBox = collapseLabel(val.label, val.value);
      this.shown = false;
    }
  }

  public onFocus() {
    if(this.disabled) { return; }
    this.shown = true;
    const input = this.element.nativeElement.querySelector('input.input') as HTMLInputElement;
    input.setSelectionRange(0, input.value.length);

    const dropdown = this.element.nativeElement.querySelector('.dropdown') as HTMLElement;
    if(dropdown) {
      const dropdownHeight = dropdown.offsetHeight;
      const dropdownBottom = dropdown.getBoundingClientRect().bottom;
      const distanceFromBottom = window.innerHeight - dropdownBottom;
      if (distanceFromBottom < dropdownHeight) {
        dropdown.style.bottom = `${input.offsetHeight}px`;
      } else {
        dropdown.style.bottom = '';
      }
    }
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      const input = this.element.nativeElement.querySelector('input.input') as HTMLInputElement;
      this.unlisten = this.render.listen(input, 'blur', (event: FocusEvent) => {
        setTimeout(() => {
          this.zone.run(() => {
            const found = this.items.find((item) => item.value === this.value);
            if (found && this.textBox.toLowerCase() !== collapseLabel(found.label, found.value).toLowerCase()) {
              if (this.shownItems.length === 0) {
                const item = this.items.find((i) => i.default);
                this.textBox = item ? collapseLabel(item.label, item.value) : collapseLabel(this.items[0].label, this.items[0].value);
                this.valueChange.emit(item ? item.value : this.items[0].value);
              } else {
                this.selectItem(this.currentActive);
              }
            }
            this.shown = false;
          });
        }, 150);
      });
    });
  }

  ngOnDestroy(): void {
    this.unlisten?.();
  }

  private setInitialTextBoxText() {
    // eslint-disable-next-line eqeqeq
    const itm = this.items.find((item) => item.value == this._value) ?? this.items.find((item) => item.default);
    this._textBox = collapseLabel(itm?.label, itm?.value);
  }
}
