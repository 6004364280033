import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy, Injector } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { BackBehaviourService } from 'src/app/services/back-behaviour.service';
import { SensorService } from 'src/app/api/system/sensor.service';
import { checkIfSystemIsOnline, transferDevice } from 'src/api/v3/system';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { ErrorResponse, PermissionRole } from 'src/api/v3/common';
import { defaultHomeConfiguration } from 'src/app/api/user.service';
import { HomeConfigurationElementType } from 'src/app/models/home-configuration';
import { Subscription } from 'rxjs';
import { autoinject } from 'src/shim';
import { LocatorService } from 'src/app/services/locator.service';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent extends LanguageAware implements OnInit, OnDestroy {
  private systemChangeSubscription: Subscription;
  private refreshSubscription: Subscription;
  public transferIsInProgress = false;
  public checkingForOnlineSystem = false;
  public shouldShowNewSystemWarning = false;
  private newSystemWarningTimeout = null;
  public readonly PermissionRole = PermissionRole;
  private get headerService() { return autoinject(LocatorService.injector, HeaderService); }

  constructor(
    cdRef: ChangeDetectorRef,
    private ws: WebSocketService, // tik tam, kad butu uzkrauta
    private router: Router,
    private ns: NewSystemService,
    private senS: SensorService,
    private bh: BackBehaviourService,
    private msg: MessageboxService,
    private r: ActivatedRoute,
  ) {
    super(cdRef, false, false);
    this.tag = 'Home';
    this.log('+');
    if ( this.systems.activeSystem !== null && this.r.snapshot.data.bare ) {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    if ( this.systems.activeSystem ) {
      this.systems.setCurrentSystem(this.systems.activeSystem);
    }
    this.background.setUserSelected();

    this.systemChangeSubscription = this.systems.onActiveSystemChange.subscribe(() => {
      this.handleSystemChange();
      this.handleHeaderView();
      this.determineIfNewSystemWarningShouldBeShown();
    });
    this.handleHeaderView();
    this.refreshSubscription = this.refresher.onRefresh.subscribe(() => {
      this.doRefresh();
    });
    localStorage.setItem('ref', 'home');
    this.refresher.enableRefresher();
    this.determineIfNewSystemWarningShouldBeShown();
  }

  private handleHeaderView() {
    if (this.systems.activeSystem !== null) {
      const canRequestAssistance = this.systems.currentUserPermissions?.permissions.sys_assistance.execute && (
                                    this.systems.activeSystem.installerId !== 0
                                    || this.us.currentUser.permissions.permissions.users.view
                                  ) &&
                                  this.us.currentUser.permissions.role !== PermissionRole.Installer;
      this.headerBar.showHeader({
        showDotMenu: this.us.currentUser.permissions?.role === PermissionRole.GeneralUser,
        showHamburger: true,
        showHome: this.us.currentUser.permissions?.role !== PermissionRole.GeneralUser,
        showInstallerAssist: canRequestAssistance,
      });
      this.headerBar.onHomeButtonClicked = () => {
        this.systems.setCurrentSystem(null);
        this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
      };
      if ( canRequestAssistance ) {
        this.headerBar.onActionButtonClicked = () => {
          this.router.navigate(['../request-assistance'], { relativeTo: this.r });
        };
      }
    } else {
      this.headerBar.showHeader({
        showHamburger: true,
      });
    }
    if (this.headerBar.isDesktop) {
      if(this.us.currentUser.permissions?.role === PermissionRole.GeneralUser) {
        this.headerBar.setDotMenuItems([
          {
            name: this.trans('systems.menu.editHomeScreen'),
            action: '/home-configuration',
          }
        ]);
      } else if (this.us.currentUser.permissions?.role === PermissionRole.Company) {
        this.headerBar.setDotMenuItems([
          {
            name: this.trans('systems.menu.systemConfiguration'),
            action: '/system-configuration',
          },
        ]);
      } else if (this.us.currentUser.permissions?.role === PermissionRole.SuperAdmin) {
        this.headerBar.setDotMenuItems([
          {
            name: this.trans('systems.menu.editHomeScreen'),
            action: '/home-configuration',
          },
          {
            name: this.trans('systems.menu.systemConfiguration'),
            action: '/system-configuration',
          },
        ]);
      }
    } else {
      this.headerBar.setDotMenuItems([
        {
          name: this.trans('systems.menu.editHomeScreen'),
          action: '/home-configuration',
        },
        {
          name: this.trans('systems.menu.systemConfiguration'),
          action: '/system-configuration',
        },
      ]);
    }
    this.headerBar.setHeaderBright();

    if (this.platform.isAndroid()) {
      if (this.us.currentUser.permissions?.permissions.systems.view) {
        this.headerService.setBackURL('*');
        this.platform.androidHandler().setBackButtonType('normal');
      } else {
        this.platform.androidHandler().setBackButtonType('close');
      }
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.systemChangeSubscription?.unsubscribe();
    this.refreshSubscription?.unsubscribe();
  }

  public newSystemClick() {
    this.ns.setDefaults();
    this.router.navigate(['/add-system']);
  }

  public hasSystem(): boolean {
    return this.systems.hasSystems() && this.systems.activeSystem !== null;
  }

  public systemNetState(): string {
    return this.systems.activeSystem.online ? this.trans('systems.statuses.online') : this.trans('systems.statuses.offline');
  }

  private handleSystemChange() {
    if (this.systems.activeSystem !== null) {
      if (this.us.currentUser.permissions?.role === PermissionRole.GeneralUser) {
        this.headerBar.doShowDotMenu();
      }
      this.background.setUserSelected();
    }
  }

  public getPositionForType(type: string): string {
    const systemHomeConfig = this.us.currentUser.homeConfigurations.find((cf) => cf.systemId === this.systems.activeSystem.id);
    if (systemHomeConfig === undefined || systemHomeConfig.elementOrder.find((t) => t.type === type) === undefined) {
      return defaultHomeConfiguration.elementOrder.find((t) => t.type === type).position.toString();
    }
    return systemHomeConfig.elementOrder.find((t) => t.type === type).position.toString();
  }

  public shouldShowType(type: HomeConfigurationElementType) {
    if (this.us.currentUser.permissions?.permissions.systems.view) {
      if ( type === 'areas' && !this.us.currentUser.permissions?.permissions.sys_areas.view ) { return false; }
      if ( type === 'cameras' && !this.us.currentUser.permissions?.permissions.sys_cameras.view ) { return false; }
      if ( type === 'events' && !this.us.currentUser.permissions?.permissions.sys_events.view ) { return false; }
      if ( type === 'outputs' && !this.us.currentUser.permissions?.permissions.sys_outputs.view ) { return false; }
      if ( type === 'sensors' && !this.us.currentUser.permissions?.permissions.sys_sensors.view ) { return false; }
      if ( type === 'thermostats' && !this.us.currentUser.permissions?.permissions.sys_thermostats.view ) { return false; }
      return true;
    }
    const systemHomeConfig = this.us.currentUser.homeConfigurations.find((cf) => cf.systemId === this.systems.activeSystem.id);
    if (systemHomeConfig === undefined) {
      return true;
    }
    const found = systemHomeConfig.elementOrder.find((t) => t.type === type);
    return found === undefined || found.visible;
  }

  public doRefresh() {
    this.log('Refresh');
    this.senS.watcherStatus.isWorking = true;
    this.api.get('/me', true, { systemId: this.systems.activeSystem === null ? 0 : this.systems.activeSystem.id }).subscribe(
      (result) => {
        if (result.success) {
          if (!this.api.hasToken()) {
            // Panašu, kad kol siuntė duomenis, vartotojas padarė logout.
            return;
          }
          this.us.setCurrentUser(result);
          this.us.change();
          this.us.saveCurrentUser();
          this.api.setToken(result.token);
          if (result.lastSystem !== undefined) {
            this.systems.setCurrentSystemFromRaw(result.lastSystem);
            if (this.systems.activeSystem?.sensors.length > 0) {
              this.senS.startWatcher(true);
            }
            if ( this.systems.activeSystem && !this.systems.activeSystem.online && !this.checkingForOnlineSystem && !this.transferIsInProgress ) {
              this.checkingForOnlineSystem = true;
              const activeSystem = this.systems.activeSystem;
              checkIfSystemIsOnline({
                imei: this.systems.activeSystem.imei,
                checkAll: true,
                mpass: this.systems.activeSystem.mpass,
              }).toPromise().then((onlineRes) => {
                this.checkingForOnlineSystem = false;
                let supported = false;
                if (activeSystem.supported_commands.indexOf('.18.') !== -1 || activeSystem.supported_commands.endsWith('.18')) {
                  supported = true;
                } else if (activeSystem.supported_commands.indexOf('.22.') !== -1 || activeSystem.supported_commands.endsWith('.22')) {
                  supported = true;
                }
                if ( onlineRes.success && onlineRes.online && !onlineRes.own_region && supported ) {
                  this.msg.open({
                    buttons: this.msg.buttons.YesNoCancel,
                    headerText: this.trans('systems.titles.transferDevice'),
                    messageContent: this.trans('systems.labels.deviceInDifferentRegion'),
                  }, (p) => {
                    p.yesClicked.subscribe(() => {
                      this.doMoveDevice({
                        id: activeSystem.id,
                        imei: activeSystem.imei,
                        mpass: activeSystem.mpass,
                        srv: onlineRes.srv,
                      });
                    });
                  });
                }
              });
            }
          } else {
            this.toaster.postError(result.error);
          }
        }
      },
      (error) => {
        this.l.log('Nepavyko atnaujinti vartotojo duomenu', this.tag);
      }
    );
  }

  private doMoveDevice(opts: any) {
    this.transferIsInProgress = true;
    let onlineWaitSubscriber = null;
    const timeOutTimer = setTimeout(() => {
      onlineWaitSubscriber?.unsubscribe();
      onlineWaitSubscriber = null;
      this.transferIsInProgress = false;
      this.msg.open({
        buttons: this.msg.buttons.Close,
        headerText: this.trans('general.titleError'),
        iconType: this.msg.iconType.Error,
        messageContent: this.trans('systems.errors.failedToTransferDevice'),
      });
    }, 60000);
    onlineWaitSubscriber = this.ws.onSystemStatusChange.subscribe((newStatus) => {
      if ( newStatus.system_id !== opts.id ) { return; }
      if ( newStatus.system_status === 'online' ) {
        clearTimeout(timeOutTimer);
        this.transferIsInProgress = false;
        this.msg.open({
          buttons: this.msg.buttons.Close,
          messageContent: this.trans('general.titleSuccess'),
          iconType: this.msg.iconType.Success,
        });
      }
      onlineWaitSubscriber?.unsubscribe();
      onlineWaitSubscriber = null;
    });
    transferDevice({
      systemUid: opts.imei,
      mpass: opts.mpass,
      srv: opts.srv,
    }).subscribe(
      res => {
        if ( !res.success ) {
          clearTimeout(timeOutTimer);
          onlineWaitSubscriber?.unsubscribe();
          onlineWaitSubscriber = null;
          this.transferIsInProgress = false;
          this.msg.open({
            buttons: this.msg.buttons.Close,
            headerText: this.trans('general.titleError'),
            iconType: this.msg.iconType.Error,
            messageContent: (res as ErrorResponse).error,
          });
        }
      },
      error => {
        clearTimeout(timeOutTimer);
        onlineWaitSubscriber?.unsubscribe();
        onlineWaitSubscriber = null;
        this.transferIsInProgress = false;
      }
    );
  }

  private determineIfNewSystemWarningShouldBeShown() {
    if ( !this.systems.activeSystem ) {
      this.shouldShowNewSystemWarning = false;
      clearTimeout(this.newSystemWarningTimeout);
      return;
    }
    const secondsAfterCreation = ((new Date().getTime()) - this.systems.activeSystem.created_at * 1000) / 1000;
    if ( secondsAfterCreation < 80 ) {
      clearTimeout(this.newSystemWarningTimeout);
      this.shouldShowNewSystemWarning = true;
      this.newSystemWarningTimeout = setTimeout(() => {
        this.shouldShowNewSystemWarning = false;
      }, (80 - secondsAfterCreation) * 1000);
    } else {
      this.shouldShowNewSystemWarning = false;
      clearTimeout(this.newSystemWarningTimeout);
    }
  }
}
