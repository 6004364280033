import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanicSettings } from 'src/api/v3/common';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { PopupService } from 'src/app/services/popup.service';
import { FooterButton } from 'src/app/ui/desktop-footer/desktop-footer.component';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { AppSettings } from 'src/environments/environment';

@Component({
  selector: 'app-cp-panic-button',
  templateUrl: './cp-panic-button.component.html',
  styleUrls: ['./cp-panic-button.component.scss'],
})
export class CpPanicButtonComponent extends LanguageAware implements OnInit {
  public classificatorText = '';
  public codeText = '';
  private original = null;
  public classificatorTextValue = 0;
  public panicSettings: PanicSettings;
  public inputChanged = false;
  public isRequestLoading = false;
  private delayDuration = 2000;
  public footerButtons: FooterButton[] = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.onDesktopSave() },
    { label: this.trans('general.cancel'),  callback: () => this.router.navigate([companyRootRoute, 'settings']) },
  ];
  public desktopEnabled = AppSettings.companyDesktopEnabled;
  public canEdit = this.us.currentUser.permissions?.permissions.panic_settings.edit;
  public val = new ValidatorBuilder<PanicSettings>()
  .required('grg_enabled', `${this.trans('validation.required').replace(':attribute', this.trans('settings.grg.label.enabled'))}`)
  .required('grg_host', `${this.trans('validation.required').replace(':attribute', this.trans('settings.grg.label.host'))}`)
  .regex('grg_host', /^.{5,255}$/, `${this.trans('validation.min.string').replace(':attribute', this.trans('settings.grg.label.host')).replace(':min', '5')}`)
  .required('grg_port', `${this.trans('validation.required').replace(':attribute', this.trans('settings.grg.label.port'))}`)
  .regex('grg_port', /^[0-9]+$/, `${this.trans('validation.numeric').replace(':attribute', this.trans('settings.grg.label.port'))}`)
  .regex('grg_port', /^\+?[1-9][0-9]{0,5}$/, `${this.trans('validation.between.numeric')
  .replace(':attribute', this.trans('settings.grg.label.port')).replace(':min', '1').replace(':max', '999999')}`)
  .required('grg_code', `${this.trans('validation.required').replace(':attribute', this.trans('settings.grg.label.code'))}`)
  .regex('grg_code', /^.{4,4}$/, `${this.trans('validation.size.string').replace(':attribute', this.trans('settings.grg.label.code')).replace(':size', '3')}`)
  .regex('grg_code', /^[RE][0-9A-Fa-f]{3}$/, `${this.trans('validation.regex').replace(':attribute', this.trans('settings.grg.label.code'))}`)
  .required('grg_zone', `${this.trans('validation.required').replace(':attribute', this.trans('settings.grg.label.zone'))}`)
  .regex('grg_zone', /^.{3,3}$/, `${this.trans('validation.size.string').replace(':attribute', this.trans('settings.grg.label.zone')).replace(':size', '3')}`)
  .regex('grg_zone', /^[0-9A-Fa-f]{3}$/, `${this.trans('validation.regex').replace(':attribute', this.trans('settings.grg.label.zone'))}`)
  .build().bindContext(this);

  constructor(cdRef: ChangeDetectorRef, private pp: PopupService, private router: Router, private messagebox: MessageboxService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.grg'),
      backUrl: this.g.getMobileSettingsUrl().join('/'),
    });
    if ( !this.us.currentUser.panic_settings ) {
      this.us.currentUser.panic_settings = {
        grg_enabled: false,
        grg_code: '',
        grg_host: '',
        grg_port: 0,
        grg_zone: '',
        company_id: this.us.currentUser.company_id,
        id: 0,
      };
    }
    this.original = JSON.stringify(this.us.currentUser.panic_settings);
    this.panicSettings = JSON.parse(this.original);
    this.generateClassificatorCodeTexts();
    this.background.setGray();
    const that = this;
    this.headerBar.onBackClicked = () => {
      that.revert();
    };
    if ( this.canEdit ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
      this.footerBar.onButton1Click = () => {
        that.revert();
        that.router.navigate(that.g.getHomeUrl());
      };
      this.footerBar.onButton2Click = () => {
        that.save();
      };
    }
  }

  ngOnInit(): void {}

  onInputChange(): void {
    this.inputChanged = this.original !== JSON.stringify(this.panicSettings);
  }

  private revert() {
    this.us.currentUser.panic_settings = JSON.parse(this.original);
  }

  private generateClassificatorCodeTexts() {
    const code = this.us.currentUser.panic_settings.grg_code;
    if (code === '') {
      this.classificatorText = 'E';
      this.codeText = '';
      return;
    }
    if (code[0] !== 'E' && code[0] !== 'R') {
      this.classificatorText = 'E';
      this.codeText = code.substr(0, 3);
    } else {
      this.classificatorText = code[0];
      this.codeText = code.substr(1, 3);
    }
    if (this.codeText.length < 3) {
      this.codeText = this.codeText.padStart(3, '0');
    }
    this.classificatorTextValue = this.panicSettings.grg_code[0] === 'E' ? 0 : 1;
  }

  public changeIp() {
    this.pp.showPopup(
      {
        headerText: this.trans('settings.grg.label.host'),
        field1: {
          maxLen: 15,
          oldValue: this.us.currentUser.panic_settings.grg_host,
        },
        onSubmitString: (res) => {
          this.us.currentUser.panic_settings.grg_host = res;
        },
      },
      PopupType.IPAddress
    );
  }

  public changePort() {
    this.pp.showPopup(
      {
        headerText: this.trans('settings.grg.label.port'),
        field1: {
          maxLen: 5,
          oldValue: this.us.currentUser.panic_settings.grg_port.toString(),
        },
        onSubmit: (res) => {
          this.us.currentUser.panic_settings.grg_port = res.value1;
        },
      },
      PopupType.Number
    );
  }

  public changeClassificator() {
    this.pp.showSlideout(
      {
        headerText: this.trans('settings.grg.label.classificator'),
        items: ['E', 'R'],
        onSubmit: (res) => {
          this.onChangeClassificatorSubmit(res);
        },
      },
      PopupType.Slideout
    );
  }

  public onChangeClassificatorSubmit(res?: any, that?: this) {
    const innerThis = that ? that : this;
    if(that) {
      innerThis.us.currentUser.panic_settings.grg_code = (res === 0 ? 'E' : 'R') + innerThis.codeText;
    } else {
      innerThis.panicSettings.grg_code = (res === 0 ? 'E' : 'R') + innerThis.codeText;
      innerThis.classificatorTextValue = res;
    }
    innerThis.generateClassificatorCodeTexts();
  }

  public changeCode() {
    this.pp.showPopup(
      {
        headerText: this.trans('settings.grg.label.code'),
        field1: {
          isHex: true,
          maxLen: 3,
          oldValue: this.codeText,
        },
        onSubmitString: (res) => {
          res = res.padStart(3, '0');
          this.onChangeCodeSubmit(res);
        },
      },
      PopupType.String
    );
  }

  public onChangeCodeSubmit(res?: string, that?: this) {
    const innerThis = that ? that : this;
    if(that) {
      innerThis.us.currentUser.panic_settings.grg_code = innerThis.classificatorText + res;
      innerThis.generateClassificatorCodeTexts();
    } else {
      innerThis.panicSettings.grg_code = innerThis.classificatorText + innerThis.codeText;
    }
  }

  private save() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/edit/panic-button', this.us.currentUser.panic_settings, true).subscribe(
      (result) => {
        this.miniStatus.hide();
        if (result.success) {
          this.router.navigate(this.g.getHomeUrl());
        } else {
          this.toaster.postError(result.error);
        }
      },
      (error) => {
        this.miniStatus.hide();
        this.toaster.postError(this.trans('auth.errors.serverSideError'));
      }
    );
  }

  public changeZone() {
    this.pp.showPopup(
      {
        headerText: this.trans('settings.grg.label.zone'),
        field1: {
          isHex: true,
          maxLen: 3,
          oldValue: this.us.currentUser.panic_settings.grg_zone,
        },
        onSubmitString: (res) => {
          if (res.length < 3) {
            res = res.padStart(3, '0');
          }
          this.us.currentUser.panic_settings.grg_zone = res;
        },
      },
      PopupType.String
    );
  }

  public async onDesktopSave(): Promise<void> {
    this.codeText = this.codeText.padStart(3, '0');
    this.onChangeCodeSubmit();
    this.panicSettings.grg_zone = this.panicSettings.grg_zone.padStart(3, '0');
    const validationResult = await this.val.validate(this.panicSettings);
    if(validationResult === false) { return; };

    this.isRequestLoading = true;
    const result = await this.api.post('/edit/panic-button', this.panicSettings, true).toPromise();
    if(result.success) {
      this.original = JSON.stringify(this.panicSettings);
      this.panicButtonSettingsSaved();
    } else {
      this.inputChanged = false;
      const errorMessage = result.error ? result.error : '';
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleError'),
        messageContent: `${this.trans('general.errors.errorOccurred')}${errorMessage !== '' ? `: ${errorMessage}` : '.'}`,
        iconType: this.messagebox.iconType.Error
      });
    }
    this.isRequestLoading = false;
  }

  private async panicButtonSettingsSaved() {
    this.isRequestLoading = false;
    await new Promise<void>(() => setTimeout(() => {
      this.us.currentUser.panic_settings = this.panicSettings;
      this.onInputChange();
    }, this.delayDuration));
  }

}
