import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-round',
  templateUrl: './loader-round.component.html',
  styleUrls: ['./loader-round.component.scss'],
})
export class LoaderRoundComponent implements OnInit {
  @Input() displayText = '';
  @Input() isVisible = false;
  @Input() overlayIsGray = false;
  @Input() isInline = false;
  @Input() height = 200;
  @Input() backgroundColor = '#FFFFFF';
  constructor() {}

  ngOnInit(): void {}
}
