import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { LocatorService } from 'src/app/services/locator.service';
import { autoinject } from 'src/shim';

@Component({
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit {
  public forgetMode = false;
  public get lang() { return autoinject(LocatorService.injector, LanguageService); }
  public get authService() { return autoinject(LocatorService.injector, AuthService); }
  constructor(private router: Router) {}

  ngOnInit(): void {}

  public goBack() {
    if ( this.authService.mergedRegionAccounts.length === 0 ) {
      this.router.navigate(['login'], { replaceUrl: true });
    } else {
      this.forgetMode = !this.forgetMode;
    }
  }
}
