import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PgmService } from 'src/app/api/system/pgm.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TCamera } from 'src/app/models/camera';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PgmData } from 'src/app/models/pgm';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss'],
})
export class CameraComponent extends LanguageAware implements OnInit {
  public cameraObject: TCamera = null;
  public pgmList: PgmData[] = [];
  public pgmLimitReached = false;
  public canEdit = this.systems.activeSystemHelper.can.edit.cameras();

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    public pgmS: PgmService,
  ) {
    super(cdRef);
    this.tag = 'camera';
    this.cameraObject = this.es.getEditableComponent(TEditableComponent.Camera) ?? {
      fullUrl: '',
      host: '',
      name: '',
      id: 0,
      pass: '',
      path: '',
      port: '',
      user: '',
      assignedPgms: [],
    };
    const textToShow = this.cameraObject.id === 0 ? this.trans('systems.titles.addNewCameras') : this.trans('systems.labels.editCamera');
    this.headerBar.showHeader({
      backUrl: '*',
      headerText: textToShow,
      actionButtonText: (this.cameraObject.id !== 0 && this.systems.activeSystemHelper.can.delete.cameras()) ? this.trans('general.delete') : '',
    });
    if ( this.canEdit ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.cameraObject.id === 0 ? this.trans('general.add') : this.trans('general.save'), true, false);
      this.headerBar.onBackClicked = () => {
        this.revertChanges(this.systems.activeSystem.id, this.cameraObject.id, this.es.getComponentBeforeModification(TEditableComponent.Camera));
      };
      this.footerBar.onButton1Click = () => {
        this.revertChanges(this.systems.activeSystem.id, this.cameraObject.id, this.es.getComponentBeforeModification(TEditableComponent.Camera));
        history.back();
      };
      this.footerBar.onButton2Click = () => {
        this.saveChanges();
        history.back();
      };
    }
    if ( this.systems.activeSystemHelper.can.delete.cameras() ) {
      this.headerBar.onActionButtonClicked = () => {
        this.deleteCamera();
        history.back();
      };
    }
    this.background.setGray();
    this.pgmList = pgmS.getPgms();
    this.pgmLimitReached = this.cameraObject.assignedPgms.length >= 3;
  }

  ngOnInit(): void {}

  private saveChanges() {
    const url = this.cameraObject.id === 0 ? '/add/camera' : '/edit/camera';
    const activeSystemId = this.systems.activeSystem.id;
    const cameraId = this.cameraObject.id;
    const cameraObj = this.cameraObject;
    if (this.cameraObject.host === '' || (this.cameraObject.pass !== '' && this.cameraObject.user === '')) {
      this.cameraObject.fullUrl = '';
    } else {
      this.cameraObject.fullUrl = 'rtsp://';
      if (this.cameraObject.user !== '' && this.cameraObject.pass !== '') {
        this.cameraObject.fullUrl += this.cameraObject.user + ':' + this.cameraObject.pass + '@';
      }
      this.cameraObject.fullUrl += this.cameraObject.host + ':' + (this.cameraObject.port || '554');
      this.cameraObject.fullUrl += this.cameraObject.path;
    }
    const before = this.es.getComponentBeforeModification(TEditableComponent.Camera);
    if (this.cameraObject.id === 0) {
      const current = this.systems.activeSystem.cameras;
      current.push(this.cameraObject);
      this.systems.activeSystem.cameras = current;
    }
    this.api.post(url, { system_id: activeSystemId, camera: this.cameraObject }, true).subscribe(
      (result) => {
        if (result.success) {
          if (cameraId === 0) {
            cameraObj.id = result.camera_id;
          }
          this.systems.saveActiveSystem(activeSystemId);
        } else {
          this.toaster.postError(result.error);
          this.revertChanges(activeSystemId, cameraId, before);
        }
      },
      (error) => {
        this.revertChanges(activeSystemId, cameraId, before);
      }
    );
  }

  private revertChanges(systemId: number, cameraId: number, before: TCamera) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const camera = system.cameras.find((c) => c.id === cameraId);
    if (camera === undefined) {
      return;
    }

    if (cameraId === 0) {
      system.cameras = system.cameras.filter((c) => c.id !== 0);
    } else {
      camera.name = before.name;
      camera.pass = before.pass;
      camera.host = before.host;
      camera.path = before.path;
      camera.port = before.port;
      camera.fullUrl = before.fullUrl;
      camera.user = before.user;
    }
    this.systems.saveActiveSystem(systemId);
  }

  private deleteCamera() {
    if (this.cameraObject.id === 0) {
      return;
    }
    if ( !this.systems.currentUserPermissions?.permissions.sys_cameras.delete ) {
      this.toaster.postError(this.trans('systems.errors.operationNotAllowed'));
      return;
    }

    const activeSystemId = this.systems.activeSystem.id;
    const cameraId = this.cameraObject.id;
    const cameraObj = this.cameraObject;
    this.systems.activeSystem.cameras = this.systems.activeSystem.cameras.filter((c) => c.id !== cameraId);
    this.api.delete(`/camera?system_id=${activeSystemId}&camera_id=${cameraId}`, true).subscribe(
      (result) => {
        if (result.success) {
          this.systems.saveActiveSystem(activeSystemId);
        } else {
          this.toaster.postError(result.error);
          this.revertDeletion(activeSystemId, cameraObj);
        }
      },
      (error) => {
        this.revertDeletion(activeSystemId, cameraObj);
      }
    );
  }

  private revertDeletion(systemId: number, before: TCamera) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }

    const current = system.cameras;
    current.push(before);
    system.cameras = current;
    this.systems.saveActiveSystem(systemId);
  }

  public handleInput(value: string, field: string) {
    let protocol = 'rtsp';
    let userName = '';
    let password = '';
    let host = '';
    let port = '';
    let path = '';
    // Ieškom kažko panašaus į rtsp://user:pass@host:port/path
    const match1 = new RegExp('^(.+)://(.+):(.+)@(.+):(.+?(?=$|/))(.*|$)');
    const match2 = new RegExp('^(.+):(.+)@(.+):(.+?(?=$|/))(.*|$)');
    const match3 = new RegExp('(.+)://(.+):(.+?(?=$|/))(.*|$)');
    const match4 = new RegExp('(.+):(.+?(?=$|/))(.*|$)');
    const unmatch = new RegExp('[/:@]');
    if (match1.test(value)) {
      const groups = match1.exec(value);
      protocol = groups[1];
      userName = groups[2];
      password = groups[3];
      host = groups[4];
      port = groups[5];
      path = groups[6];
    } else if (match2.test(value)) {
      const groups = match2.exec(value);
      protocol = 'rtsp';
      userName = groups[1];
      password = groups[2];
      host = groups[3];
      port = groups[4];
      path = groups[5];
    } else if (match3.test(value)) {
      const groups = match3.exec(value);
      protocol = groups[1];
      host = groups[2];
      port = groups[3];
      path = groups[4];
    } else if (match4.test(value)) {
      const groups = match4.exec(value);
      protocol = 'rtsp';
      host = groups[1];
      port = groups[2];
      path = groups[3];
    } else if (unmatch.test(value) && field !== 'path' && field !== 'pass') {
      this.toaster.postError(this.trans('systems.errors.badRtspLink'));
      return;
    } else {
      this.cameraObject[field] = value;
      return;
    }
    if (protocol !== 'rtsp') {
      this.toaster.postError(this.trans('systems.errors.badRtspLink'));
      return;
    }

    this.cameraObject.host = host;
    this.cameraObject.port = port;
    this.cameraObject.user = userName;
    this.cameraObject.pass = password;
    this.cameraObject.path = path;

    this.refreshWindow();
  }

  /**
   *
   * @param pgmNumber PGM queue_no
   * @param value Priskirta arba ne.
   */
  public onPgmSelect(pgmNumber: number, value: boolean) {
    const foundIndex = this.cameraObject.assignedPgms.indexOf(pgmNumber);
    if (foundIndex > -1 && !value) {
      this.cameraObject.assignedPgms.splice(foundIndex, 1);
    } else if (foundIndex === -1 && value) {
      this.cameraObject.assignedPgms.push(pgmNumber);
    }
    this.pgmLimitReached = this.cameraObject.assignedPgms.length >= 3;
  }

  /**
   *
   * @param pgmNumber PGM queue_no
   */
  public isPgmAssigned(pgmNumber: number) {
    return this.cameraObject.assignedPgms.includes(pgmNumber);
  }
}
