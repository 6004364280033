import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/app-settings';
import { UserService } from 'src/app/api/user.service';
import { TSystemData } from 'src/app/models/system-data';
import { AreaService } from 'src/app/services/area.service';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageService } from 'src/app/services/language.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { SystemsService } from 'src/app/services/systems.service';

@Component({
  selector: 'app-company-mobile-sidebar',
  templateUrl: './company-mobile-sidebar.component.html',
  styleUrls: ['./company-mobile-sidebar.component.scss'],
})
export class CompanyMobileSidebarComponent implements OnInit, OnDestroy {
  private translationSubscription = this.ls.onTranslationsChange.subscribe(() => {
    this.translationChanged();
  });
  public systemListShowing = false;
  public get newVersionAvailable() {
    return this.g.isNewVersionAvailable();
  }
  constructor(
    private cdRef: ChangeDetectorRef,
    public ls: LanguageService,
    public systems: SystemsService,
    public areaService: AreaService,
    private ns: NewSystemService,
    public us: UserService,
    public g: GlobalService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    this.translationSubscription.unsubscribe();
  }

  private translationChanged() {
    this.cdRef.markForCheck();
  }

  public trans(keyword) {
    return this.ls.get(keyword);
  }

  public toggleSystemListView() {
    this.systemListShowing = !this.systemListShowing;
  }

  public systemClicked(systemId: number) {
    if (this.systems.activeSystem.id === systemId) {
      return;
    }
    this.systems.setCurrentSystem(this.systems.getSystem(systemId));
  }

  public addSystemClick() {
    this.ns.setDefaults();
  }

  public getStatusIconForSystem(system: TSystemData) {
    if (system.areas.length !== 1) {
      return '';
    }

    return this.areaService.getIconByAreaStatusEx(system.areas[0]);
  }

  public isSystemDisarmed(system: TSystemData): boolean {
    if (system.areas.length !== 1) {
      return false;
    }

    return system.areas[0].status === 1;
  }

  public isSystemAlarmed(system: TSystemData): boolean {
    if (system.areas.length !== 1) {
      return false;
    }

    return system.areas[0].alarmed;
  }

  public showHelp(): boolean {
    return environment.showHelp;
  }

  public showVersion(): boolean {
    return environment.showAppVersion;
  }
}
