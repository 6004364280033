import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';

@Component({
  selector: 'app-no-password',
  templateUrl: './no-password.component.html',
  styleUrls: ['./no-password.component.scss'],
})
export class NoPasswordComponent extends LanguageAware implements OnInit {
  private controlsEnabled = true;

  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.tag = 'NoPass';
    this.headerBar.showHeader({
      backUrl: '/email',
      showLogo: true,
    });

    if (this.us.currentUser.email === '') {
      this.router.navigate(['/email']);
    }
  }

  ngOnInit() {}

  public resetClick() {
    if (!this.controlsEnabled) {
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    this.miniStatus.show(this.trans('general.sending'));
    this.api
      .post(
        '/reset',
        {
          lang: this.ls.selectedLanguage.value,
          email: this.us.currentUser.email,
        },
        false
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if (result.success) {
            that.toaster.postSuccess(that.trans('auth.passwordResetSent'));
            that.router.navigate(['/email']);
          } else {
            that.toaster.postError(result.error);
          }
          that.controlsEnabled = true;
        },
        (error) => {
          this.log('', error);
          that.controlsEnabled = true;
          that.miniStatus.hide();
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
        }
      );
  }
}
