<div [ngClass]="{'middle_column_grid': isInGeneralSettings}">
  <div [ngClass]="{'middle_column': isInGeneralSettings}">
    <div class="sectionHeader" *ngIf="desktopView">
        <h4 class="titleText"><app-back-button [defaultBehaviour]="isInGeneralSettings" (buttonPressed)="backButtonPressed()" [style.display]="'inline-block'"></app-back-button>{{ isNew ? trans('companies.labels.addCompany') : trans('companies.labels.editCompany') }}</h4>
        <ng-container *ngIf="!isNew && canDelete">
            <div class="delete" (click)="doDelete()">
                <h4>{{trans('general.delete')}}</h4>
                <div class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
            </div>
        </ng-container>
    </div>
    <div class="white_round padding8">
        <app-name-value [nameText]="trans('companies.labels.alias')">{{companyToEdit.alias}}&nbsp;<span (click)='copyAlias()'><svg width="11.935" height="11.935" viewBox="0 0 11.935 11.935">
            <g transform="translate(0.5 0.5)">
              <path d="M14.594,13.5h4.921a1.094,1.094,0,0,1,1.094,1.094v4.921a1.094,1.094,0,0,1-1.094,1.094H14.594A1.094,1.094,0,0,1,13.5,19.514V14.594A1.094,1.094,0,0,1,14.594,13.5Z" transform="translate(-9.673 -9.673)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
              <path d="M4.64,10.108H4.094A1.094,1.094,0,0,1,3,9.014V4.094A1.094,1.094,0,0,1,4.094,3H9.014a1.094,1.094,0,0,1,1.094,1.094V4.64" transform="translate(-3 -3)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
            </g>
          </svg></span>
        </app-name-value>
        <app-string-input type="text" label="{{trans('companies.labels.companyName')}}" [(value)]="companyToEdit.name" [hasError]="val.hasError('name')" (valueChange)="inputChanged=true" [disabled]="!canEdit">
            <app-form-error *ngIf="val.hasError('name')">{{ val.getError('name') }}</app-form-error>
        </app-string-input>
        <app-string-input type="email" label="{{trans('companies.labels.companyEmail')}}" [(value)]="companyToEdit.email" [hasError]="val.hasError('email')" (valueChange)="inputChanged=true" [disabled]="!canEdit">
            <app-form-error *ngIf="val.hasError('email')">{{ val.getError('email') }}</app-form-error>
        </app-string-input>
        <app-string-input type="text" label="{{trans('companies.labels.owner.email')}}" [(value)]="companyToEdit.registration_email" [disabled]="!us.currentUser.permissions.permissions.company_settings.edit || us.currentUser.company_id !== 0" (valueChange)="inputChanged=true" [disabled]="!canEdit">
            <app-form-error *ngIf="val.hasError('registration_email')">{{ val.getError('registration_email') }}</app-form-error>
        </app-string-input>
        <app-string-input type="text" label="{{trans('companies.labels.companyPhone')}}" [(value)]="companyToEdit.phone" [hasError]="val.hasError('phone')" (valueChange)="inputChanged=true" [disabled]="!canEdit">
            <app-form-error *ngIf="val.hasError('phone')">{{ val.getError('phone') }}</app-form-error>
        </app-string-input>
        <app-dropdown *ngIf="desktopView" label="{{trans('companies.labels.companyCountry')}}" [items]="countryList" [(value)]="companyToEdit.country" [hasError]="val.hasError('country')"  (valueChange)="inputChanged=true" [disabled]="!canEdit">
            <app-form-error *ngIf="val.hasError('country')">{{ val.getError('country') }}</app-form-error>
        </app-dropdown>
        <app-name-value *ngIf="!desktopView" [nameText]="trans('companies.labels.companyCountry')" (clicked)="selectCountry()" [isEnabled]="canEdit">
            {{countryText}}
            <app-form-error *ngIf="val.hasError('country')">{{ val.getError('country') }}</app-form-error>
        </app-name-value>
        <app-string-input type="text" label="{{trans('companies.labels.companyCity')}}" [(value)]="companyToEdit.city" [hasError]="val.hasError('city')" (valueChange)="inputChanged=true" [disabled]="!canEdit"></app-string-input>
        <app-string-input type="text" label="{{trans('companies.labels.companyAddress')}}" [(value)]="companyToEdit.address" [hasError]="val.hasError('address')" (valueChange)="inputChanged=true" [disabled]="!canEdit"></app-string-input>
        <app-string-input type="text" label="{{trans('companies.labels.companyVat')}}" [(value)]="companyToEdit.vat_id" [hasError]="val.hasError('vat_id')" (valueChange)="inputChanged=true" [disabled]="!canEdit"></app-string-input>
        <app-name-value *ngIf="us.currentUser.company_id === 0" [nameText]="trans('companies.labels.activeCompany')" [isEnabled]="canEdit">
            <app-toggle-button [buttonId]="'decp_active'" [initialState]="companyToEdit.active" (toggled)="companyToEdit.active = $event.checked; inputChanged=true"></app-toggle-button>
        </app-name-value>
        <div class="separator16"></div>
        <div *ngIf="desktopView" class="logo_container">
            <div id="suipi_logo_h">{{trans('companies.labels.uploadLogo')}}</div>
            <div id="suipi_logo_h_desc">{{trans('companies.labels.uploadLogoDesc')}}</div>
            <div>
                <input id="suipi_input" type="file" name="cpicon" accept="image/" (change)="handleNewFile($event.target.files)" #fileInput [disabled]="!canEdit">
                <label for="suipi_input">
                    <div id="suipi_upload">
                        <img id="suipi_preview" [src]="fileToUploadUrl" *ngIf="fileToUploadUrl !== null" (load)="imageLoaded($event.target)">
                        <img id="suipi_preview" [src]="companyToEdit.logo" *ngIf="companyToEdit.logo !== '' && fileToUploadUrl === null">
                        <div id="suipi_icon" *ngIf="fileToUploadUrl === null && companyToEdit.logo === ''" [inlineSVG]="'assets/images/upload.svg'"></div>
                    </div>
                </label>
                <div id="suipi_text">{{trans('companies.labels.companyLogo')}}</div>
                <app-button type="filled-secondary" *ngIf="companyToEdit.logo !== '' || fileToUploadUrl !== null" (click)="removeLogo()" [disabled]="!canEdit">{{trans('companies.labels.removeLogo')}}</app-button>
            </div>
        </div>
        <ng-component *ngIf="(inputChanged || isNew) && canEdit && desktopView">
            <ng-template appTeleport="footer">
                <app-desktop-footer [buttons]="footerButtons" [loading]="isSaving"></app-desktop-footer>
            </ng-template>
        </ng-component>
    </div>
  </div>
</div>
