import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { MessageboxService } from 'src/app/services/messagebox.service';

@Component({
  selector: 'app-transfer-device',
  templateUrl: './transfer-device.component.html',
  styleUrls: ['./transfer-device.component.scss'],
})
export class TransferDeviceComponent extends LanguageAware implements OnInit {
  public dataReceived = false;
  public message = '';
  public confirmationMessage = '';
  public onlineResult = null;

  constructor(
    cdRef: ChangeDetectorRef,
    private msg: MessageboxService
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.transferDevice'),
      backUrl: '*',
    });
    this.background.setGray();
    this.footerBar.showFooter(this.trans('systems.buttons.transfer'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.reconfirmTransfer();
    };

    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.findDevice();
  }

  ngOnInit(): void {}

  private findDevice() {
    this.api.get(`/online?imei=${this.systems.activeSystem.imei}&checkAll=true`, true).subscribe((result) => {
      this.onlineResult = null;
      this.miniStatus.hide();
      if (result.success) {
        if (result.local_version === undefined || (!result.own_region && result.remote_version === undefined)) {
          this.message = this.trans('systems.labels.cannotTransferDevice');
        } else if (!result.online) {
          this.message = this.trans('systems.titles.deviceOffline');
        } else {
          this.onlineResult = result;
          if (result.own_region && result.remote_version === undefined) {
            this.onlineResult.remote_version = 1;
          }
          this.message = this.trans('systems.labels.deviceStatus') + ': ' + (result.own_region ? result.local_version : result.remote_version);
        }
        this.dataReceived = true;
      } else {
        this.toaster.postError(result.error);
      }
    });
  }

  private reconfirmTransfer() {
    if (this.onlineResult === null) {
      return;
    }
    if (!this.onlineResult.online) {
      return;
    }
    this.confirmationMessage = this.trans('systems.labels.deviceTransferConfirmation')
      .replace(':appversion', this.onlineResult.own_region ? this.onlineResult.remote_version : this.onlineResult.local_version)
      .replace(':oldversion', this.onlineResult.own_region ? this.onlineResult.local_version : this.onlineResult.remote_version);
    this.msg.open({
      buttons: this.msg.buttons.CustomButton1Cancel,
      headerText: this.trans('general.areYouSure'),
      messageContent: this.confirmationMessage,
      button1Text: this.trans('systems.buttons.transfer'),
      showCloseButton: false,
    }, (m) => {
      m.button1Clicked.subscribe(() => { this.doTransfer(); });
    });
  }

  public doTransfer() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('systems.statuses.transfering'));
    this.api.post('/transfer-device', {
      systemUid: this.systems.activeSystem.imei,
      mpass: this.systems.activeSystem.mpass,
      srv: this.onlineResult.srv,
      toVersion: this.onlineResult.own_region ? this.onlineResult.remote_version : this.onlineResult.local_version,
    }, true).subscribe((result) => {
      this.miniStatus.hide();
      if (!result.success) {
        this.toaster.postError(result.error);
      } else {
        this.toaster.postSuccess('OK');
      }
    });
  }
}
