<div *ngIf="desktopView; else isMobile">
  <div class="sectionHeader">
    <h4 class="titleText">{{ isNew ? trans('settings.reactions.labels.addNew') : trans('settings.reactions.labels.edit') }}</h4>
    <ng-container *ngIf="!isNew && us.currentUser.permissions?.permissions.reactions.delete">
      <div class="delete" (click)="onDeleteClick()">
        <h4>{{trans('general.delete')}}</h4>
        <div class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
      </div>
    </ng-container>
  </div>
  <div class="editSection">
    <div class="{{canEdit ? '' : 'is_disabled'}}">
      <app-text-edit [value]="reactionItem && reactionItem.name" (valueChanged)="setName($event); onInputChange()" [isReadOnly]="!canEdit">{{ trans('settings.reactions.labels.nameReaction') }}</app-text-edit>
      <div id="cpegr_logo_container" class="{{canEdit ? '' : 'is_disabled'}}">
        <input id="cperl_input" type="file" name="icon" accept="image/svg" (change)="handleNewFile($event.target.files)" [readOnly]="!canEdit"/>
        <label for="cperl_input">
          <div id="cperl_upload">
            <div id="cperl_preview"><img [src]="fileToUploadUrl" *ngIf="iconChanged" (load)="imageLoaded($event.target)" /></div>
            <div id="cperl_icon" *ngIf="!iconChanged">
              <img [src]="reactionItem.image_png ? reactionItem.image_png : reactionItem.image"/>
            </div>
            <div id="cperl_text">{{ trans('settings.reactions.labels.uploadNewIcon') }}</div>
            <div id="cperl_arrow">
              <svg viewBox="0 0 8.402 14.697">
                <path
                d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z"
                transform="translate(19.653 20.891) rotate(180)"
                />
              </svg>
            </div>
          </div>
        </label>
      </div>
    </div>
    <app-name-value [nameText]="trans('general.enabled')" [isEnabled]="canEdit">
      <app-toggle-button [initialState]="reactionItem.active" (toggled)="reactionItem.active = $event.checked; onInputChange()" [buttonId]="'cper_active'"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.reactions.labels.notificationSoundIsAlert')" [isEnabled]="canEdit" [showUnderline]="false">
      <app-toggle-button [initialState]="reactionItem.custom_sound" (toggled)="reactionItem.custom_sound = $event.checked ? 1 : 0; onInputChange()" [buttonId]="'cper_sound'"></app-toggle-button>
    </app-name-value>
  </div>
  <app-event-assignment-data-table [reactionItem]="reactionItem" [assignments]="initialAssignments" (eventToggled)="eventChecked($event)"></app-event-assignment-data-table>
  <ng-component *ngIf="(isNew || inputChanged) && canEdit">
    <ng-template appTeleport="footer">
      <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
    </ng-template>
  </ng-component>
</div>


<ng-template #isMobile>
  <div class="white_round padding16">
    <div class="{{canEdit ? '' : 'is_disabled'}}">
      <app-text-edit [value]="reactionItem.name" (valueChanged)="setName($event)" [isReadOnly]="!canEdit">{{ trans('settings.reactions.labels.nameReaction') }}</app-text-edit>
      <div id="cpegr_logo_container">
        <input id="cperl_input" type="file" name="icon" accept="image/svg" (change)="handleNewFile($event.target.files)" [disabled]="!canEdit" />
        <label for="cperl_input">
          <div id="cperl_upload">
            <div id="cperl_preview"><img [src]="fileToUploadUrl" *ngIf="iconChanged" (load)="imageLoaded($event.target)" /></div>
            <div id="cperl_icon" *ngIf="!iconChanged" [inlineSVG]="reactionItem.image"></div>
            <div id="cperl_text">{{ trans('settings.reactions.labels.uploadNewIcon') }}</div>
            <div id="cperl_arrow">
              <svg viewBox="0 0 8.402 14.697"><path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/></svg>
            </div>
          </div>
        </label>
      </div>
    </div>
    <app-name-value [nameText]="trans('general.enabled')" [isEnabled]="canEdit">
      <app-toggle-button [initialState]="reactionItem.active" (toggled)="reactionItem.active = $event.checked" [buttonId]="'cper_active'"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.reactions.labels.notificationSoundIsAlert')" [isEnabled]="canEdit">
      <app-toggle-button [initialState]="reactionItem.custom_sound" (toggled)="reactionItem.custom_sound = $event.checked" [buttonId]="'cper_sound'"></app-toggle-button>
    </app-name-value>
  </div>
  <div class="bold_header" id="cper_header">
  <div id="cper_text">{{ trans('settings.reactions.labels.assignedEvents') }}</div>
  <div id="cper_plus" *ngIf="us.currentUser.permissions?.permissions.reactions.create" (click)="addEvent()">
    <span>
      <svg xmlns="http://www.w3.org/2000/svg" width="15.851" height="15.851" viewBox="0 0 15.851 15.851"><g transform="translate(-6.5 -6.5)"><path d="M18,7.5V21.351" transform="translate(-3.575)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /><path d="M7.5,18H21.351" transform="translate(0 -3.575)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /></g></svg>
    </span>
  </div>
  </div>
  <div class="white_round {{canEdit ? '' : 'is_disabled'}}">
    <div *ngFor="let iDescription of eventDescription.eventDescriptions" class="cperd_item">
      <div class="cperd_check">
        <app-checkbox
          [isSecondaryColor]="true"
          [checkValue]="iDescription.reaction_id === reactionItem.id"
          [isRound]="false"
          (valueChanged)="eventChecked(iDescription)"
        ></app-checkbox>
      </div>
      <div class="cperd_name" (click)="openEvent(iDescription)">{{ iDescription.default_name }}</div>
      <div class="cperd_status" (click)="openEvent(iDescription)">{{ iDescription.active === 1 ? trans('general.enabled') : trans('general.disabled') }}</div>
      <div class="cperd_cid" (click)="openEvent(iDescription)">
        <span>{{ iDescription.qualifier }}-{{ iDescription.event_code }}</span>
      </div>
      <div class="cperd_arrow" (click)="openEvent(iDescription)">
        <svg viewBox="0 0 8.402 14.697"><path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/></svg>
      </div>
    </div>
  </div>
</ng-template>
