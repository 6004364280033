import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AreaService } from 'src/app/services/area.service';
import { TBackgroundType } from 'src/app/models/background-types';
import { LanguageAware } from 'src/app/general/language-aware';
import { BackgroundService } from 'src/app/services/background.service';

@Component({
  selector: 'app-company-mobile-host',
  templateUrl: './company-mobile-host.component.html',
  styleUrls: ['./company-mobile-host.component.scss'],
})
export class CompanyMobileHostComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly TBackgroundType = TBackgroundType;
  public isDesktopRoleSimpleUser = this.headerBar.isDesktopRoleSimpleUser;
  public tag = 'CompanyMobileHostComponent';
  public isOnSystemHome: boolean;
  public isOnGradientBackgroundPage: boolean;
  private gradientBackgroundPageRoutes = ['home-configuration'];
  private currentSystemId: number;
  private routeParamsSubscription = this.route.paramMap.subscribe((params) => {
    this.currentSystemId = parseInt(params.get('systemId'), 10);
  });
  private routeSubscription = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      const route = this.router.url.split('/');
      this.isOnSystemHome = route[route.length - 1] === this.currentSystemId.toString() || route[route.length - 1] === 'home' ? true : false;
      this.isOnGradientBackgroundPage = this.gradientBackgroundPageRoutes.includes(route[route.length - 1]);
    }
  });
  public get backgroundClass(): string {
    switch (this.background.currentType) {
      case TBackgroundType.Gradient:
        return this.bg.currentType === TBackgroundType.Gradient ? '' : 'bg-gray';
      case TBackgroundType.White:
        return 'bg-white';
      case TBackgroundType.Gray:
        return 'bg-gray';
    }
  }
  private get bgStart() {
    return this.systems.activeSystem?.theme.startColor;
  }
  private get bgEnd() {
    return this.systems.activeSystem?.theme.endColor;
  }
  public get bgFull() {
    return this.systems.activeSystem?.theme.fullBackground;
  }
  public get backgroundStyle() {
    return {
      '--home-background-start': this.bgStart,
      '--home-background-end': this.bgEnd,
      '--full-background': this.bgFull,
    };
  }

  constructor(
    cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public areaService: AreaService,
    private router: Router,
    public bg: BackgroundService,
  ) {
    super(cdRef);
    this.log('+');
    const routeArray = this.router.url.split('/');
    this.isOnSystemHome = routeArray[routeArray.length - 1] === 'home' ? true : false;
    this.currentSystemId = parseInt(this.route.snapshot.paramMap.get('systemId'), 10);
    this.footerBar.isDesktop = this.platform.isDesktop();
    this.headerBar.isDesktop = this.platform.isDesktop();
    if( this.us.currentUser && this.us.currentUser.permissions?.permissions.systems.view ) { this.headerBar.isDesktopRoleSimpleUser = false; }
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.routeParamsSubscription.unsubscribe();
  }

  ngOnInit(): void {}

}
