<app-company-page-layout gridTemplateColumns="3fr 2fr">
  <div class="header">
    <h4>{{ trans('users.labels.users') }}</h4>
    <div class="flex-1">
      <div class="search-input-wrapper">
        <input id="searchInputField" autocomplete="off" type="text" name="searchPhrase" [(ngModel)]="filterCriteria.searchPhrase" [placeholder]="trans('general.search')" class="search-input" (keyup)="onSearchSubmit($event)" (focus)="showSearch()" />
        <div class="svg-wrapper">
          <div *ngIf="isSearchLoading" class="spinner"></div>
          <div *ngIf="searchVisible && !isSearchLoading" class="close" (click)="hideSearch()"></div>
        </div>
      </div>
    </div>
    <a routerLink="new" *ngIf="canAdd"><app-icon-button [label]="trans('users.buttons.addNew')" icon="assets/images/company/plus.svg"></app-icon-button></a>
    <app-company-columns [items]="columnConfig" [(value)]="columns.value" #cols></app-company-columns>
  </div>
  <div class="search-section" *ngIf="searchVisible">
    <ng-container *ngFor="let chip of filterChips">
      <ng-container *ngFor="let activeColumn of columns.value">
        <ng-container *ngIf="chip.name === activeColumn">
          <app-chip
            [label]="chip.label"
            [name]="chip.name"
            [isLoading]="chip.value && isSearchLoading"
            [(value)]="chip.value"
            [isDisabled]="isSearchLoading"
            (click)="onChipPress($event, chip)">
          </app-chip>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!chip.modifiableColumn && chip.name === 'inactive' && columns.value.includes('active')">
        <app-chip
          [label]="chip.label"
          [name]="chip.name"
          [isLoading]="chip.value && isSearchLoading"
          [(value)]="chip.value"
          [isDisabled]="isSearchLoading"
          (click)="onChipPress($event, chip)">
        </app-chip>
      </ng-container>
      <app-chip *ngIf="!chip.modifiableColumn && chip.name !== 'inactive'"
        [label]="chip.label"
        [name]="chip.name"
        [isLoading]="chip.value && isSearchLoading"
        [(value)]="chip.value"
        [isDisabled]="isSearchLoading"
        (click)="onChipPress($event, chip)">
      </app-chip>
    </ng-container>
  </div>
  <app-users-data-table
    [columns]="columns.value"
    (modifyColumns)="cols.show()"
    [searchLoader]="loader"
    (searchFor)="onContextSearch($event)"
  ></app-users-data-table>
</app-company-page-layout>
