import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Permission } from 'src/api/v3/common';
import { PlatformService } from '../api/platform.service';
import { UserService } from '../api/user.service';
import { GlobalService } from '../services/global.service';

/**
 * Naudojant šį Guard būtina nurodyti ir "data" su nurodyta taisykle "rule".
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  constructor(
    private us: UserService,
    private pl: PlatformService,
    private router: Router,
    private g: GlobalService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const accessLevel: keyof Permission = route.data.accessLevel ?? 'view';
    if ( !this.us.currentUser ) {
      this.us.loadUserDataFromStorage();
    }
    const result = this.us.currentUser?.permissions.permissions[route.data.rule][accessLevel] ?? false;
    if ( !result && !state.root.component ) { // Fail pirminio krovimo metu (per naršyklės URL)
      return this.router.createUrlTree(this.g.getHomeUrl());
    }
    return result;
  }
}
