import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService, AccountInfo, AccountStore } from 'src/app/api/auth.service';
import { HttpRequestError, HttpRequestErrorType, RequestService } from 'src/app/api/request.service';
import { RegionService } from 'src/app/api/region.service';
import { MiniStatusService } from './mini-status.service';
import { ToasterService } from './toaster.service';
import { LoggerService } from 'src/app/api/logger.service';
import { Router } from '@angular/router';
import { LanguageService } from './language.service';
import { ToastType } from '../models/toaster-type';
import { objectToParams } from 'src/api/v3/common';
import { UserService } from '../api/user.service';

@Injectable({
  providedIn: 'root',
})
export class ApiRequestService implements OnDestroy {
  public onNotAuthorised = null;
  private tag = 'Api';
  private tokenResetSource = new Subject<void>();
  public onTokenReset = this.tokenResetSource.asObservable();
  private httpErrorSubscibtion: Subscription;

  constructor(
    private auth: AuthService,
    private req: RequestService,
    private region: RegionService,
    private http: HttpClient,
    private us: UserService,
    private ms: MiniStatusService,
    private ts: ToasterService,
    private l: LoggerService,
    private router: Router,
    private ls: LanguageService
  ) {
    this.httpErrorSubscibtion = req.onHttpError.subscribe(this.onHttpError);
  }

  private onHttpError = {
    next: (error: HttpRequestError) => {
      switch (error.type) {
        case HttpRequestErrorType.Offline:
          let translated = this.ls.get('mobileapp.errors.noConnection');
          if (translated === 'mobileapp.errors.noConnection') {
            translated = 'No internet connection';
          }
          this.ts.postError(translated);
          return;
        case HttpRequestErrorType.Unauthorized:
          this.ms.hide();
          this.ts.post(this.ls.get('auth.sessionExpired'), ToastType.Warning, true, 5000, error.eid);
          this.logOutFromSwitcher(this.GetUserIdFromToken() ?? 0);
          this.gotoLogin();
          return;

        default:
          if ('message' in error) {
            if ('eid' in error) {
              this.ts.postError(this.resolveErrorTextByType(error.type, error.message), error.eid);
            } else {
              this.ts.postError(this.resolveErrorTextByType(error.type, error.message));
            }
          } else {
            this.ts.postError(this.ls.get('general.unknownError'));
          }
          break;
      }
    },
  };

  private resolveErrorTextByType(type: HttpRequestErrorType, message?: string): string {
    if ( message && message !== '' ) {
      return message;
    }
    if ( type === HttpRequestErrorType.Forbidden ) {
      return this.ls.get('systems.errors.operationNotAllowed');
    }

    return this.ls.get('general.unknownError');
  }

  ngOnDestroy(): void {
    this.httpErrorSubscibtion.unsubscribe();
  }

  /** Gražina API URL kurio gale dar pridedamas subpath, pvz '/v3/api' */
  getBaseUrl() {
    return this.region.regionApiUrl;
  }

  /** Gražina API URL */
  getBaseNoPath() {
    return this.region.regionBaseUrl;
  }

  /** Gražina pilną API URL */
  getUrl(appendable: string) {
    return this.getBaseUrl() + appendable;
  }

  public getAuthorizationHeader() {
    return new HttpHeaders(this.req.getHttpHeaders({ auth: true }));
  }

  getLangHeader() {
    return new HttpHeaders(this.req.getHttpHeaders({ auth: false }));
  }

  /** Vykdo POST komandą */
  /** @param path Nurodo užklausos adresą be _hostname_ */
  /** @param formData Siunčiamų duomenų objektas. */
  /** @param authenticated Nurodo ar užklausą vykdyti kaip prisijungusiu vartotoju. */
  /** @returns __JSON__ */
  post(path: string, formData: any, authenticated: boolean): Observable<any> {
    return this.req.post<any, any>(path, formData, {
      auth: authenticated,
    });
  }

  /** Vykdo DELETE komandą */
  /** @param path Nurodo užklausos adresą be _hostname_ */
  /** @param authenticated Nurodo ar užklausą vykdyti kaip prisijungusiu vartotoju. */
  /** @returns __JSON__ */
  delete(path: string, authenticated: boolean, params?: Record<string, unknown>): Observable<any> {
    return this.req.delete<any, any>(path, params, {
      auth: authenticated,
    });
  }

  /** Vykdo GET komandą */
  /** @param path Nurodo užklausos adresą be _hostname_. Taip pat privalo būti pateikti visi reikiami duomenų parametrai. */
  /** @param authenticated Nurodo ar užklausą vykdyti kaip prisijungusiu vartotoju. */
  /** @returns __JSON__ */
  get(path: string, authenticated: boolean, params?: Record<string, unknown>): Observable<any> {
    return this.req.get<any, any>(path, objectToParams(params as any), {
      auth: authenticated,
    });
  }

  /** Vykdo GET komandą. */
  /** @param path Nurodo užklausos adresą be _hostname_. Taip pat privalo būti pateikti visi reikiami duomenų parametrai. */
  /** @param authenticated Nurodo ar užklausą vykdyti kaip prisijungusiu vartotoju. */
  /** @returns __HTML__ arba __Plain text__ tipo duomenis. */
  getHtml(path: string, authenticated: boolean): Observable<any> {
    return this.req.get<string, any, 'text'>(this.getUrl(path), undefined, {
      returntype: 'text',
      auth: authenticated,
    });
  }

  getFile(path: string, authenticated: boolean): Observable<any> {
    return this.req.get<Blob, any, 'blob'>(this.getUrl(path), undefined, {
      returntype: 'blob',
      auth: authenticated,
    });
  }

  /** Funkcija netikrina ar turimas token yra geras. Ji patikrina tik ar toks išvis yra. */
  /** @returns true, kai yra išsaugotas token. */
  hasToken(): boolean {
    return this.auth.hasToken();
  }

  /** Iš local storage paima išsaugotą token. */
  /** @returns token */
  public getToken(): string {
    return this.auth.getToken();
  }

  public setToken(token: string) {
    this.auth.setToken(token);
  }

  public getLastAccount(): AccountInfo | undefined {
    return this.auth.getLastAccount();
  }
  /**
   * Before calling: must close webspcket, and clear systems
   */
  public switchAccount(account: AccountInfo) {
    this.auth.switchAccount(account);
  }

  public getAccountByEmail(email: string): AccountInfo | undefined {
    return this.auth.getAccountByEmail(email);
  }

  public get availableAccounts(): AccountInfo[] {
    return this.auth.availableAccounts;
  }

  public get hasAccounts(): boolean {
    return this.availableAccounts.length > 0;
  }

  public logOutFromSwitcher(id: number) {
    this.auth.logOutFromSwitcher(id);
  }

  public GetUserIdFromToken(): number | null {
    return this.auth.GetUserId();
  }

  private registerUserDataWithSwitcher() {
    const store = JSON.parse(localStorage.getItem('globalAccounts') ?? '{}') as AccountStore;
    const regionStore = store[this.region.regionId] ?? {};
    if (!regionStore[this.us.currentUser.id]) {
      this.l.log(`Šio user (${this.us.currentUser.id}) token niekada nebuvo registruotas store, tai WTF.`, this.tag);
      return;
    }
    if (!this.us.currentUser.name || !this.us.currentUser.email) {
      this.l.log(`UserService neturi vartotojo duomenu, nekeičiame switcher dumenu.`, this.tag);
      return;
    }
    regionStore[this.us.currentUser.id].name = this.us.currentUser.name;
    regionStore[this.us.currentUser.id].email = this.us.currentUser.email;
    regionStore[this.us.currentUser.id].role = this.us.currentUser.permissions?.role;
    regionStore[this.us.currentUser.id].loginType = this.us.getLoginType();
    store[this.region.regionId] = regionStore;
    localStorage.setItem('globalAccounts', JSON.stringify(store));
  }

  private gotoLogin() {
    this.setToken('');
    this.ms.hide();
    this.router.navigate(['/login']);
  }

  /** Patikrina ar tokenas geras. Siunčia užklausą į serverį */
  public resolveToken() {
    if (this.hasToken()) {
      this.l.log('Resolve token', this.tag);
      return new Promise((resolve, reject) => {
        this.post('/me', {}, true).subscribe(
          (data) => {
            this.l.log('Me resovled', this.tag);
            if (data.success) {
              this.us.setCurrentUser(data);
              this.us.change();
              this.us.saveCurrentUser();
              this.registerUserDataWithSwitcher();
            } else {
              this.setToken('');
              this.us.setCurrentUser(null);
              this.us.change();
              this.us.saveCurrentUser();
            }
            resolve(true);
          },
          (error) => {
            this.setToken('');
            this.us.setCurrentUser(null);
            this.us.change();
            this.us.saveCurrentUser();
            resolve(true);
          }
        );
      });
    } else {
      this.setToken('');
      this.us.setCurrentUser(null);
      this.us.change();
      this.us.saveCurrentUser();
      return false;
    }
  }

  public checkToken(operationCallback: (value: boolean) => void) {
    if (this.hasToken()) {
      this.post('/me', {}, true).subscribe(
        (data) => {
          if (data.success) {
            this.us.setCurrentUser(data);
            this.us.change();
            this.us.saveCurrentUser();
            operationCallback(true);
          } else {
            this.setToken('');
            this.us.setCurrentUser(null);
            this.us.change();
            this.us.saveCurrentUser();
            operationCallback(false);
          }
        },
        () => {
          this.setToken('');
          operationCallback(false);
        }
      );
    } else {
      if (operationCallback !== null) {
        operationCallback(false);
      }
    }
  }

  public httpSender(): HttpClient {
    return this.http;
  }
}
