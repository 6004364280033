import http from '../http';
import { BasicSuccessResponse, objectToParams, PermissionRule } from './common';

type GetPermissionRulesRequest = {
    searchPhrase?: string;
    searchFields?: string[];
    paginationPage?: number;
    include_statistics?: boolean;
    forList?: boolean;
};
type GetPermissionRuleRequest = { id: number };
type SavePermissionRuleRequest = PermissionRule;
type GetPermissionRulesResponse = BasicSuccessResponse & {
    rules: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        next_page_url: string | null;
        prev_page_url: string | null;
        from: number;
        to: number;
        data: PermissionRule[];
    };
    built_in_default: PermissionRule[];
};
export type AddPermissionRuleResponse = BasicSuccessResponse & { id: number };
type GetPermissionRuleResponse = BasicSuccessResponse & { rule: PermissionRule };

const getPermissionRule = (req: GetPermissionRuleRequest) => http.get<GetPermissionRuleResponse, URLSearchParams>('/v3/api/permission-rule', objectToParams(req));
const addPermissionRule = (req: SavePermissionRuleRequest) => http.post<AddPermissionRuleResponse, SavePermissionRuleRequest>('/v3/api/permission-rule', req);
const getPermissionRules = (req: GetPermissionRulesRequest) => http.get<GetPermissionRulesResponse, URLSearchParams>('/v3/api/permission-rules', objectToParams(req));
const savePermissionRule = (req: SavePermissionRuleRequest) => http.put<BasicSuccessResponse, SavePermissionRuleRequest>('/v3/api/permission-rule', req);
const deletePermissionRule = (req: GetPermissionRuleRequest) => http.delete<BasicSuccessResponse, URLSearchParams>('/v3/api/permission-rule', objectToParams(req));

export default {
    addPermissionRule,
    getPermissionRule,
    getPermissionRules,
    savePermissionRule,
    deletePermissionRule,
};
