import { Injectable } from '@angular/core';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { LanguageService } from '../../../../../services/language.service';
import { ExpanderType } from '../models/expander-type';
import { SP3ZoneType } from '../models/sp3-zone-type';
import { SP3Input } from '../models/sp3input';
import { SP3InputType } from '../models/sp3input-type';
import { SP3Device } from '../models/types';
import { WirelessDeviceType } from '../models/wireless-type';
import { HwfwService } from './hwfw.service';

@Injectable({
  providedIn: 'root',
})
export class ConfiguratorService {
  private changes = [];
  public currentConfiguration: SP3Device = null;
  public wirelessDeviceTypes = [
    { group: 0, type: WirelessDeviceType.Disabled, name: this.lang.get('configurators.labels.disabled') },
    { group: 1, type: WirelessDeviceType.PIR, name: this.lang.get('config_sp3.sections.wireless.types.1') },
    { group: 1, type: WirelessDeviceType.Magnet, name: this.lang.get('config_sp3.sections.wireless.types.2') },
    { group: 1, type: WirelessDeviceType.FloodDetector, name: this.lang.get('config_sp3.sections.wireless.types.3') },
    { group: 1, type: WirelessDeviceType.SmokeDetector, name: this.lang.get('config_sp3.sections.wireless.types.4') },
    { group: 1, type: WirelessDeviceType.GlassDetector, name: this.lang.get('config_sp3.sections.wireless.types.5') },
    { group: 1, type: WirelessDeviceType.Vibration, name: this.lang.get('config_sp3.sections.wireless.types.6') },
    { group: 1, type: WirelessDeviceType.Combo, name: this.lang.get('config_sp3.sections.wireless.types.7') },
    { group: 2, type: WirelessDeviceType.Siren, name: this.lang.get('config_sp3.sections.wireless.types.8') },
    { group: 3, type: WirelessDeviceType.Keyfob, name: this.lang.get('config_sp3.sections.wireless.types.9') },
    { group: 2, type: WirelessDeviceType.ShSiren, name: this.lang.get('config_sp3.sections.wireless.types.10') },
    { group: 4, type: WirelessDeviceType.KeypadSh, name: this.lang.get('config_sp3.sections.wireless.types.11') },
    { group: 1, type: WirelessDeviceType.CameraPIR, name: this.lang.get('config_sp3.sections.wireless.types.12') },
    { group: 6, type: WirelessDeviceType.Pgm2W, name: this.lang.get('config_sp3.sections.wireless.types.13') },
    { group: 1, type: WirelessDeviceType.Pgm2WZone, name: this.lang.get('config_sp3.sections.wireless.types.14') },
    { group: 5, type: WirelessDeviceType.Repeater, name: this.lang.get('config_sp3.sections.wireless.types.15') },
    { group: 1, type: WirelessDeviceType.SmokeSFW2, name: this.lang.get('config_sp3.sections.wireless.types.16') },
    { group: 1, type: WirelessDeviceType.MagnetSquare, name: this.lang.get('config_sp3.sections.wireless.types.17') },
    { group: 1, type: WirelessDeviceType.PanicButton, name: this.lang.get('config_sp3.sections.wireless.types.18') },
  ];
  public zoneDefinitions: DropdownItem<number>[] = [
    { value: 0, label: this.lang.get('config_sp3.sections.zones.definitions.0') },
    { value: 1, label: this.lang.get('config_sp3.sections.zones.definitions.1') },
    { value: 2, label: this.lang.get('config_sp3.sections.zones.definitions.2') },
    { value: 4, label: this.lang.get('config_sp3.sections.zones.definitions.3') },
    { value: 5, label: this.lang.get('config_sp3.sections.zones.definitions.4') },
    { value: 6, label: this.lang.get('config_sp3.sections.zones.definitions.5') },
    { value: 7, label: this.lang.get('config_sp3.sections.zones.definitions.6') },
    { value: 8, label: this.lang.get('config_sp3.sections.zones.definitions.7') },
    { value: 9, label: this.lang.get('config_sp3.sections.zones.definitions.8') },
    { value: 10, label: this.lang.get('config_sp3.sections.zones.definitions.9') },
  ];
  public zoneTypes: DropdownItem<number>[] = [
    { value: SP3ZoneType.NO, label: this.lang.get('config_sp3.sections.zones.zoneTypes.0') },
    { value: SP3ZoneType.NC, label: this.lang.get('config_sp3.sections.zones.zoneTypes.1') },
    { value: SP3ZoneType.EOL, label: this.lang.get('config_sp3.sections.zones.zoneTypes.2') },
    { value: SP3ZoneType.EOL_T, label: this.lang.get('config_sp3.sections.zones.zoneTypes.3') },
    { value: SP3ZoneType.ATZ, label: this.lang.get('config_sp3.sections.zones.zoneTypes.4') },
    { value: SP3ZoneType.ATZ_T, label: this.lang.get('config_sp3.sections.zones.zoneTypes.5') },
    { value: SP3ZoneType.EOL3, label: this.lang.get('config_sp3.sections.zones.zoneTypes.6') },
  ];
  public outputDefinitions: DropdownItem<number>[] = [
    { value: 0, label: this.lang.get('config_sp3.sections.outputsOutputs.definitions.siren') },
    { value: 1, label: this.lang.get('config_sp3.sections.outputsOutputs.definitions.remote') },
    { value: 3, label: this.lang.get('config_sp3.sections.outputsOutputs.definitions.fireReset') },
    { value: 4, label: this.lang.get('config_sp3.sections.outputsOutputs.definitions.systemState') },
    { value: 5, label: this.lang.get('config_sp3.sections.outputsOutputs.definitions.flash') },
    { value: 6, label: this.lang.get('config_sp3.sections.outputsOutputs.definitions.thermostat') },
    { value: 8, label: this.lang.get('config_sp3.sections.outputsOutputs.definitions.buzzer') },
  ];

  constructor(private lang: LanguageService, private fw: HwfwService) {}

  public hasChanges(): boolean {
    return this.changes.length > 0;
  }

  public clearChanges() {
    this.changes = [];
  }

  public rebuildInputs() {
    this.currentConfiguration.inputs = [];
    for (let iInternal = 0; iInternal < 10; iInternal++) {
      if (this.currentConfiguration.modules.keypads.keypadParams.additionalWiegand && (iInternal === 0 || iInternal === 1)) {
        continue;
      }
      this.currentConfiguration.inputs.push(
        new SP3Input({
          hwType: SP3InputType.Internal,
          canBeOutput: true,
          canBeInput: true,
          queueNo: iInternal + 1,
          name: this.lang.get('config_sp3.sections.zones.internalZones.' + iInternal),
        })
      );
    }
    for (let iExpander = 0; iExpander < 8; iExpander++) {
      const expanderObject = this.currentConfiguration.modules.rs485[iExpander];
      if (
        expanderObject.id === ExpanderType.iO ||
        expanderObject.id === ExpanderType.iO_WL ||
        expanderObject.id === ExpanderType.iO_MO ||
        expanderObject.id === ExpanderType.iO_LO
      ) {
        this.currentConfiguration.inputs.push(
          new SP3Input({
            hwType: SP3InputType.FirstExpander + iExpander,
            canBeOutput: true,
            canBeInput: true,
            queueNo: 1,
            name: 'RS485 ' + expanderObject.name,
          })
        );
      } else if (expanderObject.id === ExpanderType.iO8 || expanderObject.id === ExpanderType.iO8_LORA) {
        for (let iExpanderIo = 0; iExpanderIo < 8; iExpanderIo++) {
          this.currentConfiguration.inputs.push(
            new SP3Input({
              hwType: SP3InputType.FirstExpander + iExpander,
              canBeOutput: true,
              canBeInput: true,
              queueNo: 1 + iExpanderIo,
              name: 'RS485 ' + expanderObject.name + ', ' + (iExpanderIo + 1) + ' I/O',
            })
          );
        }
      } else if (expanderObject.id === ExpanderType.REL_LORA) {
        this.currentConfiguration.inputs.push(
          new SP3Input({
            hwType: SP3InputType.FirstExpander + iExpander,
            canBeOutput: true,
            canBeInput: false,
            queueNo: 1,
            name: 'RS485 ' + expanderObject.name,
          })
        );
      } else if (expanderObject.id === ExpanderType.PB_LORA) {
        this.currentConfiguration.inputs.push(
          new SP3Input({
            hwType: SP3InputType.FirstExpander + iExpander,
            canBeOutput: false,
            canBeInput: true,
            queueNo: 1,
            name: 'RS485 ' + expanderObject.name,
          })
        );
      }
    }
    for (let iWl = 0; iWl < 42; iWl++) {
      const wirelessObject = this.currentConfiguration.wireless.devices[iWl];
      const typeObject = this.wirelessDeviceTypes.find((t) => t.type === wirelessObject.type);
      let typeName = '';
      if (typeObject !== undefined) {
        typeName = typeObject.name;
      }
      let inputName = '';
      if (wirelessObject.group === 0x01) {
        inputName = this.lang.get('config_sp3.sections.zones.wirelessDeviceId').replace(':device', typeName) + (iWl + 1);
      } else if (wirelessObject.type === WirelessDeviceType.Siren) {
        inputName = this.lang.get('config_sp3.sections.zones.wlSirenTamperId') + (iWl + 1);
      } else if (wirelessObject.type === WirelessDeviceType.ShSiren) {
        inputName = this.lang.get('config_sp3.sections.zones.wlShSirenTamperId') + (iWl + 1);
      } else if (wirelessObject.type === WirelessDeviceType.KeypadSh) {
        inputName = this.lang.get('config_sp3.sections.zones.wkKeypadTamperId') + (iWl + 1);
      } else if (wirelessObject.type === WirelessDeviceType.Keyfob) {
        inputName = this.lang.get('config_sp3.sections.zones.keyfobId') + (iWl + 1);
      } else if (wirelessObject.type === WirelessDeviceType.Pgm2W) {
        inputName = this.lang.get('config_sp3.sections.wireless.types.13') + ' ID' + (iWl + 1);
      }
      if (inputName !== '') {
        this.currentConfiguration.inputs.push(
          new SP3Input({
            hwType: SP3InputType.Wireless,
            canBeOutput: wirelessObject.type === WirelessDeviceType.Pgm2W,
            canBeInput: wirelessObject.type !== WirelessDeviceType.Pgm2W,
            queueNo: 1 + iWl,
            name: inputName,
          })
        );
      }
    }
    for (let iKeypad = 0; iKeypad < 8; iKeypad++) {
      const keypadObject = this.currentConfiguration.modules.keypads.keypads[iKeypad];
      if (this.currentConfiguration.modules.keypads.keypadParams.type === 0x01 && keypadObject.sn.substr(0, 1) === '1') {
        this.currentConfiguration.inputs.push(
          new SP3Input({
            hwType: SP3InputType.Keypad,
            canBeOutput: false,
            canBeInput: true,
            queueNo: 1 + iKeypad,
            name: this.lang.get('config_sp3.sections.zones.keypadId').replace(':id', (iKeypad + 1).toString()),
          })
        );
      } else if (this.currentConfiguration.modules.keypads.keypadParams.type === 0x01 && keypadObject.sn.substr(0, 2) === '3B') {
        this.currentConfiguration.inputs.push(
          new SP3Input({
            hwType: SP3InputType.Keypad,
            canBeOutput: true,
            canBeInput: false,
            queueNo: 1,
            name: this.lang.get('config_sp3.sections.modulesKeypads.rtxPgmId').replace(':id', '1'),
          })
        );
        this.currentConfiguration.inputs.push(
          new SP3Input({
            hwType: SP3InputType.Keypad,
            canBeOutput: true,
            canBeInput: false,
            queueNo: 2,
            name: this.lang.get('config_sp3.sections.modulesKeypads.rtxPgmId').replace(':id', '2'),
          })
        );
        this.currentConfiguration.inputs.push(
          new SP3Input({
            hwType: SP3InputType.Keypad,
            canBeOutput: true,
            canBeInput: false,
            queueNo: 3,
            name: this.lang.get('config_sp3.sections.modulesKeypads.rtxPgmId').replace(':id', '3'),
          })
        );
      }
    }
    for (let iZone = 0; iZone < this.currentConfiguration.zones.length; iZone++) {
      const zoneObject = this.currentConfiguration.zones[iZone];
      if (zoneObject.type === SP3ZoneType.ATZ || zoneObject.type === SP3ZoneType.ATZ_T) {
        const foundInput = this.currentConfiguration.inputs.find((i) => i.hwType === zoneObject.hwType && i.queueNo === zoneObject.input && i.canBeInput);
        if (foundInput !== undefined) {
          this.currentConfiguration.inputs.push(
            new SP3Input({
              hwType: zoneObject.hwType === 0 ? SP3InputType.InternalATZ : zoneObject.hwType,
              canBeOutput: false,
              canBeInput: true,
              queueNo: zoneObject.hwType === 0 ? foundInput.queueNo : foundInput.queueNo + 10,
              name: foundInput.name + ' - ' + this.lang.get('config_sp3.sections.zones.atzName'),
            })
          );
        }
      }
    }
    if (this.currentConfiguration.systemOptions.systemGeneral.ledOut) {
      this.currentConfiguration.inputs.push(
        new SP3Input({
          hwType: SP3InputType.FireSensor,
          canBeOutput: false,
          canBeInput: true,
          queueNo: 0,
          name: this.lang.get('config_sp3.sections.zones.fireSensor'),
        })
      );
    }
  }

  public getVisibleZones(): any[] {
    let maxZonesToShow = 10;
    for (const iExpander of this.currentConfiguration.modules.rs485) {
      if (
        iExpander.id === ExpanderType.iO ||
        iExpander.id === ExpanderType.iO_WL ||
        iExpander.id === ExpanderType.iO_MO ||
        iExpander.id === ExpanderType.iO_LO ||
        iExpander.id === ExpanderType.PB_LORA
      ) {
        maxZonesToShow++;
      } else if (iExpander.id === ExpanderType.iO8 || iExpander.id === ExpanderType.iO8_LORA) {
        maxZonesToShow += 8;
      }
    }
    for (const iWireless of this.currentConfiguration.wireless.devices) {
      if (iWireless.group === 0x01) {
        maxZonesToShow++;
      } else if (iWireless.type === WirelessDeviceType.Siren || iWireless.type === WirelessDeviceType.ShSiren || iWireless.type === WirelessDeviceType.KeypadSh) {
        maxZonesToShow++;
      }
    }
    for (const iKeypad of this.currentConfiguration.modules.keypads.keypads) {
      if (iKeypad.sn.substr(0, 1) === '1') {
        maxZonesToShow++;
      }
    }
    // }
    for (const iZone of this.currentConfiguration.zones) {
      if (iZone.type === SP3ZoneType.ATZ || iZone.type === SP3ZoneType.ATZ_T) {
        // ATZ ir ATZ_T
        maxZonesToShow++;
      }
    }
    if (this.currentConfiguration.systemOptions.systemGeneral.ledOut) {
      maxZonesToShow++;
    }
    if ( this.fw.firmwareIsGreaterThanOrEqual('1.16') && this.fw.isRevision('2', true) ) {
      if ( maxZonesToShow > 64 ) { maxZonesToShow = 64; }
    } else if (maxZonesToShow > 32) {
      maxZonesToShow = 32;
    }
    if ( maxZonesToShow < this.currentConfiguration.zones.length ) {
      const zonesToShow = this.currentConfiguration.zones
        .filter(z => z.input !== 0 || z.hwType !== 0 || this.currentConfiguration.inputs.find((i) => i.hwType === z.hwType && i.queueNo === z.input && i.canBeInput));
      if ( zonesToShow.length > maxZonesToShow ) {
        for ( let i = this.currentConfiguration.zones.length - 1; i > 0; i-- ) {
          const iZone = this.currentConfiguration.zones[i];
          if ( iZone.hwType === 0 && iZone.input === 0 ) {
            zonesToShow.splice(i, 1);
          }
          if ( zonesToShow.length <= maxZonesToShow ) { break; }
        }
      }
      return zonesToShow;
    } else {
      return this.currentConfiguration.zones;
    }
  }

  public setCurrentConfiguration(config: any) {
    this.currentConfiguration = config;
    for (const iWireless of this.currentConfiguration.wireless.devices) {
      iWireless.voltage = '0';
      iWireless.rssi = '0';
      iWireless.temperature = '0';
    }
  }

  public getKeypadNameBySerialNumber(serialNumber: string): string {
    if (serialNumber.length < 2) {
      return this.lang.get('configurators.labels.disabled');
    }
    const two = serialNumber.substr(0, 2);
    if (two === '11') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' K636';
    } else if (two === '12') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' K10V(H)';
    } else if (two === '13') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' K32LED';
    } else if (two === '14') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' K32+LED';
    } else if (two === '1C') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' K35 LCD icons';
    } else if (two === '1E') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' Protegus 32LED';
    } else if (two === '1F') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' Protegus 16LED';
    } else if (two === '20') {
      return 'Crow CR16 Icon';
    } else if (two === '21') {
      return 'Crow CR-LCD';
    } else if (two === '1D') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' K32LCD';
    } else if (two === '1B') {
      return this.lang.get('config_sp3.sections.modulesKeypads.keypad') + ' TM50/TM70';
    } else if (two === '3B') {
      return 'RTX3 ' + this.lang.get('config_sp3.sections.modulesKeypads.transceiver');
    } else {
      return this.lang.get('configurators.labels.disabled');
    }
  }

  public isParadox(): boolean {
    const paradox = this.currentConfiguration.modules.keypads.keypads.find((k) => k.sn.length > 1 && k.sn.substr(0, 2) === '3B');
    return paradox !== undefined;
  }

  public isCrow(): boolean {
    if (this.isParadox()) {
      return false;
    }
    return this.currentConfiguration.modules.rs485.find((d) => d.id === ExpanderType.RF_SH) !== undefined;
  }
}
