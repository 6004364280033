import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MessageBoxButtons } from '../popups/messagebox/messagebox-config';
import { TSwipeCoordinate } from '../models/swipe-coordinate';
import { AppSettings } from 'src/app-settings';
import { UserService } from '../api/user.service';
import { PermissionRole } from 'src/api/v3/common';
import { PlatformService } from '../api/platform.service';
import { SystemsService } from './systems.service';
export const companyRootRoute = '/d/company';

export const dualModeDevices: number[] = [0x18, 0x30, 0x31, 0x38, 0x3a, 0x3e, 0x42, 0x43, 0x44, 0x4e];
export const indirectOnlyDevices: number[] = [0x19, 0x1c, 0x21, 0x24, 0x27, 0x2a, 0x2c, 0x2e, 0x37, 0x39, 0x3c, 0x3f, 0x41, 0x45, 0x46, 0x47, 0x48, 0x4f, 0x50, 0x51];
export const directOnlyDevices: number[] = [0x3b];
export const gFamily: number[] = [0x18, 0x19, 0x1c, 0x27, 0x2c, 0x2e, 0x30, 0x31, 0x38, 0x39, 0x3a, 0x3c, 0x3e, 0x3f, 0x41, 0x42, 0x43, 0x44, 0x45, 0x46, 0x47, 0x48, 0x4e, 0x50];
export const spFamily: number[] = [0x3b, 0x51];
export const gvFamily: number[] = [0x24, 0x37];

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public static MIN_THERMOSTAT_TEMPERATURE = -20; // reali modulio galimybe -55
  public static MAX_THERMOSTAT_TEMPERATURE = 50; // reali modulio galimybe +125
  private loaded = false;
  private redirectionURL = '';
  public onSwipeStartedSource = new Subject<TSwipeCoordinate>();
  public configurableDevice = null;
  public MessageBoxButtons = MessageBoxButtons;
  /**
   * Nurodo jog pradėtas tempimas pirštu.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeStarted = this.onSwipeStartedSource.asObservable();

  public onSwipeEndedSource = new Subject<TSwipeCoordinate>();
  /**
   * Nurodo jog baigtas tempimas pirštu. Pirštas pakeltas.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeEnded = this.onSwipeEndedSource.asObservable();

  public onSwipeContinueSource = new Subject<TSwipeCoordinate>();
  /**
   * Nurodo jog vis dar vykdomas tempimas pirštu.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeContinue = this.onSwipeContinueSource.asObservable();

  public onScrolledToBottomSource = new Subject<void>();
  public onScrolledToBottom = this.onScrolledToBottomSource.asObservable();
  public onNewVersionAvailable: EventEmitter<void> = new EventEmitter();

  constructor(private us: UserService, private platform: PlatformService, private systems: SystemsService) { }

  public isLoaded(): boolean {
    return this.loaded;
  }

  public setLoaded() {
    this.loaded = true;
  }

  public setRedirectionURL(url: string) {
    this.redirectionURL = url;
  }

  public hasRedirectionURL(): boolean {
    return this.redirectionURL.length > 0;
  }

  public getRedirectionURL(): string {
    return this.redirectionURL;
  }

  public getHomeUrl(): string[] {
    const result = this.resolveRootRoute();
    const isDekstop = this.platform.isDesktopView();
    if ( !isDekstop ) {
      if (this.us.currentUser?.permissions?.role === PermissionRole.Company) {
        result.push('company');
      } else if (this.us.currentUser?.permissions?.role === PermissionRole.Installer) {
        result.push('installer');
      } else if (this.us.currentUser?.permissions?.role === PermissionRole.SuperAdmin) {
        result.push('sadmin');
      } else {
        result.push('home');
      }
    } else {
      if ( !this.us.currentUser?.permissions.permissions.systems.view ) {
        if ( this.systems.activeSystem ) {
          result.push(this.systems.activeSystem.id.toString());
        }
      }
    }
    return result;
  }

  public getMobileSettingsUrl(): string[] {
    const urlToReturn = this.getHomeUrl();
    const isDekstop = this.platform.isDesktopView();
    if ( !isDekstop ) {
      if (
        this.us.currentUser?.permissions?.role === PermissionRole.Company ||
        this.us.currentUser?.permissions?.role === PermissionRole.Installer ||
        this.us.currentUser?.permissions?.role === PermissionRole.SuperAdmin
      ) {
        urlToReturn.push('settings');
      }
    }
    return urlToReturn;
  }

  public getAppVersion(): string {
    return AppSettings.appVersionMajor.toString() + '.' + AppSettings.appVersionMinor.toString() + '(' + AppSettings.appVersionBuild.toString() + ')';
  }

  public isNewVersionAvailable(): boolean {
    localStorage.setItem('version_check_date', new Date().toISOString());
    if (this.us.appVersionDetails === null) {
      return false;
    }
    return this.us.appVersionDetails.build > AppSettings.appVersionBuild;
  }

  public checkAndNotifyAboutNewVersion() {
    if ( this.isNewVersionAvailable() ) {
      this.onNewVersionAvailable.emit();
    }
  }

  public resolveRootRoute(): string[] {
    const result = ['/'];
    if ( this.platform.isDesktopView() ) {
      result.push('d');
      if ( this.us.currentUser?.permissions.permissions.systems.view ) {
        result.push('company');
      }
    }
    return result;
  }
}
