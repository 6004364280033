import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AccountInfo, AuthService } from 'src/app/api/auth.service';
import { LoggerService } from 'src/app/api/logger.service';
import { RegionService } from 'src/app/api/region.service';
import { RequestService } from 'src/app/api/request.service';
import { RtcService } from 'src/app/api/rtc/rtc.service';
import { SystemsService } from 'src/app/services/systems.service';
import { LoginService } from '../../login.service';
import { UserService } from 'src/app/api/user.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-account-switcher',
  templateUrl: './account-switcher.component.html',
  styleUrls: ['./account-switcher.component.scss'],
})
export class AccountSwitcherComponent implements OnInit {
  private readonly routesAllowingActiveAccountSelect = ['/login/accounts'];
  @Output() selected = new EventEmitter<void>();
  @Input() public forgetMode = false;

  constructor(
    public auth: AuthService,
    private login: LoginService,
    private router: Router,
    private rtc: RtcService,
    public region: RegionService,
    private systems: SystemsService,
    private l: LoggerService,
    private req: RequestService,
    private us: UserService,
    private ls: LanguageService
  ) {
    if(this.auth.mergedRegionAccounts.length === 0) {
      this.auth.loadMergedRegionAccounts();
    }
  }

  public readonly trans = (key: string) => this.ls.get(key);

  ngOnInit(): void {}

  public async click(account: AccountInfo) {
    if (this.forgetMode) {
      this.auth.forgetAccount(account);
      return;
    }
    await this.switchAccount(account);
  }

  public async switchAccount(account: AccountInfo) {
    if(this.us.currentUser) {
      if (account.active) {
        this.selected.emit();
        if(this.routesAllowingActiveAccountSelect.includes(this.router.url)) {
          await this.router.navigate(this.login.determineLoginDestination());
        }
        return;
      }
      await this.router.navigate(this.login.determineLoginDestination());
    }
    this.rtc.close();
    this.us.clearUserData();
    this.systems.clearSystems();
    if (!account.token) {
      this.login.email = account.email;
      this.login.password = '';
      this.selected.emit();
      this.auth.switchAccount(account);
      this.router.navigateByUrl('/login/password');
      return;
    }
    this.auth.switchAccount(account);
    try {
      await this.doRefresh();
    } catch (e) {
      this.l.log('Refresh failed', 'AccountSwitcherComponent', { e });
    }
    this.systems.loadSystems();
    this.selected.emit();
    this.region.updateActiveRegionName(this.us.userRegions);
    this.router.navigate(this.login.determineLoginDestination());
  }

  private async doRefresh() {
    this.l.log('Refresh', 'AccountSwitcherComponent');
    const result = await this.req.user.me(this.systems.activeSystem === null ? 0 : this.systems.activeSystem.id).toPromise();
    if (!result.success) {
      this.l.log('Refresh failed', 'AccountSwitcherComponent', { result });
      return;
    }
    if (!this.auth.hasToken()) {
      return;
    }
    this.auth.setToken(result.token);
    this.us.setCurrentUser(result);
    this.us.change();
    this.us.saveCurrentUser();
    this.us.allUsersLoaded = false;
    this.rtc.connect();
  }
}
