import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { TDotMenuItem } from 'src/app/models/dot-menu-item';

@Component({
  selector: 'app-cp-settings',
  templateUrl: './cp-settings.component.html',
  styleUrls: ['./cp-settings.component.scss']
})
export class CpSettingsComponent extends LanguageAware implements OnInit {
  public settingItems: TDotMenuItem[] = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('settings.title'),
      backUrl: this.g.getHomeUrl().join('/'),
    });
    this.background.setGray();

    const settingItems = [];
    if ( this.us.currentUser.permissions?.permissions.reactions.view ) {
      settingItems.push({
        name: this.trans('systems.menu.reactions'),
        action: this.g.getHomeUrl().join('/') + '/edit-reactions',
      });
      settingItems.push({
        name: this.trans('systems.menu.eventDescriptions'),
        action: this.g.getHomeUrl().join('/') + '/edit-events',
      });
    }
    if ( this.us.currentUser.permissions?.permissions.panic_settings.view ) {
      settingItems.push({
        name: this.trans('systems.menu.grg'),
        action: this.g.getHomeUrl().join('/') + '/panic-button',
      });
    }
    if ( AppSettings.regionsEnabled && this.us.currentUser.permissions?.permissions.regions.view ) {
      settingItems.push({
        name: this.trans('settings.labels.regions'),
        action: this.g.getHomeUrl().join('/') + '/regions',
      });
    }
    if ( this.us.currentUser.permissions?.permissions.ipcom_settings.view ) {
      settingItems.push({
        name: this.trans('systems.menu.ipcom'),
        action: this.g.getHomeUrl().join('/') + '/ipcoms',
      });
    }
    if (AppSettings.deviceTransferEnabled && this.us.currentUser.permissions?.permissions.transfer_device.execute ) {
      settingItems.push({
        name: this.trans('systems.titles.transferDevice'),
        action: this.g.getHomeUrl().join('/') + '/transfer-device',
      });
    }
    if ( this.us.currentUser.permissions?.permissions.global_settings.view ) {
      settingItems.push({
        name: this.trans('settings.global.title'),
        action: this.g.getHomeUrl().join('/') + '/global-settings',
      });
    }
    if ( this.us.currentUser.permissions?.permissions.roles.view ) {
      settingItems.push({
        name: this.trans('permissions.titles.list'),
        action: this.g.getHomeUrl().join('/') + '/permissions',
      });
    }
    if ( this.us.currentUser.permissions?.permissions.tags.view ) {
      settingItems.push({
        name: this.trans('tags.titles.list'),
        action: this.g.getHomeUrl().join('/') + '/tags',
      });
    }
    this.setSettingItems(settingItems);
  }

  ngOnInit(): void {
  }

  private setSettingItems(items: TDotMenuItem[]) {
    this.settingItems = items;
  }

  public contextClick(url: string) {
    this.router.navigateByUrl(url);
  }

}
