<div id='czone-grid'>
    <div class="group-background" id='czone-back'></div>
    <div id='czone-container'>
        <div class="list-item-v1 czone-item" *ngFor='let zone of systems.activeSystem.zones'>
            <div [inlineSVG]="'assets/images/zone-general-icon.svg'"></div>
            <div >{{zone.queue_no}}</div>
            <div>
                <app-text-edit [value]='zone.name' (exitedField)='submitNewName(zone.queue_no, $event)' [maxCharacters]="maxNameLen"></app-text-edit>
            </div>
            <div></div>
        </div>
    </div>
</div>