import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';

@Component({
  selector: 'app-su-transfer-device',
  templateUrl: './su-transfer-device.component.html',
  styleUrls: ['./su-transfer-device.component.scss'],
})
export class SuTransferDeviceComponent extends LanguageAware implements OnInit {
  public systemUid = '';
  public selectedIpcom = 0;
  public ipcomText = '';
  private customPort = 0;
  public customPortText = '';
  public mpass = '123456';
  private customHost = '';
  public customHostText = '';

  constructor(cdRef: ChangeDetectorRef, private pp: PopupService, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.transferDevice'),
      backUrl: this.g.getMobileSettingsUrl().join('/'),
    });
    this.background.setGray();

    const localIpcoms = this.us.currentUser.ipcoms.filter((i) => i.own_ipcom && i.host_for_devices !== '');
    if (localIpcoms.length > 0) {
      this.selectedIpcom = localIpcoms[0].id;
    }
    this.rebuildVisualData();
  }

  ngOnInit(): void {}

  private rebuildVisualData() {
    const found = this.us.currentUser.ipcoms.find((i) => i.id === this.selectedIpcom);
    if (found === undefined) {
      this.ipcomText = this.trans('general.unused');
    } else {
      this.ipcomText = found.name;
    }
    if (this.customPort === 0) {
      this.customPortText = this.trans('general.unused');
    } else {
      this.customPortText = this.customPort.toString();
    }
    if (this.customHost === '') {
      this.customHostText = this.trans('general.unused');
    } else {
      this.customHostText = this.customHost;
    }
  }

  public performTransfer() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api
      .post(
        '/transfer-device',
        {
          systemUid: this.systemUid,
          targetsrv: this.selectedIpcom,
          customHost: this.customHost,
          customPort: this.customPort,
          mpass: this.mpass,
        },
        true
      )
      .subscribe((result) => {
        that.miniStatus.hide();
        if (!result.success) {
          that.toaster.postError(result.error);
        } else {
          that.router.navigate(['/sadmin']);
        }
      });
  }

  public changeIpcom() {
    const that = this;
    const ipcoms: DropdownItem<number>[] = [];
    const ownIpcoms = this.us.currentUser.ipcoms.filter((i) => i.host_for_devices !== '');
    ipcoms.push({
      value: 0,
      label: this.trans('general.unused'),
    });
    for (const iIpcom of ownIpcoms) {
      ipcoms.push({
        value: iIpcom.id,
        label: iIpcom.own_ipcom ? '*' + iIpcom.name : iIpcom.name,
      });
    }
    this.pp.showSlideout<DropdownItem<number>>(
      {
        headerText: this.trans('users.labels.targetIpcom'),
        items: ipcoms,
        onSubmit: (res) => {
          that.selectedIpcom = res.value;
          that.ipcomText = res.label as string;
          if (res.value > 0) {
            that.customHost = '';
          }
          that.customPort = 0;
          that.rebuildVisualData();
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public changePort() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('users.labels.customPort'),
        field1: {
          minValue: 0,
          maxValue: 65535,
          oldValue: this.customPort.toString(),
        },
        onSubmit: (res) => {
          that.customPort = res.value1;
          that.rebuildVisualData();
        },
      },
      PopupType.Number
    );
  }

  public changeMpass() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('systems.labels.mpass'),
        field1: {
          maxLen: 6,
          oldValue: this.mpass,
        },
        onSubmitString: (res) => {
          that.mpass = res;
        },
      },
      PopupType.String
    );
  }

  public changeHost() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('users.labels.customHost'),
        field1: {
          maxLen: 255,
          oldValue: this.customHost,
        },
        onSubmitString: (res) => {
          that.customHost = res;
          that.rebuildVisualData();
        },
      },
      PopupType.String
    );
  }
}
