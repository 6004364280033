/* eslint-disable no-shadow */
/* eslint-disable id-blacklist */
export enum PopupType {
  None,
  /**
   * Privaloma naudoti onSubmitString callback funkciją
   */
  Password,
  /**
   * Privaloma naudoti onSubmitString callback funkciją
   */
  String,
  Number,
  NumberWithUnitList,
  TimeZone,
  /**
   * Privaloma naudoti onSubmitString callback funkciją
   */
  IPAddress,
  Slideout,
  SlideoutWithValue,
  SlideoutWithFunctionValue,
  /**
   * Privaloma naudoti onSubmitString callback funkciją
   */
  Phone,
  /**
   * Privaloma naudoti onSubmitString callback funkciją
   */
  NumericPassword,
  DateYMD,
}
