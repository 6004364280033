import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TBackgroundType } from 'src/app/models/background-types';
import { TSwipeCoordinate } from 'src/app/models/swipe-coordinate';
import { BackgroundService } from 'src/app/services/background.service';
import { GlobalService } from 'src/app/services/global.service';
import { HeaderService } from 'src/app/services/header.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoggerService } from 'src/app/api/logger.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-refresh',
  templateUrl: './refresh.component.html',
  styleUrls: ['./refresh.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('spin', [
      state('1', style({ transform: 'translateX(-50%) rotate(0deg)' })),
      state('2', style({ transform: 'translateX(-50%) rotate(360deg)' })),
      transition('1 => 2', animate('1s')),
    ]),
    trigger('textFade', [
      state('in', style({ opacity: 1 })),
      state('out', style({ opacity: 0 })),
      transition('in => out', animate('0.5s')),
      transition('out => in', animate('0.25s')),
    ]),
    trigger('spinnerFade', [
      state('out', style({ opacity: 1 })),
      state('in', style({ opacity: 0 })),
      transition('in => out', animate('0.25s')),
      transition('out => in', animate('0.5s')),
    ]),
  ],
})
export class RefreshComponent implements OnInit, OnDestroy {
  private tag = 'Refresh';
  private maxHeight = 50;
  private touchStartListener = null;
  private touchMoveListener = null;
  private touchEndListener = null;
  public touchStartedAt: TSwipeCoordinate = null;
  private lastMovementSample: Date = new Date();
  public refresherHeight = 0;
  public spinState = '1';
  public textFadeState = 'out';

  constructor(private l: LoggerService, g: GlobalService, private r: RefreshService, public lang: LanguageService, private bg: BackgroundService, private hd: HeaderService) {
    this.l.log('+', this.tag);

    const that = this;
    this.touchStartListener = g.onSwipeStarted.subscribe((coords: TSwipeCoordinate) => {
      that.handleStart(coords);
    });
    this.touchMoveListener = g.onSwipeContinue.subscribe((coords: TSwipeCoordinate) => {
      that.handleMove(coords);
    });
    this.touchEndListener = g.onSwipeEnded.subscribe((coords: TSwipeCoordinate) => {
      that.handleEnd(coords);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.touchStartListener !== null) {
      this.touchStartListener.unsubscribe();
    }
    if (this.touchMoveListener !== null) {
      this.touchMoveListener.unsubscribe();
    }
    if (this.touchEndListener !== null) {
      this.touchEndListener.unsubscribe();
    }
  }

  private handleStart(coords: TSwipeCoordinate) {
    if (!this.r.refresherIsEnabled) {
      return;
    }
    if (this.hd.sideMenuOpen) {
      return;
    }
    const contentElement = document.getElementById('content');
    if (contentElement === null) {
      return;
    }
    if (contentElement.scrollTop !== 0) {
      return;
    }
    this.touchStartedAt = coords;

    // this.l.log('pradedam drag', this.tag, contentElement.scrollTop);
  }

  private handleMove(coords: TSwipeCoordinate) {
    if (this.touchStartedAt === null) {
      return;
    }
    if (!this.r.refresherIsEnabled) {
      this.touchStartedAt = null;
      this.refresherHeight = 0;
      return;
    }
    if (new Date().getTime() - this.lastMovementSample.getTime() < 100) {
      return;
    }
    let deltaY = coords.y - this.touchStartedAt.y;
    if (deltaY < 0) {
      deltaY = 0;
    }
    if (deltaY > this.maxHeight) {
      deltaY = this.maxHeight;
    }
    if (deltaY === this.maxHeight) {
      this.textFadeState = 'in';
    } else if (this.textFadeState === 'in') {
      this.textFadeState = 'out';
    }

    this.refresherHeight = deltaY;
  }

  private handleEnd(coords: TSwipeCoordinate) {
    // this.l.log('baigem drag', this.tag);
    if (this.refresherHeight === this.maxHeight) {
      this.r.doRefresh();
    }
    this.touchStartedAt = null;
    this.refresherHeight = 0;
  }

  doRepeat(e) {
    if (this.spinState === '1') {
      this.spinState = '2';
    } else {
      this.spinState = '1';
    }
  }

  public isBackgroundWhite(): boolean {
    return this.bg.currentType === TBackgroundType.White;
  }

  public isBackgroundGray(): boolean {
    return this.bg.currentType === TBackgroundType.Gray;
  }
}
