<ng-template #headerTitle let-class="className"><div [ngClass]="class">{{trans('events.titles.event')}}</div></ng-template>
<ng-template #headerSystemId let-class="className"><div [ngClass]="class">{{trans('events.titles.system')}}</div></ng-template>
<ng-template #headerArea let-class="className"><div [ngClass]="class">{{trans('events.titles.area2')}}</div></ng-template>
<ng-template #headerFakeTag let-class="className"><div [ngClass]="class">{{trans('events.titles.tag')}}</div></ng-template>
<ng-template #headerTime let-class="className"><div [ngClass]="class">{{trans('events.titles.timeDate')}}</div></ng-template>
<ng-template #contentSystemId let-value="value"
  ><a [routerLink]="[rootRoute, 'systems', value.toString()]">{{ getSystemName(value) }}</a></ng-template
>
<ng-template #contentTime let-value="value">{{ formatDate(value) }}</ng-template>
<ng-template #contentIcon let-item="item"><img class="icon" [src]="reactions.getReactionIconUrl(item.reaction, true)" /></ng-template>
<ng-template #contentArea let-item="item">{{ item.areaId ? item.areaText : '' }}</ng-template>
<ng-template #contentFakeTag let-item="item">{{ item.tagId ? item.tagText : '' }}</ng-template>
<ng-template #row let-date="ctx.date">
  <div class="dateSpacer">
    <span>{{ date }}</span>
  </div>
</ng-template>
<ng-template #tagsContent let-value="item.tags">
  <app-inline-tag-list *ngIf="value" [tags]="value"></app-inline-tag-list>
</ng-template>
<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.events.view"
  [data]="loader"
  [dataInvalidator]="onChangeObservable"
  [rowInsertComputer]="rowInsertComputer"
  [slots]="{
    '%row': row,
    'header:title': headerTitle,
    'header:systemId': headerSystemId,
    'header:areaText': headerArea,
    'header:fake:tag': headerFakeTag,
    'header:time': headerTime,
    'fake:icon': contentIcon,
    systemId: contentSystemId,
    time: contentTime,
    areaText: contentArea,
    'fake:tag': tagsContent
  }"
  [columns]="columns"
  (modifyColumns)="modifyColumns.emit()"
  (rowContextClick)="onRightClick($event)"
></app-data-table>

<app-context-menu-data-table *ngIf="contextMenu.isVisible"
  (contextVisibilityChange)="contextMenu.isVisible = $event"
  [headerText]="contextMenu.item.title"
>
  <app-groupbox *ngIf="contextMenu.item.tags.length > 0" [headerText]="trans('systems.buttons.filterTag')" [defaultStateCollapsed]="true" [iconStyle]="'solid'">
    <ng-container *ngFor="let tag of contextMenu.item.tags">
      <a class="tag" (click)="onContextFilter('tag', tag.tag_id)">{{tag.name}}</a>
    </ng-container>
  </app-groupbox>
  <a *ngIf="contextMenu.item && contextMenu.item.event_id" class="context-text" (click)="onContextFilter('event_description')">
    {{ trans('events.filter.buttons.filterEventDesc') }}
    <p>{{contextMenu.item.eventDescriptionName}}</p>
  </a>
  <a *ngIf="contextMenu.item && contextMenu.item.systemId" class="context-text" (click)="onContextFilter('system')">
    {{ trans('events.filter.buttons.filterSystem') }}
    <p>{{ getSystemName(contextMenu.item.systemId) }}</p>
  </a>
  <a *ngIf="contextMenu.item && contextMenu.item.reaction" class="context-text" [ngClass]="{'context-text-last': contextMenu.item && contextMenu.item.user_id === 0}" (click)="onContextFilter('reaction')">
    {{ trans('events.filter.buttons.filterReaction') }}
    <p>{{ getReactionName(contextMenu.item.reaction) }}</p>
  </a>
  <a *ngIf="contextMenu.item && contextMenu.item.user_id !== 0" class="context-text context-text-last" (click)="onContextFilter('user')">
    {{ trans('events.filter.buttons.filterUser') }}
    <p>{{ contextMenu.item.user_name }}</p>
  </a>
</app-context-menu-data-table>
