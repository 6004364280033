import { Component, ElementRef, EventEmitter, HostListener, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Tag } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { TagService } from 'src/app/api/tag.service';
import { LanguageService } from 'src/app/services/language.service';
import { LocatorService } from 'src/app/services/locator.service';
import { autoinject } from 'src/shim';

@Component({
  selector: 'app-tag-input',
  templateUrl: './tag-input.component.html',
  styleUrls: ['./tag-input.component.scss']
})
export class TagInputComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() name = '';
  @Input() initialTags: Tag[] = [];
  @Input() isReadOnly = false;
  @Output() tagsChanged = new EventEmitter<void>();
  private lookupTimer;
  public _val = '';
  public loadedLookupTags: Tag[] = [];
  public selectedTags: Tag[] = [];
  public noTagsShowing = false;
  private get tagService() {return autoinject(LocatorService.injector, TagService); }
  public get val() {return this._val; };
  public set val(value: string) {
    this._val = value;
    this.noTagsShowing = false;
    clearTimeout(this.lookupTimer);

    if ( this._val.length >= 3 ) {
      this.lookupTimer = setTimeout(() => {
        this.lookUpTags();
      }, 1000);
    } else if (this.loadedLookupTags.length > 0 ) {
      this.loadedLookupTags = [];
    }
  }

  constructor(private injector: Injector, public lang: LanguageService, private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.selectedTags = this.initialTags;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ( changes.initialTags ) {
      this.selectedTags = this.initialTags ?? [];
    }
  }

  public onChange($event) {
    console.log($event);
  }

  private async lookUpTags() {
    console.log('looking up');
    const words = this._val.split(' ').filter(w => w !== '');
    this.loadedLookupTags = [];
    for ( const [i, iWord] of words.entries() ) {
      const tags = await requests.tag.getTags({searchPhrase: iWord, searchFields: ['name']}).toPromise();
      console.log('got tags', tags.list.data);
      if ( i === words.length-1 ) {
        this.loadedLookupTags = tags.list.data;
        if ( words.length > 0 ) { this._val = iWord; }
      } else if ( tags.list.data.length > 0 ) {
        const found = tags.list.data.findIndex(t => !this.selectedTags.find(st => st.id === t.id));
        if ( found !== -1 ) {
          this.tagSelected(tags.list.data[found], true);
        }
      }
    }

    this.loadedLookupTags = this.loadedLookupTags.filter(t => !this.selectedTags.find(st => st.id === t.id));
    if ( this.loadedLookupTags.length === 0 ) {
      this.noTagsShowing = true;
    }
  }

  public tagSelected(tag: Tag, selectedByLoading?: boolean) {
    this.selectedTags.push(tag);
    this.loadedLookupTags = [];
    if ( !selectedByLoading ) {
      this._val = '';
    }
    this.tagsChanged.emit();
  }

  public tagDeselected(tag: Tag) {
    if ( this.isReadOnly ) { return; }
    this.selectedTags.splice(this.selectedTags.indexOf(this.selectedTags.find(t => t.id === tag.id)), 1);
    this.tagsChanged.emit();
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && target.id !== 'toggleSettings') {
      this.noTagsShowing = false;
      this.loadedLookupTags = [];
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.noTagsShowing = false;
      this.loadedLookupTags = [];
    }
  }
}
