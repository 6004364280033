import { Injectable, NgZone } from '@angular/core';
import { ToasterService } from './toaster.service';
import { LanguageService } from './language.service';
import { MiniStatusService } from './mini-status.service';
import { ApiRequestService } from './api-request.service';
import { DeviceService } from './device.service';
import { Subject } from 'rxjs';
import { PlatformService } from '../api/platform.service';

export interface WiFiNetworkBasicData {
  ssid: string;
  level: number;
}

export interface WiFiNetworkBasicData {
  ssid: string;
  level: number;
}

@Injectable({
  providedIn: 'root',
})
export class WifiService {
  private deviceNetworks: WiFiNetworkBasicData[] = [];
  private otherNetworks: WiFiNetworkBasicData[] = [];
  private discoveryInterval = 20; // Skundemis, Android 9 leidzia daryti 4 skenavimus per 2 minutes.
  private discoveryTimer = null;
  private primaryPoller = null;
  private scanCounter = 0;
  private selectedDeviceNetwork = '';
  private selectedOtherNetwork = '';
  private currentWifiNetwork = '';
  private mobileDataWasOn = false;
  public deviceWiFiConnectionChangeSource = new Subject<string>();
  public onDeviceWifiConnectionChange = this.deviceWiFiConnectionChangeSource.asObservable();
  private deviceNetworkFound = new Subject<void>();
  public onDeviceNetworkFound = this.deviceNetworkFound.asObservable();
  private wifiErrorSource = new Subject<void>();
  public onWifiError = this.wifiErrorSource.asObservable();

  constructor(
    private toaster: ToasterService,
    private ls: LanguageService,
    private ms: MiniStatusService,
    private rs: ApiRequestService,
    private ps: PlatformService,
    private ds: DeviceService,
    zone: NgZone
  ) {
    window.wifiJsi = {
      onWiFiResult: (data) => {
        zone.run(() => {
          this.wifiNetworksReceived(data);
        });
      },
      onWiFiDeviceConnect: (result) => {
        zone.run(() => {
          this.deviceWiFiConnectionChangeSource.next(result);
        });
      },
      onCheckDeviceOnline: (uid) => {
        zone.run(() => {
          this.checkDeviceConnection(uid);
        });
      },
    };
  }

  public startWifiDiscovery() {
    if (this.discoveryTimer !== null) {
      this.ms.hide();
      return;
    }

    const that = this;
    if (this.sendStart()) {
      this.tickDiscovery();
      this.primaryPoller = setTimeout(() => {
        this.pollForNetworks();
      }, 5000);
    } else {
      this.ms.hide();
    }
  }

  private tickDiscovery() {
    const that = this;
    this.discoveryTimer = setTimeout(() => {
      that.pollForNetworks();
      that.tickDiscovery();
      that.scanCounter++;
    }, this.discoveryInterval * 1000);
  }

  public stopWifiDiscovery() {
    clearTimeout(this.discoveryTimer);
    this.discoveryTimer = null;
    clearTimeout(this.primaryPoller);
    this.primaryPoller = null;
    this.sendStop();
  }

  private sendStart(): boolean {
    if (this.ps.isAndroid()) {
      try {
        this.ps.androidHandler().startWifiDiscovery();
      } catch {
        this.toaster.postError(this.ls.get('mobileapp.errors.updateRequired'));
        this.stopWifiDiscovery();
        return false;
      }
      return true;
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().startWifiDiscovery.postMessage('');
      return true;
    } else {
      this.toaster.postError(this.ls.get('systems.errors.wifiNotPossible'));
      return false;
    }
  }

  private sendStop() {
    if (!this.ps.isMobile()) {
      return;
    }

    if (this.ps.isAndroid()) {
      this.ps.androidHandler().stopWifiDiscovery();
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().stopWifiDiscovery.postMessage('');
    }
  }

  private pollForNetworks() {
    if (!this.ps.isMobile()) {
      return;
    }

    if (this.ps.isAndroid()) {
      this.ms.show(this.ls.get('systems.statuses.scanningWifi'));
      const list = this.ps.androidHandler().getCurrentListOfWifiNetworks();
      this.wifiNetworksReceived(list);
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().getCurrentListOfWifiNetworks.postMessage('');
    }
  }

  private wifiNetworksReceived(data: string) {
    if (data === '') {
      return;
    }
    if (data.startsWith('ERR')) {
      const errorCode = data.substring(3);
      if (errorCode === 'permissions') {
        this.toaster.postError(this.ls.get('systems.errors.wifiErrorPermissions'));
        this.ms.hide();
        this.wifiErrorSource.next();
      } else if (this.scanCounter > 0) {
        this.toaster.postError(this.ls.get('systems.errors.wifiScanError'));
        this.ms.hide();
        this.wifiErrorSource.next();
      }
      // scanCounter 0 ir yra ERR, tai nieko nedarom, tiesiog leidziam palaukti dar vieno karto.
      return;
    } else if (data !== 'ERR') {
      setTimeout(() => {
        // Reikia tam, kad vartotojui labai trumpam pasimatytu loaderis, kad zinotu, jog tinklai vis dar ieskomi.
        this.ms.hide();
      }, 200);
    }

    const networks: WiFiNetworkBasicData[] = JSON.parse(data);
    const device: WiFiNetworkBasicData[] = [];
    const other: WiFiNetworkBasicData[] = [];
    networks.forEach((iNetwork) => {
      if (iNetwork.ssid.startsWith(this.ds.getDeviceWifiById(this.ds.getHwId()) + '_')) {
        device.push(iNetwork);
      } else if (iNetwork.ssid !== '') {
        other.push(iNetwork);
      }
    });

    device.forEach((iNetwork) => {
      let found = false;
      this.deviceNetworks.forEach((iHave) => {
        if (iHave.ssid === iNetwork.ssid) {
          found = true;
        }
      });
      if (!found) {
        this.deviceNetworks.push(iNetwork);
      }
    });
    this.otherNetworks = other;
    if (this.deviceNetworks.length > 0) {
      this.deviceNetworkFound.next();
    }
  }

  public hasDeviceNetworks(): boolean {
    return this.deviceNetworks.length > 0;
  }

  public hasOtherNetworks(): boolean {
    return this.otherNetworks.length > 0;
  }

  public getDeviceNetworks(): WiFiNetworkBasicData[] {
    return this.deviceNetworks;
  }

  public getOtherNetworks(): WiFiNetworkBasicData[] {
    return this.otherNetworks;
  }

  public selectDevice(ssid: string) {
    this.selectedDeviceNetwork = ssid;
  }

  public selectOther(ssid: string) {
    this.selectedOtherNetwork = ssid;
  }

  public connectToDeviceWifi(ssid: string, pass: string) {
    if (!this.ps.isMobile()) {
      return;
    }
    if (this.ps.isAndroid() && this.selectedDeviceNetwork === '') {
      return;
    }

    if (this.ps.isAndroid()) {
      this.ps.androidHandler().connectToDevice(this.selectedDeviceNetwork, ssid, pass);
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().connectToDevice.postMessage({ ssid1: this.selectedDeviceNetwork, ssid2: ssid, password: pass });
    }
  }

  public getSelectedOtherNetwork(): string {
    return this.selectedOtherNetwork;
  }

  public checkDeviceConnection(uid: string) {
    this.rs.get('/online?checkAll=true&imei=' + uid, true).subscribe(
      (result) => {
        if (result.success) {
          if (this.ps.isAndroid()) {
            this.ps.androidHandler().onDeviceConnection(result.online);
          } else if (this.ps.isApple()) {
            this.ps.appleHandler().onDeviceConnection.postMessage({ result: result.online });
          }
        } else {
          if (this.ps.isAndroid()) {
            this.ps.androidHandler().onDeviceConnection(false);
          } else if (this.ps.isApple()) {
            this.ps.appleHandler().onDeviceConnection.postMessage({ result: false });
          }
        }
      },
      (error) => {
        if (this.ps.isAndroid()) {
          this.ps.androidHandler().onDeviceConnection(false);
        } else if (this.ps.isApple()) {
          this.ps.appleHandler().onDeviceConnection.postMessage({ result: false });
        }
      }
    );
  }

  public wasMobileDataOn(): boolean {
    return this.mobileDataWasOn;
  }

  public mobileDataIsOn(value: boolean) {
    this.mobileDataWasOn = value;
  }
}
