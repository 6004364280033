<div class="white_round padding16">
    <div class="{{canEdit ? '' : 'is_disabled'}}">
        <app-text-edit [value]="userItem.email" (valueChanged)="userItem.email = $event" [inputType]="'email'" [isReadOnly]="!canEdit">{{trans('auth.form.email')}}</app-text-edit>
        <app-text-edit [value]="userItem.name" (valueChanged)="userItem.name = $event" [maxCharacters]="23" [isReadOnly]="!canEdit">{{trans('auth.form.nameAuth')}}</app-text-edit>
        <app-text-edit [value]="userItem.phone" (valueChanged)="userItem.phone = $event" [isReadOnly]="!canEdit">{{trans('auth.form.phoneField')}}</app-text-edit>
    </div>
    <app-name-value [nameText]="trans('users.labels.country')" (clicked)="changeCountry()" [isEnabled]="canEdit">{{countryText}}</app-name-value>
    <app-name-value [nameText]="trans('users.labels.permissionRule')" (clicked)="changeRule()">{{ruleName}}</app-name-value>
    <app-name-value [nameText]="trans('users.labels.role')" [isEnabled]="false">{{roleName}}</app-name-value>
    <app-name-value [nameText]="trans('users.status.active')" [isEnabled]="canEdit">
        <app-toggle-button [initialState]="userItem.active === 1" (toggled)="userItem.active = $event.checked ? 1 : 0"></app-toggle-button>
    </app-name-value>
    <app-name-value *ngIf="userItem.id === 0" [nameText]="trans('auth.form.password')" (clicked)="changePassword()" [isEnabled]="canEdit">{{newPassword === '' ? '' : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'}}</app-name-value>
    <div id="cpeu_pass" class="{{canEdit ? '' : 'is_disabled'}}" *ngIf="userItem.id !== 0" (click)="changePassword()">{{trans('users.buttons.changePassword')}}</div>
    <div *ngIf="canSeeDeviceSetupTemplate && roleSupportsTemplates">
        <p class="heading">{{ trans('setup.device.userTemplates') }}</p>
        <div *ngFor="let iTemplate of templates" class="dropdown_with_delete">
            <app-name-value (clicked)="doChangeTemplate(iTemplate.id)">{{iTemplate.name}}</app-name-value>
            <div [inlineSVG]="'assets/images/close.svg'" (click)="onTemplateDeleted(iTemplate.id)"></div>
        </div>
        <app-name-value *ngIf="canEdit && canEditDeviceSetupTemplate" [nameText]="trans('setup.device.selectUser')" (clicked)="doAddTemplate()"></app-name-value>
    </div>
</div>
