<ng-template #headerName let-class="className"><div [ngClass]="class">{{trans('setup.device.name')}}</div></ng-template>
<ng-template #headerHost let-class="className"><div [ngClass]="class">{{trans('setup.device.inputHost')}}</div></ng-template>
<ng-template #headerReceiverNr let-class="className"><div [ngClass]="class">{{trans('setup.device.receiverNumber')}}</div></ng-template>
<ng-template #headerLineNr let-class="className"><div [ngClass]="class">{{trans('setup.device.line_nr')}}</div></ng-template>
<ng-template #headerPort let-class="className"><div [ngClass]="class">{{trans('setup.device.port')}}</div></ng-template>
<ng-template #headerCompany let-class="className"><div [ngClass]="class">{{trans('setup.device.templateCompany')}}</div></ng-template>
<ng-template #headerModify let-class="className"><div [ngClass]="class"></div></ng-template>
<ng-template #headerUsers let-class="className"><div [ngClass]="class">{{trans('setup.device.templateUsers')}}</div></ng-template>

<ng-template #nameContent let-value="value">
  <span class="text">{{ value }}</span>
</ng-template>
<ng-template #hostContent let-value="value">
  <span class="text">{{ value }}</span>
</ng-template>
<ng-template #lineNrContent let-value="value">
  <span class="text" [style.text-transform]="'uppercase'">{{ value.toString(16) }}</span>
</ng-template>
<ng-template #receiverNrContent let-value="value">
  <span class="text" [style.text-transform]="'uppercase'">{{ value.toString(16) }}</span>
</ng-template>
<ng-template #portContent let-value="value">
  <span class="text">{{ value }}</span>
</ng-template>
<ng-template #companyContent let-value="value">
    {{value === 0 ? '' : companyService.getCompany(value)?.name}}
</ng-template>
<ng-template #usersContent let-value="item.users">
  {{value.length}}
</ng-template>

<ng-template #modifyContent let-item="item">
  <div class="flex gap-2" *ngIf="us.currentUser.permissions.permissions.dev_setup_templates.delete || us.currentUser.permissions.permissions.dev_setup_templates.edit">
    <div *ngIf="us.currentUser.permissions.permissions.dev_setup_templates.create" class="editIco" (click)="createTemplateCopy(item)" [inlineSVG]="'assets/images/files.svg'"></div>
    <div *ngIf="us.currentUser.permissions.permissions.dev_setup_templates.delete" class="deleteIco" (click)="onDeleteClick(item);" [inlineSVG]="'assets/images/remove.svg'"></div>
  </div>
</ng-template>

<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.dev_setup_templates.view"
  [data]="loader"
  [slots]="{
    'header:name': headerName,
    'header:host': headerHost,
    'header:port': headerPort,
    'header:receiver_nr': headerReceiverNr,
    'header:line_nr': headerLineNr,
    'header:fake:modify': headerModify,
    'header:company_id': headerCompany,
    'header:users': headerUsers,
    'name': nameContent,
    'host': hostContent,
    'line_nr': lineNrContent,
    'receiver_nr': receiverNrContent,
    'port': portContent,
    'users': usersContent,
    'company_id': companyContent,
    'fake:modify': modifyContent
  }"
  [columns]="columns"
  (modifyColumns)="modifyColumns.emit()"
  (rowClick)="onRowClick($event)"
  [dataSize]="deviceSetupTemplateService.deviceSetupTemplates.length"
>
</app-data-table>
