import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'src/shim';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';
import { PermissionRole } from 'src/api/v3/common';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';

@Component({
  selector: 'app-change-installer',
  templateUrl: './change-installer.component.html',
  styleUrls: ['./change-installer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangeInstallerComponent extends LanguageAware implements OnInit {
  private chosenInstaller = 0;
  public targetEmail = '';
  public filteredPeople = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private r: ActivatedRoute,
    private router: Router,
    private pp: PopupService,
  ) {
    super(cdRef);
    const installer = this.r.snapshot.paramMap.get('installer');
    if (installer !== null && isNumeric(installer)) {
      this.chosenInstaller = parseInt(installer, 10);
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.labels.changeInstaller'),
      backUrl: '*',
    });
    this.background.setGray();
    if (this.us.companyInstallers.length === 0) {
      this.getUsers();
    }
    this.footerBar.showFooter(this.trans('systems.buttons.changeInstaller'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.doChange();
    };
  }

  ngOnInit(): void {}

  private getUsers() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/all-users', {}, true).subscribe(
      (result) => {
        this.miniStatus.hide();
        if (result.success) {
          this.us.companyInstallers = result.users.filter((u) => this.permissionService.getPermission(u.access_permission_id)?.role === PermissionRole.Installer);
        } else {
          this.toaster.postError(result.error);
        }
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }

  private doChange() {
    this.router.navigate(['../../request-assistance/', this.chosenInstaller], { relativeTo: this.r, replaceUrl: true });
  }

  public doFilter(value: string) {
    if (value === '') {
      this.filteredPeople = [];
      return;
    }
    this.targetEmail = value;
    this.filteredPeople = this.us.companyInstallers.filter((ci) => ci.email.indexOf(value) !== -1 || ci.name.indexOf(value) !== -1);
  }

  public selectPerson(installer: any) {
    this.targetEmail = installer.email;
    this.chosenInstaller = installer.id;
    this.filteredPeople = [];
  }

  public selectFromList() {
    this.pp.showSlideout<DropdownItem<number>>(
      {
        headerText: this.trans('systems.labels.listOfInstallers'),
        items: this.us.companyInstallers.map((ci) => ({
          value: ci.id,
          label: ci.email,
        })),
        onSubmit: (res) => {
          this.targetEmail = res.label as string;
          this.chosenInstaller = res.value;
        },
      },
      PopupType.SlideoutWithValue
    );
  }
}
