<div id='home-areas-section-header'>{{systems.activeSystem.hwType === 'FC' ? trans('systems.titles.groups') : trans('systems.titles.partitions')}}</div>
<div *ngFor='let area of visibleAreas' [ngClass]="{ 'hai-container': true, 'hai-container-regular': area.status !== panicButtonsAreaStatus, 'hai-container-narrow': area.status === panicButtonsAreaStatus }">
    <div class="hai-status"><div [ngClass]="{ 'hai-armed': (area.status === 2 || area.status === 3 || area.status === 4), 'hai-disarmed': (area.status === 1 || area.status === 0 || area.status === panicButtonsAreaStatus && !area.alarmed), 'hai-changing': area.status === 5, 'hai-alarm': area.status === panicButtonsAreaStatus && area.alarmed }"></div></div>
    <div class="hai_status_alarmed" *ngIf='area.alarmed'><div [inlineSVG]="'assets/images/area_alarmed.svg'"></div></div>
    <div class="hai-header" (click)='goToArea(area.id)'><span>{{ area.name }}</span><span [inlineSVG]="'assets/images/forward.svg'"></span></div>
    <div class="hai-last-status" *ngIf="area.status !== panicButtonsAreaStatus; else panicButtonArea">
        <div [ngClass]="{ 'ale-icon': true, 'paint-armed': area.status === 2 || area.status === 4 || area.status === 3, 'paint-disarmed': area.status === 1 }" [inlineSVG]="areaService.getIconByAreaStatus(area.status, area.alarmed)"></div>
        <div class="ale-text">{{areaService.getDescriptionForStatus(area.status, area.alarmed)}}</div>
        <div class="ale-user" *ngIf='area.lastPerson !== "" && !area.alarmed'>{{trans('general.by')}} {{area.lastPerson}}</div>
        <div class="ale-time" *ngIf='!area.alarmed && area.statusTime !== 0'><span *ngIf='!dt.isTodayFromNumber(area.statusTime)'>{{dt.getDayTitleFromNumber(area.statusTime) + ' ' + trans('general.timeAt') + ' '}}</span>{{dt.formatTimeFromNumber(area.statusTime)}}</div>
    </div>
    <ng-template #panicButtonArea>
      <div class="hai-last-status">
        <ng-container *ngIf="!area.alarmed; else panicButtonAreaActivated">
          <div [ngClass]="{ 'ale-icon ale-icon-check': true, 'paint-check': true }" [inlineSVG]="'assets/images/check-simple.svg'"></div>
          <div class="ale-text">{{ trans('events.list.noEvents') }}</div>
        </ng-container>
        <ng-template #panicButtonAreaActivated>
          <div [ngClass]="{ 'ale-icon': true, 'paint-armed': true }" [inlineSVG]="'assets/images/event_alarm.svg'"></div>
          <div class="ale-text">{{ trans('reactionNames.reactions.panic') }}</div>
          <div class="ale-user" *ngIf='area.lastPerson !== ""'>{{area.lastPerson}}</div>
          <div class="ale-time" *ngIf='area.alarmTime !== 0'><span *ngIf='!dt.isTodayFromNumber(area.alarmTime)'>{{dt.getDayTitleFromNumber(area.alarmTime) + ' ' + trans('general.timeAt') + ' '}}</span>{{dt.formatTimeFromNumber(area.alarmTime)}}</div>
        </ng-template>
    </div>
    </ng-template>
    <div *ngIf="area.status !== panicButtonsAreaStatus" class="hai-buttons">
        <div *ngIf='areaService.showButtons(area.id) && canSleep()' [ngClass]="{ 'hai-button-sleep': true, 'area-action-button': true, 'area-action-arm-active': area.status === 4, 'all-buttons': true, 'haibsm': canSleep() && canStay() }" (click)='setAreaState(4, area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(4, area)' [inlineSVG]="'assets/images/sleep.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(4, area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(4, false, area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(area.id)' [ngClass]="{ 'hai-button-arm': true, 'area-action-button': true, 'area-action-arm-active': area.status === 2, 'all-buttons': canSleep() && canStay(), 'haibsm': canSleep() && canStay() }" (click)='setAreaState(2, area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(2, area)' [inlineSVG]="'assets/images/arm.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(2, area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(2, false, area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(area.id)' [ngClass]="{ 'hai-button-disarm': true, 'area-action-button': true, 'area-action-disarm-active': area.status === 1, 'all-buttons': canSleep() && canStay(), 'haibsm': canSleep() && canStay() }" (click)='setAreaState(1, area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(1, area)' [inlineSVG]="'assets/images/disarm.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(1, area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(1, false, area)}}</div>
            </div>
        </div>
        <div *ngIf='areaService.showButtons(area.id) && canStay()' [ngClass]="{ 'hai-button-stay': true, 'area-action-button': true, 'area-action-arm-active': area.status === 3, 'all-buttons': true, 'haibsm': canSleep() && canStay() }" (click)='setAreaState(3, area)'>
            <div class="hai_inner">
                <div *ngIf='!areaService.shouldShowLoading(3, area)' [inlineSVG]="'assets/images/stay.svg'"></div>
                <app-loader-dots *ngIf='areaService.shouldShowLoading(3, area)' [width]="'32px'" [height]="'32px'"></app-loader-dots>
                <div class="hai_status_text">{{areaService.getDescriptionForStatus(3, false, area)}}</div>
            </div>
        </div>
        <div *ngIf='!systems.activeSystem.directControl && !areaService.isOutputAssigned(area.id) && systems.activeSystemHelper.can.edit.areas()' id='hai-control-info' (click)='goEditArea(area.id)'>{{trans('systems.labels.clickToAssignOut')}}</div>
    </div>
    <app-take-action-button *ngIf='area.status === panicButtonsAreaStatus && area.alarmed || shouldShowTakeAction[area.id] !== undefined && shouldShowTakeAction[area.id]' class='hai_area_alarmed' [areaItem]="area"></app-take-action-button>
</div>
