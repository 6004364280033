import { Subscription } from '@angular-devkit/core/node_modules/rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Company } from 'src/api/v3/company';
import requests from 'src/api/v3/requests';
import { autoinject } from 'src/shim';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { FilterCriteria } from '../sadmin/company-list/company-list.component';
import { LanguageService } from '../services/language.service';
import { LocatorService } from '../services/locator.service';
import { DropdownItem } from '../ui/dropdown/dropdown.component';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { RegionService } from './region.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService implements OnDestroy {
  public readonly companies = new Map<number, Company>();
  public readonly listStorage: DropdownItem<number>[] = [];
  public static readonly LOGO_BASE_PATH = 'app/company_logos/';
  private tag = 'CompanyService';
  private filterCriteria: FilterCriteria = null;
  private previousFilter: string = null;
  public logoBasePath = '';
  public activeCompanyId = 0;
  private cleanUpSubscriber: Subscription;
  public listBeingLoaded = false;
  private get languageService() { return autoinject(LocatorService.injector, LanguageService); }

  constructor(
    private l: LoggerService,
    private regionService: RegionService,
    auth: AuthService,
  ) {
    this.regionService.onRegionChanged.subscribe(() => {
      this.buildBaseLogoPath();
    });
    this.buildBaseLogoPath();
    this.cleanUpSubscriber = auth.onAccountOrRegionChnage.subscribe(() => {
      this.companies.clear();
      this.filterCriteria = null;
      this.previousFilter = null;
      this.activeCompanyId = 0;
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  private buildBaseLogoPath() {
    this.logoBasePath = this.regionService.ActiveRegion.protocol
        + this.regionService.ActiveRegion.backEndHost
        + (this.regionService.ActiveRegion.port !== '' ? ':' + this.regionService.ActiveRegion.port : '')
        + '/' + CompanyService.LOGO_BASE_PATH;
  }

  public ingestCompany(company: Company) {
    if ( this.companies.has(company.id) ) { return; }
    this.l.log('Ingesting company ' + company.id, this.tag, { company });
    if ( company.logo ) {
      company.logo = this.logoBasePath + company.logo;
    }
    this.companies.set(company.id, company);
  }

  public getCompany(id: number): Company | undefined {
    return this.companies.get(id);
  }

  public async getCompanyAsync(id: number): Promise<Company | undefined> {
    if ( id === 0 ) { return; }
    const found = this.companies.get(id);
    if ( found ) { return found; }

    await this.loadCompanyById(id);
    return this.companies.get(id);
  }

  private async loadCompanyById(id: number) {
    try {
      const response = await requests.company.getCompany({id}).toPromise();
      this.ingestCompany(response.company);
    } catch (e) {
      console.log(e);
    }
  }

  public getCompaniesGetter(filterCriteria: FilterCriteria | null): DataTableGetter<Company> {
    const companyService = this;
    if ( filterCriteria ) {
      if (
        !this.filterCriteria
        || this.filterCriteria.searchPhrase !== filterCriteria.searchPhrase
        || JSON.stringify(filterCriteria.searchFields) !== JSON.stringify(this.filterCriteria.searchFields)
      ) {
        this.companies.clear();
      }
      this.previousFilter = JSON.stringify(this.filterCriteria);
      this.filterCriteria = JSON.parse(JSON.stringify(filterCriteria));
    } else if ( this.filterCriteria !== null ) {
      this.filterCriteria = null;
      this.companies.clear();
    }
    return async (current, columns, more) => {
      this.l.log('Load companies', '', { current, more });
      if ( !more ) { // data table nori atsinaujinti duomenis is atminties.
        if ( companyService.companies.size > 0 ) {
          return [...companyService.companies.values()];
        }
        if ( JSON.stringify(companyService.filterCriteria) === companyService.previousFilter ) {
          return [...companyService.companies.values()];
        }
      }
      companyService.previousFilter = JSON.stringify(companyService.filterCriteria);
      const result = await requests.company.getCompanies(filterCriteria ? {
        searchPhrase: filterCriteria.searchPhrase,
        searchFields: filterCriteria.searchFields,
        paginationPage: filterCriteria.currentPage + 1,
      } : null).toPromise();
      if ( !companyService.filterCriteria ) {
        companyService.filterCriteria = {
          searchPhrase: '',
          searchFields: [],
          currentPage: result.list.current_page,
          lastPage: result.list.last_page,
        };
        companyService.previousFilter = JSON.stringify(companyService.filterCriteria);
        if ( result.list.total === companyService.companies.size ) {
          companyService.filterCriteria.currentPage = result.list.last_page;
        }
      } else {
        companyService.filterCriteria.currentPage = result.list.current_page;
        companyService.filterCriteria.lastPage = result.list.last_page;
        companyService.previousFilter = JSON.stringify(companyService.filterCriteria);
      }
      result.list.data.forEach((c) => companyService.ingestCompany(c));
      return [...companyService.companies.values()];
    };
  }

  public clearFilter() {
    this.filterCriteria = null;
  }

  public async loadDropDownList(): Promise<void> {
    if ( this.listStorage.length > 0 ) { return; }
    if ( this.listBeingLoaded ) { return; }
    this.listBeingLoaded = true;
    try {
      const result = await requests.company.getCompanies({ forList: true }).toPromise();
      this.listStorage.push({
        value: 0,
        label: this.languageService.get('general.none')
      });
      result.list.data.forEach(r => this.listStorage.push({
        value: r.id,
        label: r.name,
      }));
    } finally {
      this.listBeingLoaded = false;
    }
  }
}
