import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SensorService } from 'src/app/api/system/sensor.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { TSensorData } from 'src/app/models/sensor-data';
import { TThermostatData } from 'src/app/models/thermostat-data';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-sensors-list',
  templateUrl: './sensors-list.component.html',
  styleUrls: ['./sensors-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SensorsListComponent extends LanguageAware implements OnInit, OnDestroy {
  public sensorList: TSensorData[] = [];
  public isAutoRefreshEnabled = false;
  private activeSystemSubscription = null;
  private refreshSubscription = null;

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
    public senS: SensorService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'Sensors';
    this.background.setGray();
    const refType = localStorage.getItem('ref');
    const backUrl = refType === 'conf' || refType === 'sens' ? '../system-configuration' : '*';
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.sensors'),
      backUrl,
      activatedRoute: this.r,
    });
    this.sensorList = this.systems.activeSystem.sensors.filter((s) => s.enabled);
    this.es.setCurrentSensor(0);
    const refreshVal = localStorage.getItem('sensor_auto_refresh_' + this.systems.activeSystem.id);
    this.isAutoRefreshEnabled = refreshVal === null || refreshVal === '1';
    const that = this;
    this.activeSystemSubscription = this.systems.onActiveSystemChange.subscribe(() => {
      that.sensorList = that.systems.activeSystem.sensors.filter((s) => s.enabled);
    });

    if (this.sensorList.length > 0) {
      senS.startWatcher();
      this.refreshSubscription = this.refresher.onRefresh.subscribe(() => {
        that.doRefresh();
      });
      this.refresher.enableRefresher();
    } else {
      senS.stopSensorWatch();
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.activeSystemSubscription !== null) {
      this.activeSystemSubscription.unsubscribe();
      this.activeSystemSubscription = null;
    }
    if (this.refreshSubscription !== null) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
    this.senS.stopSensorWatch();
  }

  public onSensorSelect(sensorQueueNo: number) {
    if (!this.systems.activeSystemHelper.can.edit.sensors()) {
      return;
    }
    this.es.setCurrentSensor(sensorQueueNo);
    this.es.setSensorBeforeModification(this.systems.activeSystem.sensors.find((s) => s.queue_no === sensorQueueNo));
    this.router.navigate(['../system-sensor'], { relativeTo: this.r });
  }

  public autoRefreshToggle(newValue: boolean) {
    this.isAutoRefreshEnabled = newValue;
    localStorage.setItem('sensor_auto_refresh_' + this.systems.activeSystem.id, newValue ? '1' : '0');
    if (this.isAutoRefreshEnabled && this.sensorList.length > 0) {
      this.senS.startWatcher();
    } else {
      this.senS.stopSensorWatch();
    }
  }

  private doRefresh() {
    this.senS.startWatcher(true);
  }

  public getThermostatUnit(thermostat: TThermostatData): string {
    const selectedSensorNumber = thermostat.assignedSensors[thermostat.activeSensor - 1];
    const sensor = this.systems.activeSystem.sensors.find((s) => s.queue_no === selectedSensorNumber);
    if (sensor === undefined) {
      return this.senS.getSensorIconData(0).unit;
    }

    return this.senS.getSensorIconData(sensor.type).unit;
  }

  public getThermostatType(thermostat: TThermostatData): number {
    const selectedSensorNumber = thermostat.assignedSensors[thermostat.activeSensor - 1];
    const sensor = this.systems.activeSystem.sensors.find((s) => s.queue_no === selectedSensorNumber);
    if (sensor === undefined) {
      return 0;
    }

    return sensor.type;
  }

  public getThermostatValue(thermostat: TThermostatData): number {
    return Math.round(thermostat.temperatures[thermostat.activeSensor - 1] * 10) / 10;
  }

  public onThermostatSelect(thermostat: TThermostatData) {
    if (!this.systems.activeSystemHelper.can.edit.thermostats()) {
      return;
    }
    this.es.beginComponentEdit(TEditableComponent.Thermostat, thermostat.id, thermostat);
    localStorage.setItem('ref', 'sens');
    this.router.navigate(['../system-thermostat'], { relativeTo: this.r });
  }
}
