<div class="grid system-sensors-grid">
    <div id='ssn-toggle-back' class="group-background"></div>
    <div id='ssn-autorefresh'>{{trans('systems.labels.autoRefresh')}}</div>
    <div id='ssn-toggle'><app-toggle-button [initialState]="isAutoRefreshEnabled" (toggled)="autoRefreshToggle($event.checked)"></app-toggle-button></div>
    <div id='ssn_thermostat_header' *ngIf='systems.activeSystem.thermostats.length > 0' class="section_header">{{trans('systems.titles.thermostats')}}</div>
    <div id='ssn_thermostat_background' class="group-background"></div>
    <div id='ssn_thermostat_list'>
        <div class="ssn-item" *ngFor='let iThermostat of systems.activeSystem.thermostats' (click)='onThermostatSelect(iThermostat)'>
            <div class='ssn-icon' [inlineSVG]="senS.getSensorIcon(getThermostatType(iThermostat))"></div>
            <div class='ssn-number'>{{iThermostat.queueNo}}</div>
            <div class='ssn_text'>{{iThermostat.name}}</div>
            <div class='ssn_left_pipe'></div>
            <div class='ssn_value_container'>
                <div class="ssn_type_name">{{trans('sensors.labels.setTemperature')}}</div>
                <div class="ssn_value">{{getThermostatValue(iThermostat)}} {{getThermostatUnit(iThermostat)}}</div>
            </div>
            <div class='ssn_right_pipe'></div>
            <div class='ssn_arrow' *ngIf='systems.activeSystemHelper.can.edit.thermostats()'><div [inlineSVG]="'assets/images/forward.svg'"></div></div>
        </div>
    </div>
    <div id='ssn_sensor_header' *ngIf='systems.activeSystem.thermostats.length > 0' class="section_header">{{trans('systems.titles.sensors')}}</div>
    <div id='ssn-background' class="group-background"></div>
    <div id='ssn-list'>
        <div class="ssn-item" *ngFor='let iSensor of sensorList' (click)='onSensorSelect(iSensor.queue_no)'>
            <div class='ssn-icon' [inlineSVG]="senS.getSensorIcon(iSensor.type)"></div>
            <div class='ssn-number'>{{iSensor.queue_no}}</div>
            <div class='ssn_text'>{{iSensor.name}}</div>
            <div class='ssn_left_pipe'></div>
            <div class='ssn_value_container'>
                <div class="ssn_type_name">{{senS.getSensorIconData(iSensor.type).name}}</div>
                <div class="ssn_value">{{iSensor.temperature === '' ? '--' : iSensor.temperature}} {{senS.getSensorIconData(iSensor.type).unit}}</div>
            </div>
            <div class='ssn_right_pipe'></div>
            <div class='ssn_arrow' *ngIf='systems.activeSystemHelper.can.edit.sensors()'><div [inlineSVG]="'assets/images/forward.svg'"></div></div>
        </div>
    </div>
</div>