import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Sanitizer } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegionService } from 'src/app/api/region.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { TRegionListItemData } from 'src/app/models/region-list-item-data';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { DataTableGetter } from '../data-table/data-table.component';
type RowData = {
  column: string;
  item: TRegionListItemData;
  row: number;
};
type EditState = {
  id: number;
  column: string;
};
type PreviousEditItemState = EditState & {
  hasChanged: boolean;
};

@Component({
  selector: 'app-regions-data-table',
  templateUrl: './regions-data-table.component.html',
  styleUrls: ['./regions-data-table.component.scss'],
})
export class RegionsDataTableComponent extends LanguageAware implements OnInit, OnDestroy {
  @Input() public columns: string[] = ['name', 'api_host', 'api_path', 'region_version', 'fake:delete'];
  @Output() public modifyColumns = new EventEmitter<void>();
  public loader: DataTableGetter<TRegionListItemData>;
  public regionItemInEdit: EditState;
  public prevRegionItemInEdit: PreviousEditItemState;
  public regionItemToSave: TRegionListItemData;
  private componentBeforeModification: TRegionListItemData;
  public idsWithError = new Set<number>();
  private subscriptions: Subscription[] = [];
  public isLoading = false;
  public isSaved = false;
  private hasMessageboxOpen = false;
  private loadingStatusVisibilityDuration = 500;
  private savedStatusVisibilityDuration = 1000;
  private errorStatusVisibilityDuration = 1000;
  private savedStatusTimer;
  private loadingStatusTimer;

  constructor(
    cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService,
    public edit: EditSystemService,
    private router: Router,
    public region: RegionService
  ) {
    super(cdRef);
    this.loader = this.us.getRegionsGetter();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(event.key === 'Escape' && this.regionItemInEdit) { this.onCancelButtonClick(); }
    if(event.key === 'Enter' && this.regionItemInEdit) { this.onSaveButtonClick(); }
  }

  public async onEditButtonClick(item: any, column: string): Promise<void> {
    if(this.regionItemInEdit) {
      this.prevRegionItemInEdit = { ...this.regionItemInEdit, hasChanged: false};
      await this.exitRegionFieldInput();
      this.regionItemInEdit = { id: item.id, column };
    }
    this.regionItemInEdit = { id: item.id, column };
    this.edit.beginComponentEdit(TEditableComponent.Region, item.id, item);
    this.componentBeforeModification = this.edit.getComponentBeforeModification(TEditableComponent.Region);
    this.regionItemToSave = this.edit.getEditableComponent(TEditableComponent.Region);
  }

  private async exitRegionFieldInput(): Promise<void> {
    if(JSON.stringify(this.regionItemToSave) !== JSON.stringify(this.componentBeforeModification)) {
      this.prevRegionItemInEdit.hasChanged = true;
      await this.saveRegionItemChanges();
    }
    this.exitEditState();
  }

  private clearTimers() {
    if(this.savedStatusTimer) { clearTimeout(this.savedStatusTimer); };
    if(this.loadingStatusTimer) { clearTimeout(this.loadingStatusTimer); };
  }

  public onSaveButtonClick() {
    if(this.isSaved) { return; }
    this.prevRegionItemInEdit = { ...this.regionItemInEdit, hasChanged: false };
    this.exitRegionFieldInput();
  }

  public onCancelButtonClick() {
    Object.assign(this.regionItemToSave, this.componentBeforeModification);
    this.exitEditState();
  }

  private async saveRegionItemChanges(): Promise<void> {
    this.clearTimers();
    await this.editRegionApiRequest();
  }

  public exitEditState() {
    this.edit.endComponentEdit(TEditableComponent.Region);
    this.regionItemInEdit = undefined;
  }

  private editRegionApiRequest(): Promise<void> {
    this.isLoading = true;
    this.isSaved = false;
    const itemId = this.regionItemToSave.id;
    return new Promise(resolve => {
      this.api.post('/region', this.regionItemToSave, true).subscribe(
        async result => {
        if(result.success) {
          if(this.componentBeforeModification && this.idsWithError.has(itemId)) { this.idsWithError.delete(itemId); };
          this.loadingStatusTimer = setTimeout(() => {
            this.isLoading = false;
            this.isSaved = true;
            this.savedStatusTimer = setTimeout(() => {
              this.isSaved = false;
              this.prevRegionItemInEdit = undefined;
            }, this.savedStatusVisibilityDuration);
          }, this.loadingStatusVisibilityDuration);
        } else {
          if(!this.componentBeforeModification) {
            this.componentBeforeModification = this.edit.getComponentBeforeModification(TEditableComponent.Region);
          }
          this.idsWithError.add(itemId);

          if(!this.hasMessageboxOpen) {
            this.hasMessageboxOpen = true;
            this.messagebox.open({
              buttons: this.messagebox.buttons.Ok,
              headerText: this.trans('general.titleError'),
              iconType: this.messagebox.iconType.Error,
              messageContent: result.error ? result.error : this.trans('general.errors.errorOccurred')
            },
            (messagebox) => {
              Object.assign(this.regionItemToSave, this.componentBeforeModification);
              const messageboxClose = messagebox.messageboxClose.subscribe(() => {
                this.hasMessageboxOpen = false;
                setTimeout(() => {
                  this.idsWithError.clear();
                }, this.errorStatusVisibilityDuration);
              });
              this.subscriptions.push(messageboxClose);
            });
          }
          this.isLoading = false;
          this.isSaved = false;
        }
        resolve();
      }, (error) => {
        this.messagebox.open({
          buttons: this.messagebox.buttons.Ok,
          headerText: this.trans('general.titleError'),
          iconType: this.messagebox.iconType.Error,
          messageContent: this.trans('general.errors.errorOccurred')
        });
      });
    });
  }

  public onRowClick(event: RowData): void {
    if(event.column === 'fake:delete') {
      this.onDeleteClick(event.item);
    }
  }

  public openRegionForEditing(item) {
    this.edit.beginComponentEdit(TEditableComponent.Region, item.id, item);
    this.router.navigate([companyRootRoute, 'settings', 'regions', 'edit', item.id]);
  }

  private onDeleteClick(item): void {
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('settings.labels.deleteRegionInfo').replace(':region', item.name)}: ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deletePostRequest(item);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private deletePostRequest(item) {
    const that = this;
    const region = that.us.currentUser.regions.find((reg) => reg.id === item.id);
    if (region === undefined) {
      return;
    }
    that.api.delete('/region?id=' + item.id, true).subscribe(
      (result) => {
        if (result.success) {
            const itemInEdit = this.edit.getEditableComponent(TEditableComponent.Region);
            if(itemInEdit && itemInEdit.id === item.id) {
              that.editSystemService.endComponentEdit(TEditableComponent.Region);
              this.router.navigate([companyRootRoute, 'settings', 'regions']);
            }
          that.us.currentUser.regions.splice(that.us.currentUser.regions.indexOf(region), 1);
          that.messagebox.open({
            buttons: that.messagebox.buttons.Close,
            headerText: that.trans('general.titleSuccess'),
            messageContent: that.trans('systems.labels.deletedSuccessfully'),
            iconType: that.messagebox.iconType.Success
          });
        } else {
          const errorMessage = result.error ? result.error : '';
          that.messagebox.open({
            buttons: that.messagebox.buttons.Close,
            headerText: that.trans('general.titleError'),
            messageContent: `${that.trans('general.errors.errorOccurred')}${errorMessage !== '' ? `: ${errorMessage}` : '.'}`,
            iconType: that.messagebox.iconType.Error
          });
        }
      },
      (error) => {
        that.messagebox.open({
          buttons: that.messagebox.buttons.Close,
          headerText: that.trans('general.titleError'),
          messageContent: that.trans('auth.errors.serverSideError'),
          iconType: that.messagebox.iconType.Error
        });
      }
    );
  }

}
