import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { GoogleAuthService } from 'src/app/api/google-auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { LoginService } from '../../login.service';

export const emailValidator = new ValidatorBuilder<{
  email: string;
}>()
  .required('email', 'EMAIL_REQUIRED')
  .regex('email', /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, 'EMAIL_INVALID');

@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss'],
})
export class LoginEmailComponent implements OnInit {
  public isLoading = false;
  public val = emailValidator.build();
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(
    public login: LoginService,
    private router: Router,
    public googleAuth: GoogleAuthService,
    private ls: LanguageService,
    public auth: AuthService
  ) { }

  ngOnInit(): void {}

  public async next() {
    this.isLoading = true;
    if (!(await this.val.validate(this.login))) {
      this.isLoading = false;
      return;
    }
    const res = await this.login.checkEmail(this.login.email).toPromise();
    if (res.result === 'local') {
      if (res.noPassword) {
        this.val.pushError('email', 'NO_PASSWORD');
        this.isLoading = false;
        return;
      }
      this.router.navigate(['login', 'password'], { replaceUrl: true });
      return;
    } else if (res.result === 'disabled') {
      this.val.pushError('email', 'DISABLED_USER');
    } else if (res.result === 'no_user') {
      this.val.pushError('email', 'NO_USER');
    } else if (res.result === 'social') {
      switch (res.type) {
        case 'google':
          this.val.pushError('email', 'SOCIAL_GOOGLE');
          break;
        default:
          this.val.pushError('email', 'SOCIAL');
          break;
      }
    }
    this.isLoading = false;
  }
}
