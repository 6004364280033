import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/api/user.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-settings-list',
  templateUrl: './settings-list.component.html',
  styleUrls: ['./settings-list.component.scss']
})
export class SettingsListComponent implements OnInit, AfterViewInit {
  @Output() visibilityChange = new EventEmitter<boolean>();
  public settings = [];

  constructor(
    public router: Router,
    private us: UserService,
    private editSystemService: EditSystemService,
    private lang: LanguageService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    if ( this.us.currentUser.permissions.permissions.company_settings.view && this.us.currentUser.company_id !== 0 ) {
      this.settings.push({
        title: this.lang.get('companies.titles.companySettings'),
        icon: 'assets/images/company/settings/gears.svg',
        callback: () => {
          this.editSystemService.beginComponentEdit(TEditableComponent.Company, this.us.currentUser.belongsToCompany.id, this.us.currentUser.belongsToCompany);
          this.openSettingSection('general');
        }
      });
    }
    if ( this.us.currentUser.permissions.permissions.reactions.view ) {
      this.settings.push({
        title: this.lang.get('systems.menu.reactions'),
        icon: 'assets/images/company/settings/reaction-types.svg',
        callback: () => { this.openSettingSection('reaction-types'); }
      });
      this.settings.push({
        title: this.lang.get('systems.menu.eventDescriptions'),
        icon: 'assets/images/company/settings/bell.svg',
        callback: () => { this.openSettingSection('event-descriptions'); }
      });
    }
    if ( this.us.currentUser.permissions.permissions.panic_settings.view ) {
      this.settings.push({
        title: this.lang.get('systems.menu.grg'),
        icon: 'assets/images/company/settings/sos.svg',
        callback: () => { this.openSettingSection('panic-button'); }
      });
    }
    if ( this.us.currentUser.permissions.permissions.regions.view ) {
      this.settings.push({
        title: this.lang.get('settings.labels.regions'),
        icon: 'assets/images/company/settings/marker.svg',
        callback: () => { this.openSettingSection('regions'); }
      });
    }
    if ( this.us.currentUser.permissions.permissions.global_settings.view ) {
      this.settings.push({
        title: this.lang.get('settings.global.title'),
        icon: 'assets/images/company/settings/gears.svg',
        callback: () => { this.openSettingSection('global'); }
      });
    }
    if ( this.us.currentUser.permissions.permissions.ipcom_settings.view ) {
      this.settings.push({
        title: this.lang.get('systems.menu.ipcom'),
        icon: 'assets/images/company/settings/ipcom-settings.svg',
        callback: () => { this.openSettingSection('ipcoms'); }
      });
    }
    if ( this.us.currentUser.permissions.permissions.transfer_device.execute ) {
      this.settings.push({
        title: this.lang.get('systems.titles.transferDevice'),
        icon: 'assets/images/company/settings/transfer.svg',
        callback: () => { this.openSettingSection('transfer-device'); }
      });
    }
    if ( this.us.currentUser.permissions.permissions.dev_setup_templates.view ) {
      this.settings.push({
        title: this.lang.get('setup.device.setupTemplates'),
        icon: 'assets/images/company/settings/templates.svg',
        callback: () => { this.openSettingSection('device-setup-templates'); }
      });
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.checkComponentPosition();
  }

  public openSettingSection(settingName: string): void {
    this.router.navigate([companyRootRoute, 'settings', settingName]);
  }

  checkComponentPosition() {
    const componentElement = this.elementRef.nativeElement;
    const componentRect = componentElement.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const availableWidth = windowWidth - componentRect.x;
    const settingsContainer = componentElement.querySelector('.settings-container');
    const requiredWidthToOpenComponent = 640;

    if (availableWidth <= requiredWidthToOpenComponent) {
      this.renderer.setStyle(settingsContainer, 'right', '0');
    }
  }

  public getIconUrl(url): string {
    return `url('${url}')`;
  }

}
