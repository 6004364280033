import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IPComOutput, IPComOutputMode, IPComReceiver, IPComSettings } from 'src/api/v3/settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { IPCom } from 'src/app/models/ipcom';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { ValidatorBuilder, ValidatorInstance } from 'src/app/ui/validator';
type ValidationType = Pick<IPComReceiver, 'encryption_password' | 'receiver_name'> & Pick<IPComOutput, 'ip' | 'tcp_port' | 'sia_key'>;

@Component({
  selector: 'app-edit-ipcom-input-output',
  templateUrl: './edit-ipcom-input-output.component.html',
  styleUrls: ['./edit-ipcom-input-output.component.scss']
})
export class EditIpcomInputOutputComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly canSeeTags = this.us.currentUser.permissions.permissions.tags.view;
  public readonly canEditTags = this.us.currentUser.permissions.permissions.tags.edit;
  public readonly canEditCompany = this.us.currentUser.permissions.permissions.company_settings.edit && this.us.currentUser.company_id === 0;
  public readonly canViewCompany = this.us.currentUser.permissions.permissions.company_settings.view && this.us.currentUser.company_id === 0;
  public readonly protocols: DropdownItem<number>[] = [0,1,2,3,4,5,6,7,8].map(i => ({ label: this.trans('settings.ipcom.outputProtocols.' + i), value: i }));

  private ipcomSettings: IPComSettings;

  public isNew = false;
  public ipcomItem: IPCom;
  public companyText = '';
  public dataChanged = false;
  public outputItem: IPComOutput;
  public receiverItem: IPComReceiver;
  public companyList: DropdownItem<number>[] = [];
  public validator: ValidatorInstance<ValidationType>;
  public canEdit = this.us.currentUser.permissions.permissions.ipcom_settings.edit;
  public footerButtons = [{ label: this.trans('general.back'), callback: () => this.endEditing() }];
  public footerButtonsNew = [{ label: this.trans('general.add'), callback: () => this.confirmAddition() }, { label: this.trans('general.cancel'), callback: () => history.back() }];

  constructor(
    cdRef: ChangeDetectorRef,
    private r: ActivatedRoute,
    private router: Router,
    private pp: PopupService,
  ) {
    super(cdRef, true);

    this.validator = new ValidatorBuilder<ValidationType>()
      .required('receiver_name', this.trans('general.required'))
      .required('encryption_password', this.trans('general.required'))
      .regex('encryption_password', /^\+?.{6}(?:.{10})?$/,
        this.trans('validation.exactAttributeLength').replace(':attribute', this.trans('settings.labels.encryptionForDevices')).replace(':length', '6 :or 16').replace(':or', this.trans('general.or'))
      )
      .required('ip', this.trans('general.required'))
      .required('tcp_port', this.trans('general.required'))
      .regex('tcp_port', /^\d{2,5}$/, this.trans('validation.between.numeric')
        .replace(':attribute', this.trans('settings.ipcom.outputPort')).replace(':min', '10').replace(':max', '65535')
      )
      .required('sia_key', this.trans('general.required'))
      .useOnlyWhen(() => this.outputItem.out_mode === 7)
      .custom('sia_key', async (v) => [v.match(/[,|;:=-]+/) === null, this.trans('settings.errors.illegalEncryptionChars')])
      .minLength('sia_key', 16, this.trans('validation.exactAttributeLength').replace(':attribute', this.trans('settings.ipcom.outputSiaKey')).replace(':length', '16'))
      .useOnlyWhen(() => this.outputItem.out_mode === 7)
      .maxLength('sia_key', 16, this.trans('validation.exactAttributeLength').replace(':attribute', this.trans('settings.ipcom.outputSiaKey')).replace(':length', '16'))
      .useOnlyWhen(() => this.outputItem.out_mode === 7)
      .build().bindContext({...this.receiverItem,...this.outputItem});

    const receiverIdParam = this.r.snapshot.paramMap.get('receiverId');
    this.isNew = !receiverIdParam;
    this.canEdit = (!this.isNew && this.canEdit) || (this.isNew && this.us.currentUser.permissions.permissions.ipcom_settings.create);
    this.ipcomSettings = this.editSystemService.getEditableComponent(TEditableComponent.IPComSettings);
    this.ipcomItem = this.editSystemService.getEditableComponent(TEditableComponent.IPcom);
    if ( this.isNew ) {
      const foundDeleted = this.ipcomSettings.receivers.find(rd => rd.deleted);
      const receiverId = this.getNextAvailableNumber(false, 'receiver_id', 1);
      this.receiverItem = foundDeleted ?? {
        com: [],
        company_id: this.us.currentUser.company_id,
        deviation_negative: 0,
        deviation_positive: 0,
        encryption_password: '123456',
        ipcom_id: this.ipcomItem.id,
        line_number: '1',
        network_mode: 'TCP',
        outputs: [],
        parent_id: receiverId,
        port: this.getNextAvailableNumber(false, 'port', 55001),
        receiver_id: receiverId,
        receiver_name: '',
        receiver_number: this.getNextReceiverNumber(),
        smpp: [],
        tags: [],
      };
    } else {
      this.receiverItem = this.ipcomSettings.receivers.find(rc => rc.receiver_id === parseInt(receiverIdParam, 10));
    }

    if ( !this.receiverItem ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'settings'], { replaceUrl: true});
      return;
    }

    if ( this.canEditCompany || this.canViewCompany ) {
      this.loadCompanyList();
    }
    if ( this.receiverItem.outputs.length === 0 ) {
      this.outputItem = {
        active: 'True',
        buffer_size: 1024,
        com_id: 0,
        hb_enable: 'True',
        hb_interval: 30,
        identificator: this.getNextAvailableNumber(true, 'identificator', 1),
        ip: '',
        ipcom_id: this.ipcomItem.id,
        name: '',
        out_mode: IPComOutputMode.Surgard,
        output_id: this.getNextAvailableNumber(true, 'output_id', 1),
        tcp_port: 0,
        type: 0,
        encryption_enabled: 'False',
        sia_key: '',
      };
      this.receiverItem.outputs.push(this.outputItem.output_id);
      this.ipcomSettings.outputs.push(this.outputItem);
    } else {
      this.outputItem = this.ipcomSettings.outputs.find(out => out.output_id === this.receiverItem.outputs[0]);
      this.protocols.forEach(p => p.default = p.value === this.outputItem.out_mode);
    }
    if ( !this.platform.isDesktopView() ) {
      this.headerBar.showHeader({
        headerText: this.isNew ? this.trans('settings.ipcom.addSecurityCenter') : this.receiverItem.receiver_name,
        backUrl: '*',
        actionButtonText: this.isNew || !this.us.currentUser.permissions.permissions.ipcom_settings.delete ? '' : this.trans('general.delete'),
      });
      this.background.setGray();
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    if ( this.outputItem.name === '' ) {
      this.outputItem.name = this.receiverItem.receiver_name + '_out';
    }
  }

  public changeProtocol() {
    this.pp.showSlideout<DropdownItem<number>>({
      headerText: this.trans('settings.ipcom.outputProtocol'),
      items: this.protocols,
      onSubmit: (res) => {
        this.outputItem.out_mode = res.value;
        this.onInputChanged();
      }
    }, PopupType.SlideoutWithValue);
  }

  private loadCompanyList() {
    this.companyService.loadDropDownList().then(() => {
      this.companyService.listStorage.forEach(i => i.default = i.value === this.receiverItem.company_id);
      this.companyList = this.companyService.listStorage;
      this.companyText = this.companyList.find(c => c.value === this.receiverItem.company_id).label as string;
    });
  }

  public changeCompany() {
    this.pp.showSlideout<DropdownItem<number>>({
      headerText: this.trans('settings.ipcom.companySelect'),
      items: this.companyList,
      onSubmit: (res) => {
        this.receiverItem.company_id = res.value;
        this.companyText = res.label as string;
        this.onInputChanged();
      }
    }, PopupType.SlideoutWithValue);
  }

  public onInputChanged() {
    const changed = this.editSystemService.hasChanged(TEditableComponent.IPcom) || this.editSystemService.hasChanged(TEditableComponent.IPComSettings);
    this.dataChanged = changed;
    if ( this.desktopView ) { return; }
    if ( changed && this.footerBar.isShowing() ) { return; }
    if ( !changed ) {
      this.footerBar.hideFooter();
      return;
    }

    if ( this.isNew ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.add'), true, false);
      this.footerBar.onButton2Click = () => this.confirmAddition();
    } else {
      this.footerBar.showFooter(this.trans('general.back'), '', true, false);
    }
    this.footerBar.onButton1Click = () => {
      history.back();
    };
  }

  private async confirmAddition() {
    if ( ! await this.validator.validate({...this.receiverItem, ...this.outputItem}) ) {
      return;
    }
    if ( this.receiverItem.deleted ) {
      this.receiverItem.deleted = undefined;
    } else {
      this.ipcomSettings.receivers.push(this.receiverItem);
    }
    history.back();
  }

  private getNextAvailableNumber(isOutput: boolean, field: string, minValue: number): number {
    let nextToUse = minValue;
    const list: any = isOutput ? this.ipcomSettings.outputs : this.ipcomSettings.receivers;
    while (list.find(check => check[field] === nextToUse)) {
      nextToUse++;
    }
    return nextToUse;
  }

  private getNextReceiverNumber(): string {
    let nextToUse = 1;
    while (this.ipcomSettings.receivers.find(r => r.receiver_number === nextToUse.toString(16))) {
      nextToUse++;
    }

    return nextToUse.toString(16);
  }

  private async endEditing() {
    if ( ! await this.validator.validate({...this.receiverItem, ...this.outputItem}) ) {
      return;
    }

    history.back();
  }
}
