import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupComponent } from 'src/app/ui/popup/popup.component';

export interface ItemConfiguration<TValue> {
  name: string;
  value: TValue;
}

const getDistance = (rect1: DOMRect, rect2: DOMRect) => {
  const x1 = rect1.x + rect1.width / 2;
  const y1 = rect1.y + rect1.height / 2;
  const x2 = rect2.x + rect2.width / 2;
  const y2 = rect2.y + rect2.height / 2;
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
};

@Component({
  selector: 'app-company-columns',
  templateUrl: './company-columns.component.html',
  styleUrls: ['./company-columns.component.scss'],
})
export class CompanyColumnsComponent<TValue = any> extends LanguageAware implements OnInit, OnDestroy {
  private _items: ItemConfiguration<TValue>[] = [];
  public get items(): ItemConfiguration<TValue>[] {
    return this.order.map((key) => this._items[key]);
  }
  @Input()
  public set items(value: ItemConfiguration<TValue>[]) {
    this._items = value.filter(i => i !== null);
    this.order = [...this._items.keys()];
  }

  @Input() value: TValue[] = [];
  @Output() valueChange = new EventEmitter<TValue[]>();
  @ViewChild(PopupComponent) popup?: PopupComponent;
  @ViewChild('pop') popRef: ElementRef<HTMLElement>;
  private ghost?: HTMLElement;

  public order = [...this._items.keys()];
  public movingItem?: number;
  public stopClick = false;
  public offsetX: number;
  public offsetY: number;
  private highlithethedItem?: number;
  private intersectionObserver = new IntersectionObserver((entries) => {});
  constructor(cdRef: ChangeDetectorRef, private zone: NgZone) {
    super(cdRef);
  }

  public show = () => this.popup?.show();
  public getValue(item: TValue): boolean {
    return this.value.includes(item);
  }

  public setValue(item: TValue, value: boolean): void {
    const newValue = this.order
      .map((key) => this._items[key])
      .map((c) => (c.value === item ? (value ? c.value : undefined) : this.value.includes(c.value) ? c.value : undefined))
      .filter((c) => c !== undefined);
    this.valueChange.emit(newValue);
  }

  public publishNewOrder(): void {
    const newValue = this.order
      .map((key) => this._items[key])
      .map((c) => (this.value.includes(c.value) ? c.value : undefined))
      .filter((c) => c !== undefined);
    this.valueChange.emit(newValue);
  }

  public beginGrab(item: ItemConfiguration<TValue>, event: MouseEvent | TouchEvent): void {
    this.movingItem = this.items.indexOf(item);
    const ref = document.querySelectorAll('app-portal .popup .item')[this.movingItem] as HTMLElement;
    ref.style.background = 'red';
    const rect = ref.getBoundingClientRect();
    if (event instanceof MouseEvent) {
      this.offsetX = event.clientX - rect.left;
      this.offsetY = event.clientY - rect.top;
      this.updateGhostCords(event.clientX, event.clientY);
    } else {
      this.offsetX = event.touches[0].clientX - rect.left;
      this.offsetY = event.touches[0].clientY - rect.top;
      this.updateGhostCords(event.touches[0].clientX, event.touches[0].clientY);
    }
    event.preventDefault();
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  public endGrab($event: MouseEvent | TouchEvent): void {
    if (this.movingItem === undefined) return;
    if (this.highlithethedItem !== this.movingItem && this.highlithethedItem !== this.movingItem + 1) {
      if (this.highlithethedItem > this.movingItem) {
        this.highlithethedItem -= 1;
      }
      this.order.splice(this.highlithethedItem, 0, this.order.splice(this.movingItem, 1)[0]);
      this.publishNewOrder();
    }
    this.movingItem = undefined;
    Array.from(document.querySelectorAll('app-portal .popup .itemSeperator')).forEach((c) => c.classList.remove('highlighted'));
    $event.preventDefault();
    $event.stopPropagation();
    this.stopClick = true;
    setTimeout(() => (this.stopClick = false), 10);
  }

  public moveGrab($event: MouseEvent | TouchEvent): void {
    this.zone.runOutsideAngular(() => {
      if (this.movingItem === undefined) return;
      if ($event instanceof MouseEvent) {
        this.updateGhostCords($event.clientX, $event.clientY);
      } else {
        this.updateGhostCords($event.touches[0].clientX, $event.touches[0].clientY);
      }
      const items = Array.from(document.querySelectorAll('app-portal .popup .itemSeperator'));
      const ghostRect = this.ghost.getBoundingClientRect();
      const distances = items.map((c) => getDistance(c.getBoundingClientRect(), ghostRect));
      const twoSmallest = [...distances].sort((a, b) => a - b).slice(0, 2);
      const smallestIndex = distances.indexOf(twoSmallest[0]);
      const index = Math.abs(twoSmallest[0] - twoSmallest[1]) < 3 ? this.highlithethedItem : smallestIndex;
      if (index === this.highlithethedItem) return;
      this.highlithethedItem = index;
      items.forEach((c, i) => {
        if (i === this.highlithethedItem && this.movingItem !== i && this.movingItem + 1 !== i) {
          c.classList.add('highlighted');
        } else {
          c.classList.remove('highlighted');
        }
      });
    });
    if (this.movingItem) $event.preventDefault();
  }
  private boundMoveGrab = this.moveGrab.bind(this);

  private updateGhostCords(mouseX: number, mouseY: number): void {
    if (this.movingItem === undefined) return;
    if (!this.ghost) {
      this.ghost = document.querySelector('app-portal .item.ghostItem') as HTMLElement;
    }
    this.ghost.style.top = mouseY - this.offsetY + 'px';
    this.ghost.style.left = mouseX - this.offsetX + 'px';
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      document.addEventListener('mousemove', this.boundMoveGrab);
      document.addEventListener('touchmove', this.boundMoveGrab, { passive: false });
    });
  }

  ngOnDestroy(): void {
    this.zone.runOutsideAngular(() => {
      document.removeEventListener('mousemove', this.boundMoveGrab);
      document.removeEventListener('touchmove', this.boundMoveGrab);
      this.intersectionObserver?.disconnect();
    });
  }
}
