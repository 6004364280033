import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BypassZonesComponent } from '../system/bypass-zones/bypass-zones.component';
import { AreaKeypadComponent } from '../system/area-keypad/area-keypad.component';
import { CheckboxComponent } from '../general/checkbox/checkbox.component';
import { PasswordEditComponent } from '../components/password-edit/password-edit.component';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { FormsModule } from '@angular/forms';
import { ButtonSingleLineArrowComponent } from '../components/button-single-line-arrow/button-single-line-arrow.component';
import { DotComponent } from '../general/dot/dot.component';
import { CompanyMobileHeaderComponent } from '../company/components/company-mobile-header/company-mobile-header.component';
import { UiModule } from '../ui/ui.module';
import { TmapperComponent } from './tmapper/tmapper.component';
@NgModule({
  declarations: [
    BypassZonesComponent,
    AreaKeypadComponent,
    CheckboxComponent,
    PasswordEditComponent,
    ButtonSingleLineArrowComponent,
    DotComponent,
    CompanyMobileHeaderComponent,
    TmapperComponent,
  ],
  imports: [CommonModule, InlineSVGModule, FormsModule],
  exports: [
    BypassZonesComponent,
    AreaKeypadComponent,
    CheckboxComponent,
    PasswordEditComponent,
    ButtonSingleLineArrowComponent,
    DotComponent,
    CompanyMobileHeaderComponent,
    TmapperComponent,
  ],
})
export class AppCommonModule {}
