<div class="filterSectionWrap">
  <div class="flex-1">
    <ng-container *ngFor="let item of activeFilterCriteria">
      <div id="eventsFilterSection" class="addFilter">
        <div class="activeFilterCriteria" (click)="onActiveFilterClick(item)">
          {{ item.searchTypeLabel + ': ' + item.searchPhrase }}
          <div (click)="onActiveFilterRemoveClick(item)" class="icon" inlineSVG="assets/images/delete.svg"></div>
        </div>
        <div *ngIf="activeChangeSelector === item.searchTypeName" class="filterSettings">
          <div class="keywordInput">
            <app-string-input label="Keyword" [(value)]="addFilterCriteria.searchPhrase" (valueChange)="onTypingSearchField()"></app-string-input>
          </div>
          <ng-container *ngIf="addFilterCriteria.searchPhrase.length >= 3">
            <div class="itemsFromAddFilterCriteria">
              <ng-container *ngIf="filterSearchQueryLoading; else queryResults">
                <div class="loadingIcon"></div>
              </ng-container>
              <ng-template #queryResults>
                <ng-container *ngIf="itemsFromAddFilterCriteria.size === 0">
                  <p class="noResults">{{ trans('general.noResults') }}</p>
                </ng-container>
                <ng-container *ngFor="let item of itemsFromAddFilterCriteria; let last = last">
                  <h4 [ngClass]="{ 'item': true, 'item-last': last }" (click)="onSubmitFilterCriteriaClick(item)">{{ item.name }}</h4>
                </ng-container>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div id="eventsFilterSection" class="addFilter">
      <div *ngIf="showAddFilter"  class="addFilterText" (click)="onAddFilterClick()">
        <div inlineSVG="assets/images/filter.svg"></div>
        <p>{{ trans('events.buttons.addFilter') }}</p>
      </div>
      <div *ngIf="showFilterSection" class="filterSettings">
        <ng-container *ngIf="!showFilterKeywordSection; else searchEvents">
          <ng-container *ngFor="let searchType of searchTypes; let i = index">
            <div id="searchType" *ngIf="searchType.visible" [ngClass]="{'filterCriteriaHeader' : true, 'last': i + 1 === searchTypes.length }" (click)="selectFilterCriteria(searchType)">
              <h3>{{searchType.label}}</h3>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #searchEvents>
          <div class="keywordInput">
            <app-string-input label="Keyword" [(value)]="addFilterCriteria.searchPhrase" (valueChange)="onTypingSearchField()"></app-string-input>
          </div>
          <ng-container *ngIf="addFilterCriteria.searchPhrase.length >= 3">
            <div class="itemsFromAddFilterCriteria">
              <ng-container *ngIf="filterSearchQueryLoading; else queryResults">
                <div class="loadingIcon"></div>
              </ng-container>
              <ng-template #queryResults>
                <ng-container *ngIf="itemsFromAddFilterCriteria.size === 0">
                  <p class="noResults">{{ trans('general.noResults') }}</p>
                </ng-container>
                <ng-container *ngFor="let item of itemsFromAddFilterCriteria; let last = last">
                  <h4 [ngClass]="{ 'item': true, 'item-last': last }" (click)="onSubmitFilterCriteriaClick(item)">{{ item.name }}</h4>
                </ng-container>
              </ng-template>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
  <app-events-datetime-picker (datesChanged)="datesChanged($event)" [startDate]="startDate" [endDate]="endDate"></app-events-datetime-picker>
</div>
