<ng-template #headerName let-class="className"><div [ngClass]="class">{{trans('permissions.labels.name')}}</div></ng-template>
<ng-template #headerRole let-class="className"><div [ngClass]="class">{{trans('permissions.labels.role')}}</div></ng-template>
<ng-template #headerDescription let-class="className"><div [ngClass]="class">{{trans('permissions.labels.description')}}</div></ng-template>
<ng-template #headerCompany let-class="className"><div [ngClass]="class">{{trans('permissions.labels.company')}}</div></ng-template>
<ng-template #headerTags let-class="className"><div [ngClass]="class">{{trans('permissions.labels.tags')}}</div></ng-template>
<ng-template #headerDefault let-class="className"><div [ngClass]="class">{{trans('permissions.labels.default')}}</div></ng-template>
<ng-template #contentIcon let-value="item.role">
  <div *ngIf="value === PermissionRole.SuperAdmin" class="roleIcon superAdmin" inlineSVG="assets/images/super_admin_role.svg"></div>
  <div *ngIf="value === PermissionRole.Company" class="roleIcon company" inlineSVG="assets/images/company_role.svg"></div>
  <div *ngIf="value === PermissionRole.Installer" class="roleIcon" inlineSVG="assets/images/installer_role.svg"></div>
  <div *ngIf="value === PermissionRole.GeneralUser" class="roleIcon" inlineSVG="assets/images/general_role.svg"></div>
  <div *ngIf="value === PermissionRole.SystemMaster" class="roleIcon" inlineSVG="assets/images/owner_role.svg"></div>
  <div *ngIf="value === PermissionRole.SystemUser" class="roleIcon" inlineSVG="assets/images/general_role.svg"></div>
</ng-template>
<ng-template #roleContent let-value="value"><div>{{trans('permissions.roles.' + value)}}</div></ng-template>
<ng-template #deleteIcon let-value="item">
  <div *ngIf="value.id > 6" class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>
<ng-template #arrowIcon><div class="arrow">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.402 14.697">
    <path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/>
  </svg>
</div></ng-template>
<ng-template #companyContent let-value="item.company_id">
  {{value === 0 ? '' : companyService.getCompany(value)?.name}}
</ng-template>
<ng-template #tagsContent let-value="item.tags">
  <app-inline-tag-list [tags]="value"></app-inline-tag-list>
</ng-template>
<ng-template #defaultContent let-value="value">
  {{value ? trans('general.yes') : ''}}
</ng-template>
<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.roles.view"
  class="permissionsDatatable"
  [data]="loader"
  [slots]="{
    'fake:roleIcon': contentIcon,
    'header:name': headerName,
    'header:role': headerRole,
    'header:description': headerDescription,
    'header:company': headerCompany,
    'header:tags': headerTags,
    role: roleContent,
    'fake:delete': deleteIcon,
    'fake:arrow': arrowIcon,
    company: companyContent,
    tags: tagsContent,
    'default': defaultContent
  }"
  [columns]="columns"
  (modifyColumns)="modifyColumns.emit()"
  (rowClick)="onRowClick($event)"
  [dataSize]="this.permissionService.permissionsRules.size"
  [showHeaders]="showHeaders"
>
</app-data-table>
