import { Injectable, Injector, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { SystemData } from 'src/api/v3/common';
import { DbSystemData } from 'src/api/v3/system';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { FilterCriteria } from 'src/app/company/pages/company-users/company-users.component';
import { TAreaData } from 'src/app/models/area-data';
import { TCamera } from 'src/app/models/camera';
import { TDeviceUser } from 'src/app/models/device-user';
import { TEventData } from 'src/app/models/event-data';
import { TSystemData } from 'src/app/models/system-data';
import { TZoneData } from 'src/app/models/zone-data';
import { LanguageService } from 'src/app/services/language.service';
import { LocatorService } from 'src/app/services/locator.service';
import { autoinject } from 'src/shim';
import { AuthService } from '../auth.service';
import { LoggerService } from '../logger.service';
import { PermissionService } from '../permission.service';
import { RequestService } from '../request.service';
import { RtcService } from '../rtc/rtc.service';
import { TagService } from '../tag.service';
import { UserService } from '../user.service';
import { AreaService } from './area.service';
import { CameraService } from './camera.service';
import { EventService } from './event.service';
import { PgmService } from './pgm.service';
import { SensorService } from './sensor.service';
import { ThermostatService } from './thermostat.service';
import { ZoneService } from './zone.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService implements OnDestroy {
  private readonly tag = 'SystemService';

  public readonly systems = new Map<number, TSystemData>();
  public readonly filteredSystems = new Map<number, TSystemData>();
  public readonly partialSystems = new Map<number, Partial<DbSystemData>>();
  public readonly pendingSystemms = new Map<number, Promise<TSystemData>>();
  private previousFilterCriteria = null;

  private get eventService() { return autoinject(LocatorService.injector, EventService); }
  private get areaService() { return autoinject(LocatorService.injector, AreaService); }
  private get zoneService() { return autoinject(LocatorService.injector, ZoneService); }
  private get pgmService() { return autoinject(LocatorService.injector, PgmService); }
  private get sensorService() { return autoinject(LocatorService.injector, SensorService); }
  private get thermostatService() { return autoinject(LocatorService.injector, ThermostatService); }
  private get cameraService() { return autoinject(LocatorService.injector, CameraService); }
  private get userService() { return autoinject(LocatorService.injector, UserService); }
  private get lang() { return autoinject(LocatorService.injector, LanguageService); }
  private get permissionService() { return autoinject(LocatorService.injector, PermissionService); }
  private get loggerService() { return autoinject(LocatorService.injector, LoggerService); }

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.systems.clear();
    this.pendingSystemms.clear();
  });
  constructor(private auth: AuthService, private req: RequestService, private sanitzer: DomSanitizer, private injector: Injector, private rtc: RtcService) {}
  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  public ingestSystem(system?: SystemData | null, isFilteringSystems = false): TSystemData | undefined {
    if (!system) { return; }

    system.events.events.forEach((event) => this.eventService.ingestEvent(event, system.id));
    system.areas.forEach((area) => this.areaService.ingestArea(area));
    system.zones.forEach((zone) => this.zoneService.ingestZone(zone));
    system.pgms.forEach((pgm) => this.pgmService.ingestPgm(pgm, system.id));
    system.sensors.forEach((sensor) => this.sensorService.ingestSensor(sensor));
    system.thermostats.forEach((thermostat) => this.thermostatService.ingestThermostat(thermostat));
    system.cameras.forEach((camera) => this.cameraService.ingestCamera(camera));
    system.protegus_users.forEach((user) => this.userService.ingestProtegusUser(user, { id: (system as SystemData).id, name: (system as SystemData).name }));
    system.device_users.forEach((user) => this.userService.ingestDeviceUser(user));
    system.related_permissions.forEach((r) => this.permissionService.ingestIntoSessionStorage(r));

    const processedSystem: TSystemData = {
      id: system.id,
      areas: [],
      pgms: [],
      notifications: system.notifications?.notifications.map((n) => ({ id: n.id, name: n.name, enabled: n.on, useAlertSound: n.alert_sound_on })) ?? [],
      sensors: [],
      zones: [],
      events: [],
      protegus_users: [], // TODO: Ingest Protegus Users
      device_users: [], // TODO: Ingest Device Users
      cameras: [],
      thermostats: [],
      name: system.name,
      online: system.online,
      supported_commands: system.supported_commands,
      amIMaster: true, // TODO: Remove
      mpass: system.mpass,
      address: system.address,
      timeZone: system.timeZone,
      imei: system.imei,
      notificationSoundsEnabled: system.notifications?.sounds_on ?? false,
      notificationsEnabled: system.notifications?.global_value ?? false,
      signalLevel: system.signalLevel,
      hwType: system.hwType,
      canBypassZone: system.canBypassZone, // TODO: Maybe Remove?
      canUnbypassZone: system.canUnbypassZone, // TODO: Maybe Remove?
      directControl: !!system.direct,
      noSleepStay: system.noSleepStay,
      maxDeviceUsers: system.maxDeviceUsers,
      panel: system.centralPanel,
      coordinates: system.coordinates,
      eventConfiguration: JSON.parse(system.eventConfiguration),
      canEditUsers: system.canEditUsers, // TODO: Remove
      theme: {
        startColor: system.theme?.background_start,
        endColor: system.theme?.background_end,
        fullBackground: system.theme?.full_background,
      },
      installerId: system.installer_id,
      installerEmail: system.installerEmail,
      installerName: system.installerName,
      logo: system.logo ? this.sanitzer.bypassSecurityTrustUrl(system.logo) : '',
      company_id: system.company_id,
      assistedById: system.assistedById,
      assistedByEmail: system.assistedByEmail,
      supportsFireReset: system.supportsFireReset, // TODO: Remove
      amIWorking: system.amIWorking ?? false, // What's this used for?
      privacyOfOwners: system.privacyOfOwners ?? [],
      owners: system.owners ?? [],
      deviceId: system.device_id ?? 0,
      created_at: system.created_at,
      tags: system.tags.map((t) => ({ textColor: TagService.getTextColor(t.color), ...t })),
      companyName: system.companyName,
    };
    Object.defineProperties(processedSystem, {
      events: {
        get: () => [...(this.eventService.systemEvents.get(processedSystem.id)?.values() ?? [])].map((id) => this.eventService.events.get(id)),
        set: (events) => events.forEach((event: TEventData) => this.eventService.ingestEventData(event)),
      },
      areas: {
        get: () => [...(this.areaService.systemAreas.get(processedSystem.id)?.values() ?? [])].map((id) => this.areaService.areas.get(id)),
        set: (areas: TAreaData[]) => {
          this.areaService.systemAreas.get(processedSystem.id)?.clear();
          areas.forEach((area) => this.areaService.ingestAreaData(area, processedSystem.id));
        },
      },
      zones: {
        get: () => [...(this.zoneService.systemZones.get(processedSystem.id)?.values() ?? [])],
        set: (zones: TZoneData[]) => {
          this.zoneService.systemZones.get(processedSystem.id)?.clear();
          zones.forEach((zone) => this.zoneService.ingestZoneData(zone, processedSystem.id));
        },
      },
      pgms: {
        get: () => [...(this.pgmService.systemPgms.get(processedSystem.id)?.values() ?? [])].map((id) => this.pgmService.pgms.get(id)),
        set: (pgms) => pgms.forEach((pgm) => this.pgmService.ingestPgm(pgm, system.id)),
      },
      sensors: {
        get: () => [...(this.sensorService.systemSensors.get(processedSystem.id)?.values() ?? [])].map((id) => this.sensorService.sensors.get(id)),
        set: (sensors) => sensors.forEach((sensor) => this.sensorService.ingestSensor(sensor)),
      },
      thermostats: {
        get: () => [...(this.thermostatService.systemThermostats.get(processedSystem.id)?.values() ?? [])].map((id) => this.thermostatService.thermostats.get(id)),
        set: (thermostats) => thermostats.forEach((thermostat) => this.thermostatService.ingestThermostat(thermostat)),
      },
      cameras: {
        get: () => [...(this.cameraService.systemCameras.get(processedSystem.id)?.values() ?? [])].map((id) => this.cameraService.cameras.get(id)),
        set: (cameras: TCamera[]) => {
          this.cameraService.systemCameras.get(processedSystem.id)?.clear();
          cameras.forEach((camera) => this.cameraService.ingestCameraData(camera, processedSystem.id));
        },
      },
      protegus_users: {
        get: () => [...(this.userService.systemUsers.get(processedSystem.id)?.values() ?? [])].map((sud) => this.userService.users.get(sud.id)),
        set: (protegus_users) => protegus_users.forEach((user) => this.userService.ingestProtegusUser(user, {id: (processedSystem as TSystemData).id, name: (processedSystem as TSystemData).name })),
      },
      device_users: {
        get: () => [...(this.userService.systemDeviceUsers.get(processedSystem.id)?.values() ?? [])],
        set: (newUsers: TDeviceUser[]) => {
          this.userService.systemDeviceUsers.get(processedSystem.id)?.clear();
          newUsers.forEach((user) => this.userService.ingestDeviceUserData(user, processedSystem.id));
        },
      },
    });
    if(isFilteringSystems) {
      this.filteredSystems.set(processedSystem.id, processedSystem);
    } else {
      this.systems.set(processedSystem.id, processedSystem);
    }
    this.rtc.updateIntrests([...new Set([...this.systems.keys(), ...this.partialSystems.keys()])]);
    this.loggerService.log('Ingesting system ' + system.id + ' ' + system.name, this.tag);
    return processedSystem;
  }

  public async getSystem(id: number): Promise<TSystemData> {
    if (this.systems.has(id)) {
      return this.systems.get(id);
    }
    if (this.pendingSystemms.has(id)) {
      return await this.pendingSystemms.get(id);
    }
    const promise = new Promise<TSystemData>(async (resolve) => {
      const res = await this.req.system.getSystem({ system_id: id }).toPromise();
      if (res.success) {
        return resolve(this.ingestSystem(res.system));
      }
    });
    this.pendingSystemms.set(id, promise);
    const system = await promise;
    this.pendingSystemms.delete(id);
    return system;
  }

  public async loadPartialSystemData(): Promise<void> {
    const res = await this.req.system.getSystemsLite({ columns: ['id', 'imei', 'name'], limit: 100 }).toPromise();
    if (res.success) {
      const allSystems = res.totalSystems;

      res.systems.forEach((system) => this.partialSystems.set(system.id, system));
      while (this.partialSystems.size < allSystems) {
        const resl = await this.req.system.getSystemsLite({ offset: this.partialSystems.size, columns: ['id', 'imei', 'name'], limit: 100 }).toPromise();
        if (resl.success) {
          resl.systems.forEach((system) => this.partialSystems.set(system.id, system));
        }
      }
      this.rtc.updateIntrests([...new Set([...this.systems.keys(), ...this.partialSystems.keys()])]);
    }
  }

  public getSystemName(id: number): string {
    if (this.partialSystems.has(id)) {
      return this.partialSystems.get(id).name;
    }
    if (this.systems.has(id)) {
      return this.systems.get(id).name;
    }
    return this.lang.get('systems.titles.unknown');
  }

  public getSystemsGetter(resultsFiltered: boolean, systemsFilterCriteria?: FilterCriteria) {
    if (systemsFilterCriteria) {
      return this.getFilteredSystemsGetter(systemsFilterCriteria);
    } else {
      this.previousFilterCriteria = null;
      return this.getAllSystemsGetter(resultsFiltered);
    }
  }

  private getAllSystemsGetter(resultsFiltered: boolean): DataTableGetter<TSystemData> {
    return async (current, columns, more) => {
      if (!more && current <= this.systems.size && !resultsFiltered) {
        return [...this.systems.values()].sort((a, b) => a.name.localeCompare(b.name));
      }
      await this.req.system
        .getSystems({
          offsetCount: current,
        })
        .pipe(
          map((result) => {
            if (result.success) {
              const systems = result.systems.filter((s) => s.hwType !== '').map((s) => this.ingestSystem(s));
              return systems;
            }
            return [];
          })
        )
        .toPromise();
      return [...this.systems.values()].sort((a, b) => a.name.localeCompare(b.name));
    };
  }

  private getFilteredSystemsGetter(systemsFilterCriteria: FilterCriteria): DataTableGetter<TSystemData> {
    const filterCriteriaString = JSON.stringify(systemsFilterCriteria);
    if(systemsFilterCriteria.lastPage === 0 && this.previousFilterCriteria !== filterCriteriaString) {
      this.filteredSystems.clear();
    }
    return async () => {
      if(this.previousFilterCriteria !== filterCriteriaString) {
        this.previousFilterCriteria = filterCriteriaString;
        await this.loadFilteredSystems(systemsFilterCriteria).then(result => {
          systemsFilterCriteria.currentPage = result.current_page;
          systemsFilterCriteria.lastPage = result.last_page;
        });
      }
      return [...this.filteredSystems.values()].sort((a, b) => a.name.localeCompare(b.name));
    };
  }

  private async loadFilteredSystems(systemsFilterCriteria: FilterCriteria): Promise<any> {
    const result = await this.req.system
    .filterSystems({
      searchPhrase: systemsFilterCriteria.searchPhrase,
      searchFields: systemsFilterCriteria.searchFields,
      paginationPage: systemsFilterCriteria.currentPage === systemsFilterCriteria.lastPage ? systemsFilterCriteria.lastPage : systemsFilterCriteria.currentPage + 1
    }).toPromise();
    if (result.success) {
      result.list.data.forEach((system) => this.ingestSystem(system, true));
      return result.list;
    }
  }
}
