import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TZoneData } from 'src/app/models/zone-data';
import { ZoneService } from 'src/app/services/zone.service';

@Component({
  selector: 'app-zone-status-list',
  templateUrl: './zone-status-list.component.html',
  styleUrls: ['./zone-status-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZoneStatusListComponent extends LanguageAware implements OnInit {
  private zoneToBypass: TZoneData = null;
  public showKeypad = false;
  public showWarning = false;
  private readonly panicButtonsAreaStatus = 18;

  constructor(
    cdRef: ChangeDetectorRef,
    public zs: ZoneService,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'AreaZones';
    this.headerBar.showHeader({
      headerText: this.systems.activeArea.status !== this.panicButtonsAreaStatus ? this.trans('systems.titles.zones') : this.trans('systems.titles.panicButtonsList'),
      backUrl: '*',
      showDotMenu: true,
    });
    localStorage.setItem('ref', 'zone-status-list');
    this.background.setGray();
    const that = this;
    this.headerBar.setDotMenuItems([
      {
        name: this.trans('systems.menu.settings'),
        action: () => { this.router.navigate(['../system-zone-list/status'], { relativeTo: this.r }); },
      },
    ]);
    const systemId = this.systems.activeSystem.id;
    if (this.systems.activeSystem.hwType !== 'G16T' && this.systems.activeSystem.hwType !== 'E16T') {
      this.zs.getZoneStatuses(this.systems.activeArea.queue_no, this.systems.activeSystem.id, (z) => {
        that.gotZoneStatuses(z, systemId);
      });
    }

    this.showWarning = this.systems.activeSystem.panel >= 0x10 && this.systems.activeSystem.panel < 0x20;
  }

  ngOnInit(): void {}

  public doBypass(zone: TZoneData, event) {
    if (!this.systems.activeSystemHelper.can.bypassZone()) {
      return;
    }
    this.log('Darom bypass', zone.queue_no);
    if (event !== undefined) {
      event.preventDefault();
    }
    this.zoneToBypass = zone;
    if (this.systems.activeArea.showKeypad) {
      this.showKeypad = true;
    } else {
      this.zs.performBypass(zone);
    }
  }

  public zoneIsPanicButton(): boolean {
   return this.systems.activeArea.status === this.panicButtonsAreaStatus ? true : false;
  }

  public getZones(): TZoneData[] {
    return this.systems.activeSystem.zones.filter((z) => z.areas.find((a) => a === this.systems.activeArea.queue_no) !== undefined && z.visible);
  }

  private gotZoneStatuses(zones: any, systemId: number) {
    this.log('Grizo zonu buseson: ', zones);
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }

    for (const iZone of Object.keys(zones)) {
      const zone = system.zones.find((z) => z.queue_no === parseInt(iZone, 10));
      if (zone === undefined) {
        continue;
      }
      zone.bypassed = zones[iZone].bypass;
      zone.alarmed = zones[iZone].alarm;
      zone.failed = zones[iZone].failure;
    }
  }

  public keypadCancelled() {
    this.showKeypad = false;
  }

  public pinConfirmed(pin?: string, remember?: boolean) {
    this.showKeypad = false;
    this.zs.performBypass(this.zoneToBypass, pin, remember);
  }
}
