import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfiguratorService as SP3Config } from 'src/app/configurators/sp3/src/app/services/configurator.service';
import { ConfiguratorService as GV17Config } from 'src/app/configurators/gv17/src/app/services/configurator.service';
import { ConfiguratorService as G16Config } from 'src/app/configurators/g16/src/app/services/configurator.service';
import { PopupType } from 'src/app/models/popup-type';
import { NewSystemService } from 'src/app/services/new-system.service';
import { PopupService } from 'src/app/services/popup.service';
import { LanguageAware } from '../language-aware';
import { gFamily, gvFamily, indirectOnlyDevices, spFamily } from 'src/app/services/global.service';
import { LocatorService } from 'src/app/services/locator.service';
import { G16 } from 'src/app/configurators/g16/src/app/models/g16-devices';
import requests from 'src/api/v3/requests';

@Component({
  selector: 'app-device-quick-settings-write',
  templateUrl: './device-quick-settings-write.component.html',
  styleUrls: ['./device-quick-settings-write.component.scss']
})
export class DeviceQuickSettingsWriteComponent extends LanguageAware implements OnInit {
  private readonly RETRY_COUNT = 10;
  public configurationStep: 'connect' | 'read' | 'write' | 'find' | 'done' | 'error' = 'connect';
  public shouldAbort = false;
  private adminCode = '123456';
  private conf: SP3Config | GV17Config | G16Config = null;
  private firstTime = true;
  private retries = 0;
  public readonly trk8Device = this.ns.deviceSettings.hwId === G16.G16T_47 || this.ns.deviceSettings.hwId === G16.GET || this.ns.deviceSettings.hwId === G16.G16T_50;
  public shouldShowAdvancedSettings = true;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private r: ActivatedRoute,
    private ns: NewSystemService,
    private pp: PopupService,
  ) {
    super(cdRef);
    if ( !this.ns.isSystemBeingCreated() ) {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.statuses.finalizing'),
    });
    this.footerWithCancel();
    this.ns.deviceSettings.objectId = this.ns.deviceSettings.objectId.padStart(this.ns.deviceSettings.hwId === 0x4e ? 6 : 4, '0').toLocaleUpperCase();
    this.ns.deviceSettings.pcCode = this.ns.deviceSettings.pcCode.padStart(4, '0');
    this.findDevice();
  }

  ngOnInit(): void {
  }

  private footerWithCancel() {
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.shouldAbort = true;
      this.router.navigate(this.g.getHomeUrl());
    };
  }

  private footerWithRetry() {
    this.configurationStep = 'error';
    this.footerBar.showFooter(this.trans('general.retry'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.configurationStep = 'connect';
      this.footerWithCancel();
      this.findDevice();
    };
  }

  private footerWithRetryAndCancel() {
    const retryFinalStep = this.configurationStep === 'find';
    this.configurationStep = 'error';
    this.adminCode = '';
    this.footerBar.showFooter(this.trans('general.retry'), this.trans('general.cancel'), true, false);
    this.footerBar.onButton1Click = () => {
      this.configurationStep = retryFinalStep ? 'find' : 'connect';
      if ( retryFinalStep ) { this.retries = 0; }
      this.footerWithCancel();
      this.findDevice();
    };
    this.footerBar.onButton2Click = () => {
      this.shouldAbort = true;
      this.router.navigate(this.g.getHomeUrl());
    };
  }

  private footerWithDone() {
    if ( this.us.currentUser.permissions.permissions.sys_advanced_settings.view && this.shouldShowAdvancedSettings ) {
      this.openAdvancedConfig();
      return;
    }
    if ( this.us.currentUser.permissions.permissions.system.create || this.us.currentUser.permissions.permissions.systems.create ) {
      this.addAsSystem();
      return;
    }
    this.configurationStep = 'done';
    this.footerBar.showFooter(this.trans('general.done'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.router.navigate(this.g.getHomeUrl());
    };
  }

  private findDevice() {
    if ( this.configurationStep !== 'find' ) {
      this.retries = 0;
    } else {
      this.retries++;
    }
    requests.config.info({
      systemUid: this.ns.getUID(),
      mpass: this.ns.getMpass(),
    }, this.configurationStep === 'find' && this.retries < 3).subscribe(
      (result) => {
        if ( this.shouldAbort ) { return; }
        if (result.success) {
          // !!! pridedant naujus modulius, juos reikia prideti ir i systems.service prie supports.advancedSettings
          if ( this.configurationStep === 'find' ) {
            localStorage.setItem('ref', 'dev_setup');
            this.footerWithDone();
          } else if ([/* SP3 */ '3B', /* GV17 */ '24', '37', /* G16 */ '3A', '3C', '42', '43', '44', '45', '46', '47', '48', '50', '51'].includes(result.data.hwId)) {
            // G16
            this.g.configurableDevice = result.data;
            this.g.configurableDevice.mpass = this.ns.getMpass();
            this.readConfig();
          } else {
            this.toaster.postError(this.trans('systems.titles.notSupported'));
            this.footerWithRetry();
          }
        } else if ( this.configurationStep === 'find' && this.retries < this.RETRY_COUNT ) {
          setTimeout(() => {
            this.findDevice();
          }, 10000);
        } else if ( this.configurationStep === 'find' ) {
          this.toaster.postError(result.error);
          this.footerWithRetryAndCancel();
        } else {
          this.toaster.postError(result.error);
          this.footerWithRetry();
        }
      },
      (error) => {
        this.footerWithRetry();
      }
    );
  }

  private askForAdminPass(action?: () => void) {
    this.pp.showPopup(
      {
        headerText: this.trans('configurators.labels.adminCode'),
        field1: {
          maxLen: 6,
        },
        onSubmitString: (pass) => {
          this.adminCode = pass ?? '';
          if ( action ) { action(); }
        },
        onCancel: () => {
          this.footerWithRetryAndCancel();
        }
      },
      PopupType.Password
    );
  }

  public readConfig() {
    this.configurationStep = 'read';
    if (this.adminCode === '') {
      this.askForAdminPass(() => this.readConfig() );
      return;
    }
    let modificationFunction: () => void = null;
    if ( gFamily.includes(this.ns.deviceSettings.hwId) ) {
      this.conf = LocatorService.injector.get(G16Config);
      modificationFunction = () => this.performGChanges();
    } else if ( gvFamily.includes(this.ns.deviceSettings.hwId) ) {
      this.conf = LocatorService.injector.get(GV17Config);
      modificationFunction = () => this.performGvChanges();
    } else if ( spFamily.includes(this.ns.deviceSettings.hwId) ) {
      this.conf = LocatorService.injector.get(SP3Config);
      modificationFunction = () => this.performSpChanges();
    }
    this.api.post('/config/read', {
      systemUid: this.g.configurableDevice.uid,
      mpass: this.g.configurableDevice.mpass,
      adminCode: this.adminCode,
    }, true).subscribe(
      (result) => {
        if ( this.shouldAbort ) { return; }
        if (result.success) {
          this.conf.setCurrentConfiguration(result.data);
          this.conf.clearChanges();
          this.log('Config', this.conf.currentConfiguration);
          this.g.configurableDevice.adminCode = this.adminCode;
          modificationFunction();
        } else {
          if (result.error_code !== undefined && result.error_code === '04') {
            if (this.firstTime) {
              this.firstTime = false;
            } else {
              this.toaster.postError(result.error);
            }
            this.askForAdminPass(() => this.readConfig() );
          } else {
            this.toaster.postError(result.error);
            this.footerWithRetry();
          }
        }
      },
      (error) => {
        this.footerWithRetry();
      }
    );
  }

  private performSpChanges() {
    this.configurationStep = 'write';
    const spConfig: SP3Config = (this.conf as SP3Config);
    spConfig.currentConfiguration.systemOptions.systemGeneral.objectNumber = parseInt(this.ns.deviceSettings.objectId, 16);
    spConfig.currentConfiguration.reportingCms.reporting.primaryChannel.commType = 1; // IP
    spConfig.currentConfiguration.reportingCms.reporting.primaryChannel.domain = this.ns.selectedSetupTemplate.host;
    spConfig.currentConfiguration.reportingCms.reporting.primaryChannel.port = parseInt(this.ns.selectedSetupTemplate.port, 10);
    spConfig.currentConfiguration.reportingCms.reporting.primaryChannel.key = this.ns.selectedSetupTemplate.encryption;
    spConfig.currentConfiguration.reportingCms.reporting.primaryChannel.protocol = 0; // force TRK
    if ( this.ns.selectedSetupTemplate.backup_enabled ) {
      spConfig.currentConfiguration.reportingCms.reporting.backupChannel.commType = 1; // IP
      spConfig.currentConfiguration.reportingCms.reporting.backupChannel.domain = this.ns.selectedSetupTemplate.backup_host;
      spConfig.currentConfiguration.reportingCms.reporting.backupChannel.port = parseInt(this.ns.selectedSetupTemplate.backup_port, 10);
      spConfig.currentConfiguration.reportingCms.reporting.backupChannel.key = this.ns.selectedSetupTemplate.backup_encryption;
      spConfig.currentConfiguration.reportingCms.reporting.backupChannel.protocol = 0; // force TRK
    } else {
      spConfig.currentConfiguration.reportingCms.reporting.backupChannel.commType = 0;
    }
    spConfig.currentConfiguration.reportingCms.settings.settings.siaReceiverNr = this.ns.selectedSetupTemplate.receiver_nr.toString(16);
    spConfig.currentConfiguration.reportingCms.settings.settings.siaLineNr = this.ns.selectedSetupTemplate.line_nr.toString(16);
    spConfig.currentConfiguration.reportingCms.settings.settings.gprsPingEnabled = this.ns.selectedSetupTemplate.ping_enabled;
    spConfig.currentConfiguration.reportingCms.settings.settings.gprsPing = this.ns.selectedSetupTemplate.ping_period;
    spConfig.currentConfiguration.events[1].enabled = this.ns.selectedSetupTemplate.test_enabled;
    spConfig.currentConfiguration.systemOptions.systemGeneral.testPeriod = Math.floor(this.ns.selectedSetupTemplate.test_period / 60); // min -> h
    if ( spConfig.currentConfiguration.systemOptions.systemGeneral.testPeriod === 0 ) {
      spConfig.currentConfiguration.systemOptions.systemGeneral.testPeriod = 1;
    }
    spConfig.currentConfiguration.reportingCms.settings.settings.backupAfterTries = this.ns.selectedSetupTemplate.backup_after;
    spConfig.currentConfiguration.reportingCms.settings.settings.primaryAfter = Math.floor(this.ns.selectedSetupTemplate.primary_after / 60);
    if ( spConfig.currentConfiguration.reportingCms.settings.settings.primaryAfter === 0 ) {
      spConfig.currentConfiguration.reportingCms.settings.settings.primaryAfter = 1;
    }
    this.writeChanges();
  }

  private performGvChanges() {
    this.configurationStep = 'write';
    const gvConfig: GV17Config = (this.conf as GV17Config);
    gvConfig.currentConfiguration.systemOptions.systemGeneral.objectNumber = parseInt(this.ns.deviceSettings.objectId, 16);
    gvConfig.currentConfiguration.reporting.primary.type = this.ns.selectedSetupTemplate.communication_type;
    gvConfig.currentConfiguration.reporting.primary.host = this.ns.selectedSetupTemplate.host;
    gvConfig.currentConfiguration.reporting.primary.port = parseInt(this.ns.selectedSetupTemplate.port, 10);
    gvConfig.currentConfiguration.reporting.primary.key = this.ns.selectedSetupTemplate.encryption;
    if ( this.ns.selectedSetupTemplate.backup_enabled ) {
      gvConfig.currentConfiguration.reporting.backup.type = this.ns.selectedSetupTemplate.backup_communication_type;
      gvConfig.currentConfiguration.reporting.backup.host = this.ns.selectedSetupTemplate.backup_host;
      gvConfig.currentConfiguration.reporting.backup.port = parseInt(this.ns.selectedSetupTemplate.backup_port, 10);
      gvConfig.currentConfiguration.reporting.backup.key = this.ns.selectedSetupTemplate.backup_encryption;
    } else {
      gvConfig.currentConfiguration.reporting.backup.type = 0;
    }
    gvConfig.currentConfiguration.reporting.general.primaryAfter = Math.floor(this.ns.selectedSetupTemplate.primary_after / 60);
    if ( gvConfig.currentConfiguration.reporting.general.primaryAfter === 0 ) {
      gvConfig.currentConfiguration.reporting.general.primaryAfter = 1;
    }
    gvConfig.currentConfiguration.reporting.general.backupAfter = this.ns.selectedSetupTemplate.backup_after;
    gvConfig.currentConfiguration.reporting.general.ipPingEnabled = this.ns.selectedSetupTemplate.ping_enabled;
    gvConfig.currentConfiguration.reporting.general.ipPing = this.ns.selectedSetupTemplate.ping_period;
    gvConfig.currentConfiguration.systemOptions.periodicTest.enableTest = this.ns.selectedSetupTemplate.test_enabled;
    gvConfig.currentConfiguration.systemOptions.periodicTest.hours = Math.floor(this.ns.selectedSetupTemplate.test_period / 60); // min -> h
    if ( gvConfig.currentConfiguration.systemOptions.periodicTest.hours === 0 ) {
      gvConfig.currentConfiguration.systemOptions.periodicTest.hours = 1;
    }
    this.writeChanges();
  }

  private performGChanges() {
    this.configurationStep = 'write';
    const gconfig: G16Config = (this.conf as G16Config);
    gconfig.currentConfiguration.systemOptions.systemGeneral.objectNumber = this.ns.deviceSettings.objectId;
    const intHwId = parseInt(this.ns.getDeviceInfo().hwId, 16);
    if ( !indirectOnlyDevices.includes(intHwId) ) {
      gconfig.currentConfiguration.systemOptions.systemGeneral.directControl = this.ns.deviceSettings.remoteControl && this.ns.selectedPanel.canBeControlledRemotely;
      if ( this.ns.selectedPanel.pcCodeNeeded ) {
        gconfig.currentConfiguration.systemOptions.systemGeneral.panelPassword = this.ns.deviceSettings.pcCode;
      }
      gconfig.currentConfiguration.systemOptions.systemGeneral.panelKey = this.ns.selectedPanel.id.toString(16);
    }
    gconfig.currentConfiguration.reporting.cmsSettings.primary.communicationType = 1; // IP
    gconfig.currentConfiguration.reporting.cmsSettings.primary.communicationMode = this.ns.selectedSetupTemplate.communication_type; // TCP/UDP
    gconfig.currentConfiguration.reporting.cmsSettings.primary.domain = this.ns.selectedSetupTemplate.host;
    gconfig.currentConfiguration.reporting.cmsSettings.primary.port = this.ns.selectedSetupTemplate.port;
    gconfig.currentConfiguration.reporting.cmsSettings.primary.encryptionKey = this.ns.selectedSetupTemplate.encryption;
    gconfig.currentConfiguration.reporting.cmsSettings.primary.protocol = this.trk8Device ? 4 : 0; // force TRK8 arba TRK3
    if ( this.ns.selectedSetupTemplate.backup_enabled ) {
      gconfig.currentConfiguration.reporting.cmsSettings.backup.communicationType = 1; // IP
      gconfig.currentConfiguration.reporting.cmsSettings.backup.communicationMode = this.ns.selectedSetupTemplate.backup_communication_type; // TCP/UDP
      gconfig.currentConfiguration.reporting.cmsSettings.backup.domain = this.ns.selectedSetupTemplate.backup_host;
      gconfig.currentConfiguration.reporting.cmsSettings.backup.port = this.ns.selectedSetupTemplate.backup_port;
      gconfig.currentConfiguration.reporting.cmsSettings.backup.encryptionKey = this.ns.selectedSetupTemplate.backup_encryption;
      gconfig.currentConfiguration.reporting.cmsSettings.backup.protocol = this.trk8Device ? 4 : 0; // force TRK8 arba TRK3
    } else {
      gconfig.currentConfiguration.reporting.cmsSettings.backup.communicationType = 0;
    }
    gconfig.currentConfiguration.reporting.settings.pingEnabled = this.ns.selectedSetupTemplate.ping_enabled;
    gconfig.currentConfiguration.reporting.settings.pingMinutes = Math.floor(this.ns.selectedSetupTemplate.ping_period / 60);
    gconfig.currentConfiguration.reporting.settings.pingSeconds = Math.floor(this.ns.selectedSetupTemplate.ping_period % 60);
    gconfig.currentConfiguration.reporting.settings.testEnabled = this.ns.selectedSetupTemplate.test_enabled;
    gconfig.currentConfiguration.reporting.settings.testHours = Math.floor(this.ns.selectedSetupTemplate.test_period / 60);
    gconfig.currentConfiguration.reporting.settings.testMinutes = Math.floor(this.ns.selectedSetupTemplate.test_period % 60);
    gconfig.currentConfiguration.reporting.settings.backupAfter = this.ns.selectedSetupTemplate.backup_after;
    gconfig.currentConfiguration.reporting.settings.primaryAfterMinutes = Math.floor(this.ns.selectedSetupTemplate.primary_after / 60);
    gconfig.currentConfiguration.reporting.settings.primaryAfterSeconds = Math.floor(this.ns.selectedSetupTemplate.primary_after % 60);
    if ( this.trk8Device ) {
      gconfig.currentConfiguration.shared.extendedAccountNumber = this.ns.deviceSettings.moduleId;
      gconfig.currentConfiguration.reporting.settings.receiverNumberSia = this.ns.selectedSetupTemplate.receiver_nr.toString(16);
      gconfig.currentConfiguration.reporting.settings.lineNumberSia = this.ns.selectedSetupTemplate.line_nr.toString(16);
    } else {
      gconfig.currentConfiguration.reporting.settings.receiverNumberTrk = this.ns.selectedSetupTemplate.receiver_nr.toString(16).substring(0, 2);
      gconfig.currentConfiguration.reporting.settings.lineNumberTrk = this.ns.selectedSetupTemplate.line_nr.toString(16).substring(0,1);
    }
    this.shouldShowAdvancedSettings = gconfig.fwIsGood();
    this.writeChanges();
  }

  private writeChanges() {
    if (this.adminCode === '') {
      this.askForAdminPass(() => this.writeChanges());
      return;
    }
    this.api.post('/config/write', {
      systemUid: this.g.configurableDevice.uid,
      mpass: this.g.configurableDevice.mpass,
      adminCode: this.adminCode,
      config: this.conf.currentConfiguration,
    }, true).subscribe(
      (result) => {
        if ( this.shouldAbort ) { return; }
        if (!result.success) {
          if (result.error_code !== undefined && result.error_code === '04') {
            if (this.firstTime) {
              this.firstTime = false;
            } else {
              this.toaster.postError(result.error);
            }
            this.askForAdminPass(() => this.writeChanges());
          } else {
            this.toaster.postError(result.error);
            this.footerWithRetry();
          }
        } else {
          this.conf.clearChanges();
          this.configurationStep = 'find';
          setTimeout(() => {
            this.findDevice();
          }, 10000);
        }
      },
      (error) => {
        this.footerWithRetry();
      }
    );
  }

  public openAdvancedConfig() {
    if ( gFamily.includes(this.ns.deviceSettings.hwId) ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'configure', 'g16']);
    } else if ( gvFamily.includes(this.ns.deviceSettings.hwId) ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'configure', 'gv17']);
    } else if ( spFamily.includes(this.ns.deviceSettings.hwId) ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'configure', 'sp3']);
    } else if ( spFamily.includes(this.ns.deviceSettings.hwId) ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'configure', 'fc']);
    }
  }

  public addAsSystem() {
    this.g.configurableDevice = null;
    this.router.navigate(['../../add-system-details'], { relativeTo: this.r, replaceUrl: true });
  }
}
