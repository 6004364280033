import permission from './permission';
import company from './company';
import user from './user';
import tag from './tag';
import system from './system';
import deviceSetupTemplates from './device-setup-templates';
import settings from './settings';
import config from './../requests/config';

export default {
    permission,
    company,
    user,
    tag,
    system,
    deviceSetupTemplates,
    settings,
    config,
};
