import { httpGet, httpPost } from '../http';
import { objectToParams, Region } from '../v3/common';
import { CommonRequest, CommonResponse } from './common';
import { GV17Device } from 'src/app/configurators/gv17/src/app/models/types';
import { TDeviceInfo } from 'src/app/models/device-info';

const info = (params: InfoRequest, silent: boolean) => httpGet<InforRequestResponse, URLSearchParams>('/v3/api/config/info', objectToParams(params), { silentOnError: silent });
const write = (params: ConfigWriteRequestParams) => httpPost<CommonResponse, ConfigWriteRequestParams>('/v3/api/config/write', params);
const read = (params: CommonConfigRequest) => httpGet<ConfigReadRequestResponse, URLSearchParams>('/v3/api/config/read', objectToParams(params));

type InfoRequest = Omit<CommonRequest, 'adminCode'>;

type CommonConfigRequest = CommonRequest & {
  fwVersion: string;
};

type ConfigReadRequestResponse = CommonResponse & {
  data?: GV17Device;
};

type ConfigWriteRequestParams = CommonConfigRequest & {
  config: GV17Device;
};

type InforRequestResponse = CommonResponse & {
  data: TDeviceInfo & {
    isInForeignRegion: boolean;
  };
  foreignSystem: any[] | null; // kazkada reiketu nurodyti pilna struktura.
  ownRegion: Region | null;
  srv: number;
};

export default {
  info,
  read,
  write,
};
