import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from 'src/api/v3/company';
import { CompanyService } from 'src/app/api/company.service';
import { PersistenceService } from 'src/app/api/persistence.service';
import { PlatformService } from 'src/app/api/platform.service';
import { UserService } from 'src/app/api/user.service';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { TEditableComponent } from 'src/app/models/editable-component';
import { BackgroundService } from 'src/app/services/background.service';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { FooterService } from 'src/app/services/footer.service';
import { GlobalService } from 'src/app/services/global.service';
import { HeaderService } from 'src/app/services/header.service';
import { LanguageService } from 'src/app/services/language.service';
import { LocatorService } from 'src/app/services/locator.service';
import { AppSettings } from 'src/environments/environment';
import { autoinject } from 'src/shim';

type FilteredResultsPageData = {currentPage: number; lastPage: number};
export interface FilterCriteria extends FilteredResultsPageData {
  searchPhrase: string;
  searchFields: string[];
}
@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss']
})
export class CompanyListComponent implements OnInit {
  public canAdd = this.us.currentUser.permissions?.permissions.company_settings.create && this.us.currentUser.company_id === 0;
  public canDelete = this.us.currentUser.permissions?.permissions.company_settings.delete;
  public desktopView = this.platform.isDesktop() && AppSettings.companyDesktopEnabled;
  public columns = this.persistence.bind('company_list_columns', ['logo', 'name', 'fake:arrow']);
  private get backgroundService() { return autoinject(LocatorService.injector, BackgroundService); }
  private get headerService() { return autoinject(LocatorService.injector, HeaderService); }
  private get globalService() { return autoinject(LocatorService.injector, GlobalService); }
  private get footerService() { return autoinject(LocatorService.injector, FooterService); }
  public trans = (k: string): string => this.lang.get(k);
  @Input() public filterCriteria: FilterCriteria = null;
  @Input() loader: DataTableGetter<Company>;
  @Output() public modifyColumns = new EventEmitter<void>();

  constructor(
    private router: Router,
    private persistence: PersistenceService,
    private companyService: CompanyService,
    private us: UserService,
    private platform: PlatformService,
    private injector: Injector,
    private lang: LanguageService,
    private es: EditSystemService,
  ) {
    if ( !this.desktopView ) {
      this.columns.value = ['logo', 'name', 'fake:arrow'];
      this.headerService.showHeader({
        backUrl: this.globalService.getHomeUrl().join('/'),
        headerText: this.trans('companies.titles.list'),
        showFind: false,
      });
      this.footerService.hideFooter();
      this.backgroundService.setGray();
    }
  }

  ngOnInit(): void {
    if ( !this.loader ) {
      this.loader = this.companyService.getCompaniesGetter(this.filterCriteria);
    }
  }

  public addCompany() {
    this.es.beginComponentEdit(TEditableComponent.Company, 0, null);
    this.router.navigate([...this.globalService.getHomeUrl(), 'companies', 'new']);
  }
}
