import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/api/logger.service';
import { FooterService } from 'src/app/services/footer.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { SystemsService } from 'src/app/services/systems.service';

@Injectable({
  providedIn: 'root',
})
export class MobileToCompanyGuard implements CanDeactivate<unknown> {
  private readonly routeMapping = new Map<string, (systemId?: string, subPath?: string[]) => UrlTree>([
    ['/', () => this.router.createUrlTree([companyRootRoute])],
    ['/home', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId])],
    ['/events', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'events'])],
    ['/event-control', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'event-control'])],
    ['/system-thermostat', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'system-thermostat'])],
    ['/area-view', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'area'])],
    ['/zone-status-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'area', 'zones'])],
    ['/users', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'users'])],
    ['/user', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'user'])],
    ['/system-sensor-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'sensors'])],
    ['/system-sensor', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'system-sensor'])],
    ['/system-configuration', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings'])],
    ['/system-information', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'info'])],
    ['/reread', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'reread'])],
    ['/system-notifications', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'system-notifications'])],
    ['/quick-view-outputs', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'outputs'])],
    ['/quick-view-cameras', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'quick-view-cameras'])],
    ['/system-pgm-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'outputs'])],
    ['/system-pgm', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'system-pgm'])],
    ['/pgm-icon-selection', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'pgm-icon-selection'])],
    ['/system-transfer', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'system-transfer'])],
    ['/transfer-from-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'transfer', 'list'])],
    ['/advanced-settings', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'advanced'])],
    ['/system-delete', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'delete'])],
    ['/system-area-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'areas'])],
    ['/system-area', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'areas', 'edit'])],
    ['/system-zone-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'zones'])],
    ['/system-zone', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'system-zone'])],
    ['/reset-fire-sensors', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'fire-reset'])],
    ['/home-background-selection', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'info', 'background'])],
    ['/camera-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'camera-list'])],
    ['/camera', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'camera'])],
    ['/add-system', () => this.router.createUrlTree([companyRootRoute, 'systems', 'add-system'])],
    ['/add-system-details', () => this.router.createUrlTree([companyRootRoute, 'systems', 'add-system-details'])],
    ['/new-system-offline', () => this.router.createUrlTree([companyRootRoute, 'systems', 'new-system-offline'])],
    ['/confirm-device-transfer', () => this.router.createUrlTree([companyRootRoute, 'systems', 'confirm-device-transfer'])],
    ['/add-system-indirect', () => this.router.createUrlTree([companyRootRoute, 'systems', 'add-system-indirect'])],
    ['/add-system-area-pgms', () => this.router.createUrlTree([companyRootRoute, 'systems', 'add-system-area-pgms'])],
    ['/add-system-area', () => this.router.createUrlTree([companyRootRoute, 'systems', 'add-system-area'])],
    ['/add-system-area-list', () => this.router.createUrlTree([companyRootRoute, 'systems', 'add-system-area-list'])],
    ['/customize-pgms', () => this.router.createUrlTree([companyRootRoute, 'systems', 'customize-pgms'])],
    ['/add-system-zone-list', () => this.router.createUrlTree([companyRootRoute, 'systems', 'add-system-zone-list'])],
    ['/add-system-complete', () => this.router.createUrlTree([companyRootRoute, 'systems', 'add-system-complete'])],
    ['/customize-areas', () => this.router.createUrlTree([companyRootRoute, 'systems', 'customize-areas'])],
    ['/customize-zones', () => this.router.createUrlTree([companyRootRoute, 'systems', 'customize-zones'])],
    ['/company', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId])],
    ['/transfer-type', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'transfer-type'])],
    ['/transfer-device', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'transfer-device'])],
    ['/request-assistance', (systemId, sub) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'request-assistance'].concat(sub))],
    ['/change-installer', (systemId, sub) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'change-installer'].concat(sub))],
    ['/configure', (systemId, sub) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'configure'].concat(sub))],
    ['/owner-privacy', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'owner-privacy'])],
  ]);
  constructor(private router: Router, private l: LoggerService, private footerService: FooterService, private ss: SystemsService) {}

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (nextState) {
      let url = nextState.url;
      let subPath = '';
      if (url.startsWith(companyRootRoute)) {
        return true;
      }
      this.l.log('Bandoma palikti company', 'MobileToCompanyGuard', { url });
      let systemId = currentRoute.paramMap.get('systemId');
      if ( !systemId ) {
        systemId = this.ss.activeSystem?.id.toString();
      }
      // const configurator = this.handleConfigurators(url, systemId);
      // if (configurator) {
      //   this.l.log('Nukreipiama į configurator', 'MobileToCompanyGuard', { url, newUrl: configurator.toString() });
      //   return configurator;
      // }
      if ( url.includes('/', 1) ) {
        subPath = url.substring(url.indexOf('/', 1) + 1);
        url = url.substring(0, url.indexOf('/', 1));
      }
      if (this.routeMapping.has(url)) {
        const newUrl = this.routeMapping.get(url)(systemId, subPath.split('/'));
        this.l.log('Nukreipiama atgal į company', 'MobileToCompanyGuard', { newUrl: newUrl.toString() });
        this.footerService.hideFooter(); // This is a hack.
        return newUrl;
      }
    }
    return true;
  }

  // private handleConfigurators(url: string, systemId: string): UrlTree | undefined {
  //   if (url.startsWith('/configure')) {
  //     const i = url.indexOf('/', 9);
  //     const remainder = url.substring(i + 1).split('/');
  //     return this.router.createUrlTree([companyRootRoute, 'systems', systemId, 'settings', 'advanced', ...remainder]);
  //   }
  // }
}
