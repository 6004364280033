import { SP3InputType } from './sp3input-type';

export class SP3Input {
  name: string;
  canBeOutput: boolean;
  canBeInput: boolean;
  hwType: SP3InputType;
  queueNo: number;
  public constructor(init?: Partial<SP3Input>) {
    Object.assign(this, init);
  }
}
