import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  @Input() value: boolean;
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean;
  @Input() name: string;
  @Input() label: string;
  @Output() valueChange = new EventEmitter<boolean>();

  constructor() {
    this.value = false;
    this.isLoading = false;
    this.isDisabled = false;
    this.label = '';
  }

  onSelect(): void {
    this.value = !this.value;
    this.valueChange.emit(this.value as boolean);
  }

  ngOnInit(): void {
  }
}
