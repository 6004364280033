import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-company-page-layout',
  templateUrl: './company-page-layout.component.html',
  styleUrls: ['./company-page-layout.component.scss'],
})
export class CompanyPageLayoutComponent implements OnInit {
  @Input() gridTemplateColumns?: string;
  public hasSide = false;

  constructor() {}

  ngOnInit(): void {}
}
