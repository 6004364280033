<ng-template #headerLogo let-class="className"><div [ngClass]="class">{{trans('companies.labels.companyLogoHeader')}}</div></ng-template>
<ng-template #headerName let-class="className"><div [ngClass]="class">{{trans('companies.labels.companyName')}}</div></ng-template>
<ng-template #headerEmail let-class="className"><div [ngClass]="class">{{trans('companies.labels.companyEmail')}}</div></ng-template>
<ng-template #headerPhone let-class="className"><div [ngClass]="class">{{trans('companies.labels.companyPhone')}}</div></ng-template>
<ng-template #headerAddress let-class="className"><div [ngClass]="class">{{trans('companies.labels.companyAddress')}}</div></ng-template>
<ng-template #headerActive let-class="className"><div [ngClass]="class">{{trans('companies.labels.activeCompany')}}</div></ng-template>
<ng-template #contentIcon let-value="value"><div class="companyIcon" [style.--icon]="'url(' + value + ')'"></div></ng-template>
<ng-template #deleteIcon>
  <div class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>
<ng-template #arrowIcon><div class="arrow">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.402 14.697">
    <path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/>
  </svg>
</div></ng-template>
<ng-template #activeContent let-value="value">
  {{ value ? trans('companies.labels.activeCompany') : trans('companies.labels.inactiveCompany') }}
</ng-template>
<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.company_settings.view"
  class="companiesDatatable"
  [data]="loader"
  [slots]="{
    'header:logo': headerLogo,
    'header:name': headerName,
    'header:email': headerEmail,
    'header:phone': headerPhone,
    'header:address': headerAddress,
    'header:active' : headerActive,
    logo: contentIcon,
    'active' : activeContent,
    'fake:delete': deleteIcon,
    'fake:arrow': arrowIcon
  }"
  [columns]="columns"
  (modifyColumns)="modifyColumns.emit()"
  (rowClick)="onRowClick($event)"
  [dataSize]="this.companyService.companies.size"
  [showHeaders]="showHeaders"
  [activeItemId]="companyService.activeCompanyId"
>
</app-data-table>
