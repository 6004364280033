<div id="cpae_header">{{headerText}}</div>
<div id='cpae_container'>
    <div class="cpae_day-item" *ngFor='let iDay of eventsToShow'>
        <div class="cpae_day-header grid"><div>{{iDay.dayTitle}}</div></div>
        <div class="cpae_events-container">
            <div class="cpae_event-item grid" *ngFor='let iEvent of iDay.dayEvents'>
                <div class="cpae_new" *ngIf='newEventFromId > -1 && iEvent.id > newEventFromId'></div>
                <div class="cpae_event-icon" [inlineSVG]="reactions.getReactionIcon(iEvent.reaction)"></div>
                <div [ngClass]="{ 'cpae_event-name': true, 'cpae_event-name-no-area': iEvent.areaId === 0 }">{{iEvent.title}}</div>
                <div class="cpae_event-area" *ngIf='iEvent.areaId !== 0'>{{iEvent.areaText}}</div>
                <div class="cpae_event-system">{{iEvent.systemName}}</div>
                <div class="cpae_event-time">{{dt.formatTimeFromNumber(iEvent.time)}}</div>
            </div>
        </div>
    </div>
    <div class="cpae_day-item"><div class="cpae_event-item"></div></div>
</div>
