import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UserService } from 'src/app/api/user.service';
import { UResolvable } from 'src/app/guards/UResolvable';
import { LocatorService } from 'src/app/services/locator.service';
import { autoinject } from 'src/shim';

@Component({
  templateUrl: './company-layout.component.html',
  styleUrls: ['./company-layout.component.scss'],
})
export class CompanyLayoutComponent implements OnInit, UResolvable<typeof CompanyLayoutComponent> {
  constructor(public us: UserService) {}

  ngOnInit(): void {}

  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot) {
    const us = autoinject(LocatorService.injector, UserService);
    if ( !us.currentUser || us.currentUser.id === 0 ) {
      us.loadUserDataFromStorage();
    }
  }
}
