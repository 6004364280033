import { Injectable, Injector, OnDestroy } from '@angular/core';
import { SystemZoneData } from 'src/api/v3/common';
import { TZoneData } from 'src/app/models/zone-data';
import { LocatorService } from 'src/app/services/locator.service';
import { autoinject } from 'src/shim';
import { AuthService } from '../auth.service';
import { AreaService } from './area.service';

@Injectable({
  providedIn: 'root',
})
export class ZoneService implements OnDestroy {
  // Pagal sistemos id -> pagal zonos queue_no, gaunam zonos objektą
  public readonly systemZones = new Map<number, Map<number, TZoneData>>();
  // Pagal sistemos id -> pagal zonos queue_no, gaunam area->queue_no sąrašą.
  public readonly systemZoneAreas = new Map<number, Map<number, Set<number>>>();

  private get areaService() { return autoinject(LocatorService.injector, AreaService); }

  private cleanUpSubscription = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.systemZones.clear();
    this.systemZoneAreas.clear();
  });
  constructor(private injector: Injector, private auth: AuthService) {}
  ngOnDestroy(): void {
    this.cleanUpSubscription.unsubscribe();
  }

  ingestZone(zone?: SystemZoneData): TZoneData | undefined {
    if (!zone) { return; }

    if (!this.areaService.areas.has(zone.area_id)) { return; }
    const { area_id, ...rest } = zone;

    if (!this.systemZones.get(zone.system_id)?.has(zone.queue_no)) {
      const processedZone: TZoneData = {
        areas: [],
        alarmed: false,
        bypassed: false,
        enabled: true,
        failed: false,
        ...rest,
      };
      Object.defineProperty(processedZone, 'areas', {
        get: () => [...this.systemZoneAreas.get(zone.system_id)?.get(zone.queue_no).values()],
        set: (areaQueueNumbers: number[]) => {
          this.systemZoneAreas.get(zone.system_id)?.get(zone.queue_no).clear();
          areaQueueNumbers.forEach((n) => this.systemZoneAreas.get(zone.system_id)?.get(zone.queue_no).add(n));
        },
      });
      if ( !this.systemZones.has(zone.system_id) ) {
        this.systemZones.set(zone.system_id, new Map<number, TZoneData>());
      }
      this.systemZones.get(zone.system_id).set(zone.queue_no, processedZone);
      if (!this.systemZoneAreas.has(zone.system_id)) {
        this.systemZoneAreas.set(zone.system_id, new Map<number, Set<number>>());
      }
      if ( !this.systemZoneAreas.get(zone.system_id).has(zone.queue_no) ) {
        this.systemZoneAreas.get(zone.system_id).set(zone.queue_no, new Set<number>());
      }
    }

    if (!this.areaService.areaZones.has(zone.area_id)) {
      this.areaService.areaZones.set(zone.area_id, new Set());
    }
    this.areaService.areaZones.get(area_id)?.add(zone.id);
    const area = this.areaService.areas.get(zone.area_id);
    const zones = this.systemZoneAreas.get(zone.system_id);
    zones.get(zone.queue_no).add(area.queue_no);

    return this.systemZones.get(zone.system_id).get(zone.queue_no);
  }

  /**
   * Naudojama pridedant naują zoną.
   *
   * @param zone Zonos duomenys
   * @param systemId Sistemos id
   */
  public ingestZoneData(zone: TZoneData, systemId: number) {
    if ( !this.systemZones.has(systemId) ) {
      this.systemZones.set(systemId, new Map<number, TZoneData>());
    }
    if (!this.systemZoneAreas.has(systemId)) {
      this.systemZoneAreas.set(systemId, new Map<number, Set<number>>());
    }
    if ( !this.systemZoneAreas.get(systemId).has(zone.queue_no) ) {
      this.systemZoneAreas.get(systemId).set(zone.queue_no, new Set<number>());
    }
    zone.areas.forEach((n) => {
      if ( !this.systemZoneAreas.get(systemId).get(zone.queue_no).has(n) ) {
        this.systemZoneAreas.get(systemId).get(zone.queue_no).add(n);
      }
    });
    Object.defineProperty(zone, 'areas', {
      get: () => [...this.systemZoneAreas.get(systemId)?.get(zone.queue_no).values()],
      set: (areaQueueNumbers: number[]) => {
        this.systemZoneAreas.get(systemId)?.get(zone.queue_no).clear();
        areaQueueNumbers.forEach((n) => this.systemZoneAreas.get(systemId)?.get(zone.queue_no).add(n));
      },
    });
    this.systemZones.get(systemId).set(zone.queue_no, zone);
  }
}
