import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { TAreaData } from 'src/app/models/area-data';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';

@Component({
  selector: 'app-add-system-area-list',
  templateUrl: './add-system-area-list.component.html',
  styleUrls: ['./add-system-area-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSystemAreaListComponent extends LanguageAware implements OnInit, AfterViewInit {
  public windowName = 'area-list';

  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService, private r: ActivatedRoute) {
    super(cdRef);
    this.progressBar.showProgress();
    this.headerBar.showHeader({
      headerText: this.systems.activeSystem.hwType === 'FC' ? this.trans('systems.titles.groups') : this.trans('systems.titles.partitions'),
    });
    this.footerBar.showFooter(this.trans('general.skip'), this.trans('auth.buttons.next'), true, false);
    this.footerBar.onButton1Click = () => {
      this.router.navigate(this.g.getHomeUrl());
    };
    this.footerBar.onButton2Click = () => {
      this.onNext();
    };
    this.background.setGray();
    this.helper.shouldShowAddButtonAtBottom(this.systems.activeSystem.areas.length, 'area-list');
    this.helper.setContentRowHeight(true, true, true, this.windowName);
  }

  ngOnInit(): void {
    this.progressBar.setProgressData(5, 7);
  }

  ngAfterViewInit() {
    const that = this;
    if (!this.helper.plusIsAtBottomNow(this.windowName)) {
      const plusButton = document.querySelector('#asal-add-system-button') as HTMLElement;
      const areasBackground = document.querySelector('#asal-background') as HTMLElement;
      this.helper.setCountForBottomButton(this.systems.activeSystem.areas.length, plusButton.offsetTop, areasBackground.offsetHeight, true, true, true, this.windowName);
    }
    const areaList = document.querySelector('#asal-area-list') as HTMLElement;
    this.helper.shouldShowFakeElement(areaList.offsetHeight, this.windowName);
  }

  private onNext() {
    if (this.systems.activeSystem.areas.length === 0) {
      this.toaster.postError(this.trans('systems.errors.oneAreaRequired'));
      return;
    }

    if (this.systems.activeSystem.zones.length > 0) {
      this.router.navigate(['../add-system-zone-list'], { relativeTo: this.r });
    } else {
      this.router.navigate(['../system-zone'], { relativeTo: this.r });
    }
  }

  public onAddArea() {
    this.es.beginComponentEdit(TEditableComponent.Area, 0, null);
    localStorage.setItem('ref', 'new');
    this.router.navigate(['../system-area'], { relativeTo: this.r });
  }

  public onAreaSelect(area: TAreaData) {
    this.es.beginComponentEdit(TEditableComponent.Area, area.id, area);
    localStorage.setItem('ref', 'new');
    this.router.navigate(['../system-area'], { relativeTo: this.r });
  }

  public getAreas(): TAreaData[] {
    return this.systems.activeSystem.areas;
  }

  public getAssignedPgmName(area: TAreaData): string {
    const pgm = this.systems.activeSystem.pgms.find((p) => p.control_area && p.area_id === area.id);
    if (pgm !== undefined) {
      return this.trans('systems.labels.controlledWith') + ': ' + pgm.name;
    }

    return '';
  }

  public onAreaDelete(event, area: TAreaData) {
    event.stopPropagation();
    this.deleteArea(area.id);
    const areaList = document.querySelector('#asal-area-list') as HTMLElement;
    this.helper.shouldShowFakeElement(areaList.offsetHeight, this.windowName);
  }

  private deleteArea(areaId: number) {
    const area = this.systems.activeSystem.areas.find((a) => a.id === areaId); // Pasilaikom tam, kad pridetume atgal, jeigu bus klaida.
    const activeSystemId = this.systems.activeSystem.id;
    let controllingPgm = 0;
    this.systems.activeSystem.areas = this.systems.activeSystem.areas.filter((a) => a.id !== areaId);

    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.area_no === area.queue_no) {
        iPgm.area_id = 0;
        iPgm.area_no = 0;
        iPgm.control_area = false;
        controllingPgm = iPgm.id;
      }
    }

    this.systems.setCurrentSystem(this.systems.activeSystem);

    const that = this;
    this.api.delete('/area', true, { area_id: areaId, system_id: this.systems.activeSystem.id }).subscribe(
      (result) => {
        if (!result.success) {
          that.revertDeletion(area, activeSystemId, controllingPgm);
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.revertDeletion(area, activeSystemId, controllingPgm);
        that.toaster.postError(this.trans('systems.errors.areaSaveFailed'));
      }
    );
  }

  private revertDeletion(area: TAreaData, systemId: number, pgmId: number) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }

    system.areas.push(area);
    if (pgmId === 0) {
      if (this.systems.activeSystem.id === system.id) {
        this.systems.setCurrentSystem(system);
      }
      return;
    }

    for (const iPgm of system.pgms) {
      if (iPgm.id === pgmId) {
        iPgm.area_id = area.id;
        iPgm.control_area = true;
      }
    }
    if (this.systems.activeSystem.id === system.id) {
      this.systems.setCurrentSystem(system);
    }
  }
}
