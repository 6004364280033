<div class="grid show-zone-grid">
    <div class="group-background" id='sz-back'></div>
    <div id='sz-container'>
        <div class='list-item-v2' *ngFor="let zone of hiddenZones" (click)='zoneClick(zone)'>
            <div>
                <label class="round-checkbox" (click)='zoneClick(zone)'>
                    <input type="checkbox" [checked]='zone.visible'>
                    <span></span>
                </label>
            </div>
            <div [inlineSVG]="'assets/images/zone-general-icon.svg'"></div>
            <div>{{zone.queue_no}}</div>
            <div>
                <div>{{zone.name}}</div>
                <div>{{getAssignedAreas(zone.queue_no)}}&nbsp;</div>
            </div>
        </div>
    </div>
</div>