import { Injectable, Injector } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { GlobalResolverService } from '../api/global-resolver.service';
import { LocatorService } from '../services/locator.service';
import { isUResolvable } from './UResolvable';

@Injectable({
  providedIn: 'root',
})
export class UResolver implements Resolve<void> {
  constructor(private injector: Injector) {}
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    const globalResolver = this.injector.get(GlobalResolverService);
    console.log(state.url);
    const globalResolvePromise = globalResolver.resolve();
    if (isUResolvable(route.component)) {
      let lastSegment;
      if ( state.url.lastIndexOf('/') > 8 ) {
        lastSegment = state.url.substring(state.url.lastIndexOf('/') + 1, state.url.length);
      }
      const localPromise = route.component.resolve(LocatorService.injector, route, lastSegment);
      await Promise.all([globalResolvePromise, 'toPromise' in localPromise ? localPromise.toPromise() : localPromise]);
    } else {
      console.warn(`Cannot resolve '${route.url.map((s) => s.toString()).join('/')}' maybe it's just to use global resolver?`, { component: route.component });
    }
  }
}
