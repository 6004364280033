import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { SystemsService } from 'src/app/services/systems.service';

@Component({
  selector: 'app-transfer-type',
  templateUrl: './transfer-type.component.html',
  styleUrls: ['./transfer-type.component.scss'],
})
export class TransferTypeComponent extends LanguageAware implements OnInit {
  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    public ss: SystemsService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.transferType'),
      backUrl: '../system-configuration',
      activatedRoute: this.r,
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  public goToSystemTransfer() {
    this.router.navigate(['../system-transfer'], { relativeTo: this.r });
  }

  public goToDeviceTransfer() {
    this.router.navigate(['../transfer-device'], { relativeTo: this.r });
  }

  public goToSystemCompanyTransfer() {
    this.router.navigate(['../transfer-to-company'], { relativeTo: this.r });
  }
}
