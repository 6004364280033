import { httpPost } from '../http';
import { CommonResponse } from './common';

const getTranslations = (params: LanguageRequestParams) => httpPost<LanguageRequestResponse, LanguageRequestParams>('/v3/api/translations', params);

export type LanguageRequestParams = {
  language: string;
  version: string;
  config: 'gv17';
};

export type LanguageRequestResponse = CommonResponse & {
  translations?: string;
  version?: string;
  code?: string;
};

export default {
  getTranslations,
};
