import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './general/login/login.component';
import { GuestGuardService } from './guards/guest-guard.service';
import { LoadingScreenComponent } from './general/loading-screen/loading-screen.component';
import { EmailComponent } from './general/email/email.component';
import { PasswordLoginComponent } from './general/password-login/password-login.component';
import { HomeComponent } from './general/home/home.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { AddSystemUidComponent } from './general/add-system-uid/add-system-uid.component';
import { NoPasswordComponent } from './general/no-password/no-password.component';
import { NameRegisterComponent } from './general/name-register/name-register.component';
import { AddSystemDetailsComponent } from './general/add-system-details/add-system-details.component';
import { HomeBackgroundSelectionComponent } from './general/home-background-selection/home-background-selection.component';
import { SystemLocationComponent } from './general/system-location/system-location.component';
import { WiringComponent } from './general/wiring/wiring.component';
import { AddSystemIndirectComponent } from './general/add-system-indirect/add-system-indirect.component';
import { AddSystemAreaComponent } from './general/add-system-area/add-system-area.component';
import { AddSystemAreaListComponent } from './general/add-system-area-list/add-system-area-list.component';
import { AddSystemZoneListComponent } from './general/add-system-zone-list/add-system-zone-list.component';
import { AddSystemCompleteComponent } from './general/add-system-complete/add-system-complete.component';
import { SystemConfigurationComponent } from './system/system-configuration/system-configuration.component';
import { SystemInformationComponent } from './system/system-information/system-information.component';
import { SystemNotificationsComponent } from './system/system-notifications/system-notifications.component';
import { AreaListComponent } from './system/area-list/area-list.component';
import { AreaComponent } from './system/area/area.component';
import { AreaPgmsComponent } from './general/area-pgms/area-pgms.component';
import { ZoneListComponent } from './system/zone-list/zone-list.component';
import { SystemZoneComponent } from './general/system-zone/system-zone.component';
import { SystemPgmListComponent } from './system/system-pgm-list/system-pgm-list.component';
import { SystemPgmComponent } from './system/system-pgm/system-pgm.component';
import { TransferComponent } from './system/transfer/transfer.component';
import { DeleteComponent } from './system/delete/delete.component';
import { AreaViewComponent } from './system/area-view/area-view.component';
import { EventsComponent } from './system/events/events.component';
import { ProfileComponent } from './general/profile/profile.component';
import { PasswordChangeComponent } from './general/password-change/password-change.component';
import { AccountDeleteComponent } from './general/account-delete/account-delete.component';
import { UserListComponent } from './system/user-list/user-list.component';
import { UserComponent } from './system/user/user.component';
import { CustomizeAreasComponent } from './general/customize-areas/customize-areas.component';
import { CustomizeZonesComponent } from './general/customize-zones/customize-zones.component';
import { ZoneStatusListComponent } from './system/zone-status-list/zone-status-list.component';
import { PgmIconSelectComponent } from './system/pgm-icon-select/pgm-icon-select.component';
import { HomeConfigurationComponent } from './general/home-configuration/home-configuration.component';
import { SensorsListComponent } from './system/sensors-list/sensors-list.component';
import { SystemSensorComponent } from './system/system-sensor/system-sensor.component';
import { EventControlComponent } from './system/event-control/event-control.component';
import { ShowZonesComponent } from './system/show-zones/show-zones.component';
import { TransferFromListComponent } from './system/transfer-from-list/transfer-from-list.component';
import { LanguageListComponent } from './general/language-list/language-list.component';
import { HelpComponent } from './general/help/help.component';
import { CameraListComponent } from './system/camera-list/camera-list.component';
import { CameraComponent } from './system/camera-list/camera/camera.component';
import { RereadComponent } from './system/reread/reread.component';
import { SystemThermostatComponent } from './system/system-thermostat/system-thermostat.component';
import { AdvancedSettingsComponent } from './general/advanced-settings/advanced-settings.component';
import { TicketComponent } from './general/help/ticket/ticket.component';
import { CpHomeComponent } from './mobile-company/cp-home/cp-home.component';
import { UserRoleGuardService } from './guards/user-role-guard.service';
import { CompanyRoleGuardService } from './guards/company-role-guard.service';
import { CpAllEventsComponent } from './mobile-company/cp-all-events/cp-all-events.component';
import { CpFilteredSystemsComponent } from './mobile-company/cp-filtered-systems/cp-filtered-systems.component';
import { CpEditEventsComponent } from './mobile-company/cp-edit-events/cp-edit-events.component';
import { CpEditEventComponent } from './mobile-company/cp-edit-event/cp-edit-event.component';
import { CpAllUsersComponent } from './mobile-company/cp-all-users/cp-all-users.component';
import { CpEditUserComponent } from './mobile-company/cp-edit-user/cp-edit-user.component';
import { InstallerRoleGuardService } from './guards/installer-role-guard.service';
import { CpEditReactionsComponent } from './mobile-company/cp-edit-reactions/cp-edit-reactions.component';
import { CpEditReactionComponent } from './mobile-company/cp-edit-reaction/cp-edit-reaction.component';
import { CpInstallerAcceptComponent } from './mobile-company/cp-installer-accept/cp-installer-accept.component';
import { AdminRoleGuardService } from './guards/admin-role-guard.service';
import { SuAllUsersComponent } from './mobile-company/su-all-users/su-all-users.component';
import { CpFilteredUsersComponent } from './mobile-company/cp-filtered-users/cp-filtered-users.component';
import { CpPanicButtonComponent } from './mobile-company/cp-panic-button/cp-panic-button.component';
import { SuIpcomsComponent } from './mobile-company/su-ipcoms/su-ipcoms.component';
import { SuIpcomComponent } from './mobile-company/su-ipcom/su-ipcom.component';
import { RequestAssistanceComponent } from './system/request-assistance/request-assistance.component';
import { ChangeInstallerComponent } from './system/change-installer/change-installer.component';
import { HistorySystemsComponent } from './installer/history-systems/history-systems.component';
import { NewSystemOfflineComponent } from './system/new-system-offline/new-system-offline.component';
import { ScanNearbyDevicesComponent } from './general/scan-nearby-devices/scan-nearby-devices.component';
import { NearbyDeviceFoundComponent } from './general/nearby-device-found/nearby-device-found.component';
import { DeviceApConnectComponent } from './general/device-ap-connect/device-ap-connect.component';
import { SuRegionsComponent } from './mobile-company/su-regions/su-regions.component';
import { SuRegionComponent } from './mobile-company/su-region/su-region.component';
import { ResetFireSensorsComponent } from './system/reset-fire-sensors/reset-fire-sensors.component';
import { QuickCamerasComponent } from './general/quick-cameras/quick-cameras.component';
import { QuickOutputsComponent } from './general/quick-outputs/quick-outputs.component';
import { OwnerPrivacyComponent } from './system/owner-privacy/owner-privacy.component';
import { ReplaceDeviceAdminComponent } from './system/replace-device-admin/replace-device-admin.component';
import { VersionComponent } from './general/version/version.component';
import { ConfirmDeviceTransferComponent } from './general/confirm-device-transfer/confirm-device-transfer.component';
import { SuTransferDeviceComponent } from './mobile-company/su-transfer-device/su-transfer-device.component';
import { TransferTypeComponent } from './system/transfer/transfer-type/transfer-type.component';
import { TransferDeviceComponent } from './system/transfer/transfer-device/transfer-device.component';
import { SettingsComponent } from './sadmin/settings/settings.component';
import { MobileAppComponent } from './mobile-app.component';
import { AppSettings } from 'src/app-settings';
import { LoginModule } from './login/login.module';
import { WifiDetailsComponent } from './general/wifi-details/wifi-details.component';
import { UResolver } from './guards/uresolve.resolver';
import { LoginBwcResetGuard } from './guards/login-bwc-reset.guard';
import { LoginBwcActivateGuard } from './guards/login-bwc-activate.guard';
import { DesktopUserLayoutComponent } from './desktop-user/desktop-user-layout/desktop-user-layout.component';
import { CompanyMobileHostComponent } from './company/pages/company-mobile-host/company-mobile-host.component';
import { MobileToDesktopUserGuard } from './guards/mobile-to-desktop-user.guard';
import { MobileGuardService } from './guards/mobile-guard.service';
import { CompanyAccountSettingsComponent } from './company/pages/company-account-settings/company-account-settings.component';
import { CompanyListComponent } from './sadmin/company-list/company-list.component';
import { EditCompanyComponent } from './sadmin/edit-company/edit-company.component';
import { PermissionListComponent } from './sadmin/permission-list/permission-list.component';
import { EditPermissionComponent } from './sadmin/edit-permission/edit-permission.component';
import { TagListComponent } from './sadmin/tag-list/tag-list.component';
import { EditTagComponent } from './sadmin/edit-tag/edit-tag.component';
import { ActiveSystemComponent } from './system/active-system/active-system.component';
import { TransferSystemToCompanyComponent } from './system/transfer-system-to-company/transfer-system-to-company.component';
import { Routes } from 'src/api/v3/common';
import { NewSystemComponent } from './general/new-system/new-system.component';
import { DeviceSetupTemplateSelectionComponent } from './general/device-setup-template-selection/device-setup-template-selection.component';
import { PanelSelectionComponent } from './general/panel-selection/panel-selection.component';
import { PanelBrandSelectionComponent } from './general/panel-brand-selection/panel-brand-selection.component';
import { DeviceQuickSettingsComponent } from './general/device-quick-settings/device-quick-settings.component';
import { DeviceQuickSettingsWriteComponent } from './general/device-quick-settings-write/device-quick-settings-write.component';
import { CpSettingsComponent } from './mobile-company/cp-settings/cp-settings.component';

export const newSystemRoutes: Routes = [
  { path: '', redirectTo: 'add-system', pathMatch: 'full' },
  { path: 'add-system', component: AddSystemUidComponent, data: { animation: 2 }, },
  { path: 'add-system-details', component: AddSystemDetailsComponent, data: { animation: 3 }, },
  { path: 'confirm-device-transfer', component: ConfirmDeviceTransferComponent, },
  { path: 'add-system-indirect', component: AddSystemIndirectComponent, },
  { path: 'new-system-offline', component: NewSystemOfflineComponent, data: { animation: 3 } },
  { path: 'system-area', component: AreaComponent, data: { animation: 4 }, },
  { path: 'add-system-area-list', component: AddSystemAreaListComponent, },
  { path: 'system-zone', component: SystemZoneComponent, data: { animation: 5 }, },
  { path: 'add-system-zone-list', component: AddSystemZoneListComponent, canActivate: [AuthGuardService], },
  { path: 'home-background-selection', component: HomeBackgroundSelectionComponent, data: { animation: 4 }, },
  { path: 'wiring-diagrams', component: WiringComponent, },
  { path: 'customize-pgms', component: SystemPgmListComponent, },
  { path: 'customize-areas', component: CustomizeAreasComponent, data: { animation: 5 }, },
  { path: 'customize-zones', component: CustomizeZonesComponent, data: { animation: 6 }, },
  { path: 'add-system-area-pgms', component: AreaPgmsComponent, },
  { path: 'add-system-area', component: AddSystemAreaComponent, },
  { path: 'add-system-complete', component: AddSystemCompleteComponent, canActivate: [AuthGuardService], data: { animation: 4 }, },
  { path: 'scan-nearby-devices', component: ScanNearbyDevicesComponent, canActivate: [AuthGuardService], data: { animation: 4 } },
  { path: 'nearby-device-found', component: NearbyDeviceFoundComponent, canActivate: [AuthGuardService], data: { animation: 5 } },
  { path: 'wifi-details', component: WifiDetailsComponent, canActivate: [AuthGuardService], data: { animation: 6 } },
  { path: 'device-ap-connect', component: DeviceApConnectComponent, canActivate: [AuthGuardService], data: { animation: 7 } },
  { path: 'select-template', component: DeviceSetupTemplateSelectionComponent, resolve: { u: UResolver }, data: { animation: 3 } },
  { path: 'select-panel-brand', component: PanelBrandSelectionComponent, data: { animation: 4 } },
  { path: 'select-panel', component: PanelSelectionComponent, data: { animation: 5 } },
  { path: 'quick-settings', component: DeviceQuickSettingsComponent, data: { animation: 6 } },
  { path: 'quick-settings/write', component: DeviceQuickSettingsWriteComponent, data: { animation: 7 } },
];

const routes: Routes = [
  AppSettings.companyDesktopEnabled ?
    { path: 'd/company', loadChildren: () => import('./company/company.module').then((m) => m.CompanyModule), } :
    { path: 'd/company', redirectTo: 'company' },

  /** Simple User Desktop */
  { path: 'd', component: DesktopUserLayoutComponent, canDeactivate: [MobileToDesktopUserGuard], children: [
    { path: '', component: CompanyMobileHostComponent, canActivate: [UserRoleGuardService, AuthGuardService], children: [
      { path: '', component: MobileAppComponent, resolve: { u: UResolver }, children: [
        { path: '', redirectTo: 'home', pathMatch: 'full' },
        { path: 'new-system', component: NewSystemComponent, children: newSystemRoutes },
        { path: 'home', component: HomeComponent, data: { animation: 1, bare: true } },
        { path: 'password-change', component: PasswordChangeComponent, },
        { path: 'select-language', component: LanguageListComponent, },
      ] },
    ]},
    { path: 'profile', component: CompanyAccountSettingsComponent, },
    { path: 'help', children: [
      { path: '', outlet: 'center', component: HelpComponent, data: { animation: 5 } },
      { path: '', component: CompanyMobileHostComponent, children: [
        { path: '', component: MobileAppComponent, children: [
          { path: '', component: HomeComponent, data: { animation: 1 } },
        ] }
      ] }
    ]},
    { path: 'help/ticket', children: [
      { path: '', outlet: 'center', component: TicketComponent },
      { path: '', component: CompanyMobileHostComponent, children: [
        { path: '', component: MobileAppComponent, children: [
          { path: '', component: HomeComponent, data: { animation: 1 } },
        ] }
      ] }
    ] },
    { path: 'no-password', component: NoPasswordComponent, canActivate: [GuestGuardService], },
    { path: 'name-register', component: NameRegisterComponent, },
    { path: 'undefined/home', redirectTo: 'home' }, // @TODO pataisyti "home/home". undefiend/home yra tik laikinas apejimas.
    { path: ':systemId', component: CompanyMobileHostComponent, children: [
      { path: '', component: ActiveSystemComponent, children: [
        { path: '', component: MobileAppComponent, canActivate: [UserRoleGuardService, AuthGuardService], resolve: { u: UResolver}, children: [
          { path: '', redirectTo: 'home', pathMatch: 'full' },
          { path: 'home', component: HomeComponent, data: { animation: 1 } },
          { path: 'home-background-selection', component: HomeBackgroundSelectionComponent, data: { animation: 4 }, },
          { path: 'system-location', component: SystemLocationComponent, },
          { path: 'system-zone', component: SystemZoneComponent, data: { animation: 5 }, },
          { path: 'system-configuration', redirectTo: 'home', pathMatch: 'full' },
          { path: 'system-information', component: SystemInformationComponent, data: { animation: 3 }, },
          { path: 'system-notifications', component: SystemNotificationsComponent, data: { animation: 3 }, },
          { path: 'system-area-list', component: AreaListComponent, data: { animation: 3 }, },
          { path: 'system-area', component: AreaComponent, data: { animation: 4 }, },
          { path: 'area-pgm-list', component: AreaPgmsComponent, },
          { path: 'system-zone-list', component: ZoneListComponent, data: { animation: 3 }, },
          { path: 'system-zone-list/:destination', component: ZoneListComponent, data: { animation: 4 }, },
          { path: 'system-pgm-list', component: SystemPgmListComponent, data: { animation: 3 }, },
          { path: 'system-pgm', component: SystemPgmComponent, data: { animation: 4 }, },
          { path: 'system-transfer', component: TransferComponent, data: { animation: 3 }, },
          { path: 'system-delete', component: DeleteComponent, data: { animation: 3 }, },
          { path: 'area-view', component: AreaViewComponent, data: { animation: 2 }, },
          { path: 'events', component: EventsComponent, data: { animation: 2 }, },
          { path: 'users', component: UserListComponent, data: { animation: 3 }, },
          { path: 'user', component: UserComponent, data: { animation: 4 }, },
          { path: 'zone-status-list', component: ZoneStatusListComponent, data: { animation: 3 }, },
          { path: 'pgm-icon-selection', component: PgmIconSelectComponent, data: { animation: 5 }, },
          { path: 'home-configuration', component: HomeConfigurationComponent, data: { animation: 2 }, },
          { path: 'system-sensor-list', component: SensorsListComponent, data: { animation: 3 }, },
          { path: 'system-sensor', component: SystemSensorComponent, data: { animation: 4 }, },
          { path: 'event-control', component: EventControlComponent, data: { animation: 3 }, },
          { path: 'show-zone', component: ShowZonesComponent, },
          { path: 'transfer-from-list', component: TransferFromListComponent, },
          { path: 'camera-list', component: CameraListComponent, data: { animation: 3 }, },
          { path: 'edit-camera', component: CameraComponent, canActivate: [AuthGuardService, UserRoleGuardService], data: { animation: 4 }, },
          { path: 'add-camera', component: CameraComponent, canActivate: [AuthGuardService, UserRoleGuardService], data: { animation: 4 }, },
          { path: 'reread', component: RereadComponent, data: { animation: 4 }, },
          { path: 'system-thermostat', component: SystemThermostatComponent, },
          { path: 'advanced-settings', component: AdvancedSettingsComponent, data: { animation: 4 }, },
          { path: 'reset-fire-sensors', component: ResetFireSensorsComponent, data: { animation: 3 } },
          { path: 'quick-view-cameras', component: QuickCamerasComponent, data: { animation: 2 }, },
          { path: 'quick-view-outputs', component: QuickOutputsComponent, data: { animation: 2 }, },
          { path: 'owner-privacy', component: OwnerPrivacyComponent, data: { animation: 4 }, },
          { path: 'replace-device-administrator', component: ReplaceDeviceAdminComponent, },
          { path: 'version', component: VersionComponent, data: { animation: 5 } },
          { path: 'transfer-type', component: TransferTypeComponent, data: { animation: 3 }, },
          { path: 'transfer-device', component: TransferDeviceComponent, data: { animation: 4 }, },
          { path: 'transfer-to-company', component: TransferSystemToCompanyComponent, data: { animation: 4 }, },
          { path: 'request-assistance', component: RequestAssistanceComponent, data: { animation: 1 } },
          { path: 'request-assistance/:installer', component: RequestAssistanceComponent, data: { animation: 2 } },
          { path: 'change-installer/:installer', component: ChangeInstallerComponent, data: { animation: 2 } },
          { path: 'configure/sp3', loadChildren: () => import('src/app/configurators/sp3/src/app/app.module').then(m => m.AppModule) },
          { path: 'configure/gv17', loadChildren: () => import('src/app/configurators/gv17/src/app/app.module').then(m => m.AppModule) },
          { path: 'configure/g16', loadChildren: () => import('src/app/configurators/g16/src/app/app.module').then(m => m.AppModule) },
          { path: 'configure/fc', loadChildren: () => import('src/app/configurators/fc/src/app/app.module').then(m => m.AppModule) },
        ] },
      ]},
    ]},
    // Device setup metu einant į advanced settings turi užkrauti be aktyvios sistemos.
    { path: 'configure', component: MobileAppComponent, children: [
      { path: 'sp3', loadChildren: () => import('src/app/configurators/sp3/src/app/app.module').then(m => m.AppModule) },
      { path: 'gv17', loadChildren: () => import('src/app/configurators/gv17/src/app/app.module').then(m => m.AppModule) },
      { path: 'g16', loadChildren: () => import('src/app/configurators/g16/src/app/app.module').then(m => m.AppModule) },
      { path: 'fc', loadChildren: () => import('src/app/configurators/fc/src/app/app.module').then(m => m.AppModule) },
    ]},
  ], canActivate: [UserRoleGuardService, AuthGuardService] },

  { path: 'login', loadChildren: () => LoginModule },
  { path: 'password/reset/:hash', canActivate: [LoginBwcResetGuard], component: LoadingScreenComponent },
  { path: 'reset/:hash', canActivate: [LoginBwcResetGuard], component: LoadingScreenComponent },
  { path: 'activate/:hash', canActivate: [LoginBwcActivateGuard], component: LoadingScreenComponent },
  { path: 'register/:reghash', canActivate: [LoginBwcActivateGuard], component: LoadingScreenComponent },
  {
    path: '', component: MobileAppComponent, resolve: { u: UResolver }, canActivate: [MobileGuardService], children: [
      { path: '', component: LoadingScreenComponent },
      { path: 'new-system', component: NewSystemComponent, children: newSystemRoutes },
      { path: 'login', component: LoginComponent, canActivate: [GuestGuardService], },
      { path: 'email', component: EmailComponent, canActivate: [GuestGuardService], },
      { path: 'password-login', component: PasswordLoginComponent, canActivate: [GuestGuardService], },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuardService], data: { animation: 1 }, },
      { path: 'no-password', component: NoPasswordComponent, canActivate: [GuestGuardService], },
      { path: 'name-register', component: NameRegisterComponent, canActivate: [AuthGuardService], },
      { path: 'home-background-selection', component: HomeBackgroundSelectionComponent, canActivate: [AuthGuardService], data: { animation: 4 }, },
      { path: 'system-location', component: SystemLocationComponent, canActivate: [AuthGuardService], },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuardService], },
      { path: 'password-change', component: PasswordChangeComponent, canActivate: [AuthGuardService], },
      { path: 'profile/delete', component: AccountDeleteComponent, canActivate: [AuthGuardService], },
      { path: 'select-language', component: LanguageListComponent, canActivate: [AuthGuardService], },
      { path: 'help', component: HelpComponent, data: { animation: 5 } },
      { path: 'help/ticket', component: TicketComponent },
      { path: 'version', component: VersionComponent, data: { animation: 5 } },
      { path: '', component: ActiveSystemComponent, canActivate: [AuthGuardService], children: [
        { path: 'system-zone', component: SystemZoneComponent, data: { animation: 5 }, },
        { path: 'system-configuration', component: SystemConfigurationComponent, data: { animation: 2 }, },
        { path: 'system-information', component: SystemInformationComponent, data: { animation: 3 }, },
        { path: 'system-notifications', component: SystemNotificationsComponent, data: { animation: 3 }, },
        { path: 'system-area-list', component: AreaListComponent, data: { animation: 3 }, },
        { path: 'system-area', component: AreaComponent, data: { animation: 4 }, },
        { path: 'area-pgm-list', component: AreaPgmsComponent, },
        { path: 'system-zone-list', component: ZoneListComponent, data: { animation: 3 }, },
        { path: 'system-zone-list/:destination', component: ZoneListComponent, data: { animation: 4 }, },
        { path: 'system-pgm-list', component: SystemPgmListComponent, data: { animation: 3 }, },
        { path: 'system-pgm', component: SystemPgmComponent, data: { animation: 4 }, },
        { path: 'system-transfer', component: TransferComponent, data: { animation: 4 }, },
        { path: 'system-delete', component: DeleteComponent, data: { animation: 3 }, },
        { path: 'area-view', component: AreaViewComponent, data: { animation: 2 }, },
        { path: 'events', component: EventsComponent, data: { animation: 2 }, },
        { path: 'users', component: UserListComponent, data: { animation: 3 }, },
        { path: 'user', component: UserComponent, data: { animation: 4 }, },
        { path: 'zone-status-list', component: ZoneStatusListComponent, data: { animation: 3 }, },
        { path: 'pgm-icon-selection', component: PgmIconSelectComponent, data: { animation: 5 }, },
        { path: 'home-configuration', component: HomeConfigurationComponent, data: { animation: 2 }, },
        { path: 'system-sensor-list', component: SensorsListComponent, data: { animation: 3 }, },
        { path: 'system-sensor', component: SystemSensorComponent, data: { animation: 4 }, },
        { path: 'event-control', component: EventControlComponent, data: { animation: 3 }, },
        { path: 'show-zone', component: ShowZonesComponent, },
        { path: 'transfer-from-list', component: TransferFromListComponent, },
        { path: 'reset-fire-sensors', component: ResetFireSensorsComponent, data: { animation: 3 } },
        { path: 'quick-view-cameras', component: QuickCamerasComponent, data: { animation: 2 }, },
        { path: 'quick-view-outputs', component: QuickOutputsComponent, data: { animation: 2 }, },
        { path: 'owner-privacy', component: OwnerPrivacyComponent, data: { animation: 4 }, },
        { path: 'replace-device-administrator', component: ReplaceDeviceAdminComponent, },
        { path: 'transfer-type', component: TransferTypeComponent, data: { animation: 3 }, },
        { path: 'edit-camera', component: CameraComponent, data: { animation: 4 }, },
        { path: 'add-camera', component: CameraComponent, data: { animation: 4 }, },
        { path: 'reread', component: RereadComponent, data: { animation: 4 }, },
        { path: 'system-thermostat', component: SystemThermostatComponent, },
        { path: 'advanced-settings', component: AdvancedSettingsComponent, data: { animation: 4 }, },
        { path: 'camera-list', component: CameraListComponent, data: { animation: 3 }, },
        { path: 'transfer-to-company', component: TransferSystemToCompanyComponent, data: { animation: 4 }, },
        { path: 'transfer-device', component: TransferDeviceComponent, data: { animation: 4 }, },
        { path: 'request-assistance', component: RequestAssistanceComponent },
      ]},
      { path: 'request-assistance/:installer', component: RequestAssistanceComponent, },
      { path: 'change-installer/:installer', component: ChangeInstallerComponent, },

      {
        path: 'configure/sp3',
        loadChildren: () => import('./configurators/sp3/src/app/app.module').then(m => m.AppModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'configure/gv17',
        loadChildren: () => import('./configurators/gv17/src/app/app.module').then(m => m.AppModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'configure/g16',
        loadChildren: () => import('./configurators/g16/src/app/app.module').then(m => m.AppModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'configure/fc',
        loadChildren: () => import('./configurators/fc/src/app/app.module').then(m => m.AppModule),
        canActivate: [AuthGuardService],
      },

      /** Company */
      {
        path: 'company', children: [
          { path: '', component: CpHomeComponent },
          { path: 'all-events', component: CpAllEventsComponent },
          { path: 'filtered-systems', component: CpFilteredSystemsComponent },
          { path: 'group-events/:group', component: CpAllEventsComponent },
          { path: 'edit-events', component: CpEditEventsComponent },
          { path: 'edit-event', component: CpEditEventComponent },
          { path: 'add-event', component: CpEditEventComponent },
          { path: 'all-users', component: CpAllUsersComponent },
          { path: 'add-user', component: CpEditUserComponent, resolve: { u: UResolver } },
          { path: 'edit-user/:num', component: CpEditUserComponent, resolve: { u: UResolver } },
          { path: 'edit-user/:num/:from', component: CpEditUserComponent, resolve: { u: UResolver } },
          { path: 'edit-reactions', component: CpEditReactionsComponent },
          { path: 'edit-reaction', component: CpEditReactionComponent },
          { path: 'filtered-users', component: CpFilteredUsersComponent },
          { path: 'panic-button', component: CpPanicButtonComponent },
          { path: 'global-settings', component: SettingsComponent },
          { path: 'company-settings', component: EditCompanyComponent },
          { path: 'permissions', component: PermissionListComponent },
          { path: 'permissions/new', component: EditPermissionComponent },
          { path: 'permissions/edit', component: EditPermissionComponent },
          { path: 'tags', component: TagListComponent },
          { path: 'tags/new', component: EditTagComponent },
          { path: 'tags/edit', component: EditTagComponent },
        ], canActivate: [CompanyRoleGuardService, AuthGuardService],
      },

      /** SuperAdmin */
      {
        path: 'sadmin', children: [
          { path: '', component: CpHomeComponent },
          { path: 'all-events', component: CpAllEventsComponent },
          { path: 'filtered-systems', component: CpFilteredSystemsComponent },
          { path: 'edit-events', component: CpEditEventsComponent },
          { path: 'edit-event', component: CpEditEventComponent },
          { path: 'add-event', component: CpEditEventComponent },
          { path: 'all-users', component: SuAllUsersComponent },
          { path: 'add-user', component: CpEditUserComponent, resolve: { u: UResolver } },
          { path: 'edit-user/:num', component: CpEditUserComponent, resolve: { u: UResolver } },
          { path: 'edit-user/:num/:from', component: CpEditUserComponent, resolve: { u: UResolver } },
          { path: 'edit-reactions', component: CpEditReactionsComponent },
          { path: 'edit-reaction', component: CpEditReactionComponent },
          { path: 'filtered-users', component: CpFilteredUsersComponent },
          { path: 'panic-button', component: CpPanicButtonComponent },
          { path: 'ipcoms', component: SuIpcomsComponent },
          { path: 'ipcom', component: SuIpcomComponent },
          { path: 'global-settings', component: SettingsComponent },
          { path: 'regions', component: SuRegionsComponent },
          { path: 'region', component: SuRegionComponent },
          { path: 'transfer-device', component: SuTransferDeviceComponent },
          { path: 'settings', component: CpSettingsComponent },
          { path: 'companies', component: CompanyListComponent },
          { path: 'companies/new', component: EditCompanyComponent },
          { path: 'companies/edit', component: EditCompanyComponent },
          { path: 'permissions', component: PermissionListComponent },
          { path: 'permissions/new', component: EditPermissionComponent },
          { path: 'permissions/edit', component: EditPermissionComponent },
          { path: 'tags', component: TagListComponent },
          { path: 'tags/new', component: EditTagComponent },
          { path: 'tags/edit', component: EditTagComponent },
        ], canActivate: [AdminRoleGuardService, AuthGuardService],
      },

      /** Installer */
      {
        path: 'installer', children: [
          { path: '', component: CpHomeComponent },
          { path: 'all-events', component: CpAllEventsComponent },
          { path: 'filtered-systems', component: CpFilteredSystemsComponent },
          { path: 'history-systems', component: HistorySystemsComponent },
          { path: 'permissions', component: PermissionListComponent },
          { path: 'permissions/new', component: EditPermissionComponent },
          { path: 'permissions/edit', component: EditPermissionComponent },
          { path: 'tags', component: TagListComponent },
          { path: 'tags/new', component: EditTagComponent },
          { path: 'tags/edit', component: EditTagComponent },
        ], canActivate: [InstallerRoleGuardService, AuthGuardService],
      },
      { path: 'accept-invitation/:hash', component: CpInstallerAcceptComponent, },

      /** Path iš notification */
      { path: 'system/view/:systemId', component: LoadingScreenComponent },
      { path: 'system/events/:systemId', component: LoadingScreenComponent },
      { path: 'area/view/:areaId', component: LoadingScreenComponent },

      { path: '**', component: LoadingScreenComponent },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
