<div class="section_header padding_l16 awf_container" *ngIf="!filteringActive">
    <div class="awf_text">{{sectionText}}</div>
    <app-popup-menu>
        <div slot="content" class="awf_icon" (click)="openSortingMenu()"><svg width="18px" height="18px" viewBox="0 0 18.051 18.051"><path d="M7.092,15.144H5.158V2.895a.645.645,0,0,0-.645-.645H3.224a.645.645,0,0,0-.645.645V15.144H.645a.645.645,0,0,0-.455,1.1l3.223,3.868a.645.645,0,0,0,.911,0l3.223-3.868A.646.646,0,0,0,7.092,15.144Zm9.67-2.579H11.6a.645.645,0,0,0-.645.645V14.5a.645.645,0,0,0,.645.645h2.256l-2.468,2.839a1.289,1.289,0,0,0-.433.964v.71a.645.645,0,0,0,.645.645h5.158a.645.645,0,0,0,.645-.645V18.367a.645.645,0,0,0-.645-.645H14.506l2.468-2.839a1.289,1.289,0,0,0,.433-.964v-.71A.645.645,0,0,0,16.762,12.565Zm1.252-3.44L15.626,2.678a.645.645,0,0,0-.607-.428h-1.67a.645.645,0,0,0-.607.428L10.353,9.125a.645.645,0,0,0,.607.861h1a.645.645,0,0,0,.614-.446l.178-.521h2.861l.178.521a.645.645,0,0,0,.615.446h1a.645.645,0,0,0,.607-.861ZM13.523,6.763l.66-1.934.66,1.934Z" transform="translate(0 -2.25)" fill="#666"/></svg></div>
        <div slot="menu">
            <ng-container *ngFor="let iField of sortableFields">
                <div (click)="sortingOrderSelected(true, iField.field)">
                    <svg viewBox="0 0 18.051 18.051"><path d="M7.092,15.144H5.158V2.895a.645.645,0,0,0-.645-.645H3.224a.645.645,0,0,0-.645.645V15.144H.645a.645.645,0,0,0-.455,1.1l3.223,3.868a.645.645,0,0,0,.911,0l3.223-3.868A.646.646,0,0,0,7.092,15.144Zm9.67-2.579H11.6a.645.645,0,0,0-.645.645V14.5a.645.645,0,0,0,.645.645h2.256l-2.468,2.839a1.289,1.289,0,0,0-.433.964v.71a.645.645,0,0,0,.645.645h5.158a.645.645,0,0,0,.645-.645V18.367a.645.645,0,0,0-.645-.645H14.506l2.468-2.839a1.289,1.289,0,0,0,.433-.964v-.71A.645.645,0,0,0,16.762,12.565Zm1.252-3.44L15.626,2.678a.645.645,0,0,0-.607-.428h-1.67a.645.645,0,0,0-.607.428L10.353,9.125a.645.645,0,0,0,.607.861h1a.645.645,0,0,0,.614-.446l.178-.521h2.861l.178.521a.645.645,0,0,0,.615.446h1a.645.645,0,0,0,.607-.861ZM13.523,6.763l.66-1.934.66,1.934Z" transform="translate(0 -2.25)" fill="#666"/></svg>
                    <div>{{iField.name}}</div>
                </div>
                <div (click)="sortingOrderSelected(false, iField.field)">
                    <svg viewBox="0 0 18.051 18.052"><path d="M.645,7.408H2.579V19.657a.645.645,0,0,0,.645.645H4.513a.645.645,0,0,0,.645-.645V7.408H7.092a.645.645,0,0,0,.456-1.1L4.324,2.439a.645.645,0,0,0-.911,0L.189,6.307A.645.645,0,0,0,.645,7.408ZM11.6,9.986h5.158a.645.645,0,0,0,.645-.645V8.052a.645.645,0,0,0-.645-.645H14.506l2.468-2.839a1.289,1.289,0,0,0,.433-.964v-.71a.645.645,0,0,0-.645-.645H11.6a.645.645,0,0,0-.645.645V4.184a.645.645,0,0,0,.645.645h2.256L11.392,7.667a1.289,1.289,0,0,0-.433.964v.71A.645.645,0,0,0,11.6,9.986Zm6.409,9.454-2.388-6.447a.645.645,0,0,0-.607-.428h-1.67a.645.645,0,0,0-.607.428L10.353,19.44a.645.645,0,0,0,.607.861h1a.645.645,0,0,0,.614-.446l.178-.521h2.861l.178.521a.645.645,0,0,0,.615.446h1a.645.645,0,0,0,.607-.861Zm-4.491-2.362.66-1.934.66,1.934Z" transform="translate(0 -2.25)" fill="#666"/></svg>
                    <div>{{iField.name}}</div>
                </div>
            </ng-container>
        </div>
    </app-popup-menu>
    <div class="awf_icon" (click)="openFilter()"><svg width="18px" height="18px" viewBox="0 0 17.999 18.002"><path d="M17.754,15.564l-3.505-3.505a.843.843,0,0,0-.6-.246h-.573a7.309,7.309,0,1,0-1.266,1.266v.573a.843.843,0,0,0,.246.6l3.505,3.505a.84.84,0,0,0,1.192,0l.995-.995a.848.848,0,0,0,0-1.2ZM7.313,11.813a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,7.313,11.813Z" fill="#666"/></svg></div>
</div>
<div *ngIf="filteringActive" class="awf_filter_container">
    <app-text-edit class="awf_filter_input" [transparentInput]="true" [value]="fs.getFilterPhraseFor(sectionName)" (valueChanged)="fs.performFilter(sectionName, $event, dataSource, filterableFields)" [doFocus]="true"></app-text-edit>
    <div class="awf_clear" (click)="fs.clearFilterFor(sectionName); filteringActive = false"><svg width="12" height="12" viewBox="0 0 15.199 15.199"><path d="M22.7,9.031,21.168,7.5,15.1,13.569,9.031,7.5,7.5,9.031,13.569,15.1,7.5,21.168,9.031,22.7,15.1,16.63,21.168,22.7,22.7,21.168,16.63,15.1Z" transform="translate(-7.5 -7.5)" fill="#666"/></svg></div>
</div>