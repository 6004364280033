import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/api/user.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-dash-group-item',
  templateUrl: './dash-group-item.component.html',
  styleUrls: ['./dash-group-item.component.scss'],
})
export class DashGroupItemComponent implements OnInit {
  @Input() groupItem = null;
  public groupIsVisible = true;

  constructor(private us: UserService, private lang: LanguageService, private router: Router) {}

  ngOnInit(): void {
    if (this.groupItem.icon === '') {
      this.groupItem.icon = 'assets/images/group_other.svg';
    }
    this.groupIsVisible = true; // this.groupItem.events > 0;
  }

  public showGroupEvents() {
    this.router.navigate(['/company/group-events/' + this.groupItem.id]);
  }
}
