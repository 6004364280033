<div class="middle_column_grid">
  <div class="middle_column">
    <div class="header">
      <h4 class="title">{{trans('systems.titles.transferDevice')}}</h4>
    </div>
    <div class="pageContent">
      <app-string-input [label]="trans('systems.labels.imei')" [(value)]="systemUid" [maxLength]='16'>
        <app-form-error *ngIf="val.hasError('systemUid')">{{ val.getError('systemUid') }}</app-form-error>
      </app-string-input>
      <app-name-value [nameText]="trans('users.labels.useIpcom')">
        <app-toggle-button [buttonId]="'ipcom'" [initialState]="useIpcom" (toggled)="useIpcom = $event.checked"></app-toggle-button>
      </app-name-value>
      <app-dropdown *ngIf="useIpcom" [label]="trans('users.labels.targetIpcom')" [items]="ipcomDropdownItems" [(value)]="selectedIpcom"></app-dropdown>
      <app-string-input *ngIf="!useIpcom" [label]="trans('users.labels.customHost')" [(value)]="customHost"></app-string-input>
      <app-number-input [label]="trans('users.labels.customPort')" [(value)]="customPort" [min]="0" [max]="65535" [maxLength]="5" [step]="1">
        <app-form-error *ngIf="val.hasError('customPort')">{{ val.getError('customPort') }}</app-form-error>
      </app-number-input>
      <app-string-input [label]="trans('systems.labels.mpass')" [(value)]="mpass" [maxLength]="6"></app-string-input>
      <app-button [disabled]="transferring" rounded="partial" type="filled-secondary" (click)="performTransfer()">
        <div class="flex">
          <div *ngIf="transferring" class="spinner"></div>
          {{ trans('systems.buttons.transfer') }}
        </div>
      </app-button>
    </div>
  </div>
</div>
