<ng-template #name let-class="className"><div [ngClass]="class">{{trans('settings.labels.regionName')}}</div></ng-template>
<ng-template #api_host let-class="className"><div [ngClass]="class">{{trans('settings.labels.regionHost')}}</div></ng-template>
<ng-template #api_path let-class="className"><div [ngClass]="class">{{trans('settings.labels.regionPath')}}</div></ng-template>
<ng-template #region_version let-class="className"><div [ngClass]="class">{{trans('settings.labels.regionVersion')}}</div></ng-template>

<ng-template #nameContent let-value="value" let-item="item" let-column="column">
  <ng-container *ngIf="regionItemInEdit && regionItemInEdit.id === item.id && regionItemInEdit.column === column; then editName else defaultName"></ng-container>
  <ng-template #editName>
    <div class="contentEditWrap">
      <app-string-input #inputField [(value)]="regionItemToSave.name" ngClass="flex-1" size="small" autofocus></app-string-input>
      <div class='saveIcon' (click)="onSaveButtonClick()" [inlineSVG]="'assets/images/check-simple.svg'"></div>
      <div class='cancelIcon' (click)="onCancelButtonClick()" [inlineSVG]="'assets/images/close-simple.svg'"></div>
    </div>
  </ng-template>
  <ng-template #defaultName>
    <div class="flex">
      <a (click)="openRegionForEditing(item)" [ngClass]="['linkText', this.idsWithError.has(item.id) ? 'hasError': '']">{{value}}</a>
      <ng-container *ngTemplateOutlet="statusIcon; context: {item, column}"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #apiHostContent let-value="value" let-item="item" let-column="column">
  <ng-container *ngIf="regionItemInEdit && regionItemInEdit.id === item.id && regionItemInEdit.column === column; then apiHost else defaultApiHost"></ng-container>
  <ng-template #apiHost>
    <div class="contentEditWrap">
      <app-string-input #inputField [(value)]="regionItemToSave.api_host" ngClass="flex-1" size="small" autofocus></app-string-input>
      <div class='saveIcon' (click)="onSaveButtonClick()" [inlineSVG]="'assets/images/check-simple.svg'"></div>
      <div class='cancelIcon' (click)="onCancelButtonClick()" [inlineSVG]="'assets/images/close-simple.svg'"></div>
    </div>
  </ng-template>
  <ng-template #defaultApiHost>
    <div class="flex">
      <a [ngClass]="['secondaryText', this.idsWithError.has(item.id) ? 'hasError': '']">{{value}}</a>
      <ng-container *ngTemplateOutlet="statusIcon; context: {item, column}"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #apiPathContent let-value="value" let-item="item" let-column="column">
  <ng-container *ngIf="regionItemInEdit && regionItemInEdit.id === item.id && regionItemInEdit.column === column; then apiPath else defaultApiPath"></ng-container>
  <ng-template #apiPath>
    <div class="contentEditWrap">
      <app-string-input #inputField [(value)]="regionItemToSave.api_path" ngClass="flex-1" size="small" autofocus></app-string-input>
      <div class='saveIcon' (click)="onSaveButtonClick()" [inlineSVG]="'assets/images/check-simple.svg'"></div>
      <div class='cancelIcon' (click)="onCancelButtonClick()" [inlineSVG]="'assets/images/close-simple.svg'"></div>
    </div>
  </ng-template>
  <ng-template #defaultApiPath>
    <div class="flex">
      <a [ngClass]="['secondaryText', this.idsWithError.has(item.id) ? 'hasError': '']">{{value}}</a>
      <ng-container *ngTemplateOutlet="statusIcon; context: {item, column}"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #regionVersionContent let-value="value" let-item="item" let-column="column">
  <ng-container *ngIf="regionItemInEdit && regionItemInEdit.id === item.id && regionItemInEdit.column === column; then regionVersion else defaultRegionVersion"></ng-container>
  <ng-template #regionVersion>
    <div class="contentEditWrap">
      <div class="versionDropdown">
        <app-dropdown [label]="trans('settings.labels.regionVersion')" size="small" [items]="[{label: '1', value: 1}, {label: '2', value: 2}]" [(value)]="regionItemToSave.region_version"></app-dropdown>
      </div>
      <div class='saveIcon' (click)="onSaveButtonClick()" [inlineSVG]="'assets/images/check-simple.svg'"></div>
      <div class='cancelIcon' (click)="onCancelButtonClick()" [inlineSVG]="'assets/images/close-simple.svg'"></div>
    </div>
  </ng-template>
  <ng-template #defaultRegionVersion>
    <div class="flex">
      <a [ngClass]="['secondaryText', this.idsWithError.has(item.id) ? 'hasError': '']">{{value}}</a>
      <ng-container *ngTemplateOutlet="statusIcon; context: {item, column}"></ng-container>
    </div>
  </ng-template>
</ng-template>

<ng-template #deleteIcon>
  <div class="deleteIcon" [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>
<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.regions.view"
  [data]="loader"
  [slots]="{
    'header:name': name,
    name: nameContent,
    'header:api_host': api_host,
    api_host: apiHostContent,
    'header:api_path': api_path,
    api_path: apiPathContent,
    'header:region_version': region_version,
    region_version: regionVersionContent,
    'fake:delete': deleteIcon
  }"
  [columns]="columns"
  (modifyColumns)="modifyColumns.emit()"
  (rowClick)="onRowClick($event)"
  [dataSize]="us.currentUser.regions.length"
>
</app-data-table>

<ng-template #statusIcon let-item="item" let-column="column" let-value="value">
  <ng-container *ngIf="region.quickEditAllowed">
    <div [ngSwitch]="true">
      <ng-container *ngSwitchCase="prevRegionItemInEdit && prevRegionItemInEdit.id === item.id && prevRegionItemInEdit.column === column && prevRegionItemInEdit.hasChanged && isLoading && !isSaved">
        <div class="spinner"></div>
      </ng-container>
      <ng-container *ngSwitchCase="prevRegionItemInEdit && prevRegionItemInEdit.id === item.id && prevRegionItemInEdit.column === column && prevRegionItemInEdit.hasChanged && isSaved">
        <div class='checkIcon' [inlineSVG]="'assets/images/check-simple.svg'"></div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class='editIcon' (click)="onEditButtonClick(item, column); $event.stopPropagation()" [inlineSVG]="'assets/images/edit.svg'"></div>
      </ng-container>
    </div>
  </ng-container>
</ng-template>
