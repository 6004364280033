import { AfterViewChecked, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HeaderService } from 'src/app/services/header.service';
import { SystemConfigurationComponent } from 'src/app/system/system-configuration/system-configuration.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { autoinject } from 'src/shim';
import { NewSystemService } from 'src/app/services/new-system.service';
import { LocatorService } from 'src/app/services/locator.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-desktop-user-layout',
  templateUrl: './desktop-user-layout.component.html',
  styleUrls: ['./desktop-user-layout.component.scss'],
  providers: [SystemConfigurationComponent]
})
export class DesktopUserLayoutComponent extends LanguageAware implements OnInit, OnDestroy, AfterViewChecked {
  public tag = 'DesktopUserLayoutComponent';
  public columnCount = 1;
  public hasSystems: boolean;
  public isOnHomeRoute: boolean;
  public isCenterRouteActive: boolean;
  private twoColumnRoutes = [
    '/d/new-system/',
    '/d/configure/',
  ];
  private get newSystemService() { return autoinject(LocatorService.injector, NewSystemService); }

  private routerLocationCenterRoutes = ['/d/help', '/d/help/ticket'];

  private routeSubscription = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      const route = this.router.url;
      this.isCenterRouteActive = this.routerLocationCenterRoutes.includes(route);
      this.isOnHomeRoute = route.endsWith('/home') ? true : false;

      this.columnCount = this.systems.systems.length > 0 ? (this.twoColumnRoutes.find(r => route.startsWith(r)) ? 2 : 3) : 1;
      this.cdRef.detectChanges();
    }
  });
  private systemsLoadedListener: Subscription;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private headerService: HeaderService,
    public systemConfiguration: SystemConfigurationComponent,
    private injector: Injector,
  ) {
    super(cdRef);
    this.systems.loadSystems();
    this.headerService.isDesktopRoleSimpleUser = true;
    this.hasSystems = this.systems.systems.length > 0 ? true : false;
    this.checkIfSystemsLoaded();
    this.log('+');
    this.systemsLoadedListener = this.systems.onSystemsLoaded.subscribe(() => {
      this.hasSystems = this.systems.systems.length > 0 ? true : false;
      if ( this.hasSystems ) {
        this.columnCount = this.hasSystems ? (this.twoColumnRoutes.includes(this.router.url) ? 2 : 3) : 1;
        this.cdRef.detectChanges();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.headerService.isDesktopRoleSimpleUser = false;
    this.systemsLoadedListener.unsubscribe();
  }

  ngAfterViewChecked() {
    if(this.isCenterRouteActive) {
      const host = document.querySelector('.bg-gray');
      host?.classList.remove('bg-gray');
    }
  }

  private checkIfSystemsLoaded() {
    if(this.hasSystems) { return; }
    setTimeout(() => {
      this.hasSystems = this.systems.systems.length > 0 ? true : false;
      if(this.hasSystems) {
        this.columnCount = this.hasSystems ? (this.twoColumnRoutes.includes(this.router.url) ? 2 : 3) : 1;
        this.cdRef.detectChanges();
      }
      this.checkIfSystemsLoaded();
    }, 200);
  }

  public newSystemClick() {
    this.newSystemService.setDefaults();
    this.router.navigate([...this.g.resolveRootRoute(), 'new-system']);
  }

  public setRef() {
    localStorage.setItem('ref', 'conf');
  }
}
