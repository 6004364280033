<button
  [ngClass]="[
    'button',
    rounded === 'none' ? '' : rounded === 'partial' ? 'partialRounding' : 'fullRounding',
    type,
    active ? 'active' : '',
    disabled ? 'disabled' : '',
    isLoading ? 'loading' : ''
  ]"
  (click)="disabled ? $event.stopPropagation() || $event.preventDefault() : undefined"
>
  <ng-content></ng-content>
</button>
