<app-company-page-layout gridTemplateColumns="3fr 2fr">
  <div class="header">
    <h4 class="title">{{trans('setup.device.setupTemplates')}}</h4>
    <ng-container *ngIf="us.currentUser.permissions.permissions.dev_setup_templates.create">
      <app-icon-button [routerLink]="[companyRootRoute + '/settings/device-setup-templates/new']" [label]="trans('setup.device.addTemplate')" icon="assets/images/company/plus.svg"></app-icon-button>
    </ng-container>
    <app-company-columns [items]="columnConfig" [(value)]="columns.value" #cols></app-company-columns>
  </div>
  <app-dev-templates-data-table [columns]="columns.value" (modifyColumns)="cols.show()"></app-dev-templates-data-table>
</app-company-page-layout>
