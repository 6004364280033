import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { DeviceSetupTemplate } from 'src/api/v3/device-setup-templates';
import requests from 'src/api/v3/requests';
import { LoggerService } from 'src/app/api/logger.service';
import { G16 } from 'src/app/configurators/g16/src/app/models/g16-devices';
import { UResolvable } from 'src/app/guards/UResolvable';
import { gFamily, gvFamily, indirectOnlyDevices, spFamily } from 'src/app/services/global.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-device-setup-template-selection',
  templateUrl: './device-setup-template-selection.component.html',
  styleUrls: ['./device-setup-template-selection.component.scss']
})
export class DeviceSetupTemplateSelectionComponent extends LanguageAware implements OnInit, UResolvable<typeof DeviceSetupTemplateSelectionComponent> {
  public noTemplates = false;
  public readonly trk8Device = this.ns.deviceSettings.hwId === G16.G16T_47 || this.ns.deviceSettings.hwId === G16.GET || this.ns.deviceSettings.hwId === G16.G16T_50;
  constructor(
    cdRef: ChangeDetectorRef,
    public ns: NewSystemService,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'TemplateSelection';
    this.log('+');
    if ( !this.ns.isSystemBeingCreated() ) {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    if ( this.ns.loadedSetupTemplates.length === 1 ) {
      this.onSelectionMade(this.ns.loadedSetupTemplates[0]);
      return;
    }
    if ( this.ns.loadedSetupTemplates.length === 0 ) {
      this.noTemplates = true;
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.selectSetupTemplate'),
      backUrl: '*',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.background.setGray();
    this.footerBar.onButton1Click = () => {
      history.back();
    };
  }

  ngOnInit(): void {
  }

  public onSelectionMade(template: DeviceSetupTemplate) {
    const singleTemplate = this.ns.loadedSetupTemplates.length === 1;
    this.ns.selectedSetupTemplate = template;
    const intHwId = parseInt(this.ns.getDeviceInfo().hwId, 16);
    if ( gFamily.includes(intHwId) ) {
      if ( indirectOnlyDevices.includes(intHwId) ) {
        this.router.navigate(['../quick-settings'], { relativeTo: this.r, replaceUrl: singleTemplate });
      } else {
        this.router.navigate(['../select-panel-brand'], { relativeTo: this.r, replaceUrl: singleTemplate });
      }
    } else if ( spFamily.includes(intHwId) ) {
      this.router.navigate(['../quick-settings'], { relativeTo: this.r, replaceUrl: singleTemplate });
    } else if ( gvFamily.includes(intHwId) ) {
      this.router.navigate(['../quick-settings'], { relativeTo: this.r, replaceUrl: singleTemplate });
    }
  }

  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot) {
    const l = injector.get(LoggerService);
    const ns = injector.get(NewSystemService);
    const trk8Device = ns.deviceSettings.hwId === G16.G16T_47 || ns.deviceSettings.hwId === G16.GET || ns.deviceSettings.hwId === G16.G16T_50;
    if ( ns.loadedSetupTemplates.length === 0 ) {
      const templates = await requests.deviceSetupTemplates.getTemplates({}).toPromise();
      ns.loadedSetupTemplates = trk8Device ? templates.list.filter(t => t.encryption.length >= 16) : templates.list.filter(t => t.encryption.length < 16);
    }
    l.log('Resolved', 'TemplateSelection');
  }
}
