import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from 'src/api/v3/company';
import requests from 'src/api/v3/requests';
import { CompanyService } from 'src/app/api/company.service';
import { LocaleService } from 'src/app/api/locale.service';
import { LanguageService } from 'src/app/services/language.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { StepperComponent } from 'src/app/ui/stepper/stepper.component';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { countryList } from 'src/environments/countrylist';
type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  companyVat: string;
  companyAddress: string;
  companyCity: string;
  companyCountry: string;
  companyEmail: string;
  companyPhone: string;
};
const companyRegisterValidator = new ValidatorBuilder<FormData>()
  .required('firstName', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.firstName'))
  .required('lastName', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.lastName'))
  .required('email', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.email'))
  .regex('email', /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, (_, c: CompanyRegisterComponent) => c.trans('companies.errors.invalidEmail'))
  .required('phone', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.phone'))
  .required('companyName', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.companyName'))
  .required('companyAddress', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.companyAddress'))
  .required('companyCity', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.companyCity'))
  .required('companyCountry', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.companyCountry'))
  .required('companyEmail', (_, c: CompanyRegisterComponent) => c.trans('companies.errors.email'))
  .regex('companyEmail', /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, (_, c: CompanyRegisterComponent) => c.trans('companies.errors.invalidEmail'));

@Component({
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.scss'],
})
export class CompanyRegisterComponent implements OnInit {
  public val = companyRegisterValidator.build().bindContext(this);
  public data: FormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    companyAddress: '',
    companyCountry: '',
    companyVat: '',
    companyCity: '',
    companyEmail: '',
    companyPhone: '',
  };
  public step = 0;
  public countryList: DropdownItem<string>[];
  public readonly trans = (key: string) => this.ls.get(key);
  public submitting = false;
  @ViewChild('stepper') stepper: StepperComponent;

  constructor(
    private locale: LocaleService,
    private ls: LanguageService,
    private toaster: ToasterService,
    private companyService: CompanyService,
    private router: Router,
  ) {
    const labaler = (code: string) => this.locale.getCountryName(code);
    this.countryList = countryList.map((code) => ({
      value: code,
      label: labaler,
    }));
    this.countryList.unshift({
      value: '',
      label: this.trans('companies.labels.companyCountry'),
      default: true,
    });
  }

  ngOnInit(): void {}

  public async onNext() {
    if (!(await this.val.validate(this.data, ['firstName', 'lastName', 'email', 'phone']))) { return; }
    this.stepper.nextStep();
  }

  public async submit() {
    if ( this.submitting ) { return; }
    if (!(await this.val.validate(this.data))) { return; }
    this.submitting = true;
    const company: Company = {
        address: this.data.companyAddress,
        city: this.data.companyCity,
        country: this.data.companyCountry,
        email: this.data.companyEmail,
        name: this.data.companyName,
        registration_email: this.data.email,
        phone: this.data.companyPhone,
        vat_id: this.data.companyVat,
        active: undefined,
        id: undefined,
        owner_id: undefined,
        logo: undefined,
        alias: undefined,
    };
    requests.company.addCompany({
      owner_last_name: this.data.lastName,
      owner_name: this.data.firstName,
      owner_phone: this.data.phone,
      ...company,
    }).subscribe(
      result => {
        this.submitting = false;
        company.id = result.id;
        this.companyService.ingestCompany(company);
        this.toaster.postSuccess(this.trans('companies.labels.registration.registered'));
        this.router.navigate(['/']);
      },
      error => { this.submitting = false; }
    );
  }
}
