<div class="grid ther_grid">
    <div id='ther_control_back' class="group-background"></div>
    <div id='ther_control_header' class="section_header">{{trans('systems.labels.thermostat')}}</div>
    <div id='ther_control'>
        <div id='ther_outer'><div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.052 151.473">
            <g transform="translate(239.026 1.5) rotate(180)">
              <path class="scale1_dash" d="M-.5,0-.5,9.47" transform="matrix(0.259, 0.966, -0.966, 0.259, 233.741, -147.648)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.47" transform="matrix(0.174, 0.985, -0.985, 0.174, 235.89, -137.579)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0V9.48" transform="matrix(0.087, 0.996, -0.996, 0.087, 237.162, -127.318)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0V9.48" transform="translate(237.526 -117.028) rotate(90)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.47" transform="matrix(-0.087, 0.996, -0.996, -0.087, 237.123, -106.994)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.47" transform="matrix(-0.174, 0.985, -0.985, -0.174, 235.717, -96.785)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.506,0l.012,9.467" transform="matrix(-0.259, 0.966, -0.966, -0.259, 233.46, -86.844)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.506,0l.012,9.467" transform="translate(230.311 -77.03) rotate(110)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.509,0l.017,9.47" transform="translate(226.272 -67.437) rotate(115)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.509,0l.017,9.47" transform="translate(221.459 -58.323) rotate(120)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.509,0l.019,9.456" transform="matrix(-0.574, 0.819, -0.819, -0.574, 215.979, -49.942)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.509,0l.019,9.456" transform="translate(209.68 -41.793) rotate(130)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.509,0l.019,9.449" transform="translate(202.693 -34.312) rotate(135)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.509,0l.019,9.449" transform="translate(195.083 -27.38) rotate(140)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.509,0l.017,9.45" transform="matrix(-0.819, 0.574, -0.574, -0.819, 186.784, -20.986)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.509,0l.017,9.45" transform="translate(178.072 -15.491) rotate(150)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.506,0l.012,9.438" transform="translate(169.04 -11.051) rotate(155)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.506,0l.012,9.438" transform="translate(159.53 -7.14) rotate(160)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.434" transform="matrix(-0.966, 0.259, -0.259, -0.966, 149.696, -4.126)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.434" transform="matrix(-0.985, 0.174, -0.174, -0.985, 139.656, -1.928)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.474,0V9.44" transform="matrix(-0.996, 0.087, -0.087, -0.996, 129.312, -0.408)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.474,0V9.44" transform="translate(119.026) rotate(180)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.434" transform="matrix(0.996, 0.087, -0.087, 0.996, 110.708, -10.041)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.434" transform="matrix(0.985, 0.174, -0.174, 0.985, 101.332, -11.219)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.494,0-.506,9.438" transform="matrix(0.966, 0.259, -0.259, 0.966, 92.075, -13.22)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.494,0-.506,9.438" transform="translate(83.047 -16.009) rotate(20)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.491,0-.509,9.45" transform="translate(74.078 -19.341) rotate(25)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.491,0-.509,9.45" transform="translate(65.653 -23.675) rotate(30)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.491,0-.509,9.449" transform="matrix(0.819, 0.574, -0.574, 0.819, 57.871, -28.908)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.491,0-.509,9.449" transform="translate(50.341 -34.618) rotate(40)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.491,0-.509,9.456" transform="translate(43.34 -40.94) rotate(45)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.491,0-.509,9.456" transform="translate(36.913 -47.871) rotate(50)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.491,0-.509,9.47" transform="matrix(0.574, 0.819, -0.819, 0.574, 30.887, -55.096)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.491,0-.509,9.47" transform="translate(25.742 -63.058) rotate(60)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.494,0-.506,9.467" transform="translate(21.61 -71.56) rotate(65)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.494,0-.506,9.467" transform="translate(17.935 -80.268) rotate(70)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.47" transform="matrix(0.259, 0.966, -0.966, 0.259, 15.071, -89.218)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.47" transform="matrix(0.174, 0.985, -0.985, 0.174, 12.959, -98.429)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0V9.48" transform="matrix(0.087, 0.996, -0.996, 0.087, 11.452, -107.571)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0V9.48" transform="translate(10.954 -117.028) rotate(90)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.47" transform="matrix(-0.087, 0.996, -0.996, -0.087, 11.629, -126.565)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.5,0-.5,9.47" transform="matrix(-0.174, 0.985, -0.985, -0.174, 12.786, -135.934)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
              <path class="scale1_dash" d="M-.506,0l.012,9.467" transform="matrix(-0.259, 0.966, -0.966, -0.259, 14.831, -145.121)" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="3"/>
            </g>
          </svg>
          </div></div>
        <app-arc-slider id='ther_inner' [style.height]="getHeightForActivePart()" [minValue]="getMinValue()" [maxValue]="getMaxValue()" [value]="thermostatObject.temperatures[thermostatObject.activeSensor - 1]" (valueChanged)='setTemperature($event)'></app-arc-slider>
        <div class="ther_temp">{{getTargetTemperatureBase()}}<span id='ther_decimals'>.{{getTargetTemperatureDecimals()}}</span><span id='ther_units'>{{senS.getSensorIconData(sensorObject.type).unit}}</span></div>
        <div id='ther_minus_button'><div class="ther_button" [inlineSVG]="'assets/images/minus.svg'" (mousedown)='platform.isDesktop() ? minusDown() : true' (mouseup)='platform.isDesktop() ? minusUp() : true' (mouseleave)='minusUp()' (touchstart)='minusDown()' (touchend)='minusUp()'></div></div>
        <div id='ther_plus_button'><div class="ther_button" [inlineSVG]="'assets/images/plus.svg'" (mousedown)='platform.isDesktop() ? plusDown() : true' (mouseup)='platform.isDesktop() ? plusUp() : true' (mouseleave)='plusUp()' (touchstart)='plusDown()' (touchend)='plusUp()'></div></div>
    </div>
    <div id='ther_sensor_header' class="section_header">{{trans('sensors.labels.activeSensor')}}</div>
    <div id='ther_sensor_back' class="group-background"></div>
    <div id='ther_sensor'>
        <div class='thers_icon' [inlineSVG]="senS.getSensorIcon(sensorObject.type)"></div>
        <div class='thers_number'>{{sensorObject.queue_no}}</div>
        <div class='thers_text'>{{sensorObject.name}}</div>
        <div class='thers_pipe'></div>
        <div class='thers_value_container'>
            <div class="thers_type_name">{{senS.getSensorIconData(sensorObject.type).name}}</div>
            <div class="thers_value">{{sensorObject.temperature === '' ? '--' : sensorObject.temperature}} {{senS.getSensorIconData(sensorObject.type).unit}}</div>
        </div>
    </div>
</div>