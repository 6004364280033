<div class="cpsli_container {{showBorder ? 'cpsli_border' : ''}}">
    <div class="cpsli_icon" *ngIf="systemItem.theme.fullBackground !== ''" [ngStyle]="{ background: systemItem.theme.fullBackground }"></div>
    <div class="cpsli_icon" *ngIf="systemItem.theme.fullBackground === ''" [ngStyle]="{ background: 'linear-gradient(180deg, ' + systemItem.theme.startColor + ' 0%, ' + systemItem.theme.endColor + ' 100%)' }"></div>
    <div class="cpsli_bookmark {{isBookmarked ? 'cpsli_bookmark_active' : ''}}" *ngIf="showBookmark" (click)="toggleBookmark()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.83 12.251"><path d="M8.915,3l1.828,3.7,4.087.6-2.958,2.881.7,4.07L8.915,12.328,5.26,14.251l.7-4.07L3,7.3l4.087-.6Z" transform="translate(-2.5 -2.5)" stroke="#c1c1c1" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/></svg></div>
    <div class="cpsli_group_icon" *ngIf="systemItem.hasOwnProperty('activeGroupIcon')"><div [inlineSVG]="systemItem['activeGroupIcon']"></div></div>
    <div class="cpsli_system_name" (click)="gotoSystem()">{{systemItem.name}}</div>
    <div class="cpsli_status" *ngIf="systemItem.online">{{lang.get('systems.statuses.online')}}<div class="cpsli_status_con"></div></div>
    <div class="cpsli_status" *ngIf="!systemItem.online">{{lang.get('systems.statuses.offline')}}<div class="cpsli_status_coff"></div></div>
    <div class="cpsli_installer" *ngIf="showInstaller">{{lang.get('systems.labels.installerDDot')}} {{installerNameText}}</div>
    <div class="cpsli_address" *ngIf="showAddress">{{lang.get('systems.labels.address')}}: {{systemItem.address}}</div>
    <div class="cpsli_delete" *ngIf="canDelete" (click)="deleteSystem()"><svg viewBox="0 0 13.426 13.423"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#666"/></svg></div>
    <div class="cpsli_arrow" *ngIf='showArrow' (click)="gotoSystem()"><svg viewBox="0 0 8.402 14.697"><path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/></svg></div>
</div>