import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionRole } from 'src/api/v3/common';
import { AppSettings } from 'src/app-settings';
import { DateService } from 'src/app/services/date.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent extends LanguageAware implements OnInit, AfterViewInit {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly PermissionRole = PermissionRole;
  public oldVersion = null;
  public newerVersion = null;
  public lastTimeChecked = null;

  constructor(cdRef: ChangeDetectorRef, public router: Router, public dt: DateService) {
    super(cdRef);
    this.tag = 'Help';
    this.background.setGray();
    if(this.desktopView) {
      if(this.us.currentUser.permissions?.role === PermissionRole.GeneralUser) {
        this.headerBar.showHeader({
          showDotMenu: true,
        });
      }
    } else {
      this.headerBar.showHeader({
        backUrl: '*',
        headerText: this.trans('general.help'),
      });
    }
    console.log('current ver', AppSettings.appVersionMajor, AppSettings.appVersionMinor, AppSettings.appVersionBuild, AppSettings.appVersionDate);
    const savedOldVersion = localStorage.getItem('last_version');
    console.log('old version', savedOldVersion);
    const savedOldBuild = localStorage.getItem('last_version_build');
    console.log('olg build', savedOldBuild);
    if (savedOldBuild !== null && parseInt(savedOldBuild, 10) !== AppSettings.appVersionBuild) {
      this.oldVersion = JSON.parse(savedOldVersion);
    }
    localStorage.setItem('last_version_build', AppSettings.appVersionBuild.toString());
    localStorage.setItem(
      'last_version',
      JSON.stringify({
        major: AppSettings.appVersionMajor,
        minor: AppSettings.appVersionMinor,
        build: AppSettings.appVersionBuild,
        date: AppSettings.appVersionDate,
      })
    );
    this.lastTimeChecked = localStorage.getItem('version_check_date');
    console.log('ver from server', this.us.appVersionDetails);
    if (this.us.appVersionDetails !== null && AppSettings.appVersionBuild < this.us.appVersionDetails.build) {
      this.newerVersion = this.us.appVersionDetails;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if(this.headerBar.isDesktop) {
      setTimeout(() => {
        const header = document.querySelector('.header-dark');
        if(header) {
          header.classList.replace('header-dark', 'header-bright');
        }
      });
    }
  }

  public getAddress(): string {
    return AppSettings.address;
  }

  public getEmail(): string {
    return AppSettings.email;
  }

  public getPhone(): string {
    return AppSettings.phone;
  }

  public getUrl(): string {
    return AppSettings.companyUrl;
  }

  public getTitle(): string {
    return AppSettings.title;
  }

  public getCurrentVersion(): string {
    return AppSettings.appVersionMajor + '.' + AppSettings.appVersionMinor;
  }

  public getBuild(): string {
    return AppSettings.appVersionBuild.toString();
  }

  public getCopyright(): string {
    return AppSettings.appCopyright;
  }

  public getDate(): number {
    return AppSettings.appVersionDate;
  }

  public getLastTimeCheckedDate(): string {
    const dateString = this.lastTimeChecked?.slice(0, 10);
    const timeString = this.lastTimeChecked?.slice(11, 16);

    return dateString && timeString ? dateString + ' ' + timeString : '';
  }

  public clickUpdate() {
    location.reload();
  }

  public clickEmail() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('mailto:' + AppSettings.email);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('mailto:' + AppSettings.email);
    } else {
      window.open('mailto:' + AppSettings.email, '_blank');
    }
  }

  public clickPhone() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('tel:' + AppSettings.phone);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('tel:' + AppSettings.phone);
    }
  }

  public clickUrl() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('http://' + AppSettings.companyUrl);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('http://' + AppSettings.companyUrl);
    } else {
      window.open('http://' + AppSettings.companyUrl, '_blank');
    }
  }

  public clickTicket() {
    this.router.navigate([...this.g.resolveRootRoute(), 'help', 'ticket']);
  }

  public shouldShowLoggerbutton(): boolean {
    return AppSettings.showLogger;
  }

  public logResolution() {
    if (this.l.showLogView) {
      this.l.log('Ekrano rezoliucija: ', '', {
        plotis: screen.availWidth,
        aukstis: screen.availHeight,
      });
      this.l.log('Lango rezoliucija: ', '', {
        plotis: window.innerWidth,
        aukstis: window.innerHeight,
      });
    }
  }
}
