<div
  [ngClass]="[size && size === 'small' ? 'formGroupSm' : 'formGroup', inputType === 'checkbox' ? (ngType === 'switch' ? 'hasSwitch' : 'hasCheckbox') : '', hasError ? 'hasError' : '']"
>
  <label *ngIf="label"
    [ngClass]="[
      'label',
      isEmpty ? 'empty' : '',
      inputType === 'textarea' ? 'labelTextarea' : '',
      inputType === 'checkbox' ? (ngType === 'switch' ? 'labelSwitch' : 'labelCheckbox') : ''
    ]"
    [for]="formId"
    >{{ label }}</label
  >
  <textarea
    *ngIf="inputType === 'textarea'; else input"
    [id]="formId"
    [name]="formId"
    [value]="inputProxy"
    [ngClass]="[size && size === 'small' ? 'fontSizeSm' : 'fontSize', 'textarea']"
    [disabled]="disabled"
    (input)="inputProxy = $event.target.value"
    (focus)="Focus.emit($event)"
    (blur)="Blur.emit($event)"
    [rows]="rows"
  ></textarea>
  <ng-template #input>
    <input
      #inputElement
      [id]="formId"
      [name]="formId"
      [(ngModel)]="inputProxy"
      [ngClass]="[size && size === 'small' ? 'fontSizeSm' : 'fontSize', 'input', inputType === 'checkbox' ? (ngType === 'switch' ? 'switch' : 'checkbox') : '', inputType === 'email' ? 'lowerCase' : '']"
      [type]="inputType"
      [max]="max"
      [min]="min"
      [step]="step"
      [required]="required"
      [disabled]="disabled"
      [checked]="inputProxy"
      [autocomplete]="autocomplete"
      (change)="onChange($event)"
      (focus)="Focus.emit($event)"
      (blur)="Blur.emit($event)"
      [maxlength]="maxLength"
    />
  </ng-template>

  <span class="showPassEye {{showPassword ? 'showPassEye_visible' : ''}}" *ngIf="ngType === 'password' && !platform.isEdgeBrowser(87)" (click)='showPassword = !showPassword'></span>
  <ng-content></ng-content>
</div>
