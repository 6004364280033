import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TCamera } from 'src/app/models/camera';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-camera-list',
  templateUrl: './camera-list.component.html',
  styleUrls: ['./camera-list.component.scss'],
})
export class CameraListComponent extends LanguageAware implements OnInit {
  public readonly cameraCount = this.systems.activeSystem.cameras.length;
  public readonly cameraLimit = this.us.currentUser.limits.camera_count;
  public readonly cameras = this.systems.activeSystem.cameras;
  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'cameraList';
    const refType = localStorage.getItem('ref');
    const backUrl = refType === 'conf' ? '../system-configuration' : '*';
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.myCameras'),
      backUrl,
      activatedRoute: this.r,
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  public selectCamera(camera: TCamera) {
    this.es.beginComponentEdit(TEditableComponent.Camera, camera.id, camera);
    this.router.navigate(['../edit-camera'], { relativeTo: this.r });
  }

  public addCamera() {
    if ( !this.systems.currentUserPermissions?.permissions.sys_cameras.create ) {
      this.toaster.postError(this.trans('systems.errors.operationNotAllowed'));
      return;
    }
    this.es.beginComponentEdit(TEditableComponent.Camera, 0);
    this.router.navigate(['../add-camera'], { relativeTo: this.r });
  }
}
