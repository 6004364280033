import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PlatformService } from 'src/app/api/platform.service';

@Component({
  selector: 'app-arc-slider',
  templateUrl: './arc-slider.component.html',
  styleUrls: ['./arc-slider.component.scss'],
})
export class ArcSliderComponent implements OnInit, AfterViewInit {
  @Input() minValue = -55;
  @Input() maxValue = 125;
  @Input() value = this.minValue + 50;
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  private isCurrentlyDragging = false;
  private startingPosition = null;
  private containerCenter = null;
  private startingValue = 0;
  private startingDegree = 345;
  private endingDegree = 555;
  private lastDegreeValue = 0;
  public circleRadius = 0;
  public circleCircum = 0;
  private totalValuesPerCircle = 0;
  public readyToShowActivePart = false;

  constructor(private sanitizer: DomSanitizer, public ps: PlatformService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      const activer: HTMLElement = document.querySelector('.arcs_active_ring');
      this.circleRadius = activer.offsetWidth / 2 - 5;
      this.circleCircum = this.circleRadius * 2 * Math.PI;
      const total = this.maxValue - this.minValue;
      const diff = this.endingDegree - this.startingDegree;
      const valuesPerDegree = total / diff;
      this.totalValuesPerCircle = 360 * valuesPerDegree;
      this.readyToShowActivePart = true;
    }, 20);
  }

  public percentToDeg(): number {
    const diff = this.endingDegree - this.startingDegree;
    const percent = (this.value - this.minValue) / (this.maxValue - this.minValue);
    const val = this.startingDegree + Math.floor(diff * percent);
    return val;
  }

  public startDragging(x: number, y: number) {
    this.isCurrentlyDragging = true;
    const container: HTMLElement = document.querySelector('.arcs_container');
    const boundingBox = container.getBoundingClientRect();
    this.containerCenter = {
      x: boundingBox.left + container.offsetWidth * 0.5,
      y: boundingBox.top + container.offsetWidth * 0.5,
    };
    this.startingPosition = { x, y };
    this.startingValue = this.value;
    this.lastDegreeValue = 0;
  }

  public endDragging() {
    if (!this.isCurrentlyDragging) {
      return;
    }
    this.isCurrentlyDragging = false;
  }

  public stillDragging(x: number, y: number) {
    if (!this.isCurrentlyDragging) {
      return;
    }
    const curentPoint = { x, y };
    const val =
      Math.atan2(curentPoint.y - this.containerCenter.y, curentPoint.x - this.containerCenter.x) -
      Math.atan2(this.startingPosition.y - this.containerCenter.y, this.startingPosition.x - this.containerCenter.x);
    let degrees = Math.round((val * 180) / Math.PI);
    const beforedeg = degrees;
    if (this.lastDegreeValue < 0 && degrees > 0 && Math.abs(degrees - this.lastDegreeValue) > 50) {
      degrees = degrees - 360;
    } else if (this.lastDegreeValue > 0 && degrees < 0 && Math.abs(degrees - this.lastDegreeValue) > 50) {
      degrees = 360 + degrees;
    }
    this.lastDegreeValue = degrees;
    const degreeRange = this.endingDegree - this.startingDegree;
    const valueRange = this.maxValue - this.minValue;
    const valuesPerDegree = valueRange / degreeRange;
    this.value = this.startingValue + degrees * valuesPerDegree;
    if (this.value < this.minValue) {
      this.value = this.minValue;
    } else if (this.value > this.maxValue) {
      this.value = this.maxValue;
    }
    this.valueChanged.emit(this.value);
  }

  public getCurrentActiveProgress() {
    const progressSoFar = this.value - this.minValue;
    const percentFromTotalCircle = progressSoFar / this.totalValuesPerCircle;

    return this.circleCircum - this.circleCircum * percentFromTotalCircle;
  }
}
