import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-company-header-settings',
  templateUrl: './company-header-settings.component.html',
  styleUrls: ['./company-header-settings.component.scss']
})
export class CompanyHeaderSettingsComponent implements OnInit {
  @Output() showSettingsChange = new EventEmitter<boolean>();

  constructor(private elementRef: ElementRef, public login: LoginService, public lang: LanguageService) { }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside && target.id !== 'toggleSettings') {
      this.showSettingsChange.emit(false);
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showSettingsChange.emit(false);
    }
  }

  ngOnInit(): void {
  }

}
