import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild }
from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { WithPrefix } from 'src/rxjs-tools';
import { startCase } from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { LanguageAware } from 'src/app/general/language-aware';

type DataTableValue = object | Record<string, unknown>;
export type DataTableRowInsert<TContext extends DataTableValue | unknown> = { row: number; ctx: TContext };
type DataTableColumns<TValue extends DataTableValue> = Extract<keyof TValue, string> | WithPrefix<string, 'fake:'> | '%row';
export type DataTableLoader<TValue extends DataTableValue> = (start: number, columns: DataTableColumns<TValue>[]) => Promise<TValue[]> | Observable<TValue[]>;
export type DataTableGetter<TValue extends DataTableValue> = (current: number, columns: DataTableColumns<TValue>[], more: boolean) => Promise<TValue[]> | Observable<TValue[]>;
export type DataTableRowInsertComputer<TValue extends DataTableValue, TContext> = (currentData: TValue[]) => DataTableRowInsert<TContext>[];

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent<TData extends DataTableValue> extends LanguageAware implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private _data?: TData[] | DataTableGetter<TData>;
  public get data(): TData[] | DataTableGetter<TData> | undefined {
    return this._data;
  }
  @Input()
  public set data(value: TData[] | DataTableGetter<TData> | undefined) {
    this._data = value;
    if (typeof value === 'function') {
      this.loadData(true, true);
    }
  }
  @Input() public dataLoader?: DataTableLoader<TData>;
  @Input() public activeItemId?: number;
  @Input() public dataInvalidator?: Observable<void>;
  @Input() public columns?: DataTableColumns<TData>[];
  @Input() public slots?: Record<DataTableColumns<TData> | WithPrefix<DataTableColumns<TData>, 'header:' | 'header' | ''>, TemplateRef<unknown> | undefined>;
  @Input() public rowInsertComputer?: DataTableRowInsertComputer<TData, unknown>;
  @Input() public dataSize: number;
  @Input() public showHeaders = true;

  @ViewChild('loader') loader: ElementRef<HTMLDivElement>;

  @Output() public modifyColumns = new EventEmitter<void>();
  @Output() public rowClick = new EventEmitter<{ row: number; column: DataTableColumns<TData>; item: TData }>();
  @Output() public rowContextClick = new EventEmitter<{ row: number; column: DataTableColumns<TData>; item: TData; event: MouseEvent }>();

  public startCase = startCase;
  public isLoading = false;
  private loadedData: TData[] = [];
  public rowInserts = new Map<number, DataTableRowInsert<unknown>>();
  private dataInvalidatorSubscribtion?: Subscription;

  private visabilityObserver = new IntersectionObserver(
    (entires) => {
      if (entires[0].isIntersecting) {
        this.isLoading = entires[0].isIntersecting;
        this.loadData();
      }
    },
    {
      threshold: 0.1,
    }
  );
  public get shownData(): TData[] {
    if (this.data !== undefined && typeof this.data === 'function') { return this.loadedData; }
    if (this.data !== undefined && Array.isArray(this.data)) { return this.data as TData[]; }
    if (this.dataLoader === undefined) { throw new Error('No data source provided'); }
    if (this.dataLoader !== undefined && this.columns === undefined) { throw new Error('No columns provided, when using dataLoader'); }
    return this.loadedData;
  }

  public get usedColumns(): DataTableColumns<TData>[] {
    return this.columns?.filter(c => c !== null) ?? [];
  }
  public get dataTableStyle(): Record<string, string | undefined | null> {
    return {
      '--data-table-rows': `${this.shownData.length + this.rowInserts.size}`,
      '--data-table-columns': `${this.usedColumns.length}`,
    };
  }
  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef, true);
    if (this.dataInvalidator) {
      this.dataInvalidatorSubscribtion = this.dataInvalidator.pipe(debounceTime(50)).subscribe(() => {
        this.loadData(true, true);
      });
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dataSize) {
      setTimeout(() => this.loadData(true, true), 50);
    }
    if (changes.dataInvalidator) {
      this.dataInvalidatorSubscribtion?.unsubscribe();
      this.dataInvalidatorSubscribtion = this.dataInvalidator?.pipe(debounceTime(50)).subscribe(() => {
        this.loadData(true, true);
      });
    }
  }

  public getRowInsert(row: number): DataTableRowInsert<unknown> | undefined {
    if (!this.rowInsertComputer) { return; }
    if (this.rowInserts.has(row)) { return this.rowInserts.get(row); }
    return undefined;
  }

  ngOnInit(): void {
    this.loadData(true, true);
  }

  ngAfterViewInit(): void {
    if(this.loader) {
      this.visabilityObserver.observe(this.loader.nativeElement);
    }
  }

  ngOnDestroy(): void {
    this.visabilityObserver.disconnect();
    this.dataInvalidatorSubscribtion?.unsubscribe();
  }

  private async loadData(reload: boolean = false, ignoreLoading: boolean = false): Promise<void> {
    if (!ignoreLoading && !this.isLoading) { return; }
    if (!this.dataLoader && typeof this.data !== 'function') { return; }
    if (reload) {
      this.loadedData = [];
    }
    if (typeof this.data === 'function') {
      const result = this.data(this.loadedData.length, this.usedColumns, !reload);
      if (result instanceof Promise) {
        this.loadedData = await result;
      } else {
        this.loadedData = await result.toPromise();
      }
    } else {
      const res = this.dataLoader(this.loadedData.length, this.usedColumns);
      if (res instanceof Promise) {
        this.loadedData.push(...(await res));
      } else {
        this.loadedData.push(...(await res.toPromise()));
      }
    }
    if (this.rowInsertComputer) {
      this.rowInserts.clear();
      this.rowInsertComputer(this.loadedData).forEach((insert) => {
        this.rowInserts.set(insert.row, insert);
      });
    }
    this.isLoading = false;
  }

  public getSlot(column: string, name?: string): TemplateRef<unknown> | undefined {
    const tmp = this.slots?.[name ? name + ':' + column : column] ?? this.slots?.[name ?? ''];
    if (tmp) { return tmp; }
    return undefined;
  }
}
