<ng-template #headerName let-class="className">
  <div [ngClass]="class">{{trans('systems.labels.nameSystem')}}</div>
</ng-template>
<ng-template #headerHwType let-class="className">
  <div [ngClass]="class">{{trans('systems.labels.device')}}</div>
</ng-template>
<ng-template #headerImei let-class="className">
  <div [ngClass]="class">{{trans('systems.labels.imei')}}</div>
</ng-template>
<ng-template #headerInstallerId let-class="className">
  <div [ngClass]="class">{{trans('systems.labels.installerDDot').replace(':','')}}</div>
</ng-template>
<ng-template #headerOwners let-class="className">
  <div [ngClass]="class">{{trans('systems.labels.systemMasters')}}</div>
</ng-template>
<ng-template #headerOnline let-class="className">
  <div [ngClass]="class">{{trans('systems.labels.status')}}</div>
</ng-template>
<ng-template #headerAddress let-class="className">
  <div [ngClass]="class">{{trans('systems.labels.address')}}</div>
</ng-template>
<ng-template #headerTags let-class="className">
  <div [ngClass]="class">{{trans('systems.labels.systemTags')}}</div>
</ng-template>
<ng-template #headerCompanyName let-class="className">
  <div [ngClass]="class">{{trans('companies.labels.companyName')}}</div>
</ng-template>

<ng-template #contentIcon let-item="item">
  <div class="sdt-icon"
    [ngStyle]="{ background: item.theme.fullBackground === '' ? 'linear-gradient(180deg, ' + item.theme.startColor + ' 0%, ' + item.theme.endColor + ' 100%)' : item.theme.fullBackground }"
  >
    <div
      *ngIf="item.areas.length === 1 && systems.systemHelper(item).supports.areas()"
      [ngClass]="{ 'sdt-subicon': true, 'sdt-subicon-disarmed': isSystemDisarmed(item), 'sdt-subicon-alarmed': isSystemAlarmed(item)}"
    >
      <div [ngClass]="{ 'svg': true }" [inlineSVG]="getStatusIconForSystem(item)"></div>
    </div>
  </div>
</ng-template>
<ng-template #contentInstallerId let-item="item" let-value="value">
  {{ item.installerEmail ? item.installerEmail : trans('systems.labels.none') }}
</ng-template>
<ng-template #contentOwners let-class="className" let-value="value">
  <div [ngClass]="class">{{ value && value[0] ? value[0].email : '' }}</div>
</ng-template>
<ng-template #contentOnline let-value="value" let-item="item">
  <div [ngClass]="['status', value ? 'online' : 'offline']">{{ value ? trans('systems.statuses.online') : trans('systems.statuses.offline') }}</div>
</ng-template>
<ng-template #tagsContent let-value="item.tags">
  <app-inline-tag-list [tags]="value"></app-inline-tag-list>
</ng-template>
<ng-template #contentCompanyName let-value="value">{{ value ? value : this.trans('settings.ipcom.companyNotAssigned') }}</ng-template>
<ng-template #deleteIcon>
  <div class='deleteIcon' *ngIf="canDelete" [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>

<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.systems.view"
  class="systemsDatatable"
  [data]="loader"
  [slots]="{
    'header:name': headerName,
    'header:hwType': headerHwType,
    'header:imei': headerImei,
    'header:installer_id': headerInstallerId,
    'header:owners': headerOwners,
    'header:online': headerOnline,
    'header:address': headerAddress,
    'header:tags': headerTags,
    'header:companyName': headerCompanyName,
    online: contentOnline,
    owners: contentOwners,
    installer_id: contentInstallerId,
    'fake:icon': contentIcon,
    'fake:delete': deleteIcon,
    tags: tagsContent,
    companyName: contentCompanyName
  }"
  [columns]="columns"
  (modifyColumns)="modifyColumns.emit()"
  (rowClick)="onRowClick($event)"
  (rowContextClick)="onRightClick($event)"
  [dataSize]="system.systems.size"
  [activeItemId]="activeSystemId"
>
</app-data-table>

<app-context-menu-data-table *ngIf="contextMenu.isVisible"
  (contextVisibilityChange)="contextMenu.isVisible = $event"
  [headerText]="contextMenu.item.name"
>
  <app-groupbox *ngIf="contextMenu.item.tags.length > 0" [headerText]="trans('systems.buttons.filterTag')" [defaultStateCollapsed]="true" [iconStyle]="'solid'">
    <ng-container *ngFor="let tag of contextMenu.item.tags">
      <a class="tag" (click)="onContextFilter(tag.name, 'tags')">{{tag.name}}</a>
    </ng-container>
  </app-groupbox>
  <a class="context-text" (click)="onContextFilter(contextMenu.item.hwType, 'hwType')">
    {{trans('systems.buttons.filterDevice')}}:
    <p>{{contextMenu.item.hwType}}</p>
  </a>
  <a *ngIf="contextMenu.item.owners.length !== 0" class="context-text" (click)="onContextFilter(contextMenu.item.owners[0]?.email, 'owner')">
    {{trans('systems.buttons.filterOwner')}}:
    <p>{{contextMenu.item.owners[0]?.email}}</p>
  </a>
  <a *ngIf="contextMenu.item.installerEmail" class="context-text" (click)="onContextFilter(contextMenu.item.installerEmail, 'installer_id')">
    {{trans('systems.buttons.filterInstaller')}}:
    <p>{{contextMenu.item.installerEmail}}</p>
  </a>
  <a (click)="onContextLinkClick('settings/info')">{{trans('systems.menu.systemInformation')}}</a>
  <a (click)="onContextLinkClick('events')">{{trans('systems.menu.events')}}</a>
  <a (click)="onContextLinkClick('users')">{{trans('systems.menu.users')}}</a>
  <a (click)="onContextLinkClick('settings/advanced')">{{trans('systems.menu.advancedSettings')}}</a>
</app-context-menu-data-table>
