import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { Chip } from 'src/api/v3/common';
import { PersistenceService } from 'src/app/api/persistence.service';
import { SystemService } from 'src/app/api/system/system.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TSystemData } from 'src/app/models/system-data';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';
import { DataTableGetter } from '../../components/data-table/data-table.component';
import { FilterCriteria } from '../company-users/company-users.component';

const defaultColumns = ['fake:icon', 'name', 'hwType', 'imei', 'installer_id', 'owners', 'companyName', 'online', 'address', 'tags', 'fake:delete'];
@Component({
  templateUrl: './company-systems.component.html',
  styleUrls: ['./company-systems.component.scss'],
})
export class CompanySystemsComponent extends LanguageAware implements OnInit {
  public readonly canAdd = this.us.currentUser.permissions.permissions.systems.create && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
  loader: DataTableGetter<TSystemData>;
  systemsFilterCriteria: FilterCriteria;
  searchVisible: boolean;
  isSearchLoading: boolean;
  resultsFiltered: boolean;
  formSubmitted: boolean;
  isManuallySubmitted: boolean;
  typingTimer: NodeJS.Timeout;
  doneTypingInterval: number;
  filterChips: Chip[];

  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('systems.titles.icon'), value: 'fake:icon' },
    { name: this.trans('systems.labels.systemName'), value: 'name' },
    { name: this.trans('systems.labels.device'), value: 'hwType' },
    { name: this.trans('systems.labels.imei'), value: 'imei' },
    { name: this.trans('systems.labels.installer'), value: 'installer_id' },
    { name: this.trans('systems.labels.systemMasters'), value: 'owners' },
    { name: this.trans('systems.labels.status'), value: 'online' },
    { name: this.trans('systems.labels.address'), value: 'address' },
    { name: this.trans('systems.labels.systemTags'), value: 'tags' },
    { name: this.trans('companies.labels.companyName'), value: 'companyName' },
    { name: this.trans('systems.buttons.delete'), value: 'fake:delete' },
  ];
  public readonly columns = this.persistance.bind('system_columns', defaultColumns);

  constructor(cdRef: ChangeDetectorRef, private persistance: PersistenceService, private system: SystemService) {
    super(cdRef);
    this.loader = this.system.getSystemsGetter(this.resultsFiltered);
    this.searchVisible = false;
    this.isSearchLoading = false;
    this.resultsFiltered = false;
    this.formSubmitted = false;
    this.doneTypingInterval = 500;
    this.filterChips = [
      { name: 'name', label: this.trans('systems.labels.systemName'), value: false, modifiableColumn: true },
      { name: 'hwType', label: this.trans('systems.labels.device'), value: false, modifiableColumn: true },
      { name: 'imei', label: this.trans('systems.labels.imei'), value: false, modifiableColumn: true },
      { name: 'sys_user', label: this.trans('systems.titles.systemUser'), value: false, modifiableColumn: false },
      { name: 'installer_id', label: this.trans('systems.labels.installer'), value: false, modifiableColumn: true },
      { name: 'owners', label: this.trans('systems.labels.systemMasters'), value: false, modifiableColumn: true },
      { name: 'address', label: this.trans('systems.labels.address'), value: false, modifiableColumn: true },
      { name: 'tags', label: this.trans('systems.labels.systemTags'), value: false, modifiableColumn: true },
    ];
    this.resetSystemsFilterCriteria();
  }

  ngOnInit(): void {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.hideSearch();
  }

  public onChipPress(event: any, chip: Chip): void {
    if(this.systemsFilterCriteria.searchFields.includes(chip.name) && !chip.value) {
      const index = this.systemsFilterCriteria.searchFields.indexOf(chip.name);
      this.systemsFilterCriteria.searchFields.splice(index, 1);
    } else if (!this.systemsFilterCriteria.searchFields.includes(chip.name) && chip.value) {
      this.systemsFilterCriteria.searchFields.push(chip.name);
    }
    this.onSearchSubmit(event);
  };

  public onSearchSubmit(event: any): void {
    clearTimeout(this.typingTimer);
    if(event.key === 'Enter' || event.type === 'click') {
      this.isManuallySubmitted = true;
      this.initializeSearch();
    } else {
      this.isManuallySubmitted = false;
      this.typingTimer = setTimeout(() => {
        this.initializeSearch();
      }, this.doneTypingInterval);
    }
  }

  public onContextSearch(eventData) {
    this.resetPagesCount();
    const chip = this.filterChips.find(filterChip => filterChip.name === eventData.searchField);
    if (chip) { chip.value = true; }
    this.showSearch();
    this.systemsFilterCriteria.searchPhrase = eventData.searchPhrase;
    this.systemsFilterCriteria.searchFields = [eventData.searchField];
    this.isManuallySubmitted = true;
    this.initializeSearch();
  }

  private async initializeSearch(): Promise<void> {
    this.resetPagesCount();
    if(this.systemsFilterCriteria.searchPhrase.length >= (this.isManuallySubmitted ? 2 : 3)) {
      this.isSearchLoading = true;
      await this.setFilteredSystems().then(() => {
        this.resultsFiltered = true;
        this.isSearchLoading = false;
      });
    } else if (this.resultsFiltered && this.systemsFilterCriteria.searchPhrase.length === 0) {
      this.loader = this.system.getSystemsGetter(this.resultsFiltered);
      this.resultsFiltered = false;
    }
  }

  private setFilteredSystems(): Promise<void> {
    this.filterActiveSearchFields();
    return new Promise((resolve) => {
      this.loader = this.system.getSystemsGetter(this.resultsFiltered, this.systemsFilterCriteria);
      resolve();
    });
  }

  private filterActiveSearchFields(): void {
    this.systemsFilterCriteria.searchFields.map(searchFieldName => {
      if (!this.columns.value.includes(searchFieldName) && searchFieldName !== 'owners' && searchFieldName !== 'address' && searchFieldName !== 'sys_user') {
        const index = this.systemsFilterCriteria.searchFields.indexOf(searchFieldName);
        this.systemsFilterCriteria.searchFields.splice(index, 1);
        const inactiveChip = this.filterChips.find(chip => chip.name === searchFieldName);
        inactiveChip.value = false;
      }
    });
  }

  public showSearch(): void {
    this.searchVisible = true;
  }

  public hideSearch(): void {
    if(!this.searchVisible) { return; }
    this.searchVisible = false;
    this.isSearchLoading = false;
    this.resetSystemsFilterCriteria();
    if(this.resultsFiltered === true) {
      this.loader = this.system.getSystemsGetter(this.resultsFiltered);
      this.resultsFiltered = false;
    }
    const searchInputField = document.getElementById('searchInputField');
    searchInputField.blur();
  }

  private resetSystemsFilterCriteria(): void {
    this.systemsFilterCriteria = {
      searchPhrase: '',
      searchFields: [],
      currentPage: 0,
      lastPage: 0
    };
    this.filterChips.map(chip => chip.value = false);
  }

  private resetPagesCount(): void {
    this.systemsFilterCriteria.currentPage = 0;
    this.systemsFilterCriteria.lastPage = 0;
  }
}
