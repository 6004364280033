<app-company-page-layout gridTemplateColumns="3fr 2fr">
  <div class="header">
    <h4 class="title">{{trans('systems.menu.eventDescriptions')}}</h4>
    <div class="flex-1">
      <div class="search-input-wrapper">
        <input id="searchInputField" autocomplete="off" type="text" name="searchPhrase" [(ngModel)]="searchPhrase" [placeholder]="trans('general.search')" class="search-input" (focus)="showSearch()"/>
        <div class="svg-wrapper">
          <div *ngIf="searchVisible && searchPhrase && searchPhrase !== ''" class="close" (click)="hideSearch()"></div>
        </div>
      </div>
    </div>
    <app-icon-button [routerLink]="['new']" [label]="trans('events.settings.titleAdd')" icon="assets/images/company/plus.svg"></app-icon-button>
    <app-company-columns [items]="columnConfig" [(value)]="columns.value" #cols></app-company-columns>
  </div>
  <app-event-descriptions-data-table [columns]="columns.value" (modifyColumns)="cols.show()" [searchPhrase]="searchPhrase"></app-event-descriptions-data-table>
</app-company-page-layout>
