import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { IPopup } from '../IPopup';

@Component({
  selector: 'app-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss'],
})
export class StatusMessageComponent implements OnInit, AfterViewInit, IPopup {
  @ViewChild('basePopup') basePopup;
  @ViewChild('loaderComponent') loaderComponent;
  public isSuccess = true;
  public headerText = '';
  public statusText = '';
  public popupName = 'StatusMsg';
  public loaderText = '';
  public closed: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit() {
    if ( this.loaderText !== '' ) {
      this.loaderComponent.displayText = this.loaderText;
      this.loaderComponent.isVisible = true;
    } else {
      this.loaderComponent.isVisible = false;
    }
  }

  public showLoader(textToShow: string) {
    this.loaderText = textToShow;
    if ( this.loaderComponent ) { this.loaderComponent.isVisible = true; }
  }

  public showSuccess(headerText: string, statusText: string) {
    this.headerText = headerText;
    this.statusText = statusText;
    this.loaderText = '';
    this.isSuccess = true;
    if ( this.loaderComponent ) { this.loaderComponent.isVisible = false; }
  }

  public showFailure(headerText: string, statusText: string) {
    this.headerText = headerText;
    this.statusText = statusText;
    this.loaderText = '';
    this.isSuccess = false;
    if ( this.loaderComponent ) { this.loaderComponent.isVisible = false; }
  }

  public close() {
    this.basePopup.hidePopup();
  }
}
