import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DateTime, DateTimeFormatOptions } from 'luxon';
import { EventFilterCriteria } from 'src/app/api/system/event.service';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-events-datetime-picker',
  templateUrl: './events-datetime-picker.component.html',
  styleUrls: ['./events-datetime-picker.component.scss']
})
export class EventsDatetimePickerComponent extends LanguageAware implements OnInit {
  public dateRangePickerVisible = false;
  public readonly datePickerSectionId: string = 'datePickerSection';

  @Input() startDate: DateTime | null = null;
  @Input() endDate: DateTime | null = null;

  @Output() datesChanged = new EventEmitter<EventFilterCriteria>();


  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngOnInit(): void {
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.dateRangePickerVisible = false;
    }
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.checkIfClickedInside(target);
    if (!clickedInside) {
      this.dateRangePickerVisible = false;
    }
  }

  private checkIfClickedInside(element: HTMLElement): boolean {
    if (element.id === this.datePickerSectionId) {
      return true;
    }
    if (element.parentElement) {
      return this.checkIfClickedInside(element.parentElement);
    }
    return false;
  }

  public quickDate(days: number) {
    const thisMidnight = DateTime.local().startOf('day');
    this.startDate = thisMidnight.minus({ days: days - 1 });
    this.endDate = thisMidnight.plus({ days: 1, milliseconds: -1 });
  }

  public get dateRangeText(): string {
    const startDate = this.startDate ? this.startDate.toJSDate() : null;
    const endDate = this.endDate ? this.endDate.toJSDate() : null;
    let formattedDateRange = this.trans('events.filter.buttons.filterDates');
    if (startDate && endDate) {
      const options: DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      const formatter = new Intl.DateTimeFormat('en-US', options);
      if (startDate instanceof Date && !isNaN(startDate.valueOf()) && endDate instanceof Date && !isNaN(endDate.valueOf())) {
        const formattedStartDate = formatter.format(startDate);
        const formattedEndDate = formatter.format(endDate);
        if (startDate.getFullYear() === endDate.getFullYear()) {
          formattedDateRange = `${formattedStartDate.slice(0, -6)} - ${formattedEndDate}`;
        } else {
          formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        }
      }

    }
    return formattedDateRange;
  }

  public get dateRangeCount(): string {
    let range = '';
    if (this.startDate && this.endDate) {
        const start = this.startDate.startOf('day');
        const end = this.endDate.startOf('day');
        const diff = end.diff(start, 'days').toObject();
        const count = Math.abs(diff.days as number) + 1;
        range = (diff.days < 0 ? '-': '') + count.toString();
    }
    return range !== 'NaN' ? range : '';
  }

  public onDateSelectComplete() {
    this.datesChanged.emit({
      startDate: this.startDate ? this.startDate.toISO() : undefined,
      endDate: this.endDate ? this.endDate.toISO() : undefined,
    });
    this.dateRangePickerVisible = false;
  }

  public clearDateSelection() {
    this.startDate = undefined;
    this.endDate = undefined;
  }

}
