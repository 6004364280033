import { ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupOptions } from '../models/popup-options';
import { PopupType } from '../models/popup-type';
import { SlideoutOptions } from '../models/slideout-options';
import { IPopup } from '../popups/IPopup';
import { DropdownItem } from '../ui/dropdown/dropdown.component';

/**
 * @member paramSetter Funkcija, skirta tam, kad būtų galima popup objektui nurodyti parametrų reikšmes ir prisikabinti prie įvykių (subscribe)
 * @member attachToClass Nurodo css klasę elemento, kuriame reikia pridėti popup komponentą.
 */
export type CustomPopupOptions<T> = {
  paramSetter?: (instance: T) => void;
  attachToClass?: string;
};

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private popupOpenSource = new Subject<any>();
  public onShowPopup = this.popupOpenSource.asObservable();
  private popupCloseSource = new Subject<any>();
  public onHidePopup = this.popupCloseSource.asObservable();
  private slideoutOpenSource = new Subject<any>();
  public onShowSlideout = this.slideoutOpenSource.asObservable();
  private slideoutCloseSource = new Subject<any>();
  public onHideSlideout = this.slideoutCloseSource.asObservable();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  /**
   * Parodo bendrinį popup iš 'app-popup' komponento, pagal nurodytus parametrus bei tipą.
   *
   * @param options Popup nustatymai
   * @param popupType Popupo tipas
   */
  public showPopup(options: PopupOptions, popupType: PopupType) {
    this.popupOpenSource.next({ options, popupType });
  }

  public hidePopup() {
    this.popupCloseSource.next();
  }

  public showSlideout<T extends DropdownItem<string|number> | string>(options: SlideoutOptions<T>, popupType: PopupType) {
    this.slideoutOpenSource.next({ options, popupType });
  }

  public hideSlideout() {
    this.slideoutCloseSource.next();
  }

  /**
   * Pirma sukuria, paskui parodo nurodytą popup.
   *
   * @param t Popup komponento clasė
   * @param options Papildomi nustatymai, kurie reikalingi popup parodymui.
   */
  public openPopup<T extends IPopup>(t: Type<T>, options?: CustomPopupOptions<T>) {
    const compRef: ComponentRef<T> = this.componentFactoryResolver.resolveComponentFactory(t).create(this.injector);
    const instance = compRef.instance;
    instance.closed.subscribe(() => {
      console.log('Closed ' + instance.popupName);
      this.appRef.detachView(compRef.hostView);
      compRef.destroy();
    });
    if ( options?.paramSetter ) { options.paramSetter(instance); }
    this.appRef.attachView(compRef.hostView);
    const domElem = (compRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    if ( options?.attachToClass ) {
      document.body.querySelector(options.attachToClass.startsWith('.') ? options.attachToClass : '.' + options.attachToClass)?.appendChild(domElem);
    } else {
      document.body.appendChild(domElem);
    }
  }
}
