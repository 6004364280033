import { Component, OnInit } from '@angular/core';
import { AppSettings, environment } from 'src/app-settings';
import { PlatformService } from '../api/platform.service';
import { LanguageService } from '../services/language.service';
import { ForgotPasswordTarget } from './login.service';

@Component({
  selector: 'app-login-layout-sofa',
  templateUrl: './login-layout-sofa.component.html',
  styleUrls: ['./login-layout-sofa.component.scss'],
})
export class LoginLayoutSofaComponent implements OnInit {
  public readonly canShowGoogle = environment.googleLoginSupported;
  public readonly canShowApple = environment.appleLoginSupported && this.ps.isApple() && this.ps.hasAppleMessageHandlers();
  public readonly title = AppSettings.title;
  public ForgotPasswordTarget = ForgotPasswordTarget;
  public readonly trans = (key: string) => this.ls.get(key);
  public readonly canRegisterCompany = environment.companyRegistrationSupported;
  constructor(private ls: LanguageService, private ps: PlatformService) {}

  ngOnInit(): void {}
}
