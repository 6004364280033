import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
})
export class DropdownSelectComponent implements OnInit {
  public value = '';
  public fieldIsFocused = false;
  @Input() isReadOnly = false;
  @Input() initialValue = '';
  @Input() dataSource = [];
  @Input() header = '';
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor(public ls: LanguageService) {}

  ngOnInit(): void {
    this.value = this.initialValue;
  }

  public newValueSelected() {
    this.valueChanged.emit(this.value);
  }
}
