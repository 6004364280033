import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionRole } from 'src/api/v3/common';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEventData } from 'src/app/models/event-data';
import { SystemFindPopupComponent } from 'src/app/popups/system-find-popup/system-find-popup.component';
import { CompanyEventsService } from 'src/app/services/company-events.service';
import { PopupService } from 'src/app/services/popup.service';
import { SystemsService } from 'src/app/services/systems.service';

@Component({
  selector: 'app-cp-home',
  templateUrl: './cp-home.component.html',
  styleUrls: ['./cp-home.component.scss'],
})
export class CpHomeComponent extends LanguageAware implements OnInit, OnDestroy {
  @ViewChild('fragmentLoader') fragmentLoader;
  public lastSeenGroups = [];
  private userDataSubscriber = null;
  private eventSubscriber = null;
  public that = this;
  public PermissionRole = PermissionRole;
  public currentUserRole = this.us.currentUser.permissions?.role ?? PermissionRole.Installer;

  constructor(cdRef: ChangeDetectorRef, private router: Router, ce: CompanyEventsService, private pp: PopupService, private ss: SystemsService) {
    super(cdRef);
    this.headerBar.showHeader({
      showHamburger: true,
      showLogo: true,
    });
    this.background.setGray();
    if (this.systems.activeSystem !== null) {
      this.systems.setCurrentSystem(null);
    }
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().setBackButtonType('close');
    }
    this.userDataSubscriber = this.us.onUserDataChanged.subscribe(() => {
      this.updateData();
    });
    this.updateData();
    this.eventSubscriber = ce.onEventForward.subscribe((data) => {
      this.handleEvent(data.receivedEvent, data.reactionGroup);
    });
    this.helper.setCountForBottomButton(1, 1, 1, false, true, false, 'cph');
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.userDataSubscriber !== null) {
      this.userDataSubscriber.unsubscribe();
      this.userDataSubscriber = null;
    }
    if (this.eventSubscriber !== null) {
      this.eventSubscriber.unsubscribe();
      this.eventSubscriber = null;
    }
  }

  public gotoAllEvents() {
    this.router.navigate([...this.g.getHomeUrl(), 'all-events']);
  }

  public openFind() {
    this.pp.openPopup(SystemFindPopupComponent, { attachToClass: 'mobile-app' });
  }

  private updateData() {
    if ( this.currentUserRole !== PermissionRole.Company ) { return; }
    if ( !this.us.currentUser.permissions?.permissions.events.view ) { return; }
    this.lastSeenGroups = [];
    let eventCount = 0;
    for (const iReaction of this.us.currentUser.reactions) {
      const found = this.us.currentUser.lastSeenReactions.find((lsr) => lsr.reaction_id === iReaction.id);
      eventCount += found === undefined ? 0 : found.unseen_events;
      this.lastSeenGroups.push({
        name: iReaction.default_name ? this.trans('reactionNames.reactions.' + iReaction.name) : iReaction.name,
        events: eventCount,
        icon: iReaction.image,
        id: iReaction.id,
      });
    }
  }

  private handleEvent(event: TEventData, reactionGroup: any) {
    const foundLastSeenGroup = this.lastSeenGroups.find((lg) => lg.id === reactionGroup.id);
    if (foundLastSeenGroup === undefined) {
      this.lastSeenGroups.push({
        name: reactionGroup.default_name ? this.trans('reactionNames.reaction_groups.' + reactionGroup.name) : reactionGroup.name,
        events: 1,
        icon: reactionGroup.image,
        id: reactionGroup.id,
      });
    } else {
      foundLastSeenGroup.events++;
    }
  }

  public addSystem() {
    this.router.navigate(['new-system', 'add-system']);
  }

  public openSystemHistory() {
    this.router.navigate([...this.g.getHomeUrl(), 'history-systems']);
  }

  public fetchMoreSystems(context: any, callback?: any) {
    context.miniStatus.show(context.trans('general.pleaseWait'));
    context.systems.loadSystems(true, () => {
      context.miniStatus.hide();
      context.fragmentLoader.isEnabled = context.systems.length % context.us.eventPerPage === 0;
      if (callback !== undefined) {
        callback();
      }
    });
  }
}
