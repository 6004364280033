import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnInit {
  public mobileView = false;
  readonly breakpoint = this.brk.observe(['(min-width: 560px)']).pipe( distinctUntilChanged() );
  constructor(private brk: BreakpointObserver) {
    this.breakpoint.subscribe((state) => {
      this.mobileView = !state.matches;
    });
  }

  ngOnInit(): void {
  }
}
