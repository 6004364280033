import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupType } from 'src/app/models/popup-type';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { PopupService } from 'src/app/services/popup.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/environments/environment';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { EventDescriptionService } from 'src/app/services/event-description.service';
import { companyRootRoute } from 'src/app/services/global.service';
type EventDescription = {
  default_name: string;
  qualifier: string;
  event_code: string;
  reaction_id: number;
};

@Component({
  selector: 'app-cp-edit-event',
  templateUrl: './cp-edit-event.component.html',
  styleUrls: ['./cp-edit-event.component.scss'],
})
export class CpEditEventComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public eventDescItem = null;
  private original = null;
  public reactionText = '';
  private backUrl = this.g.getHomeUrl().join('/') + '/edit-events';
  public canDelete = this.us.currentUser.permissions?.permissions.reactions.delete;
  private subscriptions: Subscription[] = [];
  public delayDuration = 2000;
  public canEdit = this.us.currentUser.permissions?.permissions.reactions.edit || this.us.currentUser.permissions?.permissions.reactions.create;
  public desktopEnabled = AppSettings.companyDesktopEnabled;

  public inputChanged = false;
  public isRequestLoading = false;
  public readonly isNew = this.ar.routeConfig.path === 'new';
  public footerButtons = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.saveEventDescription() },
    { label: this.trans('general.cancel'),  callback: () => this.navigateBack() },
  ];
  public newEventDescItem = {
    id: 0,
    default_name: '',
    qualifier: '',
    event_code: '',
    reaction_id: 0,
    active: true,
    area_event: true
  };
  public val = new ValidatorBuilder<EventDescription>()
  .required('default_name', `${this.trans('validation.required').replace(':attribute', this.trans('events.settings.labels.nameEvent'))}`)
  .required('qualifier', `${this.trans('validation.required').replace(':attribute', this.trans('events.settings.labels.qualifier'))}`)
  .regex('qualifier', /^(R|E|-)$/, `${this.trans('validation.regex').replace(':attribute', this.trans('events.settings.labels.qualifier'))}`)
  .required('event_code', `${this.trans('validation.required').replace(':attribute', this.trans('events.settings.labels.code'))}`)
  .required('reaction_id', `${this.trans('validation.required').replace(':attribute', this.trans('events.settings.labels.reaction'))}`)
  .build().bindContext(this);
  private eventDescIdChangeSubscription = this.ar.params.subscribe(async (params) => {
    if ( !this.desktopView ) { return; }
    const eventDescId = Number(params.eventDescId);
    if ( this.inputChanged || this.es.getId(TEditableComponent.EventDescription) !== -1) {
      this.revert();
    }
    if (eventDescId && !isNaN(eventDescId)) {
      const eventDesc = await this.eventDescription.getEventDescription(eventDescId);
      if (!eventDesc) {
        throw new Error('Event description not found');
      }
      this.es.beginComponentEdit(TEditableComponent.EventDescription, eventDesc.id, eventDesc);
    }
    this.reinitVariables();
  });

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private sanitizer: DomSanitizer,
    private ar: ActivatedRoute,
    private pp: PopupService,
    private es: EditSystemService,
    private messagebox: MessageboxService,
    public eventDescription: EventDescriptionService,
  ) {
    super(cdRef);
    if(this.desktopView) {
      this.eventDescription.quickEditAllowed = false;
    }
    this.reinitVariables();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
    this.eventDescIdChangeSubscription?.unsubscribe();
    if(this.desktopView) {
      this.eventDescription.quickEditAllowed = true;
    }
  }

  private rebuildReactionText() {
    if(!this.eventDescItem) { return; }
    const reaction = this.us.currentUser.reactions.find((r) => r.id === this.eventDescItem.reaction_id);
    if (reaction === undefined) {
      this.reactionText = this.us.currentUser.reactions[0].default_name
        ? this.trans('reactionNames.reactions.' + this.us.currentUser.reactions[0].name)
        : this.us.currentUser.reactions[0].name;
    } else {
      this.reactionText = reaction.default_name ? this.trans('reactionNames.reactions.' + reaction.name) : reaction.name;
    }
  }

  private performSave() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.saving'));
    this.api.post('/edit/event-description', {
      id: this.eventDescItem.id,
      code: this.eventDescItem.event_code,
      name: this.eventDescItem.default_name,
      reaction: this.eventDescItem.reaction_id,
      active: this.eventDescItem.active,
      qualifier: this.eventDescItem.qualifier,
      area_event: this.eventDescItem.area_event,
    }, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          if (that.eventDescItem.id === 0) {
            that.eventDescItem.id = result.newId;
            that.eventDescription.eventDescriptions.push(that.eventDescItem);
          }
          that.es.endComponentEdit(TEditableComponent.EventDescription);
          that.router.navigate([this.backUrl]);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
      }
    );
  }

  private revert() {
    const original = this.es.getComponentBeforeModification(TEditableComponent.EventDescription);
    if (original === null) {
      return;
    }
    const found = this.eventDescription.eventDescriptions.find((ed) => ed.id === original.id);
    if (found === undefined) {
      this.eventDescription.eventDescriptions.push(original);
    } else {
      found.default_name = original.default_name;
      found.area_event = original.area_event;
      found.qualifier = original.qualifier;
      found.event_code = original.event_code;
      found.active = original.active;
      found.reaction_id = original.reaction_id;
    }
  }

  public changeName() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('events.settings.labels.nameEvent'),
        field1: {
          maxLen: 255,
          oldValue: this.eventDescItem.default_name,
        },
        onSubmitString: (res) => {
          that.eventDescItem.default_name = res;
        },
      },
      PopupType.String
    );
  }

  public changeQualifier() {
    if ( !this.canEdit ) { return; }
    this.pp.showSlideout<DropdownItem<string>>(
      {
        headerText: this.trans('events.settings.labels.qualifier'),
        items: [
          { value: 'E', label: this.trans('events.settings.qualifier.E') },
          { value: 'R', label: this.trans('events.settings.qualifier.R') }
        ],
        onSubmit: (res) => {
          this.eventDescItem.qualifier = res.value;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public changeCidCode() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('events.settings.labels.code'),
        field1: {
          isHex: true,
          maxLen: 3,
          oldValue: this.eventDescItem.event_code,
        },
        onSubmitString: (res) => {
          that.eventDescItem.event_code = res;
        },
      },
      PopupType.String
    );
  }

  public changeReaction() {
    const that = this;
    const reactions: DropdownItem<number>[] = [];
    for (const iReaction of this.us.currentUser.reactions) {
      reactions.push({
        label: iReaction.default_name ? this.trans('reactionNames.reactions.' + iReaction.name) : iReaction.name,
        value: iReaction.id,
      });
    }
    this.pp.showSlideout<DropdownItem<number>>(
      {
        headerText: this.trans('events.settings.labels.reaction'),
        items: reactions,
        onSubmit: (res) => {
          that.eventDescItem.reaction_id = res.value;
          that.rebuildReactionText();
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  private doDelete() {
    const that = this;
    const desc = this.eventDescription.eventDescriptions.find((ed) => ed.id === this.eventDescItem.id);
    if (desc === undefined) {
      return;
    }
    this.eventDescription.eventDescriptions.splice(this.eventDescription.eventDescriptions.indexOf(desc), 1);
    this.api.post('/delete/event-description', { id: this.eventDescItem.id }, true).subscribe(
      (result) => {
        if (!result.success) {
          const errorMessage = result.error ? result.error : '';
          that.messagebox.open({
            buttons: that.messagebox.buttons.Close,
            headerText: that.trans('general.titleError'),
            messageContent: `${that.trans('general.errors.errorOccurred')}${errorMessage !== '' ? `: ${errorMessage}` : '.'}`,
            iconType: that.messagebox.iconType.Error
          });
          that.revert();
        }
        that.miniStatus.hide();
        that.es.endComponentEdit(TEditableComponent.EventDescription);
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.messagebox.open({
          buttons: that.messagebox.buttons.Close,
          headerText: that.trans('general.titleError'),
          messageContent: that.trans('auth.errors.serverSideError'),
          iconType: that.messagebox.iconType.Error
        });
        that.miniStatus.hide();
        that.revert();
        that.es.endComponentEdit(TEditableComponent.EventDescription);
      }
    );
    if(this.platform.isDesktop()) {
      this.router.navigate([companyRootRoute, 'settings', 'event-descriptions']);
    } else {
      this.router.navigate([this.backUrl]);
    }
  }

  public getReactionsList(): Array<any> {
    if(this.us.currentUser.reactions.length === 0) {
      this.us.loadUserDataFromStorage();
    }
    const reactionsList = [];
    const reactions = this.us.currentUser.reactions;
    reactions.map(reaction => reactionsList.push({
      label: reaction.default_name? this.trans(`reactionNames.reactions.${reaction.name}`): reaction.name,
      value: reaction.id
    }));
    return reactionsList;
  }

  private async saveEventDescription() {
    const validationResult = await this.val.validate(this.isNew? this.newEventDescItem : this.eventDescItem);
    if(validationResult === false) { return; };

    const that = this;
    if(this.isNew) {
      if(this.newEventDescItem.qualifier === '-') {
        this.newEventDescItem.event_code = this.newEventDescItem.event_code.padEnd(3, '-');
      } else {
        this.newEventDescItem.event_code = this.newEventDescItem.event_code.padStart(3, '0');
      }
    } else {
      if(this.eventDescItem.qualifier === '-') {
        this.eventDescItem.event_code = this.eventDescItem.event_code.padEnd(3, '-');
      } else {
        this.eventDescItem.event_code = this.eventDescItem.event_code.padStart(3, '0');
      }
    }
    this.api
      .post(
        '/edit/event-description',
        {
          id: this.isNew? this.newEventDescItem.id : this.eventDescItem.id,
          code: this.isNew? this.newEventDescItem.event_code : this.eventDescItem.event_code,
          name: this.isNew? this.newEventDescItem.default_name : this.eventDescItem.default_name,
          reaction: this.isNew? this.newEventDescItem.reaction_id : this.eventDescItem.reaction_id,
          active: this.isNew? this.newEventDescItem.active : this.eventDescItem.active,
          qualifier: this.isNew? this.newEventDescItem.qualifier : this.eventDescItem.qualifier,
          area_event: this.isNew? this.newEventDescItem.area_event : this.eventDescItem.area_event,
        },
        true
      )
      .subscribe(
        async (result) => {
          if (result.success) {
            this.isRequestLoading = true;
            if (that.eventDescItem && that.eventDescItem.id === 0) {
              that.eventDescItem.id = result.newId;
              that.eventDescription.eventDescriptions.push(that.eventDescItem);
            }
            if(that.isNew) {
              that.newEventDescItem.id = result.newId;
              that.eventDescription.eventDescriptions.push(that.newEventDescItem);
            }
            that.es.endComponentEdit(TEditableComponent.EventDescription);

            if(that.isNew) {
              this.router.navigate([companyRootRoute, 'settings', 'event-descriptions']);
            } else {
              Object.assign(this.original, this.eventDescItem);
              setTimeout(() => {
                that.isRequestLoading = false;
              }, 200);
              await new Promise<void>(() => setTimeout(() => {
                that.onValueChange();
              }, that.delayDuration));
            }
          } else {
            this.messagebox.open({
              buttons: this.messagebox.buttons.Ok,
              headerText: this.trans('general.titleError'),
              iconType: this.messagebox.iconType.Error,
              messageContent: result.error ? result.error : this.trans('general.errors.errorOccurred')
            });
            that.isRequestLoading = false;
          }
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  public onDeleteClick(): void {
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('events.settings.deleteEventDescriptionInfo').replace(':event', this.eventDescItem.default_name)}: ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.doDelete();
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  public onValueChange() {
    if(JSON.stringify(this.original) !== JSON.stringify(this.eventDescItem)) {
      this.inputChanged = true;
    } else {
      this.inputChanged = false;
    }
  }

  private navigateBack() {
    this.revert();
    this.es.endComponentEdit(TEditableComponent.EventDescription);
    this.router.navigate([companyRootRoute, 'settings', 'event-descriptions']);
  }

  private reinitVariables() {
    this.eventDescItem = this.es.getEditableComponent(TEditableComponent.EventDescription);
    this.original = this.es.getComponentBeforeModification(TEditableComponent.EventDescription);

    if (!this.eventDescItem && this.desktopView && !this.isNew ) {
      this.router.navigate([companyRootRoute, 'settings', 'event-descriptions']);
    }
    const reactionItem = this.es.getEditableComponent(TEditableComponent.Reaction);
    if (reactionItem !== null) {
      this.backUrl = this.g.getHomeUrl().join('/') + '/edit-reaction';
      this.canDelete = false;
    }
    const textForHeader = this.eventDescItem ? this.eventDescItem.default_name : this.trans('events.settings.titleAdd');
    this.rebuildReactionText();
    if ( !this.desktopView ) {
      this.headerBar.showHeader({
        headerText: textForHeader,
        backUrl: this.backUrl,
        actionButtonText: this.eventDescItem === null || this.eventDescItem.id === 0 || !this.canDelete ? undefined : this.trans('general.delete'),
      });
      this.background.setGray();
      this.headerBar.onBackClicked = () => {
        this.revert();
        this.es.endComponentEdit(TEditableComponent.EventDescription);
      };
      if ( this.canEdit ) {
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
        this.footerBar.onButton1Click = () => {
          this.revert();
          this.es.endComponentEdit(TEditableComponent.EventDescription);
          this.router.navigate([this.backUrl]);
        };
        this.footerBar.onButton2Click = () => {
          this.performSave();
        };
      }
      if (this.eventDescItem && this.eventDescItem.id !== 0) {
        this.headerBar.onActionButtonClicked = () => {
          this.doDelete();
        };
      }
    }
  }

}
