import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissionRole } from 'src/api/v3/common';
import { UserService } from '../api/user.service';
import { ApiRequestService } from '../services/api-request.service';
import { GlobalService } from '../services/global.service';
import { SystemsService } from '../services/systems.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyRoleGuardService implements CanActivate {
  constructor(private us: UserService, private rs: ApiRequestService, private router: Router, private g: GlobalService, private ss: SystemsService) {}

  canActivate() {
    if (this.rs.hasToken() && !this.us.currentUser) {
      this.us.loadUserDataFromStorage();
    }
    if ( !(this.us.currentUser.permissions?.role === PermissionRole.Company) ) {
      this.router.navigate(this.g.getHomeUrl());
      return false;
    }
    return true;
  }
}
