import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-confirm-device-transfer',
  templateUrl: './confirm-device-transfer.component.html',
  styleUrls: ['./confirm-device-transfer.component.scss'],
})
export class ConfirmDeviceTransferComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router, public ns: NewSystemService, private ar: ActivatedRoute) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.transferDevice'),
      backUrl: '*',
    });
    const that = this;
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('systems.buttons.transfer'), true, false);
    this.footerBar.onButton1Click = () => {
      history.back();
    };
    this.footerBar.onButton2Click = () => {
      that.confirmTransfer();
    };
    this.background.setGray();
  }

  ngOnInit(): void {}

  private confirmTransfer() {
    this.router.navigate(['../add-system-details'], { replaceUrl: true, relativeTo: this.ar });
  }
}
