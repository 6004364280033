import { Component, OnDestroy, OnInit } from '@angular/core';
import { NewSystemService } from 'src/app/services/new-system.service';

@Component({
  selector: 'app-new-system',
  templateUrl: './new-system.component.html',
  styleUrls: ['./new-system.component.scss']
})
export class NewSystemComponent implements OnInit, OnDestroy {

  constructor(private ns: NewSystemService) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.ns.setDefaults();
  }
}
