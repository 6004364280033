<h4><app-back-button [style.display]="'inline-block'"></app-back-button> {{ isNew && desktopView ? trans('settings.ipcom.addSecurityCenter') : receiverItem.receiver_name }}</h4>
<div class="white_round padding8">
    <app-string-input type="text" label="{{trans('settings.ipcom.securityCName')}}" [(value)]="receiverItem.receiver_name" [disabled]="!canEdit || receiverItem.receiver_id === 0" (valueChange)="onInputChanged()">
        <app-form-error *ngIf="validator.hasError('receiver_name')">{{ validator.getError('receiver_name') }}</app-form-error>
    </app-string-input>
    <div class="settings_holder">
        <div>
            <div class="section_header">{{trans('settings.ipcom.inputSettings')}}</div>
            <ng-container *ngIf="receiverItem.receiver_id !== 0 || isNew">
                <app-string-input type="text" label="{{trans('settings.ipcom.inputHost')}}" [value]="ipcomItem.host" [disabled]="true"></app-string-input>
                <app-number-input label="{{trans('settings.ipcom.inputPort')}}" [value]="receiverItem.port" [disabled]="true"></app-number-input>
                <app-string-input type="password" label="{{trans('settings.labels.encryptionForDevices')}}" [(value)]="receiverItem.encryption_password" [disabled]="!canEdit" (valueChange)="onInputChanged()">
                    <app-form-error *ngIf="validator.hasError('encryption_password')">{{ validator.getError('encryption_password') }}</app-form-error>
                </app-string-input>
            </ng-container>
            <div *ngIf="receiverItem.receiver_id === 0 && !isNew" class="padding16">{{trans('settings.ipcom.builtInReceiverInfo')}}</div>
        </div>
        <div>
            <div class="section_header">{{trans('settings.ipcom.outputSettings')}}</div>
            <app-string-input type="text" label="{{trans('settings.ipcom.outputHost')}}" [(value)]="outputItem.ip" [disabled]="!canEdit" (valueChange)="onInputChanged()">
                <app-form-error *ngIf="validator.hasError('ip')">{{ validator.getError('ip') }}</app-form-error>
            </app-string-input>
            <app-number-input label="{{trans('settings.ipcom.outputPort')}}" [(value)]="outputItem.tcp_port" [disabled]="!canEdit" (valueChange)="onInputChanged()" [maxLength]="5">
                <app-form-error *ngIf="validator.hasError('tcp_port')">{{ validator.getError('tcp_port') }}</app-form-error>
            </app-number-input>
            <app-dropdown *ngIf="desktopView" label="{{trans('settings.ipcom.outputProtocol')}}" [items]="protocols" [(value)]="outputItem.out_mode" [disabled]="!canEdit" (valueChange)="onInputChanged()"></app-dropdown>
            <app-name-value *ngIf="!desktopView" [nameText]="trans('settings.ipcom.outputProtocol')" (clicked)="changeProtocol()" [isEnabled]="canEdit">{{protocols[outputItem.out_mode]}}</app-name-value>
            <ng-container  *ngIf="outputItem.out_mode === 7">
                <app-name-value [nameText]="trans('settings.ipcom.outputEncryptionEnabled')" [isEnabled]="canEdit">
                    <app-toggle-button [buttonId]="'outSiaEnabled'" [initialState]="outputItem.encryption_enabled === 'True'" (toggled)="outputItem.encryption_enabled = $event.checked ? 'True' : 'False'; onInputChanged()"></app-toggle-button>
                </app-name-value>
                <app-string-input type="password" label="{{trans('settings.ipcom.outputSiaKey')}}" [(value)]="outputItem.sia_key" [disabled]="!canEdit || outputItem.encryption_enabled === 'False'" (valueChange)="onInputChanged()">
                    <app-form-error *ngIf="validator.hasError('sia_key')">{{ validator.getError('sia_key') }}</app-form-error>
                </app-string-input>
            </ng-container>
        </div>
    </div>
    <app-dropdown *ngIf="desktopView && (canEditCompany || canViewCompany)" [label]="trans('settings.ipcom.companySelect')" [items]="companyList" [(value)]="receiverItem.company_id" [disabled]="!canEdit || !canEditCompany" (valueChange)="onInputChanged()"></app-dropdown>
    <app-name-value *ngIf="!desktopView && (canEditCompany || canViewCompany)" [nameText]="trans('settings.ipcom.companySelect')" (clicked)="changeCompany()">{{companyText}}</app-name-value>
    <ng-container *ngIf="(canSeeTags || canEditTags) && (receiverItem.receiver_id !== 0 || isNew)">
        <app-tag-input #tags [label]="trans('settings.ipcom.tags')" [name]="'rtaglist'" [initialTags]="receiverItem.tags" [isReadOnly]="!canEditTags" (tagsChanged)="onInputChanged()"></app-tag-input>
    </ng-container>
</div>

<ng-component *ngIf="(isNew || dataChanged) && desktopView">
    <ng-template appTeleport="footer">
        <app-desktop-footer [buttons]="isNew ? footerButtonsNew : footerButtons"></app-desktop-footer>
    </ng-template>
</ng-component>