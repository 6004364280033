import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/api/request.service';
import { LoginService } from 'src/app/login/login.service';
import { LanguageService } from 'src/app/services/language.service';
import { ValidatorBuilder } from 'src/app/ui/validator';

const codeValidator = new ValidatorBuilder<{ code: string }>()
  .required('code', (_, c: CodeComponent) => c.trans('auth.errors.requiredEmailVerificationCode'))
  .regex('code', /^[\d]{3}$/, (_, c: CodeComponent) => c.trans('auth.errors.invalidEmailVerificationCode'));

@Component({
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss'],
})
export class CodeComponent implements OnInit {
  public isLoading = false;
  public val = codeValidator.build().bindContext(this);
  public code = '';
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(private login: LoginService, private router: Router, private req: RequestService, private ls: LanguageService, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.login.activationToken) {
      this.router.navigate(['login']);
    }
  }

  public async next() {
    if (!(await this.val.validate(this))) { return; }
    this.isLoading = true;
    const res = await this.req.login.activate({ digitCode: this.code, pinHash: this.login.activationToken }).toPromise();
    if (res.success === false) {
      this.val.pushError('code', res.error);
      this.isLoading = false;
      return;
    }
    this.login.activationToken = undefined;
    this.login.passwordToken = res.password_hash;
    this.login.email = res.email;
    this.router.navigate(['login', 'register', 'password'], { replaceUrl: true });
  }

  public checkLengthAndFix($target: HTMLInputElement) {
    this.code = $target.value.substring(0, 3);
    $target.valueAsNumber = this.code === '' ? 0 : parseInt(this.code, 10);
  }
}
