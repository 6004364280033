<div class="grid system-pgms-grid">
    <div id='sp-background' class="group-background"></div>
    <div id='sp-pgm-list'>
        <div class="sp-pgm-item" *ngFor='let pgm of pgmS.getPgms()' (click)='onPgmSelect(pgm.id)'>
            <div class='sp-pgm-icon' [inlineSVG]="pgmS.getPgmIcon(pgm.icon, pgm.on, pgm.enabled)"></div>
            <div class='sp-pgm-number'>{{pgm.queue_no}}</div>
            <app-button-double-line-arrow class='sp-pgm-text' [firstLine]="pgm.name"></app-button-double-line-arrow>
        </div>
    </div>
</div>
<div id="sp_adv_container" class="padding16" *ngIf="(systems.activeSystem.hwType === 'GV17' || systems.activeSystem.hwType === 'CWI') && systems.activeSystem.amIMaster && !isNewSystem">
    <div id="sp_adv_info">{{trans('systems.labels.pgmListScheduleInfo')}}</div>
    <div id="sp_adv_goto" (click)="gotoAdvancedSettings()">{{trans('systems.labels.gotoAdvancedSettings')}}</div>
</div>
<app-plus-button *ngIf="systems.activeSystemHelper.can.add.outputs()" (plusClicked)="addOuput()" [showLimiter]="true" [currentCount]="currentOutputCount" [maxLimit]="maxOutputs" [aboveFooter]="isNewSystem"></app-plus-button>