<ng-container>
  <div class="wrapper">
    <button [name]="name" [class]="!isDisabled ? 'ripple': ''" [disabled]="isDisabled" (click)="onSelect()" [value]="value">
      <ng-container *ngIf="value && !isLoading">
        <div class="check"></div>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <div class="spinner"></div>
      </ng-container>
      {{label}}
    </button>
  </div>
</ng-container>
