import { Injectable } from '@angular/core';
import { UserService } from '../api/user.service';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { TEventListItemData } from '../models/event-list-item-data';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';
import { MiniStatusService } from './mini-status.service';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root'
})
export class EventDescriptionService {
  public reactionTexts = [];
  public eventDescriptions = [];
  public loadedEventDescriptions = [];
  public readonly eventPerPage = 30;
  public quickEditAllowed = true;

  constructor(
    private us: UserService,
    private miniStatus: MiniStatusService,
    private api: ApiRequestService,
    private toaster: ToasterService,
    private lang: LanguageService
  ) { }

  public loadDescriptions() {
    if (this.eventDescriptions.length > 0) {
      this.buildReactionTexts();
      return;
    }
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.lang.get('general.pleaseWait'));
    this.api.post('/get-event-descriptions', {}, true).subscribe(
      (result) => {
        if (result.success) {
          that.eventDescriptions = result.list;
          that.buildReactionTexts();
        } else {
          that.toaster.postError(result.error);
        }
        that.miniStatus.hide();
      },
      (error) => {
        that.toaster.postError(that.lang.get('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  private buildReactionTexts() {
    this.reactionTexts = [];
    for (const iDesc of this.eventDescriptions) {
      const reaction = this.us.currentUser.reactions.find((r) => r.id === iDesc.reaction_id);
      if (reaction === undefined) {
        this.reactionTexts.push(this.lang.get('general.none'));
      } else {
        this.reactionTexts.push(reaction.default_name ? this.lang.get('reactionNames.reactions.' + reaction.name) : reaction.name);
      }
    }
  }

  public getEventDescriptionsGetter(searchPhrase?: string, priorityIds?: number[]): DataTableGetter<TEventListItemData> {
    let loaded = 0;
    let prevSearchPhrase = '';
    this.sortEventDescriptionsByPriorityIds(this.eventDescriptions, priorityIds);

    return async (current, columns, more) => {
      const lowerCaseSearchPhrase = searchPhrase ? searchPhrase.toLowerCase() : '';
      if (searchPhrase && searchPhrase !== prevSearchPhrase) {
        this.loadedEventDescriptions = [];
        prevSearchPhrase = searchPhrase;
      }
      if (!more && current <= loaded) {
        const filteredData = this.eventDescriptions.filter(ed => (
          ed.default_name.toLowerCase().includes(lowerCaseSearchPhrase) ||
          ed.event_code.toLowerCase().includes(lowerCaseSearchPhrase)
        ));
        loaded = this.eventPerPage;
        const slicedData = filteredData.slice(0, this.eventPerPage);
        this.loadedEventDescriptions = [...slicedData];
        return filteredData.slice(0, this.eventPerPage);
      }
      if (this.eventDescriptions.length > 0) {
        this.loadedEventDescriptions.push(
          ...this.eventDescriptions.filter(ed => (
            ed.default_name.toLowerCase().includes(lowerCaseSearchPhrase) ||
            ed.event_code.toLowerCase().includes(lowerCaseSearchPhrase)
          )).slice(loaded, loaded + this.eventPerPage)
        );
      }
      loaded = this.loadedEventDescriptions.length;
      return this.loadedEventDescriptions;
    };
  }

  private sortEventDescriptionsByPriorityIds(eventDescriptions: any[], priorityIds: number[]) {
    if (priorityIds && priorityIds.length > 0) {
      eventDescriptions.sort((a, b) => {
        const aIndex = priorityIds.indexOf(a.id);
        const bIndex = priorityIds.indexOf(b.id);
        if (aIndex === -1 && bIndex === -1) {
          return 0;
        } else if (aIndex === -1) {
          return 1;
        } else if (bIndex === -1) {
          return -1;
        } else {
          return aIndex - bIndex;
        }
      });
    }
  }

  public async getEventDescription(id: number): Promise<TEventListItemData> {
    const that = this;
    return await new Promise((resolve, reject) => {
      that.api.post('/get-event-description', { id }, true).subscribe(
        (result) => {
          if (result.success) {
            resolve(result.item);
          } else {
            reject();
          }
        },
        (error) => {}
      );
    });
  }

}
