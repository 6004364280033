import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { ValidatorBuilder } from 'src/app/ui/validator';
type TransferDeviceData = {
  systemUid: string;
  targetsrv: number;
  customHost: string;
  customPort: null | number;
  mpass: string;
};

@Component({
  selector: 'app-cp-transfer-device',
  templateUrl: './cp-transfer-device.component.html',
  styleUrls: ['./cp-transfer-device.component.scss']
})
export class CpTransferDeviceComponent extends LanguageAware implements OnInit {
  public systemUid = '';
  public selectedIpcom = 0;
  public customPort = null;
  public mpass = '123456';
  public customHost = '';
  public ipcomDropdownItems = [];
  public transferring = false;
  public useIpcom = true;
  public readonly val = new ValidatorBuilder<TransferDeviceData>()
  .required('systemUid', `${this.trans('validation.required').replace(':attribute', this.trans('systems.labels.imei'))}`)
  .regex('systemUid', /^[a-zA-Z0-9]{11,16}$/, `${this.trans('validation.min.string')
  .replace(':attribute', this.trans('systems.labels.imei')).replace(':min', '11')}`)
  .regex('customPort', /^(null|\d+)$/, `${this.trans('validation.numeric').replace(':attribute', this.trans('users.labels.customPort'))}`)
  .build().bindContext(this);

  constructor(cdRef: ChangeDetectorRef, private messagebox: MessageboxService) {
    super(cdRef);
    const localIpcoms = this.us.currentUser.ipcoms.filter((i) => i.own_ipcom && i.host_for_devices !== '');
    if (localIpcoms.length > 0) {
      this.selectedIpcom = localIpcoms[0].id;
    }
    this.generateIPComDropdownItems();
  }

  ngOnInit(): void {
  }

  private get deviceToTransfer(): TransferDeviceData {
    return {
      systemUid: this.systemUid,
      targetsrv: this.useIpcom ? this.selectedIpcom : 0,
      customHost: this.useIpcom ? '' : this.customHost,
      customPort: this.customPort,
      mpass: this.mpass,
    };
  }

  public generateIPComDropdownItems() {
    const ownIpcoms = this.us.currentUser.ipcoms.filter((i) => i.host_for_devices !== '');
    for (const iIpcom of ownIpcoms) {
      this.ipcomDropdownItems.push({
        value: iIpcom.id,
        label: iIpcom.own_ipcom ? '*' + iIpcom.name : iIpcom.name,
      });
    }
  }

  public async performTransfer() {
    const validationResult = await this.val.validate(this.deviceToTransfer);
    if(validationResult === false) { return; };
    this.transferring = true;
    this.api
      .post(
        '/transfer-device',
        this.deviceToTransfer,
        true
      )
      .subscribe((result) => {
        this.transferring = false;
        if (!result.success) {
          this.showMessagebox(false, result.error);
        } else {
          this.showMessagebox(true, this.trans('settings.labels.transferCompleted'));
        }
      }, (error) => {
        this.transferring = false;
      });
  }

  private showMessagebox(success: boolean, message: string): void {
    this.messagebox.open({
      buttons: this.messagebox.buttons.Close,
      headerText: success ? this.trans('general.titleSuccess') : this.trans('general.titleError'),
      messageContent: message,
      iconType: success ? this.messagebox.iconType.Success : this.messagebox.iconType.Error
    });
  }

}
