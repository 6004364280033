<div *ngIf="this.platform.isDesktop() && desktopEnabled; else isMobile">
  <div class="middle_column_grid">
    <div class="middle_column">
      <div class="header">
        <h4 class="title">{{trans('settings.grg.title2')}}</h4>
      </div>
      <div class="panicButtonSection">
        <app-name-value [nameText]="trans('settings.grg.label.enable')" [isEnabled]="canEdit">
          <app-toggle-button [buttonId]="'cppb'" [initialState]="panicSettings.grg_enabled" (toggled)="panicSettings.grg_enabled = $event.checked; onInputChange()"></app-toggle-button>
        </app-name-value>
        <div class="{{canEdit ? '' : 'is_disabled'}}">
          <app-string-input [label]="trans('settings.grg.label.host')" [(value)]="panicSettings.grg_host" [maxLength]="255" (valueChange)="onInputChange()" [disabled]="!panicSettings.grg_enabled">
            <app-form-error *ngIf="val.hasError('grg_host')">{{ val.getError('grg_host') }}</app-form-error>
          </app-string-input>
          <app-string-input [label]="trans('settings.grg.label.port')" [(value)]="panicSettings.grg_port" [maxLength]="6" (valueChange)="onInputChange()" [disabled]="!panicSettings.grg_enabled">
            <app-form-error *ngIf="val.hasError('grg_port')">{{ val.getError('grg_port') }}</app-form-error>
          </app-string-input>
          <app-dropdown [label]="trans('settings.grg.label.classificator')" [items]="[{label: 'E', value: 0}, {label: 'R', value: 1}]" [(value)]="classificatorTextValue" (valueChange)="onChangeClassificatorSubmit(classificatorTextValue);onInputChange()" [disabled]="!panicSettings.grg_enabled"></app-dropdown>
          <app-string-input [label]="trans('settings.grg.label.code')" [(value)]="codeText" [maxLength]="3" (valueChange)="onChangeCodeSubmit();onInputChange()" [disabled]="!panicSettings.grg_enabled">
            <app-form-error *ngIf="val.hasError('grg_code')">{{ val.getError('grg_code') }}</app-form-error>
          </app-string-input>
          <app-string-input [label]="trans('settings.grg.label.zone')" [(value)]="panicSettings.grg_zone" [maxLength]="3" (valueChange)="onInputChange()" [disabled]="!panicSettings.grg_enabled">
            <app-form-error *ngIf="val.hasError('grg_zone')">{{ val.getError('grg_zone') }}</app-form-error>
          </app-string-input>
        </div>
      </div>
    </div>
  </div>
  <ng-component *ngIf="inputChanged && canEdit">
    <ng-template appTeleport="footer">
      <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
    </ng-template>
  </ng-component>
</div>

<ng-template #isMobile>
  <div class="white_round padding16">
    <app-name-value [nameText]="trans('settings.grg.label.enabled')" [isEnabled]="canEdit">
      <app-toggle-button [buttonId]="'cppb'" [initialState]="us.currentUser.panic_settings.grg_enabled" (toggled)="us.currentUser.panic_settings.grg_enabled = $event.checked"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.host')" (clicked)="changeIp()" [isEnabled]="canEdit">{{us.currentUser.panic_settings.grg_host}}</app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.port')" (clicked)="changePort()" [isEnabled]="canEdit">{{us.currentUser.panic_settings.grg_port}}</app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.classificator')" (clicked)="changeClassificator()" [isEnabled]="canEdit">{{classificatorText}}</app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.code')" (clicked)="changeCode()" [isEnabled]="canEdit">{{codeText}}</app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.zone')" (clicked)="changeZone()" [isEnabled]="canEdit">{{us.currentUser.panic_settings.grg_zone}}</app-name-value>
  </div>
  <app-popup></app-popup>
</ng-template>
