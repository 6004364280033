import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-history-systems',
  templateUrl: './history-systems.component.html',
  styleUrls: ['./history-systems.component.scss'],
})
export class HistorySystemsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.history'),
      backUrl: this.g.getHomeUrl().join('/'),
    });
    this.background.setGray();
  }

  ngOnInit(): void {}
}
