<div class="grid system-cameras-grid">
    <div id='sc-back' class="group-background"></div>
    <div id='sc-list'>
        <div class="sc-item" *ngFor='let iCamera of cameras' (click)='selectCamera(iCamera)'>
            <div class='sc-icon' [inlineSVG]="'assets/images/camera2.svg'"></div>
            <app-button-double-line-arrow class='sc-text' [firstLine]="iCamera.name"></app-button-double-line-arrow>
        </div>
    </div>
</div>
<app-plus-button
    *ngIf="systems.activeSystemHelper.can.add.cameras()"
    [centerButton]="cameras.length === 0"
    (plusClicked)="addCamera()"
    [buttonText]="trans('systems.titles.clickPlusCamera')"
    [showLimiter]="true"
    [currentCount]="cameraCount"
    [maxLimit]="cameraLimit">
</app-plus-button>