import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PermissionRole } from 'src/api/v3/common';
import { ReactionService } from 'src/app/api/system/reaction.service';
import { FragmenterComponent } from 'src/app/components/fragmenter/fragmenter.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEventData } from 'src/app/models/event-data';
import { DateService } from 'src/app/services/date.service';
import { EventsService } from 'src/app/services/events.service';
import { WebSocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventsComponent extends LanguageAware implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fragmentLoader') fragmentLoader: FragmenterComponent;
  public lastSynced = '';
  public groupedEvents: any[] = [];
  private eventSubscription = null;
  private refreshSubscriber = null;
  public that = this;
  private eventCountBeforeGrouping = 0;

  constructor(
    cdRef: ChangeDetectorRef,
    public dt: DateService,
    private ev: EventsService,
    ws: WebSocketService,
    private es: EventsService,
    public reactions: ReactionService,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.background.setGray();
    this.headerBar.showHeader({
      backUrl: '*',
      showDotMenu: this.us.currentUser.permissions.role === PermissionRole.GeneralUser,
    });
    this.headerBar.setDotMenuItems([
      {
        name: this.trans('systems.menu.eventsHideUnhide'),
        action: () => { this.router.navigate(['../event-control'], { relativeTo: this.r }); },
      },
    ]);
    this.groupedEvents = this.getGroupedEvents();
    this.eventSubscription = ws.onEventReceived.subscribe(() => {
      this.groupedEvents = this.getGroupedEvents();
    });
    this.refresher.enableRefresher();
    this.refreshSubscriber = this.refresher.onRefresh.subscribe(() => {
      this.loadEvents();
    });
    if (this.us.currentUser.permissions?.role === PermissionRole.SuperAdmin && this.systems.activeSystem.events.length < 2) {
      this.loadEvents();
    }
  }

  ngOnInit(): void {
    this.lastSynced = this.trans('events.titles.syncTime') + ': ' + this.dt.formatDateTime(new Date());
  }

  ngAfterViewInit(): void {
    this.fragmentLoader.isEnabled = this.eventCountBeforeGrouping % this.us.eventPerPage === 0;
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.refreshSubscriber !== null) {
      this.refreshSubscriber.unsubscribe();
      this.refreshSubscriber = null;
    }
    if (this.eventSubscription !== null) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
  }

  public getGroupedEvents(): any[] {
    let eventListToUse = [];
    if (this.systems.activeSystem.eventConfiguration.length === 0) {
      eventListToUse = this.systems.activeSystem.events;
    } else {
      eventListToUse = this.systems.activeSystem.events.filter((e) => this.systems.activeSystem.eventConfiguration.indexOf(e.reaction) !== -1);
    }
    if (this.fragmentLoader !== undefined) {
      this.fragmentLoader.isEnabled = eventListToUse.length % this.us.eventPerPage === 0;
    }
    this.eventCountBeforeGrouping = eventListToUse.length;

    return this.ev.groupEvents(eventListToUse, 0, 0);
  }

  private loadEvents() {
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const systemId = this.systems.activeSystem.id;
    this.api.post('/events', { system_id: systemId }, true).subscribe(
      (result) => {
        if (result.success) {
          const system = this.systems.getSystem(systemId);
          if (system === undefined) {
            this.miniStatus.hide();
            return;
          }
          const convertedEvents: TEventData[] = [];
          for (const iEvent of result.events) {
            const singleEvent = this.es.convertFromRaw(iEvent);
            convertedEvents.push(singleEvent);
          }
          system.events = convertedEvents;
          this.groupedEvents = this.getGroupedEvents();
        } else {
          this.toaster.postError(result.error);
        }
        this.miniStatus.hide();
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }

  public loadEventChunk(context: any, callback?: any) {
    context.miniStatus.show(context.trans('general.pleaseWait'));
    if ( !context.systems.activeSystem ) {
      context.miniStatus.hide();
      return;
    }
    const systemId = context.systems.activeSystem.id;
    const lastEventId = context.systems.activeSystem.events.length === 0 ? 0 : context.systems.activeSystem.events[context.systems.activeSystem.events.length - 1].id;
    context.api.post('/events', { system_id: systemId, offsetEvents: lastEventId, forApi: true }, true).subscribe(
      (result) => {
        if (result.success) {
          const system = context.systems.getSystem(systemId);
          if (system === undefined) {
            context.miniStatus.hide();
            if (callback !== undefined) {
              callback();
            }
            return;
          }
          const convertedEvents: TEventData[] = [];
          for (const iEvent of result.events) {
            const singleEvent = context.es.convertFromRaw(iEvent);
            convertedEvents.push(singleEvent);
          }
          system.events.push(...convertedEvents);
          context.groupedEvents = context.getGroupedEvents();
        } else {
          context.toaster.postError(result.error);
        }
        context.miniStatus.hide();
        if (callback !== undefined) {
          callback();
        }
      },
      (error) => {
        context.miniStatus.hide();
        if (callback !== undefined) {
          callback();
        }
      }
    );
  }
}
