import { GV17SimpleUserData } from 'src/app/configurators/gv17/src/app/models/types';
import { URLSearchParams } from 'url';
import { httpGet } from '../http';
import { objectToParams } from '../v3/common';
import { CommonRequest, CommonResponse } from './common';

const read = (params: ConfigUsersRequestParams) => httpGet<ConfigUsersRequestResponse, URLSearchParams>('/v3/api/config/users/read', objectToParams(params));

export type ConfigUsersRequestParams = CommonRequest & {
  packet: 0;
  fwVersion: string;
};

export type ConfigUsersRequestResponse = CommonResponse & {
  data?: GV17SimpleUserData[];
};

export default {
  read,
};
