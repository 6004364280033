<div class="page">
  <div *ngIf="!mobileView" class="loginHeader">
    <div><app-back-button [buttonColor]="'currentColor'"></app-back-button></div>
    <app-language-switcher class="ls" [dropdownColor]="'#ffffff'"></app-language-switcher>
  </div>
  <div class="content">
    <div class="logoContainer">
      <app-back-button [buttonColor]="'currentColor'" *ngIf="mobileView"></app-back-button>
      <span class="logo" [inlineSVG]="'assets/images/logo_emblem_text.svg'"></span>
      <span *ngIf="mobileView"><app-language-switcher class="ls"></app-language-switcher></span>
    </div>
    <ng-content></ng-content>
    <router-outlet></router-outlet>
  </div>
</div>
