import { NgModule } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-w';
import { AutofocusDirective } from './autofocus.directive';

@NgModule({
  imports: [
    InlineSVGModule.forRoot(),
  ],
  declarations: [AutofocusDirective],
  exports: [AutofocusDirective, InlineSVGModule],
})
export class DirectivesModule {}
