import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import requests from 'src/api/v3/requests';
import { RequestService } from 'src/app/api/request.service';
import { EventFilterCriteria } from 'src/app/api/system/event.service';
import { ReactionService } from 'src/app/api/system/reaction.service';
import { SystemService } from 'src/app/api/system/system.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { EventDescriptionService } from 'src/app/services/event-description.service';
type SearchType = {
  name: string;
  label: string;
  visible: boolean;
};
type ActiveFilterCriteria = {
    id: number;
    searchPhrase: string;
    searchTypeName: string;
    searchTypeLabel: string;
};

@Component({
  selector: 'app-company-events-filter',
  templateUrl: './company-events-filter.component.html',
  styleUrls: ['./company-events-filter.component.scss']
})
export class CompanyEventsFilterComponent extends LanguageAware implements OnInit {
  @Input() public set contextCriteria(value) {
    if (!value) { return; }
    this.removeActiveFilters();
    if(value.systemId) {
      this.onContextSystemFilter(value.systemId);
    } else if (value.reactionId) {
      this.onContextReactionFilter(value.reactionId);
    } else if (value.eventDescId) {
      this.onContextEventDescriptionFilter(value.eventDescId);
    } else if (value.tagId) {
      this.onContextTagFilter(value.tagId, value.item);
    } else if (value.userId) {
      this.onContextUserFilter(value.userId, value.item);
    }
  }
  @Output() public criteriaChanged = new EventEmitter<EventFilterCriteria>();

  public selectedSystemId = -1;
  public selectedReactionId = -1;
  public selectedTagId = null;
  public selectedEventDescId: number = null;
  public selectedUserId: number = null;
  public startDate: DateTime | null = null;
  public endDate: DateTime | null = null;

  public showFilterSection = false;
  public showFilterKeywordSection = false;
  public filterSearchQueryLoading = false;

  public activeFilterCriteria: ActiveFilterCriteria[] = [];
  public itemsFromAddFilterCriteria = new Set();
  private typingTimer = null;
  public activeChangeSelector = null;
  private readonly doneTypingInterval = 500;

  public searchTypes: SearchType[] = [
    { name: 'system', label: this.trans('events.titles.system'), visible: true },
    { name: 'event', label: this.trans('events.settings.labels.reaction'), visible: true },
    { name: 'tag', label: this.trans('events.titles.tag'), visible: true },
  ];

  public addFilterCriteria = {
    searchPhrase: '',
    searchTypeName: '',
    searchTypeLabel: '',
  };

  public get showAddFilter(): boolean {
    const visibleFilters = this.searchTypes.filter(item => item.visible === true);
    return visibleFilters.length > 0 ? true : false;
  }

  constructor(
    cdRef: ChangeDetectorRef,
    private req: RequestService,
    private systemService: SystemService,
    private reactions: ReactionService,
    private eventDescription: EventDescriptionService,
  ) {
    super(cdRef);
    this.systemService.loadPartialSystemData();
  }

  public filter(): void {
    this.criteriaChanged.emit({
      systemId: this.selectedSystemId,
      reactionId: this.selectedReactionId,
      eventDescId: this.selectedEventDescId,
      userId: this.selectedUserId,
      tagId: this.selectedTagId,
      startDate: this.startDate ? this.startDate.toISO() : undefined,
      endDate: this.endDate ? this.endDate.toISO() : undefined,
    });
  }

  ngOnInit(): void {}

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.checkIfClickedInside(target);
    if (!clickedInside) {
      this.cancelFilterCriteriaSetup();
    }
  }

  private checkIfClickedInside(element: HTMLElement): boolean {
    const hiddenSectionId = 'searchType';
    const filterSectionId = 'eventsFilterSection';
    if (element.id === hiddenSectionId || element.id === filterSectionId) {
      return true;
    }
    if (element.parentElement) {
      return this.checkIfClickedInside(element.parentElement);
    }
    return false;
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.cancelFilterCriteriaSetup();
    }
  }

  public onAddFilterClick() {
    if(this.activeChangeSelector !== null) {
      this.activeChangeSelector = null;
    }
    this.showFilterSection = true;
  }

  public selectFilterCriteria(searchType) {
    this.showFilterKeywordSection = true;
    this.addFilterCriteria.searchTypeName = searchType.name;
    this.addFilterCriteria.searchTypeLabel = searchType.label;
  }

  public onTypingSearchField() {
    if(this.addFilterCriteria.searchPhrase.length >= 3) {
      clearTimeout(this.typingTimer);
      this.filterSearchQueryLoading = true;
      this.typingTimer = setTimeout(() => {
        this.itemsFromAddFilterCriteria.clear();
        if(this.addFilterCriteria.searchTypeName === 'system') {
          this.loadSystemsFromSearchPhrase();
        } else if (this.addFilterCriteria.searchTypeName === 'event') {
          this.loadReactionsFromSearchPhrase();
        } else if (this.addFilterCriteria.searchTypeName === 'tag') {
          this.loadTagsFromSearchPhrase();
        }
      }, this.doneTypingInterval);
    } else {
      this.filterSearchQueryLoading = false;
    }
  }

  private async loadSystemsFromSearchPhrase() {
    const criteria = {
      searchPhrase: this.addFilterCriteria.searchPhrase,
      searchFields: ['name'],
      currentPage: 0,
      lastPage: 0,
    };
    const result = await this.req.system.filterSystems({
      searchPhrase: criteria.searchPhrase,
      searchFields: criteria.searchFields,
      paginationPage: criteria.currentPage === criteria.lastPage ? criteria.lastPage : criteria.currentPage + 1
    }).toPromise();
    if (result.success) {
      this.filterSearchQueryLoading = false;
      result.list.data.forEach((system) => {
        const existingItem = this.itemsFromAddFilterCriteria.has({ id: system.id, name: system.name });
        if (!existingItem) {
          this.itemsFromAddFilterCriteria.add({ id: system.id, name: system.name });
        }
      });
    }
  }

  private loadReactionsFromSearchPhrase(): void {
    const searchPhrase = this.addFilterCriteria.searchPhrase;
    this.itemsFromAddFilterCriteria.clear();
    const reactions = this.us.currentUser.reactions;
    if(searchPhrase && searchPhrase !== '' && reactions.length > 0) {
      reactions.filter(reaction => {
        if(reaction.name.toLowerCase().includes(searchPhrase ? searchPhrase.toLowerCase() : '')) {
          this.itemsFromAddFilterCriteria.add({
            id: reaction.id,
            name: reaction.default_name ? this.trans('reactionNames.reactions.' + reaction.name) : reaction.name,
          });
        }
      });
    } else {
      reactions.map(reaction => {
        this.itemsFromAddFilterCriteria.add({
          id: reaction.id,
          name: reaction.default_name ? this.trans('reactionNames.reactions.' + reaction.name) : reaction.name,
        }
        );
      });
    }
    this.filterSearchQueryLoading = false;
  }

  private async loadTagsFromSearchPhrase(): Promise<void> {
    const result = await requests.tag.getTags({
      searchPhrase: this.addFilterCriteria.searchPhrase
    }).toPromise();
    if(result.success) {
      result.list.data.forEach((tag) => {
        const existingItem = this.itemsFromAddFilterCriteria.has({ id: tag.id, name: tag.name });
        if (!existingItem) {
          this.itemsFromAddFilterCriteria.add({ id: tag.id, name: tag.name });
        }
      });
    }
    this.filterSearchQueryLoading = false;
  }

  public onSubmitFilterCriteriaClick(selectedItem) {
    const index = this.activeFilterCriteria.findIndex(item => item.searchTypeName === this.addFilterCriteria.searchTypeName);
    if(this.addFilterCriteria.searchTypeName === 'system') {
      this.selectedSystemId = selectedItem.id;
    } else if (this.addFilterCriteria.searchTypeName === 'event') {
      this.selectedReactionId = selectedItem.id;
    } else if (this.addFilterCriteria.searchTypeName === 'tag') {
      this.selectedTagId = selectedItem.id;
    } else if (this.addFilterCriteria.searchTypeName === 'event_description') {
      this.selectedEventDescId = selectedItem.id;
    } else if (this.addFilterCriteria.searchTypeName === 'user') {
      this.selectedUserId = selectedItem.id;
    }
    this.filter();

    if (index !== -1) {
      this.activeFilterCriteria[index].searchPhrase = selectedItem.name;
    } else {
      this.activeFilterCriteria.push(
        {
          id: selectedItem.id,
          searchPhrase: selectedItem.name,
          searchTypeName: this.addFilterCriteria.searchTypeName,
          searchTypeLabel: this.addFilterCriteria.searchTypeLabel,
        }
      );
      const searchType = this.searchTypes.find(type => type.name === this.addFilterCriteria.searchTypeName);
      if(searchType) {
        searchType.visible = false;
      }
    }
    this.cancelFilterCriteriaSetup();
  }

  public onActiveFilterClick(activeFilter) {
    if(this.showFilterSection) { this.showFilterSection = false; };
    if(this.showFilterKeywordSection) { this.showFilterKeywordSection = false; };

    const editableFilters = ['system', 'event', 'tag'];
    if(editableFilters.includes(activeFilter.searchTypeName)) {
      this.activeChangeSelector = activeFilter.searchTypeName;
      this.addFilterCriteria.searchTypeName = activeFilter.searchTypeName;
      this.addFilterCriteria.searchTypeLabel = activeFilter.searchTypeLabel;
    }
  }

  public onActiveFilterRemoveClick(activeFilter) {
    this.resetActiveFilterTypeVisibility(activeFilter);
    this.cancelFilterCriteriaSetup();

    const index = this.activeFilterCriteria.findIndex(item => item.searchTypeName === activeFilter.searchTypeName);
    if (index !== -1) {
      if(this.activeFilterCriteria[index].searchTypeName === 'system') {
        this.selectedSystemId = -1;
      } else if (this.activeFilterCriteria[index].searchTypeName === 'event') {
        this.selectedReactionId = -1;
      } else if (this.activeFilterCriteria[index].searchTypeName === 'tag') {
        this.selectedTagId = null;
      } else if (this.activeFilterCriteria[index].searchTypeName === 'event_description') {
        this.selectedEventDescId = null;
      } else if (this.activeFilterCriteria[index].searchTypeName === 'user') {
        this.selectedUserId = null;
      }
      this.activeFilterCriteria.splice(index, 1);
      this.filter();
    }
  }

  private cancelFilterCriteriaSetup() {
    this.showFilterSection = false;
    this.showFilterKeywordSection = false;
    this.addFilterCriteria = {
      searchPhrase: '',
      searchTypeName: '',
      searchTypeLabel: '',
    };
    this.activeChangeSelector = null;
  }

  public datesChanged(event) {
    this.startDate = event.startDate ? DateTime.fromISO(event.startDate) : null;
    this.endDate = event.endDate ? DateTime.fromISO(event.endDate) : null;
    this.filter();
  }

  private resetSearchTypeVisibilities() {
    this.searchTypes.map(searchType => searchType.visible = true);
  }

  private resetActiveFilterTypeVisibility(activeFilter) {
    this.searchTypes.map(type => {
      if(type.name === activeFilter.searchTypeName) {
        type.visible = true;
      }
    });
  }

  private removeActiveFilters() {
    this.resetSearchTypeVisibilities();

    this.selectedSystemId = -1;
    this.selectedReactionId = -1;
    this.selectedEventDescId = null;
    this.selectedUserId = null;
    this.selectedTagId = null;
    this.startDate = null;
    this.endDate = null;
    this.activeFilterCriteria = [];
  }

  private onContextSystemFilter(id: number): void {
    const systemName = this.systemService.getSystemName(id);
    const selectedItem = {
      id,
      name: systemName,
    };
    this.addFilterCriteria.searchTypeName = 'system';
    this.addFilterCriteria.searchTypeLabel = this.trans('events.filter.buttons.filterSystem');
    this.onSubmitFilterCriteriaClick(selectedItem);
  }

  private onContextReactionFilter(id: number): void {
    let reactionName;
    const reaction = this.reactions.reactions.get(id);
    if(!reaction) { reactionName = ''; }
    reactionName = reaction.name;
    if(reaction.default_name) {
      reactionName = this.trans(`reactionNames.reactions.${reactionName}`);
    }
    const selectedItem = {
      id,
      name: reactionName,
    };
    this.addFilterCriteria.searchTypeName = 'event';
    this.addFilterCriteria.searchTypeLabel = this.trans('events.filter.buttons.filterReaction');
    this.onSubmitFilterCriteriaClick(selectedItem);
  }

  private async onContextEventDescriptionFilter(id: number): Promise<void> {
    const eventDescription = await this.eventDescription.getEventDescription(id);
    const selectedItem = {
      id,
      name: eventDescription.default_name,
    };
    this.addFilterCriteria.searchTypeName = 'event_description';
    this.addFilterCriteria.searchTypeLabel = this.trans('events.filter.buttons.filterEventDesc');
    this.onSubmitFilterCriteriaClick(selectedItem);
  }

  private onContextUserFilter(id: number, item): void {
    if(!item) { return; }
    const selectedItem = {
      id,
      name: item.user_name ? item.user_name : '',
    };
    this.addFilterCriteria.searchTypeName = 'user';
    this.addFilterCriteria.searchTypeLabel = this.trans('events.filter.buttons.filterUser');
    this.onSubmitFilterCriteriaClick(selectedItem);
  }

  private onContextTagFilter(id: number, item): void {
    if(!item) { return; }
    const tag = item.tags.find(tag => tag.tag_id === id);
    if(tag) {
      const selectedItem = {
        id,
        name: tag.name,
      };
      this.addFilterCriteria.searchTypeName = 'tag';
      this.addFilterCriteria.searchTypeLabel = this.trans('events.filter.buttons.filterTag');
      this.onSubmitFilterCriteriaClick(selectedItem);
    }
  }

}
