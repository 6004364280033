import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ReactionService } from 'src/app/api/system/reaction.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { ApiRequestService } from 'src/app/services/api-request.service';

@Component({
  selector: 'app-event-control',
  templateUrl: './event-control.component.html',
  styleUrls: ['./event-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventControlComponent extends LanguageAware implements OnInit {
  public groupedReactions = [];
  private oldConfiguration = '';
  private doSave = false;

  constructor(
    cdRef: ChangeDetectorRef,
    private rs: ApiRequestService,
    public reactions: ReactionService
  ) {
    super(cdRef);
    this.tag = 'EventConfig';
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.eventsHideUnhide'),
      backUrl: '*',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.footerBar.onButton1Click = () => {
      this.onCancel();
    };
    this.footerBar.onButton2Click = () => {
      this.onSave();
    };
    this.headerBar.onBackClicked = () => {
      this.revertChanges(this.systems.activeSystem.id, this.oldConfiguration);
    };
    this.generatedGroups();
    this.oldConfiguration = JSON.stringify(this.systems.activeSystem.eventConfiguration);
  }

  ngOnInit(): void {}

  private onCancel() {
    this.revertChanges(this.systems.activeSystem.id, this.oldConfiguration);
    history.back();
  }

  private onSave() {
    if (this.doSave) {
      this.sendChanges(this.systems.activeSystem.id, JSON.stringify(this.systems.activeSystem.eventConfiguration), this.oldConfiguration);
    }
    history.back();
  }

  private generatedGroups() {
    const allReactions = [...this.reactions.basicReactions.values()].filter((r) => r.companyId === undefined || r.companyId === this.systems.activeSystem.company_id);
    allReactions.sort((r1, r2) => r1.group.localeCompare(r2.group));

    let currentGroup = null;
    for (const iReaction of allReactions) {
      if (currentGroup === null || currentGroup.group !== iReaction.group) {
        currentGroup = {
          group: iReaction.group,
          reactions: [iReaction],
        };
        this.groupedReactions.push(currentGroup);
      } else {
        currentGroup.reactions.push(iReaction);
      }
    }
  }

  public isVisible(reactionId: number) {
    return this.systems.activeSystem.eventConfiguration.length === 0 || this.systems.activeSystem.eventConfiguration.indexOf(reactionId) !== -1;
  }

  public setVisible(reactionId: number, value?: boolean) {
    // Jeigu sąrašas tuščias, tai vadinasi galima tik viena operacija - išjungimas.
    if (this.systems.activeSystem.eventConfiguration.length === 0) {
      for (const iReaction of this.reactions.basicReactions.values()) {
        // Sudedam viską išskyrus tą, kuris kątik nužymėtas.
        if (iReaction.id === reactionId) {
          continue;
        }
        this.systems.activeSystem.eventConfiguration.push(iReaction.id);
      }
      this.doSave = true;
      return;
    }

    const found = this.systems.activeSystem.eventConfiguration.indexOf(reactionId);
    if ((value === undefined || !value) && found === -1) {
      this.systems.activeSystem.eventConfiguration.push(reactionId);
      this.doSave = true;
    } else if ((value === undefined || value) && found !== -1) {
      this.systems.activeSystem.eventConfiguration.splice(found, 1);
      this.doSave = true;
    }
  }

  private sendChanges(systemId: number, newConfig: string, oldConfig: string) {
    this.rs.post('/edit-event-configuration', { systemId, configuration: newConfig }, true).subscribe(
      (result) => {
        if (result.success) {
          this.log('Nustatymai išsaugoti.');
          this.systems.saveActiveSystem(systemId);
        } else {
          this.log('Nepavyko išsaugoti nustatymų');
          this.toaster.postError(result.error);
          this.revertChanges(systemId, oldConfig);
        }
      },
      (error) => {
        this.toaster.postError(this.trans('auth.errors.serverSideError'));
        this.revertChanges(systemId, oldConfig);
      }
    );
  }

  private revertChanges(systemId: number, oldConfig: string) {
    this.log('Atstatom senus nustatytmus.');
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    system.eventConfiguration = JSON.parse(oldConfig);
  }
}
