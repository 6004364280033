import { EventEmitter } from '@angular/core';
import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionRule } from 'src/api/v3/common';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { FilterCriteria } from 'src/app/company/pages/company-users/company-users.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss']
})
export class PermissionListComponent extends LanguageAware implements OnInit {
  public readonly canAdd = this.us.currentUser.permissions?.permissions.roles.create;
  public readonly canDelete = this.us.currentUser.permissions?.permissions.roles.delete;
  public readonly desktopView = this.platform.isDesktopView();
  public columns = this.persistenceService.bind('permission_list_columns', ['roleIcon', 'name', 'fake:arrow']);

  @Input() public filterCriteria: FilterCriteria = null;
  @Input() loader: DataTableGetter<PermissionRule>;
  @Output() public modifyColumns = new EventEmitter<void>();

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
  ) {
    super(cdRef);
    if ( !this.desktopView ) {
      this.columns.value = ['roleIcon', 'name', 'fake:arrow'];
      this.headerBar.showHeader({
        backUrl: '*',
        headerText: this.trans('permissions.titles.list'),
      });
      this.background.setGray();
      this.footerBar.hideFooter();
    } else if ( this.us.currentUser.company_id !== 0 && this.columns.value.includes('company') ) {
      this.columns.value.splice(this.columns.value.indexOf('company'), 1);
    }
  }

  ngOnInit(): void {
    if ( !this.loader ) {
      this.loader = this.permissionService.getPermissionsGetter(this.filterCriteria);
    }
  }

  public addPermissionRule() {
    this.es.beginComponentEdit(TEditableComponent.Permission, 0, null);
    this.router.navigate([...this.g.getHomeUrl(), 'permissions', 'new']);
  }

}
