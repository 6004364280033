import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { TAreaData } from 'src/app/models/area-data';
import { PgmData } from 'src/app/models/pgm';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupService } from 'src/app/services/popup.service';
import { PopupType } from 'src/app/models/popup-type';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { NewSystemService } from 'src/app/services/new-system.service';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.scss'],
})
export class AreaComponent extends LanguageAware implements OnInit {
  public areaHasPgm = false;
  public showPgmData = false;
  public maxAreaLen = 100;
  public localArea: TAreaData = null;
  public localPgm: PgmData = null;
  private backUrlByRef = '';
  public readonly panicButtonsAreaStatus = 18;
  public canEdit = this.systems.activeSystemHelper.can.edit.areas() || this.systems.activeSystemHelper.can.add.areas() || this.ns.isSystemBeingCreated();

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private pp: PopupService,
    private ns: NewSystemService,
    private r: ActivatedRoute,
    private router: Router,
  ) {
    super(cdRef);
    this.tag = 'Area';
    this.es.endComponentEdit(TEditableComponent.Output);
    this.es.endComponentEdit(TEditableComponent.Helper1);
    this.localArea = this.es.getEditableComponent(TEditableComponent.Area);
    const ref = localStorage.getItem('ref');
    this.backUrlByRef = (ref !== null && ref === 'new' ? '../add-system-area-list' : '*');
    if (this.localArea === null) {
      this.headerBar.showHeader({
        headerText: this.trans('systems.titles.addNewArea'),
        backUrl: this.backUrlByRef,
        activatedRoute: this.r,
      });
      this.localArea = {
        name: '',
        queue_no: 1,
        zones: [],
        id: 0,
        canBypass: false,
        showKeypad: false,
        status: 0,
        statusTime: 0,
        alarmed: false,
        lastPerson: '',
        alarmTime: 0,
        alarmType: 0,
      };
      const areas = this.systems.activeSystem.areas;
      let maxNumber = 0;
      for (const iArea of areas) {
        if (iArea.queue_no > maxNumber) {
          maxNumber = iArea.queue_no;
        }
      }
      this.localArea.queue_no = maxNumber + 1;
      this.headerBar.onBackClicked = () => {
        this.revertPgmForNewArea();
      };
    } else {
      this.headerBar.showHeader({
        headerText: this.systems.activeSystem.hwType === 'FC' ? this.trans('systems.labels.editGroup') : this.trans('systems.labels.editArea'),
        backUrl: this.backUrlByRef,
        activatedRoute: this.r,
      });
      this.localPgm = this.systems.activeSystem.pgms.find((p) => p.control_area && p.area_id === this.localArea.id) ?? null;
      this.areaHasPgm = this.localPgm !== null;
      this.showPgmData = this.localPgm !== null;
      if (this.localPgm !== null) {
        this.es.beginComponentEdit(TEditableComponent.Output, this.localPgm.id, this.localPgm);
      }
      this.headerBar.onBackClicked = () => {
        this.revertChanges(
          this.systems.activeSystem.id,
          this.es.getComponentBeforeModification(TEditableComponent.Area),
          this.es.getComponentBeforeModification(TEditableComponent.Output),
          this.es.getComponentBeforeModification(TEditableComponent.Helper1)
        );
      };
    }
    if ( this.canEdit ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
      this.footerBar.onButton1Click = () => {
        this.revertChanges(
          this.systems.activeSystem.id,
          this.es.getComponentBeforeModification(TEditableComponent.Area),
          this.es.getComponentBeforeModification(TEditableComponent.Output),
          this.es.getComponentBeforeModification(TEditableComponent.Helper1)
        );
        if ( this.backUrlByRef === '*' ) {
          history.back();
        } else {
          this.router.navigate([this.backUrlByRef], { relativeTo: this.r });
        }
      };
      this.footerBar.onButton2Click = () => {
        this.onConfirm();
      };
    }
    this.background.setGray();
    if (this.systems.activeSystem.hwType === 'SP3') {
      this.maxAreaLen = 23;
    }
  }

  ngOnInit(): void {}

  public onConfirm() {
    if ( !this.canEdit ) { return; }
    if (this.localArea.queue_no < 1 || this.localArea.queue_no > 255) {
      this.toaster.postError(this.trans('systems.errors.badAreaNumber'));
      return;
    }
    let area: TAreaData = this.systems.activeSystem.areas.find((a) => a.queue_no === this.localArea.queue_no);
    const isNew = area === undefined;
    if (this.localArea.name === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('systems.labels.areaName')));
      return;
    }
    if (isNew) {
      area = this.localArea;
    } else {
      area.name = this.localArea.name;
      area.queue_no = this.localArea.queue_no;
    }
    if (this.areaHasPgm && !this.showPgmData) {
      // Nuzymeta varna
      const pgm = this.systems.activeSystem.pgms.find((p) => p.queue_no === this.localPgm.queue_no);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (pgm.control_area = false), (pgm.area_id = 0);
      pgm.area_no = 0;
      this.areaHasPgm = false;
      this.localPgm.control_area = false;
      this.localPgm.area_id = 0;
      this.localPgm.area_no = 0;
    } else if (this.areaHasPgm) {
      const pgms = this.systems.activeSystem.pgms;
      for (const iPgm of pgms) {
        // nuresetinam visus esamus pgm, kurie priskirti sriciai.
        if (iPgm.area_id === this.localArea.id) {
          iPgm.area_id = 0;
          iPgm.area_no = 0;
          iPgm.control_area = false;
        }
      }
      // o dabar priskiriam aktualu
      const pgm = this.systems.activeSystem.pgms.find((p) => p.queue_no === this.localPgm.queue_no);
      pgm.pulseTime = this.localPgm.pulseTime;
      pgm.control_area = true;
      pgm.area_id = area.id;
      pgm.area_no = area.queue_no;
      pgm.type = this.localPgm.type;
    }

    if (isNew) {
      this.addArea();
    } else {
      this.editArea();
    }
    if ( this.backUrlByRef === '*' ) {
      history.back();
    } else {
      this.router.navigate([this.backUrlByRef], { relativeTo: this.r });
    }
  }

  public pgmModeSelect(mode: string) {
    if ( !this.canEdit ) { return; }
    this.localPgm.type = mode;
  }

  public selectPgm() {
    if ( !this.canEdit ) { return; }
    const slItems: DropdownItem<number>[] = [{ value: 0, label: this.trans('systems.labels.none') }];
    const pgms = this.systems.activeSystem.pgms.filter((p) => !p.control_area);
    for (const iPgm of pgms) {
      slItems.push({
        value: iPgm.id,
        label: iPgm.name,
      });
    }
    this.pp.showSlideout<DropdownItem<number>>(
      {
        headerText: this.trans('systems.labels.assignOutput'),
        items: slItems,
        onSubmit: (res) => {
          const iPgm = this.systems.activeSystem.pgms.find((p) => p.id === res.value);
          if (this.es.getId(TEditableComponent.Helper1) !== -1) {
            this.revertHelper();
          }
          if (iPgm === undefined) {
            this.areaHasPgm = false;
            return;
          }
          this.es.beginComponentEdit(TEditableComponent.Helper1, iPgm.id, iPgm);
          iPgm.control_area = true;
          iPgm.area_id = this.localArea.id;
          if (this.localPgm !== null) {
            this.localPgm.control_area = false;
            this.localPgm.area_id = null;
            this.localPgm.area_no = null;
          }
          this.localPgm = iPgm;
          this.areaHasPgm = true;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  private revertHelper() {
    const helper: PgmData = this.es.getComponentBeforeModification(TEditableComponent.Helper1);
    const found = this.systems.activeSystem.pgms.find((p) => p.id === helper.id);
    found.type = helper.type;
    found.pulseTime = helper.pulseTime;
    found.control_area = helper.control_area;
    found.area_id = helper.area_id;
    found.area_no = helper.area_no;
  }

  private addArea() {
    const activeSystemId = this.systems.activeSystem.id;
    const area = this.localArea; // Pasilaiko objekta.
    if (!this.areaHasPgm && this.es.getId(TEditableComponent.Helper1) !== -1) {
      this.revertHelper();
    }
    if (!this.areaHasPgm && this.localPgm !== null) {
      this.localPgm.area_id = null;
      this.localPgm.control_area = false;
      this.localPgm = null;
    }
    const pgm = this.localPgm;
    const helperBefore = this.es.getComponentBeforeModification(TEditableComponent.Helper1);
    this.systems.activeSystem.areas.push(this.localArea); // tinka kai areas yra tiesiog array
    this.api.post('/add/area', { system_id: activeSystemId, area, pgm: this.localPgm }, true).subscribe(
      (result) => {
        if (!result.success) {
          this.revertAreaAddition(activeSystemId, area, helperBefore);
          this.toaster.postError(result.error);
        } else {
          area.id = result.area_id;
          if (pgm !== null) {
            pgm.area_id = result.area_id;
          }
          this.systemAreaService.ingestAreaData(area, activeSystemId); // būtinas kai areas yra property su get; set;
          if (this.systems.activeSystem.id === activeSystemId) {
            this.systems.setCurrentSystem(this.systems.activeSystem);
          }
        }
      },
      (error) => {
        this.toaster.postError(this.trans('systems.errors.areaSaveFailed'));
        this.revertAreaAddition(activeSystemId, area, helperBefore);
      }
    );
  }

  private editArea() {
    const systemId = this.systems.activeSystem.id;
    if (!this.areaHasPgm && this.es.getId(TEditableComponent.Helper1) !== -1) {
      this.revertHelper();
    }
    if (!this.areaHasPgm && this.localPgm !== null) {
      this.localPgm.area_id = null;
      this.localPgm.control_area = false;
      this.localPgm = null;
    }
    const areaBefore = this.es.getComponentBeforeModification(TEditableComponent.Area);
    const pgmBefore = this.es.getComponentBeforeModification(TEditableComponent.Output);
    const helperBefore = this.es.getComponentBeforeModification(TEditableComponent.Helper1);
    this.api
      .post(
        '/edit/area',
        {
          system_id: this.systems.activeSystem.id,
          area: this.localArea,
          pgm: this.localPgm,
        },
        true
      )
      .subscribe(
        (result) => {
          if (!result.success) {
            this.revertChanges(systemId, areaBefore, pgmBefore, helperBefore);
            this.toaster.postError(result.error);
          } else {
            this.systems.setCurrentSystem(this.systems.activeSystem);
          }
        },
        (error) => {
          this.revertChanges(systemId, areaBefore, pgmBefore, helperBefore);
          this.toaster.postError(this.trans('systems.errors.areaSaveFailed'));
        }
      );
  }

  /**
   * Ištrina pridėtą sritį ir atstato PGM būseną.
   *
   * @param area Sritis, kurią reikia pašalinti
   * @param pgm PGM kuriam reikia panaikinti sąryšį su sritimi
   */
  private revertAreaAddition(systemId: number, area: TAreaData, pgm: PgmData) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    this.log('Revert area addition.');
    system.areas = system.areas.filter((a) => a.queue_no !== area.queue_no);
    if (pgm !== null) {
      pgm.control_area = false;
      pgm.area_id = null;
      pgm.area_no = 0;
    }
    if (this.systems.activeSystem.id === system.id) {
      this.systems.setCurrentSystem(system);
    }
  }

  /**
   * Gali būti, kad sričiai buvo priskirtas pgm, nors dabar išeiname iš lango neišsaugoję nieko.
   */
  private revertPgmForNewArea() {
    if (this.localPgm !== null && this.localPgm.control_area) {
      this.log('revert pgm for new area');
      for (const iPgm of this.systems.activeSystem.pgms) {
        if (iPgm.queue_no === this.localPgm.queue_no) {
          iPgm.control_area = false;
          iPgm.area_no = 0;
          iPgm.area_id = 0;
          break;
        }
      }
    }
  }

  private revertChanges(systemId: number, areaBefore: TAreaData, pgmBefore: PgmData, helperBefore: PgmData) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const area = system.areas.find((a) => a.id === areaBefore.id);
    if (area !== undefined) {
      area.name = areaBefore.name;
    }
    if (pgmBefore !== null) {
      const pgm = system.pgms.find((p) => p.id === pgmBefore.id);
      if (pgm !== undefined) {
        pgm.pulseTime = pgmBefore.pulseTime;
        pgm.control_area = pgmBefore.control_area;
        pgm.area_id = pgmBefore.area_id;
        pgm.area_no = pgmBefore.area_no;
        pgm.type = pgmBefore.type;
      }
    }
    if (helperBefore !== null) {
      const pgm = system.pgms.find((p) => p.id === helperBefore.id);
      if (pgm !== undefined) {
        pgm.pulseTime = helperBefore.pulseTime;
        pgm.control_area = helperBefore.control_area;
        pgm.area_id = helperBefore.area_id;
        pgm.area_no = helperBefore.area_no;
        pgm.type = helperBefore.type;
      }
    }
  }

  public queueNumberIsReadOnly(): boolean {
    return true;
  }

  public areaIsPanicButtonsArea(): boolean {
    return this.localArea.status === this.panicButtonsAreaStatus;
  }
}
