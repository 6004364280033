import { environment as env } from "src/environments/environment";
import { AppRegion, UrlProtocol } from "src/environments/environment.types";

export class AppSettings {
    /** "http://" arba "https://" */
//    public static protocol = 'https://';
    /** Host name dalis viso URL. pvz 'www.protegus.eu' */
//    public static backEndHost = 'ruejai.protegus.app';
    /** Jeigu backend turi custom port, tai jį reikia įrašyti čia */
//    public static port = '';
    /** Jeigu vartotojams pateikiama nuoroda į "manual", tai ją reiktų įrašyti čia. Tai turi būti pilnas URL. */
//    public static manualUrl = '';
    /** Url dalis pagal kurią kreipiamasi į backend. Ši dalis bus pridedama prie pilno backend URL. Privalo prasidėti "/" simboliu.
     * pvz /v3/api
     */
//    public static apiPath = '/v3/api';
    /** Rodo "Log" mygtuką bei vykdo veiksmų loginimą. Naudinga tik beta testavimams. Production turi būti "false" */
    public static showLogger = false;
    /** Nurodo ar vykdyti loginimą į "console" */
    public static logToConsole = false;
    /** Web aplikacijos pavadinimas, kuris bus rodomas naršyklių tab'e. pvz "Protegus" */
    public static title = "RUEJAI"
    /** Fizinis įmonės adresas */
    public static address = 'Draugystės g. 17, LT-51229 Kaunas, Lietuva';
    /** Įmonės email, kuriuo galima susisiekti */
    public static email = "service@trikdis.com"
    /** Įmonės telefonas, kuriuo galima paskambinti */
    public static phone = '+370 37 408040';
    /** Įmonės web puslapis (tai nėra web app!) */
    public static companyUrl = 'trikdis.com';
    /** Nurodo ar rodyti google login mygtuka */
    public static googleLoginSupported = false;
    /** Nurodo ar rodyti apple login mygtuka */
    public static appleLoginSupported = false;
    /** Nurodo ar soniniame meniu bus matoma Help dalis */
    public static showHelp = true;
    /** Nurodo ar rodyti Advanced meniu */
    public static showConfig = true;
    /** Nurodo ar reikalingas regioninis peradresavimas */
    public static regionsEnabled = true;
    /** Palaikomi regionai */
    public static regions: AppRegion[] = [
        { id: 'ruejai', backEndHost: 'ruejai.protegus.app', port: '', protocol: UrlProtocol.https, gmtOffsetStart: 0, gmtOffsetEnd: 0 },
    ];
    /** Nurodo ar galima kurti vartotojus, kurie dar neegzistuoja */
    public static canAutoCreateAccounts = true;
    /** Nurodo ar super adminui reikia krauti pilną vartotojų sąrašą */
    public static loadAllUsersForSU = false;
    /** Nurodo ar super adminui reikia krauti pilną sistemų sąrašą */
    public static loadAllSystemsForSU = true;
    /** Nurodo ar tik Super admin gali pridėti/ištrinti vartotojus. Esant false tą gali daryti visi. */
    public static onlySUcanAddRemoveUsers = false;
    /** Nurodo ar galima trinti sistemą esant sistemų sąraše */
    public static canDeleteSystemFromList = false;
    /** Nurodo ar galima trinti vartotojus esant vartotojų sąraše */
    public static canDeleteUserFromList = false;
    /** Nurodo ar turi veikti ribojimų sistema. (Kamerų kiekis, sistemų kiekis ir t.t.) */
    public static limitationSystemEnabled = true;
    /** Nurodo ar reikia rodyti versiją */
    public static showAppVersion = false;
    public static appVersionMajor = 2;
    public static appVersionMinor = 0;
    public static appVersionBuild = 127;
    public static appVersionDate = 1694433359;
    /** Nurodo ar super admin gali peradresuoti modulius tarp regionų */
    public static deviceTransferEnabled = true;
    /** Nurodo ar vartotojai gali peradresuoti modulius tarp v1 ir v2 versijų */
    public static deviceTransferToAppEnabled = true;
    public static primaryColor = "#0070A7"
    public static secondaryColor = "#EF476F"
    /** Nurodo kokį pavadinimą reikės rodyti Wifi GV moduliams */
    public static wifiGateDevice37Name: 'WP17' | 'ZEM-CWI' | 'AP-114' = 'WP17';
    /** sentry glitch */
    public static sentry = null;
    public static appCopyright = 'UAB Trikdis Copyright &reg; Trikdis EU 018535771 Protegus &reg';
    /** Nurodo ar įkompiliuojamas company desktop */
    public static companyDesktopEnabled = true;
    public static companyRegistrationSupported = true;
    public static googleClientIdWeb = "131091336687-8kg1fhrbstoakft532hajc6kmke10ft3.apps.googleusercontent.com";
    /** Google reCAPTCHA siteKey */
    public static greSiteKey = "";
    /** Pasakom ar siusti js error į api serveri */
    public static localReport = true;
    public static readonly production = false;
}

export const environment = AppSettings;
