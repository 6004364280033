import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app-settings';
import { DateService } from 'src/app/services/date.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
})
export class VersionComponent extends LanguageAware implements OnInit {
  public oldVersion = null;
  public newerVersion = null;

  constructor(cdRef: ChangeDetectorRef, public dt: DateService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('help.appVersionTitle'),
      backUrl: '*',
    });
    console.log('current ver', AppSettings.appVersionMajor, AppSettings.appVersionMinor, AppSettings.appVersionBuild, AppSettings.appVersionDate);
    this.background.setGray();
    const savedOldVersion = localStorage.getItem('last_version');
    console.log('old version', savedOldVersion);
    const savedOldBuild = localStorage.getItem('last_version_build');
    console.log('olg build', savedOldBuild);
    if (savedOldBuild !== null && parseInt(savedOldBuild, 10) !== AppSettings.appVersionBuild) {
      this.oldVersion = JSON.parse(savedOldVersion);
    }

    localStorage.setItem('last_version_build', AppSettings.appVersionBuild.toString());
    localStorage.setItem('last_version',
      JSON.stringify({
        major: AppSettings.appVersionMajor,
        minor: AppSettings.appVersionMinor,
        build: AppSettings.appVersionBuild,
        date: AppSettings.appVersionDate,
      })
    );

    console.log('ver from server', this.us.appVersionDetails);
    if (this.us.appVersionDetails !== null && AppSettings.appVersionBuild < this.us.appVersionDetails.build) {
      this.newerVersion = this.us.appVersionDetails;
    }
  }

  ngOnInit(): void {}

  public getTitle(): string {
    return AppSettings.title;
  }

  public getCurrentVersion(): string {
    return AppSettings.appVersionMajor + '.' + AppSettings.appVersionMinor;
  }

  public getBuild(): string {
    return AppSettings.appVersionBuild.toString();
  }

  public getDate(): number {
    return AppSettings.appVersionDate;
  }

  public clickUpdate() {
    location.reload();
  }
}
