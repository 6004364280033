import { Injectable } from '@angular/core';
import { GlobalService } from '../services/global.service';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../api/user.service';
import { PermissionRole } from 'src/api/v3/common';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuardService implements CanActivate {
  constructor(private us: UserService, private router: Router, private g: GlobalService) {}

  canActivate() {
    if ( !this.us.currentUser ) {
      this.us.loadUserDataFromStorage();
    }
    if ( this.us.currentUser?.permissions.role !== PermissionRole.GeneralUser && this.us.currentUser?.permissions.role !== PermissionRole.Installer ) {
      this.router.navigate(this.g.getHomeUrl());
      return false;
    }
    return true;
  }
}
