import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-cp-global',
  templateUrl: './cp-global.component.html',
  styleUrls: ['./cp-global.component.scss']
})
export class CpGlobalComponent extends LanguageAware implements OnInit {

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
  }

  ngOnInit(): void {
  }

}
