import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent extends LanguageAware implements OnInit, OnDestroy {
  public isDesktop = this.platform.isDesktop();
  public inputChanged = false;
  public isRequestLoading = false;
  public delayDuration = 2000;
  public modifiedSettings = new Set<{ field: string; value: string }>();
  public originalTextual = JSON.stringify(this.us.currentUser.settings.textual);
  public originalToggle = JSON.stringify(this.us.currentUser.settings.togglable);
  public footerButtons = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: async () => { this.onSaveClick(); }
    },
    { label: this.trans('general.cancel'),  callback: () => this.navigateBack() },
  ];

  constructor(
    cdRef: ChangeDetectorRef,
    private pp: PopupService,
    private router: Router,
    private messagebox: MessageboxService,
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('settings.global.title'),
      backUrl: this.g.getMobileSettingsUrl().join('/'),
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.refresher.doSilentRefresh();
  }

  public saveToggle(field: string, value: boolean) {
    const that = this;
    const data = {};
    data[field] = value;
    this.api.post('/settings', data, true).subscribe((result) => {
      if (!result.success) {
        that.toaster.postError(result.error);
        const found = that.us.currentUser.settings.togglable.find((t) => t.field === field);
        if (found !== undefined) {
          found.value = !value;
        }
      }
    });
  }

  public saveTextual(field: string, name: string, value: string) {
    const that = this;
    this.pp.showPopup(
      {
        headerText: name,
        field1: {
          maxLen: 255,
          oldValue: value,
        },
        onSubmitString: (res) => {
          const found = that.us.currentUser.settings.textual.find((t) => t.field === field);
          if (found !== undefined) {
            found.value = res;

            const data = {};
            data[found.field] = found.value;
            that.api.post('/settings', data, true).subscribe((result) => {
              if (!result.success) {
                that.toaster.postError(result.error);
                found.value = value;
              }
            });
          }
        },
      },
      PopupType.String
    );
  }

  public onInputChange(field?: string, value?: any): void {
    this.inputChanged = this.originalTextual !== JSON.stringify(this.us.currentUser.settings.textual) ||
      this.originalToggle !== JSON.stringify(this.us.currentUser.settings.togglable);

    const existingField = Array.from(this.modifiedSettings).find(item => item.field === field);
    if (this.inputChanged && !existingField) {
      this.modifiedSettings.add({ field, value });
    } else if (!this.inputChanged && existingField) {
      this.modifiedSettings.delete(existingField);
    }
  }

  public toggleValueChange(field?: string, value?: any) {
    const toggleItem = this.us.currentUser.settings.togglable.find(item => item.field === field);
    toggleItem.value = value;
    this.onInputChange(field, value);
  }

  private async onSaveClick(): Promise<void> {
    this.isRequestLoading = true;
    this.saveSettingChanges();
    setTimeout(() => {
      this.isRequestLoading = false;
    }, 200);
    await new Promise<void>(() => setTimeout(() => {
      this.onInputChange();
    }, this.delayDuration));
  }

  private saveSettingChanges() {
    const data = {};
    this.modifiedSettings.forEach(item => {
      data[item.field] = item.value;
    });
    this.api.post('/settings', data, true).subscribe((result) => {
      if (!result.success) {
        this.openErrorMessageBox(result.error);
      } else {
        this.setSettingsAsOriginal();
      }
    });
  }

  private setSettingsAsOriginal() {
    this.originalToggle = JSON.stringify(this.us.currentUser.settings.togglable);
    this.originalTextual = JSON.stringify(this.us.currentUser.settings.textual);
    this.modifiedSettings.clear();
  }

  private openErrorMessageBox(error?: string) {
    this.messagebox.open({
      buttons: this.messagebox.buttons.Ok,
      headerText: this.trans('general.titleError'),
      iconType: this.messagebox.iconType.Error,
      messageContent: error ? error : this.trans('general.errors.errorOccurred')
    });
  }

  private navigateBack() {
    this.router.navigate([companyRootRoute, 'settings']);
  }

}
