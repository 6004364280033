import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-reread',
  templateUrl: './reread.component.html',
  styleUrls: ['./reread.component.scss'],
})
export class RereadComponent extends LanguageAware implements OnInit, OnDestroy {
  constructor(
    cdRef: ChangeDetectorRef,
    private ds: DeviceService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'SystemReread';
    const ref = localStorage.getItem('ref');
    this.headerBar.showHeader({
      backUrl: ref === 'conf' ? '../system-configuration' : '*',
      headerText: this.trans('systems.titles.syncSystem'),
      activatedRoute: this.r,
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.miniStatus.hide();
    this.ds.stopReRead();
  }

  public reReadClick() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }

    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.ds.performReRead(
      this.systems.activeSystem.id,
      (m) => {
        this.toaster.postSuccess(m);
        this.miniStatus.hide();
      },
      (e) => {
        if (e !== '') {
          this.toaster.postError(e);
        }
        this.miniStatus.hide();
      },
      (s, p) => {
        this.miniStatus.show(s);
      }
    );
  }
}
