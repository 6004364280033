import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-quick-cameras',
  templateUrl: './quick-cameras.component.html',
  styleUrls: ['./quick-cameras.component.scss'],
})
export class QuickCamerasComponent extends LanguageAware implements OnInit {
  public readonly cameraCount = this.systems.activeSystem.cameras.length;
  public readonly cameraLimit = this.us.currentUser.limits.camera_count;
  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.myCameras'),
      backUrl: '*',
      showSettings: this.systems.activeSystemHelper.can.edit.cameras(),
    });
    this.background.setGray();
    const that = this;
    this.headerBar.onActionButtonClicked = () => {
      that.router.navigate(['../camera-list'], { relativeTo: this.r });
    };
  }

  ngOnInit(): void {}

  public addCamera() {
    localStorage.setItem('ref', 'quick');
    this.es.beginComponentEdit(TEditableComponent.Camera, 0);
    this.router.navigate(['../add-camera'], { relativeTo: this.r });
  }
}
