import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/api/request.service';
import { LoginService } from 'src/app/login/login.service';
import { LanguageService } from 'src/app/services/language.service';
import { customPasswordValidatorBuilder, ValidatorBuilder } from 'src/app/ui/validator';

@Component({
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  public isLoading = false;
  public val = new ValidatorBuilder<{ password: string }>().custom(
    'password',
    customPasswordValidatorBuilder({
      tooShort: () => this.trans('auth.errors.passwordTooShort'),
      noLowerCase: () => this.trans('auth.errors.passwordNoLowercase'),
      noUpperCase: () => this.trans('auth.errors.passwordNoUppercase'),
      noNumber: () => this.trans('auth.errors.passwordNoNumber'),
    })
  ).build();
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(public login: LoginService, private router: Router, private req: RequestService, private ls: LanguageService) {}

  ngOnInit(): void {
    if (!this.login.passwordToken) {
      this.router.navigate(['login', 'password-reset', 'email']);
    }
  }

  public async next() {
    if (!(await this.val.validate(this.login))) {return;}
    this.isLoading = true;
    const res = await this.req.login.passwordResetComplete({ password: this.login.password, token: this.login.passwordToken }).toPromise();
    if (res.success === false) {
      this.val.pushError('password', res.error);
      this.isLoading = false;
      return;
    }
    this.login.passwordToken = undefined;
    this.login.password = '';
    this.router.navigate(['login'], { replaceUrl: true });
  }
}
