<div class="wrap">
  <div class="popupSection">
    <div class="popupSectionHeadingItem" [routerLink]="['profile']" (click)="showSettingsChange.emit(false)">
      <div class="userIcon"></div>
      <p class="text">{{lang.get('users.settings.title')}}</p>
      <div class='icon' [inlineSVG]="'assets/images/forward.svg'"></div>
    </div>
    <div>
      <p class="changeAccount">{{lang.get('users.labels.changeAccount')}}</p>
    </div>
    <app-account-switcher></app-account-switcher>
    <div class="popupSectionItem">
      <div class='addIcon' [inlineSVG]="'assets/images/add.svg'"></div>
      <a [routerLink]="['/login/email']" class="text">{{lang.get('users.buttons.addAccount')}}</a>
      <div class='icon' [inlineSVG]="'assets/images/forward.svg'"></div>
    </div>
  </div>
</div>
