import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastType } from 'src/app/models/toaster-type';
import { DisableMobileDataPopupComponent } from 'src/app/popups/disable-mobile-data-popup/disable-mobile-data-popup.component';
import { DeviceService } from 'src/app/services/device.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { WifiService } from 'src/app/services/wifi.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-device-ap-connect',
  templateUrl: './device-ap-connect.component.html',
  styleUrls: ['./device-ap-connect.component.scss'],
})
export class DeviceApConnectComponent extends LanguageAware implements OnInit, OnDestroy {
  private connectionListener: Subscription;
  public connecting = false;
  public success = false;
  private deviceMac = '';
  private cancelling = false;
  private popup: DisableMobileDataPopupComponent = null; // nenaudojamas dabar?

  constructor(
    cdRef: ChangeDetectorRef,
    private wfs: WifiService,
    private router: Router,
    public ds: DeviceService,
    private ns: NewSystemService,
    private zone: NgZone,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      backUrl: '../nearby-device-found',
      activatedRoute: this.r,
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      if (this.wfs.wasMobileDataOn()) {
        if ( this.popup ) { this.popup.onOk = () => { this.exit(); }; }
      } else {
        this.exit();
      }
    };
    this.background.setGray();
    this.connecting = true;
    this.connectionListener = this.wfs.onDeviceWifiConnectionChange.subscribe((status: string) => {
      this.zone.run(() => {
        this.wifiDeviceConnectionResult(status);
      });
    });
    this.wfs.stopWifiDiscovery();
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.connectionListener?.unsubscribe();
  }

  private exit() {
    if ( this.popup ) { this.popup.close(); }
    this.wfs.stopWifiDiscovery();
    this.wfs.selectDevice('');
    this.wfs.selectOther('');
    this.miniStatus.hide();
    this.router.navigate(['../new-system'], { relativeTo: this.r, replaceUrl: true });
  }

  private exitToOfflineScreen() {
    if ( this.popup ) { this.popup.close(); }
    this.wfs.stopWifiDiscovery();
    this.wfs.selectDevice('');
    this.wfs.selectOther('');
    this.miniStatus.hide();
    this.router.navigate(['../new-system-offline'], { relativeTo: this.r, replaceUrl: true });
  }

  private wifiDeviceConnectionResult(status: string) {
    if (status.startsWith('OK')) {
      const step = status.substr(2);
      if (step === 'device_connected') {
        this.l.log(this.trans('systems.statuses.setDeviceWifi'));
      } else if (step.startsWith('mac_')) {
        const mac = step.substring(4);
        this.l.log(this.trans('systems.statuses.waitForDeviceWifi'));
        this.deviceMac = mac === '' ? this.ns.getUID() : mac;
      } else if (step === 'connected') {
        this.l.log(this.trans('systems.statuses.finishing'));
      } else if (step === 'finished') {
        console.log('FINISHED');
        console.log('canceling?', this.cancelling);
        if (!this.cancelling) {
          this.ns.setUID(this.deviceMac);
          console.log('Einam i sistemos duomenis');
          this.miniStatus.hide();
          if ( this.us.currentUser.permissions.permissions.dev_setup_templates.execute ) {
            this.router.navigate(['../select-template'], { relativeTo: this.r, replaceUrl: true });
          } else {
            this.router.navigate(['../add-system-details'], { relativeTo: this.r, replaceUrl: true });
          }
        }
        this.cancelling = false;
      } else if (step === 'finished_after_failure') {
        this.cancelling = false;
      } else if (step.startsWith('check_device_')) {
        const secondsToCount = step.substring('check_device_'.length);
        this.miniStatus.show(this.trans('systems.statuses.checkingForDeviceOnline'), parseInt(secondsToCount, 10));
      }
    } else if (status.startsWith('ERR')) {
      const step = status.substr(3);
      if (step === '') {
        this.toaster.postError(this.trans('systems.errors.failedDeviceWifiConnection'));
        this.miniStatus.hide();
      } else if (step === 'device_connected') {
        this.toaster.postError(this.trans('systems.errors.failedDeviceWifiConnection'));
        this.miniStatus.show(this.trans('general.pleaseWait'));
        this.cancelling = true;
      } else if (step === 'wifi_saving') {
        this.toaster.postError(this.trans('systems.errors.failedDeviceWifiSave'));
        this.miniStatus.show(this.trans('general.pleaseWait'));
        this.cancelling = true;
      } else if (step === 'connected_wifi') {
        this.toaster.postError(this.trans('systems.errors.failedToConnectToWifi'));
        this.miniStatus.show(this.trans('general.pleaseWait'));
        this.cancelling = true;
      } else if (step === 'connected_protegus') {
        this.toaster.postError(this.trans('systems.errors.failedToconnectToService'));
        if (this.platform.isAndroid()) {
          this.miniStatus.show(this.trans('general.pleaseWait'));
          this.cancelling = true;
        } else if (this.platform.isApple()) {
          this.miniStatus.hide();
        }
      } else if (step === 'return') {
        this.toaster.postError(this.trans('systems.errors.failedToGetBack'));
        this.miniStatus.hide();
        this.cancelling = false;
      } else if (step === 'lost_connection') {
        this.toaster.postError(this.trans('systems.errors.failedDeviceWifiLost'));
        this.miniStatus.hide();
        this.cancelling = false;
      } else if (step === 'not_in_cloud') {
        this.toaster.postError(this.trans('system.errors.deviceNotInCloud'));
        this.exitToOfflineScreen();
      }
    } else if (status.startsWith('INFO')) {
      this.toaster.post(status.substr(4), ToastType.Warning, true, 10000);
      this.miniStatus.hide();
    }
  }
}
