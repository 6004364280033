import { ApplicationRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { LanguageService } from 'src/app/services/language.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';
import { ConfigsWithCancelOrCloseButton, ConfigsWithSecondButton, DefaultSetupConfigs, LinkStyleConfigs, MessageBoxButtons, MessageBoxIcon } from './messagebox-config';

@Component({
  selector: 'app-messagebox',
  templateUrl: './messagebox.component.html',
  styleUrls: ['./messagebox.component.scss'],
})
export class MessageboxComponent implements OnInit {
  public MessageBoxButtons = MessageBoxButtons;
  public MessageBoxIcon = MessageBoxIcon;
  public LinkStyleConfigs = LinkStyleConfigs;
  public DefaultSetupConfigs = DefaultSetupConfigs;
  public ConfigsWithCancelOrCloseButton = ConfigsWithCancelOrCloseButton;
  public ConfigsWithSecondButton = ConfigsWithSecondButton;
  public buttonClassSelector: string;
  public youtubeVideoUrl: SafeResourceUrl;

  @Input() buttons: MessageBoxButtons = MessageBoxButtons.Ok;
  @Input() iconType: MessageBoxIcon | undefined;
  @Input() button1Text: string | undefined;
  @Input() button2Text: string | undefined;
  @Input() showCloseButton = true;
  @Input() canCloseByOverlay = false;
  @Input() messageContent: SafeHtml | string | undefined;
  @Input() headerText: string | undefined;
  @Input() youtubeVideoId: string | undefined;
  @Input() messageboxComponentRef: ComponentRef<MessageboxComponent>;
  @Input() alignCenter = false;
  @Output() okClicked: EventEmitter<any> = new EventEmitter();
  @Output() yesClicked: EventEmitter<any> = new EventEmitter();
  @Output() noClicked: EventEmitter<any> = new EventEmitter();
  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() button1Clicked: EventEmitter<any> = new EventEmitter();
  @Output() button2Clicked: EventEmitter<any> = new EventEmitter();
  @Output() messageboxClose: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('basePopup') basePopup: BasePopupComponent;

  constructor(public l: LanguageService, private appRef: ApplicationRef, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.buttonClassSelector = this.LinkStyleConfigs.includes(this.buttons) ? 'msgb_button_link' : 'msgb_button';
    this.youtubeVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.youtubeVideoId);
  }

  public clickOk() {
    this.okClicked.emit();
    this.messageboxClose.emit(true);
    this.basePopup.hidePopup();
    this.removeMessageboxFromView();
  }

  public clickYes() {
    this.yesClicked.emit();
    this.messageboxClose.emit(true);
    this.basePopup.hidePopup();
    this.removeMessageboxFromView();
  }

  public clickNo() {
    this.noClicked.emit();
    this.messageboxClose.emit(true);
    this.basePopup.hidePopup();
    this.removeMessageboxFromView();
  }

  public clickCancel() {
    this.cancelClicked.emit();
    this.messageboxClose.emit(true);
    this.basePopup.hidePopup();
    this.removeMessageboxFromView();
  }

  public clickButton1() {
    this.button1Clicked.emit();
    this.messageboxClose.emit(true);
    this.basePopup.hidePopup();
    this.removeMessageboxFromView();
  }

  public clickButton2() {
    this.button2Clicked.emit();
    this.messageboxClose.emit(true);
    this.basePopup.hidePopup();
    this.removeMessageboxFromView();
  }

  public basePopupCancelled() {
    this.cancelClicked.emit();
    this.messageboxClose.emit(true);
    this.removeMessageboxFromView();
  }

  public show() {
    this.basePopup.show();
  }

  private removeMessageboxFromView(): void {
    if(this.messageboxComponentRef) {
      this.appRef.detachView(this.messageboxComponentRef.hostView);
      this.messageboxComponentRef.destroy();
    }
  }
}
