<div class="sticky" *ngIf="desktopView; else isMobile">
  <div class="sectionHeader">
    <h4 class="titleText">{{ isNew ? trans('events.settings.titleAdd') : trans('events.settings.titleEdit') }}</h4>
    <ng-container *ngIf="!isNew && canDelete">
      <div class="delete" (click)="onDeleteClick()">
        <h4>{{trans('general.delete')}}</h4>
        <div class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
      </div>
    </ng-container>
  </div>
  <div class="editSection">
    <ng-container *ngIf="isNew; else editEventDescItem">
      <app-string-input class="{{canEdit ? '' : 'is_disabled'}}" [label]="trans('events.settings.labels.nameEvent')" [(value)]="newEventDescItem.default_name" [maxLength]="255" [disabled]="!canEdit">
        <app-form-error *ngIf="val.hasError('default_name')">{{ val.getError('default_name') }}</app-form-error>
      </app-string-input>
      <app-name-value [nameText]="trans('events.settings.labels.active')" [isEnabled]="canEdit">
        <app-toggle-button [buttonId]="'active'" [initialState]="true" (toggled)="newEventDescItem.active = $event.checked"></app-toggle-button>
      </app-name-value>
      <app-name-value [nameText]="trans('events.settings.labels.areaEvent')" [isEnabled]="canEdit">
        <app-toggle-button [buttonId]="'area'" [initialState]="true" (toggled)="newEventDescItem.area_event = $event.checked"></app-toggle-button>
      </app-name-value>
      <div class="{{canEdit ? '' : 'is_disabled'}}">
        <app-dropdown [label]="trans('events.settings.labels.qualifier')" [items]="[{label: trans('events.settings.qualifier.E'), value: 'E'}, {label: trans('events.settings.qualifier.R'), value: 'R'}, {label: '-', value: '-'}]" [(value)]="newEventDescItem.qualifier" [disabled]="!canEdit">
          <app-form-error *ngIf="val.hasError('qualifier')">{{ val.getError('qualifier') }}</app-form-error>
        </app-dropdown>
        <app-string-input [label]="trans('events.settings.labels.code')" [(value)]="newEventDescItem.event_code" [maxLength]="3" [disabled]="!canEdit">
          <app-form-error *ngIf="val.hasError('event_code')">{{ val.getError('event_code') }}</app-form-error>
        </app-string-input>
        <app-dropdown [label]="trans('events.settings.labels.reaction')" [(value)]="newEventDescItem.reaction_id" (valueChange)="newEventDescItem.reaction_id = $event" [items]="getReactionsList()" [disabled]="!canEdit">
          <app-form-error *ngIf="val.hasError('reaction_id')">{{ val.getError('reaction_id') }}</app-form-error>
        </app-dropdown>
      </div>
    </ng-container>
    <ng-template #editEventDescItem>
      <app-string-input class="{{canEdit ? '' : 'is_disabled'}}" [label]="trans('events.settings.labels.nameEvent')" [(value)]="eventDescItem.default_name" (valueChange)="onValueChange()" [maxLength]="255" [disabled]="!canEdit">
        <app-form-error *ngIf="val.hasError('default_name')">{{ val.getError('default_name') }}</app-form-error>
      </app-string-input>
      <app-name-value [nameText]="trans('events.settings.labels.active')" [isEnabled]="canEdit">
        <app-toggle-button [buttonId]="'active'" [initialState]="eventDescItem.active" (toggled)="eventDescItem.active = $event.checked ? 1 : 0; onValueChange()"></app-toggle-button>
      </app-name-value>
      <app-name-value [nameText]="trans('events.settings.labels.areaEvent')" [isEnabled]="canEdit">
        <app-toggle-button [buttonId]="'area'" [initialState]="eventDescItem.area_event" (toggled)="eventDescItem.area_event = $event.checked; onValueChange()"></app-toggle-button>
      </app-name-value>
      <div class="{{canEdit ? '' : 'is_disabled'}}">
        <app-dropdown [label]="trans('events.settings.labels.qualifier')" [items]="[{label: trans('events.settings.qualifier.E'), value: 'E'}, {label: trans('events.settings.qualifier.R'), value: 'R'}, {label: '-', value: '-'}]" [(value)]="eventDescItem.qualifier" (valueChange)="onValueChange()" [disabled]="!canEdit">
          <app-form-error *ngIf="val.hasError('qualifier')">{{ val.getError('qualifier') }}</app-form-error>
        </app-dropdown>
        <app-string-input [label]="trans('events.settings.labels.code')" [(value)]="eventDescItem.event_code" (valueChange)="onValueChange()" [maxLength]="3" [disabled]="!canEdit">
          <app-form-error *ngIf="val.hasError('event_code')">{{ val.getError('event_code') }}</app-form-error>
        </app-string-input>
        <app-dropdown [label]="trans('events.settings.labels.reaction')" [items]="getReactionsList()" [(value)]="eventDescItem.reaction_id" (valueChange)="eventDescItem.reaction_id = $event; onValueChange()" [disabled]="!canEdit">
          <app-form-error *ngIf="val.hasError('reaction_id')">{{ val.getError('reaction_id') }}</app-form-error>
        </app-dropdown>
      </div>
    </ng-template>
  </div>
  <ng-component *ngIf="(isNew || inputChanged) && canEdit">
    <ng-template appTeleport="footer">
      <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
    </ng-template>
  </ng-component>
</div>

<ng-template #isMobile>
  <div class="white_round">
    <app-name-value [nameText]="trans('events.settings.labels.nameEvent')" (clicked)="changeName()" [isEnabled]="canEdit">{{eventDescItem.default_name}}</app-name-value>
    <app-name-value [nameText]="trans('general.enabled')" [isEnabled]="canEdit">
      <app-toggle-button [buttonId]="'cpese_enable'" [initialState]="eventDescItem.active === 1" (toggled)="eventDescItem.active = $event.checked ? 1 : 0"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('events.settings.labels.areaEvent')" [isEnabled]="canEdit">
      <app-toggle-button [buttonId]="'cpese_areae'" [initialState]="eventDescItem.area_event" (toggled)="eventDescItem.area_event = $event.checked"></app-toggle-button>
    </app-name-value>

    <div id="cpese_qual_container" class="{{canEdit ? '' : 'is_disabled'}}" (click)="changeQualifier()">
      <div id="cpese_qual">{{trans('events.settings.labels.qualifier')}}</div>
      <div *ngIf="eventDescItem.qualifier !== '-'" id="cpese_qualt"><span>{{trans('events.settings.qualifier.' + eventDescItem.qualifier)}}</span></div>
      <div id="cpese_arrow"><svg viewBox="0 0 8.402 14.697"><path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/></svg></div>
    </div>
    <app-name-value [nameText]="trans('events.settings.labels.code')" (clicked)="changeCidCode()" [isEnabled]="canEdit">{{eventDescItem.event_code}}</app-name-value>
    <app-name-value [nameText]="trans('events.settings.labels.reaction')" (clicked)="changeReaction()" [showUnderline]="false" [isEnabled]="canEdit">{{reactionText}}</app-name-value>
  </div>
  <app-popup></app-popup>
</ng-template>
