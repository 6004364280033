import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-button-double-line-arrow',
  templateUrl: './button-double-line-arrow.component.html',
  styleUrls: ['./button-double-line-arrow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonDoubleLineArrowComponent implements OnInit {
  @Input() firstLine = '';
  @Input() secondLine = '';
  @Input() withBorder = false;
  @Input() showArrow = true;
  @Input() icon = '';
  @Input() firstLineIsBold = false;
  @Input() isDisabled = false;

  constructor() {}

  ngOnInit(): void {}
}
