import { ApiRequestService } from './api-request.service';
import { LocatorService } from './locator.service';
import { SystemsService } from './systems.service';

export const sendError = (error: { message?: string; stack?: string }) => {
  if (!error) {
    console.warn('[ERROR] Buvo klaida, bet neaisku kokia, nes objektas null.');
    return;
  }
  if (error.message) {
    const lastMessage = localStorage.getItem('last_error_reported');
    if (lastMessage !== null && lastMessage === error.message) {
      return;
    }
    localStorage.setItem('last_error_reported', error.message);
  }
  const api: ApiRequestService = LocatorService.injector.get(ApiRequestService);
  const systems: SystemsService = LocatorService.injector.get(SystemsService);
  api.post('/error', { msg: error.message, stack: error.stack, activeSystem: systems.activeSystem ? systems.activeSystem.id : 0 }, true).subscribe(
    (result) => {
      // ok
    },
    () => {
      console.warn('[ERROR] Deja, nepavyko nusiųsti klaidos pranešimo.');
    }
  );
};
