import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { PlatformService } from 'src/app/api/platform.service';
import { Router } from '@angular/router';
import { GoogleService } from 'src/app/services/google.service';
import { AppleService } from 'src/app/services/apple.service';
import { AppSettings } from 'src/app-settings';
import { FcmService } from 'src/app/services/fcm.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent extends LanguageAware implements OnInit {
  private controlsEnabled = true;
  public hasAccounts = this.api.hasAccounts;
  constructor(cdRef: ChangeDetectorRef, public ps: PlatformService, private router: Router, private google: GoogleService, private apple: AppleService, private fcm: FcmService) {
    super(cdRef);
    this.tag = 'Login';
    this.headerBar.showHeader({
      showHamburger: true,
    });
    this.background.setLogin();
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().setBackButtonType('close');
    }
  }

  ngOnInit() {
    if (!this.ps.isAndroid()) {
      const userAgreed = localStorage.getItem('user-consent-agree');
      this.log('user agreed:', userAgreed);
      if (userAgreed === null || userAgreed === '0') {
        this.router.navigate(['/user-consent', 'login']);
        return;
      }
    }
  }

  public loginClick() {
    this.router.navigate(['/email']);
  }

  public googleClick() {
    if (!this.controlsEnabled) {
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    this.google.performLogin(
      (data) => {
        that.handleLogin(data, 'google');
      },
      (error) => {
        that.handleLoginFailed(error);
      }
    );
  }

  public appleClick() {
    if (!this.controlsEnabled) {
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    this.apple.performLogin(
      (data) => {
        that.handleLogin(data, 'apple');
      },
      (error) => {
        that.handleLoginFailed(error);
      }
    );
  }

  handleLogin(data, loginType: string) {
    this.controlsEnabled = true;
    this.api.setToken(data.token);
    this.us.setCurrentUser(data);
    this.us.change();
    this.us.saveCurrentUser();
    this.us.setLoginType(loginType);
    if (data.lastSystem !== undefined) {
      this.systems.setCurrentSystemFromRaw(data.lastSystem);
    }
    this.systems.loadSystems();
    this.router.navigate(this.g.getHomeUrl());
    this.fcm.requestForToken();
  }

  handleLoginFailed(error?: string) {
    this.controlsEnabled = true;
    if (error !== undefined) {
      this.toaster.postError(error);
    }
  }

  public canShowGoogle(): boolean {
    return AppSettings.googleLoginSupported;
  }

  public canShowApple(): boolean {
    return AppSettings.appleLoginSupported && this.ps.isApple() && this.ps.hasAppleMessageHandlers();
  }
}
