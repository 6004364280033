import { ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, Router } from '@angular/router';
import { BasicSuccessResponse, ErrorResponse } from 'src/api/v3/common';
import { LanguageAware } from 'src/app/general/language-aware';
import { ValidatorBuilder } from 'src/app/ui/validator';
import requests from 'src/api/v3/requests';
import { DeviceSetupTemplate } from 'src/api/v3/device-setup-templates';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { DeviceSetupTemplateService } from 'src/app/services/device-setup-template.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { UResolvable } from 'src/app/guards/UResolvable';
import { autoinject } from 'src/shim';
import { LocatorService } from 'src/app/services/locator.service';
import { UserService } from 'src/app/api/user.service';
import { LanguageService } from 'src/app/services/language.service';
import { PopupService } from 'src/app/services/popup.service';
import { PopupType } from 'src/app/models/popup-type';

@Component({
  selector: 'app-cp-dev-setup-template-edit',
  templateUrl: './cp-dev-setup-template-edit.component.html',
  styleUrls: ['./cp-dev-setup-template-edit.component.scss']
})
export class CpDevSetupTemplateEditComponent extends LanguageAware implements OnInit, OnDestroy, UResolvable<typeof CpDevSetupTemplateEditComponent> {
  private readonly delayDuration = 2000;
  private readonly defaultTestPeriodDuration = 1 * 24 * 60;             // 1 diena MINUTĖMIS
  private readonly maxTestPeriodDuration =  10 * 24 * 60 + (15 * 60);   // 10 dienų 15 valandų MINUTĖMIS
  private readonly defaultReturnFromBackupDuration = 5 * 60;            // 5 minutės
  private readonly maxReturnFromBackupDuration = 99 * 60;               // 99 minutės
  private readonly defaultTemplate: DeviceSetupTemplate = {
    id: undefined,
    name: '',
    ip_com: null,
    host: '',
    port: '',
    encryption: '',
    communication_type: 0,
    backup_ip_com: null,
    backup_host: '',
    backup_port: '',
    backup_encryption: '',
    backup_communication_type: 0,
    backup_enabled: false,
    receiver_nr: 0,
    line_nr: 0,
    test_enabled: false,
    ping_enabled: false,
    test_period: this.defaultTestPeriodDuration,
    ping_period: 600,
    backup_after: 3,
    primary_after: this.defaultReturnFromBackupDuration,
    company_id: this.us.currentUser.company_id,
    users: [],
  };
  private readonly recommendedDefaults = {
    test_period: this.defaultTestPeriodDuration,
    ping_period: 600,
    backup_after: 3,
    primary_after: this.defaultReturnFromBackupDuration,
  };

  public readonly desktopView = this.platform.isDesktopView();
  public readonly isNew = this.ar.routeConfig.path === 'new';
  public readonly canOnlyView = !this.isNew && !this.us.currentUser.permissions?.permissions.dev_setup_templates.edit;

  private subscriptions: Subscription[] = [];
  private receiversDropdownItems: DropdownItem<number>[] = [];
  private get popupService() { return autoinject(LocatorService.injector, PopupService); }

  public newUserSelected = 0;
  public inputChanged = false;
  public isRequestLoading = false;
  public template: DeviceSetupTemplate = null;
  public testPeriodDuration = { days: 1, hours: 0 };
  public hexValueHolder = { line_nr: '', receiver_nr: '' };
  public returnFromBackupPeriodDuration = { minutes: 5, seconds: 0 };
  public communicationTypeDropdownItems: DropdownItem<number>[] = [
    { label: this.trans('setup.device.communicationTypes.tcp'), value: 0 },
    { label: this.trans('setup.device.communicationTypes.upd'), value: 1 },
  ];
  public footerButtons = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.onDesktopSave() },
    { label: this.trans('general.cancel'),  callback: () => this.navigateBack() },
  ];
  public val = new ValidatorBuilder<Omit<DeviceSetupTemplate, 'receiver_nr' | 'line_nr' | 'users'> & { receiver_nr: string; line_nr: string }>()
  .required('name', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.name'))}`)
  .required('host', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.host'))}`)
  .required('port', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.port'))}`)
  .required('encryption', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.encryptionForDevices'))}`)
  .regex('encryption', /^\+?.{6}(?:.{10})?$/,
    this.trans('validation.exactAttributeLength')
    .replace(':attribute', this.trans('settings.labels.encryptionForDevices'))
    .replace(':length', '6 :or 16').replace(':or', this.trans('general.or')))

  .required('receiver_nr', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.receiverNumber'))}`)
  .required('line_nr', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.lineNumber'))}`)
  .regex('receiver_nr', /^[0-9a-fA-F]{1,6}$/, this.trans('validation.hexadecimal').replace(':attribute', this.trans('setup.device.receiverNumber')))
  .regex('line_nr', /^[0-9a-fA-F]{1,6}$/, this.trans('validation.hexadecimal').replace(':attribute', this.trans('setup.device.lineNumber')))
  .required('test_period', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.testPeriod'))}`)
  .required('ping_period', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.pingPeriod'))}`)
  .required('backup_after', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.backupAfter'))}`)
  .required('primary_after', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.primaryAfter'))}`)
  .regex('primary_after', /^.{1,99}$/, `${this.trans('validation.min.string').replace(':attribute', this.trans('setup.device.primaryAfter')).replace(':min', '1')}`)
  .build().bindContext(this);

  public backupVal = new ValidatorBuilder<any>()
  .required('backup_host', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.host'))}`)
  .required('backup_port', `${this.trans('validation.required').replace(':attribute', this.trans('setup.device.port'))}`)
  .required('backup_encryption', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.encryptionForDevices'))}`)
  .regex('backup_encryption', /^\+?.{6}(?:.{10})?$/,
    this.trans('validation.exactAttributeLength')
    .replace(':attribute', this.trans('settings.labels.encryptionForDevices'))
    .replace(':length', '6 :or 16').replace(':or', this.trans('general.or')))
  .build().bindContext(this);

  public static async resolve(injector: Injector, activatedRoute: ActivatedRouteSnapshot) {
    const userService = autoinject(LocatorService.injector, UserService);
    const langService = autoinject(LocatorService.injector, LanguageService);
    if ( userService.usersForList.length !== 0 ) {
      return;
    }

    const result = await requests.user.getDeviceSetupUserList().toPromise();
    if ( result.success ) {
      userService.usersForList = result.users.map<DropdownItem<number>>(u => ({ value: u.id, label: u.name, extraParam: u.email }));
      userService.usersForList.unshift({
        value: 0,
        label: langService.get('setup.device.selectUser'),
      });
    }
  }

  constructor(
    cdRef: ChangeDetectorRef,
    private ar: ActivatedRoute,
    private es: EditSystemService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService,
    private dsts: DeviceSetupTemplateService
  ) {
    super(cdRef);
    if ( this.platform.isDesktopView() ) {
      this.subscriptions.push(this.ar.params.subscribe((params) => this.onTemplateChange(params)));
    } else {
      this.reinitVariables();
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.es.endComponentEdit(TEditableComponent.DeviceSetupTemplate);
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  private onTemplateChange(params: Params) {
    if ( !this.desktopView ) { return; }
    const templateId = Number(params.templateId);
    if ( this.inputChanged || this.es.getId(TEditableComponent.DeviceSetupTemplate) !== -1) {
      this.revert();
    }
    if (templateId && !isNaN(templateId)) {
      const template = this.dsts.deviceSetupTemplates.find(r => r.id === templateId);
      if (!template) {
        throw new Error('Device setup template not found');
      }
      this.es.beginComponentEdit(TEditableComponent.DeviceSetupTemplate, template.id, template);
    }
    this.reinitVariables();
  }

  public onTestPeriodBlur() {
    this.checkIfTestPeriodDurationValuesAreNumbers();
    if(this.testPeriodDuration.days === 10 && this.testPeriodDuration.hours > 15) {
      this.testPeriodDuration.hours = 15;
    }
    this.onTestPeriodChange();
  }

  public onTestPeriodChange() {
    this.checkIfTestPeriodDurationValuesAreNumbers();
    const durationInSeconds = (this.testPeriodDuration.days * 24 * 60) + (this.testPeriodDuration.hours * 60);
    if(durationInSeconds > this.maxTestPeriodDuration) {
      this.testPeriodDuration.days = 10;
      this.testPeriodDuration.hours = 15;
      this.template.test_period = this.maxTestPeriodDuration;
    } else {
      this.template.test_period = durationInSeconds;
    }
    this.onValueChange();
  }

  public onReturnFromBackupPeriodBlur() {
    this.checkIfReturnFromBackupPeriodDurationValuesAreValidNumbers();
    const roundNumber = Math.round(this.returnFromBackupPeriodDuration.seconds / 10) * 10;
    if(roundNumber >= 60) {
      const additionalMinutes = Math.floor(roundNumber / 60);
      const remainingSeconds = roundNumber - (additionalMinutes * 60);

      this.returnFromBackupPeriodDuration.seconds = remainingSeconds;
      this.returnFromBackupPeriodDuration.minutes = this.returnFromBackupPeriodDuration.minutes + additionalMinutes;
    } else {
      this.returnFromBackupPeriodDuration.seconds = roundNumber;
    }
    this.onReturnFromBackupPeriodChange();
  }

  public onReturnFromBackupPeriodChange() {
    this.checkIfReturnFromBackupPeriodDurationValuesAreValidNumbers();
    const durationInSeconds = (this.returnFromBackupPeriodDuration.minutes * 60) + this.returnFromBackupPeriodDuration.seconds;
    if(durationInSeconds > this.maxReturnFromBackupDuration) {
      this.returnFromBackupPeriodDuration.minutes = 99;
      this.returnFromBackupPeriodDuration.seconds = 0;
      this.template.primary_after = this.maxReturnFromBackupDuration;
    } else {
      this.template.primary_after = durationInSeconds;
    }
    this.onValueChange();
  }

  public onPingPeriodChange() {
    const roundNumber = Math.round(this.template.ping_period / 10) * 10;
    this.template.ping_period = roundNumber;
    this.onValueChange();
  }

  private checkIfReturnFromBackupPeriodDurationValuesAreValidNumbers() {
    if(this.returnFromBackupPeriodDuration.minutes > 99) {
      this.returnFromBackupPeriodDuration.minutes = 99;
    }
    if (isNaN(this.returnFromBackupPeriodDuration.minutes) || this.returnFromBackupPeriodDuration.minutes < 0) {
      this.returnFromBackupPeriodDuration.minutes = 0;
    }
    if (isNaN(this.returnFromBackupPeriodDuration.seconds) || this.returnFromBackupPeriodDuration.seconds < 0) {
      this.returnFromBackupPeriodDuration.seconds = 0;
    }

  }

  private checkIfTestPeriodDurationValuesAreNumbers() {
    if (isNaN(this.testPeriodDuration.days)) {
      this.testPeriodDuration.days = 0;
    }
    if (isNaN(this.testPeriodDuration.hours)) {
      this.testPeriodDuration.hours = 0;
    }
  }

  public onDeleteClick(): void {
    if(!this.us.currentUser.permissions?.permissions.dev_setup_templates.delete) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('setup.device.deleteTemplateInfo').replace(':template', this.template.name)}: ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.delete();
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private delete() {
    requests.deviceSetupTemplates.deleteTemplate({ id: this.template.id }).subscribe(
      (res: BasicSuccessResponse | ErrorResponse) => {
        if(res.success) {
          this.showMessagebox(true, this.trans('setup.device.templateDeleted'));
          const found = this.dsts.deviceSetupTemplates.find(t => t.id === this.template.id);
          if ( found ) {
            this.dsts.deviceSetupTemplates.splice(this.dsts.deviceSetupTemplates.indexOf(found), 1);
          }
          this.navigateBack();
        } else {
          this.toaster.postError((res as ErrorResponse).error);
        }
      }
    );
  }

  private showMessagebox(success: boolean, message: string): void {
    this.messagebox.open({
      buttons: this.messagebox.buttons.Close,
      headerText: success ? this.trans('general.titleSuccess') : this.trans('general.titleError'),
      messageContent: message,
      iconType: success ? this.messagebox.iconType.Success : this.messagebox.iconType.Error
    });
  }

  public async onDesktopSave(): Promise<void> {
    const validationSuccessful = await this.val.validate({...this.template, receiver_nr: this.hexValueHolder.receiver_nr, line_nr: this.hexValueHolder.line_nr});
    const backupChannelValidationSuccessful = this.template.backup_enabled ?
      await this.backupVal.validate({...this.template, receiver_nr: this.hexValueHolder.receiver_nr, line_nr: this.hexValueHolder.line_nr})
      : true;
    if(!validationSuccessful || !backupChannelValidationSuccessful) { return; };
    let added = false;
    let edited = false;
    if(this.isNew && this.us.currentUser.permissions?.permissions.dev_setup_templates.create) {
      this.isRequestLoading = true;
      added = await this.addNewTemplate();
    } else if (this.us.currentUser.permissions?.permissions.dev_setup_templates.edit) {
      this.isRequestLoading = true;
      edited = await this.editExistingTemplate();
    }
    await new Promise<void>(() => setTimeout(() => {
      if ( added ) {
        this.navigateBack();
      } else if ( edited ) {
        this.es.beginComponentEdit(TEditableComponent.DeviceSetupTemplate, this.template.id, this.template);
        this.onValueChange();
      }
    }, this.delayDuration));
  }

  public onValueChange() {
    if (this.isNew === false) {
      this.inputChanged = this.es.hasChanged(TEditableComponent.DeviceSetupTemplate);
    }
  }

  private navigateBack() {
    this.revert();
    this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'device-setup-templates'], { replaceUrl: true });
  }

  private revert() {
    if (this.template?.id === undefined) {
      return;
    }
    const before = this.es.getComponentBeforeModification(TEditableComponent.DeviceSetupTemplate);
    Object.assign(this.template, before);
    this.es.endComponentEdit(TEditableComponent.DeviceSetupTemplate);
    this.inputChanged = false;
  }

  private loadReceiversDropdownItems() {
    const dropdownItems: DropdownItem<number | null>[] = [];
    if(this.us.currentUser.permissions.permissions.ipcom_settings.view) {
      this.ipcomService.ipcomReceiversForTemplate.forEach(r => {
        dropdownItems.push({
          label: r.receiver_name,
          value: r.id,
        });
      });
    } else if(!this.isNew) {
      this.template.ip_com = null;
      this.template.backup_ip_com = null;
      this.onValueChange();
    }
    dropdownItems.unshift({
      label: this.trans('general.unused'),
      value: null,
      default: true
    });
    this.receiversDropdownItems = dropdownItems;
  }

  public get ipcomDropdownList() {
    return this.receiversDropdownItems.sort((a, b) => a.value - b.value);
  }

  public primaryReceiverChanged(id: number) {
    if(!this.us.currentUser.permissions?.permissions.ipcom_settings.view) { return; }
    const selectedReceiver = this.ipcomService.ipcomReceiversForTemplate.find(r => r.id === id);
    if(!selectedReceiver) {
      this.template.ip_com = null;
      this.onValueChange();
      return;
    }
    this.template.ip_com = selectedReceiver.id;
    this.template.host = selectedReceiver.input_host;
    this.template.port = selectedReceiver.port.toString(10);
    this.template.encryption = selectedReceiver.encryption_password;
    this.onValueChange();
  }

  public backupReceiverChanged(id: number) {
    if(!this.us.currentUser.permissions?.permissions.ipcom_settings.view) { return; }
    const selectedReceiver = this.ipcomService.ipcomReceiversForTemplate.find(r => r.id === id);
    if(!selectedReceiver) {
      this.template.backup_ip_com = null;
      this.onValueChange();
      return;
    }
    this.template.backup_ip_com = selectedReceiver.id;
    this.template.backup_host = selectedReceiver.input_host;
    this.template.backup_port = selectedReceiver.port.toString(10);
    this.template.backup_encryption = selectedReceiver.encryption_password;
    this.onValueChange();
  }

  private async addNewTemplate(): Promise<boolean> {
    try {
      const result = await requests.deviceSetupTemplates.addTemplate(this.template).toPromise();
      if ( result.success ) {
        this.template.id = result.id;
        this.dsts.deviceSetupTemplates.push(this.template);
      } else {
        this.toaster.postError((result as unknown as ErrorResponse).error);
      }
      return result.success;
    } finally {
      this.isRequestLoading = false;
    }
  }

  private async editExistingTemplate(): Promise<boolean> {
    try {
      const res = await requests.deviceSetupTemplates.saveTemplate(this.template).toPromise();
      if(res.success) {
        this.isRequestLoading = false;
        this.es.endComponentEdit(TEditableComponent.DeviceSetupTemplate);
      } else {
        this.toaster.postError((res as unknown as ErrorResponse).error);
      }
      return res.success;
    } finally {
      this.isRequestLoading = false;
    }
  }

  public onResetSettingsClick() {
    this.template = Object.assign(this.template, this.recommendedDefaults);
    this.hexValueHolder.line_nr = this.template.line_nr.toString(16);
    this.hexValueHolder.receiver_nr = this.template.receiver_nr.toString(16);
    this.returnFromBackupPeriodDuration.minutes = this.template.primary_after / 60;// sekundės pakeičiam į minutes.
    this.returnFromBackupPeriodDuration.seconds = this.template.primary_after - (this.returnFromBackupPeriodDuration.minutes * 60);
    this.testPeriodDuration.days = Math.floor(this.template.test_period / (24 * 60)); // minutes pakeičiam į dienas.
    this.testPeriodDuration.hours = (this.template.test_period / 60) - (this.testPeriodDuration.days * 24); // minutes pakeičiam į valandas.
    this.onValueChange();
  }

  private convertNumbersToHexStrings() {
    this.hexValueHolder.line_nr = this.template.line_nr.toString(16);
    this.hexValueHolder.receiver_nr = this.template.receiver_nr.toString(16);
  }

  private reinitVariables() {
    this.template = this.isNew ? null : this.es.getEditableComponent(TEditableComponent.DeviceSetupTemplate);
    if(!this.isNew && !this.es.getComponentBeforeModification(TEditableComponent.DeviceSetupTemplate)) {
      this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'device-setup-templates'], { replaceUrl: true });
    }
    if(this.template === null) {
      this.template = Object.assign({}, this.defaultTemplate);
    }
    this.loadReceiversDropdownItems();
    this.convertNumbersToHexStrings();
    this.returnFromBackupPeriodDuration.minutes = this.template.primary_after / 60;// sekundės pakeičiam į minutes.
    this.returnFromBackupPeriodDuration.seconds = this.template.primary_after - (this.returnFromBackupPeriodDuration.minutes * 60);
    this.testPeriodDuration.days = Math.floor(this.template.test_period / (24 * 60)); // minutes pakeičiam į dienas.
    this.testPeriodDuration.hours = (this.template.test_period / 60) - (this.testPeriodDuration.days * 24); // minutes pakeičiam į valandas.
  }

  public updateReceiverAndLine() {
    this.template.line_nr = parseInt(this.hexValueHolder.line_nr, 16);
    this.template.receiver_nr = parseInt(this.hexValueHolder.receiver_nr, 16);
  }

  public onUserAdded(value: number) {
    if ( value === 0 ) { return; }
    try {
      if ( this.template.users.find(u => u.id === value) ) { return; }
      const userItem = this.us.usersForList.find(u => u.value === value);
      this.template.users.push({
        id: userItem.value,
        name: userItem.label as string,
        email: userItem.extraParam as string?? '',
      });
      this.onValueChange();
    } finally {
      setTimeout(() => {
        this.newUserSelected = 0;
      }, 250);
    }
  }

  public doAddUser() {
    this.popupService.showSlideout<DropdownItem<number>>({
      headerText: this.trans('setup.device.selectUser'),
      items: this.us.usersForList,
      onSubmit: (res) => {
        if ( res.value !== 0 ) {
          this.onUserAdded(res.value);
        }
      }
    }, PopupType.SlideoutWithValue);
  }

  public onUserChanged(newUserId: number, oldUserId: number) {
    if ( newUserId === oldUserId ) { return; }
    if ( newUserId === 0 || this.template.users.find(u => u.id === newUserId) ) {
      this.onUserDeleted(oldUserId);
      return;
    }
    const oldUser = this.template.users.find(u => u.id === oldUserId);
    const newUser = this.us.usersForList.find(u => u.value === newUserId);
    oldUser.id = newUser.value;
    oldUser.name = newUser.label as string;
    oldUser.email = newUser.extraParam as string ?? '';
    this.onValueChange();
  }

  public doChangeUser(oldUserId: number) {
    this.popupService.showSlideout<DropdownItem<number>>({
      headerText: this.trans('setup.device.selectUser'),
      items: this.us.usersForList,
      onSubmit: (res) => {
        if ( res.value === 0 ) {
          this.onUserDeleted(oldUserId);
        } else {
          this.onUserChanged(res.value, oldUserId);
        }
      }
    }, PopupType.SlideoutWithValue);
  }

  public onUserDeleted(userId: number) {
    if ( userId === 0 ) { return; }
    const user = this.template.users.find(u => u.id === userId);
    this.template.users.splice(this.template.users.indexOf(user), 1);
    this.onValueChange();
  }
}
