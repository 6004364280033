import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TDeviceUser } from 'src/app/models/device-user';
import { TEditableComponent } from 'src/app/models/editable-component';
import { FilteringService } from 'src/app/services/filtering.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { PermissionRole, ProtegusUserData } from 'src/api/v3/common';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserListComponent extends LanguageAware implements OnInit, OnDestroy {
  public masters: ProtegusUserData[] = [];
  public admins: TDeviceUser[] = [];
  public simple: TDeviceUser[] = [];
  private activeSystemSubscriber = null;
  public unauthorizedUser: TDeviceUser = null;
  public supportsAdmins = false;
  public masterInTheList = false;
  public currentUserIsAdmin = false;
  public isCg = false;

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
    public fs: FilteringService,
    private msg: MessageboxService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'UserList';
    this.background.setGray();
    this.es.currentUserObj = null;
    const refType = localStorage.getItem('ref');
    const backUrl = refType === 'conf' ? '../system-configuration' : '*';
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.users'),
      backUrl,
      activatedRoute: this.r,
    });
    this.supportsAdmins = (this.systems.activeSystemHelper.supports.gvTypeUsers() || this.systems.activeSystemHelper.supports.cgTypeUsers())
                          && this.systems.activeSystem.hwType !== 'CWI';
    this.isCg = this.systems.activeSystem.hwType === 'CG17';
    const that = this;
    this.reloadView();
    this.activeSystemSubscriber = this.systems.onActiveSystemChange.subscribe(() => {
      that.reloadView();
    });
  }

  private reloadView() {
    this.masters = this.getMasters();
    this.admins = this.getAdmins();
    this.simple = this.getDeviceUsers();
    this.unauthorizedUser = this.getUnauthorizedUser();
    const found = this.systems.activeSystem.device_users.find((du) => du.protegus_user_id === this.us.currentUser.id);
    this.currentUserIsAdmin = found !== undefined && found.zone_number < 9;
    this.masterInTheList =
      this.us.currentUser.permissions?.role !== PermissionRole.GeneralUser ||
      !this.systems.activeSystem.amIMaster ||
      this.systems.activeSystem.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id) !== undefined;
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.activeSystemSubscriber !== null) {
      this.activeSystemSubscriber.unsubscribe();
      this.activeSystemSubscriber = null;
    }
  }

  public onAddUser() {
    this.es.beginComponentEdit(TEditableComponent.User, 0, null);
    this.router.navigate(['../user'], { relativeTo: this.r });
  }

  public deviceUserClick(userId: number) {
    this.log('atidarom dev user', userId);
    const found = this.systems.activeSystem.device_users.find((u) => u.id === userId);
    if (found === undefined) {
      return;
    }
    if (
      !this.systems.activeSystem.amIMaster &&
      !this.us.currentUser.permissions?.permissions.systems.view &&
      this.systems.activeSystem.owners.find((o) => o.id === found.protegus_user_id) !== undefined
    ) {
      return; // paprastam negalima redaguoti masteriu
    }
    this.es.beginComponentEdit(TEditableComponent.User, userId, found);
    this.router.navigate(['../user'], { relativeTo: this.r });
  }

  private getMasters(): ProtegusUserData[] {
    if ( this.platform.isDesktopView() ) {
      const masters = this.us.systemUsers.get(this.systems.activeSystem.id) ?? [];
      return masters.filter(m => m.master).map((u) => this.us.users.get(u.id));
    }
    return this.systems.activeSystem.protegus_users.filter((u) => u.master).map((m) => this.us.users.get(m.id));
  }

  private getAdmins(): TDeviceUser[] {
    if (!this.supportsAdmins) {
      return [];
    }
    return this.systems.activeSystem.device_users.filter((du) => du.zone_number < 9 && (du.name !== '' || du.email !== '' || du.phone !== '' || du.code !== ''));
  }

  private getDeviceUsers(): TDeviceUser[] {
    if (this.systems.activeSystemHelper.supports.cgTypeUsers()) {
      return this.systems.activeSystem.device_users.filter((du) => du.zone_number > 8);
    } else if (this.systems.activeSystemHelper.supports.gvTypeUsers()) {
      return this.systems.activeSystem.device_users.filter((du) => du.zone_number > 10);
    } else {
      return this.systems.activeSystem.device_users;
    }
  }

  private getUnauthorizedUser(): TDeviceUser {
    return this.systems.activeSystem.device_users.find((du) => du.zone_number === 10);
  }

  public openOwnerSettings(owner: ProtegusUserData) {
    if (this.us.currentUser.permissions?.role !== PermissionRole.SuperAdmin && owner.id !== this.us.currentUser.id) {
      return;
    }
    this.es.beginComponentEdit(TEditableComponent.User, owner.id, owner);
    this.router.navigate(['../owner-privacy'], { relativeTo: this.r });
  }

  public addMaster() {
    if (this.systems.activeSystemHelper.supports.gvTypeUsers()) {
      const adminCount = this.systems.activeSystem.device_users.filter((u) => u.zone_number < 8).length;
      if (adminCount === 7) {
        this.msg.open({
          buttons: this.msg.buttons.CustomButton1,
          headerText: this.trans('users.errors.deviceAdminListFull'),
          messageContent: this.trans('users.labels.' + (this.systems.activeSystemHelper.supports.simpleUsers() ? 'adminToReplaceInfo' : 'adminToReplaceNoUsersInfo')),
          button1Text: this.trans('users.buttons.selectAdmin')
        }, (m) => {
          m.button1Clicked.subscribe(() => { this.gotoReplaceAdmin(); });
        });
      } else {
        this.performAddMasterToList();
      }
    } else if (this.systems.activeSystemHelper.supports.cgTypeUsers()) {
      const adminCount = this.systems.activeSystem.device_users.filter((u) => u.zone_number < 9).length;
      if (adminCount === 8) {
        this.msg.open({
          buttons: this.msg.buttons.CustomButton1,
          headerText: this.trans('users.errors.deviceAdminListFull'),
          messageContent: this.trans('users.labels.' + (this.systems.activeSystemHelper.supports.simpleUsers() ? 'adminToReplaceInfo' : 'adminToReplaceNoUsersInfo')),
          button1Text: this.trans('users.buttons.selectAdmin')
        }, (m) => {
          m.button1Clicked.subscribe(() => { this.gotoReplaceAdmin(); });
        });
      } else {
        this.performAddMasterToList();
      }
    }
  }

  private performAddMasterToList() {
    const maxAdmins = this.systems.activeSystemHelper.supports.cgTypeUsers() ? 8 : 7;
    let zoneNumberToAssign = 0;
    for (let i = 1; i <= maxAdmins; i++) {
      if (this.systems.activeSystem.device_users.find((u) => u.zone_number === i) === undefined) {
        zoneNumberToAssign = i;
        break;
      }
    }
    let enabledAreas = '';
    let enabledOutputs = 0;
    if (this.systems.activeSystemHelper.supports.cgTypeUsers()) {
      enabledAreas = ''.padStart(this.systems.activeSystem.areas.length, '1');
    } else if (this.systems.activeSystemHelper.supports.gvTypeUsers()) {
      const pgm3 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 3);
      const pgm4 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 4);
      const pgm5 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 5);
      const pgm1 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 1);
      const pgm2 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 2);
      enabledOutputs =
        (pgm3 === undefined || !pgm3.enabled ? 0 : 0x01) +
        (pgm4 === undefined || !pgm4.enabled ? 0 : 0x02) +
        (pgm5 === undefined || !pgm5.enabled ? 0 : 0x04) +
        (pgm1 === undefined || !pgm1.enabled ? 0 : 0x08) +
        (pgm2 === undefined || !pgm2.enabled ? 0 : 0x10);
    }
    const newUser: TDeviceUser = {
      id: 0,
      areas: enabledAreas,
      code: '1234',
      email: this.us.currentUser.email,
      enable_data: 1,
      name: this.us.currentUser.name,
      pgms: enabledOutputs,
      phone: this.us.currentUser.phone,
      present: false,
      protegus_user_id: this.us.currentUser.id,
      schedule_no: 0,
      zone_number: zoneNumberToAssign,
      isOwner: true,
      ownerPermissions: {},
    };
    if (this.systems.activeSystem.hwType === 'CG17') {
      newUser.name = newUser.email;
    }
    const systemId = this.systems.activeSystem.id;
    const currentUsers = this.systems.activeSystem.device_users;
    currentUsers.push(newUser);
    this.systems.activeSystem.device_users = currentUsers;
    this.masterInTheList = true;
    this.currentUserIsAdmin = true;
    const defaultPermissions = this.permissionService.getDefaultPermissionFor(PermissionRole.SystemMaster, this.systems.activeSystem.company_id);
    this.api.post('/edit/user',{
      user: newUser,
      system_id: this.systems.activeSystem.id,
      canSeeEvents: defaultPermissions.permissions.sys_events.view,
      canEditUsers: defaultPermissions.permissions.sys_users.edit,
    }, true).subscribe(
      (result) => {
        if (!result.success) {
          this.toaster.postError(result.error);
          this.revertAdminAddition(systemId);
          return;
        }
        const system = this.systems.getSystem(systemId);
        if (system === undefined) {
          return;
        }
        const user = system.device_users.find((u) => u.id === 0);
        if (user === undefined) {
          return;
        }
        if (result.protegus_user_id !== undefined && result.protegus_user_id !== 0) {
          user.protegus_user_id = result.protegus_user_id;
        }
        if (result.id !== undefined && result.id !== 0) {
          user.id = result.id;
        }
        this.systems.saveActiveSystem(systemId);
      },
      (error) => {
        this.revertAdminAddition(systemId);
      }
    );
  }

  private revertAdminAddition(systemId: number) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const user = system.device_users.find((u) => u.id === 0);
    if (user === undefined) {
      return;
    }
    system.device_users = system.device_users.filter((u) => u.id !== 0);
    this.masterInTheList = false;
    this.currentUserIsAdmin = false;
    this.reloadView();
  }

  public gotoReplaceAdmin() {
    this.router.navigate(['../replace-device-administrator'], { relativeTo: this.r });
  }

  public getMasterName(master: ProtegusUserData): string {
    if (master.name === '' && master.phone === '') {
      return this.trans('users.labels.noName') + ' ' + this.trans('users.labels.noPhone');
    }
    if (
      this.us.currentUser.permissions?.role === PermissionRole.SuperAdmin ||
      this.us.currentUser.permissions?.role === PermissionRole.Company ||
      this.systems.activeSystem.amIMaster
    ) {
      return master.name === '' ? master.phone : master.name;
    }

    const privacy = this.systems.activeSystem.privacyOfOwners.find((op) => op.user_id === master.id);
    if (privacy === undefined) {
      return master.name === '' ? master.phone : master.name;
    }
    if (this.currentUserIsAdmin) {
      if (master.name !== '' && privacy.show_name_admins) {
        return master.name;
      } else if ((!privacy.show_name_admins || master.name === '') && master.phone !== '') {
        return privacy.show_phone_admins ? master.phone : '<' + this.trans('general.hidden').toLowerCase() + '>';
      } else if (!privacy.show_name_admins) {
        return '<' + this.trans('general.hidden').toLowerCase() + '>';
      }
    } else {
      if (master.name !== '' && privacy.show_name_users) {
        return master.name;
      } else if ((!privacy.show_name_admins || master.name === '') && master.phone !== '') {
        return privacy.show_phone_users ? master.phone : '<' + this.trans('general.hidden').toLowerCase() + '>';
      } else if (!privacy.show_name_users) {
        return '<' + this.trans('general.hidden').toLowerCase() + '>';
      }
    }
  }

  public getMaterEmail(master: ProtegusUserData): string {
    if (
      this.us.currentUser.permissions?.role === PermissionRole.SuperAdmin ||
      this.us.currentUser.permissions?.role === PermissionRole.Company ||
      this.systems.activeSystem.amIMaster
    ) {
      return master.email;
    }

    const privacy = this.systems.activeSystem.privacyOfOwners.find((op) => op.user_id === master.id);
    if (privacy === undefined) {
      return master.email;
    }

    if (this.currentUserIsAdmin) {
      return privacy.show_email_admins ? master.email : '<' + this.trans('general.hidden').toLowerCase() + '>';
    } else {
      return privacy.show_email_users ? master.email : '<' + this.trans('general.hidden').toLowerCase() + '>';
    }
  }
}
