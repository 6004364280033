import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { BindObject, PersistenceService } from 'src/app/api/persistence.service';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';
import { UserService } from 'src/app/api/user.service';
import { DataTableGetter } from '../../components/data-table/data-table.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { Chip, PermissionRole, ProtegusUserData } from 'src/api/v3/common';

type FilteredResultsPageData = {currentPage: number; lastPage: number};
export interface FilterCriteria extends FilteredResultsPageData {
  searchPhrase: string;
  searchFields: string[];
}

@Component({
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.scss'],
})
export class CompanyUsersComponent extends LanguageAware implements OnInit {
  public readonly canAdd = this.us.currentUser.permissions.permissions.users.create && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
  loader: DataTableGetter<ProtegusUserData>;
  filterCriteria: FilterCriteria;
  prevFilterCriteria: string;
  searchVisible: boolean;
  isSearchLoading: boolean;
  resultsFiltered: boolean;
  formSubmitted: boolean;
  isManuallySubmitted: boolean;
  typingTimer: NodeJS.Timeout;
  doneTypingInterval: number;
  filterChips: Chip[];

  public readonly defaultColumns = ['fake:icon', 'name', 'email', 'phone_number', 'role', 'active', 'fake:delete'];
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('users.labels.icon'), value: 'fake:icon' },
    { name: this.trans('users.filterCriteria.name'), value: 'name' },
    { name: this.trans('users.filterCriteria.email'), value: 'email' },
    { name: this.trans('users.filterCriteria.phone_number'), value: 'phone_number' },
    this.us.currentUser.permissions.permissions.systems.view ?
      { name: this.trans('systems.labels.system'), value: 'system_name' } : null,
    { name: this.trans('users.filterCriteria.role'), value: 'role' },
    this.us.currentUser.permissions.permissions.roles.view ?
      { name: this.trans('users.filterCriteria.permissionRule'), value: 'access_permission_id' } : null,
    { name: this.trans('users.filterCriteria.active'), value: 'active' },
    this.us.currentUser.permissions.role !== PermissionRole.Company && this.us.currentUser.permissions.permissions.company_settings.view ?
    { name: this.trans('users.filterCriteria.company_name'), value: 'company' } : null,
    { name: this.trans('users.buttons.delete'), value: 'fake:delete' },
  ].filter(item => item !== null);
  public columns: BindObject<string[]>;

  constructor(
    private persistence: PersistenceService,
    private user: UserService,
    cdRef: ChangeDetectorRef,
  ) {
    super(cdRef);
    this.loader = this.user.getUsersGetter(this.resultsFiltered);
    this.searchVisible = false;
    this.isSearchLoading = false;
    this.resultsFiltered = false;
    this.formSubmitted = false;
    this.doneTypingInterval = 500;
    this.filterChips = [
      { name: 'name', label: this.trans('users.filterCriteria.name'), value: false, modifiableColumn: true },
      { name: 'email', label: this.trans('users.filterCriteria.email'), value: false, modifiableColumn: true },
      { name: 'phone_number', label: this.trans('users.filterCriteria.phone_number'), value: false, modifiableColumn: true },
      this.us.currentUser.permissions.permissions.systems.view ?
        { name: 'system_name', label: this.trans('users.filterCriteria.system_name'), value: false, modifiableColumn: true } : null,
      this.us.currentUser.permissions.role !== PermissionRole.Company &&
      this.us.currentUser.permissions.permissions.company_settings.view ?
        { name: 'company', label: this.trans('users.filterCriteria.company_name'), value: false, modifiableColumn: true } : null,
      { name: 'role', label: this.trans('users.filterCriteria.role'), value: false, modifiableColumn: true },
      this.us.currentUser.permissions.permissions.roles.view ?
        { name: 'access_permission_id', label: this.trans('users.filterCriteria.permissionRule'), value: false, modifiableColumn: true } : null,
      { name: 'active', label: this.trans('users.filterCriteria.active'), value: false, modifiableColumn: true },
      { name: 'inactive', label: this.trans('users.filterCriteria.inactive'), value: false, modifiableColumn: false },
    ].filter(item => item !== null);
    this.resetFilterCriteria();
    this.columns = this.persistence.bind('users_columns', this.defaultColumns);
  }

  ngOnInit(): void {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.hideSearch();
  }

  public onChipPress(event: any, chip: Chip): void {
    if(this.filterCriteria.searchFields.includes(chip.name) && !chip.value) {
      const index = this.filterCriteria.searchFields.indexOf(chip.name);
      this.filterCriteria.searchFields.splice(index, 1);
    } else if (!this.filterCriteria.searchFields.includes(chip.name) && chip.value) {
      this.filterCriteria.searchFields.push(chip.name);
    }
    this.onSearchSubmit(event);
  };

  public onSearchSubmit(event: any): void {
    clearTimeout(this.typingTimer);
    if(event.key === 'Enter' || event.type === 'click') {
      this.isManuallySubmitted = true;
      this.initializeSearch();
    } else {
      this.isManuallySubmitted = false;
      this.typingTimer = setTimeout(() => {
        this.initializeSearch();
      }, this.doneTypingInterval);
    }
  }

  private async initializeSearch(): Promise<void> {
    this.filterActiveSearchFields();
    const { searchPhrase, searchFields } = this.filterCriteria;
    const filterCriteriaString = JSON.stringify({searchPhrase, searchFields});
    if (filterCriteriaString !== this.prevFilterCriteria) {
      this.resetPagesCount();
    }
    if(this.filterCriteria.searchPhrase.length >= (this.isManuallySubmitted ? 2 : 3)) {
      this.isSearchLoading = true;
      await this.setFilteredUsers().then(() => {
        this.prevFilterCriteria = filterCriteriaString;
        this.resultsFiltered = true;
        this.isSearchLoading = false;
      });
    } else if (this.resultsFiltered && this.filterCriteria.searchPhrase.length === 0) {
      this.loader = this.user.getUsersGetter(this.resultsFiltered);
      this.resultsFiltered = false;
    }
  }

  private setFilteredUsers(): Promise<void> {
    return new Promise((resolve) => {
      this.loader = this.user.getFilteredUsersGetter(this.filterCriteria);
      resolve();
    });
  }

  private filterActiveSearchFields(): void {
    this.filterCriteria.searchFields.map(searchFieldName => {
      if (
        !this.columns.value.includes(searchFieldName) &&
        searchFieldName !== 'inactive'
      ) {
        const index = this.filterCriteria.searchFields.indexOf(searchFieldName);
        this.filterCriteria.searchFields.splice(index, 1);
        const inactiveChip = this.filterChips.find(chip => chip.name === searchFieldName);
        if(inactiveChip) { inactiveChip.value = false; };
      } else if (
        !this.columns.value.includes('active') &&
        searchFieldName === 'inactive'
      ) {
        const index = this.filterCriteria.searchFields.indexOf('inactive');
        this.filterCriteria.searchFields.splice(index, 1);
        const inactiveChip = this.filterChips.find(chip => chip.name === searchFieldName);
        if(inactiveChip) { inactiveChip.value = false; };
      }
    });
  }

  public onContextSearch(eventData) {
    const chip = this.filterChips.find(filterChip => filterChip.name === eventData.searchField);
    if (chip) { chip.value = true; }
    this.showSearch();
    this.filterCriteria.searchPhrase = eventData.searchPhrase;
    this.filterCriteria.searchFields = [eventData.searchField];

    this.isManuallySubmitted = true;
    this.initializeSearch();
  }

  public showSearch(): void {
    this.searchVisible = true;
  }

  public hideSearch(): void {
    if(!this.searchVisible) { return; }
    this.searchVisible = false;
    this.isSearchLoading = false;
    this.resetFilterCriteria();
    if(this.resultsFiltered) {
      this.loader = this.user.getUsersGetter(this.resultsFiltered);
      this.resultsFiltered = false;
    };
    const searchInputField = document.getElementById('searchInputField');
    searchInputField.blur();
  }

  private resetFilterCriteria(): void {
    this.filterCriteria = {
      searchPhrase: '',
      searchFields: [],
      currentPage: 0,
      lastPage: 0
    };
    this.filterChips.map(chip => chip.value = false);
  }

  private resetPagesCount(): void {
    this.filterCriteria.currentPage = 0;
    this.filterCriteria.lastPage = 0;
  }
}
