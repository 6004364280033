import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicSuccessResponse } from 'src/api/v3/common';
import { AddCompanyResponse, Company, SaveCompanyLogoResponse } from 'src/api/v3/company';
import requests from 'src/api/v3/requests';
import { LocaleService } from 'src/app/api/locale.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupType } from 'src/app/models/popup-type';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { PopupService } from 'src/app/services/popup.service';
import { FooterButton } from 'src/app/ui/desktop-footer/desktop-footer.component';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { countryList } from 'src/environments/countrylist';

@Component({
  selector: 'app-edit-company',
  templateUrl: './edit-company.component.html',
  styleUrls: ['./edit-company.component.scss']
})
export class EditCompanyComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly isInGeneralSettings = this.desktopView && this.route.routeConfig.path === 'settings/general';
  public canEdit = this.us.currentUser.permissions?.permissions.company_settings.edit;
  public companyToEdit: Company | null = null;
  public isNew = false;
  public canDelete = false;
  public inputChanged = false;
  public isSaving = false;
  public footerButtons: FooterButton[] = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.saveChanges() },
    { label: this.trans('general.cancel'),
      callback: () => {
        this.revert();
        if ( this.us.currentUser.company_id === 0 ) {
          this.router.navigate([companyRootRoute, 'companies']);
        } else {
          this.router.navigate([companyRootRoute, 'settings']);
        }
      }
    },
  ];
  public countryList: DropdownItem<string>[];
  public val = new ValidatorBuilder<{
    email: string;
    phone: string;
    country: string;
    registration_email: string;
    name: string;
    address: string;
  }>()
    .required('email', this.trans('companies.errors.email'))
    .regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, this.trans('companies.errors.invalidEmail'))
    .regex('phone', /^\+?[0-9]{6,15}$/, this.trans('general.invalidData'))
    .required('country', this.trans('validation.countryRequired'))
    .required('name', this.trans('general.required'))
    .required('address', this.trans('general.required'))
    .regex('registration_email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, this.trans('companies.errors.invalidEmail'))
    .required('registration_email', this.trans('general.required'))
    .build().bindContext(this.companyToEdit);
  public countryText = '';
  private companyIdChangeSubscription = this.route.params.subscribe((params) => {
    if ( !this.desktopView ) { return; }
    const companyId = Number(params.companyId);
    if ( this.inputChanged || this.es.getId(TEditableComponent.Company) !== -1) {
      this.revert();
    }
    if (companyId && !isNaN(companyId)) {
      const company = this.companyService.companies.get(companyId);
      if (!company) {
        throw new Error('Company not found');
      }
      this.companyService.activeCompanyId = company.id;
      this.es.beginComponentEdit(TEditableComponent.Company, company.id, company);
    }
    this.reinitVariables();
    this.val.validate(this.companyToEdit);
  });
  private fileToUpload: File | null = null;
  public fileToUploadUrl: any = null;
  public logoChanged = false;
  private loadedLogoSize = null;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService,
    private route: ActivatedRoute,
    private locale: LocaleService,
    private popup: PopupService,
    private sanitizer: DomSanitizer,
  ) {
    super(cdRef);
    this.reinitVariables();
    if ( !this.desktopView ) {
      this.background.setGray();
      this.headerBar.showHeader({
        backUrl: this.g.getHomeUrl().join('/') + '/companies',
        headerText: this.isNew ? this.trans('companies.titles.newCompany') : this.companyToEdit.name,
        actionButtonText: this.canDelete ? this.trans('general.delete') : undefined,
      });
      if ( this.canEdit ) {
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('settings.buttons.save'), true, false);
        this.footerBar.onButton1Click = () => {
          if ( !this.isNew ) {
            this.revert();
          }
          this.router.navigate([...this.g.getHomeUrl(), 'companies']);
        };
        this.footerBar.onButton2Click = () => {
          this.saveChanges();
        };
      }
      if ( this.canDelete ) {
        this.headerBar.onActionButtonClicked = () => {
          this.doDelete();
        };
      }
    }
    const labeler = (code: string) => this.locale.getCountryName(code);
    this.countryList = countryList.map((code) => ({
      value: code,
      label: labeler,
    }));
    if ( this.desktopView ) {
      this.countryList.unshift({
        value: '',
        label: this.trans('users.labels.selectCountry'),
        default: true,
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.companyIdChangeSubscription?.unsubscribe();
  }

  private async saveChanges() {
    if ( this.isSaving ) {
      if ( !this.desktopView ) { this.miniStatus.flash(); }
      return;
    }
    if ( ! await this.val.validate(this.companyToEdit) ) { return; }
    this.isSaving = true;
    if ( !this.desktopView ) { this.miniStatus.show(this.trans('general.saving')); }
    const func = (this.isNew ? requests.company.addCompany : requests.company.saveCompany) as (p: any) => any;
    const data = this.isNew ? { ...this.companyToEdit, owner_name: '', owner_last_name: '', owner_phone: '' } : this.companyToEdit;
    try {
      const response: BasicSuccessResponse | AddCompanyResponse = await func(data).toPromise();
      if ( this.isNew ) {
        this.companyToEdit.id = (response as AddCompanyResponse).id;
        this.companyToEdit.alias = (response as AddCompanyResponse).alias;
        this.companyService.ingestCompany(this.companyToEdit);
      }
      if ( this.logoChanged ) {
        const result = await this.saveLogo();
        if ( !result ) {
          this.miniStatus.hide();
          this.isSaving = false;
          return;
        }
      }
      this.isSaving = false;
      this.es.endComponentEdit(TEditableComponent.Company);
      this.companyService.activeCompanyId = 0;
      if ( !this.desktopView ) {
        this.miniStatus.hide();
        this.router.navigate([...this.g.getHomeUrl(), 'companies']);
      } else {
        setTimeout(() => {
          if ( this.us.currentUser.company_id === 0 ) {
            this.router.navigate([companyRootRoute, 'companies']);
          } else {
            this.router.navigate([companyRootRoute, 'settings', 'general']);
          }
        }, 1000);
      }
    } catch(e) { }
    if ( !this.desktopView ) { this.miniStatus.hide(); }
    this.isSaving = false;
  }

  private revert(before?: Company) {
    if ( !before ) {
      before = this.es.getComponentBeforeModification(TEditableComponent.Company);
    }
    if ( !before ) { return; } // vadinasi naujas

    const found = this.companyService.companies.get(before.id);
    if ( !found ) { return; }

    const keys = Object.keys(found);
    for ( const iKey of keys ) {
      found[iKey] = before[iKey];
    }
    this.inputChanged = false;
    this.es.endComponentEdit(TEditableComponent.Company);
  }

  public copyAlias() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.companyToEdit.alias;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toaster.postSuccess(this.trans('companies.labels.aliasCopied'));
  }

  public selectCountry() {
    this.popup.showSlideout<DropdownItem<string>>({
      headerText: this.trans('companies.labels.companyCountry'),
      items: this.countryList,
      onSubmit: (res) => {
        this.companyToEdit.country = res.value;
        this.countryText = this.locale.getCountryName(res.value);
        this.val.validate(this.companyToEdit);
      }
    }, PopupType.SlideoutWithFunctionValue);
  }

  public doDelete() {
    if ( this.isSaving ) {
      if ( !this.desktopView ) { this.miniStatus.flash(); }
      return;
    }
    this.isSaving = true;
    if ( !this.desktopView ) { this.miniStatus.show(this.trans('general.deleting')); }
    requests.company.deleteCompany({id: this.companyToEdit.id}).subscribe(
      result => {
        this.companyService.companies.delete(this.companyToEdit.id);
        this.isSaving = false;
        this.inputChanged = false;
        this.es.endComponentEdit(TEditableComponent.Company);
        if ( !this.desktopView ) {
          this.miniStatus.hide();
          this.router.navigate([...this.g.getHomeUrl(), 'companies']);
        } else {
          setTimeout(() => {
            if ( this.us.currentUser.company_id === 0 ) {
              this.router.navigate([companyRootRoute, 'companies']);
            } else if ( this.us.currentUser.company_id === this.companyToEdit.id ) {
              document.location.reload();
            } else {
              this.router.navigate([companyRootRoute]);
            }
          }, 1000);
        }
      },
      error => {
        this.isSaving = false;
        if ( !this.desktopView ) { this.miniStatus.hide(); }
      }
    );
  }

  private reinitVariables() {
    this.companyToEdit = this.es.getEditableComponent(TEditableComponent.Company);
    if ( this.desktopView && this.route.routeConfig.path === 'settings/general' && this.companyToEdit === null ) {
      this.router.navigate([companyRootRoute, 'settings']);
      return;
    }
    this.isNew = this.companyToEdit === null || this.route.routeConfig.path === 'new';
    if ( this.isNew ) {
      this.companyToEdit = {
        active: true,
        address: '',
        alias: '',
        city: '',
        country: '',
        email: '',
        id: 0,
        logo: '',
        name: '',
        owner_id: 0,
        phone: '',
        registration_email: '',
        vat_id: '',
      };
      this.companyService.activeCompanyId = 0;
    } else {
      this.countryText = this.locale.getCountryName(this.companyToEdit.country);
    }
    this.canEdit = (this.isNew && this.us.currentUser.permissions?.permissions.company_settings.create) || (!this.isNew && this.us.currentUser.permissions?.permissions.company_settings.edit);
    this.canDelete = this.us.currentUser.permissions?.permissions.company_settings.delete && !this.isNew;
  }

  public handleNewFile(fileArray: FileList) {
    const file = fileArray.item(0);
    const mimeType = file.type;
    if (!mimeType.startsWith('image/svg') && mimeType !== 'image/png') {
      this.toaster.postError(this.trans('settings.errors.notAnImage'));
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ($event) => {
      if (mimeType.startsWith('image/svg')) {
        this.fileToUploadUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      } else {
        this.fileToUploadUrl = reader.result;
      }
    };
    this.fileToUpload = file;
    this.logoChanged = true;
    this.inputChanged = true;
  }

  public imageLoaded(img) {
    this.loadedLogoSize = {
      width: img.naturalWidth,
      height: img.naturalHeight,
    };
  }

  public async removeLogo() {
    if ( this.isSaving ) {
      if ( !this.desktopView ) { this.miniStatus.flash(); }
      return;
    }
    this.isSaving = true;
    if (this.isNew || (this.companyToEdit.logo === '' && this.fileToUploadUrl !== null)) {
      this.companyToEdit.logo = '';
      this.fileToUpload = null;
      this.fileToUploadUrl = null;
      this.loadedLogoSize = null;
      this.isSaving = false;
      this.fileInput.nativeElement.value = '';
      return;
    }
    if ( !this.desktopView ) { this.miniStatus.show(this.trans('general.pleaseWait')); }
    try {
      await requests.company.removeCompanyLogo({ company_id: this.companyToEdit.id }).toPromise();
      this.companyToEdit.logo = '';
      this.fileToUpload = null;
      this.fileToUploadUrl = null;
      this.loadedLogoSize = null;
    } catch(e) {
    }
    this.isSaving = false;
    if ( !this.desktopView ) { this.miniStatus.hide(); }
  }

  public async saveLogo(): Promise<boolean> {
    if ( !this.desktopView) { this.miniStatus.show(this.trans('general.pleaseWait')); }
    const headers = this.api.getAuthorizationHeader();
    headers.append('Content-type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('company_id', this.companyToEdit.id.toString());
    formData.append('logo_image', this.fileToUpload, this.fileToUpload.name);
    try {
      const result = await this.api.httpSender().post(this.api.getUrl('/company/logo'), formData, { headers }).toPromise();
      this.companyToEdit.logo = this.companyService.logoBasePath + (result as SaveCompanyLogoResponse).logoUrl;
      // const result = await requests.company.saveCompanyLogo({ company_id: this.companyToEdit.id, logo_image: this.fileToUpload }).toPromise();
      // this.companyToEdit.logo = this.companyService.logoBasePath + (result as SaveCompanyLogoResponse).logoUrl;
    } catch(e) {
      if ( e.error?.error ) {
        this.toaster.postError(e.error.error);
      }
      console.log('gavom klaida', e);
      return false;
    }
    return true;
  }

  public backButtonPressed() {
    if ( this.isInGeneralSettings ) { return; }
    this.revert();
    if ( this.desktopView ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'companies'], { replaceUrl: true });
    } else {
      history.back();
    }
  }
}
