import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Tag } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { FilterCriteria } from '../company/pages/company-users/company-users.component';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class TagService implements OnDestroy {
  public readonly tags = new Map<number, Tag>();
  private filterCriteria: FilterCriteria = null;
  private previousFilter: string = null;
  private tag = 'TagService';
  private cleanUpSubscriber: Subscription;

  constructor(private l: LoggerService, auth: AuthService) {
    this.cleanUpSubscriber = auth.onAccountOrRegionChnage.subscribe(() => {
      this.tags.clear();
      this.filterCriteria = null;
      this.previousFilter = null;
    });
  }

  ngOnDestroy() {
    this.cleanUpSubscriber?.unsubscribe();
  }

  public ingestTag(tag: Tag) {
    if ( this.tags.has(tag.id) ) { return; }
    this.l.log('Ingesting tag ' + tag.id, this.tag, { tag });
    tag.textColor = TagService.getTextColor(tag.color);
    this.tags.set(tag.id, tag);
  }

  public static getTextColor(backgroundHexColor: string): string {
    const textColors = {
      light: '#ffffff',
      dark: '#282828'
    };
    const r = parseInt(backgroundHexColor.slice(1, 3), 16) / 255;
    const g = parseInt(backgroundHexColor.slice(3, 5), 16) / 255;
    const b = parseInt(backgroundHexColor.slice(5, 7), 16) / 255;
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance > 0.5 ? textColors.dark : textColors.light;
  }

  public getTag(id: number): Tag | undefined {
    const found = this.tags.get(id);
    if ( found ) { return found; }
    if ( id === 0 ) { return undefined; }

    this.loadTagById(id);
    return this.tags.get(id);
  }

  public async loadTagById(id: number) {
    try {
      const response = await requests.tag.getTag({id}).toPromise();
      this.ingestTag(response.tag);
    } catch (e) {
      console.log(e);
    }
  }

  public getTagsGetter(filterCriteria: FilterCriteria | null): DataTableGetter<Tag> {
    const tagService = this;
    if ( filterCriteria ) {
      if (
        !this.filterCriteria
        || this.filterCriteria.searchPhrase !== filterCriteria.searchPhrase
        || JSON.stringify(filterCriteria.searchFields) !== JSON.stringify(this.filterCriteria.searchFields)
      ) {
        this.tags.clear();
      }
      this.previousFilter = JSON.stringify(this.filterCriteria);
      this.filterCriteria = JSON.parse(JSON.stringify(filterCriteria));
    } else if ( this.filterCriteria !== null ) {
      this.filterCriteria = null;
      this.tags.clear();
    }
    return async (current, columns, more) => {
      this.l.log('Load tags', '', { current, more });
      if ( !more ) { // data table nori atsinaujinti duomenis is atminties.
        if ( tagService.tags.size > 0 ) {
          return [...tagService.tags.values()];
        }
        if ( JSON.stringify(tagService.filterCriteria) === tagService.previousFilter ) {
          return [...tagService.tags.values()];
        }
      }
      tagService.previousFilter = JSON.stringify(tagService.filterCriteria);
      const result = await requests.tag.getTags(filterCriteria ? {
        searchPhrase: filterCriteria.searchPhrase,
        searchFields: filterCriteria.searchFields,
        paginationPage: filterCriteria.currentPage + 1,
      } : null).toPromise();
      if ( !tagService.filterCriteria ) {
        tagService.filterCriteria = {
          searchPhrase: '',
          searchFields: [],
          currentPage: result.list.current_page,
          lastPage: result.list.last_page,
        };
        tagService.previousFilter = JSON.stringify(tagService.filterCriteria);
        if ( result.list.total === tagService.tags.size ) {
          tagService.filterCriteria.currentPage = result.list.last_page;
        }
      } else {
        tagService.filterCriteria.currentPage = result.list.current_page;
        tagService.filterCriteria.lastPage = result.list.last_page;
        tagService.previousFilter = JSON.stringify(tagService.filterCriteria);
      }
      result.list.data.forEach((t) => tagService.ingestTag(t));
      return [...tagService.tags.values()];
    };
  }

  public async loadAllTags() {
    const result = await requests.tag.getTags({}).toPromise();
    result.list.data.forEach((t) => this.ingestTag(t));
  }
}
