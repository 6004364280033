import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { Router } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { PermissionRole } from 'src/api/v3/common';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransferComponent extends LanguageAware implements OnInit {
  public controlsEnabled = true;
  public targetEmail = '';
  public filteredPeople = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.transferSystem'),
      backUrl: '*',
    });
    this.footerBar.showFooter(this.trans('systems.buttons.transfer'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.onConfirm();
    };
    this.background.setGray();
    this.filteredPeople = this.getPeople();
    if (this.es.emailToTransferTo !== '') {
      this.targetEmail = this.es.emailToTransferTo;
      this.es.emailToTransferTo = '';
    }
  }

  ngOnInit(): void {}

  public onConfirm() {
    if (!this.controlsEnabled) {
      this.miniStatus.flash();
      return;
    }
    this.targetEmail = this.targetEmail.trim();
    if (this.targetEmail === null || this.targetEmail === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('auth.form.email')));
      this.controlsEnabled = true;
      this.miniStatus.hide();
      return;
    }
    this.targetEmail = this.targetEmail.toLowerCase();
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.targetEmail)) {
      this.toaster.postError(this.trans('validation.emailInvalid'));
      this.controlsEnabled = true;
      this.miniStatus.hide();
      return;
    }
    this.controlsEnabled = false;
    this.miniStatus.show(this.trans('systems.statuses.transfering'));
    const activeSystemId = this.systems.activeSystem.id;
    this.api.post('/transfer', {
      system_id: this.systems.activeSystem.id,
      transfer_to_email: this.targetEmail,
    }, true).subscribe(
      (result) => {
        this.controlsEnabled = true;
        this.miniStatus.hide();
        if (result.success) {
          if (this.us.currentUser.permissions?.role === PermissionRole.Installer && activeSystemId === this.us.currentUser.id) {
            if (this.us.currentUser.historySystems === undefined) {
              this.us.currentUser.historySystems = [];
            }
            this.us.currentUser.historySystems.push({
              name: this.systems.activeSystem.name,
              address: this.systems.activeSystem.address,
              id: 0,
              installer_id: this.us.currentUser.id,
              primary_installer: this.us.currentUser.id,
              system_imei: this.systems.activeSystem.imei,
            });
          }
          if ( this.us.currentUser.permissions?.role === PermissionRole.Installer || this.us.currentUser.permissions?.role === PermissionRole.GeneralUser ) {
            this.systems.removeSystem(activeSystemId);
            if (this.systems.hasSystems()) {
              this.systems.setCurrentSystemFromInternalList(false);
            } else {
              this.systems.setCurrentSystem(null);
            }
          }
          this.router.navigate(this.g.getHomeUrl());
        } else {
          this.toaster.postError(result.error);
        }
      },
      (error) => {
        this.miniStatus.hide();
        this.controlsEnabled = true;
      }
    );
  }

  private getPeople(filter?: string): any[] {
    if (filter === undefined || filter === '') {
      return [];
    }

    return this.systems.activeSystem.device_users.filter(
      (u) => u.protegus_user_id !== 0 && u.email !== this.us.currentUser.email && (u.email.indexOf(filter) !== -1 || u.name.indexOf(filter) !== -1)
    );
  }

  public doFilter(value: string) {
    this.targetEmail = value;
    this.filteredPeople = this.getPeople(value);
  }

  public selectPerson(email: string) {
    this.targetEmail = email;
  }
}
