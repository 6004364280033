<div [id]="datePickerSectionId" class="dateRangePickerWrap">
  <div class="dateRangePickerButton" (click)="dateRangePickerVisible = !dateRangePickerVisible">
    <div class="calendarIcon" inlineSVG="assets/images/calendar.svg"></div>
    {{ dateRangeText }}
    <div class="calendarIcon" inlineSVG="assets/images/triangle_down.svg"></div>
  </div>
  <div *ngIf="dateRangePickerVisible" class="dateRangePickerSection">
    <div class="flex">
      <div class="dateRangePickerCalendars">
        <app-datetime-input type="date" label="Start date" [(value)]="startDate"></app-datetime-input>
        <app-datetime-input type="date" label="End date" [(value)]="endDate"></app-datetime-input>
      </div>
      <div class="dateRangePickerPeriods">
        <p>{{ trans('events.filter.predefinedPeriods') }}:</p>
        <p class="text" [ngClass]="{'text-selected' : dateRangeCount === '1'}" (click)="quickDate(1)">{{ trans('events.filter.day') }}</p>
        <p class="text" [ngClass]="{'text-selected' : dateRangeCount === '7'}" (click)="quickDate(7)">{{ trans('events.filter.week') }}</p>
        <p class="text" [ngClass]="{'text-selected' : dateRangeCount === '30'}" (click)="quickDate(30)">{{ trans('events.filter.month') }}</p>
      </div>
    </div>
    <div class="dateRangePickerFooter">
      <div (click)="clearDateSelection()" class="cancelSection">{{ trans('events.filter.clear') }}</div>
      <div class="selectedSection">
        <div *ngIf="dateRangeCount !== ''" class="text-selected">{{ trans('events.filter.selectedCount').replace(':number', dateRangeCount) }} {{ dateRangeCount === '1' ?  trans('events.filter.selectedDay') : trans('events.filter.selectedDays') }}</div>
        <app-button type="filled-secondary" rounded="partial" (click)="onDateSelectComplete()">{{ trans('events.titles.filter') }}</app-button>
      </div>
    </div>
  </div>
</div>
