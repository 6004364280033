<div *ngIf="!success">
    <div *ngIf="connecting" id="dac_loader_container">
        <app-loader-round [isInline]="true" [isVisible]="true" [backgroundColor]="''" [height]="150"></app-loader-round>
        <div class="dac_text">{{trans('systems.labels.connectingToWifiDevice') + ' ' + ds.getDeviceNameById()}}</div>
    </div>
    <div *ngIf="!connecting">
    
    </div>
</div>
<div *ngIf="success">

</div>