<ng-template #headerName let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.securityCName')}}</div></ng-template>
<ng-template #headerReceiverNum let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.receiverNumber')}}</div></ng-template>
<ng-template #headerLineNum let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.lineNumber')}}</div></ng-template>
<ng-template #headerInPort let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.inputPort')}}</div></ng-template>
<ng-template #headerOutHost let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.outputHost')}}</div></ng-template>
<ng-template #headerOutPort let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.outputPort')}}</div></ng-template>
<ng-template #headerNetMode let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.networkMode')}}</div></ng-template>
<ng-template #headerDevPlus let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.positiveDeviation')}}</div></ng-template>
<ng-template #headerDevMinus let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.negativeDeviation')}}</div></ng-template>
<ng-template #headerOutProtocol let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.outputProtocol')}}</div></ng-template>
<ng-template #headerTags let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.tags')}}</div></ng-template>
<ng-template #headerOutHb let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.heartBeatEnabled')}}</div></ng-template>
<ng-template #headerOutHbInterval let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.heartBeatInterval')}}</div></ng-template>
<ng-template #headerOutBuffer let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.outputBufferSize')}}</div></ng-template>
<ng-template #headerOutIdenctificator let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.outputIdentificator')}}</div></ng-template>
<ng-template #headerCompany let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.companySelect')}}</div></ng-template>
<ng-template #headerOutEncryptionEnabled let-class="className"><div [ngClass]="class">{{trans('settings.ipcom.outputEncryptionEnabled')}}</div></ng-template>


<ng-template #netModeContent let-value="item.network_mode">
    {{trans('settings.ipcom.networkModes.' + value)}}
</ng-template>
<ng-template #inPortContent let-value="item">
    {{ value.receiver_id === 0 ? trans('general.notAvailable') : value.port === 0 ? '' : value.port }}
</ng-template>
<ng-template #outPortContent let-value="item">
    {{ value.port === 0 ? '' : value.port }}
</ng-template>
<ng-template #outProtocolContent let-value="item.out_mode">
    {{ trans('settings.ipcom.outputProtocols.' + value) }}
</ng-template>
<ng-template #tagsContent let-value="item.tags">
    <app-inline-tag-list [tags]="value"></app-inline-tag-list>
  </ng-template>
<ng-template #companyContent let-value="item.company_id">
    {{value === 0 ? '' : companyService.getCompany(value)?.name}}
</ng-template>
<ng-template #deleteIcon let-value="item.receiver_id">
    <div class='deleteIcon' *ngIf="this.us.currentUser.permissions.permissions.ipcom_settings.delete && value !== 0" [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>
<ng-template #outEncryptionEnabled let-value="value">
    <div class="checkIcon" *ngIf="value === 'True'">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 26.846">
            <path d="M12.227,30.9.527,19.2a1.8,1.8,0,0,1,0-2.546L3.073,14.1a1.8,1.8,0,0,1,2.546,0L13.5,21.986,30.382,5.1a1.8,1.8,0,0,1,2.546,0L35.473,7.65a1.8,1.8,0,0,1,0,2.546l-20.7,20.7A1.8,1.8,0,0,1,12.227,30.9Z" transform="translate(0 -4.577)"/>
        </svg>
    </div>
</ng-template>
<app-data-table
    *ngIf="us.currentUser?.permissions.permissions.ipcom_settings.view"
    class="dataTable"
    [data]="loader"
    [slots]="{
      'header:receiver_name': headerName,
      'header:network_mode': headerNetMode,
      'network_mode': netModeContent,
      'header:port': headerInPort,
      'port': inPortContent,
      'header:receiver_number': headerReceiverNum,
      'header:line_number': headerLineNum,
      'header:deviation_negative': headerDevMinus,
      'header:deviation_positive': headerDevPlus,
      'header:ip': headerOutHost,
      'header:tcp_port': headerOutPort,
      'tcp_port': outPortContent,
      'header:out_mode': headerOutProtocol,
      'out_mode': outProtocolContent,
      'header:hb_enable': headerOutHb,
      'header:hb_interval': headerOutHbInterval,
      'header:buffer_size': headerOutBuffer,
      'header:identificator': headerOutIdenctificator,
      'header:tags': headerTags,
      'tags': tagsContent,
      'header:company_id': headerCompany,
      'company_id': companyContent,
      'header:encryption_enabled': headerOutEncryptionEnabled,
      'encryption_enabled': outEncryptionEnabled,
      'fake:delete': deleteIcon
    }"
    [columns]="columns"
    (modifyColumns)="modifyColumns.emit()"
    (rowClick)="onRowClick($event)"
    [dataSize]="receivers.length"
    [showHeaders]="showHeaders"
    [activeItemId]="-1"
>
</app-data-table>
