<ng-template #header let-col="column" let-i="index">
  <div [ngClass]="['headerItem', usedColumns.length - 1 === i ? 'last' : '']">{{ col.startsWith('fake:') ? '' : startCase(col) }}</div>
</ng-template>
<ng-template #content let-item="item" let-col="column">
  <div>{{ item[col] ?? '' }}</div>
</ng-template>
<ng-template #row> </ng-template>
<ng-template #emptyCell><div></div></ng-template>
<div *ngIf="usedColumns.length === 0; else dt" class="noCollumns">
  <h2>{{trans('general.noColumns')}}</h2>
  <span>{{trans('general.go')}} > {{trans('general.modifyColumns')}}</span>
  <app-icon-button icon="assets/images/company/columns.svg" [label]="trans('general.modifyColumns')" (click)="modifyColumns.emit()"></app-icon-button>
</div>
<ng-template #dt>
  <div class="dataTable" [style]="dataTableStyle">
    <ng-container *ngFor="let col of usedColumns; let i = index">
      <ng-container *ngIf="showHeaders"
        [ngTemplateOutlet]="getSlot(col, 'header') ?? header"
        [ngTemplateOutletContext]="{ column: col, index: i, className: ['headerItem', usedColumns.length - 1 === i ? 'last' : ''] }"
      >
      </ng-container>
      <ng-container  *ngIf="!showHeaders" [ngTemplateOutlet]="emptyCell"></ng-container>
    </ng-container>
    <ng-container *ngFor="let item of shownData; let i = index">
      <ng-container *ngIf="getRowInsert(i) as ri" [ngTemplateOutlet]="getSlot('%row') ?? row" [ngTemplateOutletContext]="{ item, i, data: shownData, ctx: ri.ctx }"> </ng-container>
      <div
        *ngFor="let col of usedColumns; let rowNumber = index"
        [ngClass]="['dataItemContainer', shownData.length - 1 === i ? 'last' : '', activeItemId === item.id ? 'activeItem' : '']"
        (click)="rowClick.emit({ row: rowNumber, column: col, item })"
        (contextmenu)="rowContextClick.emit({ row: rowNumber, column: col, item, event: $event})"
      >
        <ng-container [ngTemplateOutlet]="getSlot(col) ?? content" [ngTemplateOutletContext]="{ column: col, row: i, item: item, value: item[col] }"> </ng-container>
      </div>
    </ng-container>
    <div class="loader" #loader>
      <ng-container *ngIf="isLoading">
        <div></div>
      </ng-container>
    </div>
  </div>
</ng-template>
