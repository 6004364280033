import config from './config';
import event_log from './event_log';
import language from './language';
import users from './users';

export default {
  language,
  config,
  users,
  event_log,
};
