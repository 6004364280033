import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-signal-level',
  templateUrl: './signal-level.component.html',
  styleUrls: ['./signal-level.component.scss'],
})
export class SignalLevelComponent implements OnInit {
  @Input() currentLevel = 0;
  @Input() maxLevel = 10;
  @Input() width = 25;
  @Input() height = 25;

  constructor() {}

  ngOnInit(): void {}

  public currentLevelToPxW(): string {
    const percent = this.currentLevel / this.maxLevel;
    const pixels = Math.floor(this.width * percent);
    return pixels.toString(10);
  }

  public currentLevelToPxH(): string {
    const percent = this.currentLevel / this.maxLevel;
    const pixels = Math.floor(this.height * percent);
    return pixels.toString(10);
  }
}
