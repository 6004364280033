<div class="formGroup">
    <label [ngClass]="['label', _val === '' && selectedTags.length === 0 ? 'empty' : '', selectedTags.length > 0 ? 'withBottomOffset' : '']" [for]="name">{{ label }}</label>
    <div>
        <ng-container *ngFor="let iTag of selectedTags">
            <div [style.background-color]="iTag.color" [style.color]="iTag.textColor" class="tagItem" (click)="tagDeselected(iTag)"><div *ngIf="!isReadOnly" class="removeTag"><div [inlineSVG]="'assets/images/delete.svg'"></div></div>{{iTag.name}}</div>
        </ng-container>
        <input type="text" [(ngModel)]="val" [name]="name" id="{{name}}" class="input" [disabled]="isReadOnly"/>
    </div>
    
    <div class="wrap" *ngIf="loadedLookupTags.length > 0 || noTagsShowing">
        <div class="popupSection">
            <div *ngIf="loadedLookupTags.length === 0">{{lang.get('tags.labels.noTagsFound')}}</div>
            <ng-container *ngFor="let iTag of loadedLookupTags">
                <div [style.background-color]="iTag.color" [style.color]="iTag.textColor" class="tagItem" (click)="tagSelected(iTag)">{{iTag.name}}</div>
            </ng-container>
        </div>
    </div>
</div>