<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('auth.labels.registration') }}</h2>
      <p>{{ trans('auth.labels.registrationPrompt') }}</p>
    </div>
    <form (submit)="$event.preventDefault(); next()">
      <app-string-input [label]="trans('auth.form.email')" type="email" name="email" autocomplete="email" [(value)]="login.email" [hasError]="val.hasError('email')" [autofocus]="true" [disabled]="isLoading">
        <app-form-error *ngIf="val.hasError('email')">
          <app-tmapper
            [key]="val.getError('email')"
            [default]="defaultEmailError"
            [context]="{}"
            [map]="{
              NO_PASSWORD: noPassword,
              LOCAL_USER: localUser,
              DISABLED_USER: disabledUser,
              EMAIL_REQUIRED: emailRequired,
              EMAIL_INVALID: emailInvalid
            }"
          ></app-tmapper>
        </app-form-error>
      </app-string-input>
      <div class="social-login">
        <app-google-login *ngIf="canShowGoogle"></app-google-login>
        <app-apple-login *ngIf="canShowApple"></app-apple-login>
      </div>
      <app-button type="filled-secondary" class="mainButton" [isLoading]="isLoading">{{ trans('general.next') }}</app-button>
    </form>
  </div>
</app-login-layout>
<ng-template #defaultEmailError>
  <span>{{ val.getError('email') }}</span>
</ng-template>
<ng-template #noPassword>
  <span>{{ trans('auth.errors.noPasswordSet') }} <a routerLink="/login/password-reset/email">{{ trans('auth.errors.noPasswordSetLink') }}</a></span>
</ng-template>
<ng-template #localUser>
  <span>{{ trans('auth.errors.userExists') }} <a routerLink="/login/password">{{ trans('auth.errors.userExistsLink') }}</a></span>
</ng-template>
<ng-template #disabledUser>
  <span>{{ trans('auth.errors.notActivated') }} <a routerLink="/login/register/activate/resend" (click)="isLoading = true">{{ trans('auth.errors.notActivatedLink') }}</a></span>
</ng-template>
<ng-template #socialGoogle>
  <span>{{ trans('auth.errors.useGoogleLogin') }} <a href="javascript:void(0)" (click)="googleAuth.signIn()">{{ trans('auth.errors.useGoogleLoginLink') }}</a></span>
</ng-template>
<ng-template #social>
  <span>{{ trans('auth.errors.useSocialLogin') }}</span>
</ng-template>
<ng-template #emailRequired>
  <span>{{ trans('auth.errors.requiredEmail') }}</span>
</ng-template>
<ng-template #emailInvalid>
  <span>{{ trans('auth.errors.invalidEmail') }}</span>
</ng-template>
