import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewSystemService, PanelData } from 'src/app/services/new-system.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-panel-selection',
  templateUrl: './panel-selection.component.html',
  styleUrls: ['./panel-selection.component.scss']
})
export class PanelSelectionComponent extends LanguageAware implements OnInit {

  constructor(
    cdRef: ChangeDetectorRef,
    public ns: NewSystemService,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    if ( !this.ns.isSystemBeingCreated() ) {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    if ( this.ns.selectedManufacturer.panels.length === 1 ) {
      this.ns.selectedPanel = this.ns.selectedManufacturer.panels[0];
      this.router.navigate(['../quick-settings'], { relativeTo: this.r, replaceUrl: true });
      return;
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.panelSelection'),
      backUrl: '*',
    });
    this.background.setGray();
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
    };
  }

  ngOnInit(): void {
  }

  public panelSelected(panel: PanelData) {
    this.ns.selectedPanel = panel;
    this.router.navigate(['../quick-settings'], { relativeTo: this.r });
  }
}
