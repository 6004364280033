import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-name-value',
  templateUrl: './name-value.component.html',
  styleUrls: ['./name-value.component.scss'],
})
export class NameValueComponent implements OnInit {
  @Input() nameText = '';
  @Input() showUnderline = true;
  @Input() isEnabled = true;
  @Input() icon = '';
  @Input() showEdit = false;
  @Output() clicked: EventEmitter<any> = new EventEmitter();
  @Output() editClicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public doClick() {
    if (this.isEnabled) {
      this.clicked.emit();
    }
  }

  public doEditClick($event) {
    if (this.isEnabled) {
      this.editClicked.emit();
    }
    $event.stopPropagation();
  }
}
