<div *ngIf='keypadType === "inline"' class="area-keypad-grid">
    <div class='akp-dots'>{{pinMask}}</div>
    <div class="akp-title">{{trans('systems.titles.enterPinCode')}}</div>
    <div class="akp-row1 akp-col2 akp-digit" (click)='digitClicked("1")'>1</div>
    <div class="akp-row1 akp-col3 akp-digit" (click)='digitClicked("2")'>2</div>
    <div class="akp-row1 akp-col4 akp-digit" (click)='digitClicked("3")'>3</div>
    <div class="akp-row1 akp-col5 akp-digit" (click)='digitClicked("4")'>4</div>
    <div class="akp-row1 akp-col6 akp-digit" (click)='digitClicked("5")'>5</div>
    <div class="akp-row2 akp-col2 akp-digit" (click)='digitClicked("6")'>6</div>
    <div class="akp-row2 akp-col3 akp-digit" (click)='digitClicked("7")'>7</div>
    <div class="akp-row2 akp-col4 akp-digit" (click)='digitClicked("8")'>8</div>
    <div class="akp-row2 akp-col5 akp-digit" (click)='digitClicked("9")'>9</div>
    <div class="akp-row2 akp-col6 akp-digit" (click)='digitClicked("0")'>0</div>
    <app-checkbox class="akp-remember" [checkValue]='false' [isRound]='false' (valueChanged)='rememberClicked($event)'>{{trans('systems.buttons.rememberAreaPin')}}</app-checkbox>
</div>
<div *ngIf='keypadType === "slideout"' id='slideout-keypad-container' (click)='onCancelClick()'>
    <div id="numpad" class="grid" [@showKeypad]="isVisible" (click)='$event.stopPropagation()'>
        <div id="numpad-background" class="group-background"></div>
        <div id="numpad-title">{{trans('systems.titles.enterPinCode')}}</div>
        <app-password-edit id='numpad-pin' [isReadOnly]='true' [value]='pin' [noOutline]='true'></app-password-edit>
        <app-checkbox id='numpad-remember' [checkValue]='doRememberPin' [isRound]='false' (valueChanged)='rememberClicked($event)' [isBig]="true">{{trans('systems.buttons.rememberAreaPin')}}</app-checkbox>     
        <div id='numpad-back-grey'></div>   
        <div class="nums" id='numpad-row1'>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 1 }" (touchstart)="onNumberDown(1)" (touchend)="onNumberUp()" (click)="digitClicked('1')">1</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 2 }" (touchstart)="onNumberDown(2)" (touchend)="onNumberUp()" (click)="digitClicked('2')">2</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 3 }" (touchstart)="onNumberDown(3)" (touchend)="onNumberUp()" (click)="digitClicked('3')">3</div>
        </div>
        <div class="nums" id='numpad-row2'>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 4 }" (touchstart)="onNumberDown(4)" (touchend)="onNumberUp()" (click)="digitClicked('4')">4</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 5 }" (touchstart)="onNumberDown(5)" (touchend)="onNumberUp()" (click)="digitClicked('5')">5</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 6 }" (touchstart)="onNumberDown(6)" (touchend)="onNumberUp()" (click)="digitClicked('6')">6</div>
        </div>
        <div class="nums" id='numpad-row3'>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 7 }" (touchstart)="onNumberDown(7)" (touchend)="onNumberUp()" (click)="digitClicked('7')">7</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 8 }" (touchstart)="onNumberDown(8)" (touchend)="onNumberUp()" (click)="digitClicked('8')">8</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 9 }" (touchstart)="onNumberDown(9)" (touchend)="onNumberUp()" (click)="digitClicked('9')">9</div>
        </div>
        <div class="nums" id='numpad-row4'>
            <div [ngClass]="{ 'numButton' : true, 'backspaceIcon' : true, 'numButton-pressed' : pressedButton === 10 }" (touchstart)="onNumberDown(10)" (touchend)="onNumberUp()" (click)="digitClicked('10')" [inlineSVG]="'assets/images/backspace.svg'"></div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 0 }" (touchstart)="onNumberDown(0)" (touchend)="onNumberUp()" (click)="digitClicked('0')">0</div>
            <div [ngClass]="{ 'numButton' : true, 'enterIcon' : true, 'numButton-pressed' : pressedButton === 11 }" (touchstart)="onNumberDown(10)" (touchend)="onNumberUp()" (click)="digitClicked('11')" [inlineSVG]="'assets/images/enter.svg'"></div>
        </div>
    </div>
</div>
