import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import requests from 'src/api/v3/requests';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-transfer-system-to-company',
  templateUrl: './transfer-system-to-company.component.html',
  styleUrls: ['./transfer-system-to-company.component.scss']
})
export class TransferSystemToCompanyComponent extends LanguageAware implements OnInit {
  public companyAlias = '';

  constructor(
    cdRef: ChangeDetectorRef
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.transferToCompany'),
      backUrl: '*',
    });
    this.background.setGray();
    this.footerBar.showFooter(this.trans('systems.buttons.transfer'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.onConfirm();
    };
  }

  ngOnInit(): void {
  }

  public async onConfirm() {
    if ( this.miniStatus.isVisible() ) {
      this.miniStatus.flash();
      return;
    }

    this.miniStatus.show(this.trans('systems.statuses.transfering'));
    try {
      await requests.system.transferToCompany({
        system_id: this.systems.activeSystem.id,
        company_alias: this.companyAlias,
      }).toPromise();

      this.miniStatus.hide();
      window.location.reload();
    } catch (e) {
      this.miniStatus.hide();
    }
  }
}
