import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TDeviceUser } from 'src/app/models/device-user';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PgmData } from 'src/app/models/pgm';
import { ToggleButtonComponent } from 'src/app/components/toggle-button/toggle-button.component';
import { PgmService } from 'src/app/api/system/pgm.service';
import { PermissionService } from 'src/app/api/permission.service';
import { PermissionRole } from 'src/api/v3/common';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserComponent extends LanguageAware implements OnInit {
  @ViewChild('adminToggle') adminToggle: ToggleButtonComponent;
  public userToUse: TDeviceUser = null;
  private myselfInDevice: TDeviceUser = null;
  public canEditUsersEnabled = false;
  public canShowMakeAdministrator = false;
  public canControlOutputs = false;
  public canShowZoneNumber = false;
  public isNewUser = true;
  public isUnauthorisedUser = false;
  public availableOutputs: PgmData[] = [];
  public assignedOutputs = [];
  public maxAdminCount = 0;
  public simpleUsersStartFrom = 0;
  public isUserEnabled = false;
  public readonly isCg = this.systems.activeSystem.hwType === 'CG17';
  public canSeeEventsEnabled = false;
  public canEditUserKeyboardCode = this.systems.activeSystemHelper.can.edit.userKeyboardCode() && this.systems.activeSystem.hwType !== 'FC';
  public pinLength = this.getPinLength();
  public canEditUsers = false;
  public canSeeEvents = false;
  public canEdit = this.systems.activeSystemHelper.can.edit.users();

  constructor(
    cdRef: ChangeDetectorRef,
    public es: EditSystemService,
    public pgmS: PgmService,
  ) {
    super(cdRef);
    this.tag = 'User';
    this.isNewUser = this.es.getEditableComponent(TEditableComponent.User) === null;
    this.background.setGray();
    if ( this.canEdit ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.isNewUser ? this.trans('systems.buttons.addUser') : this.trans('general.save'), true, false);
    }
    this.myselfInDevice = this.systems.activeSystem.device_users.find((u) => u.email === this.us.currentUser.email);
    if (this.isNewUser) {
      const found =
        this.systems.activeSystem.device_users.length > 0 ? this.systems.activeSystem.device_users.reduce((u1, u2) => (u1.id < u2.id ? u1 : u2)) : { id: Number.MAX_SAFE_INTEGER };
      const idToUse = found === undefined || found.id > 0 ? 0 : found.id - 1;
      this.userToUse = new TDeviceUser({
        id: idToUse,
        areas: '',
        code: '',
        email: '',
        enable_data: 1,
        name: '',
        pgms: 0,
        phone: '',
        present: false,
        protegus_user_id: 0,
        schedule_no: 0,
        zone_number: this.es.getAvailableZoneNumber(1, 1, idToUse),
      });
      this.footerBar.onButton2Click = () => {
        this.doAddUser();
      };
    } else {
      this.userToUse = this.es.getEditableComponent(TEditableComponent.User);
      if ( this.isCg ) {
        this.userToUse.email = this.userToUse.name;
      }
      if (this.systems.activeSystem.amIMaster && this.userToUse.protegus_user_id === this.us.currentUser.id) {
        this.canEditUsers = true;
      }
      this.footerBar.onButton2Click = () => {
        this.saveChanges();
      };
    }
    const title = this.isNewUser ? this.trans('systems.titles.addNewUser') : this.trans('systems.titles.editUser');
    this.isUnauthorisedUser = (this.systems.activeSystem.hwType === 'GV17' || this.systems.activeSystem.hwType === 'CWI') && this.userToUse.zone_number === 10;
    this.headerBar.showHeader({
      headerText: title,
      backUrl: '*',
      actionButtonText:
        !this.systems.activeSystemHelper.can.delete.users() ||
        this.isNewUser ||
        this.isUnauthorisedUser
          ? ''
          : this.trans('systems.buttons.deletePerson'),
    });
    this.headerBar.onBackClicked = () => {
      if (!this.isNewUser) {
        const old = this.es.getComponentBeforeModification(TEditableComponent.User);
        this.revertSave(old, this.systems.activeSystem.id, this.userToUse.id);
      }
    };
    this.footerBar.onButton1Click = () => {
      if (!this.isNewUser) {
        const old = this.es.getComponentBeforeModification(TEditableComponent.User);
        this.revertSave(old, this.systems.activeSystem.id, this.userToUse.id);
      }
      history.back();
    };
    if (!this.isNewUser) {
      this.headerBar.onActionButtonClicked = () => {
        this.doDeleteUser();
      };
    }
    this.canControlOutputs = this.systems.activeSystem.hwType === 'GV17' || this.systems.activeSystem.hwType === 'CWI';
    this.availableOutputs = this.systems.activeSystem.pgms.filter((p) => p.enabled && p.io_type !== 1);
    if (this.canControlOutputs && this.isNewUser) {
      this.userToUse.pgms = 0x04;
    }
    for (const iOut of this.systems.activeSystem.pgms) {
      let value = false;
      /* eslint-disable no-bitwise */
      if (iOut.queue_no > 2) {
        // GV moduliuose 3 4 5 1 2
        // tslint:disable-next-line: no-bitwise
        value = (this.userToUse.pgms & (0x01 << (iOut.queue_no - 3))) > 0;
      } else {
        // tslint:disable-next-line: no-bitwise
        value = (this.userToUse.pgms & (0x01 << (iOut.queue_no + 2))) > 0;
      }
      /* eslint-enable no-bitwise */
      this.assignedOutputs.push({ value, queue_no: iOut.queue_no });
    }
    if (this.isNewUser && this.systems.activeSystemHelper.supports.areas() && this.systems.activeSystem.areas.length > 0) {
      this.userToUse.areas = ''.padEnd(this.systems.activeSystem.areas.length, '1');
    }
    if (this.systems.activeSystemHelper.supports.cgTypeUsers()) {
      this.maxAdminCount = 8;
      this.simpleUsersStartFrom = 9;
    } else if (this.systems.activeSystemHelper.supports.gvTypeUsers()) {
      if (this.systems.activeSystem.hwType === 'CWI') {
        this.maxAdminCount = 0;
      } else {
        this.maxAdminCount = 7;
      }
      this.simpleUsersStartFrom = 11;
    } else {
      this.maxAdminCount = 0;
      this.simpleUsersStartFrom = 1;
    }
    this.handleElementVisibility();
    if (this.isNewUser && this.userToUse.zone_number > this.maxAdminCount && this.userToUse.zone_number < this.simpleUsersStartFrom) {
      this.userToUse.zone_number = this.es.getAvailableZoneNumber(this.simpleUsersStartFrom, this.simpleUsersStartFrom, this.userToUse.id);
    }
    if ( this.isNewUser && this.userToUse.zone_number <= this.maxAdminCount ) {
      const newSimpleUser = this.es.getAvailableZoneNumber(this.simpleUsersStartFrom, this.simpleUsersStartFrom, this.userToUse.id);
      if ( newSimpleUser !== 0 ) { // Default "is admin" varna bus išjungta jeigu yra vietos paprastu vartotoju saraše.
        this.userToUse.zone_number = newSimpleUser;
      }
    }
    if ( this.systems.activeSystemHelper.supports.gvTypeUsers() ) {
      /* eslint-disable no-bitwise */
      this.isUserEnabled = (this.userToUse.enable_data & 0x01) > 0;
      /* eslint-enable no-bitwise */
    }
    this.canEditUsers = this.loadEditUsersValue();
    this.canSeeEvents = this.loadCanSeeEventsValue();
  }

  ngOnInit(): void {}

  private trimFields() {
    this.userToUse.name = this.userToUse.name.trim();
    this.userToUse.phone = this.userToUse.phone.trim();
    this.userToUse.email = this.userToUse.email.trim();
    this.userToUse.code = this.userToUse.code.trim();
  }

  private validateFields(): boolean {
    if (this.userToUse.phone !== '' && !/[0-9-+]/.test(this.userToUse.phone)) {
      this.toaster.postError(this.trans('validation.phoneInvalid'));
      return false;
    }
    if (this.userToUse.email !== '' && !this.isCg && !this.validateEmail()) {
      this.toaster.postError(this.trans('validation.emailInvalid'));
      return false;
    }
    if (this.userToUse.code !== '' && !/^[0-9]{4}$|^[0-9]{6}$/.test(this.userToUse.code)) {
      this.toaster.postError(this.trans('validation.pinInvalid'));
      return false;
    }
    if (!this.validateZoneNumber(this.userToUse.zone_number)) {
      return false;
    }
    if (
      this.userToUse.code === ''
      && ( (!this.isCg && this.userToUse.email === '') || (this.isCg && this.userToUse.name === '') )
      && this.userToUse.phone === ''
      && !this.isUnauthorisedUser
    ) {
      this.toaster.postError(this.trans('users.errors.userDetailsRequired'));
      return false;
    }
    return true;
  }

  private validateEmail(): boolean {
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.userToUse.email)) {
      return false;
    }
    return true;
  }

  private handleElementVisibility() {
    const amIAdmin = this.myselfInDevice !== undefined && this.myselfInDevice.zone_number <= this.maxAdminCount;
    this.canControlOutputs = this.systems.activeSystem.hwType === 'GV17' || this.systems.activeSystem.hwType === 'CWI';
    this.canShowZoneNumber =
      this.systems.activeSystem.hwType !== 'SP3' &&
      this.systems.activeSystem.hwType !== 'FC' &&
      this.systems.activeSystem.hwType !== 'CG17' &&
      this.systems.activeSystem.hwType !== 'GV17' &&
      this.systems.activeSystem.hwType !== 'CWI';
    const permissions = this.systems.currentUserPermissions;
    const userValueBefore = this.canEditUsersEnabled;
    const eventsValueBefore = this.canSeeEventsEnabled;
    this.canEditUsersEnabled =
      (permissions?.permissions.sys_users.edit || amIAdmin) &&
      this.userToUse.protegus_user_id !== this.us.currentUser.id &&
      this.validateEmail();
    this.canSeeEventsEnabled =
      (permissions?.permissions.sys_users.edit || (amIAdmin && permissions?.permissions.sys_events.view)) &&
      this.userToUse.protegus_user_id !== this.us.currentUser.id &&
      this.validateEmail();
    this.canShowMakeAdministrator =
      (permissions?.permissions.sys_users.edit || amIAdmin) &&
      (this.systems.activeSystem.hwType === 'GV17' || this.systems.activeSystem.hwType === 'SP3' || this.systems.activeSystem.hwType === 'FC');

    if ( this.canEditUsersEnabled !== userValueBefore && this.isNewUser ) {
      if ( this.canEditUsersEnabled ) {
        const defaultPermissions = this.permissionService.getDefaultPermissionFor(PermissionRole.SystemUser, this.systems.activeSystem.company_id);
        this.canEditUsers = defaultPermissions?.permissions.sys_users.edit;
      } else {
        this.canEditUsers = false;
      }
    }
    if ( this.canSeeEventsEnabled !== eventsValueBefore && this.isNewUser ) {
      if ( this.canSeeEventsEnabled ) {
        const defaultPermissions = this.permissionService.getDefaultPermissionFor(PermissionRole.SystemUser, this.systems.activeSystem.company_id);
        this.canSeeEvents = defaultPermissions?.permissions.sys_events.view;
      } else {
        this.canSeeEvents = false;
      }
    }
  }

  private doAddUser() {
    if (!this.isNewUser) {
      return;
    }
    if (!this.validateZoneNumber(this.userToUse.zone_number)) {
      return;
    }

    const systemId = this.systems.activeSystem.id;
    this.trimFields();
    if (!this.validateFields()) {
      return;
    }
    if (this.isUnauthorisedUser && this.userToUse.name === '') {
      this.userToUse.name = 'U';
    }
    this.userToUse.enable_data = 1;
    const currentUsers = this.systems.activeSystem.device_users;
    currentUsers.push(this.userToUse);
    this.systems.activeSystem.device_users = currentUsers;
    const localUserId = this.userToUse.id;
    this.api.post('/add/user', {
      system_id: this.systems.activeSystem.id,
      user: this.userToUse,
      canSeeEvents: this.canSeeEvents,
      canEditUsers: this.canEditUsers,
    }, true).subscribe(
      (result) => {
        if (!result.success) {
          this.revertAddition(systemId);
          this.toaster.postError(result.error);
          return;
        }

        this.log('USER ADDED', result);
        this.assignNewUserData(result, systemId, localUserId, this.canSeeEvents, this.canEditUsers);
        if (this.systems.activeSystem.id === systemId) {
          this.systems.setCurrentSystem(this.systems.activeSystem);
        }
      },
      (error) => {
        this.revertAddition(systemId);
      }
    );

    history.back();
  }

  private doDeleteUser() {
    if (this.userToUse.id === 0) {
      return;
    }

    const systemId = this.systems.activeSystem.id;
    const userToReadd = this.es.getComponentBeforeModification(TEditableComponent.User);
    this.systems.activeSystem.device_users = this.systems.activeSystem.device_users.filter((u) => u.id !== userToReadd.id);
    this.api.delete('/user?system_id=' + systemId + '&user_id=' + userToReadd.id, true).subscribe(
      (result) => {
        if (!result.success) {
          this.reverDeletion(systemId, userToReadd);
          this.toaster.postError(result.error);
          return;
        }
        this.systems.saveActiveSystem(systemId);
      },
      (error) => {
        this.reverDeletion(systemId, userToReadd);
      }
    );
    history.back();
  }

  public saveChanges() {
    if (this.isNewUser) {
      return;
    } // Naujo vartotojo duomenis saugom vienu mostu, kitoj funkcijoj.
    this.trimFields();
    if (!this.validateFields()) {
      return;
    }
    if (this.isUnauthorisedUser && this.userToUse.name === '') {
      this.userToUse.name = 'U';
    }

    const systemId = this.systems.activeSystem.id;
    const userId = this.userToUse.id;
    const old = this.es.getComponentBeforeModification(TEditableComponent.User);
    this.api.post('/edit/user', {
      user: this.userToUse,
      system_id: this.systems.activeSystem.id,
      canSeeEvents: this.canSeeEvents,
      canEditUsers: this.canEditUsers,
    }, true).subscribe(
      (result) => {
        if (!result.success) {
          this.revertSave(old, systemId, userId);
          this.toaster.postError(result.error);
          return;
        }
        const system = this.systems.getSystem(systemId);
        if (system === undefined) {
          return;
        }
        const user = system.device_users.find((u) => u.id === userId);
        if (user === undefined) {
          return;
        }
        if (result.protegus_user_id !== undefined && result.protegus_user_id !== 0) {
          user.protegus_user_id = result.protegus_user_id;
        }
        if (result.id !== undefined && result.id !== 0) {
          user.id = result.id;
        }
        this.systems.saveActiveSystem(systemId);
      },
      (error) => {
        this.revertSave(old, systemId, userId);
      }
    );
    history.back();
  }

  public areaClick(areaNumber: number, value: boolean) {
    this.log('Check the area', areaNumber);
    this.userToUse.areas = this.userToUse.areas.substr(0, areaNumber - 1) + (value ? '1' : '0') + this.userToUse.areas.substr(areaNumber);
  }

  public userCanControlArea(areaNumber: number) {
    return this.userToUse.areas.charAt(areaNumber - 1) === '1';
  }

  public getAssignedPgmName(forArea: number): string {
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.control_area && iPgm.area_no === forArea) {
        return this.trans('systems.labels.controlledWith') + ': ' + iPgm.name;
      }
    }

    return '';
  }

  private validateZoneNumber(value: number): boolean {
    if (typeof value === 'string') {
      // nesuprantu kodel tik.
      value = parseInt(value, 10);
    }
    if (isNaN(value)) {
      return true;
    }
    const found = this.systems.activeSystem.device_users.find((u) => u.zone_number === value);
    if (found !== undefined && found.id !== this.userToUse.id) {
      this.toaster.postError(this.trans('systems.errors.userNumberExists'));
      return false;
    }
    this.userToUse.zone_number = this.es.getAvailableZoneNumber(this.userToUse.zone_number, 1, this.userToUse.id);
    let max = this.systems.activeSystem.maxDeviceUsers;
    if (max === 0) {
      max = 9999;
    }
    if (this.systems.activeSystemHelper.supports.gvTypeUsers()) {
      max += 3; // nes yra tarpas tarp adminu ir paprastu vartotoju
    }
    if (value > max) {
      this.toaster.postError(this.trans('systems.errors.maxZoneValue').replace(':max', max.toString()));
      this.userToUse.zone_number = this.es.getAvailableZoneNumber(40, 1, this.userToUse.id);
      return false;
    }
    if (value < 1) {
      this.toaster.postError(this.trans('systems.errors.minZoneValue').replace(':min', '1'));
      this.userToUse.zone_number = this.es.getAvailableZoneNumber(1, 1, this.userToUse.id);
      return false;
    }
    return true;
  }

  private revertAddition(systemId: number) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }

    system.device_users = system.device_users.filter((u) => u.id !== 0);
  }

  private assignNewUserData(data: any, systemId: number, userId: number, canSeeEvents: boolean, canEditUsers: boolean) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const user = system.device_users.find((u) => u.id === userId);
    if (user === undefined) {
      return;
    }

    if (data.protegus_user_id !== undefined) {
      user.protegus_user_id = data.protegus_user_id;
    }
    if (data.id === undefined) {
      this.toaster.postError(this.trans('systems.errors.failedToAssignIdToUser'));
      this.revertAddition(systemId);
      return;
    }
    user.id = data.id;
    if ( user.protegus_user_id !== 0 ) {
      const systemUsers = this.us.systemUsers.get(systemId);
      const defaultRule = this.permissionService.getDefaultPermissionFor(PermissionRole.SystemUser, system.company_id);
      let copy = defaultRule ? { ...defaultRule } : undefined;
      if ( copy ) { copy.permissions = PermissionService.getCopyOf(defaultRule.permissions); }
      if ( copy && defaultRule.permissions.sys_events.view !== canSeeEvents ) {
        copy.permissions.sys_events.view = canSeeEvents;
      }
      if ( copy && defaultRule.permissions.sys_users.edit !== canEditUsers ) {
        copy.permissions.sys_users.view = canEditUsers;
        copy.permissions.sys_users.create = canEditUsers;
        copy.permissions.sys_users.edit = canEditUsers;
        copy.permissions.sys_users.delete = canEditUsers;
      }
      if ( copy && PermissionService.rulesAreEqual(copy.permissions, defaultRule.permissions) ) {
        copy = undefined;
      }
      systemUsers.push({
        id: user.protegus_user_id,
        master: false,
        personal_permissions: copy?.permissions ?? null,
        system_permissions_id: defaultRule?.id ?? 0,
      });
    }
  }

  private reverDeletion(systemId: number, userToReadd: TDeviceUser) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const currentUsers = system.device_users;
    currentUsers.push(userToReadd);
    system.device_users = currentUsers;
  }

  private revertSave(old: TDeviceUser, systemId: number, userId: number) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const user = system.device_users.find((u) => u.id === userId);
    if (user === undefined) {
      return;
    }
    Object.assign(user, old);
    this.systems.saveActiveSystem(systemId);
  }

  private getPinLength(): number {
    if (this.systems.activeSystemHelper.supports.cgTypeUsers() || this.systems.activeSystemHelper.supports.gvTypeUsers()) {
      return 4;
    }

    return 6;
  }

  public handleAdministratorChange(value: boolean) {
    let foundEmptySpot = 0;
    if (value) {
      foundEmptySpot = this.es.getAvailableZoneNumber(1, 1, this.userToUse.id);
      if (foundEmptySpot === 0 || foundEmptySpot > this.maxAdminCount) {
        this.toaster.postError(this.trans('users.errors.deviceAdminListFull'));
        return;
      }
    } else {
      foundEmptySpot = this.es.getAvailableZoneNumber(1, this.simpleUsersStartFrom, this.userToUse.id);
      if (foundEmptySpot === 0) {
        this.toaster.postError(this.trans('systems.errors.userListFull'));
        return;
      }
    }

    this.userToUse.zone_number = foundEmptySpot;
    this.systems.activeSystem.device_users.sort((du1, du2) => du1.zone_number - du2.zone_number);
  }

  public clickPgm(queueNo: number, value: boolean) {
    const assignedOutput = this.assignedOutputs.find((ao) => ao.queue_no === queueNo);
    assignedOutput.value = value;
    this.userToUse.pgms =
      (this.assignedOutputs.find((ao) => ao.queue_no === 3) !== undefined && this.assignedOutputs.find((ao) => ao.queue_no === 3).value ? 0x01 : 0) +
      (this.assignedOutputs.find((ao) => ao.queue_no === 4) !== undefined && this.assignedOutputs.find((ao) => ao.queue_no === 4).value ? 0x02 : 0) +
      (this.assignedOutputs.find((ao) => ao.queue_no === 5) !== undefined && this.assignedOutputs.find((ao) => ao.queue_no === 5).value ? 0x04 : 0) +
      (this.assignedOutputs.find((ao) => ao.queue_no === 1) !== undefined && this.assignedOutputs.find((ao) => ao.queue_no === 1).value ? 0x08 : 0) +
      (this.assignedOutputs.find((ao) => ao.queue_no === 2) !== undefined && this.assignedOutputs.find((ao) => ao.queue_no === 2).value ? 0x10 : 0);
  }

  public emailValueChanged(value: string) {
    this.userToUse.email = value;
    this.handleElementVisibility();
  }

  public changeUserEnabledValue(value: boolean) {
    /* eslint-disable no-bitwise */
    if (value) {
      this.userToUse.enable_data = this.userToUse.enable_data | 0x01;
    } else {
      this.userToUse.enable_data = this.userToUse.enable_data & 0xfe;
    }
    /* eslint-enable no-bitwise */
  }

  public isOuputAssigned(queueNo: number): boolean {
    return this.assignedOutputs.find((ao) => ao.queue_no === queueNo) !== undefined && this.assignedOutputs.find((ao) => ao.queue_no === queueNo).value;
  }

  private loadEditUsersValue(): boolean {
    if ( this.userToUse.protegus_user_id === 0 ) { return false; }
    const found = this.us.systemUsers.get(this.systems.activeSystem.id).find(u => u.id === this.userToUse.protegus_user_id);
    return found.personal_permissions ?
      found.personal_permissions.sys_users.edit : this.permissionService.getPermission(found.system_permissions_id)?.permissions.sys_users.edit;
  }

  private loadCanSeeEventsValue(): boolean {
    if ( this.userToUse.protegus_user_id === 0 ) { return false; }
    const found = this.us.systemUsers.get(this.systems.activeSystem.id).find(u => u.id === this.userToUse.protegus_user_id);
    return found.personal_permissions ?
      found.personal_permissions.sys_events.view : this.permissionService.getPermission(found.system_permissions_id)?.permissions.sys_events.view;
  }

  public changeEditUsersValue(newValue: boolean) {
    this.canEditUsers = newValue;

    if ( this.userToUse.protegus_user_id === 0 ) { return; }
    const found = this.us.systemUsers.get(this.systems.activeSystem.id).find(u => u.id === this.userToUse.protegus_user_id);
    if ( found.personal_permissions ) {
      found.personal_permissions.sys_users.create = newValue;
      found.personal_permissions.sys_users.view = newValue;
      found.personal_permissions.sys_users.edit = newValue;
      found.personal_permissions.sys_users.delete = newValue;
      if ( PermissionService.rulesAreEqual(found.personal_permissions, this.permissionService.getPermission(found.system_permissions_id)?.permissions) ) {
        found.personal_permissions = null; // Jeigu tas pats, tai nustojam naudoti personal.
      }
    } else {
      found.personal_permissions = PermissionService.getCopyOf(this.permissionService.getPermission(found.system_permissions_id)?.permissions);
      found.personal_permissions.sys_users.create = newValue;
      found.personal_permissions.sys_users.view = newValue;
      found.personal_permissions.sys_users.edit = newValue;
      found.personal_permissions.sys_users.delete = newValue;
    }
  }

  public async changeCanSeeEventsValue(newValue: boolean) {
    this.canSeeEvents = newValue;

    if ( this.userToUse.protegus_user_id === 0 ) { return; }
    const found = this.us.systemUsers.get(this.systems.activeSystem.id).find(u => u.id === this.userToUse.protegus_user_id);
    if ( found.personal_permissions ) {
      found.personal_permissions.sys_events.view = newValue;
      if ( PermissionService.rulesAreEqual(found.personal_permissions, this.permissionService.getPermission(found.system_permissions_id)?.permissions) ) {
        found.personal_permissions = null; // Jeigu tas pats, tai nustojam naudoti personal.
      }
    } else {
      found.personal_permissions = PermissionService.getCopyOf(this.permissionService.getPermission(found.system_permissions_id)?.permissions);
      found.personal_permissions.sys_events.view = newValue;
    }
  }
}
