<div *ngIf="desktopView; else isMobile">
  <div class="sectionHeader">
    <h4 class="titleText">{{ isNew ? trans('settings.labels.addRegion') : trans('settings.labels.editRegion') }}</h4>
    <ng-container *ngIf="!isNew">
      <div class="delete" (click)="onDeleteClick()">
        <h4>{{trans('general.delete')}}</h4>
        <div class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
      </div>
    </ng-container>
  </div>
  <div class="editSection">
    <app-string-input [label]="trans('settings.labels.regionName')" [(value)]="regionItem.name" (valueChange)="onValueChange()" [maxLength]="255">
      <app-form-error *ngIf="val.hasError('name')">{{ val.getError('name') }}</app-form-error>
    </app-string-input>
    <app-string-input [label]="trans('settings.labels.regionHost')" [(value)]="regionItem.api_host" (valueChange)="onValueChange()" [maxLength]="255">
      <app-form-error *ngIf="val.hasError('api_host')">{{ val.getError('api_host') }}</app-form-error>
    </app-string-input>
    <app-string-input [label]="trans('settings.labels.regionPath')" [(value)]="regionItem.api_path" (valueChange)="onValueChange()" [maxLength]="255">
      <app-form-error *ngIf="val.hasError('api_path')">{{ val.getError('api_path') }}</app-form-error>
    </app-string-input>
    <app-dropdown [label]="trans('settings.labels.regionVersion')" [items]="[{label: '1', value: 1}, {label: '2', value: 2}]" [(value)]="regionItem.region_version" (valueChange)="onValueChange()">
      <app-form-error *ngIf="val.hasError('region_version')">{{ val.getError('region_version') }}</app-form-error>
    </app-dropdown>
  </div>
  <ng-component *ngIf="isNew || inputChanged">
    <ng-template appTeleport="footer">
      <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
    </ng-template>
  </ng-component>
</div>
<ng-template #isMobile>
  <div class="white_round padding16">
    <div class="{{canEdit ? '' : 'is_disabled'}}">
        <app-text-edit [value]="regionItem.name" (exitedField)="regionItem.name = $event" [isReadOnly]="!canEdit">{{trans('settings.labels.regionName')}}</app-text-edit>
        <app-text-edit [value]="regionItem.api_host" (exitedField)="regionItem.api_host = $event" [isReadOnly]="!canEdit">{{trans('settings.labels.regionHost')}}</app-text-edit>
        <app-text-edit [value]="regionItem.api_path" (exitedField)="regionItem.api_path = $event" [isReadOnly]="!canEdit">{{trans('settings.labels.regionPath')}}</app-text-edit>
    </div>
    <app-name-value [nameText]="trans('settings.labels.regionVersion')" (clicked)="changeVersion()" [isEnabled]="canEdit">{{regionItem.region_version}}</app-name-value>
  </div>
  <app-popup></app-popup>
</ng-template>
