<div id="android_off" *ngIf="!notificationsEnabledBySystem">
    <span>{{trans('systems.labels.phoneNotificationsDisabled')}}</span>
    <span [inlineSVG]="'assets/images/phone_settings.svg'" (click)="openAppNotificationsSettings()"></span>
</div>
<div class="grid system-notifications-grid">
    <div class="group-background system-notifications-globals-back {{isDesktop ? 'desktop' : ''}}"></div>
    <div class="system-notifications-globals {{isDesktop ? 'desktop' : ''}}">
        <div class="system-notifications-globals-item {{canEdit ? '' : 'is_disabled'}}">
            <div>{{trans('systems.labels.allowNotifications')}}</div>
            <app-toggle-button [initialState]='systemNotificationsEnabled' (toggled)="toggleGlobal($event.checked)" buttonId='global-notif' [isDisabled]="!canEdit"></app-toggle-button>
        </div>
        <div class="system-notifications-globals-item {{canEdit ? '' : 'is_disabled'}}">
            <div>{{trans('users.settings.menu.alert')}}</div>
            <app-toggle-button [initialState]='systemNotificationSoundsEnabled' (toggled)="toggleGlobalSound($event.checked)" buttonId='global-sound' [isDisabled]="!canEdit"></app-toggle-button>
        </div>
    </div>
    <div class='system-notifications-reactions'>
        <div class="system-notifications-item" *ngFor='let iNotificationSetting of notifications'>
            <app-system-notifications-item [notificationItem]='iNotificationSetting' [isEnabled]='systemNotificationsEnabled && canEdit' [isMuted]="!systemNotificationSoundsEnabled"></app-system-notifications-item>
        </div>
    </div>
</div>
<div class="separator32"></div>