import { Injectable, Injector } from '@angular/core';
import { merge, Observer, Subject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { environment } from 'src/app-settings';
import { AppRegion, AppRegionWithName } from 'src/environments/environment.types';
import { LoggerService } from '../api/logger.service';
import { PlatformService } from './platform.service';
import { RegionService } from './region.service';
import { RequestService } from './request.service';
import { UserService } from './user.service';
import { SystemService } from './system/system.service';
import { autoinject, useDebouncedFunction } from 'src/shim';
import { RtcService } from './rtc/rtc.service';
import { PersistenceService } from './persistence.service';
import { CurrentUserData, PermissionRole, Region } from 'src/api/v3/common';
import { SystemsService } from '../services/systems.service';
import { LocatorService } from '../services/locator.service';

interface TokenData {
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
  sub: number;
}

export interface AccountStoreItem {
  data: TokenData;
  token: string | null;
  name: string;
  email: string;
  role: number;
  loginType: string;
  region: string | AppRegion;
}

export type AccountStore = Record<string, Record<number, AccountStoreItem>>;

export interface AccountInfo {
  id: number;
  token: string;
  data: TokenData;
  name: string;
  email: string;
  active: boolean;
  loginType: string;
  role: number;
  region: AppRegionWithName;
  regions?: Region[];
}

const storeToInfoTransformer = (a: AccountStoreItem, regionService: RegionService, currentUserId: number): AccountInfo => {
  const region = typeof a.region === 'object' ? a.region : environment.regions.find((r) => r.id === a.region) ?? regionService.ActiveRegion;
  const regionWithName: AppRegionWithName = {
    ...region,
    name: regionService.getNameForRegion(region),
  };
  return {
    id: a.data.sub,
    token: a.token,
    data: a.data,
    name: a.name,
    email: a.email,
    active: a.data.sub === currentUserId && regionService.ActiveRegion.backEndHost === region?.backEndHost,
    loginType: a.loginType,
    role: a.role,
    region: regionWithName,
  };
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private static tag = 'AuthService';
  private tokenChange = new Subject<string>();
  public onTokenChange = this.tokenChange.asObservable();
  public onTokenClear = this.tokenChange.pipe(filter((t) => !t));
  private accountChange = new Subject<AccountInfo | null>();
  public onAccountChange = this.accountChange.asObservable();
  public onAccountOrRegionChnage = merge(this.onAccountChange, this.region.onRegionChanged).pipe(map((): void => { }, debounceTime(40)));
  public registerCurrentUserData: Observer<CurrentUserData> = {
    next: (user) => { this.registerUserDataWithSwitcher(user); },
    error: () => undefined,
    complete: () => undefined,
  };
  public accountStore = this.persistence.get('globalAccounts', {});
  public accountStoreSubscriptions = this.persistence.subscribe('globalAccounts').subscribe((store) => {
    this.accountStore = store;
  });
  public mergedRegionAccounts: AccountInfo[] = [];
  private get req() { return autoinject(LocatorService.injector, RequestService); }
  private get user() { return autoinject(LocatorService.injector, UserService); }
  private get system() { return autoinject(LocatorService.injector, SystemService); }
  private get rtc() { return autoinject(LocatorService.injector, RtcService); }
  private get ss() { return autoinject(LocatorService.injector, SystemsService); }
  constructor(private platform: PlatformService, private region: RegionService, private l: LoggerService, private injector: Injector, private persistence: PersistenceService) {
    const [loadUserData] = useDebouncedFunction(() => this.loadUserDataInternal(), 0);
    this.loadUserData = loadUserData;
  }

  /** Funkcija netikrina ar turimas token yra geras. Ji patikrina tik ar toks išvis yra. */
  /** @returns true, kai yra išsaugotas token. */
  hasToken(): boolean {
    const token = localStorage.getItem('token');
    return token && token.includes('.');
  }

  /** Iš local storage paima išsaugotą token. */
  /** @returns token */
  public getToken(): string {
    const token = localStorage.getItem('token');
    if (token == null) {
      return '';
    }
    return token;
  }

  public setToken(token: string) {
    const oldUserId = this.GetUserId();
    this.l.log('setToken', AuthService.tag, { token });
    localStorage.setItem('token', token);

    if (!token) {
      this.region.unlockRegion();
    } else {
      this.registerTokenWithSwitcher(token);
      this.region.lockRegion();
    }

    if (this.platform.isAndroid()) {
      this.platform.androidHandler().onNewToken(token);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().onNewToken.postMessage(token ?? '');
    }
    const newUserId = this.GetUserId();
    if (oldUserId !== newUserId) { this.tokenChange.next(token); }
  }

  public getLastAccount(): AccountInfo | undefined {
    let biggestIat = -1;
    const accounts = this.availableAccounts;
    this.availableAccounts.forEach((a) => (biggestIat = Math.max(biggestIat, a.data.iat)));
    return accounts.find((a) => a.data.iat === biggestIat);
  }
  /**
   * Before calling: must close webspcket, and clear systems
   */
  public switchAccount(account: AccountInfo) {
    if ( account?.token ) {
      this.region.useRegion(account.region);
      this.setToken(account.token);
      this.switchMergedRegionAccounts(account);
    }
    this.accountChange.next(account);
  }

  private switchMergedRegionAccounts(account: AccountInfo) {
    this.mergedRegionAccounts.map(acc => {
      acc.active = acc.id === account.id;
      acc.region = acc.active ? account.region : acc.region;
    });
  };

  private locateAccount(locator: (account: AccountStoreItem) => boolean): AccountInfo | undefined {
    const store = this.accountStore;
    return Object.entries(store)
      .map(([, s]) => Object.entries(s).map(([, i]) => i))
      .reduce((s, a) => [...a, ...s], [])
      .filter((a) => locator(a))
      .map((a) => storeToInfoTransformer(a, this.region, this.GetUserId() ?? -1))
      .pop();
  }

  public getAccountByEmail(email: string): AccountInfo | undefined {
    return this.locateAccount((a) => a.email === email && a.region === this.region.currentRegion);
  }

  public get availableAccounts(): AccountInfo[] {
    const store = this.accountStore;
    return Object.entries(store)
      .map(([, s]) => Object.entries(s).map(([, i]) => i))
      .reduce((s, a) => [...a, ...s], [])
      .map((a) => storeToInfoTransformer(a, this.region, this.GetUserId() ?? -1));
  }

  public async loadMergedRegionAccounts(): Promise<void> {
    await this.region.loadRegions();
    const mergedAccounts: any[] = [];
    for (const account of this.availableAccounts) {
      const existingAccount = mergedAccounts.find(a => a.id === account.id);
      if (existingAccount) {
        if (account.active) {
          existingAccount.active = true;
          existingAccount.role = account.role;
          existingAccount.region = account.region;
          existingAccount.token = account.token;
        }
        existingAccount.regions.push(account.region);
      } else {
        mergedAccounts.push({
          ...account,
          regions: [account.region]
        });
      }
    }
    this.mergedRegionAccounts = mergedAccounts;
  }

  public get hasAccounts(): boolean {
    return this.availableAccounts.length > 0;
  }

  public logOutFromSwitcher(id: number) {
    this.accountStore[this.region.regionId][id].token = null;
    this.persistence.set('globalAccounts', this.accountStore);
    this.mergedRegionAccounts.map(mra => mra.token = mra.id === id ? null : mra.token);
  }

  public forgetAccount(account: AccountInfo) {
    delete this.accountStore[account.region.id][account.id];
    this.persistence.set('globalAccounts', this.accountStore);
    this.mergedRegionAccounts.splice(this.mergedRegionAccounts.indexOf(this.mergedRegionAccounts.find(a => a.id === account.id)), 1);
  }

  public GetUserId(): number | null {
    try {
      const tokenData = JSON.parse(atob(this.getToken().split('.')[1]));
      return tokenData.sub;
    } catch (error) {
      return null;
    }
  }

  public getTokenData(): TokenData | null {
    try {
      const tokenData = JSON.parse(atob(this.getToken().split('.')[1]));
      return tokenData;
    } catch (error) {
      return null;
    }
  }

  private registerTokenWithSwitcher(token: string) {
    const b64Data = token.split('.')[1];
    const data = JSON.parse(atob(b64Data)) as TokenData;
    const regionStore = this.accountStore[this.region.regionId] ?? {};
    if (regionStore[data.sub]) {
      regionStore[data.sub] = {
        ...regionStore[data.sub],
        data,
        token,
        region: this.region.currentRegion,
      };
    } else {
      regionStore[data.sub] = {
        data,
        token,
        name: '',
        email: '',
        role: 1,
        loginType: 'default',
        region: this.region.currentRegion,
      };
    }
    this.accountStore[this.region.regionId] = regionStore;
    this.persistence.set('globalAccounts', this.accountStore);
  }

  private async loadUserDataInternal(connectToRtc: boolean = true): Promise<CurrentUserData & { raw?: any }> {
    if ( this.user.currentUser && this.user.isCurrentUserFetched ) { return this.user.currentUser; }
    if (!this.hasToken()) { throw new Error('No token'); }
    const lastSystemId = this.persistence.get('last_system', 0);
    const result = await this.req.user.me(lastSystemId === 0 ? undefined : lastSystemId).toPromise();
    if (result.success) {
      const { success, lastSystem, ...user } = result;
      this.user.isCurrentUserFetched = true;
      this.user.setCurrentUser(user);
      const ingestedSystem = this.system.ingestSystem(lastSystem);
      if ( ingestedSystem ) {
        this.ss.setCurrentSystem(ingestedSystem);
      }
      this.setToken(user.token);
      this.registerCurrentUserData.next(this.user.currentUser);
      this.user.change();
      if (connectToRtc) { this.rtc.connect(); }
      return { ...this.user.currentUser, raw: user };
    }
    throw new Error('Failed to load user data');
  }

  public registerUserDataWithSwitcher(user: CurrentUserData) {
    if (!user || !this.hasToken()) { return; }
    this.l.log('registerUserDataWithSwitcher', AuthService.tag);
    let regionStore = this.accountStore[this.region.regionId] ?? {};
    if (!regionStore[user.id]) {
      this.l.log(`Šio user (${user.id}) token niekada nebuvo registruotas store, tai WTF.`, AuthService.tag);
      this.registerTokenWithSwitcher(this.getToken());
      regionStore = this.accountStore[this.region.regionId] ?? {};
      if (!regionStore[user.id]) {
        this.l.log(`Nepavyko registruoti tokeno.`, AuthService.tag);
        return;
      }
    }
    if (!user.email) {
      this.l.log(`UserService neturi vartotojo duomenu, nekeičiame switcher dumenu.`, AuthService.tag);
      return;
    }
    regionStore[user.id].name = user.name;
    regionStore[user.id].email = user.email;
    regionStore[user.id].role = user.permissions?.role ?? PermissionRole.GeneralUser;
    // regionStore[user.id].loginType = this.us.getLoginType()
    this.accountStore[this.region.regionId] = regionStore;
    this.persistence.set('globalAccounts', this.accountStore);
    this.loadMergedRegionAccounts();
  }

  public loadUserData: AuthService['loadUserDataInternal'];
}
