import { ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionRole, ProtegusUserData } from 'src/api/v3/common';
import { RequestService } from 'src/app/api/request.service';
import { UserService } from 'src/app/api/user.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { DataTableGetter } from '../data-table/data-table.component';
export type RowData = {
  column: string;
  item: ProtegusUserData;
  row: number;
};

@Component({
  selector: 'app-users-data-table',
  templateUrl: './users-data-table.component.html',
  styleUrls: ['./users-data-table.component.scss'],
})
export class UsersDataTableComponent extends LanguageAware implements OnInit, OnChanges, OnDestroy {
  @Input() searchLoader: DataTableGetter<ProtegusUserData>;
  @Input() public columns: string[];
  @Output() public modifyColumns = new EventEmitter<void>();
  @Output() searchFor = new EventEmitter<{searchPhrase: string; searchField: string}>();
  public canDelete = this.us.currentUser.permissions.permissions.users.delete && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
  public PermissionRole = PermissionRole;
  public get visibleColumns(): string[] {
    return this.getVisibleColumns();
  }

  public loader: DataTableGetter<ProtegusUserData>;
  private subscriptions: Subscription[] = [];
  public contextMenu = {
    isVisible: false,
    item: null
  };

  constructor(
    private sanitizer: DomSanitizer,
    public router: Router,
    private zone: NgZone,
    private messagebox: MessageboxService,
    private req: RequestService,
    public user: UserService,
    cdRef: ChangeDetectorRef
  ) {
    super(cdRef);
    this.loader = this.searchLoader;
  }

  public onRowClick(event: RowData): void {
    if(event.column === 'fake:delete') {
      this.onDeleteIconClick(event);
    } else if (event.column !== 'system_name') {
      this.router.navigate([companyRootRoute, 'users', 'edit', event.item.id]).then(() => {
        this.zone.runOutsideAngular(() => {
          setTimeout(() => {
            document.querySelector('#userSettingsPanel')?.querySelector('input')?.focus();
          }, 50);
        });
      });
    }
  }

  public async onRightClick({item, event}) {
    event.preventDefault();
    this.contextMenu.isVisible = true;
    const fetchUser = await this.user.loadSingleUserData(item.id);
    if(fetchUser.success) {
      this.contextMenu.item = fetchUser.userData;
    }
  }

  public onContextFilter(searchPhrase: string, searchField: string) {
    searchPhrase = searchField === 'role' ? this.getUserRole(Number(searchPhrase)) : searchPhrase;
    this.contextMenu.isVisible = false;
    this.searchFor.emit({ searchPhrase, searchField });
  }

  public getUserRole(permissionId: number): string {
    const permission = this.permissionService.getPermission(permissionId);
    return this.trans(`permissions.roles.${permission?.role}`);
  }

  public getRoleType(permissionId: number): PermissionRole {
    const permission = this.permissionService.getPermission(permissionId);
    return permission?.role;
  }

  public onDeleteIconClick(event: RowData): void {
    if ( this.us.currentUser.id === event.item.id ) { return; }
    if ( !this.canDelete ) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
`${this.trans('users.labels.deleteAccount')} <strong>${event.item.name} (${event.item.email})</strong>. ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deleteUser(event.item.id);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async deleteUser(user_id: number): Promise<void> {
    try {
      await this.req.user.deleteUser({user_id}).toPromise();
      this.user.users.delete(user_id);
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleSuccess'),
        messageContent: this.trans('users.labels.userIsDeletedNow'),
        iconType: this.messagebox.iconType.Success
      });
      this.router.navigate([companyRootRoute, 'users']);
    } catch (error) { }
  }

  ngOnInit(): void {
    this.loader = this.searchLoader;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.loader = this.searchLoader;
    }
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public onUserSystemClick(systemId: number) {
    this.router.navigate([companyRootRoute, 'systems', systemId]);
  }

  private getVisibleColumns(): string[]|undefined {
    if(this.columns.length === 0) return;
    if (
      !this.us.currentUser.permissions.permissions.roles.view &&
      this.columns.includes('access_permission_id')
    ) {
      this.columns= this.columns.filter(c => c !== 'access_permission_id');
    }
    if (
      (this.us.currentUser.permissions.role === PermissionRole.Company ||
      !this.us.currentUser.permissions.permissions.company_settings.view) &&
      this.columns.includes('company')
    ) {
      this.columns= this.columns.filter(c => c !== 'company');
    }
    if (
      !this.us.currentUser.permissions.permissions.systems.view &&
      this.columns.includes('system_name')
    ) {
      this.columns= this.columns.filter(c => c !== 'system_name');
    }
    return this.columns;
  }
}
