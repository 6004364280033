import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TNotificationSetting } from 'src/app/models/notification-setting';

@Component({
  selector: 'app-system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.scss'],
})
export class SystemNotificationsComponent extends LanguageAware implements OnInit {
  public notificationsEnabledBySystem = true;
  public systemNotificationsEnabled = this.systems.activeSystem?.notificationsEnabled;
  public systemNotificationSoundsEnabled = this.systems.activeSystem?.notificationSoundsEnabled;
  public notifications = this.systems.activeSystem?.notifications ?? [];
  public canEdit = this.systems.activeSystemHelper.can.edit.notificationSettings();
  public readonly isDesktop = this.platform.isDesktopView();
  constructor(cdRef: ChangeDetectorRef, private r: ActivatedRoute) {
    super(cdRef);
    this.tag = 'Notifs';
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.notifications'),
      backUrl: '../system-configuration',
      activatedRoute: this.r,
    });

    if ( this.platform.isAndroid() ) {
      this.l.log('Užklausiam ar įjungtos notifikacijos.');
      try {
        this.notificationsEnabledBySystem = this.platform.androidHandler().areNotificationsEnabled();
        this.l.log('Gavom kad notifikacijos: ' + (this.notificationsEnabledBySystem ? 'Įjugntos' : 'Išjungtos'));
      } catch (e) {
        this.l.log('Nieko negavom kadangi android app versija per sena.');
      }
    }
  }

  ngOnInit(): void {}

  public toggleGlobal(value: boolean) {
    this.log('global notifications toggles:', value);
    this.systems.activeSystem.notificationsEnabled = value;
    this.systemNotificationsEnabled = value;
    const systemBeingChanged = this.systems.activeSystem;
    this.api.post('/system/notification/toggle/global-state', { systemId: this.systems.activeSystem.id, value, userId: this.us.currentUser.id }, true).subscribe(
      (result) => {
        if (!result.success) {
          this.toaster.postError(result.error);
          systemBeingChanged.notificationsEnabled = !value;
          this.systemNotificationsEnabled = !value;
        } else {
          systemBeingChanged.notificationsEnabled = result.state;
          this.systemNotificationsEnabled = result.state;
          this.systems.setCurrentSystem(systemBeingChanged);
        }
      },
      (error) => {
        systemBeingChanged.notificationsEnabled = !value;
        this.systemNotificationsEnabled = !value;
      }
    );
  }

  public toggleGlobalSound(value: boolean) {
    this.log('global sound toggles:', value);
    this.systems.activeSystem.notificationSoundsEnabled = value;
    this.systemNotificationSoundsEnabled = value;
    const systemBeingChanged = this.systems.activeSystem;
    this.api.post('/system/notification/toggle/global-sound', { systemId: this.systems.activeSystem.id, userId: this.us.currentUser.id }, true).subscribe(
      (result) => {
        this.log('', result);
        if (!result.success) {
          this.toaster.postError(result.error);
          systemBeingChanged.notificationSoundsEnabled = !value;
          this.systemNotificationSoundsEnabled = !value;
        } else {
          systemBeingChanged.notificationSoundsEnabled = result.state;
          this.systemNotificationSoundsEnabled = result.state;
          this.systems.setCurrentSystem(systemBeingChanged);
        }
      },
      (error) => {
        systemBeingChanged.notificationSoundsEnabled = !value;
        this.systemNotificationSoundsEnabled = !value;
      }
    );
  }

  public toggleNotification(which: number, value: boolean) {
    this.log('toggle:', { which, value });
    const that = this;
    let currentNotification: TNotificationSetting = null;
    for (const iNotif of this.systems.activeSystem.notifications) {
      if (iNotif.id === which) {
        iNotif.enabled = value;
        currentNotification = iNotif;
      }
    }
    this.api.post('/system/notification/toggle/state', { systemId: this.systems.activeSystem.id, id: which, userId: this.us.currentUser.id }, true).subscribe(
      (result) => {
        that.log('', result);
        if (!result.success) {
          that.toaster.postError(result.error);
          currentNotification.enabled = !value;
        } else {
          currentNotification.enabled = result.state;
          that.systems.setCurrentSystem(that.systems.activeSystem);
        }
      },
      (error) => {
        currentNotification.enabled = !value;
      }
    );
  }

  public toggleNotificationSound(which: number, value: boolean) {
    this.log('toggle sound:', { which, value });
    const that = this;
    let currentNotification: TNotificationSetting = null;
    for (const iNotif of this.systems.activeSystem.notifications) {
      if (iNotif.id === which) {
        iNotif.useAlertSound = value;
        currentNotification = iNotif;
      }
    }
    this.api.post('/system/notification/toggle/sound', { systemId: this.systems.activeSystem.id, id: which, userId: this.us.currentUser.id }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          currentNotification.useAlertSound = !value;
        } else {
          currentNotification.useAlertSound = result.state;
          that.systems.setCurrentSystem(that.systems.activeSystem);
        }
      },
      (error) => {
        currentNotification.useAlertSound = !value;
      }
    );
  }

  public openAppNotificationsSettings() {
    this.platform.androidHandler().openSettings();
  }
}
