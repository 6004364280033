<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('auth.labels.verifyEmail') }}</h2>
      <p>{{ trans('auth.labels.verifyEmailPrompt') }}</p>
    </div>
    <form (submit)="next()">
      <input
        type="number"
        name="code"
        [value]="code"
        (input)="checkLengthAndFix($event.target)"
        autocomplete="off"
        [attr.aria-label]="trans('auth.form.emailVerificationCode')"
        [ngClass]="'len' + code.length"
        [disabled]="isLoading"
      />
      <app-form-error *ngIf="val.hasError('code')">{{ val.getError('code') }}</app-form-error>
      <p>{{ trans('auth.labels.checkSpam') }}</p>
      <app-button type="filled-secondary" class="mainButton" [isLoading]="isLoading">{{ trans('general.next') }}</app-button>
    </form>
  </div>
</app-login-layout>
