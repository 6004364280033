import { httpGet, httpPost } from '../http';
import { objectToParams } from '../v3/common';
import { CommonRequest, CommonResponse } from './common';

const read = (params: EventLogRequestParams) => httpGet<EventLogRequestResponse, URLSearchParams>('/v3/api/config/eventlog/read', objectToParams(params));
const clear = (params: CommonRequest) => httpPost<CommonResponse, CommonRequest>('/v3/api/config/eventlog/clear', params);

export type EventLogRequestParams = CommonRequest & {
  packet: number;
};

export type EventLogEntry = {
  user: number;
  eventCode: string;
  useNumber: boolean;
  name: string;
  phone: string;
  time: {
    seconds: number;
    minutes: number;
    hours: number;
    day: number;
    month: number;
    year: number;
  };
};

export type EventLogRequestResponse = CommonResponse & {
  total?: number;
  data?: EventLogEntry[];
};

export default {
  read,
  clear,
};
