import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, NgZone } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { PopupService } from 'src/app/services/popup.service';
import { PopupType } from 'src/app/models/popup-type';
import { DeviceService } from 'src/app/services/device.service';
import { isDesktop } from 'src/app/api/platform.service';
import { MessageboxService } from 'src/app/services/messagebox.service';

@Component({
  selector: 'app-add-system-uid',
  templateUrl: './add-system-uid.component.html',
  styleUrls: ['./add-system-uid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSystemUidComponent extends LanguageAware implements OnInit {
  public systemUid = '';
  public errorMessageForUnsupportedDevice = '';

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private zone: NgZone,
    private ns: NewSystemService,
    private pp: PopupService,
    private ds: DeviceService,
    private msg: MessageboxService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'UID';
    this.log('+');
    this.progressBar.showProgress();
    this.headerBar.showHeader({
      headerText: this.platform.isDesktop() ? this.trans('systems.labels.imei') : this.trans('systems.labels.scanQr'),
      backUrl: '*',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('auth.buttons.next'), true, false);
    this.background.setGray();
    this.footerBar.onButton1Click = () => {
      this.cancelClick();
    };
    this.footerBar.onButton2Click = () => {
      this.nextClick();
    };
    this.headerBar.onBackClicked = () => {
      this.ns.setUID('');
    };
    window.qrJsi = {
      onQrCodeScanned: (code) => {
        this.zone.run(() => {
          this.handleQrCode(code);
        });
      },
    };
    this.systemUid = this.ns.getUID();
  }

  ngOnInit() {
    this.progressBar.setProgressData(1, 7);
  }

  private cancelClick() {
    this.toaster.clear();
    this.ns.setUID('');
    this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
  }

  public nextClick() {
    this.toaster.clear();
    this.systemUid = this.systemUid.trim().toUpperCase();
    this.systemUid = this.systemUid.replace(/^0+/, '');
    if (this.systemUid === '') {
      this.toaster.postError(this.trans('systems.errors.imeiIsRequired'));
      return;
    }
    if (this.systemUid.length < 13 && !/[0-9A-F]{1,12}/.test(this.systemUid)) {
      this.toaster.postError(this.trans('validation.imeiInvalid'));
      return;
    }
    if (this.systemUid.length > 12 && !/[0-9]{15,16}/.test(this.systemUid)) {
      this.toaster.postError(this.trans('validation.imeiInvalid'));
      return;
    }
    if (this.systemUid.length < 12) {
      this.systemUid = this.systemUid.padStart(12, '0');
    }
    this.log('Įvestas UID: ' + this.systemUid);
    this.ns.setUID(this.systemUid);

    this.findDevice(false);
  }

  private findDevice(checkAll: boolean) {
    this.miniStatus.show(this.trans('general.checking'));
    this.api.get('/online?checkAll=' + (checkAll ? 'true' : 'false') + '&imei=' + this.systemUid + '&mpass=' + this.ns.getMpass(), true).subscribe(
      (result) => {
        if (result.success && result.online) {
          this.checkIfInForeign(result.srv);
        } else if (result.success && !checkAll) {
          this.findDevice(true);
        } else if (!result.success) {
          this.miniStatus.hide();
          this.toaster.postError(result.error);
        } else if (result.errorType !== undefined && result.errorType === 'MPASS_IS_INCORRECT') {
          this.pp.showPopup(
            {
              headerText: this.trans('systems.labels.mpass'),
              field1: {
                maxLen: 6,
                oldValue: '',
              },
              onSubmit: (res) => {
                this.ns.setMpass(res.value1.toString(10));
                this.nextClick();
              },
              onCancel: () => {
                this.miniStatus.hide();
              },
            },
            PopupType.Number
          );
        } else if (result.hwId && result.hwId > 0 && !isDesktop()) {
          if (result.hwId === 0x37) {
            this.ds.setHwId(result.hwId);
            this.router.navigate(['../scan-nearby-devices'], { relativeTo: this.r });
          } else if (result.hwId === 0x3b && result.revision && this.ds.revisionStartsWith(result.revision, ['1', '2', '3E', '52'])) {
            this.ds.setHwId(result.hwId);
            this.router.navigate(['../scan-nearby-devices'], { relativeTo: this.r });
          } else {
            this.miniStatus.hide();
            this.router.navigate(['../new-system-offline'], { relativeTo: this.r });
          }
        } else {
          this.miniStatus.hide();
          this.router.navigate(['../new-system-offline'], { relativeTo: this.r });
        }
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }

  public qrScanClick() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().showQr();
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().showQr.postMessage('');
    } else {
      this.log('neegzistuoja jsi');
    }
  }

  private handleQrCode(code: string) {
    this.log('We have received QR code ' + code);
    const codeLen = code.length;
    if (code !== this.systemUid && (codeLen === 12 || codeLen === 15 || codeLen === 16)) {
      this.systemUid = code;
      this.log('imei value is: ' + this.systemUid);
      this.refreshWindow();
      if (this.platform.isAndroid()) {
        try {
          this.platform.androidHandler().onQrAccepted();
        } catch {
          // senas android app neturi funkcijos. Darom catch, kad klaidos be reikalo neraportuotų į serverį.
        }
      } else if (this.platform.isApple()) {
        this.platform.appleHandler().onQrAccepted.postMessage('');
      }
    }
  }

  private checkIfInForeign(ipcom: number | undefined) {
    if (ipcom !== undefined) {
      this.ns.setIpcom(ipcom);
    }
    this.api.post('/get-system-info-new', {
      systemUid: this.ns.getUID(),
      mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
      srv: ipcom === undefined ? 0 : ipcom,
    }, true).subscribe(
      (result) => {
        this.miniStatus.hide();
        if ( !result.success ) {
          this.toaster.postError(result.error);
          return;
        }

        this.ns.setDeviceInfo(result.data);
        if ( !result.data.isInForeignRegion ) {
          this.log('Modulis musu regione, einam toliau');
          if ( this.us.currentUser.permissions.permissions.dev_setup_templates.execute ) {
            this.router.navigate(['../select-template'], { relativeTo: this.r });
          } else {
            this.router.navigate(['../add-system-details'], { relativeTo: this.r });
          }
          return;
        }
        this.log('Modulis kitame regione. Tikrinam ar galima perardersuot.');
        const foreignRegionName = result.data.foreignRegion;
        if (
          result.data.supported_commands.indexOf('.18.') === -1 &&
          !result.data.supported_commands.endsWith('.18') &&
          result.data.supported_commands.indexOf('.22.') === -1 &&
          !result.data.supported_commands.endsWith('.22')
        ) {
          this.log('Modulis nepalaiko reikiamos komandos');
          this.errorMessageForUnsupportedDevice = this.trans('systems.errors.devRegUnsupported').replace(':region', foreignRegionName);
          this.msg.open({
            buttons: this.msg.buttons.Ok,
            headerText: this.trans('systems.titles.notSupported'),
            messageContent: this.errorMessageForUnsupportedDevice
          });
        } else {
          // const hwIdInt = parseInt(result.data.hwId, 16);
          // if ( ![0x3B, 0x24, 0x37, ].includes(hwIdInt) ) {
          //   this.log('Modulis palaiko komandą bet šiuo metu nėra palaikomas.');
          //   this.errorMessageForUnsupportedDevice = this.trans('systems.errors.devTempUnsupported');
          //   this.messageBox.show();
          // } else
          if (result.foreignSystem !== null && result.ownRegion !== null && result.foreignSystem.length > 0) {
            this.log('Modulis palaiko komandą, bet sename regione rasta sistema.');
            this.ns.foreignSystem = result.foreignSystem[0];
            this.ns.foreignRegionName = foreignRegionName;
            this.ns.ownRegion = result.ownRegion;
            if ( this.us.currentUser.permissions.permissions.dev_setup_templates.execute ) {
              this.router.navigate(['../select-template'], { relativeTo: this.r });
            } else {
              this.router.navigate(['../confirm-device-transfer'], { relativeTo: this.r });
            }
          } else {
            this.log('Modulis palaiko komandą. Einam toliau.');
            if ( this.us.currentUser.permissions.permissions.dev_setup_templates.execute ) {
              this.router.navigate(['../select-template'], { relativeTo: this.r });
            } else {
              this.router.navigate(['../add-system-details'], { relativeTo: this.r });
            }
          }
        }
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }
}
