
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import requests from 'src/api/v3/requests';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { IPCom } from 'src/app/models/ipcom';
import { PopupType } from 'src/app/models/popup-type';
import { StatusMessageComponent } from 'src/app/popups/status-message/status-message.component';
import { PopupService } from 'src/app/services/popup.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { companyRootRoute } from 'src/app/services/global.service';
import { BasicSuccessResponse, ErrorResponse } from 'src/api/v3/common';
import { AddIpcomRequestResponse } from 'src/api/v3/settings';
import { ItemConfiguration } from 'src/app/company/components/company-columns/company-columns.component';
@Component({
  selector: 'app-su-ipcom',
  templateUrl: './su-ipcom.component.html',
  styleUrls: ['./su-ipcom.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuIpcomComponent extends LanguageAware implements OnInit, OnDestroy {
  private readonly tabNames = [ 'main', 'receivers' ];

  public readonly delayDuration = 2000;
  public readonly isDesktop = this.platform.isDesktopView();
  public readonly isNew = this.ar.routeConfig.path === 'new';
  public readonly canDelete = this.us.currentUser.permissions.permissions.ipcom_settings.delete && this.us.currentUser.company_id === 0;

  private subscriptions: Subscription[] = [];

  public regions = [];
  public regionText = '';
  public companyText = '';
  public testingIPCom = false;
  public ipcomItem: IPCom = null;
  public isRequestLoading = false;
  public ipcomDataChanged = false;
  public fileToUploadUrl: any = null;
  public receiversTabIsVisible = false;
  public companyList: DropdownItem<number>[] = [];
  public activeTabName = this.persistenceService.bind<'tab_control_page', string>('tab_control_page', 'main');
  public canEdit = this.us.currentUser.permissions?.permissions.ipcom_settings.edit && this.us.currentUser.company_id === 0;
  public receiverColumns = this.persistenceService.bind('ipcom_receiver_list_columns', ['receiver_name', 'port', 'ip', 'tcp_port', 'out_mode', 'fake:delete']);
  public readonly footerButtons = [
    {
      label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.onDesktopSave(),
    },
    { label: this.trans('general.cancel'),  callback: () => this.navigateBack() },
  ];
  public readonly val = new ValidatorBuilder<Omit<IPCom, 'ipcom_logo' | 'tags'>>()
  .required('peer_name', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.peerName'))}`)
  .required('host', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.host'))}`)
  .regex('host', /^[a-zA-Z0-9.]{3,255}$/, `${this.trans('validation.min.string')
  .replace(':attribute', this.trans('settings.labels.host')).replace(':min', '3')}`)
  .required('port', `${this.trans('validation.required').replace(':attribute', this.trans('settings.grg.label.port'))}`)
  .regex('port', /^(?!0{1,4}$)(?!0\d)\d{1,5}$/, `${this.trans('validation.regex').replace(':attribute', this.trans('settings.grg.label.port'))}`)
  .required('user_name', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.userName'))}`)
  .required('password', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.password'))}`)
  .required('region', `${this.trans('validation.required').replace(':attribute', this.trans('settings.ipcom.region'))}`)
  .regex('region', /^[0-9]+$/, `${this.trans('validation.numeric').replace(':attribute', this.trans('settings.ipcom.region'))}`)
  .build().bindContext(this);
  private idChangeSubscription = this.ar.params.subscribe((params) => {
    if ( !this.isDesktop ) { return; }
    const id = Number(params.ipcomId);
    if ( id === this.editSystemService.getId(TEditableComponent.IPcom) ) {
      this.reinitVariables();
      return;
    }
    if ( this.ipcomDataChanged || this.editSystemService.getId(TEditableComponent.IPcom) !== -1) {
      this.revert();
    }
    if (id && !isNaN(id)) {
      const ipcom = this.ipcomService.ipcoms.get(id);
      if (!ipcom) {
        throw new Error('IPCom not found');
      }
      this.editSystemService.beginComponentEdit(TEditableComponent.IPcom, ipcom.id, ipcom);
    }
    this.reinitVariables();
  });
  // !!! Užkomentuoti irašai veiksmingi, taciau šiuo metu nenaudojami. Ateityje galbut viskas bus naudojama.
  public readonly receiverColumnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('settings.ipcom.securityCName'), value: 'receiver_name' },
    //{ name: this.trans('settings.ipcom.receiverNumber'), value: 'receiver_number' },
    //{ name: this.trans('settings.ipcom.lineNumber'), value: 'line_number' },
    { name: this.trans('settings.ipcom.inputPort'), value: 'port' },
    { name: this.trans('settings.ipcom.outputHost'), value: 'ip' },
    { name: this.trans('settings.ipcom.outputPort'), value: 'tcp_port' },
    { name: this.trans('settings.ipcom.networkMode'), value: 'network_mode' },
    //{ name: this.trans('settings.ipcom.positiveDeviation'), value: 'deviation_positive' },
    //{ name: this.trans('settings.ipcom.negativeDeviation'), value: 'deviation_negative' },
    { name: this.trans('settings.ipcom.outputProtocol'), value: 'out_mode' },
    { name: this.trans('settings.ipcom.tags'), value: 'tags' },
    //{ name: this.trans('settings.ipcom.heartBeatEnabled'), value: 'hb_enable' },
    //{ name: this.trans('settings.ipcom.heartBeatInterval'), value: 'hb_interval' },
    //{ name: this.trans('settings.ipcom.outputBufferSize'), value: 'buffer_size' },
    //{ name: this.trans('settings.ipcom.outputIdentificator'), value: 'identificator' },
    this.us.currentUser.company_id === 0 ? { name: this.trans('settings.ipcom.companySelect'), value: 'company_id' } : null,
    { name: this.trans('settings.ipcom.outputEncryptionEnabled'), value: 'encryption_enabled' },
    this.canDelete ? { name: this.trans('general.delete'), value: 'fake:delete' } : null,
  ];

  constructor(
    cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private pp: PopupService,
    private router: Router,
    private ar: ActivatedRoute,
    private messagebox: MessageboxService,
  ) {
    super(cdRef);
    if ( !this.tabNames.includes(this.activeTabName.value) ) {
      this.activeTabName.value = 'main';
    }
    this.reinitVariables();
    this.headerBar.showHeader({
      headerText: this.ipcomItem.id === 0 ? this.trans('systems.titles.addNewIpComServer') : this.trans('systems.titles.editIpComServer'),
      backUrl: '*',
      actionButtonText: this.ipcomItem.id === 0 || !this.canDelete ? '' : this.trans('general.delete'),
    });
    this.background.setGray();
    this.loadCompanies();
    this.headerBar.onBackClicked = () => {
      this.revert();
    };
    if ( this.canDelete ) {
      this.headerBar.onActionButtonClicked = () => {
        this.delete();
      };
    }
  }

  private reinitVariables() {
    this.ipcomItem = this.editSystemService.getEditableComponent(TEditableComponent.IPcom);
    if( !this.ipcomItem ) {
      const i: IPCom = {
        id: 0,
        company_id: this.us.currentUser.company_id,
        description: '',
        ipcom_logo: null,
        name: '',
        password: '',
        peer_name: '',
        port: 0,
        user_name: '',
        host: '',
        host_for_devices: '',
        own_ipcom: true,
        region: 0,
        encryption_for_devices: '',
        port_for_devices: '',
      };
      this.ipcomItem = i;
      this.editSystemService.beginComponentEdit(TEditableComponent.IPcom, i.id, i); // išsaugom, kad grižus iš receivers lango turetume duomenis su pakeitimais.
    }
    if ( this.ipcomItem.id === 0 ) {
      this.canEdit = this.us.currentUser.permissions?.permissions.ipcom_settings.create;
    }
    const reg = this.us.currentUser.regions.find((r) => r.id === this.ipcomItem.region);
    if (reg !== undefined) {
      this.regionText = reg.name;
    } else {
      this.regionText = this.trans('general.none'); //
    }
    if(this.isDesktop) {
      this.generateRegionListForDropdown();
    }
    if ( this.ipcomItem.id !== 0 ) {
      this.loadSettingsAndShowTab();
    }
    if ( !this.ipcomService.ipcomSettings.has(this.ipcomItem.id) && this.activeTabName?.value === 'receivers' ) {
      this.activeTabName.value = 'main';
      this.receiversTabIsVisible = false;
    }
    if ( this.canEdit ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
      this.footerBar.onButton1Click = () => {
        this.revert();
        history.back();
      };
      this.footerBar.onButton2Click = () => {
        if (this.ipcomDataChanged) {
          this.doSave().then((result) => {
            if ( result ) {
              this.ipcomDataChanged = false;
              this.sendSettingsToIpcom().then(() => {
                this.miniStatus.hide();
                this.isRequestLoading = false;
                this.editSystemService.endComponentEdit(TEditableComponent.IPComSettings);
                this.editSystemService.endComponentEdit(TEditableComponent.IPcom);
                if ( !this.isDesktop ) {
                  history.back();
                }
              });
            } else {
              this.miniStatus.hide();
              this.isRequestLoading = false;
            }
          });
        }
      };
    } else {
      this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
      this.footerBar.onButton1Click = () => {
        this.revert();
        history.back();
      };
    }
    this.onInputChanged();
  }

  ngOnInit(): void {
    this.loadSettingsAndShowTab();
  }

  ngOnDestroy(): void {
    this.idChangeSubscription?.unsubscribe();
    if(this.ipcomDataChanged) {
      this.revert();
    }
  }

  private rebuildCompanyText() {
    const company = this.companyList.find((c) => c.value === this.ipcomItem.company_id);
    if (company === undefined) {
      this.companyText = this.trans('settings.ipcom.companyNotAssigned');
    } else {
      this.companyText = company.label as string;
    }
  }

  private async loadCompanies() {
    if ( this.us.currentUser.company_id !== 0 ) { return; }
    const companies = await requests.company.getCompanies({ forList: true }).toPromise();
    this.miniStatus.hide();
    this.companyList = [
      {
        label: this.trans('settings.ipcom.companyNotAssigned'),
        value: 0,
      },
    ];
    for (const iCompany of companies.list.data) {
      this.companyList.push({
        label: iCompany.name,
        value: iCompany.id,
      });
    }
    this.rebuildCompanyText();
  }

  public changeCompany() {
    this.pp.showSlideout<DropdownItem<number>>(
      {
        headerText: this.trans('settings.ipcom.companySelect'),
        items: this.companyList,
        onSubmit: (res) => {
          this.ipcomItem.company_id = res.value;
          this.rebuildCompanyText();
          this.companyChanged();
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public companyChanged() {
    const found = this.ipcomService.ipcomSettings.get(this.ipcomItem.id);
    found?.receivers.forEach(r => r.company_id = this.ipcomItem.company_id);
  }

  private revert() {
    if ( !this.ipcomItem || this.ipcomItem.id === 0 ) {
      this.editSystemService.endComponentEdit(TEditableComponent.IPComSettings);
      if ( this.ipcomService.ipcomSettings.has(0) ) {
        this.ipcomService.ipcomSettings.delete(0);
      }
      return;
    }
    this.ipcomDataChanged = false;
    const original = this.editSystemService.getComponentBeforeModification(TEditableComponent.IPcom);
    this.ipcomItem.ipcom_logo = original.ipcom_logo;
    if (this.ipcomItem.ipcom_logo !== null) {
      this.ipcomItem.ipcom_logo = this.sanitizer.bypassSecurityTrustUrl(this.ipcomItem.logoDataForStorage);
    }
    this.ipcomItem.peer_name = original.peer_name;
    this.ipcomItem.host = original.host;
    this.ipcomItem.port = original.port;
    this.ipcomItem.user_name = original.user_name;
    this.ipcomItem.password = original.password;
    this.ipcomItem.company_id = original.company_id;
    this.ipcomItem.name = original.name;
    this.ipcomItem.description = original.description;
    this.ipcomItem.region = original.region;
    this.ipcomItem.own_ipcom = original.own_ipcom;
    const settings = this.editSystemService.getComponentBeforeModification(TEditableComponent.IPComSettings);
    if ( !settings ) { return; }
    if ( this.ipcomService.ipcomSettings.has(this.ipcomItem.id) ) {
      this.ipcomService.ipcomSettings.delete(this.ipcomItem.id);
    }
    this.ipcomService.ipcomSettings.set(this.ipcomItem.id, settings);
    this.editSystemService.endComponentEdit(TEditableComponent.IPComSettings);
  }

  private async doSave(): Promise<boolean> {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return false;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    let result;
    const foundSettings = this.ipcomService.ipcomSettings.get(this.ipcomItem.id);
    if ( this.ipcomItem.id === 0 ) {
      result = await requests.settings.ipcom.add({ main: this.ipcomItem, settings: foundSettings }).toPromise();
    } else {
      result = await requests.settings.ipcom.save({ main: this.ipcomItem, settings: foundSettings }).toPromise();
    }
    if ( (result as BasicSuccessResponse).success ) {
      if ( this.ipcomItem.id === 0 ) {
        this.ipcomItem.id = (result as AddIpcomRequestResponse).id;
        this.ipcomService.ingestIpcom(this.ipcomItem);
        if ( this.ipcomService.ipcomSettings.has(0) ) {
          const settings = this.ipcomService.ipcomSettings.get(0);
          this.ipcomService.ipcomSettings.delete(0);
          this.ipcomService.ingestIpcomSettings(this.ipcomItem.id, settings);
        }
      }
      return true;
    } else {
      this.toaster.postError((result as ErrorResponse).error);
      return false;
    }
  }

  public async testConnection() {
    this.testingIPCom = true;
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    if ( !this.canEdit ) { return; }
    let statusMessage: StatusMessageComponent;
    this.pp.openPopup(StatusMessageComponent, {
      paramSetter: (p) => {
        statusMessage = p;
        p.showLoader(this.trans('settings.ipcom.testing'));
      },
      attachToClass: this.isDesktop ? undefined : 'mobile-app',
    });
    this.miniStatus.show(this.trans('general.pleaseWait'));
    try {
      console.log(this.ipcomItem);
      const result = await requests.settings.ipcom.test(this.ipcomItem).toPromise();
      if (result.success) {
        if (result.status) {
          this.testIPComMessage(true, statusMessage);
          this.loadSettingsAndShowTab();
        } else {
          this.testIPComMessage(false, statusMessage, this.trans('settings.ipcom.testResult') + ': ' + this.trans('settings.notConnected'));
        }
        if ( !this.ipcomService.ipcomSettings.has(this.ipcomItem.id) && result.settings !== false ) {
          this.ipcomService.ingestIpcomSettings(this.ipcomItem.id, result.settings);
        }
      } else {
        this.testIPComMessage(false, statusMessage, (result as unknown as ErrorResponse).error);
      }
    } catch (e) {
      statusMessage.close();
    } finally {
      this.testingIPCom = false;
      this.miniStatus.hide();
    }
  }

  private testIPComMessage(success: boolean, statusMessage: StatusMessageComponent, message?: string) {
    const messageboxBodyText = success ?
      this.trans('settings.buttons.testConnection') + ': ' + this.trans('settings.connected') :
      message ? message : this.trans('auth.errors.serverSideError');
    if(this.isDesktop) {
      statusMessage.close();
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('settings.ipcom.testComplete'),
        messageContent: messageboxBodyText,
        iconType: success ? this.messagebox.iconType.Success : this.messagebox.iconType.Error
      });
    } else {
      if(success) {
        statusMessage.showSuccess(this.trans('settings.ipcom.testComplete'), this.trans('settings.connected'));
      } else {
        statusMessage.showFailure(this.trans('settings.ipcom.testComplete'), message ? message : this.trans('auth.errors.serverSideError'));
      }
    }
  }

  public onDeleteClick() {
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('settings.ipcom.deleteIpcomInfo').replace(':ipcom', this.ipcomItem.name)}: ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.postDeleteRequest();
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private postDeleteRequest() {
    const that = this;
    that.api.post('/delete/ipcom', { id: that.ipcomItem.id }, true).subscribe(
      (result) => {
        if (result.success) {
          that.us.currentUser.ipcoms = that.us.currentUser.ipcoms.filter((ipc) => ipc.id !== that.ipcomItem.id);
          that.router.navigate([companyRootRoute + '/settings/ipcoms']);
        } else {
          const errorMessage = result.error ? result.error : '';
          const messageContent = `${that.trans('general.errors.errorOccurred')}${errorMessage !== '' ? `: ${errorMessage}` : '.'}`;
          that.showMessagebox(false, messageContent);
        }
      },
      (error) => { }
    );
  }

  public delete() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/delete/ipcom', { id: this.ipcomItem.id }, true).subscribe(
      (result) => {
        if (result.success) {
          that.us.currentUser.ipcoms = that.us.currentUser.ipcoms.filter((ipc) => ipc.id !== that.ipcomItem.id);
          that.miniStatus.hide();
          that.router.navigate(['/sadmin/ipcoms']);
        } else {
          that.miniStatus.hide();
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
      }
    );
  }

  private generateRegionListForDropdown() {
    const regions = new Set();
    for (const iRegion of this.us.currentUser.regions) {
      const region = {
        value: iRegion.id,
        label: iRegion.name,
      };
      if (!regions.has(region)) {
        regions.add(region);
      }
    }
    this.regions = [...regions];
  }

  public changeRegion() {
    const that = this;
    const regions = [];
    for (const iRegion of this.us.currentUser.regions) {
      regions.push({
        value: iRegion.id,
        text: iRegion.name,
      });
    }
    this.pp.showSlideout<DropdownItem<number>>(
      {
        headerText: this.trans('settings.ipcom.region'),
        items: regions,
        onSubmit: (res) => {
          that.ipcomItem.region = res.value;
          that.regionText = res.label as string;
          this.onInputChanged();
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  private async onDesktopSave() {
    const validationResult = await this.val.validate(this.ipcomItem);
    if(validationResult === false) { return; };
    this.isRequestLoading = true;
    try {
      if ( ! await this.doSave() ) { return; }
      if ( ! await this.sendSettingsToIpcom() ) { return; }
      this.editSystemService.endComponentEdit(TEditableComponent.IPComSettings);
      this.editSystemService.endComponentEdit(TEditableComponent.IPcom);
      await new Promise<void>(() => setTimeout(() => {
        this.ipcomDataChanged = false;
        this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'ipcoms'], { replaceUrl: true });
      }, this.delayDuration));
    } finally {
      this.miniStatus.hide();
      this.isRequestLoading = false;
    }
  }

  private showMessagebox(success: boolean, message: string) {
    this.messagebox.open({
      buttons: this.messagebox.buttons.Close,
      headerText: success ? this.trans('general.titleSuccess') : this.trans('general.titleError'),
      messageContent: message,
      iconType: success ? this.messagebox.iconType.Success : this.messagebox.iconType.Error
    });
  }

  private navigateBack() {
    this.revert();
    this.editSystemService.endComponentEdit(TEditableComponent.IPcom);
    this.editSystemService.endComponentEdit(TEditableComponent.IPComSettings);
    if ( this.isDesktop ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'ipcoms'], { replaceUrl: true });
    } else {
      history.back();
    }
  }

  public onTabSelected(name: string) {
    this.activeTabName.value = name;
  }

  private async loadSettingsAndShowTab() {
    await this.ipcomService.loadSettingsForIpcom(this.ipcomItem.id);

    if ( !this.ipcomService.ipcomSettings.has(this.ipcomItem.id) ) { return; }
    this.receiversTabIsVisible = true;
    if ( this.editSystemService.getId(TEditableComponent.IPComSettings) !== -1 ) { return; }
    this.editSystemService.beginComponentEdit(TEditableComponent.IPComSettings, this.ipcomItem.id, this.ipcomService.ipcomSettings.get(this.ipcomItem.id));
  }

  public onInputChanged() {
    if ( !this.isDesktop ) { return; }
    const changed = this.editSystemService.hasChanged(TEditableComponent.IPcom) || this.editSystemService.hasChanged(TEditableComponent.IPComSettings);
    this.ipcomDataChanged = changed;
  }

  public addReceiver() {
    this.ipcomDataChanged = false; // kad nebandytų daryti revert.
    this.router.navigate(['receiver', 'new'], { relativeTo: this.ar });
  }

  private async sendSettingsToIpcom(): Promise<boolean> {
    if ( !this.ipcomService.ipcomSettings.has(this.ipcomItem.id) ) {
      return true;
    }

    const result = await requests.settings.ipcom.write({ id: this.ipcomItem.id, settings: this.ipcomService.ipcomSettings.get(this.ipcomItem.id)}).toPromise();
    return result.success;
  }

  public backButtonPressed() {
    this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'ipcoms'], { replaceUrl: true });
  }
}
