import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-quick-outputs',
  templateUrl: './quick-outputs.component.html',
  styleUrls: ['./quick-outputs.component.scss'],
})
export class QuickOutputsComponent extends LanguageAware implements OnInit {
  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.outputs'),
      backUrl: '*',
      showSettings: true,
    });
    this.background.setGray();
    localStorage.setItem('ref', 'quick');
    this.headerBar.onActionButtonClicked = () => {
      this.router.navigate(['../system-pgm-list'], { relativeTo: this.r });
    };
  }

  ngOnInit(): void {}
}
