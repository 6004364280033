<app-base-popup #basePopup (closed)="closed.emit()">
    <app-loader-round #loaderComponent [isVisible]="true" [displayText]="loaderText" *ngIf="loaderText !== ''"></app-loader-round>
    <div class=" white_round padding16" id="sm_container" *ngIf="loaderText === ''">
        <div id="smi_close" (click)="close()">
            <div [inlineSVG]="'assets/images/close.svg'"></div>
        </div>
        <div id="smi_header">{{headerText}}</div>
        <div class="smi_icon" *ngIf="isSuccess" [inlineSVG]="'assets/images/success_green.svg'"></div>
        <div class="smi_icon" *ngIf="!isSuccess" [inlineSVG]="'assets/images/failed_red.svg'"></div>
        <div id="smi_status" [ngClass]="isSuccess ? 'smi_success' : 'smi_failure'">{{statusText}}</div>
    </div>
</app-base-popup>