import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/api/request.service';
import { emailValidator } from 'src/app/login/components/login-email/login-email.component';
import { LoginService } from 'src/app/login/login.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  public isLoading = false;
  public val = emailValidator.build();
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(public login: LoginService, private router: Router, private req: RequestService, private ls: LanguageService) {}

  ngOnInit(): void {}

  public async reset() {
    if (!(await this.val.validate(this.login))) {return;}
    this.isLoading = true;
    const res = await this.req.login.passwordReset(this.login.email).toPromise();
    if (res.success) {
      this.router.navigate(['login', 'password-reset', 'verify'], { replaceUrl: true });
    } else if (!res.success) {
      this.val.pushError('email', (res as any).error);
      this.isLoading = false;
    }
  }
}
