import { Component, Input, OnInit } from '@angular/core';
import { Tag } from 'src/api/v3/common';

@Component({
  selector: 'app-inline-tag-list',
  templateUrl: './inline-tag-list.component.html',
  styleUrls: ['./inline-tag-list.component.scss']
})
export class InlineTagListComponent implements OnInit {
  @Input() tags: Tag[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
