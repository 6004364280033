<app-base-popup #basePopup [canCancel]="false">
    <div class="white_round padding16 aap_container" >
        <div class='aap-icon' *ngIf="activeArea.alarmType === 0"><div [inlineSVG]="'assets/images/area_alarmed.svg'"></div></div>
        <div class='aap-icon' *ngIf="activeArea.alarmType === 1"><div [inlineSVG]="'assets/images/event_fire.svg'"></div></div>
        <div class='aap-alarm-text' *ngIf="activeArea.alarmType === 0">{{trans('systems.statuses.area.alarm')}}</div>
        <div class='aap-alarm-text' *ngIf="activeArea.alarmType === 1">{{trans('systems.statuses.area.alarmFire')}}</div>
        <div class='aap-area-name'>{{activeArea.name}}</div>
        <ng-container *ngIf="!areaIsPanicButtonsArea(); else panicButtonsArea">
          <div class='aap-button button' (click)='doDisarm()' *ngIf="activeArea.status !== 1">{{areaService.getDescriptionForStatus(1, false, activeArea)}}</div>
          <div class='aap-button button' *ngIf='systems.activeSystemHelper.supports.silenceSiren()' (click)='doSilence()'>{{trans('systems.buttons.silenceSiren')}}</div>
          <div class='aap-button button' *ngIf='systems.activeSystemHelper.supports.cancelAlarm()' (click)='doCancel()'>{{trans('systems.buttons.cancelAlarm')}}</div>
          <div class='aap-button button' *ngIf='systems.activeSystem.supportsFireReset && activeArea.alarmType === 1 && systems.activeSystemHelper.can.resetFireSensors()' (click)='gotoFireReset()'>{{trans('systems.buttons.sensors_reset')}}</div>
        </ng-container>
        <ng-template #panicButtonsArea>
          <div class='aap-button button' *ngIf='systems.activeSystemHelper.supports.cancelAlarm()' (click)='doCancel()'>{{trans('systems.statuses.area.endPanicAlarm')}}</div>
        </ng-template>
        <div class='aap-close' (click)='closeWindow()'>{{trans('general.close')}}</div>
    </div>
</app-base-popup>
<app-area-keypad *ngIf='activeArea !== null && showPinKeypad' [keypadType]='"slideout"' (cancelClicked)='keypadCancelled()' [maxLength]='6' [minLength]='4' (confirmClicked)='pinConfirmed($event.pin, $event.remember_pin)'></app-area-keypad>
