import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { IpcomService } from 'src/app/api/ipcom.service';
import { BindObject } from 'src/app/api/persistence.service';
import { ItemConfiguration } from 'src/app/company/components/company-columns/company-columns.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { UResolvable } from 'src/app/guards/UResolvable';
import { DeviceSetupTemplateService } from 'src/app/services/device-setup-template.service';
import { LocatorService } from 'src/app/services/locator.service';
import { autoinject } from 'src/shim';

@Component({
  selector: 'app-cp-dev-setup-templates',
  templateUrl: './cp-dev-setup-templates.component.html',
  styleUrls: ['./cp-dev-setup-templates.component.scss']
})
export class CpDevSetupTemplatesComponent extends LanguageAware implements OnInit, UResolvable<typeof CpDevSetupTemplatesComponent> {
  public readonly companyRootRoute = this.g.resolveRootRoute().join('/');
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('setup.device.name'), value: 'name' },
    { name: this.trans('setup.device.inputHost'), value: 'host' },
    { name: this.trans('setup.device.receiverNumber'), value: 'receiver_nr' },
    { name: this.trans('setup.device.line_nr'), value: 'line_nr' },
    { name: this.trans('setup.device.port'), value: 'port' },
    { name: this.trans('setup.device.templateCompany'), value: 'company_id' },
    { name: this.trans('setup.device.templateUsers'), value: 'users' },
  ];
  public columns: BindObject<string[]>;

  constructor(
    cdRef: ChangeDetectorRef
  ) {
    super(cdRef);
    this.setDefaultColumns();
  }

  ngOnInit(): void {
  }

  private setDefaultColumns() {
    const defaultColumns = ['name', 'host', 'receiver_nr', 'line_nr', 'port'];
    if(this.us.currentUser.permissions?.permissions.dev_setup_templates.create || this.us.currentUser.permissions?.permissions.dev_setup_templates.delete) {
      this.columnConfig.push({ name: this.trans('setup.device.modify'), value: 'fake:modify' });
      defaultColumns.push('fake:modify');
    }
    this.columns = this.persistenceService.bind('dev_template_columns', defaultColumns);
  }

  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot) {
    const ipcService = autoinject(LocatorService.injector, IpcomService);
    const setupTemplateService = autoinject(LocatorService.injector, DeviceSetupTemplateService);
    await setupTemplateService.loadDeviceSetupTemplates();
    await ipcService.loadReceiversForTemplates();
  }

}
