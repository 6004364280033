<div *ngIf='!zoneIsPanicButton() && bypassFunctionAvailable()' class="grid zone-bypass-grid" (click)='performBypass()'>
    <div class="group-background"></div>
    <div *ngIf='!localZone.bypassed' id='zbypass-do'>{{trans('systems.buttons.bypassZone')}}</div>
    <div *ngIf='localZone.bypassed' id='zbypass-undo'>{{trans('systems.labels.cancelZoneBypass')}}</div>
    <div id='zbypass-icon' [inlineSVG]="'assets/images/bypass.svg'"></div>
</div>
<div class="white_round padding_sides16">
    <app-text-edit id='asz-details-zone-number' [value]='localZone.queue_no' (enterClicked)='onNext()' [maxCharacters]='3' [inputType]="'number'" (valueChanged)="setZoneNumber($event)" [isReadOnly]='queueNumberIsReadOnly()'>{{trans('systems.labels.zoneQueueNo')}}</app-text-edit>
    <app-text-edit id='asz-details-zone-name' [value]='localZone.name' (enterClicked)='onNext()' (valueChanged)="localZone.name = $event" [doFocus]='true' [isReadOnly]='!systems.activeSystemHelper.can.edit.zones() && workingWithActive' [maxCharacters]="maxZoneLen">{{trans('systems.labels.zoneName')}}</app-text-edit>
    <div id='asz-assign-description' *ngIf="((workingWithActive && systems.activeSystemHelper.supports.areas()) || !workingWithActive) && canAssignZone">{{trans('systems.labels.asignZonesToAreas')}}</div>
    <div id='asz-area-list-container' *ngIf="((workingWithActive && systems.activeSystemHelper.supports.areas()) || !workingWithActive) && canAssignZone">
        <div class="list-item-v2 {{(canEdit || !workingWithActive) && visibleAreas.length > 1 ? '' : 'is_disabled'}}" *ngFor="let area of visibleAreas" (click)='areaClick(area.queue_no)'>
            <div>
                <app-checkbox [isRound]="true" [checkValue]="zoneHasArea(area.queue_no)" (valueChanged)="areaClick(area.queue_no)" [isSecondaryColor]="true" (click)="$event.stopPropagation()"></app-checkbox>
            </div>
            <div [inlineSVG]="'assets/images/area-general-icon.svg'"></div>
            <div>{{area.queue_no}}</div>
            <div>
                <div>{{area.name}}</div>
                <div>{{getAssignedPgmName(area)}}</div>
            </div>
        </div>
    </div>
</div>
<app-area-keypad *ngIf='showKeypad' [keypadType]='"slideout"' (cancelClicked)='keypadCancelled()' [maxLength]='6' [minLength]='4' (confirmClicked)='pinConfirmed($event.pin, $event.remember_pin)'></app-area-keypad>
