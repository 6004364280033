import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from '../api/logger.service';
import { SystemsService } from '../services/systems.service';

@Injectable({
  providedIn: 'root'
})
export class MobileToDesktopUserGuard implements CanDeactivate<unknown> {
  private readonly routeMapping = new Map<string, (systemId?: string, subPath?: string[]) => UrlTree>([
    ['/home', (id) => this.router.createUrlTree(['/d', id, 'home'])],
    ['/add-system', () => this.router.createUrlTree(['/d', 'add-system'])],
    ['/add-system-details', () => this.router.createUrlTree(['/d', 'add-system-details'])],
    ['/confirm-device-transfer', () => this.router.createUrlTree(['/d', 'confirm-device-transfer'])],
    ['/activate-email', () => this.router.createUrlTree(['/d', 'activate-email'])],
    ['/no-password', () => this.router.createUrlTree(['/d', 'no-password'])],
    ['/password-register', () => this.router.createUrlTree(['/d', 'password-register'])],
    ['/name-register', () => this.router.createUrlTree(['/d', 'name-register'])],
    ['/home-background-selection', () => this.router.createUrlTree(['/d', 'home-background-selection'])],
    ['/system-location', () => this.router.createUrlTree(['/d', 'system-location'])],
    ['/wiring-diagrams', () => this.router.createUrlTree(['/d', 'wiring-diagrams'])],
    ['/add-system-indirect', () => this.router.createUrlTree(['/d', 'add-system-indirect'])],
    ['/add-system-area-pgms', () => this.router.createUrlTree(['/d', 'add-system-area-pgms'])],
    ['/add-system-area', () => this.router.createUrlTree(['/d', 'add-system-area'])],
    ['/add-system-area-list', () => this.router.createUrlTree(['/d', 'add-system-area-list'])],
    ['/customize-pgms', () => this.router.createUrlTree(['/d', 'customize-pgms'])],
    ['/system-zone', (id) => this.router.createUrlTree(['/d', id, 'system-zone'])],
    ['/add-system-zone-list', () => this.router.createUrlTree(['/d', 'add-system-zone-list'])],
    ['/add-system-complete', () => this.router.createUrlTree(['/d', 'add-system-complete'])],
    ['/system-configuration', (id) => this.router.createUrlTree(['/d', id])],
    ['/system-information', (id) => this.router.createUrlTree(['/d', id, 'system-information'])],
    ['/system-notifications', (id) => this.router.createUrlTree(['/d', id, 'system-notifications'])],
    ['/system-area-list', (id) => this.router.createUrlTree(['/d', id, 'system-area-list'])],
    ['/system-area', (id) => this.router.createUrlTree(['/d', id, 'system-area'])],
    ['/area-pgm-list', (id) => this.router.createUrlTree(['/d', id, 'area-pgm-list'])],
    ['/system-zone-list', (id) => this.router.createUrlTree(['/d', id, 'system-zone-list'])],
    ['/system-zone-list/:destination', (id) => this.router.createUrlTree(['/d', id, 'system-zone-list/:destination'])],
    ['/system-pgm-list', (id) => this.router.createUrlTree(['/d', id, 'system-pgm-list'])],
    ['/system-pgm', (id) => this.router.createUrlTree(['/d', id, 'system-pgm'])],
    ['/system-transfer', (id) => this.router.createUrlTree(['/d', id, 'system-transfer'])],
    ['/system-delete', (id) => this.router.createUrlTree(['/d', id, 'system-delete'])],
    ['/area-view', (id) => this.router.createUrlTree(['/d', id, 'area-view'])],
    ['/events', (id) => this.router.createUrlTree(['/d', id, 'events'])],
    ['/event-control', (id) => this.router.createUrlTree(['/d', id, 'event-control'])],
    ['/profile', (id, sub) => this.router.createUrlTree(['/d', 'profile'].concat(sub))],
    ['/password-change', () => this.router.createUrlTree(['/d', 'password-change'])],
    ['/users', (id) => this.router.createUrlTree(['/d', id, 'users'])],
    ['/user', (id) => this.router.createUrlTree(['/d', id, 'user'])],
    ['/customize-areas', () => this.router.createUrlTree(['/d', 'customize-areas'])],
    ['/customize-zones', () => this.router.createUrlTree(['/d', 'customize-zones'])],
    ['/zone-status-list', (id) => this.router.createUrlTree(['/d', id, 'zone-status-list'])],
    ['/pgm-icon-selection', (id) => this.router.createUrlTree(['/d', id, 'pgm-icon-selection'])],
    ['/home-configuration', (id) => this.router.createUrlTree(['/d', id, 'home-configuration'])],
    ['/system-sensor-list', (id) => this.router.createUrlTree(['/d', id, 'system-sensor-list'])],
    ['/system-sensor', (id) => this.router.createUrlTree(['/d', id, 'system-sensor'])],
    ['/event-control', (id) => this.router.createUrlTree(['/d', id, 'event-control'])],
    ['/show-zone', (id) => this.router.createUrlTree(['/d', id, 'show-zone'])],
    ['/transfer-from-list', (id) => this.router.createUrlTree(['/d', id, 'transfer-from-list'])],
    ['/select-language', () => this.router.createUrlTree(['/d', 'select-language'])],
    ['/camera-list', (id) => this.router.createUrlTree(['/d', id, 'camera-list'])],
    ['/help', (sub) => this.router.createUrlTree(['/d', 'help'].concat(sub))],
    ['/edit-camera', (id) => this.router.createUrlTree(['/d', id, 'edit-camera'])],
    ['/add-camera', (id) => this.router.createUrlTree(['/d', id, 'add-camera'])],
    ['/reread', (id) => this.router.createUrlTree(['/d', id, 'reread'])],
    ['/system-thermostat', (id) => this.router.createUrlTree(['/d', id, 'system-thermostat'])],
    ['/advanced-settings', (id) => this.router.createUrlTree(['/d', id, 'advanced-settings'])],
    ['/new-system-offline', () => this.router.createUrlTree(['/d', 'new-system-offline'])],
    ['/scan-nearby-devices', () => this.router.createUrlTree(['/d', 'scan-nearby-devices'])],
    ['/nearby-device-found', () => this.router.createUrlTree(['/d', 'nearby-device-found'])],
    ['/wifi-details', () => this.router.createUrlTree(['/d', 'wifi-details'])],
    ['/device-ap-connect', () => this.router.createUrlTree(['/d', 'device-ap-connect'])],
    ['/reset-fire-sensors', (id) => this.router.createUrlTree(['/d', id, 'reset-fire-sensors'])],
    ['/quick-view-cameras', (id) => this.router.createUrlTree(['/d', id, 'quick-view-cameras'])],
    ['/quick-view-outputs', (id) => this.router.createUrlTree(['/d', id, 'quick-view-outputs'])],
    ['/owner-privacy', (id) => this.router.createUrlTree(['/d', id, 'owner-privacy'])],
    ['/replace-device-administrator', (id) => this.router.createUrlTree(['/d', id, 'replace-device-administrator'])],
    ['/version', () => this.router.createUrlTree(['/d', 'version'])],
    ['/transfer-type', (id) => this.router.createUrlTree(['/d', id, 'transfer-type'])],
    ['/transfer-device', (id) => this.router.createUrlTree(['/d', id, 'transfer-device'])],
    ['/configure', (id, sub) => {
      if ( id !== undefined ) {
        return this.router.createUrlTree(['/d', id, 'configure'].concat(sub));
      } else {
        return this.router.createUrlTree(['/d', 'configure'].concat(sub));
      }
    }],
    ['/transfer-to-company', (id) => this.router.createUrlTree(['/d', id, 'transfer-to-company'])],
  ]);
  constructor(private router: Router, private l: LoggerService, private ss: SystemsService) {}

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (nextState) {
        let url = nextState.url;
        let subPath = '';
        if (url.startsWith('/d')) {
          return true;
        }
        this.l.log('Bandoma palikti company', 'MobileToDesktopUserGuard', { url });
        let systemId = currentRoute.paramMap.get('systemId');
        if ( !systemId || isNaN(Number(systemId)) ) {
          systemId = this.ss.activeSystem?.id.toString();
        }
        if (this.routeMapping.has(url)) {
          const newUrl = this.routeMapping.get(url)(systemId, subPath.split('/'));
          this.l.log('Nukreipiama atgal į company. [orig]', 'MobileToDesktopUserGuard', { newUrl: newUrl.toString() });
          return newUrl;
        }
        // Toliau tikrinam ar yra master path, kuris priims random subpath.
        if (url.includes('/', 1)) {
          subPath = url.substring(url.indexOf('/', 1) + 1);
          url = url.substring(0, url.indexOf('/', 1));
        }
        if (this.routeMapping.has(url)) {
          const newUrl = this.routeMapping.get(url)(systemId, subPath.split('/'));
          this.l.log('Nukreipiama atgal į company. [subpath]', 'MobileToDesktopUserGuard', { newUrl: newUrl.toString() });
          return newUrl;
        }
      }
    return true;
  }

}
