import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RtcService } from 'src/app/api/rtc/rtc.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';

@Component({
  templateUrl: './company-home.component.html',
  styleUrls: ['./company-home.component.scss'],
})
export class CompanyHomeComponent extends LanguageAware implements OnInit {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('systems.titles.icon'), value: 'fake:icon' },
    { name: this.trans('systems.labels.systemName'), value: 'name' },
    { name: this.trans('systems.labels.device'), value: 'hwType' },
    { name: this.trans('systems.labels.imei'), value: 'imei' },
    { name: this.trans('systems.labels.installer'), value: 'installer_id' },
    { name: this.trans('systems.labels.status'), value: 'online' },
    { name: this.trans('systems.buttons.delete'), value: 'fake:delete' },
  ];
  public columns = ['fake:icon', 'name', 'hwType', 'imei', 'installer_id', 'online', 'fake:delete'];
  constructor(cdRef: ChangeDetectorRef, private rtc: RtcService) {
    super(cdRef);
    this.rtc.areaStatus.subscribe((area) => {
      this.l.log('area', 'Test', area);
    });
    this.rtc.configurationEvent.subscribe((event) => {
      this.l.log('config', 'Test', event);
    });
    this.rtc.events.subscribe((event) => {
      this.l.log('event', 'Test', event);
    });
    this.rtc.pgm.subscribe((event) => {
      this.l.log('pgm', 'Test', event);
    });
    this.rtc.systemStatusChange.subscribe((event) => {
      this.l.log('system', 'Test', event);
    });
    this.rtc.userAccessControl.subscribe((event) => {
      this.l.log('access', 'Test', event);
    });
    this.rtc.userSystem.subscribe((event) => {
      this.l.log('user', 'Test', event);
    });
    this.rtc.versionUpdate.subscribe((event) => {
      this.l.log('version', 'Test', event);
    });
  }

  ngOnInit(): void {}
}
