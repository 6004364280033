import http from '../http';
import { BasicSuccessResponse, ErrorResponse, SystemEventData } from './common';

type GetEventRequest = {
  event_id: number;
};
type GetEventResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      event: SystemEventData;
    });

export const getEvent = (req: GetEventRequest) => http.post<GetEventResponse, GetEventRequest>('/v3/api/event', req);

type GetEventsRequestGeneral = {
  system_id: number;
  date_from?: string;
  date_to?: string;
  area?: number;
  system?: number; // Kodėl čia šitas parametras?
  type?: string;
  event_desc_id?: number;
  zone_id?: number;
  user_id?: number;
  company?: number;
  returnEventCount?: boolean;
  offsetEvents?: number;
  offsetCount?: number;
  userName?: string;
  reactions?: number[];
  tag_id?: number;
};
type GetEventsRequest = Omit<GetEventsRequestGeneral, 'system'>;
type EventReaction = {
  name: string;
  company: number | 1 | -1;
};
export type EventData = SystemEventData & {
  system_id: number;
};
type GetEventsResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      events: EventData[];
      eventTypes: { [0]: EventReaction; [key: number]: EventReaction };
      companies: { [-1]: string; [key: number]: string };
    });

export const getEvents = (req: GetEventsRequest) => http.post<GetEventsResponse, GetEventsRequest & { forApi: true }>('/v3/api/events', { forApi: true, ...req });

type GetAllEventsRequest = Omit<Partial<GetEventsRequestGeneral>, 'system_id'>;
type GetAllEventsResponse = BasicSuccessResponse & { events: EventData[] };

export const getAllEvents = (req: GetAllEventsRequest) => http.post<GetAllEventsResponse, GetAllEventsRequest & { forApi: true }>('/v3/api/all-events', { forApi: true, ...req });
