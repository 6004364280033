<ng-container *ngIf="isDesktop && activeTabName.value === 'main'">
  <div class="header">
    <div class="flex-1">
      <app-back-button [defaultBehaviour]="false" (buttonPressed)="backButtonPressed()"></app-back-button>
    </div>
    <ng-container *ngIf="!isNew && canDelete">
      <div class="delete" (click)="onDeleteClick()">
        <h4>{{trans('general.delete')}}</h4>
        <div class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
      </div>
    </ng-container>
  </div>
</ng-container>
<ng-container *ngIf="isDesktop && activeTabName.value === 'receivers'">
  <div class="header" *ngIf="isDesktop">
    <div class="flex-1">
      <app-back-button [defaultBehaviour]="false" (buttonPressed)="backButtonPressed()"></app-back-button>
    </div>
    <app-icon-button *ngIf="us.currentUser.permissions.permissions.ipcom_settings.create" (click)="addReceiver()" [label]="trans('settings.ipcom.addSecurityCenter')" icon="assets/images/company/plus.svg"></app-icon-button>
    <app-company-columns [items]="receiverColumnConfig" [(value)]="receiverColumns.value" #cols></app-company-columns>
  </div>
</ng-container>

<app-tab-control #tabControl [tabs]="[
  { name: 'main', title: trans('settings.ipcom.mainSettings'), content: mainSettings, show: true, active: activeTabName.value === 'main' },
  { name: 'receivers', title: trans('settings.ipcom.receiverSettings'), content: receiverSettings, show: receiversTabIsVisible, active: activeTabName.value === 'receivers' },
]" (tabChanged)="onTabSelected($event)"></app-tab-control>

<ng-template #mainSettings>
  <div class="white_round padding16">
    <app-string-input [label]="trans('settings.labels.nameIpcom')" [(value)]="ipcomItem.name" (valueChange)="onInputChanged()" [maxLength]="255" [disabled]="!canEdit"></app-string-input>
    <app-string-input [label]="trans('settings.labels.peerName')" [(value)]="ipcomItem.peer_name" (valueChange)="onInputChanged()" [maxLength]="255" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('peer_name')">{{ val.getError('peer_name') }}</app-form-error>
    </app-string-input>
    <app-string-input type="number" [label]="trans('settings.labels.host')" [(value)]="ipcomItem.host" (valueChange)="onInputChanged()" [maxLength]="255" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('host')">{{ val.getError('host') }}</app-form-error>
    </app-string-input>
    <app-number-input [label]="trans('settings.labels.port')" [(value)]="ipcomItem.port" (valueChange)="onInputChanged()" [maxLength]="5" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('port')">{{ val.getError('port') }}</app-form-error>
    </app-number-input>
    <app-name-value [nameText]="trans('settings.labels.own')" [isEnabled]="canEdit">
      <app-toggle-button [buttonId]="'suipi_own'" [initialState]="ipcomItem.own_ipcom" (toggled)="ipcomItem.own_ipcom = $event.checked; onInputChanged()"></app-toggle-button>
    </app-name-value>
    <app-dropdown *ngIf="isDesktop" [label]="trans('settings.ipcom.region')" [items]="regions" [(value)]="ipcomItem.region" (valueChange)="onInputChanged()" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('region')">{{ val.getError('region') }}</app-form-error>
    </app-dropdown>
    <app-name-value *ngIf="!isDesktop" [nameText]="trans('settings.ipcom.region')" (clicked)="changeRegion()" [isEnabled]="canEdit">{{regionText}}</app-name-value>
    <div class="suipi_header">{{trans('settings.ipcom.userSettings')}}</div>
    <app-string-input [(value)]="ipcomItem.user_name" [label]="trans('settings.labels.userName')" (valueChange)="onInputChanged()" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('user_name')">{{ val.getError('user_name') }}</app-form-error>
    </app-string-input>
    <app-string-input [(value)]="ipcomItem.password" [label]="trans('settings.labels.password')" (valueChange)="onInputChanged()" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('password')">{{ val.getError('password') }}</app-form-error>
    </app-string-input>
    <div class="suipi_header">{{trans('settings.ipcom.deviceSettings')}}</div>
    <app-string-input [label]="trans('settings.labels.hostForDevices')" [(value)]="ipcomItem.host_for_devices" (valueChange)="onInputChanged()" [disabled]="!canEdit"></app-string-input>
    <app-string-input [label]="trans('settings.labels.portForDevices')" [(value)]="ipcomItem.port_for_devices" (valueChange)="onInputChanged()" [maxLength]="5" [disabled]="!canEdit"></app-string-input>
    <app-string-input type="password" [label]="trans('settings.labels.encryptionForDevices')" [(value)]="ipcomItem.encryption_for_devices" (valueChange)="onInputChanged()" [maxLength]="6" [disabled]="!canEdit"></app-string-input>
    <ng-container *ngIf="companyList && companyList.length > 0">
      <div class="suipi_header">{{trans('settings.ipcom.companySettings')}}</div>
      <app-dropdown *ngIf="isDesktop" [label]="trans('settings.ipcom.companySelect')" [items]="companyList" [(value)]="ipcomItem.company_id" (valueChange)="onInputChanged(); companyChanged()" [disabled]="!canEdit"></app-dropdown>
      <app-name-value *ngIf="!isDesktop" [nameText]="trans('settings.ipcom.companySelect')" (clicked)="changeCompany()" [isEnabled]="canEdit">{{companyText}}</app-name-value>
    </ng-container>
    <app-form-input type="textarea" [label]="trans('settings.labels.description')" [(value)]="ipcomItem.description" (valueChange)="onInputChanged()" rows="4" [disabled]="!canEdit"></app-form-input>
    <div class="suipi_header">{{trans('settings.ipcom.testHeader')}}</div>
    <app-button type="filled-secondary" (click)="testConnection()" rounded="partial" [disabled]="testingIPCom || !canEdit">
      <div class="testIPComButtonWrap">
        <div *ngIf="testingIPCom" class="test_spinner"></div>
        {{trans('settings.buttons.testConnection')}}
      </div>
    </app-button>
  </div>
</ng-template>
<ng-component *ngIf="(isNew || ipcomDataChanged) && isDesktop">
  <ng-template appTeleport="footer">
    <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
  </ng-template>
</ng-component>

<ng-template #receiverSettings>
  <app-ipcom-receivers-data-table [columns]="receiverColumns.value" [showHeaders]="isDesktop" [ipcomId]="ipcomItem.id" (entryDeleted)="onInputChanged()" (beforeEdit)="ipcomDataChanged = false"></app-ipcom-receivers-data-table>
  <ng-container *ngIf="!isDesktop">
      <div class="separator16"></div>
      <app-plus-button *ngIf="us.currentUser.permissions.permissions.ipcom_settings.create" (plusClicked)="addReceiver()"></app-plus-button>
  </ng-container>
</ng-template>
