import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { autoinject, isNumeric } from 'src/shim';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';
import { SelectInstallerPopupComponent } from 'src/app/popups/select-installer-popup/select-installer-popup.component';
import { customPasswordValidatorBuilder, ValidatorBuilder } from 'src/app/ui/validator';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { PermissionRole, ProtegusUserData } from 'src/api/v3/common';
import { UResolvable } from 'src/app/guards/UResolvable';
import { LocatorService } from 'src/app/services/locator.service';
import { DeviceSetupTemplateService } from 'src/app/services/device-setup-template.service';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'app-cp-edit-user',
  templateUrl: './cp-edit-user.component.html',
  styleUrls: ['./cp-edit-user.component.scss'],
})
export class CpEditUserComponent extends LanguageAware implements OnInit, UResolvable<typeof CpEditUserComponent> {
  public readonly canSeeDeviceSetupTemplate = this.us.currentUser.permissions.permissions.dev_setup_templates.view;
  public readonly canEditDeviceSetupTemplate = this.us.currentUser.permissions.permissions.dev_setup_templates.edit;

  private passwordChanged = false;
  private returnToUrl = 'all-users';
  private userBeforeModifications = '';

  public ruleName = '';
  public roleName = '';
  public countryText = '';
  public newPassword = '';
  public newTemplateSelected = 0;
  public selectedInstallerId = -1;
  public deviceSetupTemplateId = 0;
  public installersToPickFrom = [];
  public roleSupportsTemplates = false;
  public userItem: ProtegusUserData = null;
  public templates: { id: number; name: string }[] = [];
  public val = new ValidatorBuilder<{ password: string }>().custom(
    'password',
    customPasswordValidatorBuilder({
      tooShort: () => this.trans('auth.errors.passwordTooShort'),
      noLowerCase: () => this.trans('auth.errors.passwordNoLowercase'),
      noUpperCase: () => this.trans('auth.errors.passwordNoUppercase'),
      noNumber: () => this.trans('auth.errors.passwordNoNumber'),
    })
  ).build();
  public canEdit = this.us.currentUser.permissions?.permissions.users.edit || this.us.currentUser.permissions?.permissions.users.create;

  public static async resolve(injector: Injector, ar: ActivatedRouteSnapshot) {
    const users = injector.get(UserService);
    const templateService = autoinject(LocatorService.injector, DeviceSetupTemplateService);
    const promises: Promise<any>[] = [];
    if ( users.currentUser.permissions.permissions.dev_setup_templates.edit ) {
      promises.push(templateService.loadDropDownList());
    }
    await Promise.all(promises);
  }

  constructor(
    cdRef: ChangeDetectorRef,
    ar: ActivatedRoute,
    private router: Router,
    private pp: PopupService,
    private msg: MessageboxService,
  ) {
    super(cdRef);
    const userId = ar.snapshot.paramMap.get('num');
    const returnTo = ar.snapshot.paramMap.get('from');
    if (returnTo !== null) {
      if (returnTo === 'filter') {
        this.returnToUrl = 'filtered-users';
      }
    }
    let headerT = this.trans('systems.titles.addNewUser');
    if (userId !== null) {
      headerT = this.trans('systems.titles.editUser');
      if (!isNumeric(userId)) {
        this.router.navigate([...this.g.getHomeUrl(), this.returnToUrl]);
        return;
      }
      const userIdInt = parseInt(userId, 10);
      let found = this.us.companyInstallers.find((ci) => ci.id === userIdInt);
      if (found === undefined) {
        found = this.us.simpleUsers.find((si) => si.id === userIdInt);
      }
      if (this.us.currentUser.permissions?.role === PermissionRole.SuperAdmin) {
        if (found === undefined) {
          found = this.us.superAdmins.find((sui) => sui.id === userIdInt);
        }
        if (found === undefined) {
          found = this.us.companies.find((cpi) => cpi.id === userIdInt);
        }
      }
      if (found === undefined) {
        found = this.us.filteredUsers.find((fu) => fu.id === userIdInt);
      }
      if (found === undefined) {
        this.router.navigate([...this.g.getHomeUrl(), this.returnToUrl]);
        return;
      }
      this.canEdit = this.us.currentUser.permissions?.permissions.users.edit;
      this.userItem = found;
      this.userBeforeModifications = JSON.stringify(found);
    } else {
      this.userItem = {
        name: '',
        email: '',
        id: 0,
        soc_account: false,
        phone: '',
        country: 'US',
        active: 1,
        company: '',
        company_id: 0,
        access_permission_id: this.permissionService.getDefaultPermissionFor(PermissionRole.GeneralUser, this.us.currentUser.company_id).id,
        created_at: '',
        dashboard_order: '',
        phone_number: '',
        privacy_policy_accepted: true,
        soc_type: '',
        social_id: '',
        device_setup_templates: [],
      };
    }
    const p = this.permissionService.getPermission(this.userItem.access_permission_id);
    this.roleSupportsTemplates = p?.permissions.dev_setup_templates.execute ?? false;
    this.templates = this.userItem.device_setup_templates;
    const canShowDelete = this.userItem.id !== 0 && this.userItem.id !== this.us.currentUser.id && this.us.currentUser.permissions?.permissions.users.delete;
    this.headerBar.showHeader({
      headerText: headerT,
      backUrl: this.g.getHomeUrl().join('/') + '/' + this.returnToUrl,
      actionButtonText: canShowDelete ? this.trans('general.delete') : undefined,
    });
    this.background.setGray();
    this.headerBar.onBackClicked = () => {
      this.revert();
    };
    if ( this.canEdit ) {
      if (this.userItem.id > 0) {
        this.headerBar.onActionButtonClicked = () => {
          this.deleteUser();
        };
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
      } else {
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('systems.buttons.addUser'), true, false);
      }
      this.footerBar.onButton1Click = () => {
        this.revert();
        this.router.navigate([...this.g.getHomeUrl(), this.returnToUrl]);
      };
      this.footerBar.onButton2Click = () => {
        if (this.miniStatus.isVisible()) {
          this.miniStatus.flash();
          return;
        }
        if ( this.newPassword !== '' ) {
          this.val.validate({ password: this.newPassword }).then(isSuccess => {
            if ( isSuccess ) {
              this.doSave();
              return;
            }

            this.msg.open({
              buttons: this.msg.buttons.Close,
              headerText: this.trans('general.titleError'),
              messageContent: `${this.trans('general.errors.errorOccurred')}: ${this.val.getError('password')}`,
              iconType: this.msg.iconType.Error
            });
          });
        } else {
          this.doSave();
        }
      };
    }

    const foundCountry = this.us.countries.find((c) => c.code === this.userItem.country);
    if (foundCountry !== undefined) {
      this.countryText = foundCountry.name;
    }
    this.generatePermissionNameText();
  }

  ngOnInit(): void {}

  private deleteUser() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    const dataToSend: {
      user_id: number;
      replace?: number;
    } = {
      user_id: this.userItem.id,
    };
    if (this.selectedInstallerId !== -1) {
      dataToSend.replace = this.selectedInstallerId;
    } else if (this.systems.systems.find((s) => s.installerId === this.userItem.id) !== undefined) {
      if (this.installersToPickFrom.length === 0) {
        this.installersToPickFrom = this.us.companyInstallers.filter((ci) => ci.id !== this.userItem.id);
        this.installersToPickFrom.unshift({
          id: 0,
          name: this.trans('users.labels.noneInstaller'),
          email: '',
        });
      }
      this.selectedInstallerId = 0;
      this.pp.openPopup(SelectInstallerPopupComponent, {
        paramSetter: (p) => {
          p.installerList = this.installersToPickFrom;
          p.selectionCancelled.subscribe(() => { this.selectedInstallerId = -1; });
          p.selectionConfirmed.subscribe((i) => { this.installlerSelectionConfirmed(i); });
        },
        attachToClass: 'mobile-app'
      });
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.delete('/cuser', true, dataToSend).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          const role = this.permissionService.getPermission(this.userItem.access_permission_id)?.role ?? PermissionRole.GeneralUser;
          if (role === PermissionRole.Installer) {
            that.us.companyInstallers = that.us.companyInstallers.filter((ci) => ci.id !== that.userItem.id);
          } else if (role === PermissionRole.SuperAdmin) {
            that.us.superAdmins = that.us.superAdmins.filter((su) => su.id !== that.userItem.id);
          } else if (role === PermissionRole.Company) {
            that.us.companies = that.us.companies.filter((cp) => cp.id !== that.userItem.id);
          } else if (role === PermissionRole.GeneralUser) {
            that.us.simpleUsers = that.us.simpleUsers.filter((cp) => cp.id !== that.userItem.id);
          }
          that.router.navigate([...this.g.getHomeUrl(), this.returnToUrl]);
        } else {
          that.toaster.postError(result.error);
          that.revert();
        }
      },
      (error) => {
        that.revert();
      }
    );
  }

  public installlerSelectionConfirmed(selectedInstaller: number) {
    this.selectedInstallerId = selectedInstaller;
    this.deleteUser();
  }

  private doSave() {
    const that = this;
    this.miniStatus.show(this.trans('general.saving'));
    const data: Pick<ProtegusUserData, 'id' | 'name' | 'email' | 'country' | 'active' | 'phone' | 'access_permission_id' | 'device_setup_templates'>
                & { changePassword?: 'on'; newPass?: string; password?: string } = {
      id: this.userItem.id,
      name: this.userItem.name,
      email: this.userItem.email,
      country: this.userItem.country,
      active: this.userItem.active,
      phone: this.userItem.phone,
      access_permission_id: this.userItem.access_permission_id,
      device_setup_templates: this.userItem.device_setup_templates,
    };
    if (this.passwordChanged) {
      data.changePassword = 'on';
      data.newPass = this.newPassword;
    }
    if (this.userItem.id === 0) {
      data.password = this.newPassword;
    }
    this.api.post('/edit/cuser', data, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          if (that.userItem.id === 0) {
            that.userItem.id = result.newId;
            const role = this.permissionService.getPermission(this.userItem.access_permission_id)?.role ?? PermissionRole.GeneralUser;
            if (role === PermissionRole.Installer) {
              that.us.companyInstallers.push(that.userItem);
            } else if (role === PermissionRole.SuperAdmin) {
              that.us.superAdmins.push(that.userItem);
            } else if (role === PermissionRole.Company) {
              that.us.companies.push(that.userItem);
            } else if (role === PermissionRole.GeneralUser) {
              that.us.simpleUsers.push(that.userItem);
            }
          } else {
            that.checkAndMove();
          }
          that.router.navigate([...this.g.getHomeUrl(), this.returnToUrl]);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
      }
    );
  }

  private revert() {
    if (this.userItem.id === 0) {
      return;
    }
    const original = JSON.parse(this.userBeforeModifications);
    this.userItem.email = original.email;
    this.userItem.name = original.name;
    this.userItem.country = original.country;
    this.userItem.phone = original.phone;
    this.userItem.active = original.active;
    this.userItem.access_permission_id = original.access_permission_id;
    this.userItem.device_setup_templates = original.device_setup_templates;
  }

  public changeCountry() {
    const that = this;
    const itemsC: DropdownItem<string>[] = [];
    for (const iCountry of this.us.countries) {
      itemsC.push({
        label: iCountry.name,
        value: iCountry.code,
      });
    }
    this.pp.showSlideout<DropdownItem<string>>(
      {
        headerText: this.trans('users.labels.country'),
        items: itemsC,
        onSubmit: (res) => {
          that.userItem.country = res.value;
          const found = this.us.countries.find((c) => c.code === res.value);
          that.countryText = found.name;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public changeRule() {
    if ( !this.canEdit ) { return; }
    if ( this.permissionService.listBeingLoaded ) { return; }
    this.permissionService.loadDropDownList().then(() => {
      this.permissionService.listStorage.forEach(i => i.default = i.value === this.userItem.access_permission_id);
      const rolesToCheck: PermissionRole[] = [];
      if ( this.us.currentUser.company_id !== 0 ) {
        rolesToCheck.push(PermissionRole.SuperAdmin);
      }
      rolesToCheck.push(PermissionRole.SystemMaster);
      rolesToCheck.push(PermissionRole.SystemUser);
      const rules = this.permissionService.listStorage.filter(i => !rolesToCheck.includes(i.extraParam as PermissionRole));
      this.pp.showSlideout<DropdownItem<number>>(
        {
          headerText: this.trans('users.labels.permissionRule'),
          items: rules,
          onSubmit: (res) => {
            this.userItem.access_permission_id = res.value;
            this.generatePermissionNameText();
          },
        },
        PopupType.SlideoutWithValue
      );
    });
  }

  public changePassword() {
    if ( !this.canEdit ) { return; }
    this.pp.showPopup(
      {
        headerText: this.trans('users.labels.passwordNew'),
        field1: {
          maxLen: 60,
        },
        onSubmitString: (res) => {
          this.newPassword = res;
          if (this.userItem.id !== 0) {
            this.passwordChanged = true;
          }
        },
      },
      PopupType.Password
    );
  }

  public checkAndMove() {
    const original = JSON.parse(this.userBeforeModifications);
    const originalRole = this.permissionService.getPermission(original.access_permission_id)?.role ?? PermissionRole.GeneralUser;
    const modifiedRole = this.permissionService.getPermission(this.userItem.access_permission_id)?.role ?? PermissionRole.GeneralUser;
    if (originalRole === modifiedRole) {
      return;
    }

    if (originalRole === PermissionRole.GeneralUser) {
      this.us.simpleUsers = this.us.simpleUsers.filter((simu) => simu.id !== this.userItem.id);
    } else if (originalRole === PermissionRole.Installer) {
      this.us.companyInstallers = this.us.companyInstallers.filter((ci) => ci.id !== this.userItem.id);
    } else if (originalRole === PermissionRole.Company) {
      this.us.companies = this.us.companies.filter((cu) => cu.id !== this.userItem.id);
    } else if (originalRole === PermissionRole.SuperAdmin) {
      this.us.superAdmins = this.us.superAdmins.filter((su) => su.id !== this.userItem.id);
    }

    if (modifiedRole === PermissionRole.GeneralUser) {
      this.us.simpleUsers.push(this.userItem);
    } else if (modifiedRole === PermissionRole.Installer) {
      this.us.companyInstallers.push(this.userItem);
    } else if (modifiedRole === PermissionRole.Company) {
      this.us.companies.push(this.userItem);
    } else if (modifiedRole === PermissionRole.SuperAdmin) {
      this.us.superAdmins.push(this.userItem);
    }
  }

  private generatePermissionNameText() {
    const rule = this.permissionService.getPermission(this.userItem.access_permission_id);
    if ( !rule ) {
      this.ruleName = this.trans('general.none');
      this.roleName = this.trans('general.none');
    } else {
      this.ruleName = rule.name;
      this.roleName = this.trans('permissions.roles.' + rule.role);
    }
  }

  public onTemplateDeleted(templateId: number) {
    if ( templateId === 0 ) { return; }
    const template = this.templates.find(u => u.id === templateId);
    this.templates.splice(this.templates.indexOf(template), 1);
  }

  public doChangeTemplate(oldTemplateId: number) {
    this.pp.showSlideout<DropdownItem<number>>({
      headerText: this.trans('setup.device.selectTemplate'),
      items: this.deviceSetupTemplateService.listStorage,
      onSubmit: (res) => {
        if ( res.value === 0 ) {
          this.onTemplateDeleted(oldTemplateId);
        } else {
          this.onTemplateChanged(res.value, oldTemplateId);
        }
      }
    }, PopupType.SlideoutWithValue);
  }

  public onTemplateChanged(newTemplateId: number, oldTemplateId: number) {
    if ( newTemplateId === oldTemplateId ) { return; }
    if ( newTemplateId === 0 || this.templates.find(u => u.id === newTemplateId) ) {
      this.onTemplateDeleted(oldTemplateId);
      return;
    }
    const oldTemplate = this.templates.find(u => u.id === oldTemplateId);
    const newTemplate = this.deviceSetupTemplateService.listStorage.find(u => u.value === newTemplateId);
    oldTemplate.id = newTemplate.value;
    oldTemplate.name = newTemplate.label as string;
  }

  public doAddTemplate() {
    this.pp.showSlideout<DropdownItem<number>>({
      headerText: this.trans('setup.device.selectTemplate'),
      items: this.deviceSetupTemplateService.listStorage,
      onSubmit: (res) => {
        if ( res.value !== 0 ) {
          this.onTemplateAdded(res.value);
        }
      }
    }, PopupType.SlideoutWithValue);
  }

  public onTemplateAdded(templateId: number) {
    if ( templateId === 0 ) { return; }
    try {
      if ( this.templates.find(u => u.id === templateId) ) { return; }
      const templateItem = this.deviceSetupTemplateService.listStorage.find(u => u.value === templateId);
      this.templates.push({
        id: templateItem.value,
        name: templateItem.label as string,
      });
    } finally {
      setTimeout(() => {
        this.newTemplateSelected = 0;
      }, 250);
    }
  }
}
