import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { EventFilterCriteria, EventService } from 'src/app/api/system/event.service';
import { ReactionService } from 'src/app/api/system/reaction.service';
import { SystemService } from 'src/app/api/system/system.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEventData } from 'src/app/models/event-data';
import { DateService } from 'src/app/services/date.service';
import { EventDescriptionService } from 'src/app/services/event-description.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { DataTableRowInsertComputer } from '../data-table/data-table.component';
type EventFilterData = EventFilterCriteria & { item?: any };

@Component({
  selector: 'app-events-data-table',
  templateUrl: './events-data-table.component.html',
  styleUrls: ['./events-data-table.component.scss']
})
export class EventsDataTableComponent extends LanguageAware implements OnInit {
  public loader = this.events.getEventsGetter();
  public onChangeObservable = this.events.getOnNewEvent();
  public rootRoute = companyRootRoute;
  public contextMenu = {
    isVisible: false,
    item: null
  };
  @Input() public columns: string[] = ['fake:icon', 'title', 'areaText', 'systemId', 'fake:tag', 'time'];

  @Input() public set criteria(value: EventFilterCriteria) {
    this.loader = this.events.getEventsGetter(value);
    this.onChangeObservable = this.events.getOnNewEvent(value);
  }
  @Output() public modifyColumns = new EventEmitter<void>();
  @Output() public contextCriteria = new EventEmitter<EventFilterData>();

  constructor(
    cdRef: ChangeDetectorRef,
    public reactions: ReactionService,
    private events: EventService,
    private system: SystemService,
    private eventDescription: EventDescriptionService,
    private dateService: DateService,
  ) {
    super(cdRef);
  }

  ngOnInit(): void {}

  public formatDate(ts: number) {
    const d = DateTime.fromSeconds(ts);
    const n = DateTime.now();
    const dateFromSeconds = new Date(ts * 1000);
    if (d.hasSame(n, 'day') && d.hasSame(n, 'month') && d.hasSame(n, 'year')) {
      return this.dateService.formatTime(dateFromSeconds);
    } else {
      return this.dateService.formatDateTime(dateFromSeconds);
    }
  }

  public getSystemName(systemId: number): string {
    return this.system.getSystemName(systemId);
  }

  public IsSameDay(a: TEventData, b?: TEventData): boolean {
    if (!b) { return false; }
    const d1 = DateTime.fromSeconds(a.time);
    const d2 = DateTime.fromSeconds(b.time);
    return d1.hasSame(d2, 'day') && d1.hasSame(d2, 'month') && d1.hasSame(d2, 'year');
  }

  public rowInsertComputer: DataTableRowInsertComputer<TEventData, { date: string }> = (data) =>
  data.map((d, i) => {
    if (i > 0 && this.IsSameDay(d, data[i - 1])) {
      return undefined;
    } else {
      const priDate = DateTime.fromSeconds(d.time);
      const diff = DateTime.now()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .diff(priDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }), 'days');
      if (diff.days === 0) { return undefined; }
      const dateFromSeconds = new Date(d.time * 1000);
      const date = diff.days === 1 ? this.trans('events.list.yesterday') : this.dateService.formatDate(dateFromSeconds);
      return {
        row: i,
        ctx: {
          date,
        },
      };
    }
  })
  .filter((d) => d !== undefined);

  public onRightClick({item, event}) {
    event.preventDefault();
    this.contextMenu.isVisible = true;
    this.contextMenu.item = item;
    this.getEventDescriptionName(item.event_id);
  }

  private async getEventDescriptionName(id: number) {
    const eventDescription = await this.eventDescription.getEventDescription(id);
    this.contextMenu.item.eventDescriptionName = eventDescription.default_name;
  }

  public getReactionName(id: number): string {
    return this.reactions.getReactionName(id);
  }

  public onContextFilter(searchField: string, id?: number) {
    this.contextMenu.isVisible = false;
    let criteria: EventFilterCriteria = {};
    if(searchField === 'reaction') {
      criteria = { reactionId: this.contextMenu.item.reaction };
    } else if (searchField === 'system') {
      criteria = { systemId: this.contextMenu.item.systemId };
    } else if (searchField === 'event_description') {
      criteria = { eventDescId: this.contextMenu.item.event_id };
    } else if (searchField === 'user') {
      criteria = { userId: this.contextMenu.item.user_id };
    } else if (searchField === 'tag') {
      criteria = { tagId: id };
    }
    this.contextCriteria.emit({...criteria, item: this.contextMenu.item});
  }

}
