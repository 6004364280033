import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TSystemData } from 'src/app/models/system-data';
import { AreaService } from 'src/app/services/area.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoggerService } from 'src/app/api/logger.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { SystemListService } from 'src/app/services/system-list.service';
import { SystemsService } from 'src/app/services/systems.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/api/user.service';

@Component({
  selector: 'app-system-list-item',
  templateUrl: './system-list-item.component.html',
  styleUrls: ['./system-list-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('backSlide', [
      state('hide', style({ width: '0%' })),
      state('show', style({ width: '100%' })),
      transition('hide => show', animate('200ms')),
      transition('show => hide', animate('100ms')),
    ]),
    trigger('buttonFade', [
      state('hide', style({ opacity: 0 })),
      state('show', style({ opacity: 1 })),
      transition('hide => show', animate('200ms')),
      transition('show => hide', animate('100ms')),
    ]),
  ],
})
export class SystemListItemComponent implements OnInit, OnDestroy {
  @Input() systemItem: TSystemData = null;
  private currentlyShowingControls = false;
  private listenerForShowingControls = null;
  private listenerForHidingControls = null;
  private tag = 'SystemList';
  public backSlideState = 'hide';
  public buttonFadeState = 'hide';

  constructor(
    public areaService: AreaService,
    public ss: SystemsService,
    private sl: SystemListService,
    private us: UserService,
    private toaster: ToasterService,
    private l: LoggerService,
    private lang: LanguageService,
    private refresher: RefreshService
  ) {
    if (this.systemItem !== null) {
      this.tag += '-' + this.systemItem.id;
    } else {
      this.tag += '-null';
    }
    const that = this;
    this.listenerForHidingControls = this.sl.onHideSystemControls.subscribe((systemId) => {
      that.hideControls(systemId);
    });
    this.listenerForShowingControls = this.sl.onShowSystemControls.subscribe((systemId) => {
      that.showControls(systemId);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.listenerForHidingControls !== null) {
      this.listenerForHidingControls.unsubscribe();
    }
    if (this.listenerForShowingControls !== null) {
      this.listenerForShowingControls.unsubscribe();
    }
    if (this.currentlyShowingControls) {
      this.sl.hideSystemControlsFor(this.systemItem.id);
    }
  }

  public isSystemDisarmed(): boolean {
    if (this.systemItem.areas.length !== 1) {
      return false;
    }

    return this.systemItem.areas[0].status === 1;
  }

  public isSystemAlarmed(): boolean {
    if (this.systemItem.areas.length !== 1) {
      return false;
    }

    return this.systemItem.areas[0].alarmed;
  }

  public supportsAllStates() {
    return this.systemItem.directControl && !this.systemItem.noSleepStay;
  }

  public getStatusIconForSystem() {
    if (this.systemItem.areas.length !== 1) {
      return '';
    }

    return this.areaService.getIconByAreaStatusEx(this.systemItem.areas[0]);
  }

  public shouldShowControls(): boolean {
    return this.currentlyShowingControls && this.systemItem.areas.length === 1;
  }

  public toggleSystemControls() {
    if (this.systemItem.areas.length !== 1) {
      return;
    }
    if (!this.supportsAreas()) {
      return;
    }
    if (this.currentlyShowingControls) {
      this.sl.hideSystemControlsFor(this.systemItem.id);
    } else {
      this.sl.showSystemControlsFor(this.systemItem.id);
    }
  }

  private hideControls(systemId: number) {
    if (systemId !== this.systemItem.id) {
      return;
    }
    const that = this;
    that.buttonFadeState = 'hide';
    setTimeout(() => {
      this.backSlideState = 'hide';
    }, 100);
    setTimeout(() => {
      that.currentlyShowingControls = false;
    }, 200);
  }

  private showControls(systemId: number) {
    if (systemId !== this.systemItem.id) {
      return;
    }
    this.currentlyShowingControls = true;
    const that = this;
    setTimeout(() => {
      that.backSlideState = 'show';
    }, 10);
    setTimeout(() => {
      that.buttonFadeState = 'show';
    }, 210);
  }

  private canControlArea(queueNo: number) {
    if (this.systemItem.supported_commands.indexOf('.11.') !== -1) {
      return false;
    }
    if (this.systemItem.amIMaster) {
      return true;
    }
    const user = this.systemItem.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
    if (user === undefined) {
      return false;
    }
    if (user.areas === '') {
      return false;
    }
    if (queueNo > user.areas.length) {
      return false;
    }
    return user.areas.charAt(queueNo - 1) === '1';
  }

  public setAreaState(newState: number) {
    if (this.systemItem.supported_commands.indexOf('.11.') !== -1) {
      this.toaster.postError(this.lang.get('systems.errors.operationNotAllowed'));
      return;
    }
    const user = this.systemItem.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
    if (user === undefined || user.areas === '') {
      this.toaster.postError(this.lang.get('systems.errors.operationNotAllowed'));
      return;
    }
    const area = this.systemItem.areas[0];
    if (user.areas.charAt(area.queue_no - 1) !== '1') {
      this.toaster.postError(this.lang.get('systems.errors.operationNotAllowed'));
      return;
    }
    if (!this.canControlArea(area.queue_no)) {
      this.toaster.postError(this.lang.get('systems.errors.operationNotAllowed'));
      return;
    }
    if (this.areaService.isBusy()) {
      this.toaster.postError(this.lang.get('systems.errors.busyArea'));
      return;
    }
    this.l.log('Bandoma keisti būseną', this.tag, {
      areaName: area.name,
      newStatus: newState,
    });
    if (newState === area.status) {
      this.l.log('Srities būsena jau tokia, kokios reikia.', this.tag);
      return;
    }
    this.areaService.busyArea = {
      systemId: this.systemItem.id,
      areaId: area.id,
      oldRememberPin: !area.showKeypad,
      newRememberPin: !area.showKeypad,
      showPinKeypad: area.showKeypad,
      oldStatus: area.status,
      newStatus: newState,
      pinToUse: '',
      zonesToBypass: [],
      showZoneBypass: false,
    };
    this.refresher.disableRefresher();
    if (!this.systemItem.directControl || !this.areaService.busyArea.showPinKeypad) {
      this.areaService.busyArea.showPinKeypad = false;
      this.areaService.performStatusChange();
    }
    this.sl.hideSystemControlsFor(this.systemItem.id);
  }

  public supportsAreas(): boolean {
    return this.systemItem.supported_commands.indexOf('.9.') !== -1;
  }
}
