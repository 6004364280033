import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { autoinject } from 'src/shim';
import { LocaleService } from '../api/locale.service';
import { LoggerService } from '../api/logger.service';
import { RequestService } from '../api/request.service';
import { LocatorService } from './locator.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  languages = [];
  selectedLanguage = {
    value: 'en',
    description: 'English',
  };
  private onTranslationsChangeSource = new Subject<void>();
  public onTranslationsChange = this.onTranslationsChangeSource.asObservable();

  private get req() { return autoinject(LocatorService.injector, RequestService); }

  constructor(private l: LoggerService, private locale: LocaleService, private injector: Injector) {}

  /** @param keyword __string__ Raktažodis, kurio vertimą reikia gauti.
   *  @returns Vertimas, jeigu buvo rastas. Kitu atveju raktinis žodis.
   */
  get(keyword): string {
    const translation = localStorage.getItem(keyword);
    if (translation === null) {
      return keyword;
    }
    return translation;
  }

  public hasLanguage(): boolean {
    const lang = localStorage.getItem('lang');
    return lang !== null && lang !== '';
  }

  public saveUserLanguage() {
    // const rs = this.injector.get(ApiRequestService);
    // rs.post('language/set', { language: localStorage.getItem('lang') }, true).subscribe(
    //   data => {
    //   },
    //   error => {
    //     this.l.log('Klaida nustatant kalba ' + error);
    //   }
    // );
  }

  public applyTranslations(translationString: string, translationVersion: string, languageCode: string) {
    const translations = JSON.parse(translationString);
    this.applyTranslationsInternal(translations, translationVersion, languageCode);
  }

  public applyTranslationsInternal(translations: { [key: string]: string }, translationVersion: string, languageCode: string) {
    const keywords = Object.keys(translations);
    for (const iKeyword of keywords) {
      let translation: string = translations[iKeyword];
      translation = translation.replace(/\&apos;/g, '\'');
      localStorage.setItem(iKeyword, translation.replace(/\&quot;/g, '"'));
    }
    localStorage.setItem('lang_version', translationVersion);
    localStorage.setItem('lang', languageCode);
    this.selectedLanguage.value = languageCode;
    this.selectedLanguage.description = this.locale.getLocaleName(languageCode);
    this.locale.locale = languageCode;

    this.l.log('Apdorota raktažodžių: ' + keywords.length, 'Lang');
    this.onTranslationsChangeSource.next();
  }

  public async setLanguage(languageCode: string): Promise<void> {
    const res = await this.req.misc.translations({ language: languageCode }).toPromise();
    if (!res.success) { throw new Error('Failed to get translations'); }
    this.applyTranslations(res.translations, res.version, res.code);
  }
}
