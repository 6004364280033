import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { EventDescriptionService } from 'src/app/services/event-description.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';

@Component({
  selector: 'app-cp-reaction-types',
  templateUrl: './cp-reaction-types.component.html',
  styleUrls: ['./cp-reaction-types.component.scss']
})
export class CpReactionTypesComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public reactionsList = new Set();
  public searchPhrase: string;
  public searchVisible = false;
  private subscriptions: Subscription[] = [];
  private onRefreshReaction = this.us.onRefreshReactionList.subscribe((refresh) => {
    if(refresh) {
      this.loadReactionsList(this.searchPhrase);
    }
  });

  constructor(
    cdRef: ChangeDetectorRef,
    private eventDescription: EventDescriptionService,
    private router: Router,
    private es: EditSystemService,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService,
  ) {
    super(cdRef);
    this.eventDescription.loadDescriptions();
    this.loadReactionsList();
    this.subscriptions.push(this.onRefreshReaction);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
    this.refresher.doSilentRefresh();
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.hideSearch();
  }

  public onSearchPhraseChange() {
    this.loadReactionsList(this.searchPhrase);
  }

  public showSearch(): void {
    this.searchVisible = true;
  }

  public hideSearch(): void {
    if(!this.searchVisible) { return; }
    this.searchVisible = false;
    this.searchPhrase = '';
    const searchInputField = document.getElementById('searchInputField');
    searchInputField.blur();
    this.loadReactionsList();
  }

  public editReaction(id: number) {
    const reaction = this.us.currentUser.reactions.find(r => r.id === id);
    this.es.beginComponentEdit(TEditableComponent.Reaction, reaction.id, reaction);
    this.router.navigate([companyRootRoute, 'settings', 'reaction-types', 'edit', reaction.id]);
  }

  public onDeleteIconClick(reaction): void {
    const found = this.us.currentUser.reactions.find(r => r.id === reaction.id);
    if(!found) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('settings.reactions.settings.deleteReactionInfo').replace(':reaction', reaction.title)}: ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deleteConfirmed(reaction);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private deleteConfirmed(reactionToDelete) {
    if(reactionToDelete) {
      this.us.currentUser.reactions.splice(
        this.us.currentUser.reactions.findIndex((r) => r.id === reactionToDelete.id),
        1
      );
      this.api.post('/delete/reaction', { reaction_id: reactionToDelete.id }, true).subscribe(
        (result) => {
          if (!result.success) {
            this.messagebox.open({
              buttons: this.messagebox.buttons.Ok,
              headerText: this.trans('general.titleError'),
              iconType: this.messagebox.iconType.Error,
              messageContent: result.error
            });
          } else {
            const descToUpdate = this.eventDescription.eventDescriptions.filter((ed) => ed.reaction_id === reactionToDelete.id);
            for (const iDesc of descToUpdate) {
              iDesc.reaction_id = 0;
            }
            this.loadReactionsList(this.searchPhrase);
            const itemInEdit = this.es.getEditableComponent(TEditableComponent.Reaction);
            if(itemInEdit && itemInEdit.id === reactionToDelete.id) {
              this.router.navigate([companyRootRoute, 'settings', 'reaction-types']);
            }
          }
        },
        (error) => {
          this.messagebox.open({
            buttons: this.messagebox.buttons.Ok,
            headerText: this.trans('general.titleError'),
            iconType: this.messagebox.iconType.Error,
            messageContent: this.trans('auth.errors.serverSideError')
          });
          this.es.endComponentEdit(TEditableComponent.Reaction);
        }
      );
    }
  }

  private loadReactionsList(searchPhrase?: string): void {
    if(this.eventDescription.eventDescriptions.length === 0) {
      setTimeout(()=> {
        this.loadReactionsList();
      }, 50);
      return;
    }
    this.reactionsList.clear();
    const reactions = this.us.currentUser.reactions;
    if(searchPhrase && searchPhrase !== '' && reactions.length > 0) {
      reactions.filter(reaction => {
        const eventsList = this.getReactionEventsList(reaction.id) as Set<string>;
        if(reaction.name.toLowerCase().includes(searchPhrase ? searchPhrase.toLowerCase() : '')) {
          this.reactionsList.add({
            id: reaction.id,
            title: reaction.default_name ? this.trans('reactionNames.reactions.' + reaction.name) : reaction.name,
            image: reaction.image,
            image_png: reaction.image_png,
            events: this.setToString(eventsList)});
        }
      });
    } else {
      reactions.map(reaction => {
        const eventsList = this.getReactionEventsList(reaction.id) as Set<string>;
        this.reactionsList.add({
          id: reaction.id,
          title: reaction.default_name ? this.trans('reactionNames.reactions.' + reaction.name) : reaction.name,
          image: reaction.image,
          image_png: reaction.image_png,
          events: this.setToString(eventsList)}
        );
      });
    }
  }

  private getReactionEventsList(id: number) {
    const list = new Set();
    for (const ed of this.eventDescription.eventDescriptions) {
      if (ed.reaction_id === id) {
        list.add(ed.default_name);
      }
      if (list.size > 3) { break; }
    }
    return list;
  }

  private setToString(set: Set<string>) {
    const arr = Array.from(set);
    let str = arr.join(', ');
    if (str.length > 80) {
      str = str.slice(0, 80) + '...';
    }
    return str;
  }

}
