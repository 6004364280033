<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('auth.labels.name') }}</h2>
      <p>{{ trans('auth.labels.namePrompt') }}</p>
    </div>
    <form (submit)="next()">
      <app-string-input [label]="trans('auth.form.nameAuth')" autocomplete="name" type="text" [(value)]="name" [disabled]="isLoading">
        <app-form-error *ngIf="val.hasError('name')">{{ val.getError('name') }}</app-form-error>
      </app-string-input>
      <app-button type="filled-secondary" class="mainButton" [isLoading]="isLoading">{{ trans('general.next') }}</app-button>
    </form>
  </div>
</app-login-layout>
