import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';
import { LanguageService } from 'src/app/services/language.service';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { ForgotPasswordTarget, LoginService } from '../../login.service';

const passwordValidator = new ValidatorBuilder<{
  password: string;
}>().required('password', (_, c: PasswordComponent) => c.trans('auth.errors.requiredPassword'));

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  public isLoading = false;
  public ForgotPasswordTarget = ForgotPasswordTarget;
  public val = passwordValidator.build().bindContext(this);
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(public login: LoginService, private router: Router, public auth: AuthService, private ls: LanguageService, private location: Location) {}

  ngOnInit(): void {
    if (!this.login.email) {
      this.router.navigate(['login'], { replaceUrl: true });
    }
  }

  public async next() {
    this.isLoading = true;
    if (!(await this.val.validate(this.login))) {
      this.isLoading = false;
      return;
    }
    const res = await this.login.login(this.login.email, this.login.password).toPromise();
    if (res.success) {
      this.login.clearLoginState();
      this.router.navigate(this.login.determineLoginDestination(), { skipLocationChange: false });
      return;
    } else if (!res.success) {
      this.val.pushError('password', (res as any).error);
    }
    this.isLoading = false;
  }

  public back() {
    this.location.back();
  }
}
