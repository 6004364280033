import { Subscription } from '@angular-devkit/core/node_modules/rxjs';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Tag } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { CompanyService } from 'src/app/api/company.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { LocatorService } from 'src/app/services/locator.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { autoinject } from 'src/shim';
import { DataTableGetter } from '../data-table/data-table.component';
import { RowData } from '../users-data-table/users-data-table.component';

@Component({
  selector: 'app-tags-data-table',
  templateUrl: './tags-data-table.component.html',
  styleUrls: ['./tags-data-table.component.scss']
})
export class TagsDataTableComponent extends LanguageAware implements OnInit, OnChanges, OnDestroy {
  public loader: DataTableGetter<Tag>;
  private subscriptions: Subscription[] = [];
  @Input() searchLoader: DataTableGetter<Tag>;
  @Input() public columns: string[];
  @Input() public showHeaders = true;
  @Output() public modifyColumns = new EventEmitter<void>();
  private rootRoute = this.g.getHomeUrl();
  private get esService() { return autoinject(LocatorService.injector, EditSystemService); }
  public get companyService() { return autoinject(LocatorService.injector, CompanyService); }

  constructor(
    cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService,
    private router: Router,
  ) {
    super(cdRef, true);
    this.loader = this.searchLoader;
  }

  public onRowClick(tagRow: RowData): void {
    if(tagRow.column === 'fake:delete') {
      this.onDeleteIconClick(tagRow);
      return;
    }

    if (this.platform.isDesktopView() ) {
      this.router.navigate([...this.rootRoute, 'tags', 'edit', tagRow.item.id]);
    } else {
      this.esService.beginComponentEdit(TEditableComponent.Tag, tagRow.item.id, tagRow.item);
      this.router.navigate([...this.rootRoute, 'tags', 'edit']);
    }
  }

  public onDeleteIconClick(event: RowData): void {
    if ( this.us.currentUser.belongsToCompany && !this.us.currentUser.belongsToCompany.active ) {
      return;
    }
    if ( !this.us.currentUser.permissions.permissions.tags.delete ) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
`${this.trans('tags.labels.deleteTag')} <strong>${event.item.name}</strong>. ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deleteTag(event.item.id);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async deleteTag(tagId: number): Promise<void> {
    try {
      await requests.tag.deleteTag({ id: tagId}).toPromise();
      this.tagService.tags.delete(tagId);
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleSuccess'),
        messageContent: this.trans('tags.labels.tagIsDeletedNow'),
        iconType: this.messagebox.iconType.Success
      });
      this.router.navigate([...this.rootRoute, 'tags']);
    } catch (error) {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Close,
        headerText: this.trans('general.titleError'),
        messageContent: `${this.trans('tags.errors.failedToDeleteTag')}: ${error.message}`,
        iconType: this.messagebox.iconType.Error
      });
    }
  }

  ngOnInit(): void {
    this.loader = this.searchLoader;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.loader = this.searchLoader;
    }
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }
}
