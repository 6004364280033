<app-company-page-layout gridTemplateColumns="3fr 2fr">
    <div class="header">
        <h4>{{trans('tags.titles.list')}}</h4>
        <div class="flex-1">
        <div class="search-input-wrapper">
            <input id="searchInputField" autocomplete="off" type="text" name="searchPhrase" [(ngModel)]="filterCriteria.searchPhrase" placeholder="{{trans('general.search')}}" class="search-input" (keyup)="onSearchSubmit($event)" (focus)="showSearch()" />
            <div class="svg-wrapper">
            <div *ngIf="isSearchLoading" class="spinner"></div>
            <div *ngIf="searchVisible && !isSearchLoading" class="close" (click)="hideSearch()"></div>
            </div>
        </div>
        </div>
        <a routerLink="new" *ngIf="canAdd"><app-icon-button label="{{trans('tags.labels.addTag')}}" icon="assets/images/company/plus.svg"></app-icon-button></a>
        <app-company-columns [items]="columnConfig" [(value)]="columns.value" #cols></app-company-columns>
    </div>
    <div class="search-section" *ngIf="searchVisible">
        <ng-container *ngFor="let chip of filterChips">
        <ng-container *ngFor="let activeColumn of columns.value">
            <ng-container *ngIf="chip.name === activeColumn">
            <app-chip
                [label]="chip.label"
                [name]="chip.name"
                [isLoading]="chip.value && isSearchLoading"
                [(value)]="chip.value"
                [isDisabled]="isSearchLoading"
                (click)="onChipPress($event, chip)">
            </app-chip>
            </ng-container>
        </ng-container>
        <app-chip *ngIf="!chip.modifiableColumn"
            [label]="chip.label"
            [name]="chip.name"
            [isLoading]="chip.value && isSearchLoading"
            [(value)]="chip.value"
            [isDisabled]="isSearchLoading"
            (click)="onChipPress($event, chip)">
        </app-chip>
        </ng-container>
    </div>
    <app-tag-list (modifyColumns)="cols.show()" [filterCriteria]="filterCriteria" [loader]="loader"></app-tag-list>
</app-company-page-layout>
