<app-company-page-layout gridTemplateColumns="3fr 2fr">
  <div class="header">
    <h4 class="title">{{trans('systems.menu.reactions')}}</h4>
    <div class="flex-1">
      <div class="search-input-wrapper">
        <input id="searchInputField" autocomplete="off" type="text" name="searchPhrase" [(ngModel)]="searchPhrase" [placeholder]="trans('general.search')" class="search-input" (focus)="showSearch()" (ngModelChange)="onSearchPhraseChange()"/>
        <div class="svg-wrapper">
          <div *ngIf="searchVisible && searchPhrase && searchPhrase !== ''" class="close" (click)="hideSearch()"></div>
        </div>
      </div>
    </div>
    <app-icon-button [routerLink]="['new']" [label]="trans('settings.reactions.labels.addNew')" icon="assets/images/company/plus.svg"></app-icon-button>
  </div>

  <div class="dataTable">
    <ng-container *ngFor="let reaction of reactionsList">
      <div (click)="editReaction(reaction.id)" class="dataItemContainer">
        <ng-container *ngIf="reaction.image_png !== '' || reaction.image !== ''">
          <ng-container *ngIf="reaction.image_png !== '' else showPngImage">
            <img class="icon" [src]="reaction.image_png" />
          </ng-container>
          <ng-template #showPngImage>
            <img class="icon" [src]="reaction.image" />
          </ng-template>
        </ng-container>
        <div class="settingsItem">
          <a class="linkText">{{ reaction.title }}</a>
          <p class="smallText">{{reaction.events}}</p>
        </div>
        <div (click)="onDeleteIconClick(reaction); $event.stopPropagation()" class='removeIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
      </div>
    </ng-container>
  </div>
</app-company-page-layout>
