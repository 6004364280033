import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
  providedIn: 'root',
})
export class HwfwService {
  public minimumFwVersion = '1.12';

  constructor(private g: GlobalService) {}

  public getDeviceName(): string {
    if (this.g.configurableDevice.version !== '') {
      const fwParts = this.g.configurableDevice.version.split('_');
      if (fwParts.length > 0) {
        return this.g.configurableDevice.version.replace('_' + fwParts[fwParts.length - 1], '');
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  public getFw(): string {
    if (this.g.configurableDevice.version !== '') {
      const fwParts = this.g.configurableDevice.version.split('_');
      if (fwParts.length > 0) {
        return fwParts[fwParts.length - 1];
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  public isRevision(checkFor: string, endsWith: boolean = false): boolean {
    if (this.g.configurableDevice.version !== '') {
      const fwParts = this.g.configurableDevice.version.split('_');
      if (fwParts.length > 1) {
        if ( endsWith ) {
          return fwParts[1].endsWith(checkFor);
        } else {
          return fwParts[1].substr(0, checkFor.length) === checkFor;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public getRevision(): string {
    if (this.g.configurableDevice.version !== '') {
      const fwParts = this.g.configurableDevice.version.split('_');
      if (fwParts.length > 1) {
        return fwParts[1];
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  public firmwareIsGreaterThanOrEqual(checkWhat: string): boolean {
    const fwPart = this.getFw();
    const fwParts = fwPart.split('.');
    if (fwParts.length !== 2) {
      return false;
    }
    const majorVersion = parseInt(fwParts[0], 10);
    const minorVersion = parseInt(fwParts[1], 10);
    const checkParts = checkWhat.split('.');
    const majorCheck = parseInt(checkParts[0], 10);
    const minorCheck = parseInt(checkParts[1], 10);
    if (majorCheck > majorVersion) {
      return false;
    }
    if (majorCheck === majorVersion && minorCheck > minorVersion) {
      return false;
    }
    return true;
  }
}
