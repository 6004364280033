import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorResponse } from 'src/api/v3/common';
import { RequestService } from 'src/app/api/request.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { LoginService } from 'src/app/login/login.service';
import { assign, interpret } from 'xstate';
import { loginMachine } from './loginMachine';

@Component({
  templateUrl: './company-login.component.html',
  styleUrls: ['./company-login.component.scss'],
})
export class CompanyLoginComponent extends LanguageAware implements OnInit {
  private static tag = 'CompanyLoginComponent';
  private _email = '';
  public get email() {
    return this._email;
  }
  public set email(value) {
    this._email = value;
    this.state.send({ type: 'EMAIL_CHANGED' });
  }
  private _password = '';
  public get password() {
    return this._password;
  }
  public set password(value) {
    this._password = value;
    this.state.send({ type: 'PASSWORD_CHANGED' });
  }

  public state = interpret(
    loginMachine.withConfig({
      actions: {
        validateEmail: () => {
          this.checkUserEmail();
        },
        login: () => {
          this.login();
        },
        getTranslation: assign({
          message: (context, event) => {
            if (event.type === 'NO_USER') {
              return 'Invalid email';
            } else if (event.type === 'SOCIAL_USER') {
              return 'Social login for companies is not supported.';
            } else if (event.type === 'ERROR') {
              return event.message;
            } else {
              return this.trans('general.unknownError');
            }
          },
        }),
        loggedIn: () => {
          this.router.navigate(['/d/company']);
        },
      },
    })
  ).start();

  constructor(cdRef: ChangeDetectorRef, private req: RequestService, private router: Router, private loginService: LoginService) {
    super(cdRef);
  }

  ngOnInit(): void {}

  public login(): void {
    this.loginService.login(this.email, this.password).subscribe({
      next: (result) => {
        if (result.success) {
          this.state.send({ type: 'SUCCESS' });
        } else if ('error' in result) {
          this.state.send({ type: 'ERROR', message: result.error });
        } else {
          this.state.send({ type: 'ERROR', message: this.trans('general.unknownError') });
        }
      },
      error: (error) => {
        if ('error' in error) {
          this.state.send({ type: 'ERROR', message: error.error });
        } else {
          this.state.send({ type: 'ERROR', message: error.message });
        }
      },
    });
  }

  public checkUserEmail(): void {
    this.req.login.checkEmail(this.email).subscribe({
      next: (result) => {
        this.l.log('Gautas email check rezultatas', CompanyLoginComponent.tag, result);
        if (result.success) {
          if (result.isNew) {
            this.state.send({ type: 'NO_USER' });
          } else if (!result.isNew && (result as { isSoc: boolean }).isSoc) {
            this.state.send({ type: 'SOCIAL_USER' });
          } else {
            this.state.send({ type: 'HAS_USER' });
          }
        } else {
          this.state.send({ type: 'ERROR', message: (result as ErrorResponse).error });
        }
      },
      error: () => {
        this.state.send({ type: 'ERROR', message: this.trans('general.unknownError') });
      },
    });
  }
}
