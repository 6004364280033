<app-icon-button (click)="popup.show()" [label]="trans('general.modifyColumns')" icon="assets/images/company/columns.svg"></app-icon-button>
<app-popup2 #popup [content]="pop" [disableBgClick]="stopClick"></app-popup2>
<ng-template #pop let-hide="hide">
  <div class="popup" [ngClass]="[movingItem !== undefined ? 'moving' : '']" [style.--item-count]="items.length">
    <h5 (click)="!stopClick && hide()">{{trans('general.modifyColumns')}}</h5>
    <div class="itemSeperator"></div>
    <ng-container *ngFor="let itm of items; let i = index">
      <app-form-input
        class="item"
        [ngClass]="[i === movingItem ? 'moving' : '']"
        type="checkbox"
        [value]="getValue(itm.value)"
        (valueChange)="setValue(itm.value, $event)"
        [label]="itm.name"
        ><span class="mover" (mousedown)="beginGrab(itm, $event)" (touchstart)="beginGrab(itm, $event)"></span
      ></app-form-input>
      <div class="itemSeperator"></div>
    </ng-container>
  </div>
</ng-template>
<ng-template appTeleport="body">
  <app-form-input
    class="item ghostItem"
    [ngClass]="movingItem === undefined ? 'hidden' : ''"
    type="checkbox"
    [value]="getValue(items[movingItem]?.value)"
    [label]="items[movingItem]?.name"
    ><span class="mover"></span
  ></app-form-input>
</ng-template>
