<ng-template #default_name let-class="className"><div [ngClass]="class">{{trans('events.settings.labels.nameEvent')}}</div></ng-template>
<ng-template #headerCid let-class="className"><div [ngClass]="class">{{trans('events.settings.labels.cidCode')}}</div></ng-template>
<ng-template #assign let-class="className"><div [ngClass]="class">
  <div class="dataTableHeaderText">
    {{trans('settings.reactions.labels.assign')}}
  </div>
</div></ng-template>
<ng-template #active let-class="className"><div [ngClass]="class">{{trans('general.enabled')}}</div></ng-template>

<div class="header">
  <h4 class="title">{{trans('settings.reactions.labels.assignedEvents')}}</h4>
  <div class="flex">
    <div class="flex-1">
      <div class="search-input-wrapper">
        <input id="searchInputField" autocomplete="off" type="text" name="searchPhrase" [(ngModel)]="searchPhrase" [placeholder]="trans('general.search')" class="search-input" (focus)="showSearch()" (ngModelChange)="onSearchPhraseChange()"/>
        <div class="svg-wrapper">
          <div *ngIf="searchVisible && searchPhrase && searchPhrase !== ''" class="close" (click)="hideSearch()"></div>
        </div>
      </div>
    </div>
    <app-icon-button [label]="trans('events.settings.titleAdd')" icon="assets/images/company/plus.svg" (click)="router.navigate(['d', 'company', 'settings', 'event-descriptions', 'new'])"></app-icon-button>
    <app-company-columns [items]="columnConfig" [(value)]="columns.value" #cols></app-company-columns>
  </div>
</div>

<app-data-table
  *ngIf="us.currentUser?.permissions.permissions.reactions.view"
  [data]="loader"
  [slots]="{
    'header:assign': assign,
    'header:default_name': default_name,
    'header:cid_code': headerCid,
    'header:active': active,
    active: activeContent,
    assign: assignContent,
    default_name: defaultNameContent,
    cid_code: cidCodeContent
  }"
  [columns]="columns.value"
  (modifyColumns)="modifyColumns.emit()"
  (rowClick)="onRowClick($event)"
  [dataSize]="eventDescription.eventDescriptions.length"
>
</app-data-table>

<ng-template #assignContent let-value="value" let-item="item">
  <div class="assignContentSection">
    <app-toggle-button
      [buttonId]="item.id"
      [initialState]="reactionItem && item.reaction_id === reactionItem.id && reactionItem.id !== 0"
      (toggled)="onToggleButtonClick(item)"
    ></app-toggle-button>
  </div>
</ng-template>

<ng-template #defaultNameContent let-value="value" let-item="item">
  <a class="linkText" (click)="openEventDescriptionItem(item)">
    {{value}}
  </a>
</ng-template>

<ng-template #cidCodeContent let-item="item" let-column="column">
  <p class="secondaryText">
    {{ item.qualifier + ' ' + item.event_code }}
  </p>
</ng-template>

<ng-template #activeContent let-value="value" let-column="column" let-item="item">
  <p class="enabledText">
    {{ value === 1 ? trans('general.enabled') : trans('general.disabled') }}
  </p>
</ng-template>
