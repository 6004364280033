<app-login-layout-sofa>
  <div class="main-section">
    <div class="login-box">
      <div class="inline-padding">
        <h4 *ngIf="!forgetMode">{{ lang.get('auth.labels.chooseAccount') }}</h4>
        <h4 *ngIf="forgetMode">{{ lang.get('auth.labels.chooseAccountToRemove') }}</h4>
      </div>
      <app-account-switcher [forgetMode]="forgetMode"></app-account-switcher>

      <div class="inline-padding">
        <a *ngIf="!forgetMode" routerLink="/login/email">
          <app-button type="filled-secondary">{{ lang.get('auth.addAccount') }}</app-button>
        </a>
        <a *ngIf="!forgetMode" href="javascript:void(0)" (click)="forgetMode = !forgetMode">{{ lang.get('auth.forgetAccounts') }}</a>
        <a *ngIf="forgetMode" class="backLink" href="javascript:void(0)" (click)="goBack()">{{ lang.get('general.back') }}</a>
      </div>
    </div>
  </div>
</app-login-layout-sofa>
