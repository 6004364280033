import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PgmService } from 'src/app/api/system/pgm.service';

@Component({
  selector: 'app-system-pgm-list',
  templateUrl: './system-pgm-list.component.html',
  styleUrls: ['./system-pgm-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SystemPgmListComponent extends LanguageAware implements OnInit {
  public currentOutputCount = 0;
  public maxOutputs = 0;
  public isNewSystem = false;

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
    public pgmS: PgmService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    const refType = localStorage.getItem('ref');
    let backUrl = '*';
    if (refType && refType === 'conf') {
      backUrl = '../system-configuration';
    } else if (refType === 'new') {
      backUrl = '../add-system-complete';
      this.isNewSystem = true;
      this.footerBar.showFooter(this.trans('general.skip'), this.trans('general.done'), true, false);
      this.footerBar.onButton1Click = () => {
        this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
      };
      this.footerBar.onButton2Click = () => {
        this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
      };
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.pgmOutputs'),
      backUrl,
      activatedRoute: this.r,
    });
    this.background.setGray();

    if (this.systems.activeSystem.hwType === 'GV17' || this.systems.activeSystem.hwType === 'CWI') {
      this.currentOutputCount = this.systems.activeSystem.pgms.filter((p) => p.io_ability !== 'I' && p.enabled).length;
      this.maxOutputs = this.systems.activeSystem.pgms.filter((p) => p.io_ability !== 'I').length;
    }
  }

  ngOnInit(): void {}

  public onPgmSelect(pgmId: number) {
    this.es.beginComponentEdit(
      TEditableComponent.Output,
      pgmId,
      this.systems.activeSystem.pgms.find((p) => p.id === pgmId)
    );
    this.router.navigate(['../system-pgm'], { relativeTo: this.r });
  }

  public addOuput() {
    this.es.beginComponentEdit(TEditableComponent.Output, 0, {
      id: 0,
      queue_no: 0,
      enabled: false,
      on: false,
      data2: '',
      control_areas: false,
      area_no: 0,
      name: '',
      icon: 0,
      type: 'pulse',
      pulseTime: 3,
      area_id: 0,
      pulse_activated_at: 0,
      io_type: 0,
      io_ability: '',
    });
    this.router.navigate(['../system-pgm'], { relativeTo: this.r });
  }

  public gotoAdvancedSettings() {
    this.router.navigate(['/advanced-settings']);
  }
}
