<div [ngClass]="{ 'sidemenu-grid': true, 'sidemenu-grid-mobile': !headerService.isDesktop, 'sidemenu-grid-desktop': headerService.isDesktop, 'sidemenu-grid-dragging': isDragging }" *ngIf="headerService.sideMenuOpen" [style.transform]="'translateX(' + menuPosition + '%)'">
  <div id="sidemenu-header-row">
    <div [id]="headerService.isDesktop ? 'sidemenu-header-logo-container-desktop' : 'sidemenu-header-logo-container'">
      <ng-container *ngIf="(!us.currentUser || !us.currentUser.logo) && (systems.activeSystem === null || !systems.activeSystem.logo); else CompanyLogo">
        <div
          [ngClass]="[headerService.isDesktop ? 'shl-desktop' : 'shl']"
          [inlineSVG]="'assets/images/logo_emblem_text.svg'"
        ></div>
      </ng-container>
      <ng-template #CompanyLogo>
        <ng-container *ngIf="us.currentUser?.logo; else CompanySystemLogo">
          <div [ngClass]="[headerService.isDesktop ? 'shl-desktop' : 'shl']">
            <img alt="logo" class="sidemenu-header-logo" [src]="us.currentUser.logo" />
          </div>
        </ng-container>
        <ng-template #CompanySystemLogo>
          <div
            *ngIf="[PermissionRole.SystemUser, PermissionRole.SystemMaster, PermissionRole.SuperAdmin].includes(systems.currentUserPermissions?.role) && systems.activeSystem !== null && systems.activeSystem.logo !== ''"
            [ngClass]="[headerService.isDesktop ? 'shl-desktop' : 'shl']"
          >
            <img alt="logo" class="sidemenu-header-logo" [src]="systems.activeSystem.logo" />
          </div>
        </ng-template>
      </ng-template>
      <ng-container *ngIf="!headerService.isDesktop">
        <div class="shr" (click)="(us.currentUser.permissions.permissions.regions.view || us.userHasFewRegions) ? toggleRegionPart() : null">
          <div class="sidemenu-header-region">
            <div class="flex items-center gap">
              <ng-container *ngIf="isInRegions && us.currentUser && (us.currentUser.permissions.permissions.regions.view || us.userHasFewRegions)">
                <div class="forwardSvg" [inlineSVG]="'assets/images/arrow_back.svg'"></div>
              </ng-container>
              <p class="sidemenu-header-region-selected">{{ region.activeRegionName }}</p>
              <ng-container *ngIf="!isInRegions && us.currentUser && (us.currentUser.permissions.permissions.regions.view || us.userHasFewRegions)">
                  <div class="forwardSvg" [inlineSVG]="'assets/images/forward.svg'"></div>
              </ng-container>
            </div>
          </div>
        </div>
        <div id="sm_header_account" *ngIf="isLoggedIn()" (click)="toggleProfilePart()">
          <div class="flex flex-1 items-center">
            <div class="flex-1">
              <p id="sm_header_account_username">{{ us.currentUser?.name ?? trans('users.labels.user') }}</p>
              <p *ngIf="us.currentUser?.permissions.role" id="sm_header_account_role">{{ trans('permissions.roles.' + us.currentUser?.permissions.role) }}</p>
            </div>
            <div class="userIconSvg"></div>
          </div>
          <div id="sm_header_account_arrow">
            <div class="flex items-center">
              <div class="forwardSvgXl" [inlineSVG]="'assets/images/forward.svg'"></div>
            </div>
          </div>
        </div>
      </ng-container>
      <div id="sidemenu-header-separator"></div>
    </div>
  </div>
  <div id="sidemenu-content-row" class="sidemenu-profile-section" *ngIf="isInProfile && isLoggedIn()">
    <app-button-single-line-arrow class="sidemenu-button-item" [icon]="'assets/images/profile.svg'" (click)="profileClick()">
      {{ trans('users.buttons.settings') }}
    </app-button-single-line-arrow>
    <span class="accountsw-Title">{{ trans('users.labels.changeAccount') }}</span>
    <app-account-switcher (selected)="hide()"></app-account-switcher>
    <div class="add-account" (click)="addNewAccount()">
      <div class="plus-icon" [inlineSVG]="'assets/images/plus-circle.svg'"></div>
      {{ trans('users.buttons.addAccount') }}
      <div class="arrow-icon" [inlineSVG]="'assets/images/forward.svg'"></div>
    </div>
    <div id="sidemenu-login-row">
      <div class="button" id="pr-logout" (click)="doLogout()">{{ trans('systems.buttons.logout') }}</div>
    </div>
  </div>
  <div id="sidemenu-content-row" *ngIf="!isInProfile && !isInRegions && isLoggedIn()">
    <ng-container *ngIf="!headerService.isDesktop">
      <div id="sidemenu-system-select" *ngIf="(us.currentUser.permissions?.permissions.systems.create || us.currentUser.permissions?.permissions.system.create) && !isInRegions">
        <div class="sidemenu-system-item" (click)="addSystemClick()">
          <div class="ssi-add-system" [inlineSVG]="'assets/images/plus.svg'"></div>
          <div class="ssi-name">{{ trans('systems.titles.' + (us.currentUser?.permissions.permissions.dev_setup_templates.execute ? 'setupDevice' : 'addSystem')) }}</div>
        </div>
      </div>
      <div *ngIf="us.currentUser?.permissions?.role !== PermissionRole.GeneralUser && systems.activeSystem !== null && !desktopView">
        <div id="sidemenu_exit_system" (click)="goBackToCompany()">
          <div id="smes_text">{{ ls.get('systems.labels.exitSystem') }}</div>
          <div id="smes_icon" [inlineSVG]="'assets/images/exit.svg'"></div>
        </div>
        <div id="smas_container">
          <app-cp-system-list-item
            [showInstaller]="us.currentUser?.permissions?.role !== PermissionRole.Installer"
            [showAddress]="true"
            [showBorder]="false"
            [showArrow]="false"
            [systemItem]="systems.activeSystem"
          ></app-cp-system-list-item>
        </div>
      </div>
      <ng-container *ngIf="!us.currentUser?.permissions?.permissions.systems.view">
        <div *ngIf="systems.activeSystem !== null" class="sidemenu-system-item" (click)="toggleSystemListView()">
          <div
            *ngIf="systems.activeSystem.theme.fullBackground === ''"
            class="ssi-icon"
            [ngStyle]="{ background: 'linear-gradient(180deg, ' + systems.activeSystem.theme.startColor + ' 0%, ' + systems.activeSystem.theme.endColor + ' 100%)' }"
          >
            <div
              *ngIf="systems.activeSystem.areas.length === 1 && systems.activeSystemHelper.supports.areas()"
              [ngClass]="{ 'ssi-subicon': true, 'ssi-subicon-disarmed': isSystemDisarmed(systems.activeSystem), 'ssi-subicon-alarmed': isSystemAlarmed(systems.activeSystem) }"
            >
              <div [inlineSVG]="getStatusIconForSystem(systems.activeSystem)"></div>
            </div>
          </div>
          <div *ngIf="systems.activeSystem.theme.fullBackground !== ''" class="ssi-icon" [ngStyle]="{ background: systems.activeSystem.theme.fullBackground }">
            <div
              *ngIf="systems.activeSystem.areas.length === 1 && systems.activeSystemHelper.supports.areas()"
              [ngClass]="{ 'ssi-subicon': true, 'ssi-subicon-disarmed': isSystemDisarmed(systems.activeSystem), 'ssi-subicon-alarmed': isSystemAlarmed(systems.activeSystem) }"
            >
              <div [inlineSVG]="getStatusIconForSystem(systems.activeSystem)"></div>
            </div>
          </div>
          <div class="ssi-name">
            <div>{{ systems.activeSystem.name }}</div>
            <div [ngClass]="{ 'ssi-arrow': true, 'ssi-arrow-toggled': systemListShowing }" [inlineSVG]="'assets/images/arrow-down.svg'"></div>
          </div>
        </div>
        <div *ngIf="systemListShowing && systems.hasSystems()" id="sidemenu-system-select">
          <app-system-list-item *ngFor="let iSystem of systems.systems" [systemItem]="iSystem" (click)="systemClicked(iSystem.id)"></app-system-list-item>
        </div>
      </ng-container>
    </ng-container>
    <div *ngIf="us.canViewAnyOfGeneralPermissions() && systems.activeSystem === null" class="sidemenu-buttons-container">
      <app-button-single-line-arrow
        *ngIf="us.currentUser.permissions?.permissions.events.view"
        class="sidemenu-button-item"
        [icon]="'assets/images/company/CompanyEventsButton.svg'"
        (click)="allEventsClicked()"
      >{{ trans('systems.menu.events') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        *ngIf="us.currentUser.permissions?.permissions.systems.view"
        class="sidemenu-button-item"
        [icon]="'assets/images/company/CompanySystemsButton.svg'"
        (click)="allSystemsClick()"
      >{{ trans('systems.titles.systems') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        *ngIf="us.currentUser.permissions?.permissions.users.view"
        class="sidemenu-button-item"
        [icon]="'assets/images/company/CompanyUsersButton.svg'"
        (click)="allUsersClicked()"
      >{{ trans('systems.menu.users') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        *ngIf="us.currentUser.permissions?.permissions.company_settings.view && us.currentUser.company_id === 0"
        class="sidemenu-button-item"
        [icon]="'assets/images/all_companies.svg'"
        (click)="allCompaniesClicked()"
      >{{ trans('companies.titles.list') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        *ngIf="us.currentUser.permissions?.permissions.roles.view"
        class="sidemenu-button-item"
        [icon]="'assets/images/company/userRoles.svg'"
        (click)="allRolesClicked()"
      >{{ trans('permissions.titles.list') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        *ngIf="us.currentUser.permissions?.permissions.tags.view"
        class="sidemenu-button-item"
        [icon]="'assets/images/company/CompanyTagsButton.svg'"
        (click)="allTagsClicked()"
      >{{ trans('tags.titles.list') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        *ngIf="us.currentUser.permissions?.permissions.company_settings.view && us.currentUser.company_id === 0"
        class="sidemenu-button-item"
        [icon]="'assets/images/company/CompanySettingsButton.svg'"
        (click)="allSettingsClicked()"
      >{{ trans('systems.menu.settings') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        *ngIf="us.currentUser.permissions?.permissions.company_settings.view && us.currentUser.company_id !== 0"
        class="sidemenu-button-item"
        [icon]="'assets/images/company/CompanySettingsButton.svg'"
        (click)="companySettingsClicked()"
      >{{ trans('systems.menu.settings') }}</app-button-single-line-arrow>
    </div>
    <div *ngIf="systems.hasSystems() && systems.activeSystem !== null" class="sidemenu-buttons-container">
      <app-button-single-line-arrow class="sidemenu-button-item" *ngIf="systems.activeSystemHelper.can.see.events()" [icon]="'assets/images/events.svg'" (click)="eventsClick()">
        {{ trans('events.titles.eventList') }}
      </app-button-single-line-arrow>
      <app-button-single-line-arrow class="sidemenu-button-item" *ngIf="systems.activeSystemHelper.can.see.users()" [icon]="'assets/images/users.svg'" (click)="usersClick()">
        {{ trans('systems.titles.users') }}
      </app-button-single-line-arrow>
      <app-button-single-line-arrow
        class="sidemenu-button-item"
        *ngIf="systems.activeSystemHelper.can.see.outputs() && systems.activeSystemHelper.hasEnabledOutputs()"
        [icon]="'assets/images/control.svg'"
        (click)="controlClick()"
      >{{ trans('systems.menu.outputs') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        class="sidemenu-button-item"
        *ngIf="systems.activeSystemHelper.can.see.sensors()"
        [icon]="'assets/images/sensors.svg'"
        (click)="sensorsClick()"
      >{{ trans('systems.titles.sensors') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        class="sidemenu-button-item"
        [icon]="'assets/images/settings.svg'"
        (click)="systemSettingsClick()"
      >{{ trans('systems.menu.settings') }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        class="sidemenu-button-item"
        *ngIf="systems.activeSystemHelper.can.see.cameras()"
        [icon]="'assets/images/camera2.svg'"
        (click)="systemCamerasClick()"
      >{{ trans('systems.menu.myCameras') }}</app-button-single-line-arrow>
    </div>
    <div *ngIf="systems.currentUserPermissions?.role === PermissionRole.Company && systems.activeSystem === null && systems.systemBookmarks.length > 0">
      <div id="sm_quick_systems_h">{{ trans('systems.labels.quickAccess') }}</div>
      <div id="sm_quick_systems">
        <app-cp-system-list-item
          *ngFor="let iBookmark of systems.systemBookmarks; let i = index"
          [systemItem]="systems.getSystem(iBookmark)"
          [showBorder]="systems.systemBookmarks.length - 1 !== i"
          [showBookmark]="false"
        ></app-cp-system-list-item>
      </div>
    </div>
  </div>
  <div id="sidemenu-content-row" *ngIf="isInRegions && isLoggedIn()">
    <div *ngIf="regionsEnabled && us.currentUser && (us.currentUser.permissions.permissions.regions.view || us.userHasFewRegions)">
      <div id="smrg_container">
        <ul class="region-list">
          <li *ngFor="let iRegion of us.userRegions" (click)="onRegionSelect(iRegion); isInRegions = false" [ngClass]="['region-text', iRegion.api_host_to_use === region.ActiveRegion.backEndHost ? 'active' : '']">
            <div class="flex">
              {{ iRegion.name }}
              <div *ngIf="iRegion.api_host_to_use === region.ActiveRegion.backEndHost" class="simpleCheckSvg" [inlineSVG]="'assets/images/check-simple.svg'"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div id="sidemenu-content-row" class="loggedout-row" *ngIf="!isLoggedIn()">
    <span class="accountsw-Title">{{ trans('users.labels.changeAccount') }}</span>
    <div class="account-switcher">
      <app-account-switcher *ngIf="hasAccounts" (selected)="hide()"></app-account-switcher>
    </div>
  </div>
  <div id="sidemenu-login-row" *ngIf="!isLoggedIn()">
    <div class="button" (click)="loginClick()">{{ trans('auth.buttons.logIn') }}</div>
  </div>
  <div id="sidemenu-footer-row">
    <div id="sidemenu-footer-container">
      <ng-container *ngIf="!desktopView">
        <div id="sidemenu-footer-separator" *ngIf="showHelp() || showVersion()"></div>
        <div class="sidemenu-help" *ngIf="showHelp()" (click)="helpClick()">
          <div class="help-icon" [inlineSVG]="'assets/images/help.svg'"></div>
          <div class="help-text">{{ trans('general.help') }}</div>
        </div>
        <div class="sidemenu-app-settings" *ngIf="(pl.isAndroid() || pl.isApple()) && isLoggedIn()" (click)="settingsClick()">
          <div class="settings-icon" [inlineSVG]="'assets/images/phone_settings.svg'"></div>
          <div class="settings-text">{{ trans('general.buttons.appSettings') }}</div>
        </div>
        <div class="sidemenu-version" *ngIf="showVersion()" (click)="openVersion($event)">
          <div class="version-text">v{{ g.getAppVersion() }}</div>
          <div class="version-new" *ngIf="newVersionAvailable"><app-dot></app-dot></div>
        </div>
      </ng-container>
    </div>
  </div>
  <div id="sidemenu-faded-part" [style.opacity]="(menuPosition + 50) / 50" (click)="hide()"></div>
</div>
<app-popup></app-popup>
