import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { GoogleService } from 'src/app/services/google.service';
import { AppleService } from 'src/app/services/apple.service';
import { AppSettings } from 'src/app-settings';
import { WebSocketService } from 'src/app/services/websocket.service';
import { BackBehaviourService } from 'src/app/services/back-behaviour.service';
import { FcmService } from 'src/app/services/fcm.service';
import { PermissionRole } from 'src/api/v3/common';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmailComponent extends LanguageAware implements OnInit {
  public controlsEnabled = true;
  public email = '';

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private fcm: FcmService,
    private google: GoogleService,
    private apple: AppleService,
    private ws: WebSocketService,
    private bh: BackBehaviourService,
  ) {
    super(cdRef);
    this.tag = 'Email';
    this.progressBar.showProgress();
    this.headerBar.showHeader({
      backUrl: '/login',
      showLogo: true,
    });
    this.headerBar.onBackClicked = () => {
      if (localStorage.getItem('ref') === 'addAccount') {
        const switcherAccount = this.api.getLastAccount();
        if (switcherAccount) {
          this.api.switchAccount(switcherAccount);
          this.doRefresh();
          this.systems.loadSystems();
          this.controlsEnabled = true;
          this.miniStatus.hide();
          this.bh.overrideBackBehaviour = true;
          this.bh.backUrl = this.g.getHomeUrl().join('/');
        }
      }
    };
    this.footerBar.showFooter(this.trans('auth.buttons.next'), '', true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.emailSubmit();
    };
    if (this.us.currentUser !== null && this.us.currentUser.email !== '') {
      this.email = this.us.currentUser.email;
    }
  }

  ngOnInit() {
    this.progressBar.setProgressData(1, 4);
  }

  public emailSubmit() {
    if (!this.controlsEnabled) {
      return;
    }
    this.log('email submit ', this.email);
    this.miniStatus.show(this.trans('general.checking'));
    this.controlsEnabled = false;
    this.email = this.email.trim();
    if (this.email === null || this.email === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('auth.form.email')));
      this.controlsEnabled = true;
      this.miniStatus.hide();
      return;
    }
    this.email = this.email.toLowerCase();
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.email)) {
      this.toaster.postError(this.trans('validation.emailInvalid'));
      this.controlsEnabled = true;
      this.miniStatus.hide();
      return;
    }
    const switcherAccount = this.api.getAccountByEmail(this.email);
    if (switcherAccount) {
      this.api.switchAccount(switcherAccount);
      this.doRefresh();
      this.systems.loadSystems();
      this.controlsEnabled = true;
      this.miniStatus.hide();
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    const that = this;
    this.api.post('/check-email', { email: this.email }, false).subscribe(
      (data) => {
        that.log('', data);
        that.controlsEnabled = true;
        that.miniStatus.hide();
        if (data.success) {
          that.us.currentUser.email = that.email;
          if (data.isNew && AppSettings.canAutoCreateAccounts) {
            that.miniStatus.show(this.trans('auth.activation.creating'));
            that.api
              .post(
                '/register',
                {
                  email: that.email,
                  lang: that.ls.selectedLanguage.value,
                },
                false
              )
              .subscribe(
                (response) => {
                  that.miniStatus.hide();
                  if (response.success) {
                    that.us.setCurrentUser(response);
                    this.us.change();
                    this.us.saveCurrentUser();
                    if (this.us.currentUser.permissions?.role === PermissionRole.SuperAdmin) {
                      that.router.navigate(['/password-register']);
                    } else {
                      that.router.navigate(['/activate-email']);
                    }
                  } else {
                    that.toaster.postError(response.error);
                  }
                },
                (error) => {
                  that.miniStatus.hide();
                  that.toaster.postError(this.trans('auth.errors.serverSideError'));
                }
              );
          } else if (data.isNew && !AppSettings.canAutoCreateAccounts) {
            that.toaster.postError(that.trans('users.errors.doesNotExist'));
          } else if (data.isSoc && !data.isPasswordSet) {
            that.us.currentUser.email = '';
            that.toaster.postError(that.trans('auth.errors.mustUseGoogle'));
          } else if (!data.isActive) {
            that.router.navigate(['/activate-email']);
          } else if (!data.isPasswordSet) {
            that.router.navigate(['/no-password']);
          } else {
            that.router.navigate(['/password-login']);
          }
        } else {
          that.toaster.postError(data.error);
        }
      },
      (error) => {
        that.controlsEnabled = true;
        that.log('', error);
        that.toaster.postError(that.trans('auth.failed'));
        that.miniStatus.hide();
      }
    );
  }

  public googleClick() {
    if (!this.controlsEnabled) {
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    this.google.performLogin(
      (data) => {
        that.handleLogin(data, 'google');
      },
      (error) => {
        that.handleLoginFailed(error);
      }
    );
  }

  handleLogin(data, loginType: string) {
    this.controlsEnabled = true;
    this.api.setToken(data.token);
    this.us.setCurrentUser(data);
    this.us.change();
    this.us.saveCurrentUser();
    this.us.setLoginType(loginType);
    if (data.lastSystem !== undefined) {
      this.systems.setCurrentSystemFromRaw(data.lastSystem);
    }
    this.systems.loadSystems();
    this.router.navigate(this.g.getHomeUrl());
    this.fcm.requestForToken();
  }

  handleLoginFailed(error?: string) {
    this.controlsEnabled = true;
    if (error !== undefined) {
      this.toaster.postError(error);
    }
  }

  public appleClick() {
    if (!this.controlsEnabled) {
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    this.apple.performLogin(
      (data) => {
        that.handleLogin(data, 'apple');
      },
      (error) => {
        that.handleLoginFailed(error);
      }
    );
  }

  public canShowGoogle(): boolean {
    return AppSettings.googleLoginSupported;
  }

  public canShowApple(): boolean {
    return AppSettings.appleLoginSupported && this.platform.isApple() && this.platform.hasAppleMessageHandlers();
  }

  public doRefresh() {
    this.l.log('Refresh', 'EmailComponent');
    const that = this;
    this.api.get('/me', true, { systemId: this.systems.activeSystem === null ? 0 : this.systems.activeSystem.id }).subscribe(
      (result) => {
        if (result.success) {
          if (!that.api.hasToken()) {
            // Panašu, kad kol siuntė duomenis, vartotojas padarė logout.
            return;
          }
          that.us.setCurrentUser(result);
          this.us.change();
          this.us.saveCurrentUser();
          that.api.setToken(result.token);
          that.ws.websockInit();
          if (result.lastSystem !== undefined) {
            that.systems.setCurrentSystemFromRaw(result.lastSystem);
          }
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        this.l.log('Nepavyko atnaujinti vartotojo duomenu', 'EmailComponent');
      }
    );
  }
}
