<div class="popup_menu_button" id="popup_menu_button_{{id}}" (click)="showMenu()">
    <ng-content select="[slot=content]"></ng-content>
</div>
<div id="popup_menu_{{id}}">
    <div class="popup_menu_overlay" id="popup_menu_overlay_{{id}}" (click)="hideMenu(); $event.stopPropagation()">
        <div class="popup_menu_popup" id="popup_menu_popup_{{id}}">
            <div [ngClass]="{ 'popup_menu_tooltip': true, 'popup_menu_tooltip_visible': shouldFadeIn }">
                <div class="popup_menu_tooltip_bottom" id="popup_menu_tooltip_bottom_{{id}}">
                    <ng-content select="[slot=menu]"></ng-content>
                </div>
            </div>
        </div>
    </div>
</div>