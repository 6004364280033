import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import requests from 'src/api/v3/requests';
import { AuthService } from '../api/auth.service';
import { UserService } from '../api/user.service';
import { SystemsService } from './systems.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  private onRefreshSource = new Subject<any>();
  /** Vykdoma, kai vartotojas paleidžia pirštą, kai jau ištempė refresherį iš viršaus. NESUSIJĘ su silent refresh. */
  public onRefresh = this.onRefreshSource.asObservable();
  public refresherIsEnabled = false;
  private silentRefreshInProgress = false;
  private onSilentRefreshCompleteSource = new Subject<any>();
  public onSilentRefreshComplete = this.onSilentRefreshCompleteSource.asObservable();

  constructor(private auth: AuthService, private systems: SystemsService, private us: UserService) {}

  public doRefresh() {
    this.onRefreshSource.next();
  }

  public enableRefresher() {
    this.refresherIsEnabled = true;
  }

  public disableRefresher() {
    this.refresherIsEnabled = false;
  }

  public doSilentRefresh(refreshCompleteCallback?: any, failureCallback?: any) {
    if (!this.auth.hasToken()) {
      return;
    }
    this.silentRefreshInProgress = true;
    requests.user.me(this.systems.activeSystem === null ? 0 : this.systems.activeSystem.id).subscribe(
      (result) => {
        if (result.success) {
          if (!this.auth.hasToken()) {
            // Panašu, kad kol siuntė duomenis, vartotojas padarė logout.
            this.silentRefreshInProgress = false;
            return;
          }
          this.us.setCurrentUser(result);
          this.auth.setToken(result.token);
          this.us.change();
          this.us.saveCurrentUser();
          if (result.lastSystem !== undefined) {
            this.systems.setCurrentSystemFromRaw(result.lastSystem);
          }
          if (refreshCompleteCallback !== undefined) {
            refreshCompleteCallback();
          }
          this.silentRefreshInProgress = false;
          this.onSilentRefreshCompleteSource.next();
        }
      },
      () => {
        if (failureCallback !== undefined) {
          failureCallback();
        }
        this.silentRefreshInProgress = false;
        this.onSilentRefreshCompleteSource.next();
      }
    );
  }

  public isRefreshingSilently(): boolean {
    return this.silentRefreshInProgress;
  }
}
