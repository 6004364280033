import { Component, Input, OnInit } from '@angular/core';
import { FilteringService } from 'src/app/services/filtering.service';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'app-section-with-filter',
  templateUrl: './section-with-filter.component.html',
  styleUrls: ['./section-with-filter.component.scss'],
})
export class SectionWithFilterComponent implements OnInit {
  @Input() sectionText = '';
  @Input() sortableFields = [];
  @Input() sectionName = '';
  @Input() dataSource = [];
  @Input() filterableFields = [];
  public sortingMenuOpen = false;
  public filteringActive = false;

  constructor(public headerService: HeaderService, public fs: FilteringService) {}

  ngOnInit(): void {}

  public openSortingMenu() {
    this.sortingMenuOpen = true;
  }

  public sortingOrderSelected(ascending: boolean, field: string) {
    console.log('Sorting by ' + field, ascending);
    this.dataSource.sort((i1, i2) => (ascending ? (i1[field] as string).localeCompare(i2[field]) : (i2[field] as string).localeCompare(i1[field])));
  }

  public openFilter() {
    this.filteringActive = true;
  }
}
