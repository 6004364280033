import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BasicSuccessResponse } from 'src/api/v3/common';
import { DeviceSetupTemplate } from 'src/api/v3/device-setup-templates';
import requests from 'src/api/v3/requests';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { DataTableGetter } from '../data-table/data-table.component';

@Component({
  selector: 'app-dev-templates-data-table',
  templateUrl: './dev-templates-data-table.component.html',
  styleUrls: ['./dev-templates-data-table.component.scss']
})
export class DevTemplatesDataTableComponent extends LanguageAware implements OnInit, OnDestroy {
  @Input() public columns: string[];
  @Output() public modifyColumns = new EventEmitter<void>();
  public loader: DataTableGetter<DeviceSetupTemplate>;
  private subscriptions: Subscription[] = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private edit: EditSystemService,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService
  ) {
    super(cdRef);
    this.loader = this.deviceSetupTemplateService.getDeviceSetupTemplateGetter();
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public onRowClick(clickedCell) {
    if (clickedCell.column !== 'fake:modify') {
      if ( !this.platform.isDesktopView() ) {
        this.edit.beginComponentEdit(TEditableComponent.DeviceSetupTemplate, clickedCell.item.id, clickedCell.item);
      }
      this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'device-setup-templates', 'edit', clickedCell.item.id]);
    }
  }

  public onDeleteClick(item: DeviceSetupTemplate): void {
    if(!this.us.currentUser.permissions?.permissions.dev_setup_templates.delete) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('setup.device.deleteTemplateInfo').replace(':template', item.name)}: ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.delete(item.id);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private delete(id: number) {
    requests.deviceSetupTemplates.deleteTemplate({ id }).subscribe(
      (res: BasicSuccessResponse) => {
        if(res.success) {
          this.showMessagebox(true, this.trans('setup.device.templateDeleted'));
          const found = this.deviceSetupTemplateService.deviceSetupTemplates.find(t => t.id === id);
          if ( found ) {
            this.deviceSetupTemplateService.deviceSetupTemplates.splice(this.deviceSetupTemplateService.deviceSetupTemplates.indexOf(found), 1);
          }
          const itemInEdit = this.edit.getEditableComponent(TEditableComponent.DeviceSetupTemplate);
          if(itemInEdit && itemInEdit.id === id) {
            this.edit.endComponentEdit(TEditableComponent.DeviceSetupTemplate);
            this.router.navigate([...this.g.resolveRootRoute(), 'settings', 'device-setup-templates']);
          }
        }
      }
    );
  }

  public async createTemplateCopy(item: DeviceSetupTemplate) {
    if(!this.us.currentUser.permissions?.permissions.dev_setup_templates.create) { return; }
    const copy: DeviceSetupTemplate = JSON.parse(JSON.stringify(item));
    copy.name = `${item.name} - ${this.trans('general.copy')}`;
    const result = await requests.deviceSetupTemplates.addTemplate({...copy}).toPromise();
    if ( result.success ) {
      copy.id = result.id;
      this.deviceSetupTemplateService.deviceSetupTemplates.push(copy);
    }
  }

  private showMessagebox(success: boolean, message: string): void {
    this.messagebox.open({
      buttons: this.messagebox.buttons.Close,
      headerText: success ? this.trans('general.titleSuccess') : this.trans('general.titleError'),
      messageContent: message,
      iconType: success ? this.messagebox.iconType.Success : this.messagebox.iconType.Error
    });
  }

}
