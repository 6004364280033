<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('companies.titles.registrationTitle') }}</h2>
      <p>{{ trans('companies.labels.registration.description') }}</p>
    </div>
    <app-stepper [steps]="[trans('companies.labels.registration.step1'), trans('companies.labels.registration.step2')]" [templates]="[step1, step2]" #stepper [(activeStep)]="step"></app-stepper>
  </div>
</app-login-layout>

<ng-template #step1>
  <app-string-input
    [label]="trans('companies.labels.owner.firstName')"
    type="text"
    name="firstName"
    autocomplete="given-name"
    [(value)]="data.firstName"
    [hasError]="val.hasError('firstName')"
  >
    <app-form-error *ngIf="val.hasError('firstName')">{{ val.getError('firstName') }}</app-form-error>
  </app-string-input>
  <app-string-input
    [label]="trans('companies.labels.owner.lastName')"
    type="text"
    name="lastName"
    autocomplete="given-name"
    [(value)]="data.lastName"
    [hasError]="val.hasError('lastName')"
  >
    <app-form-error *ngIf="val.hasError('lastName')">{{ val.getError('lastName') }}</app-form-error>
  </app-string-input>
  <app-string-input [label]="trans('companies.labels.owner.email')" type="email" name="email" autocomplete="email" [(value)]="data.email" [hasError]="val.hasError('email')">
    <app-form-error *ngIf="val.hasError('email')">{{ val.getError('email') }}</app-form-error>
  </app-string-input>
  <app-string-input [label]="trans('companies.labels.owner.phone')" type="text" name="phone" autocomplete="tel" [(value)]="data.phone" [hasError]="val.hasError('phone')">
    <app-form-error *ngIf="val.hasError('phone')">{{ val.getError('phone') }}</app-form-error>
  </app-string-input>
  <div class="buttonContainer">
    <app-button type="filled-secondary" (click)="onNext()">{{ trans('general.next') }}</app-button>
  </div>
</ng-template>

<ng-template #step2>
  <app-string-input
    [label]="trans('companies.labels.companyName')"
    type="text"
    name="companyName"
    autocomplete="organization"
    [(value)]="data.companyName"
    [hasError]="val.hasError('companyName')"
  >
    <app-form-error *ngIf="val.hasError('companyName')">{{ val.getError('companyName') }}</app-form-error>
  </app-string-input>
  <app-string-input
    [label]="trans('companies.labels.companyEmail')"
    type="email"
    name="companyEmail"
    [(value)]="data.companyEmail"
    [hasError]="val.hasError('companyEmail')"
  >
    <app-form-error *ngIf="val.hasError('companyEmail')">{{ val.getError('companyEmail') }}</app-form-error>
  </app-string-input>
  <app-dropdown [label]="trans('companies.labels.companyCountry')" [items]="countryList" [(value)]="data.companyCountry" [hasError]="val.hasError('companyCountry')">
    <app-form-error *ngIf="val.hasError('companyCountry')">{{ val.getError('companyCountry') }}</app-form-error>
  </app-dropdown>
  <app-string-input
    [label]="trans('companies.labels.companyAddress')"
    type="text"
    name="companyAddress"
    autocomplete="address-line1"
    [(value)]="data.companyAddress"
    [hasError]="val.hasError('companyAddress')"
  >
    <app-form-error *ngIf="val.hasError('companyAddress')">{{ val.getError('companyAddress') }}</app-form-error>
  </app-string-input>
  <app-string-input
    [label]="trans('companies.labels.companyCity')"
    type="text"
    name="companyCity"
    autocomplete="address-level2"
    [(value)]="data.companyCity"
    [hasError]="val.hasError('companyCity')"
  >
    <app-form-error *ngIf="val.hasError('companyCity')">{{ val.getError('companyCity') }}</app-form-error>
  </app-string-input>
  <app-string-input [label]="trans('companies.labels.companyPhone')" type="text" name="companyPhone" [(value)]="data.companyPhone" [hasError]="val.hasError('phone')">
    <app-form-error *ngIf="val.hasError('phone')">{{ val.getError('phone') }}</app-form-error>
  </app-string-input>
  <app-string-input [label]="trans('companies.labels.companyVat')" type="text" name="companyVatNumber" [(value)]="data.companyVat" [hasError]="val.hasError('companyVat')">
    <app-form-error *ngIf="val.hasError('companyVat')">{{ val.getError('companyVat') }}</app-form-error>
  </app-string-input>
  <div class="buttonContainer">
    <app-button type="filled-gray" (click)="stepper.prevStep()" [disabled]="submitting">{{ trans('general.back') }}</app-button>
    <app-button type="filled-secondary" (click)="submit()" [isLoading]="submitting">{{ trans('general.submit') }}</app-button>
  </div>
</ng-template>
