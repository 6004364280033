import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { TDeviceUser } from 'src/app/models/device-user';
import { THomeTheme } from 'src/app/models/home-theme';
import { DeviceService } from 'src/app/services/device.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { TZoneData } from 'src/app/models/zone-data';
import { directOnlyDevices, dualModeDevices, gFamily, indirectOnlyDevices, spFamily } from 'src/app/services/global.service';

@Component({
  selector: 'app-add-system-details',
  templateUrl: './add-system-details.component.html',
  styleUrls: ['./add-system-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSystemDetailsComponent extends LanguageAware implements OnInit, AfterViewInit {
  private TRANSFER_CHECK_COUNT = 10;
  private TRANSFER_CHECK_INTERVAL = 5000;
  private readonly panicButtonsAreaStatus = 18;
  public systemName = '';
  public systemAddress = '';
  public theme: THomeTheme = null;
  public mpass = '';
  private controlsEnabled = true;
  public foreignRegionName = '';
  private deviceTransferInProgress = false;
  private deviceTransferTimeout = null;
  private transferWaitingCounter = this.TRANSFER_CHECK_COUNT;
  @ViewChild('backgroundButton') backgroundButton: ElementRef;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    public ns: NewSystemService,
    private ds: DeviceService,
    private msg: MessageboxService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'Details';
    if ( !this.platform.isDesktopView() ) {
      this.progressBar.showProgress();
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.addSystem'),
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('auth.buttons.next'), true, false);
    this.background.setGray();
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.cancelClick();
    };
    this.footerBar.onButton2Click = () => {
      that.nextClick();
    };
    this.systemName = this.ns.getName();
    if (this.systemName === '') {
      this.systemName = this.trans('systems.labels.system') + ' ' + (this.systems.getSystemCount() + 1);
      this.ns.setName(this.systemName);
    }
    this.systemAddress = this.ns.getAddress();
    this.mpass = this.ns.getMpass();
    this.theme = this.ns.getTheme();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.progressBar.setProgressData(2, 7);
    if ( !this.backgroundButton ) {
      this.log('neradom ikonos komponento.');
    }
    if (this.theme.fullBackground !== '') {
      this.backgroundButton.nativeElement.style.background = this.theme.fullBackground;
    } else {
      this.backgroundButton.nativeElement.style.background = 'linear-gradient(180deg, ' + this.theme.startColor + ' 0%, ' + this.theme.endColor + ' 100%)';
    }
  }

  private cancelClick() {
    if (this.deviceTransferTimeout !== null) {
      clearTimeout(this.deviceTransferTimeout);
    }
    this.toaster.clear();
    this.ns.setUID('');
    this.miniStatus.hide();
    this.router.navigate(['../add-system'], { relativeTo: this.r, replaceUrl: true });
  }

  public nextClick() {
    this.toaster.clear();
    if (!this.controlsEnabled) {
      this.miniStatus.flash();
      return;
    }
    if (this.systemName === '') {
      this.toaster.postError(this.trans('systems.errors.noSystemName'));
      return;
    }
    this.ns.setName(this.systemName);
    this.ns.setMpass(this.mpass);
    this.deviceTransferInProgress = false;
    this.transferWaitingCounter = this.TRANSFER_CHECK_COUNT;
    this.beginCommunication();
  }

  private beginCommunication() {
    this.controlsEnabled = false;
    this.miniStatus.show(this.trans('systems.statuses.communicating'));
    const that = this;
    this.api
      .post(
        '/get-system-info-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          that.log(result);
          if (result.success) {
            that.progressBar.setProgressData(1, 8, 1);
            that.ns.setDeviceInfo(result.data);
            if (result.data.isInForeignRegion && !that.deviceTransferInProgress) {
              that.log('Modulis kitame regione. Bandom peradresuoti.');
              that.foreignRegionName = result.data.foreignRegion;
              if (result.data.supported_commands.indexOf('.18.') !== -1 || result.data.supported_commands.endsWith('.18')) {
                that.moveDevice();
              } else if (result.data.supported_commands.indexOf('.22.') !== -1 || result.data.supported_commands.endsWith('.22')) {
                that.moveDevice();
              } else {
                that.log('Modulis nepalaiko reikiamos komandos');
                that.controlsEnabled = true;
                that.miniStatus.hide();
                this.msg.open({
                  buttons: this.msg.buttons.Ok,
                  headerText: this.trans('systems.titles.notSupported'),
                  iconType: this.msg.iconType.Error,
                  messageContent: this.trans('systems.errors.devRegUnsupported').replace(':region', this.foreignRegionName)
                });
                that.progressBar.setProgressData(2, 7);
              }
            } else if (result.data.isInForeignRegion && that.deviceTransferInProgress) {
              this.transferWaitingCounter--;
              that.log('Modulis vis dar kitame regione. Laukiame kol persijungs.');
              if (this.transferWaitingCounter === 0) {
                that.controlsEnabled = true;
                that.miniStatus.hide();
                that.progressBar.setProgressData(2, 7);
                return;
              }
              that.deviceTransferTimeout = setTimeout(() => {
                that.beginCommunication();
              }, this.TRANSFER_CHECK_INTERVAL);
            } else {
              that.ns.setIpcom(result.srv);
              that.getDeviceStatus();
            }
          } else {
            if (this.deviceTransferInProgress) {
              this.transferWaitingCounter--;
              if (this.transferWaitingCounter > 0) {
                that.deviceTransferTimeout = setTimeout(() => {
                  that.beginCommunication();
                }, this.TRANSFER_CHECK_INTERVAL);
                return;
              }
            }
            that.controlsEnabled = true;
            that.miniStatus.hide();
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.controlsEnabled = true;
          that.miniStatus.hide();
        }
      );
  }

  public backgroundClick() {
    this.ns.setName(this.systemName);
    this.ns.setMpass(this.mpass);
    this.router.navigate(['../home-background-selection'], { relativeTo: this.r });
  }

  public locationClick() {
    this.ns.setName(this.systemName);
    this.ns.setMpass(this.mpass);
    this.router.navigate(['../system-location'], { relativeTo: this.r });
  }

  private getDeviceStatus() {
    const that = this;
    this.api
      .post(
        '/get-system-status-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (response) => {
          that.log(response);
          if (response.success) {
            that.progressBar.setProgressData(2, 8, 1);
            that.ns.setDeviceStatus(response.data);
            const info = that.ns.getDeviceInfo();
            const hwIdInt = parseInt(info.hwId, 16);
            if (Object.keys(that.ns.getDeviceStatus().areas).length === 0 && that.ns.getDeviceInfo().areas === 0) {
              if (dualModeDevices.includes(hwIdInt)) {
                that.miniStatus.hide();
                that.ns.setDirectControl(false);
                that.writeSystemToDb('../add-system-indirect');
              } else if ( indirectOnlyDevices.includes(hwIdInt) && gFamily.includes(hwIdInt) ) {
                // turėtų būti T įrenginiai
                that.ns.setDirectControl(false);
                that.writeSystemToDb('../add-system-indirect');
              } else if (indirectOnlyDevices.includes(hwIdInt)) {
                // kiti moduliai kurie gali netureti sriciu
                that.ns.setDirectControl(false);
                that.writeSystemToDb('../add-system-complete');
              } else {
                that.controlsEnabled = true;
                that.miniStatus.hide();
                that.toaster.postError(that.trans('systems.errors.deviceHasNoAreas'));
              }
            } else {
              that.ns.setDirectControl(true);
              that.getUsers();
            }
          } else {
            that.controlsEnabled = true;
            that.miniStatus.hide();
            /** Paradox centrales */
            if (that.ns.getDeviceInfo().central_panel >= 0x20 && that.ns.getDeviceInfo().central_panel < 0x30) {
              that.toaster.postError(that.trans('systems.errors.badPcDownloadCode'));
              that.router.navigate(['../wiring-diagrams'], { relativeTo: this.r });
            } else {
              that.progressBar.setProgressData(2, 7);
              that.toaster.postError(response.error);
            }
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.controlsEnabled = true;
          that.miniStatus.hide();
        }
      );
  }

  private getUsers() {
    // Praleidziam jeigu modulis nepalaiko vartotoju duomenu nuskaitymo.
    if (this.ns.getDeviceInfo().supported_commands.indexOf('.14.') === -1) {
      this.getAreaZones(); // einam prie zonu nuskaitymo.
      return;
    }

    const that = this;
    this.api
      .post(
        '/get-system-users-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
          supportedCommands: this.ns.getDeviceInfo().supported_commands,
          hwId: this.ns.getDeviceInfo().hwId,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            that.progressBar.setProgressData(3, 8, 1);
            if (result.charset !== undefined) {
              that.ns.systemCharset = result.charset;
            }
            if (result.maxUsers !== undefined) {
              that.ns.systemMaxUsers = result.maxUsers;
            }
            if (that.ns.getDeviceInfo().supported_commands.indexOf('.14.') !== -1 && result.users !== undefined) {
              // CG17 arba SP3
              const users: TDeviceUser[] = [];
              for (const iUser of result.users) {
                // Iš modulio atejo sritys HEX formate
                // O DB mes laikom ilga string, kuriame kiekvienas simbolis tai bitas, o pozicija, tai srities numeris.
                // Todėl konvertuojam HEX į bit string
                // CG17, SP3 moduliuose max 8 sritys, bet G moduliai gali dirbti su centralėmis, kurios turi šimtus sričių
                let intAreas = parseInt(iUser.areas, 16);
                let strAreas = '';
                for (let i = 1; i < 9; i++) {
                  /* eslint-disable no-bitwise */
                  strAreas += (intAreas & 0x01) === 1 ? '1' : '0';
                  intAreas >>= 1;
                  /* eslint-enable no-bitwise */
                }
                users.push({
                  id: 0,
                  name: iUser.name,
                  phone: iUser.phone,
                  code: iUser.code,
                  zone_number: iUser.id,
                  areas: strAreas,
                  email: iUser.email !== undefined ? iUser.email : '',
                  enable_data: 0,
                  pgms: -1,
                  present: false,
                  protegus_user_id: 0,
                  schedule_no: 0,
                  isOwner: false,
                  ownerPermissions: {},
                });
                that.ns.cgUsers = users;
              }
              that.ns.userListRead = true;
            } else if (that.ns.getDeviceInfo().supported_commands.indexOf('.11.') && result.users !== undefined) {
              // GV17, Gator
            }
            that.log(result);
            that.getAreaZones();
          } else {
            that.progressBar.setProgressData(2, 7);
            that.controlsEnabled = true;
            that.miniStatus.hide();
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.controlsEnabled = true;
          that.miniStatus.hide();
        }
      );
  }

  private writeSystemToDb(completionUrl: string) {
    const that = this;
    this.api.post('/create-system', this.ns.get(), true).subscribe(
      (result) => {
        if (result.success) {
          that.systems.setCurrentSystemFromRaw(result.system);
          if (that.ns.getDeviceInfo().supported_commands.indexOf('.11.') !== -1) {
            this.ds.performReRead(
              this.systems.activeSystem.id,
              (m) => {
                that.miniStatus.hide();
                that.controlsEnabled = true;
                that.progressBar.hideProgress();
                setTimeout(() => {
                  that.router.navigate([completionUrl], { relativeTo: this.r, replaceUrl: true });
                }, 0);
              },
              (e) => {
                if (e !== '') {
                  that.toaster.postError(e);
                }
                that.miniStatus.hide();
                that.progressBar.setProgressData(2, 7);
                that.controlsEnabled = true;
              },
              (s, p) => {
                if (p === null || p.max === 0) {
                  return;
                }
                // Reikia padaryti kad tilptu i 6.
                const ratio = 6 / p.max;
                const current = Math.ceil(ratio * p.current);
                console.log('ratio, current, p.current', ratio, current, p.current);
                that.progressBar.setProgressData(2 + current, 8, 1);
              }
            );
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.hideProgress();
            setTimeout(() => {
              that.router.navigate([completionUrl], { relativeTo: this.r, replaceUrl: true });
            }, 0);
          }
        } else {
          that.progressBar.setProgressData(2, 7);
          that.toaster.postError(result.error);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      },
      (error) => {
        that.progressBar.setProgressData(2, 7);
        that.miniStatus.hide();
        that.controlsEnabled = true;
      }
    );
  }

  private getAreaZones() {
    if (this.ns.getDeviceInfo().supported_commands.indexOf('.E.') === -1) {
      this.writeSystemToDb('../add-system-complete'); // Nepalaiko komandos, tai nieko nelieka kaip tik viską įrašyti į db
      return;
    }
    const areaCount = this.ns.getAreas().length;
    let totalProgress = 8 + areaCount;
    let currentProgress = Math.floor(totalProgress * 0.4);
    totalProgress++;
    this.progressBar.setProgressData(currentProgress, totalProgress, 1);
    currentProgress++;

    const that = this;
    let areaToRead = -1;
    const readOneArea = () => {
      const areaBefore = areaToRead;
      for (const iArea of that.ns.getAreas()) {
        if (iArea.queue_no > areaToRead) {
          areaToRead = iArea.queue_no;
          break;
        }
      }
      if (areaToRead === areaBefore) {
        that.getZoneNames();
        return;
      }
      that.log('skaitom srities zonas', areaToRead);

      that.api.get(`/area-zones?system_id=0&area_number=${areaToRead}&imei=${that.ns.getUID()}&mpass=${that.ns.getMpass()}&srv=${this.ns.getIpcom()}`, true).subscribe(
        (result) => {
          if (result.success) {
            that.log(result);
            const zoneNumbers = Object.keys(result.zones);
            for (const iZoneNumber of zoneNumbers) {
              const zoneWeHave = that.ns.getZone(parseInt(iZoneNumber, 10));
              if (zoneWeHave !== undefined) {
                zoneWeHave.areas.push(areaToRead);
              } else {
                const areaObj = that.ns.getArea(areaToRead);
                if (areaObj && areaObj.status === this.panicButtonsAreaStatus) {
                  const zoneData: TZoneData = {
                    queue_no: parseInt(iZoneNumber, 10),
                    alarmed: result.zones[iZoneNumber].alarm,
                    areas: [areaToRead],
                    bypassed: result.zones[iZoneNumber].bypass,
                    enabled: result.zones[iZoneNumber].enabled,
                    failed: result.zones[iZoneNumber].failure,
                    name: this.ls.get('systems.titles.panicButton').replace(':no', iZoneNumber),
                    native: true,
                    visible: true,
                    id: 0,
                  };
                  that.ns.addZone(zoneData);
                }
              }
            }
            that.progressBar.setProgressData(currentProgress, totalProgress, 1);
            currentProgress++;
            readOneArea();
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
    };

    readOneArea();
  }

  private getZoneNames() {
    const info = this.ns.getDeviceInfo();
    const hwIdInt = parseInt(info.hwId, 16);
    if (!spFamily.includes(hwIdInt)) {
      // Nepalaiko zonų pavadinimų komandos.
      this.writeSystemToDb('../add-system-complete');
      return;
    }
    this.progressBar.setProgressData(4, 8, 1);

    this.log('Skaitom zonų pavadinimus.');
    const that = this;
    this.api
      .post(
        '/get-system-zone-names-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          hwId: info.hwId,
          charset: this.ns.systemCharset,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i] === '') {
                continue;
              }
              const zone = this.ns.getZone(i + 1);
              if (zone !== undefined) {
                zone.name = result.data[i];
              }
            }
            that.getAreaNames();
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private getAreaNames() {
    const info = this.ns.getDeviceInfo();
    const hwIdInt = parseInt(info.hwId, 16);
    if (!spFamily.includes(hwIdInt)) {
      // Nepalaiko sričių pavadinimų komandos.
      this.writeSystemToDb('../add-system-complete');
      return;
    }
    this.progressBar.setProgressData(5, 8, 1);

    this.log('Skaitom sričių pavadinimus.');
    const that = this;
    this.api
      .post(
        '/get-system-area-names-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          hwId: info.hwId,
          charset: this.ns.systemCharset,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i] === '') {
                continue;
              }
              const area = this.ns.getArea(i + 1);
              if (area !== undefined) {
                area.name = result.data[i];
              }
            }
            that.getSensorNames();
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private getSensorNames() {
    const info = this.ns.getDeviceInfo();
    const hwIdInt = parseInt(info.hwId, 16);
    if (!spFamily.includes(hwIdInt)) {
      // Nepalaiko sričių pavadinimų komandos.
      this.writeSystemToDb('../add-system-complete');
      return;
    }
    this.progressBar.setProgressData(6, 8, 1);

    this.log('Skaitom sensorių pavadinimus.');
    const that = this;
    this.api
      .post(
        '/get-system-sensor-names-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          hwId: info.hwId,
          charset: this.ns.systemCharset,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i] === '') {
                continue;
              }
              const sensor = this.ns.getDeviceStatus().sensors.find((s) => s.queue_no === i + 1);
              if (sensor !== undefined) {
                sensor.name = result.data[i];
              }
            }
            that.getOutputNames();
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private getOutputNames() {
    const info = this.ns.getDeviceInfo();
    const hwIdInt = parseInt(info.hwId, 16);
    if (!spFamily.includes(hwIdInt)) {
      // Nepalaiko sričių pavadinimų komandos.
      this.writeSystemToDb('../add-system-complete');
      return;
    }
    this.progressBar.setProgressData(7, 8, 1);

    this.log('Skaitom pgm pavadinimus.');
    const that = this;
    this.api
      .post(
        '/get-system-output-names-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          hwId: info.hwId,
          charset: this.ns.systemCharset,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i] === '') {
                continue;
              }
              const pgm = this.ns.getPgm(i + 1);
              if (pgm !== null) {
                pgm.name = result.data[i];
              }
            }
            that.writeSystemToDb('../add-system-complete');
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private moveDevice() {
    const that = this;
    this.api
      .post(
        '/transfer-device',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            that.ns.setIpcom(0);
            that.deviceTransferInProgress = true;
            that.deviceTransferTimeout = setTimeout(() => {
              that.beginCommunication();
            }, this.TRANSFER_CHECK_INTERVAL);
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }
}
