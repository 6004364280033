import { Component, Injector, NgZone } from '@angular/core';
import { ActivatedRouteSnapshot, ChildrenOutletContexts } from '@angular/router';
import { pageTransitionAnimation } from 'src/animations';
import { AppSettings } from 'src/app-settings';
import { AuthService } from './api/auth.service';
import { LoggerService } from './api/logger.service';
import { PhoneStateService } from './api/phone-state.service';
import { PlatformService } from './api/platform.service';
import { RegionService } from './api/region.service';
import { RequestService } from './api/request.service';
import { UserService } from './api/user.service';
import { UResolvable } from './guards/UResolvable';
import { AreaService } from './services/area.service';
import { FcmService } from './services/fcm.service';
import { FooterService } from './services/footer.service';
import { GlobalService } from './services/global.service';
import { HeaderService } from './services/header.service';
import { ProgressService } from './services/progress.service';
import { RefreshService } from './services/refresh.service';
import { SystemsService } from './services/systems.service';
import { SystemService } from './api/system/system.service';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
  animations: [pageTransitionAnimation],
})
export class MobileAppComponent implements UResolvable<typeof MobileAppComponent> {
  public tag = 'MobileAppComponent';
  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot) {
    const g = injector.get(GlobalService);
    const l = injector.get(LoggerService);
    const promises: Promise<void>[] = [];
    promises.push(this.loadStage2(injector, route));
    await Promise.all(promises);
    l.log('resolved', 'MobileAppComponent');
  }

  public static async loadStage2(injector: Injector, route: ActivatedRouteSnapshot) {
    const auth = injector.get(AuthService);
    const req = injector.get(RequestService);
    const ss = injector.get(SystemsService);
    const l = injector.get(LoggerService);
    const user = injector.get(UserService);
    const systemService = injector.get(SystemService);
    const platform = injector.get(PlatformService);
    if (auth.hasToken()) {
      const systemId = route.paramMap.get('systemId');
      if ( systemId !== null && !isNaN(Number(systemId)) && platform.isDesktopView() ) {
        l.log('Nustatom aktyvia sistema ' + systemId);
        const currentSystem = await systemService.getSystem(parseInt(systemId, 10));
        if ( currentSystem ) {
          ss.setCurrentSystem(currentSystem);
        }
        return;
      }
      const userData = await auth.loadUserData();
      if (userData.raw) {
        user.setCurrentUser(userData.raw);
        user.change();
        user.saveCurrentUser();
      } else {
        user.setCurrentUser(userData);
        user.change();
        user.saveCurrentUser();
      }
      const lastSystemData = ss.getLastSystemData();
      if ( lastSystemData !== null ) {
        ss.activeSystem = lastSystemData;
        if ( ss.activeSystem.eventConfiguration === undefined ) {
          ss.activeSystem.eventConfiguration = [];
        }
        l.log('Aktyvi issaugota sistema: ', 'MobileResolver', ss.activeSystem);
      }
      if ( !platform.isDesktopView() ) {
        await new Promise<void>((resolve) => ss.loadSystems(undefined, resolve));
      }
    } else {
      l.log('No token', 'MobileAppComponent');
    }
  }
  constructor(
    public progress: ProgressService,
    public header: HeaderService,
    public footer: FooterService,
    public logger: LoggerService,
    public areaService: AreaService,
    private g: GlobalService,
    public ps: PlatformService,
    region: RegionService,
    psJsi: PhoneStateService, // Tik tam, kad uzkrautu.
    fcmS: FcmService, // Tik tam, kad uzkrautu.
    rf: RefreshService,
    private contexts: ChildrenOutletContexts,
    zone: NgZone,
  ) {
    this.logger.log('+', this.tag);
    if (ps.isDesktop()) {
      document.body.onmousedown = (e) => {
        g.onSwipeStartedSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
      document.body.onmousemove = (e) => {
        g.onSwipeContinueSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
      document.body.onmouseup = (e) => {
        g.onSwipeEndedSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
    } else {
      document.body.ontouchstart = (e) => {
        g.onSwipeStartedSource.next({
          x: e.touches.item(0).clientX,
          y: e.touches.item(0).clientY,
          leftPercent: (e.touches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.touches.item(0).clientY / window.innerHeight) * 100,
        });
      };
      document.body.ontouchend = (e) => {
        g.onSwipeEndedSource.next({
          x: e.changedTouches.item(0).clientX,
          y: e.changedTouches.item(0).clientY,
          leftPercent: (e.changedTouches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.changedTouches.item(0).clientY / window.innerHeight) * 100,
        });
      };
      document.body.ontouchmove = (e) => {
        g.onSwipeContinueSource.next({
          x: e.touches.item(0).clientX,
          y: e.touches.item(0).clientY,
          leftPercent: (e.touches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.touches.item(0).clientY / window.innerHeight) * 100,
        });
      };
    }
    document.documentElement.style.setProperty('--height-progress', '10px');
    document.documentElement.style.setProperty('--height-header', '77px');
    document.documentElement.style.setProperty('--height-footer', '52px');
    document.documentElement.style.setProperty('--color-primary', AppSettings.primaryColor);
    document.documentElement.style.setProperty('--color-secondary', AppSettings.secondaryColor);
    psJsi.onPhoneResumed.subscribe(() => {
      zone.run(() => {
        rf.doSilentRefresh();
      });
    });

    if (AppSettings.regionsEnabled) {
      region.autoSelectRegion();
    }
  }
  public contentIsScrolling(e) {
    const elementHeight = (e.target as HTMLElement).clientHeight;
    const elementInnerHeight = (e.target as HTMLElement).scrollHeight;
    const scrolledSoFar = (e.target as HTMLElement).scrollTop;
    if (elementHeight + scrolledSoFar >= elementInnerHeight - 50) {
      this.g.onScrolledToBottomSource.next();
    }
  }

  public getPageTransitionAnimationData(): string {
    const primaryContext = this.contexts.getContext('primary');
    return primaryContext?.route?.snapshot?.data?.animation ?? primaryContext?.route?.snapshot?.firstChild?.data?.animation ?? primaryContext?.route?.snapshot?.routeConfig?.path ??
           'default';
  }
}
