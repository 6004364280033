import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { PermissionRole } from 'src/api/v3/common';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteComponent extends LanguageAware implements OnInit {
  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    const ref = localStorage.getItem('ref');
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.removeSystem'),
      backUrl: ref === 'conf' ? '../system-configuration' : '*',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', false, false);
    this.footerBar.onButton1Click = () => {
      if ( ref === 'conf' ) {
        this.router.navigate(['../system-configuration'], { relativeTo: this.r, replaceUrl: true });
      } else {
        history.back();
      }
    };
    this.background.setGray();
  }

  ngOnInit(): void {}

  public onDelete() {
    this.es.deleteSystem();
    this.systems.setCurrentSystemFromInternalList(false);
    this.router.navigate(this.g.getHomeUrl());
  }

  public canDeleteWholeSystem(): boolean {
    return this.systems.activeSystem && (
      this.systems.currentUserPermissions?.permissions.system.delete
      && this.systems.currentUserPermissions?.role !== PermissionRole.SystemUser
      || this.us.currentUser.permissions?.permissions.systems.delete
    );
  }
}
