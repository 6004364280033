import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionRole } from 'src/api/v3/common';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { UserFindPopupComponent } from 'src/app/popups/user-find-popup/user-find-popup.component';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-cp-all-users',
  templateUrl: './cp-all-users.component.html',
  styleUrls: ['./cp-all-users.component.scss'],
})
export class CpAllUsersComponent extends LanguageAware implements OnInit {
  public canAdd = this.us.currentUser.permissions?.permissions.users.create;
  public canShowDelete = AppSettings.canDeleteUserFromList && this.us.currentUser.permissions?.permissions.users.delete;

  constructor(cdRef: ChangeDetectorRef, private router: Router, private pp: PopupService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.users'),
      backUrl: '/company',
      showFind: true,
    });
    this.background.setGray();

    if (this.us.companyInstallers.length === 0 && this.us.simpleUsers.length === 0) {
      this.getUsers();
    }
    this.helper.setCountForBottomButton(1, 1, 1, false, true, false, 'cpau');
    if (this.us.countries.length === 0) {
      this.loadCountries();
    }
    this.headerBar.onActionButtonClicked = () => {
      this.pp.openPopup(UserFindPopupComponent, { attachToClass: 'mobile-app' });
    };
  }

  ngOnInit(): void {}

  public goToProfile() {
    this.router.navigate(['/profile']);
  }

  private getUsers() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api.post('/all-users', {}, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.us.companyInstallers = result.users.filter((u) => this.permissionService.getPermission(u.access_permission_id)?.role === PermissionRole.Installer);
          that.us.simpleUsers = result.users.filter((u) => this.permissionService.getPermission(u.access_permission_id)?.role === PermissionRole.GeneralUser);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
      }
    );
  }

  public addUser() {
    this.router.navigate(['/company/add-user']);
  }

  public editUser(userId: number) {
    this.router.navigate(['/company/edit-user/' + userId]);
  }

  private loadCountries() {
    const that = this;
    this.api.post('/countries', {}, true).subscribe(
      (result) => {
        if (result.success) {
          that.us.countries = result.list;
        }
      },
      (error) => {
        // nieko nedarom
      }
    );
  }

  public deleteUser(user, event) {
    event.stopPropagation();
    const that = this;
    const userToHole = JSON.stringify(user);
    const role = this.permissionService.getPermission(user.access_permission_id)?.role ?? PermissionRole.GeneralUser;
    if (role === PermissionRole.Installer) {
      that.us.companyInstallers = that.us.companyInstallers.filter((ci) => ci.id !== user.id);
    } else if (role === PermissionRole.SuperAdmin) {
      that.us.superAdmins = that.us.superAdmins.filter((su) => su.id !== user.id);
    } else if (role === PermissionRole.Company) {
      that.us.companies = that.us.companies.filter((cp) => cp.id !== user.id);
    } else if (role === PermissionRole.GeneralUser) {
      that.us.simpleUsers = that.us.simpleUsers.filter((cp) => cp.id !== user.id);
    }
    this.api.post('/delete/cuser', { user_id: user.id }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          that.revertDeletion(userToHole);
        }
      },
      (error) => {
        that.revertDeletion(userToHole);
      }
    );
  }

  private revertDeletion(userBefore: any) {
    const user = JSON.parse(userBefore);
    const role = this.permissionService.getPermission(user.access_permission_id)?.role ?? PermissionRole.GeneralUser;
    if (role === PermissionRole.Installer && this.us.companyInstallers.find((ci) => ci.id === user.id) === undefined) {
      this.us.companyInstallers.push(user);
    } else if (role === PermissionRole.SuperAdmin && this.us.superAdmins.find((su) => su.id === user.id) === undefined) {
      this.us.superAdmins.push(user);
    } else if (role === PermissionRole.Company && this.us.companies.find((cp) => cp.id === user.id) === undefined) {
      this.us.companies.push(user);
    } else if (role === PermissionRole.GeneralUser && this.us.simpleUsers.find((cp) => cp.id === user.id) === undefined) {
      this.us.simpleUsers.push(user);
    }
  }
}
