import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GroupboxService } from '../../services/groupbox.service';

@Component({
  selector: 'app-groupbox',
  templateUrl: './groupbox.component.html',
  styleUrls: ['./groupbox.component.scss'],
})
export class GroupboxComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() headerText = '';
  @Input() iconStyle: 'solid' | 'outline' = 'outline';
  @Input() defaultStateCollapsed: boolean;
  public isCollapsed = false;
  private refreshSubscriber = null;
  @ViewChild('contentGroupBox') contentGroupbox: ElementRef;
  private observer: MutationObserver;

  constructor(grps: GroupboxService, private cdRef: ChangeDetectorRef) {
    const that = this;
    this.refreshSubscriber = grps.onRefresh.subscribe(() => {
      that.recalculate();
    });
    this.observer = new MutationObserver((mutations: MutationRecord[]) => {
      this.recalculate();
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if(this.defaultStateCollapsed) {
      this.isCollapsed = true;
      this.recalculate();
      this.cdRef.detectChanges();
    } else {
      if (this.contentGroupbox !== undefined) {
        this.contentGroupbox.nativeElement.style.maxHeight = this.contentGroupbox.nativeElement.scrollHeight + 'px';
      }
    }
    this.observer.observe(this.contentGroupbox.nativeElement, { childList: true, subtree: true });
  }

  ngOnDestroy() {
    if (this.refreshSubscriber !== null) {
      this.refreshSubscriber.unsubscribe();
      this.refreshSubscriber = null;
    }
    this.observer.disconnect();
  }

  public toggleGroupbox() {
    this.isCollapsed = !this.isCollapsed;
    this.recalculate();
  }

  private recalculate() {
    if (this.isCollapsed) {
      this.contentGroupbox.nativeElement.style.maxHeight = '0px';
    } else {
      this.contentGroupbox.nativeElement.style.maxHeight = this.contentGroupbox.nativeElement.scrollHeight + 'px';
    }
  }
}
