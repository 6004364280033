import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { ActivatedRoute, Router } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TagInputComponent } from 'src/app/ui/tag-input/tag-input.component';

@Component({
  selector: 'app-system-information',
  templateUrl: './system-information.component.html',
  styleUrls: ['./system-information.component.scss'],
})
export class SystemInformationComponent extends LanguageAware implements OnInit {
  @ViewChild('tags') tags: TagInputComponent;
  public readonly canEditTags = this.us.currentUser.permissions?.permissions.tags.edit;
  public readonly canSeeTags = this.us.currentUser.permissions?.permissions.tags.view;
  public ownerList = [];
  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.tag = 'SysInfo';
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.systemInformation'),
      backUrl: '../system-configuration',
      activatedRoute: this.r,
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    const that = this;
    if (this.es.systemInfoBeforeModification === null) {
      this.es.systemInfoBeforeModification = {
        name: this.systems.activeSystem.name,
        address: this.systems.activeSystem.address,
        timeZone: this.systems.activeSystem.timeZone,
        theme: {
          startColor: this.systems.activeSystem.theme.startColor,
          endColor: this.systems.activeSystem.theme.endColor,
          fullBackground: this.systems.activeSystem.theme.fullBackground,
        },
        mpass: this.systems.activeSystem.mpass,
        tags: [...this.systems.activeSystem.tags],
      };
    }
    this.footerBar.onButton1Click = () => {
      that.revertChanges(that.systems.activeSystem.id, JSON.stringify(that.es.systemInfoBeforeModification));
      this.router.navigate(['../system-configuration'], { relativeTo: this.r, replaceUrl: true });
    };
    this.footerBar.onButton2Click = () => {
      that.onOk();
    };
    this.headerBar.onBackClicked = () => {
      that.revertChanges(that.systems.activeSystem.id, JSON.stringify(that.es.systemInfoBeforeModification));
    };
    this.handleOwners();
  }

  ngOnInit(): void {
    const icon: HTMLElement = document.querySelector('#system-information-background-button');
    if (icon === null) {
      this.log('neradom ikonos komponento.');
    }
    if (this.systems.activeSystem.theme.fullBackground !== '') {
      icon.style.background = this.systems.activeSystem.theme.fullBackground;
    } else {
      icon.style.background = 'linear-gradient(180deg, ' + this.systems.activeSystem.theme.startColor + ' 0%, ' + this.systems.activeSystem.theme.endColor + ' 100%)';
    }
  }

  private saveSettings() {
    const that = this;
    const systemId = this.systems.activeSystem.id;
    const old = JSON.stringify(this.es.systemInfoBeforeModification);
    this.es.systemInfoBeforeModification = null;
    if ( this.canEditTags) {
      this.systems.activeSystem.tags = [...this.tags.selectedTags];
    }
    this.api
      .post(
        '/system/save',
        {
          systemId: this.systems.activeSystem.id,
          name: this.systems.activeSystem.name,
          address: this.systems.activeSystem.address,
          timeZone: this.systems.activeSystem.timeZone,
          backgroundStart: this.systems.activeSystem.theme.startColor,
          backgroundEnd: this.systems.activeSystem.theme.endColor,
          fullBackground: this.systems.activeSystem.theme.fullBackground,
          mpass: this.systems.activeSystem.mpass,
          tags: this.canEditTags ? this.systems.activeSystem.tags : undefined,
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            that.systems.setCurrentSystem(that.systems.activeSystem);
          } else {
            that.toaster.postError(result.error);
            that.revertChanges(systemId, old);
          }
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.revertChanges(systemId, old);
        }
      );
  }

  public onOk() {
    this.saveSettings();
    this.router.navigate(['../system-configuration'], { relativeTo: this.r, replaceUrl: true});
  }

  public locationClick() {
    this.router.navigate(['../system-location'], { relativeTo: this.r });
  }

  public backgroundClick() {
    this.router.navigate(['../home-background-selection'], { relativeTo: this.r });
  }

  private revertChanges(systemid: number, oldSettings: string) {
    this.es.systemInfoBeforeModification = null;
    const system = this.systems.getSystem(systemid);
    if (system === undefined) {
      return;
    }
    const old = JSON.parse(oldSettings);
    system.name = old.name;
    system.address = old.address;
    system.timeZone = old.timeZone;
    system.theme = old.theme;
    system.mpass = old.mpass;
    system.tags = [...old.tags];
    this.systems.saveActiveSystem(systemid);
  }

  public copyImei() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.systems.activeSystem.imei;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  private handleOwners() {
    this.ownerList = [];
    const deviceUser = this.systems.activeSystem.device_users.find((du) => du.protegus_user_id === this.us.currentUser.id);
    for (const iOwner of this.systems.activeSystem.owners) {
      const privacySetting = this.systems.activeSystem.privacyOfOwners.find((po) => po.user_id === iOwner.id);
      if (privacySetting === undefined) {
        // show all
        this.ownerList.push({
          name: iOwner.name,
          email: iOwner.email,
          phone_number: iOwner.phone_number,
        });
        continue;
      }

      this.ownerList.push({
        name: (deviceUser === undefined || deviceUser.zone_number > 8 ? privacySetting.show_name_users : privacySetting.show_name_admins)
          ? iOwner.name
          : '<' + this.trans('general.hidden').toLowerCase() + '>',
        email: (deviceUser === undefined || deviceUser.zone_number > 8 ? privacySetting.show_email_users : privacySetting.show_email_admins)
          ? iOwner.email
          : '<' + this.trans('general.hidden').toLowerCase() + '>',
        phone_number: (deviceUser === undefined || deviceUser.zone_number > 8 ? privacySetting.show_phone_users : privacySetting.show_phone_admins)
          ? iOwner.phone_number
          : '<' + this.trans('general.hidden').toLowerCase() + '>',
      });
    }
  }
}
