import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageService } from 'src/app/services/language.service';
import { MiniStatusService } from 'src/app/services/mini-status.service';
import { SystemsService } from 'src/app/services/systems.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { IPopup } from '../IPopup';

@Component({
  selector: 'app-system-find-popup',
  templateUrl: './system-find-popup.component.html',
  styleUrls: ['./system-find-popup.component.scss']
})
export class SystemFindPopupComponent implements OnInit, AfterViewInit, IPopup {
  @ViewChild('basePopup') basePopup;
  @ViewChild('phraseEdit') phraseEdit;
  public systemToFind = '';
  public popupName = 'FilterSystems';
  public closed: EventEmitter<void> = new EventEmitter();

  constructor(
    public lang: LanguageService,
    private rs: ApiRequestService,
    private ms: MiniStatusService,
    private t: ToasterService,
    private router: Router,
    private ss: SystemsService,
    private g: GlobalService
  ) { }

  ngOnInit(): void {}
  ngAfterViewInit() { this.show(); }

  public confirmSearch() {
    this.basePopup.hidePopup();
    this.ms.show(this.lang.get('general.pleaseWait'));
    this.router.navigate([...this.g.getHomeUrl(), 'filtered-systems']);
    this.rs.post('/filter-systems', { searchPhrase: this.systemToFind, searchFields: [] }, true).subscribe(
      result => {
        this.ms.hide();
        if ( !result.success ) {
          this.t.postError(result.error);
        } else {
          this.ss.filteredSystems = [];
          for ( const iSystem of result.list.data ) {
            if ( iSystem ) {
              this.ss.filteredSystems.push(this.ss.convertSystemFromRaw(iSystem));
            }
          }
        }
      },
      error => {
        this.ms.hide();
      }
    );
  }

  public show() {
    this.phraseEdit.focusEdit();
  }
}
