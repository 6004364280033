import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { AppSettings } from 'src/app-settings';
import { sendError } from './services/error-reporter';

@Injectable()
export class GlobalErrorCatcher implements ErrorHandler {
  constructor() {}

  handleError(error: { message?: string; stack?: string }) {
    console.error('[ERROR]', error);
    if (error.message?.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      return;
    }
    if (AppSettings.localReport) sendError(error);
    Sentry.captureException(error);
  }
}
