import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from '../../language-aware';
import { HeaderService } from 'src/app/services/header.service';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { PermissionRole } from 'src/api/v3/common';
type SupportTicket = {
  email: string;
  formContent: string;
};

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent extends LanguageAware implements OnInit, AfterViewInit {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly PermissionRole = PermissionRole;
  public formContent = '';
  public email = '';
  private TIME_BETWEEN_CLICKS = 5; // s
  private lastClickTime = null;
  public isLoading = false;
  public val = new ValidatorBuilder<SupportTicket>()
  .required('email', `${this.trans('validation.required').replace(':attribute', this.trans('auth.form.email'))}`)
  .regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, this.trans('auth.errors.invalidEmail'))
  .required('formContent', `${this.trans('validation.required').replace(':attribute', this.trans('help.supportTicket'))}`)
  .build().bindContext(this);

  constructor(
    cdRef: ChangeDetectorRef,
    public headerBar: HeaderService,
    private messagebox: MessageboxService
  ) {
    super(cdRef);
    this.email = this.us.currentUser?.email ?? '';
    this.background.setGray();
    if(this.desktopView) {
      this.headerBar.hideHeader();
    } else {
      this.headerBar.showHeader({
        headerText: this.trans('help.supportTicket'),
        backUrl: '*',
      });
    }
    if ( !this.platform.isDesktopView() ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('help.submit'), true, false);
      this.footerBar.onButton1Click = () => {
        history.back();
      };
      this.footerBar.onButton2Click = () => {
        this.sendReport();
      };
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if(this.headerBar.isDesktop) {
      setTimeout(() => {
        const header = document.querySelector('.header-dark');
        if(header) {
          header.classList.replace('header-dark', 'header-bright');
        }
      });
    }
  }

  public async sendReport() {
    if ( this.isLoading ) { return; }
    this.email = this.us.currentUser.id === 0 ? this.email : this.us.currentUser.email;
    const validationResult = await this.val.validate({ email: this.email, formContent: this.formContent });
    if(validationResult === false) { return; }
    this.isLoading = true;

    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      this.isLoading = false;
      return;
    }
    if (this.formContent.trim() === '') {
      this.isLoading = false;
      return;
    }
    if (this.lastClickTime !== null && new Date().getTime() - this.lastClickTime.getTime() <= this.TIME_BETWEEN_CLICKS * 1000) {
      this.isLoading = false;
      return;
    }
    this.lastClickTime = new Date();
    if (this.us.currentUser.id !== 0) {
      this.email = this.us.currentUser.email;
    } else if ( this.email.trim() === '' ) {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Ok,
        headerText: this.trans('general.titleError'),
        iconType: this.messagebox.iconType.Error,
        messageContent: this.trans('help.errors.emptyEmail')
      });
      this.lastClickTime = null;
      this.isLoading = false;
      return;
    }
    if ( !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.email) ) {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Ok,
        headerText: this.trans('general.titleError'),
        iconType: this.messagebox.iconType.Error,
        messageContent: this.trans('validation.emailInvalid')
      });
      this.lastClickTime = null;
      this.isLoading = false;
      return;
    }

    const that = this;
    if ( !this.platform.isDesktopView() ) {
      this.miniStatus.show(this.trans('help.sending'));
    }
    this.api
      .post(
        '/help',
        {
          content: this.formContent,
          email: this.email,
          user: this.us.currentUser.id,
        },
        true
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if (result.success) {
            that.messagebox.open({
              buttons: that.messagebox.buttons.Close,
              headerText: that.trans('general.titleSuccess'),
              messageContent: result.message,
              iconType: that.messagebox.iconType.Success
            });
            that.formContent = '';
            that.isLoading = false;
          } else {
            that.messagebox.open({
              buttons: that.messagebox.buttons.Ok,
              headerText: that.trans('general.titleError'),
              iconType: that.messagebox.iconType.Error,
              messageContent: result.error
            });
          }
        },
        (error) => {
          that.miniStatus.hide();
          that.messagebox.open({
            buttons: that.messagebox.buttons.Ok,
            headerText: that.trans('general.titleError'),
            iconType: that.messagebox.iconType.Error,
            messageContent: that.trans('help.errors.failedToSend')
          });
          that.isLoading = false;
        }
      );
  }

  public emailSet(value: string) {
    this.email = value;
  }
}
