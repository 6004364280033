import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicSuccessResponse, Permission, PermissionList, PermissionRole, PermissionRule } from 'src/api/v3/common';
import { AddPermissionRuleResponse } from 'src/api/v3/permission';
import requests from 'src/api/v3/requests';
import { PermissionService } from 'src/app/api/permission.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupType } from 'src/app/models/popup-type';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { PopupService } from 'src/app/services/popup.service';
import { FooterButton } from 'src/app/ui/desktop-footer/desktop-footer.component';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { TagInputComponent } from 'src/app/ui/tag-input/tag-input.component';

@Component({
  selector: 'app-edit-permission',
  templateUrl: './edit-permission.component.html',
  styleUrls: ['./edit-permission.component.scss']
})
export class EditPermissionComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public canEdit = (this.us.currentUser.permissions?.permissions.roles.edit || this.us.currentUser.permissions?.permissions.roles.create)
                && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
  public readonly canEditTags = this.us.currentUser.permissions?.permissions.tags.edit && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);
  public readonly canSeeTags = this.us.currentUser.permissions?.permissions.tags.view;
  public canEditAllOptions = this.canEdit;
  @ViewChild('tags') tags: TagInputComponent;
  public permissionToEdit: PermissionRule | null = null;
  public parent: PermissionRule = undefined;
  public isNew = false;
  public canDelete = false;
  public inputChanged = false;
  public isSaving = false;
  public footerButtons: FooterButton[] = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.saveChanges() },
    { label: this.trans('general.cancel'),  callback: () => this.backButtonPressed() },
  ];
  public parentName = '';
  public roleText = '';
  public companyText = '';
  public parentList: DropdownItem<number>[] = [];
  public generalPermissions: (keyof PermissionList)[] = [];
  public systemPermissions: (keyof PermissionList)[] = [];
  public advancedPermissions: (keyof PermissionList)[] = [];
  private permissionsIdChangeSubscription = this.route.params.subscribe((params) => {
    if ( !this.desktopView ) { return; }
    const ruleId = Number(params.permissionId);
    if ( this.inputChanged || this.es.getId(TEditableComponent.Permission) !== -1) {
      this.revert();
    }
    if (ruleId && !isNaN(ruleId)) {
      const rule = this.permissionService.getPermission(ruleId);
      if (!rule) {
        throw new Error('Rule not found');
      }
      this.es.beginComponentEdit(TEditableComponent.Permission, rule.id, rule);
    }
    this.reinitVariables();
  });

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
    private popupService: PopupService,
    private route: ActivatedRoute,
    private messagebox: MessageboxService,
  ) {
    super(cdRef);
    this.reinitVariables();
    if ( !this.desktopView ) {
      this.background.setGray();
      this.headerBar.showHeader({
        backUrl: '*',
        headerText: this.isNew ? this.trans('permissions.titles.newPermission') : this.permissionToEdit.name,
        actionButtonText: this.canDelete ? this.trans('general.delete') : undefined,
      });
      if ( this.canEdit ) {
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('settings.buttons.save'), true, false);
        this.headerBar.onBackClicked = () => {
          if ( !this.isNew ) {
            this.revert();
          }
        };
        this.footerBar.onButton1Click = () => {
          if ( !this.isNew ) {
            this.revert();
          }
          history.back();
        };
        this.footerBar.onButton2Click = () => {
          this.saveChanges();
        };
      }
      if ( this.canDelete ) {
        this.headerBar.onActionButtonClicked = () => {
          this.doDelete();
        };
      }
    }
    this.generateRoleText();
    this.generatePermissionGroups();
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.permissionsIdChangeSubscription?.unsubscribe();
  }

  private reinitVariables() {
    this.permissionToEdit = this.es.getEditableComponent(TEditableComponent.Permission);
    this.isNew = this.permissionToEdit === null || this.route.routeConfig.path === 'new';
    if ( this.isNew ) {
      const defaultRule = this.permissionService.getDefaultPermissionFor(this.us.currentUser.permissions?.role, this.us.currentUser.company_id);
      this.permissionToEdit = PermissionService.getCopyOfRule(defaultRule);
      this.permissionToEdit.parent_id = defaultRule.id;
      this.permissionToEdit.default = false;
      this.permissionToEdit.id = 0;
      this.permissionToEdit.company_id = this.us.currentUser.company_id;
      this.parentName = defaultRule.name;
    } else {
      this.parentName = this.permissionService.getPermission(this.permissionToEdit.parent_id)?.name ?? '';
      if ( this.permissionToEdit.company_id !== 0 ) {
        this.companyText = this.companyService.getCompany(this.permissionToEdit.company_id)?.name;
      }
    }
    this.parent = this.permissionService.getPermission(this.permissionToEdit?.parent_id) ?? this.permissionToEdit;
    this.canDelete = this.us.currentUser.permissions?.permissions.roles.delete && !this.isNew && this.permissionToEdit.parent_id !== null;
    this.canEdit = this.canEdit && (this.us.currentUser.company_id === 0 || this.permissionToEdit.parent_id !== null);
    this.canEditAllOptions = this.canEdit && this.permissionToEdit.parent_id === null && this.us.currentUser.company_id === 0;
    this.generatePermissionGroups();
    this.generateRoleText();
    this.generateParentList();
  }

  private async saveChanges() {
    if ( this.isSaving ) {
      if ( !this.desktopView ) { this.miniStatus.flash(); }
      return;
    }
    this.isSaving = true;
    if ( !this.desktopView ) { this.miniStatus.show(this.trans('general.saving')); }
    const func = (this.isNew ? requests.permission.addPermissionRule : requests.permission.savePermissionRule) as (p: any) => any;
    if ( this.canEditTags ) {
      this.permissionToEdit.tags = this.tags?.selectedTags ?? this.permissionToEdit.tags;
    }
    const data = this.isNew ? { ...this.permissionToEdit } : this.permissionToEdit;
    func(data).subscribe(
      (res: BasicSuccessResponse | AddPermissionRuleResponse) => {
        if ( this.isNew ) {
          this.permissionToEdit.id = (res as AddPermissionRuleResponse).id;
          this.permissionService.ingestPermissionRule(this.permissionToEdit);
          this.permissionService.listStorage.push({
            value: this.permissionToEdit.id,
            label: this.permissionToEdit.name,
            extraParam: this.permissionToEdit.role,
          });
        }
        this.isSaving = false;
        this.inputChanged = false;
        if ( this.permissionToEdit.default ) {
          this.permissionService.permissionsRules.forEach(
            pr => (pr.role === this.permissionToEdit.role && pr.id !== this.permissionToEdit.id && pr.company_id === this.permissionToEdit.company_id ) && (pr.default = false)
          );
        }
        this.es.endComponentEdit(TEditableComponent.Permission);
        if ( !this.desktopView ) {
          this.miniStatus.hide();
          history.back();
        } else {
          setTimeout(() => {
            this.router.navigate([...this.g.resolveRootRoute(), 'permissions'], { replaceUrl: true });
          }, 1000);
        }
      },
      error => {
        if ( !this.desktopView ) { this.miniStatus.hide(); }
        this.isSaving = false;
      }
    );
  }

  private revert(before?: PermissionRule) {
    if ( !before ) {
      before = this.es.getComponentBeforeModification(TEditableComponent.Permission);
    }
    if ( !before ) { return; } // vadinasi naujas

    const found = this.permissionService.getPermission(before.id);
    if ( !found ) { return; }

    const keys = Object.keys(found);
    for ( const iKey of keys ) {
      found[iKey] = before[iKey];
    }
    this.inputChanged = false;
    this.es.endComponentEdit(TEditableComponent.Permission);
  }

  private doDelete() {
    if ( this.isSaving ) {
      if ( !this.desktopView ) { this.miniStatus.flash(); }
      return;
    }
    this.isSaving = true;
    if ( !this.desktopView ) { this.miniStatus.show(this.trans('general.deleting')); }
    requests.permission.deletePermissionRule({id: this.permissionToEdit.id}).subscribe(
      result => {
        this.permissionService.removePermissionRule(this.permissionToEdit.id);
        this.isSaving = false;
        this.es.endComponentEdit(TEditableComponent.Permission);
        if ( !this.desktopView ) {
          this.miniStatus.hide();
          history.back();
        } else {
          setTimeout(() => {
            this.router.navigate([...this.g.resolveRootRoute(), 'permissions'], { replaceUrl: true });
          }, 1000);
        }
      },
      error => {
        this.isSaving = false;
        if ( !this.desktopView ) { this.miniStatus.hide(); }
      }
    );
  }

  public selectParent() {
    this.popupService.showSlideout<DropdownItem<number>>({
      headerText: this.trans('permissions.labels.parentRule'),
      items: this.parentList.filter(p => p.value !== this.permissionToEdit.id),
      onSubmit: (res) => {
        this.permissionToEdit.parent_id = res.value;
        this.parentName = res.label as string;
        const found = this.permissionService.getPermission(res.value);
        this.inputChanged = true;
        this.handleChangedParent(found, res.value);
      }
    }, PopupType.SlideoutWithValue);
  }

  public parentChanged() {
    this.inputChanged = true;
    const parent = this.permissionService.getPermission(this.permissionToEdit.parent_id);
    this.handleChangedParent(parent, this.permissionToEdit.parent_id);
  }

  private async handleChangedParent(parent: PermissionRule, parentId: number) {
    if ( !parent ) {
      try {
        parent = await this.permissionService.loadPermissionById(parentId);
      } catch(e){ }
    }
    this.parentName = parent?.name ?? '';
    this.permissionToEdit.role = parent?.role;
    this.generateRoleText();
    this.generatePermissionGroups();

  }

  private generateRoleText() {
    this.roleText = this.trans('permissions.roles.' + this.permissionToEdit.role);
  }

  private generatePermissionGroups() {
    this.parent = this.permissionService.getPermission(this.permissionToEdit.parent_id) ?? this.permissionToEdit;
    const canSeeEverything = this.us.currentUser.permissions.role === PermissionRole.SuperAdmin
      && (this.parent.id === this.permissionToEdit.id)
      && this.us.currentUser.company_id === 0;
    this.generalPermissions = [];
    this.systemPermissions = [];
    this.advancedPermissions = [];
    for ( const iPermissionKey of this.permissionService.allGeneralPermissions ) {
      const parentPermission: Permission = this.parent.permissions[iPermissionKey];
      if ( canSeeEverything || parentPermission.view || parentPermission.create || parentPermission.edit || parentPermission.delete || parentPermission.execute ) {
        this.generalPermissions.push(iPermissionKey);
      }
    }
    for ( const iPermissionKey of this.permissionService.allSystemPermissions ) {
      const parentPermission: Permission = this.parent.permissions[iPermissionKey];
      if ( canSeeEverything || parentPermission.view || parentPermission.create || parentPermission.edit || parentPermission.delete || parentPermission.execute ) {
        this.systemPermissions.push(iPermissionKey);
      }
    }
    for ( const iPermissionKey of this.permissionService.allAdvancedPermissions ) {
      const parentPermission: Permission = this.parent.permissions[iPermissionKey];
      if ( canSeeEverything || parentPermission.view || parentPermission.create || parentPermission.edit || parentPermission.delete || parentPermission.execute ) {
        this.advancedPermissions.push(iPermissionKey);
      }
    }
  }

  private generateParentList() {
    this.permissionService.loadDropDownList().then(() => {
      this.permissionService.listStorage.forEach(i => i.default = i.value === this.permissionToEdit.parent_id);
      const rolesToCheck: PermissionRole[] = [];
      if ( this.us.currentUser.company_id !== 0 ) {
        rolesToCheck.push(PermissionRole.SuperAdmin);
      }
      this.parentList = this.permissionService.listStorage.filter(i => !rolesToCheck.includes(i.extraParam as PermissionRole));
    });
  }

  public backButtonPressed() {
    this.revert();
    if ( this.desktopView ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'permissions'], { replaceUrl: true });
    } else {
      history.back();
    }
  }

  public onPermissionHeaderClick(permission: string) {
    if(!this.desktopView) {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Ok,
        messageContent: permission,
        alignCenter: true
      });
    }
  }
}
