<div class="mobile-app">
  <div
    id="master-container"
    [ngClass]="footer.isDesktop || header.isDesktop || ps.isDesktopView() ? {} : {
      'layout-all': true,
      'layout-progress': progress.isShowing() && !header.isShowing() && !footer.isShowing(),
      'layout-progress-header': progress.isShowing() && header.isShowing() && !footer.isShowing(),
      'layout-progress-footer': progress.isShowing() && !header.isShowing() && footer.isShowing(),
      'layout-header': !progress.isShowing() && header.isShowing() && !footer.isShowing(),
      'layout-footer': !progress.isShowing() && !header.isShowing() && footer.isShowing(),
      'layout-header-footer': !progress.isShowing() && header.isShowing() && footer.isShowing()
    }"
  >
    <app-progress *ngIf="progress.isShowing()"></app-progress>
    <app-header *ngIf="header.isShowing()"></app-header>
    <app-refresh></app-refresh>
    <div id="content" (scroll)="contentIsScrolling($event)" [@pageTransition]="getPageTransitionAnimationData()">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="footer.isShowing()"></app-footer>
  </div>
  <app-log-view *ngIf="logger.showLogView"></app-log-view>
  <app-area-keypad *ngIf="areaService.busyArea !== null && areaService.busyArea.showPinKeypad" [keypadType]="'slideout'" [maxLength]="6" [minLength]="4"></app-area-keypad>
  <app-bypass-zones *ngIf="areaService.busyArea !== null && areaService.busyArea.showZoneBypass" [dataSource]="areaService.busyArea.zonesToBypass"></app-bypass-zones>
  <app-portal name="mobile"></app-portal>
</div>
