<div class="page">
  <app-language-switcher class="ls"></app-language-switcher>
  <h1>{{ trans('general.welcomeText') }} {{ title }}</h1>
  <ng-content></ng-content>
  <div class="social-login">
    <app-google-login *ngIf="canShowGoogle"></app-google-login>
    <app-apple-login *ngIf="canShowApple"></app-apple-login>
    <app-portal [name]="ForgotPasswordTarget"></app-portal>
  </div>
  <div class="register">
    <a routerLink="/login/register">{{ trans('auth.labels.register') }}</a>
    <a routerLink="/login/register/company" *ngIf="canRegisterCompany">{{ trans('auth.labels.registerCompany') }}</a>
    <div class="help">
      <a routerLink="/login/help">
        <div class="icon" [inlineSVG]="'assets/images/help.svg'"></div>
        {{ trans('general.help') }}
      </a>
    </div>
  </div>
</div>
