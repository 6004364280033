import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicSuccessResponse, Tag } from 'src/api/v3/common';
import requests from 'src/api/v3/requests';
import { AddTagResponse } from 'src/api/v3/tag';
import { TagService } from 'src/app/api/tag.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { FooterButton } from 'src/app/ui/desktop-footer/desktop-footer.component';
import { ValidatorBuilder } from 'src/app/ui/validator';

@Component({
  selector: 'app-edit-tag',
  templateUrl: './edit-tag.component.html',
  styleUrls: ['./edit-tag.component.scss']
})
export class EditTagComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly canEdit = this.us.currentUser.permissions?.permissions.tags.edit || this.us.currentUser.permissions?.permissions.tags.create;
  public tagToEdit: Tag | null = null;
  public isNew = false;
  public canDelete = false;
  public inputChanged = false;
  public isSaving = false;
  public companyText = '';
  public footerButtons: FooterButton[] = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.saveChanges() },
    { label: this.trans('general.cancel'),  callback: () => this.backButtonPressed() },
  ];
  public val = new ValidatorBuilder<{ color: string; name: string }>()
    .required('name', this.trans('general.required'))
    .required('color', this.trans('general.required'))
    .regex('color', /(^#[0-9a-fA-F]{6})|(^rgb\(((1?[0-9]{1,2}|2[0-4][0-9]|25[0-5]),){2}(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\))/, this.trans('tags.errors.incorrectColor'))
    .minLength('name', 3, this.trans('validation.min.string').replace(':attribute', this.trans('tags.labels.name')).replace(':min', '3'))
    .minLength('color', 7, this.trans('tags.errors.incorrectColor'))
    .build().bindContext(this.tagToEdit);
  private tagIdChangeSubscription = this.route.params.subscribe((params) => {
    if ( !this.desktopView ) { return; }
    const tagId = Number(params.tagId);
    if ( this.inputChanged || this.es.getId(TEditableComponent.Tag) !== -1) {
      this.revert();
    }
    if (tagId && !isNaN(tagId)) {
      const tag = this.tagService.getTag(tagId);
      if (!tag) {
        throw new Error('Tag not found');
      }
      this.es.beginComponentEdit(TEditableComponent.Tag, tag.id, tag);
    }
    this.reinitVariables();
    this.val.validate(this.tagToEdit);
  });

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super(cdRef);
    this.reinitVariables();
    if ( !this.desktopView ) {
      this.background.setGray();
      this.headerBar.showHeader({
        backUrl: '*',
        headerText: this.isNew ? this.trans('tags.titles.newTag') : this.tagToEdit.name,
        actionButtonText: this.canDelete ? this.trans('general.delete') : undefined,
      });
      if ( this.canEdit ) {
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('settings.buttons.save'), true, false);
        this.headerBar.onBackClicked = () => {
          if ( !this.isNew ) {
            this.revert();
          }
        };
        this.footerBar.onButton1Click = () => {
          if ( !this.isNew ) {
            this.revert();
          }
          this.router.navigate([...this.g.resolveRootRoute(), 'tags'], { replaceUrl: true });
        };
        this.footerBar.onButton2Click = () => {
          this.saveChanges();
        };
      }
      if ( this.canDelete ) {
        this.headerBar.onActionButtonClicked = () => {
          this.doDelete();
        };
      }
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.tagIdChangeSubscription?.unsubscribe();
  }

  private reinitVariables() {
    this.tagToEdit = this.es.getEditableComponent(TEditableComponent.Tag);
    this.isNew = this.tagToEdit === null || this.route.routeConfig.path === 'new';
    if ( this.isNew ) {
      this.tagToEdit = {
        id: 0,
        company_id: this.us.currentUser.company_id,
        name: '',
        description: '',
        color: this.generateNewColor(),
      };
    } else {
      if ( this.tagToEdit.company_id !== 0 ) {
        this.companyText = this.companyService.getCompany(this.tagToEdit.company_id)?.name;
      }
    }
    this.canDelete = this.us.currentUser.permissions?.permissions.tags.delete && !this.isNew;
  }

  private async saveChanges() {
    if ( this.isSaving ) {
      if ( !this.desktopView ) { this.miniStatus.flash(); }
      return;
    }
    if ( ! await this.val.validate(this.tagToEdit) ) { return; }
    this.isSaving = true;
    this.tagToEdit.color = this.tagToEdit.color.toUpperCase();
    if ( !this.desktopView ) { this.miniStatus.show(this.trans('general.saving')); }
    const func = (this.isNew ? requests.tag.addTag : requests.tag.saveTag) as (t: any) => any;
    const data = this.isNew ? { ...this.tagToEdit } : this.tagToEdit;
    func(data).subscribe(
      (res: BasicSuccessResponse | AddTagResponse) => {
        if ( this.isNew ) {
          this.tagToEdit.id = (res as AddTagResponse).id;
          this.tagService.ingestTag(this.tagToEdit);
        }
        this.es.endComponentEdit(TEditableComponent.Tag);
        if ( !this.desktopView ) {
          this.miniStatus.hide();
          this.inputChanged = false;
          this.isSaving = false;
          this.router.navigate([...this.g.resolveRootRoute(), 'tags'], { replaceUrl: true });
        } else {
          setTimeout(() => {
            this.inputChanged = false;
            this.isSaving = false;
            this.router.navigate([...this.g.resolveRootRoute(), 'tags'], { replaceUrl: true });
          }, 1000);
        }
      },
      error => {
        if ( !this.desktopView ) { this.miniStatus.hide(); }
        this.isSaving = false;
      }
    );
  }

  private revert(before?: Tag) {
    if ( !before ) {
      before = this.es.getComponentBeforeModification(TEditableComponent.Tag);
    }
    if ( !before ) { return; } // vadinasi naujas

    const found = this.tagService.tags.get(before.id);
    if ( !found ) { return; }

    const keys = Object.keys(found);
    for ( const iKey of keys ) {
      found[iKey] = before[iKey];
    }
    this.inputChanged = false;
    this.es.endComponentEdit(TEditableComponent.Tag);
  }

  private doDelete() {
    if ( this.isSaving ) {
      if ( !this.desktopView ) { this.miniStatus.flash(); }
      return;
    }
    this.isSaving = true;
    if ( !this.desktopView ) { this.miniStatus.show(this.trans('general.deleting')); }
    requests.tag.deleteTag({id: this.tagToEdit.id}).subscribe(
      result => {
        this.tagService.tags.delete(this.tagToEdit.id);
        this.es.endComponentEdit(TEditableComponent.Tag);
        if ( !this.desktopView ) {
          this.miniStatus.hide();
          this.isSaving = false;
          this.inputChanged = false;
          this.router.navigate([...this.g.resolveRootRoute(), 'tags'], { replaceUrl: true });
        } else {
          setTimeout(() => {
            this.isSaving = false;
            this.inputChanged = false;
            this.router.navigate([...this.g.resolveRootRoute(), 'tags'], { replaceUrl: true });
          }, 1000);
        }
      },
      error => {
        this.isSaving = false;
        if ( !this.desktopView ) { this.miniStatus.hide(); }
      }
    );
  }

  private generateNewColor(): string {
    const usedColors = [...this.tagService.tags.values()].map(t => t.color);
    console.log('our colors', usedColors);
    let finalColor;
    let safeRepeatCounter = 0;
    while ( (!finalColor || usedColors.includes(finalColor)) && safeRepeatCounter < 30 ) {
      safeRepeatCounter++;
      const randomRed = Math.round(Math.random() * 255);
      const randomGreen = Math.round(Math.random() * 255);
      const randomBlue = Math.round(Math.random() * 255);
      finalColor = '#' + randomRed.toString(16).padStart(2, '0').toUpperCase()
                       + randomGreen.toString(16).padStart(2, '0').toUpperCase()
                       + randomBlue.toString(16).padStart(2, '0').toUpperCase();
      console.log('try color ' + finalColor);
    }
    return finalColor ?? '#FFFFFF';
  }

  public changeTextColor(color: string) {
    this.tagToEdit.textColor = TagService.getTextColor(color);
  }

  public backButtonPressed() {
    this.revert();
    if ( this.desktopView ) {
      this.router.navigate([...this.g.resolveRootRoute(), 'tags'], { replaceUrl: true });
    } else {
      history.back();
    }
  }
}
