import { ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { PersistenceService } from 'src/app/api/persistence.service';
import { ItemConfiguration } from 'src/app/company/components/company-columns/company-columns.component';
import { LanguageAware } from 'src/app/general/language-aware';
const defaultColumns = ['default_name', 'active', 'cid_code', 'area_event', 'reaction_id', 'fake:delete'];

@Component({
  selector: 'app-cp-event-descriptions',
  templateUrl: './cp-event-descriptions.component.html',
  styleUrls: ['./cp-event-descriptions.component.scss']
})
export class CpEventDescriptionsComponent extends LanguageAware implements OnInit {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('events.settings.labels.nameEvent'), value: 'default_name' },
    { name: this.trans('general.enabled'), value: 'active' },
    { name: this.trans('events.settings.labels.cidCode'), value: 'cid_code' },
    { name: this.trans('events.settings.labels.areaEvent'), value: 'area_event' },
    { name: this.trans('events.settings.labels.reaction'), value: 'reaction_id' },
    { name: this.trans('general.delete'), value: 'fake:delete' },
  ];
  public readonly columns = this.persistance.bind('event_list_columns', defaultColumns);
  public searchPhrase: string;
  public searchVisible = false;

  constructor(
    cdRef: ChangeDetectorRef,
    private persistance: PersistenceService,
  ) {
    super(cdRef);
  }

  ngOnInit(): void {
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.hideSearch();
  }

  public showSearch(): void {
    this.searchVisible = true;
  }

  public hideSearch(): void {
    if(!this.searchVisible) { return; }
    this.searchVisible = false;
    this.searchPhrase = '';
    const searchInputField = document.getElementById('searchInputField');
    searchInputField.blur();
  }

}
