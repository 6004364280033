import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PgmService } from 'src/app/api/system/pgm.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PgmIconData } from 'src/app/models/pgm';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-pgm-icon-select',
  templateUrl: './pgm-icon-select.component.html',
  styleUrls: ['./pgm-icon-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PgmIconSelectComponent extends LanguageAware implements OnInit {
  public currentIcon = 0;
  public pgmIcons: PgmIconData[] = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
    public pgmS: PgmService,
  ) {
    super(cdRef);
    this.tag = 'PgmIcon';
    this.background.setGray();
    const pgm = this.es.getEditableComponent(TEditableComponent.Output);
    if (pgm === null) {
      this.log('Bandyta įeiti į ikonų langą ne iš PGM lango.');
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.pickIconSet'),
      backUrl: '*',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      history.back();
    };
    this.pgmIcons = [...pgmS.pgmIcons.values()];
    this.currentIcon = pgm.icon;
  }

  ngOnInit(): void {}

  public onIconSelected(iconId: number) {
    const pgm = this.es.getEditableComponent(TEditableComponent.Output);
    if (pgm === null) {
      this.log('Neaišku kodėl, bet pasirinkus ikoną PGM jau neegzistuoja');
    } else {
      pgm.icon = iconId;
    }
    history.back();
  }
}
