import { SafeUrl } from '@angular/platform-browser';
import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToFormData, objectToParams } from './common';

export type EventDescriptionData = {
  id: number;
  qualifier: 'E' | 'R';
  event_code: string;
  default_name: string;
  reaction_id: number;
  active: 0 | 1;
  company_id: number;
  area_event: boolean;
  created_at: string;
  updated_at: string;
};

type SetEventDescriptionRequest = {
  qualifier: 'E' | 'R';
  code: string;
  name: string;
  reaction: number;
  active: number;
  area_event: boolean;
  id?: number;
};

type SetPanicButtonSettingsRequest = {
  grg_host: string;
  grg_port: number;
  grg_code: string;
  grg_zone: string;
  grg_enabled: boolean | 'on' | 'off';
};

type EditIpComRequest = {
  id: number | 0;
  peer_name: string;
  host: string;
  port: number;
  user_name: string;
  password: string;
  own_ip: boolean;
  region: number;
};

type TestIpcomRequest = {
  peer_name: string;
  host: string;
  port: number;
  user_name: string;
  password: string;
};

type RequestAssistanceRequest = {
  accessPeriod: number; // Hours
  describeIssue: string;
  id: number; // Installer ID
  systemId: number;
};

type GetAssistanceDataResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      data: {
        installer_id: number;
        system_id: number;
        transferred: boolean;
        access_until: string;
        issue: string;
      }[];
    });

export type Company = {
  id: number;
  name: string;
  address: string;
  logo: string | null | SafeUrl;
  phone: string;
  email: string;
  country: string;
  vat_id: string;
  registration_email: string;
  owner_id: number;
  active: boolean;
  city: string;
  alias: string;
  assigned_systems?: number;
};

type AddCompanyRequest = {
  name: string;
  address: string;
  phone: string;
  email: string;
  country: string;
  vat_id: string;
  registration_email: string;
  owner_id?: number;
  city: string;
  owner_name: string;
  owner_last_name: string;
  owner_phone: string;
};

type GetCompaniesRequest = {
  searchPhrase?: string;
  searchFields?: string[];
  paginationPage?: number;
  include_statistics?: boolean;
  forList?: boolean; // TRUE gražins tik { id, name }[]
};

type GetCompaniesResponse = BasicSuccessResponse & {
  list: {
    total: number;
    per_page: number;
    current_page: number;
    last_page: number;
    next_page_url: string | null;
    prev_page_url: string | null;
    from: number;
    to: number;
    data: Company[];
  };
};

type GetCompanyRequest = { id: number };
type DeleteIpComRequest = { id: number };
type DeleteCompanyRequest = { id: number };
type DeleteEventDescriptionRequest = { id: number };
type GetAssistanceDataRequest = { systemId: number };
type RemoveCompanyLogoRequest = { company_id: number };
type GetEventDescriptionsRequest = { forList?: boolean };
type SaveCompanyRequest = Exclude<Company, 'logo' | 'alias'>;
type DeleteIpComResponse = ErrorResponse | BasicSuccessResponse;
type RevokeAssistanceResponse = ErrorResponse | BasicSuccessResponse;
type RemoveCompanyLogoResponse = ErrorResponse | BasicSuccessResponse;
type RequestAssistanceResponse = ErrorResponse | BasicSuccessResponse;
type GetCompanyResponse = BasicSuccessResponse & { company: Company };
type SaveCompanyLogoRequest = { company_id: number; logo_image: Blob };
type DeleteEventDescriptionResponse = ErrorResponse | BasicSuccessResponse;
type SetPanicButtonSettingsResponse = ErrorResponse | BasicSuccessResponse;
export type SaveCompanyLogoResponse = BasicSuccessResponse & { logoUrl: string };
type EditIpComResponse = ErrorResponse | (BasicSuccessResponse & { id: number });
type RevokeAssistanceRequest = { id: number; /* Installer ID */ systemId: number };
export type AddCompanyResponse = BasicSuccessResponse & { id: number; alias: string };
type TestIpcomResponse = ErrorResponse | (BasicSuccessResponse & { status: boolean });
type GetEventDescriptionsResponse = ErrorResponse
                                    | (BasicSuccessResponse & { list: EventDescriptionData[] })
                                    | (BasicSuccessResponse & { list: { id: number; default_name: string }[] });
type SetEventDescriptionResponse = ErrorResponse | BasicSuccessResponse | (BasicSuccessResponse & { newId: number });

const addCompany = (req: AddCompanyRequest) => http.post<AddCompanyResponse, AddCompanyRequest>('/v3/api/company', req);
const saveCompany = (req: SaveCompanyRequest) => http.put<BasicSuccessResponse, SaveCompanyRequest>('/v3/api/company', req);
const getCompany = (req: GetCompanyRequest) => http.get<GetCompanyResponse, URLSearchParams>('/v3/api/company', objectToParams(req));
const deleteCompany = (req: DeleteCompanyRequest) => http.delete<BasicSuccessResponse, DeleteCompanyRequest>('/v3/api/company', req);
const getCompanies = (req: GetCompaniesRequest) => http.get<GetCompaniesResponse, URLSearchParams>('/v3/api/companies', objectToParams(req));
const saveCompanyLogo = (req: SaveCompanyLogoRequest) => http.post<SaveCompanyLogoResponse, FormData>('/v3/api/company/logo', objectToFormData(req));
const removeCompanyLogo = (req: RemoveCompanyLogoRequest) => http.delete<RemoveCompanyLogoResponse, RemoveCompanyLogoRequest>('/v3/api/company/logo', req);
export const revokeAssistance = (req: RevokeAssistanceRequest) => http.post<RevokeAssistanceResponse, RevokeAssistanceRequest>('/v3/api/revoke-installer', req);
export const requestAssistance = (req: RequestAssistanceRequest) => http.post<RequestAssistanceResponse, RequestAssistanceRequest>('/v3/api/installer-assistance', req);
export const getAssistanceData = (req: GetAssistanceDataRequest) => http.get<GetAssistanceDataResponse, URLSearchParams>('/v3/api/installer-assistance', objectToParams(req));
export const setPanicButtonSettings = (req: SetPanicButtonSettingsRequest) => http.post<SetPanicButtonSettingsResponse, SetPanicButtonSettingsRequest>('/v3/api/edit/panic-button', req);

// @TODO perkelti į nuosavą failą "ipcom"
export const editIpCom = (req: EditIpComRequest) => http.post<EditIpComResponse, EditIpComRequest>('/v3/api/edit/ipcom', req);
export const testIpcom = (req: TestIpcomRequest) => http.post<TestIpcomResponse, TestIpcomRequest>('/v3/api/test-ipcom', req);
export const deleteIpCom = (req: DeleteIpComRequest) => http.post<DeleteIpComResponse, DeleteIpComRequest>('/v3/api/delete/ipcom', req);

// @TODO perkelti į nuosavą failą "event_description"
export const getEventDescriptions = (req: GetEventDescriptionsRequest) => http.post<GetEventDescriptionsResponse, GetEventDescriptionsRequest>('/v3/api/get-event-descriptions', req);
export const setEventDescription = (req: SetEventDescriptionRequest) => http.post<SetEventDescriptionResponse, SetEventDescriptionRequest>('/v3/api/edit/event-description', req);
export const deleteEventDescription = (req: DeleteEventDescriptionRequest) => http.post<DeleteEventDescriptionResponse, DeleteEventDescriptionRequest>('/v3/api/delete/event-description', req);

export default {
  getCompany,
  addCompany,
  saveCompany,
  getCompanies,
  deleteCompany,
  saveCompanyLogo,
  removeCompanyLogo,
};
