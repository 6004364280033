import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionRole } from 'src/api/v3/common';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { EventDescriptionService } from 'src/app/services/event-description.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { RefreshService } from 'src/app/services/refresh.service';
import { AppSettings } from 'src/environments/environment';

@Component({
  selector: 'app-cp-edit-reaction',
  templateUrl: './cp-edit-reaction.component.html',
  styleUrls: ['./cp-edit-reaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CpEditReactionComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public reactionItem = null;
  private originalAssignements = [];
  public initialAssignments = new Set();
  private assignments = new Set();
  private backUrl = '';
  public fileToUploadUrl: any = null;
  private loadedIconSize = null;
  private fileToUpload: File | null = null;
  public iconChanged = false;
  public readonly isNew = this.ar.routeConfig.path === 'new';
  private subscriptions: Subscription[] = [];
  public inputChanged = false;
  public isRequestLoading = false;
  private delayDuration = 2000;
  public readonly footerButtons = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.saveReactionDesktop() },
    { label: this.trans('general.cancel'),  callback: () => this.navigateBackDesktop() },
  ];
  private original = null;
  public canEdit = this.us.currentUser.permissions?.permissions.reactions.edit || this.us.currentUser.permissions?.permissions.reactions.create;
  private reactionIdChangeSubscription = this.ar.params.subscribe((params) => {
    if ( !this.desktopView ) { return; }
    const reactionId = Number(params.reactionId);
    if ( this.inputChanged || this.es.getId(TEditableComponent.Reaction) !== -1) {
      this.revert();
    }
    if (reactionId && !isNaN(reactionId)) {
      const reaction = this.us.currentUser.reactions.find(r => r.id === reactionId);
      if (!reaction) {
        throw new Error('Reaction not found');
      }
      this.es.beginComponentEdit(TEditableComponent.Reaction, reaction.id, reaction);
    }
    this.reinitVariables();
  });

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private ar: ActivatedRoute,
    private es: EditSystemService,
    private sanitizer: DomSanitizer,
    private messagebox: MessageboxService,
    private rs: RefreshService,
    public eventDescription: EventDescriptionService
  ) {
    super(cdRef);
    this.reinitVariables();
    this.backUrl = this.desktopView ? companyRootRoute + '/settings' + '/reaction-types': this.g.getHomeUrl().join('/') + '/edit-reactions';
    if (this.reactionItem === null) {
      this.router.navigate([this.backUrl]);
      return;
    }
    if ( !this.desktopView ) {
      this.background.setGray();
      this.headerBar.showHeader({
        headerText: this.trans('events.settings.labels.reaction'),
        backUrl: this.backUrl,
        actionButtonText: this.reactionItem.id === 0 || !this.us.currentUser.permissions?.permissions.reactions.delete ? undefined : this.trans('general.delete'),
      });
      this.headerBar.onActionButtonClicked = () => {
        this.doDelete();
        this.router.navigate([this.backUrl]);
      };
      if ( this.canEdit ) {
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
        this.footerBar.onButton1Click = () => {
          this.revert();
          this.es.endComponentEdit(TEditableComponent.Reaction);
          this.router.navigate([this.backUrl]);
        };
        this.footerBar.onButton2Click = () => {
          this.doSave();
        };
      }
      this.headerBar.onBackClicked = () => {
        this.revert();
        this.es.endComponentEdit(TEditableComponent.Reaction);
      };
    }
    this.loadDescriptions();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if(this.inputChanged) {
      this.revert();
    }
    this.es.endComponentEdit(TEditableComponent.Reaction);
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
    this.reactionIdChangeSubscription?.unsubscribe();
  }

  private loadDescriptions() {
    if (this.eventDescription.eventDescriptions.length > 0) {
      if (this.reactionItem.id !== 0) {
        this.populateAssignements();
      }
      return;
    }
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/get-event-descriptions', {}, true).subscribe(
      (result) => {
        if (result.success) {
          this.eventDescription.eventDescriptions = result.list;
          if (this.reactionItem.id !== 0) {
            this.populateAssignements();
          }
        } else {
          this.messagebox.open({
            buttons: this.messagebox.buttons.Ok,
            headerText: this.trans('general.titleError'),
            iconType: this.messagebox.iconType.Error,
            messageContent: result.error ? result.error : this.trans('general.errors.errorOccurred')
          });
        }
        this.miniStatus.hide();
      },
      (error) => {
        this.messagebox.open({
          buttons: this.messagebox.buttons.Ok,
          headerText: this.trans('general.titleError'),
          iconType: this.messagebox.iconType.Error,
          messageContent: this.trans('auth.errors.serverSideError')
        });
        this.miniStatus.hide();
      }
    );
  }

  private populateAssignements() {
    this.originalAssignements = [];
    this.initialAssignments.clear();
    for (const iDesc of this.eventDescription.eventDescriptions) {
      this.originalAssignements.push({
        descId: iDesc.id,
        reactionId: iDesc.reaction_id,
      });
      if(iDesc.reaction_id === this.reactionItem.id) {
        this.initialAssignments.add(iDesc.id);
      }
    }
    this.assignments = new Set([...this.initialAssignments]);
  }

  public doDelete() {
    const reactionId = this.es.getId(TEditableComponent.Reaction);
    if(!reactionId) { return; }
    this.us.currentUser.reactions.splice(
      this.us.currentUser.reactions.findIndex((r) => r.id === reactionId),
      1
    );
    this.api.post('/delete/reaction', { reaction_id: reactionId }, true).subscribe(
      async (result) => {
        if (!result.success) {
          this.messagebox.open({
            buttons: this.messagebox.buttons.Ok,
            headerText: this.trans('general.titleError'),
            iconType: this.messagebox.iconType.Error,
            messageContent: result.error
          });
          this.revert();
        } else {
          await new Promise<void>(resolve => {
            const descToUpdate = this.eventDescription.eventDescriptions.filter((ed) => ed.reaction_id === reactionId);
            for (const iDesc of descToUpdate) {
              iDesc.reaction_id = 0;
            }
            this.rs.doSilentRefresh();
            setTimeout(() => {
              resolve();
            }, 1000);
          });
          if(this.desktopView && AppSettings.companyDesktopEnabled) {
            this.us.refreshReactionListChange(true);
            this.router.navigate([companyRootRoute, 'settings', 'reaction-types']);
          }
        }
        this.es.endComponentEdit(TEditableComponent.Reaction);
      },
      (error) => {
        this.messagebox.open({
          buttons: this.messagebox.buttons.Ok,
          headerText: this.trans('general.titleError'),
          iconType: this.messagebox.iconType.Error,
          messageContent: this.trans('auth.errors.serverSideError')
        });
        this.revert();
        this.es.endComponentEdit(TEditableComponent.Reaction);
      }
    );
  }

  private async doSave() {
    if (this.iconChanged) {
      if (this.loadedIconSize !== null) {
        if (this.loadedIconSize.width !== this.loadedIconSize.height) {
          this.messagebox.open({
            buttons: this.messagebox.buttons.Ok,
            headerText: this.trans('general.titleError'),
            iconType: this.messagebox.iconType.Error,
            messageContent: this.trans('settings.errors.incorrectImageSize')
          });
          return false;
        }
      }
      if (this.miniStatus.isVisible()) {
        this.miniStatus.flash();
        return;
      }
      this.miniStatus.show(this.trans('general.pleaseWait'));
    }

    const that = this;
    if (this.reactionItem.id === 0) {
      this.us.currentUser.reactions.push(that.reactionItem);
    }
    const reactionId = this.es.getId(TEditableComponent.Reaction);
    const assignedDescriptions = this.eventDescription.eventDescriptions.filter((ed) => ed.reaction_id === reactionId).map((edd) => edd.id);
    this.reactionItem.assignedDescriptions = assignedDescriptions;
    this.api.post('/edit/reaction', that.reactionItem, true).subscribe(
      async (result) => {
        if (result.success) {
          const reactionToUpdate = that.us.currentUser.reactions.find((r) => r.id === reactionId);
          reactionToUpdate.id = result.id;
          const descToUpdate = that.eventDescription.eventDescriptions.filter((ed) => ed.reaction_id === reactionId);
          for (const iDesc of descToUpdate) {
            iDesc.reaction_id = result.id;
          }
          this.rs.doSilentRefresh();
          if (that.iconChanged) {
            that.uploadIcon();
          } else {
            if(!this.desktopView) {
              that.es.endComponentEdit(TEditableComponent.Reaction);
            }
          }
          await new Promise<void>(resolve => {
            this.rs.doSilentRefresh();
            setTimeout(() => {
              resolve();
            }, this.delayDuration);
          });
          if(this.desktopView && this.isNew) {
            this.us.refreshReactionListChange(true);
            this.router.navigate([companyRootRoute, 'settings', 'reaction-types']);
          };
        } else {
          that.messagebox.open({
            buttons: that.messagebox.buttons.Ok,
            headerText: that.trans('general.titleError'),
            iconType: that.messagebox.iconType.Error,
            messageContent: result.error
          });
          that.revert();
          this.miniStatus.hide();
        }
        if(!this.desktopView) {
          that.es.endComponentEdit(TEditableComponent.Reaction);
        }
      },
      (error) => {
        that.messagebox.open({
          buttons: that.messagebox.buttons.Ok,
          headerText: that.trans('general.titleError'),
          iconType: that.messagebox.iconType.Error,
          messageContent: that.trans('auth.errors.serverSideError')
        });
        that.miniStatus.hide();
        that.revert();
        if(!this.desktopView) {
          that.es.endComponentEdit(TEditableComponent.Reaction);
        }
      }
    );
    if (this.desktopView) {
      await this.reactionItemSaved();
    } else {
      if (!this.iconChanged) {
        that.es.endComponentEdit(TEditableComponent.Reaction);
        that.router.navigate([this.backUrl]);
      }
    }
  }

  private uploadIcon() {
    const that = this;
    const headers = this.api.getAuthorizationHeader();
    const formData = new FormData();
    formData.append('id', this.reactionItem.id);
    if (this.iconChanged) {
      headers.append('Content-type', 'multipart/form-data');
      formData.append('image', this.fileToUpload, this.fileToUpload.name);
    }
    this.api
      .httpSender()
      .post(this.api.getUrl('/reaction/icon'), formData, { headers })
      .subscribe(
        (result) => {
          if ((result as any).success) {
            that.reactionItem.image = that.helper.getBaseNoPath() + '/' + (result as any).icon;
            that.us.saveCurrentUser();
            that.es.endComponentEdit(TEditableComponent.Reaction);
            that.router.navigate([this.backUrl]);
          }
          that.miniStatus.hide();
        },
        (error) => {
          that.messagebox.open({
            buttons: that.messagebox.buttons.Ok,
            headerText: that.trans('general.titleError'),
            iconType: that.messagebox.iconType.Error,
            messageContent: that.trans('auth.errors.serverSideError')
          });
          that.miniStatus.hide();
        }
      );
  }

  private revert() {
    for (const iAssignement of this.originalAssignements) {
      const found = this.eventDescription.eventDescriptions.find((ed) => ed.id === iAssignement.descId);
      if (found) {
        found.reaction_id = iAssignement.reactionId;
      }
    }
    const reactionId = this.es.getId(TEditableComponent.Reaction);
    if (reactionId !== -1) {
      const foundReaction = this.us.currentUser.reactions.find((r) => r.id === reactionId);
      if (foundReaction === undefined) {
        this.us.currentUser.reactions.push(this.es.getComponentBeforeModification(TEditableComponent.Reaction));
      } else {
        const original = this.es.getComponentBeforeModification(TEditableComponent.Reaction);
        foundReaction.name = original.name;
        foundReaction.default_name = original.default_name;
        foundReaction.custom_sound = original.custom_sound;
        foundReaction.active = original.active;
      }
    } else {
      const indexFound = this.us.currentUser.reactions.findIndex((r) => r.id === reactionId);
      if (indexFound === -1) {
        return;
      }
      this.us.currentUser.reactions.splice(indexFound, 1);
    }
  }

  public eventChecked(ed: any) {
    if ( !this.canEdit ) { return; }
    if (ed.reaction_id === this.reactionItem.id) {
      ed.reaction_id = 0;
      this.assignments.delete(ed.id);
    } else {
      ed.reaction_id = this.reactionItem.id;
      this.assignments.add(ed.id);
    }
    this.onInputChange();
  }

  public openEvent(event: any) {
    this.es.beginComponentEdit(TEditableComponent.EventDescription, event.id, event);
    this.router.navigate([...this.g.getHomeUrl(), 'edit-event']);
  }

  public addEvent() {
    this.es.beginComponentEdit(TEditableComponent.EventDescription, 0, {
      default_name: '',
      id: 0,
      qualifier: 'E',
      event_code: '000',
      reaction_id: this.reactionItem.id,
      active: false,
      company_id: this.us.currentUser.company_id,
      area_event: true,
    });
    if(this.desktopView) {
      this.router.navigate([companyRootRoute, 'settings', 'event-descriptions', 'edit']);
    } else {
      this.router.navigate([...this.g.getHomeUrl(), 'edit-event']);
    }
  }

  public setName(value: string) {
    this.reactionItem.name = value;
    if (this.us.currentUser.permissions?.role !== PermissionRole.SuperAdmin) {
      this.reactionItem.default_name = false;
    }
  }

  public handleNewFile(fileArray: FileList) {
    const file = fileArray.item(0);
    const mimeType = file.type;
    if (!mimeType.startsWith('image/svg')) {
      this.messagebox.open({
        buttons: this.messagebox.buttons.Ok,
        headerText: this.trans('general.titleError'),
        iconType: this.messagebox.iconType.Error,
        messageContent: this.trans('settings.errors.notAnSvg')
      });
      return;
    }
    const that = this;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ($event) => {
      that.fileToUploadUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
    };
    this.fileToUpload = file;
    this.iconChanged = true;
  }

  public imageLoaded(img) {
    this.loadedIconSize = {
      width: img.naturalWidth,
      height: img.naturalHeight,
    };
  }

  public onInputChange(): void {
    const initAssignments = Array.from(this.initialAssignments).sort();
    const modifiedAssignments = Array.from(this.assignments).sort();
    if(JSON.stringify(this.original) !== JSON.stringify(this.reactionItem) || JSON.stringify(initAssignments) !== JSON.stringify(modifiedAssignments) ) {
      this.inputChanged = true;
    } else {
      this.inputChanged = false;
    }
  }

  public onDeleteClick(): void {
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('settings.reactions.settings.deleteReactionInfo').replace(':reaction', this.reactionItem.name)}: ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.doDelete();
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async saveReactionDesktop() {
    this.isRequestLoading = true;
    await this.doSave();
    this.isRequestLoading = false;
  }

  private navigateBackDesktop() {
    this.revert();
    this.es.endComponentEdit(TEditableComponent.Reaction);
    this.router.navigate([companyRootRoute, 'settings', 'reaction-types']);
  }

  private async reactionItemSaved() {
    setTimeout(() => {
      this.isRequestLoading = false;
    }, 1000);
    this.original = Object.assign({}, this.reactionItem);
    this.initialAssignments = new Set([...this.assignments]);
    await new Promise<void>(() => setTimeout(() => {
      this.onInputChange();
    }, this.delayDuration));
  }

  private reinitVariables() {
    if(this.desktopView && this.isNew) {
      this.es.beginComponentEdit(TEditableComponent.Reaction, 0, {
        id: 0,
        monas_id: 0,
        name: '',
        active: false,
        custom_sound: 0,
        company_id: this.us.currentUser.company_id,
        group_id: 0,
        image: '',
        image_png: '',
        default_name: this.us.currentUser.permissions?.role !== PermissionRole.SuperAdmin ? false : true,
        redirect_to: 0,
      });
    }
    this.reactionItem = this.es.getEditableComponent(TEditableComponent.Reaction);
    this.original = this.es.getComponentBeforeModification(TEditableComponent.Reaction);

    this.loadDescriptions();
  }

}
