import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LoggerService } from 'src/app/api/logger.service';
import { PlatformService } from 'src/app/api/platform.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit, OnDestroy {
  private overlayItem: Element = null;
  private reenableRefresher = false;
  /**
   * Identifikatorius, kad keli popup nesusimaišytų
   */
  @Input() id = '';

  constructor(private l: LoggerService, private pl: PlatformService, private refresher: RefreshService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.overlayItem?.parentNode.removeChild(this.overlayItem);
  }

  public showMenu() {
    this.reenableRefresher = this.refresher.refresherIsEnabled;
    this.refresher.disableRefresher();
    this.overlayItem = document.querySelector('#context-button-popup-overlay-' + this.id);
    this.overlayItem.parentNode.removeChild(this.overlayItem);
    const mobileAppHTML = document.querySelector('.mobile-app') as HTMLElement;
    mobileAppHTML.appendChild(this.overlayItem);
    let offsetTop = (document.querySelector('#context-button-' + this.id) as HTMLElement).offsetTop;
    let offsetLeft = (document.querySelector('#context-button-' + this.id) as HTMLElement).offsetLeft;
    this.l.log('Context ' + this.id + ' top:left', 'ContextMenu', {
      top: offsetTop,
      left: offsetLeft,
    });
    offsetTop += 30;
    (this.overlayItem as HTMLElement).style.display = 'block';
    const thePopup = document.querySelector('#context-button-popup-' + this.id) as HTMLElement;
    const availableWidth = this.pl.isDesktop() ? (this.overlayItem as HTMLElement).offsetWidth : screen.availWidth;
    const availableHeight = this.pl.isDesktop() ? (this.overlayItem as HTMLElement).offsetHeight : screen.availHeight;
    if (offsetLeft + thePopup.offsetWidth > availableWidth) {
      this.l.log('Popup iseina uz ekrano desinio krasto ribu', 'ContextMenu');
      offsetLeft = availableWidth - thePopup.offsetWidth;
    }
    if (offsetLeft < 0) {
      this.l.log('Popup iseina uz ekrano kairio krasto ribu', 'ContextMenu');
      offsetLeft = 0;
    }
    if (offsetTop + thePopup.offsetHeight > availableHeight) {
      this.l.log('Popup iseina uz ekrano apacios ribu', 'ContextMenu');
      offsetTop = availableHeight - thePopup.offsetHeight;
      offsetTop -= 30; // Dar atimam tai, kas buvo prideta.
      offsetTop -= 5; // Dar papildomai atimam, kad butu mazas tarpelis apazioj nuo popup iki tritaskio mygtuko.
    }
    if (offsetTop < 0) {
      this.l.log('Popup iseins uz ekrano virsutines ribos', 'ContextMenu');
      // dar kazka reik del to padaryt
    }
    thePopup.style.left = offsetLeft + 'px';
    thePopup.style.top = offsetTop + 'px';
  }

  public hideMenu() {
    this.l.log('hide menu', 'ContextMenu');
    if (this.reenableRefresher) {
      this.refresher.enableRefresher();
    }
    (document.querySelector('#context-button-popup-overlay-' + this.id) as HTMLElement).style.display = 'none';
    this.overlayItem.parentNode.removeChild(this.overlayItem);
    const container = document.querySelector('#context-container-' + this.id);
    container.appendChild(this.overlayItem);
  }
}
