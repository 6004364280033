import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Tag } from 'src/api/v3/common';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { FilterCriteria } from 'src/app/company/pages/company-users/company-users.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent extends LanguageAware implements OnInit {
  public readonly canAdd = this.us.currentUser.permissions?.permissions.tags.create;
  public readonly canDelete = this.us.currentUser.permissions?.permissions.tags.delete;
  public readonly desktopView = this.platform.isDesktopView();
  public columns = this.persistenceService.bind('tag_list_columns', ['name', 'fake:arrow']);

  @Input() public filterCriteria: FilterCriteria = null;
  @Input() loader: DataTableGetter<Tag>;
  @Output() public modifyColumns = new EventEmitter<void>();

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
  ) {
    super(cdRef);
    if ( !this.desktopView ) {
      this.columns.value = ['name', 'fake:arrow'];
      this.background.setGray();
      this.headerBar.showHeader({
        backUrl: this.g.getMobileSettingsUrl().join('/'),
        headerText: this.trans('tags.titles.list'),
        showFind: false,
      });
      this.footerBar.hideFooter();
    }
  }

  ngOnInit(): void {
    if ( !this.loader ) {
      this.loader = this.tagService.getTagsGetter(this.filterCriteria);
    }
  }

  public addTag() {
    this.es.beginComponentEdit(TEditableComponent.Tag, 0, null);
    this.router.navigate([...this.g.getHomeUrl(), 'tags', 'new']);
  }
}
