<div class="white_round">
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.information()' routerLink='/system-information'>{{trans('systems.menu.systemInformation')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.notificationSettings()' routerLink='/system-notifications'>{{trans('systems.menu.notifications')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.areas()' routerLink='/system-area-list'>{{systems.activeSystem.hwType === 'FC' ? trans('systems.titles.groups') : trans('systems.titles.partitions')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.zones()' routerLink='/system-zone-list'>{{trans('systems.titles.zones')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.outputs()' routerLink='/system-pgm-list'>{{trans('systems.menu.outputs')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.sensors()' routerLink='/system-sensor-list'>{{trans('systems.titles.sensors')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.resetFireSensors()' routerLink='/reset-fire-sensors'>{{trans('systems.titles.resetFireSensors')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.users()' routerLink='/users'>{{trans('systems.titles.users')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.cameras() || systems.activeSystemHelper.can.add.cameras()' routerLink='/camera-list'>{{trans('systems.menu.myCameras')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.advancedSettings()' routerLink='/advanced-settings'>{{trans('systems.menu.advancedSettings')}}</app-button-single-line-arrow>
</div>
<ng-container *ngIf='systems.activeSystemHelper.can.transferOwnership() || systems.activeSystemHelper.can.transferDevice() || systems.activeSystemHelper.can.transferToCompany()'>
    <div class="separator32"></div>
    <div class="white_round">
        <app-button-single-line-arrow (click)="goToTransfer()">{{trans('systems.menu.transferSystem')}}</app-button-single-line-arrow>
    </div>
</ng-container>
<div class="separator32"></div>
<div class="white_round" *ngIf="systems.activeSystemHelper.can.synchronize() || systems.activeSystemHelper.can.delete.system()">
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.synchronize()' [icon]="'assets/images/question_round.svg'" [routerLink]="'/reread'">{{trans('systems.titles.outOfSync')}}</app-button-single-line-arrow>
    <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.delete.system()' routerLink='/system-delete'>{{trans('systems.titles.removeSystem')}}</app-button-single-line-arrow>
</div>
<div class="separator32"></div>
