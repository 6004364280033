import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { SystemFindPopupComponent } from 'src/app/popups/system-find-popup/system-find-popup.component';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-cp-filtered-systems',
  templateUrl: './cp-filtered-systems.component.html',
  styleUrls: ['./cp-filtered-systems.component.scss'],
})
export class CpFilteredSystemsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router, private pp: PopupService) {
    super(cdRef);
    this.headerBar.showHeader({
      showLogo: true,
      backUrl: this.g.getHomeUrl().join('/'),
    });
    this.background.setGray();
    const that = this;
    this.headerBar.onBackClicked = () => {
      that.systems.filteredSystems = [];
    };
  }

  ngOnInit(): void {}

  public openFind() {
    this.pp.openPopup(SystemFindPopupComponent, { attachToClass: 'mobile-app' });
  }

  public close() {
    this.systems.filteredSystems = [];
    this.router.navigate(this.g.getHomeUrl());
  }
}
