import { Injectable, Injector, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AppSettings } from 'src/app-settings';
import { accounts, CredentialResponse } from 'google-one-tap';
import { Subject } from 'rxjs';
import { LocaleService } from './locale.service';
import { GoogleAuthService } from './google-auth.service';
import { LoginService } from '../login/login.service';
import { autoinject } from 'src/shim';
import { LocatorService } from '../services/locator.service';

@Injectable()
export class GoogleAuthWebService implements GoogleAuthService, OnDestroy {
  private credentialResponseSubject = new Subject<CredentialResponse>();
  signIn() {
    this.google.accounts.id.prompt();
  }
  signOut(arg0: boolean): Promise<any> {
    this.google?.accounts.id.disableAutoSelect();
    return Promise.resolve();
  }
  private pendingCallbacks: (() => void)[] = [];
  public readonly supportsOneTap = true;
  private google = (window as any).google as { accounts: accounts };
  private document: Document;
  private ls: LocaleService;
  private get login() { return autoinject(LocatorService.injector, LoginService); }
  private loginSucessSubsricbtion = this.credentialResponseSubject.subscribe((res) => {
    this.login.handleGoogleLoginSuccess(res.credential);
  });
  constructor(private injector: Injector) {
    this.document = injector.get(DOCUMENT);
    this.ls = injector.get(LocaleService);
    this.addScript('https://accounts.google.com/gsi/client', () => {
      this.google = (window as any).google as { accounts: accounts };
      this.google.accounts.id.initialize({
        client_id: AppSettings.googleClientIdWeb,
        ux_mode: 'popup',
        callback: (response) => {
          this.credentialResponseSubject.next(response);
        },
        itp_support: true,
      } as any);
      this.pendingCallbacks.forEach((c) => c());
      this.pendingCallbacks = [];
    });
  }

  private addScript(url: string, onLoaded?: () => void) {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    if (onLoaded) {
      script.onload = onLoaded;
    }
    this.document.body.appendChild(script);
  }

  public whenReady(): Promise<void>;
  public whenReady(callback: () => void): void;
  public whenReady(callback?: () => void): void | Promise<void> {
    if (!callback) {
      return new Promise((resolve) => {
        this.whenReady(resolve);
      });
    }
    if (this.google) {
      callback();
    } else {
      this.pendingCallbacks.push(callback);
    }
  }

  public renderButton(container: HTMLElement) {
    this.google.accounts.id.renderButton(container, {
      theme: 'outline',
      size: 'large',
      type: 'standard',
      shape: 'pill',
      locale: this.ls.locale,
      width: '100%' as unknown as number,
    });
  }

  ngOnDestroy(): void {
    this.loginSucessSubsricbtion.unsubscribe();
  }
}
