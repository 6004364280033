import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { G16 } from 'src/app/configurators/g16/src/app/models/g16-devices';
import { dualModeDevices, gFamily } from 'src/app/services/global.service';
import { DeviceQuickSettings, NewSystemService } from 'src/app/services/new-system.service';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-device-quick-settings',
  templateUrl: './device-quick-settings.component.html',
  styleUrls: ['./device-quick-settings.component.scss']
})
export class DeviceQuickSettingsComponent extends LanguageAware implements OnInit {
  public gFamily = gFamily;
  public dualModeDevices = dualModeDevices;
  private moduleIdEditedByHand = false;
  public readonly trk8Device = this.ns.deviceSettings.hwId === G16.G16T_47 || this.ns.deviceSettings.hwId === G16.GET || this.ns.deviceSettings.hwId === G16.G16T_50;
  public val = new ValidatorBuilder<{
    objectId: string;
    pcCode: string;
    moduleId: string;
  }>()
    .maxLength(
      'objectId',
      this.trk8Device ? 6 : 4,
      this.trans('validation.max.string').replace(':attribute', this.trans('systems.labels.deviceSetupSettings.objectId')).replace(':max', this.trk8Device ? '6' : '4')
    )
    .regex('objectId', /^[0-9a-fA-F]*$/, `${this.trans('general.invalidData')}`)
    .required('objectId', this.trans('general.required'))
    .maxLength(
      'pcCode',
      4,
      this.trans('validation.max.string')
        .replace(':attribute', this.trans('systems.labels.deviceSetupSettings.' + (this.ns.selectedManufacturer?.name === 'PARADOX' ? 'pcCode' : 'pcCodeTexecom')))
        .replace(':max', '4')
    )
    .minLength(
      'pcCode',
      4,
      this.trans('validation.min.string')
        .replace(':attribute', this.trans('systems.labels.deviceSetupSettings.' + (this.ns.selectedManufacturer?.name === 'PARADOX' ? 'pcCode' : 'pcCodeTexecom')))
        .replace(':min', '4')
    )
    .regex('pcCode', /^[0-9]*$/, `${this.trans('general.invalidData')}`)
    .required('pcCode', this.trans('general.required'))
    .useOnlyWhen((ds: DeviceQuickSettings) => ds.remoteControl && this.ns.selectedPanel?.pcCodeNeeded)
    .regex('moduleId', /^[0-9a-fA-F]*$/, this.trans('general.invalidData'))
    .maxLength(
      'moduleId',
      10,
      this.trans('validation.max.string')
        .replace(':attribute', this.trans('systems.labels.deviceSetupSettings.moduleId'))
        .replace(':max', '10')
    )
    .build().bindContext(this.ns.deviceSettings);

  constructor(
    cdRef: ChangeDetectorRef,
    public ns: NewSystemService,
    private router: Router,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    if ( !this.ns.isSystemBeingCreated() ) {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.deviceSettings'),
      backUrl: '*',
    });
    this.background.setGray();
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.next'), true, false);
    this.footerBar.onButton1Click = () => {
      this.router.navigate(this.g.getHomeUrl(), { replaceUrl: true });
    };
    this.footerBar.onButton2Click = () => {
      this.proceed();
    };
  }

  ngOnInit(): void {
  }

  public async proceed() {
    if ( ! await this.val.validate(this.ns.deviceSettings) ) {
      return;
    }

    this.router.navigate(['write'], { relativeTo: this.r, replaceUrl: true });
  }

  public onObjectIdChanged() {
    if ( !this.moduleIdEditedByHand ) {
      this.ns.deviceSettings.moduleId = this.ns.deviceSettings.objectId;
    }
  }

  public onModuleIdChanged() {
    this.moduleIdEditedByHand = true;
  }
}
