<div class="page">
  <app-company-header></app-company-header>
  <div class="mainContainer">
      <div [ngClass]="{'page-grid': true,
        'page-grid-one': columnCount === 1,
        'page-grid-two': columnCount === 2,
        'page-grid-three': columnCount === 3
      }">
        <ng-container *ngIf="columnCount !== 1">
          <div class="sidebar">
              <app-cp-system-list-item *ngFor="let iSystem of systems.systems; let i = index" [systemItem]='iSystem' [showBookmark]="false" [showArrow]="false"  [ngClass]="{'selected': systems.activeSystem?.id === iSystem.id}"></app-cp-system-list-item>
          </div>
        </ng-container>
        <ng-container *ngIf="columnCount === 2"><div></div></ng-container>
        <ng-container *ngIf="columnCount === 3">
          <ng-container *ngIf="!isCenterRouteActive; else routerCenter">
            <div class="column">
              <h4>{{trans('systems.titles.menu')}}</h4>
              <div class='white_round' (click)="setRef()">
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.information()' routerLink='/system-information'>{{trans('systems.menu.systemInformation')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf="systems.activeSystemHelper.can.see.notificationSettings()" routerLink='/system-notifications'>{{trans('systems.menu.notifications')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.areas()' routerLink='/system-area-list'>{{ systems.activeSystem.hwType === 'FC' ? trans('systems.titles.groups') : trans('systems.titles.partitions') }}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.zones()' routerLink='/system-zone-list'>{{trans('systems.titles.zones')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.outputs()' routerLink='/system-pgm-list'>{{trans('systems.menu.outputs')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.sensors()' routerLink='/system-sensor-list'>{{trans('systems.titles.sensors')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.resetFireSensors()' routerLink='/reset-fire-sensors'>{{trans('systems.titles.resetFireSensors')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.users()' routerLink='/users'>{{trans('systems.titles.users')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.cameras() || systems.activeSystemHelper.can.add.cameras()' routerLink='/camera-list'>{{trans('systems.menu.myCameras')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.advancedSettings()' routerLink='/advanced-settings'>{{trans('systems.menu.advancedSettings')}}</app-button-single-line-arrow>
              </div>
              <ng-container *ngIf='systems.activeSystemHelper.can.transferOwnership() || systems.activeSystemHelper.can.transferDevice() || systems.activeSystemHelper.can.transferToCompany()'>
                <div class="separator32"></div>
                <div class="white_round">
                    <app-button-single-line-arrow (click)="systemConfiguration.goToTransfer()">{{trans('systems.menu.transferSystem')}}</app-button-single-line-arrow>
                </div>
              </ng-container>
              <div class="separator32"></div>
              <div class="white_round" *ngIf="systems.activeSystemHelper.can.synchronize() || systems.activeSystemHelper.can.delete.system()">
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.synchronize()' [icon]="'assets/images/question_round.svg'" [routerLink]="'/reread'">{{trans('systems.titles.outOfSync')}}</app-button-single-line-arrow>
                  <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.delete.system()' routerLink='/system-delete'>{{trans('systems.titles.removeSystem')}}</app-button-single-line-arrow>
              </div>
              <div class="separator32"></div>
            </div>
          </ng-container>
          <ng-template #routerCenter>
            <div class="column">
              <router-outlet name="center"></router-outlet>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="columnCount === 1"><div></div></ng-container>
        <div class="column">
          <ng-container *ngIf="isOnHomeRoute && columnCount === 1; else pageRouter">
            <div class="noSystemsContainer">
              <h1>{{trans('systems.noSystemsAdded')}}</h1>
              <h3>{{trans('systems.addFirstSystem')}}</h3>
              <div id="home-empty-plus" (click)="newSystemClick()"><div [inlineSVG]="'assets/images/plus.svg'"></div></div>
              <p class="addSystem" (click)="newSystemClick()">{{trans('systems.titles.addSystem')}}</p>
            </div>
          </ng-container>
          <ng-template #pageRouter>
            <ng-container *ngIf="isCenterRouteActive && columnCount === 1; else homeScreenRouter">
              <router-outlet name="center"></router-outlet>
              <app-footer></app-footer>
            </ng-container>
            <ng-template #homeScreenRouter>
              <h4 *ngIf="isCenterRouteActive || isOnHomeRoute">{{trans('systems.titles.homeScreen')}}</h4>
              <router-outlet></router-outlet>
            </ng-template>
          </ng-template>
        </div>
        <ng-container *ngIf="columnCount === 1"><div></div></ng-container>
      </div>
  </div>
  <app-portal name="footer"></app-portal>
</div>
