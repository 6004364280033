import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { AuthService } from 'src/app/api/auth.service';
import { PersistenceService } from 'src/app/api/persistence.service';
import { ItemConfiguration } from 'src/app/company/components/company-columns/company-columns.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { companyRootRoute } from 'src/app/services/global.service';
const defaultColumns = ['name', 'api_host', 'api_path', 'region_version', 'fake:delete'];

@Component({
  selector: 'app-cp-regions',
  templateUrl: './cp-regions.component.html',
  styleUrls: ['./cp-regions.component.scss']
})
export class CpRegionsComponent extends LanguageAware implements OnInit {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('settings.labels.regionName'), value: 'name' },
    { name: this.trans('settings.labels.regionPath'), value: 'api_host' },
    { name: this.trans('settings.labels.regionHost'), value: 'api_path' },
    { name: this.trans('settings.labels.regionVersion'), value: 'region_version' },
    { name: this.trans('general.delete'), value: 'fake:delete' },
  ];
  public readonly columns = this.persistance.bind('region_columns', defaultColumns);
  public companyRootRoute = companyRootRoute;
  public searchPhrase: string;

  constructor(cdRef: ChangeDetectorRef, private persistance: PersistenceService, private injector: Injector) {
    super(cdRef);
    if(this.us.currentUser.regions.length === 0) {
      this.getData();
    }
  }

  ngOnInit(): void {
  }

  private async getData() {
    const auth = this.injector.get(AuthService);
    await auth.loadUserData();
  }
}
