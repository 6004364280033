<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('auth.labels.resetPassword') }}</h2>
      <p>{{ trans('auth.labels.resetPasswordPrompt') }}</p>
    </div>
    <form (submit)="reset()">
      <app-string-input [label]="trans('auth.form.email')" type="email" name="email" autocomplete="email" [(value)]="login.email" [hasError]="val.hasError('email')" [disabled]="isLoading">
        <app-form-error *ngIf="val.hasError('email')">
          <app-tmapper
            [key]="val.getError('email')"
            [default]=""
            [context]="{}"
            [map]="{
              EMAIL_REQUIRED: emailRequired,
              EMAIL_INVALID: emailInvalid
            }">
          </app-tmapper>
        </app-form-error>
      </app-string-input>
      <app-button type="filled-secondary" class="mainButton" [isLoading]="isLoading">{{ trans('auth.labels.resetPasswordButton') }}</app-button>
    </form>
  </div>
</app-login-layout>

<ng-template #emailRequired>
  <span>{{ trans('auth.errors.requiredEmail') }}</span>
</ng-template>
<ng-template #emailInvalid>
  <span>{{ trans('auth.errors.invalidEmail') }}</span>
</ng-template>
