import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-reset-fire-sensors',
  templateUrl: './reset-fire-sensors.component.html',
  styleUrls: ['./reset-fire-sensors.component.scss'],
})
export class ResetFireSensorsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private r: ActivatedRoute) {
    super(cdRef);
    const refType = localStorage.getItem('ref');
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.resetFireSensors'),
      backUrl: refType === 'conf' ? '../system-configuration' : '*',
      activatedRoute: this.r,
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  public performReset() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('systems.statuses.resetingFireSensors'));
    this.api.post('/reset-fire-sensors', { systemId: this.systems.activeSystem.id }, true).subscribe(
      (result) => {
        if (result.success) {
          this.toaster.postSuccess(this.trans('general.titleSuccess'));
        } else {
          this.toaster.postError(result.error);
        }
        this.miniStatus.hide();
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }
}
