import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PermissionRole } from 'src/api/v3/common';
import { UserService } from '../api/user.service';
import { ApiRequestService } from '../services/api-request.service';
import { GlobalService } from '../services/global.service';

@Injectable({
  providedIn: 'root',
})
export class InstallerRoleGuardService implements CanActivate {
  constructor(private us: UserService, private rs: ApiRequestService, private router: Router, private g: GlobalService) {}

  canActivate() {
    if (this.rs.hasToken() && this.us.currentUser?.id === 0) {
      this.us.loadUserDataFromStorage();
    }
    if (this.us.currentUser?.permissions?.role !== PermissionRole.Installer) {
      this.router.navigate(this.g.getHomeUrl());
      return false;
    }
    return true;
  }
}
