import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompanyRoutingModule } from './company-routing.module';
import { CompanyComponent } from './company.component';

import { CompanySystemsComponent } from './pages/company-systems/company-systems.component';
import { SystemsDataTableComponent } from './components/systems-data-table/systems-data-table.component';
import { EventsDataTableComponent } from './components/events-data-table/events-data-table.component';
import { CompanyHeaderButtonComponent } from './components/company-header-button/company-header-button.component';
import { CompanyHeaderComponent } from './components/company-header/company-header.component';
import { CompanyLayoutComponent } from './pages/company-layout/company-layout.component';
import { CompanyHomeComponent } from './pages/company-home/company-home.component';
import { CompanyLoginComponent } from './pages/company-login/company-login.component';
import { CompanyEventsComponent } from './pages/company-events/company-events.component';
import { UiModule } from '../ui/ui.module';
import { CompanyMobileHostComponent } from './pages/company-mobile-host/company-mobile-host.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { LottieModule } from 'ngx-lottie';
import { AppCommonModule } from '../app-common/app-common.module';
import { CompanyMobileSidebarComponent } from './components/company-mobile-sidebar/company-mobile-sidebar.component';
import { TeleportModule } from '../teleport/teleport.module';
import { CompanyUsersComponent } from './pages/company-users/company-users.component';
import { UsersDataTableComponent } from './components/users-data-table/users-data-table.component';
import { CompanyEventsFilterComponent } from './components/company-events-filter/company-events-filter.component';
import { FormsModule } from '@angular/forms';
import { CompanyUserEditComponent } from './pages/company-user-edit/company-user-edit.component';
import { LoginModule } from '../login/login.module';
import { CompanyHeaderSettingsComponent } from './components/company-header-settings/company-header-settings.component';
import { CompanyAccountSettingsComponent, CompanyAccountSettingsLayoutComponent } from './pages/company-account-settings/company-account-settings.component';
import { CpReactionTypesComponent } from './pages/company-settings/cp-reaction-types/cp-reaction-types.component';
import { CpEventDescriptionsComponent } from './pages/company-settings/cp-event-descriptions/cp-event-descriptions.component';
import { EventDescriptionsDataTableComponent } from './components/event-descriptions-data-table/event-descriptions-data-table.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { CpRegionsComponent } from './pages/company-settings/cp-regions/cp-regions.component';
import { RegionsDataTableComponent } from './components/regions-data-table/regions-data-table.component';
import { CpGlobalComponent } from './pages/company-settings/cp-global/cp-global.component';
import { CpIpcomsComponent } from './pages/company-settings/cp-ipcoms/cp-ipcoms.component';
import { CpTransferDeviceComponent } from './pages/company-settings/cp-transfer-device/cp-transfer-device.component';
import { CompanyPageLayoutComponent } from './components/company-page-layout/company-page-layout.component';
import { CompanyListDesktopComponent } from './pages/company-list-desktop/company-list-desktop.component';
import { PermissionListDesktopComponent } from './pages/permission-list-desktop/permission-list-desktop.component';
import { TagListDesktopComponent } from './pages/tag-list-desktop/tag-list-desktop.component';
import { RegionSwitcherComponent } from './components/region-switcher/region-switcher.component';
import { MiddleColumnViewComponent } from './pages/middle-column-view/middle-column-view.component';
import { CpDevSetupTemplatesComponent } from './pages/company-settings/cp-dev-setup-templates/cp-dev-setup-templates.component';
import { DevTemplatesDataTableComponent } from './components/dev-templates-data-table/dev-templates-data-table.component';
import { CpDevSetupTemplateEditComponent } from './pages/company-settings/cp-dev-setup-templates/cp-dev-setup-template-edit/cp-dev-setup-template-edit.component';
import { SettingsListComponent } from './components/settings-list/settings-list.component';
import { IpcomsDataTableComponent } from './components/ipcoms-data-table/ipcoms-data-table.component';

@NgModule({
  declarations: [
    CompanyComponent,
    CompanyLoginComponent,
    CompanyHomeComponent,
    CompanyLayoutComponent,
    CompanyHeaderComponent,
    CompanyHeaderButtonComponent,
    CompanyEventsComponent,
    CompanySystemsComponent,
    SystemsDataTableComponent,
    EventsDataTableComponent,
    CompanyMobileHostComponent,
    NotfoundComponent,
    CompanyMobileSidebarComponent,
    CompanyUsersComponent,
    UsersDataTableComponent,
    CompanyEventsFilterComponent,
    CompanyUserEditComponent,
    CompanyHeaderSettingsComponent,
    CompanyAccountSettingsComponent,
    CompanyAccountSettingsLayoutComponent,
    CpReactionTypesComponent,
    CpEventDescriptionsComponent,
    EventDescriptionsDataTableComponent,
    ContextMenuComponent,
    CpRegionsComponent,
    RegionsDataTableComponent,
    CpGlobalComponent,
    CpIpcomsComponent,
    CpTransferDeviceComponent,
    CompanyPageLayoutComponent,
    CompanyListDesktopComponent,
    PermissionListDesktopComponent,
    TagListDesktopComponent,
    RegionSwitcherComponent,
    MiddleColumnViewComponent,
    CpDevSetupTemplatesComponent,
    DevTemplatesDataTableComponent,
    CpDevSetupTemplateEditComponent,
    SettingsListComponent,
    IpcomsDataTableComponent,
  ],
  imports: [CommonModule, CompanyRoutingModule, UiModule, LottieModule, AppCommonModule, TeleportModule, FormsModule, LoginModule],
  exports: [CompanyHeaderComponent]
})
export class CompanyModule {}
