<div>
  <div class="sectionHeader">
    <h4 class="titleText">{{ isNew ? trans('setup.device.addTemplate') : trans('setup.device.editTemplate') }}</h4>
    <ng-container *ngIf="!isNew && us.currentUser.permissions?.permissions.dev_setup_templates.delete">
      <div class="delete" (click)="onDeleteClick()">
        <h4>{{trans('general.delete')}}</h4>
        <div class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
      </div>
    </ng-container>
  </div>
  <div class="editSection">
    <app-string-input [label]="trans('setup.device.stationName')" [(value)]="template.name" (valueChange)="onValueChange()" [maxLength]="255" [disabled]="canOnlyView">
      <app-form-error *ngIf="val.hasError('name')">{{ val.getError('name') }}</app-form-error>
    </app-string-input>
    <div class="channels">
      <div class="channels-col">
        <p class="heading heading-channel">{{trans('setup.device.primaryChannel')}}</p>
        <app-dropdown label="{{trans('setup.device.receiver')}}" [items]="ipcomDropdownList" [value]="template.ip_com" (valueChange)="primaryReceiverChanged($event)" [disabled]="canOnlyView">
          <app-form-error *ngIf="val.hasError('ip_com')">{{ val.getError('ip_com') }}</app-form-error>
        </app-dropdown>
        <app-string-input [label]="trans('settings.labels.hostForDevices')" [(value)]="template.host" (valueChange)="onValueChange()" [disabled]="template.ip_com > 0" [disabled]="canOnlyView">
          <app-form-error *ngIf="val.hasError('host')">{{ val.getError('host') }}</app-form-error>
        </app-string-input>
        <app-string-input [label]="trans('settings.labels.portForDevices')" [(value)]="template.port" (valueChange)="onValueChange()" [maxLength]="5" [disabled]="template.ip_com > 0" [disabled]="canOnlyView">
          <app-form-error *ngIf="val.hasError('port')">{{ val.getError('port') }}</app-form-error>
        </app-string-input>
        <app-string-input type="password" [label]="trans('settings.labels.encryptionForDevices')" [(value)]="template.encryption" (valueChange)="onValueChange()" [maxLength]="16" [disabled]="template.ip_com > 0 || canOnlyView">
          <app-form-error *ngIf="val.hasError('encryption')">{{ val.getError('encryption') }}</app-form-error>
        </app-string-input>
        <app-dropdown [label]="trans('setup.device.transportProtocol')" [items]="communicationTypeDropdownItems" [(value)]="template.communication_type" (valueChange)="onValueChange()" [disabled]="canOnlyView">
          <app-form-error *ngIf="val.hasError('communication_type')">{{ val.getError('communication_type') }}</app-form-error>
        </app-dropdown>
      </div>
      <div class="channels-col">
        <div class="heading heading-toggle">
          <app-name-value [nameText]="trans('setup.device.backupChannel')" [isEnabled]="!canOnlyView">
            <app-toggle-button [buttonId]="'backup_enabled'" [initialState]="template.backup_enabled" (toggled)="template.backup_enabled = $event.checked; onValueChange()"></app-toggle-button>
          </app-name-value>
        </div>
        <app-dropdown label="{{trans('setup.device.receiver')}}" [items]="ipcomDropdownList" [value]="template.backup_ip_com" (valueChange)="backupReceiverChanged($event)" [disabled]="!template.backup_enabled || canOnlyView">
          <app-form-error *ngIf="template.backup_enabled && backupVal.hasError('backup_ip_com')">{{ backupVal.getError('backup_ip_com') }}</app-form-error>
        </app-dropdown>
        <app-string-input [label]="template.backup_ip_com > 0 ? trans('settings.labels.hostForDevices') : trans('setup.device.ipOrDomain')" [(value)]="template.backup_host" (valueChange)="onValueChange()" [disabled]="template.backup_ip_com > 0 || !template.backup_enabled || canOnlyView">
          <app-form-error *ngIf="template.backup_enabled && backupVal.hasError('backup_host')">{{ backupVal.getError('backup_host') }}</app-form-error>
        </app-string-input>
        <app-string-input [label]="trans('settings.labels.portForDevices')" [(value)]="template.backup_port" (valueChange)="onValueChange()" [maxLength]="5" [disabled]="template.backup_ip_com > 0 || !template.backup_enabled || canOnlyView">
          <app-form-error *ngIf="template.backup_enabled && backupVal.hasError('backup_port')">{{ backupVal.getError('backup_port') }}</app-form-error>
        </app-string-input>
        <app-string-input type="password" [label]="trans('settings.labels.encryptionForDevices')" [(value)]="template.backup_encryption" (valueChange)="onValueChange()" [maxLength]="16" [disabled]="template.backup_ip_com > 0 || !template.backup_enabled || canOnlyView">
          <app-form-error *ngIf="template.backup_enabled && backupVal.hasError('backup_encryption')">{{ backupVal.getError('backup_encryption') }}</app-form-error>
        </app-string-input>
        <app-dropdown [label]="trans('setup.device.transportProtocol')" [items]="communicationTypeDropdownItems" [(value)]="template.backup_communication_type" (valueChange)="onValueChange()" [disabled]="!template.backup_enabled" [disabled]="!template.backup_enabled || canOnlyView">
          <app-form-error *ngIf="template.backup_enabled && backupVal.hasError('backup_communication_type')">{{ backupVal.getError('backup_communication_type') }}</app-form-error>
        </app-dropdown>
      </div>
    </div>
    <div>
      <p class="heading">{{trans('setup.device.receiverAndLineNumbers')}}</p>
      <div class="channels">
        <div class="channels-col">
          <app-string-input class="hex_input" [label]="trans('setup.device.receiverNumber')" [(value)]="hexValueHolder.receiver_nr" (valueChange)="updateReceiverAndLine(); onValueChange()" [maxLength]="6" [disabled]="canOnlyView">
            <app-form-error *ngIf="val.hasError('receiver_nr')">{{ val.getError('receiver_nr') }}</app-form-error>
          </app-string-input>
        </div>
        <div class="channels-col">
          <app-string-input class="hex_input" [label]="trans('setup.device.lineNumber')" [(value)]="hexValueHolder.line_nr" (valueChange)="updateReceiverAndLine(); onValueChange()" [maxLength]="6" [disabled]="canOnlyView">
            <app-form-error *ngIf="val.hasError('line_nr')">{{ val.getError('line_nr') }}</app-form-error>
          </app-string-input>
        </div>
      </div>
    </div>
    <div>
      <p class="heading">{{trans('setup.device.testReporting')}}</p>
      <div class="channels">
        <div class="channels-col">
          <app-name-value [nameText]="trans('setup.device.test')" [isEnabled]="!canOnlyView">
            <app-toggle-button [buttonId]="'test_enabled'" [initialState]="template.test_enabled" (toggled)="template.test_enabled = $event.checked; onValueChange()"></app-toggle-button>
          </app-name-value>
          <label class="label">{{trans('setup.device.testPeriod')}}</label>
          <div class="flex">
            <app-string-input [(value)]="testPeriodDuration.days" [min]="0" [max]="10" (valueChange)="onTestPeriodChange()" [disabled]="canOnlyView"></app-string-input><span class="time time-label">{{trans('configurators.labels.days')}}</span>
            <app-string-input [(value)]="testPeriodDuration.hours" [min]="0" [max]="24" (valueChange)="onTestPeriodChange()" (Blur)="onTestPeriodBlur()" [disabled]="canOnlyView"></app-string-input><span class="time time-label flex-1">{{trans('configurators.labels.hoursLong')}}</span>
          </div>
          <div class="two-column-error">
            <app-form-error *ngIf="val.hasError('test_period')">{{ val.getError('test_period') }}</app-form-error>
          </div>
        </div>
        <div class="channels-col">
          <app-name-value [nameText]="trans('setup.device.ping')" [isEnabled]="!canOnlyView">
            <app-toggle-button [buttonId]="'ping_enabled'" [initialState]="template.ping_enabled" (toggled)="template.ping_enabled = $event.checked; onValueChange()"></app-toggle-button>
          </app-name-value>
          <app-string-input [label]="trans('setup.device.pingPeriod')" [(value)]="template.ping_period" (valueChange)="onValueChange()" (Blur)="onPingPeriodChange()" [step]="10" [min]="0" [max]="3600" [disabled]="canOnlyView">
            <app-form-error *ngIf="val.hasError('ping_period')">{{ val.getError('ping_period') }}</app-form-error>
          </app-string-input>
        </div>
      </div>
    </div>
    <div>
      <p class="heading">{{ trans('setup.device.backupReporting') }}</p>
      <div class="channels">
        <div class="channels-col">
          <div class="flex">
            <app-string-input [label]="trans('setup.device.backupAfter')" [(value)]="template.backup_after" (valueChange)="onValueChange()" [min]="0" [max]="9" [disabled]="canOnlyView"></app-string-input>
            <span class="flex-span-text">{{trans('setup.device.fails')}}</span>
          </div>
          <div class="two-column-error">
            <app-form-error *ngIf="val.hasError('backup_after')">{{ val.getError('backup_after') }}</app-form-error>
          </div>
        </div>
        <div class="channels-col">
          <label class="label">{{trans('setup.device.primaryAfter')}}</label>
          <div class="flex">
            <app-string-input [(value)]="returnFromBackupPeriodDuration.minutes" [min]="0" [max]="99" (valueChange)="onReturnFromBackupPeriodChange()" [disabled]="canOnlyView"></app-string-input><span class="time time-label">{{this.trans('configurators.labels.minutesLong')}}</span>
            <app-string-input [(value)]="returnFromBackupPeriodDuration.seconds" [min]="0" [max]="50" [disabled]="returnFromBackupPeriodDuration.minutes === 99 || canOnlyView" [step]="10" (valueChange)="onReturnFromBackupPeriodChange()" (Blur)="onReturnFromBackupPeriodBlur()"></app-string-input><span class="time time-label flex-1">{{this.trans('configurators.labels.secondsLong')}}</span>
          </div>
          <div class="two-column-error">
            <app-form-error *ngIf="val.hasError('primary_after')">{{ val.getError('primary_after') }}</app-form-error>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!canOnlyView" class="reset" (click)="onResetSettingsClick()">
      <div class="reset-icon" [inlineSVG]="'assets/images/refresh.svg'"></div>
      <p>{{trans('setup.device.resetDefaults')}}</p>
    </div>
    <div>
      <p class="heading">{{ trans('setup.device.templateUsers') }}</p>
      <div *ngFor="let iUser of template.users" class="dropdown_with_delete">
        <app-dropdown *ngIf="desktopView" [items]="us.usersForList" [value]="iUser.id" (valueChange)="onUserChanged($event, iUser.id)" [disabled]="canOnlyView"></app-dropdown>
        <app-name-value *ngIf="!desktopView" (clicked)="doChangeUser(iUser.id)">{{iUser.name}}</app-name-value>
        <div [inlineSVG]="'assets/images/close.svg'" (click)="onUserDeleted(iUser.id)"></div>
      </div>
      <app-dropdown *ngIf="!canOnlyView && desktopView && newUserSelected !== 0" [disabled]="true"></app-dropdown>
      <app-dropdown *ngIf="!canOnlyView && desktopView && newUserSelected === 0" [items]="us.usersForList" [(value)]="newUserSelected" (valueChange)="onUserAdded($event)"></app-dropdown>
      <app-name-value *ngIf="!desktopView && !canOnlyView" [nameText]="trans('setup.device.selectUser')" (clicked)="doAddUser()"></app-name-value>
    </div>
  </div>
  <ng-container *ngIf="(isNew && us.currentUser.permissions.permissions.dev_setup_templates.create) || (inputChanged && us.currentUser.permissions.permissions.dev_setup_templates.edit)">
    <ng-template appTeleport="footer">
      <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
    </ng-template>
  </ng-container>
</div>
