import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RequestService } from 'src/app/api/request.service';
import { SystemService } from 'src/app/api/system/system.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TSystemData } from 'src/app/models/system-data';
import { AreaService } from 'src/app/services/area.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { DataTableGetter } from '../data-table/data-table.component';
type RowData = {
  column: string;
  item: TSystemData;
  row: number;
};

@Component({
  selector: 'app-systems-data-table',
  templateUrl: './systems-data-table.component.html',
  styleUrls: ['./systems-data-table.component.scss'],
})
export class SystemsDataTableComponent extends LanguageAware implements OnInit, OnChanges, OnDestroy {
  @Input() public columns: string[] = [];
  @Input() searchLoader: DataTableGetter<TSystemData>;
  @Output() public modifyColumns = new EventEmitter<void>();
  @Output() searchFor = new EventEmitter<{searchPhrase: string; searchField: string}>();
  public readonly canDelete = this.us.currentUser.permissions.permissions.systems.delete && (!this.us.currentUser.belongsToCompany || this.us.currentUser.belongsToCompany.active);

  private routeSubscription = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      const route = this.router.url.split('/');
      if(route.length <= 4) {
        this.activeSystemId = null;
      }
    }
  });
  public activeSystemId: number;
  public loader: DataTableGetter<TSystemData>;
  public readonly rootRoute = companyRootRoute;
  public contextMenu = {
    isVisible: false,
    item: null
  };
  private subscriptions: Subscription[] = [];

  constructor(
    cdRef: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private router: Router,
    private req: RequestService,
    public system: SystemService,
    private messagebox: MessageboxService,
    private areaService: AreaService
  ) {
    super(cdRef);
    if(this.systems.getLastSystemData()?.id === this.systems.activeSystem?.id) {
      this.activeSystemId = this.systems.activeSystem?.id;
    }
    this.subscriptions.push(this.routeSubscription);
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.loader = this.searchLoader ? this.searchLoader : this.system.getSystemsGetter(false);
    }
  }

  public onRightClick({item, event}) {
    event.preventDefault();
    this.contextMenu.isVisible = true;
    this.contextMenu.item = item;
  }

  public onContextFilter(searchPhrase: string, searchField: string) {
    this.contextMenu.isVisible = false;
    this.searchFor.emit({ searchPhrase, searchField });
  }

  public async onContextLinkClick(route) {
    this.contextMenu.isVisible = false;
    this.activeSystemId = this.contextMenu.item.id;
    const sys = await this.system.getSystem(this.contextMenu.item.id);
    this.systems.setCurrentSystem(sys);
    this.router.navigateByUrl(`${this.rootRoute}/systems/${this.contextMenu.item.id.toString()}/${route}`);
  }

  public getThemeStyle(system: TSystemData): Record<string, unknown> {
    return {
      '--start-color': system.theme.startColor,
      '--end-color': system.theme.endColor,
    };
  }

  public async onRowClick(event: RowData): Promise<void> {
    if(event.column === 'fake:delete') {
      this.onDeleteIconClick(event);
    } else {
      this.activeSystemId = event.item.id;
      const sys = await this.system.getSystem(event.item.id);
      this.systems.setCurrentSystem(sys);
      this.router.navigate([this.rootRoute, 'systems', event.item.id.toString()]);
    }
  }

  public onDeleteIconClick(event: RowData): void {
    if ( !this.canDelete ) { return; }
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
`${this.trans('systems.labels.deleteSystemInfo')}: <strong>${event.item.name}
(${this.trans('systems.labels.imei')}: ${event.item.imei})</strong>.
${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.deleteSystem(event.item.id);
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private async deleteSystem(system_id: number): Promise<void> {
    await this.req.system.deleteSystems({systems: [system_id]}).toPromise().then(result => {
      if(result.success) {
        this.system.systems.delete(system_id);
        this.messagebox.open({
          buttons: this.messagebox.buttons.Close,
          headerText: this.trans('general.titleSuccess'),
          messageContent: this.trans('systems.labels.deletedSuccessfully'),
          iconType: this.messagebox.iconType.Success
        });
      } else {
        this.messagebox.open({
          buttons: this.messagebox.buttons.Close,
          headerText: this.trans('general.titleError'),
          messageContent: `${this.trans('systems.errors.failedToDelete')}.`,
          iconType: this.messagebox.iconType.Error
        });
      }
    });
  }

  public getStatusIconForSystem(system: TSystemData) {
    if (system.areas.length !== 1) {
      return '';
    }
    return this.areaService.getIconByAreaStatusEx(system.areas[0]);
  }

  public isSystemDisarmed(system: TSystemData): boolean {
    if (system.areas.length !== 1) {
      return false;
    }

    return system.areas[0].status === 1;
  }

  public isSystemAlarmed(system: TSystemData): boolean {
    if (system.areas.length !== 1) {
      return false;
    }

    return system.areas[0].alarmed;
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }
}
