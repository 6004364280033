import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { IPCom } from 'src/app/models/ipcom';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-su-ipcoms',
  templateUrl: './su-ipcoms.component.html',
  styleUrls: ['./su-ipcoms.component.scss'],
})
export class SuIpcomsComponent extends LanguageAware implements OnInit {
  constructor(private router: Router, private es: EditSystemService, cdRef: ChangeDetectorRef) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.ipcom'),
      backUrl: this.g.getMobileSettingsUrl().join('/'),
    });
    this.helper.setCountForBottomButton(1, 1, 1, false, true, false, 'suip');
    this.background.setGray();
  }

  ngOnInit(): void {}

  public addIpcom() {
    const i: IPCom = {
      id: 0,
      company_id: this.us.currentUser.company_id,
      description: '',
      ipcom_logo: null,
      name: '',
      password: '',
      peer_name: '',
      port: 0,
      user_name: '',
      host: '',
      host_for_devices: '',
      own_ipcom: true,
      region: 0,
      encryption_for_devices: '',
      port_for_devices: '',
    };
    this.es.beginComponentEdit(TEditableComponent.IPcom, 0, i);
    this.router.navigate(['/sadmin/ipcom']);
  }

  public editIpcom(ipcom: IPCom) {
    this.es.beginComponentEdit(TEditableComponent.IPcom, ipcom.id, ipcom);
    this.router.navigate(['/sadmin/ipcom']);
  }
}
