import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';

@Component({
  selector: 'app-name-register',
  templateUrl: './name-register.component.html',
  styleUrls: ['./name-register.component.scss'],
})
export class NameRegisterComponent extends LanguageAware implements OnInit {
  public userName = '';
  private controlsEnabled = true;

  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    if (this.us.currentUser.name !== '') {
      this.router.navigate(this.g.getHomeUrl());
      return;
    }
    this.headerBar.showHeader({
      showLogo: true,
    });
    this.footerBar.showFooter(this.trans('auth.buttons.next'), '', true, false);
    this.progressBar.showProgress();
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onFinish();
    };
  }

  ngOnInit() {
    this.progressBar.setProgressData(4, 4);
  }

  public onFinish() {
    if (!this.controlsEnabled) {
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    this.miniStatus.show(this.trans('general.saving'));
    this.api.post('/name-create', { name: this.userName }, true).subscribe(
      (result) => {
        that.controlsEnabled = true;
        that.miniStatus.hide();
        if (result.success) {
          that.us.currentUser.name = that.userName;
          that.router.navigate(this.g.getHomeUrl());
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.controlsEnabled = true;
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      }
    );
  }

  loginClick() {
    this.us.setCurrentUser(null);
    this.us.change();
    this.us.saveCurrentUser();
    this.api.setToken('');
    this.router.navigate(['/login']);
  }
}
