import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-su-regions',
  templateUrl: './su-regions.component.html',
  styleUrls: ['./su-regions.component.scss'],
})
export class SuRegionsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('settings.labels.regions'),
      backUrl: this.g.getMobileSettingsUrl().join('/'),
      showPlus: this.us.currentUser.permissions?.permissions.regions.create,
    });
    this.background.setGray();
    this.headerBar.onActionButtonClicked = () => {
      this.es.beginComponentEdit(TEditableComponent.Region, 0, null);
      this.router.navigate([...this.g.getHomeUrl(), 'region']);
    };
  }

  ngOnInit(): void {}

  public editRegion(region: any) {
    this.es.beginComponentEdit(TEditableComponent.Region, region.id, region);
    this.router.navigate([...this.g.getHomeUrl(), 'region']);
  }
}
