import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ItemConfiguration } from 'src/app/company/components/company-columns/company-columns.component';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { IPCom } from 'src/app/models/ipcom';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';

@Component({
  selector: 'app-cp-ipcoms',
  templateUrl: './cp-ipcoms.component.html',
  styleUrls: ['./cp-ipcoms.component.scss']
})
export class CpIpcomsComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly canDelete = this.us.currentUser.permissions.permissions.ipcom_settings.delete && this.us.currentUser.company_id === 0;
  private subscriptions: Subscription[] = [];
  public loader: DataTableGetter<IPCom>;
  public modifyColumns = new EventEmitter<void>();
  public columns = this.persistenceService.bind('ipcom_list_columns', ['id', 'name', 'host', 'port', 'fake:arrow']);
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('general.id'), value: 'id' },
    { name: this.trans('settings.labels.nameIpcom'), value: 'name' },
    { name: this.trans('settings.labels.peerName'), value: 'peer_name' },
    { name: this.trans('settings.labels.host'), value: 'host' },
    { name: this.trans('settings.labels.port'), value: 'port' },
    { name: this.trans('settings.labels.userName'), value: 'user_name' },
    { name: this.trans('settings.labels.own'), value: 'own_ipcom' },
    { name: this.trans('settings.ipcom.region'), value: 'region' },
    this.us.currentUser.company_id === 0 ? { name: this.trans('settings.ipcom.companySelect'), value: 'company_id' } : null,
    this.canDelete ? { name: this.trans('general.delete'), value: 'fake:delete' } : null,
  ];

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private es: EditSystemService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    if ( !this.desktopView ) {
      this.columns.value = ['id', 'name', 'fake:arrow'];
      this.headerBar.showHeader({
        backUrl: '*',
        headerText: this.trans('systems.menu.ipcom'),
      });
      this.background.setGray();
      this.footerBar.hideFooter();
    } else if ( this.us.currentUser.company_id !== 0 && this.columns.value.includes('company_id') ) {
      this.columns.value.splice(this.columns.value.indexOf('company_id'), 1);
    }
    this.loader = this.ipcomService.getIpcomsGetter();
    this.editSystemService.endComponentEdit(TEditableComponent.IPcom);
    this.editSystemService.endComponentEdit(TEditableComponent.IPComSettings);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  public addIpcom(): void {
    const i: IPCom = {
      id: 0,
      company_id: this.us.currentUser.company_id,
      description: '',
      ipcom_logo: null,
      name: '',
      password: '',
      peer_name: '',
      port: 0,
      user_name: '',
      host: '',
      host_for_devices: '',
      own_ipcom: true,
      region: 0,
      encryption_for_devices: '',
      port_for_devices: '',
    };
    this.es.beginComponentEdit(TEditableComponent.IPcom, 0, i);
    this.router.navigate(['new'], { relativeTo: this.r });
  }
}
