import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IPopup } from '../IPopup';

@Component({
  selector: 'app-base-popup',
  templateUrl: './base-popup.component.html',
  styleUrls: ['./base-popup.component.scss'],
})
export class BasePopupComponent implements OnInit, IPopup, AfterViewInit {
  @Input() popupVisible = true;
  @Input() canCancel = true;
  @Output() popupCancelled: EventEmitter<any> = new EventEmitter();
  @Output() closed: EventEmitter<void> = new EventEmitter();
  public popupName = '';
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {}

  public cancelPopup() {
    if (!this.canCancel) {
      return;
    }
    this.popupCancelled.emit();
    this.hidePopup();
  }

  public hidePopup() {
    this.popupVisible = false;
    this.closed.emit();
  }

  public show() {
    this.popupVisible = true;
  }
}
