import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { AppSettings } from 'src/app-settings';
import { PermissionRole } from 'src/api/v3/common';
import { LanguageAware } from 'src/app/general/language-aware';
import { companyRootRoute } from 'src/app/services/global.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-company-header',
  templateUrl: './company-header.component.html',
  styleUrls: ['./company-header.component.scss'],
})
export class CompanyHeaderComponent extends LanguageAware implements OnInit, OnDestroy {
  public ProtegusLogo = '';
  public CompanyHeaderButton = '';
  public CompanyDashButton = '';
  public CompanySystemsButton = '';
  public CompanyEventsButton = '';
  public CompanyUsersButton = '';
  public CompanyTagsButton = '';
  public CompanySettingsButton = '';
  public UserIcon = '';
  public showSettings = false;
  public showProfileSettings = false;
  public rootRoute = companyRootRoute;
  public newVersionAvailable = false;
  public readonly roles = PermissionRole;
  public hasAnySettingsAvailable = false;
  private userDataChangeListener = null;
  private newVersionSubscriber: Subscription;

  constructor(
    cdRef: ChangeDetectorRef,
    public login: LoginService,
  ) {
    super(cdRef);
    this.reinitVariables();
    this.userDataChangeListener = this.us.onUserDataChanged.subscribe(() => { this.reinitVariables(); });
    this.newVersionSubscriber = this.g.onNewVersionAvailable.subscribe(() => {
      this.newVersionAvailable = AppSettings.showAppVersion && this.g.isNewVersionAvailable();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.userDataChangeListener?.unsubscribe();
    this.newVersionSubscriber?.unsubscribe();
  }

  toggleSettings(): void {
    this.showSettings = !this.showSettings;
    this.showProfileSettings = false;
  }

  toggleProfileSettings(): void {
    this.showProfileSettings = !this.showProfileSettings;
  }

  onShowSettingsChange(value: boolean): void {
    this.showProfileSettings = value;
  }

  private reinitVariables() {
    const userPermissions = this.us.currentUser?.permissions?.permissions;
    if ( !userPermissions ) { return; }
    this.hasAnySettingsAvailable = [
      userPermissions.company_settings.view && this.us.currentUser?.company_id !== 0,
      userPermissions.ipcom_settings.view,
      userPermissions.reactions.view,
      userPermissions.global_settings.view,
      userPermissions.panic_settings.view,
      userPermissions.regions.view,
      userPermissions.transfer_device.execute
    ].includes(true);
  }
}
