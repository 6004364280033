import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionRole, Region } from 'src/api/v3/common';
import { AppSettings } from 'src/app-settings';
import { AuthService } from 'src/app/api/auth.service';
import { RegionService } from 'src/app/api/region.service';
import { EventService } from 'src/app/api/system/event.service';
import { SystemService } from 'src/app/api/system/system.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { LoginService } from 'src/app/login/login.service';
import { AppRegion } from 'src/environments/environment.types';

@Component({
  selector: 'app-region-switcher',
  templateUrl: './region-switcher.component.html',
  styleUrls: ['./region-switcher.component.scss']
})
export class RegionSwitcherComponent extends LanguageAware implements OnInit, OnDestroy {
  public showRegionSelection = false;
  public PermissionRole = PermissionRole;
  public currentRegion = this.region.ActiveRegion.backEndHost;
  public regionsEnabled = AppSettings.regionsEnabled;
  private subscriptions: Subscription[] = [];
  public showPasswordMessagebox = false;
  public selectedRegion: any;
  private loginConfirmed = false;
  public activeRegionName = '';

  constructor(
    cdRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    public region: RegionService,
    public auth: AuthService,
    public event: EventService,
    public system: SystemService,
    private router: Router,
    private login: LoginService,
  ) {
    super(cdRef);
    this.us.populateUserRegions();
    this.updateActiveRegionName();
    this.us.onUserDataChanged.subscribe(() => {
      this.us.populateUserRegions();
      this.updateActiveRegionName();
    });
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLButtonElement;
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      this.showRegionSelection = false;
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showRegionSelection = false;
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  private updateActiveRegionName() {
    const region = this.us.userRegions.find(iRegion => iRegion.api_host_to_use === this.region.ActiveRegion.backEndHost );
    this.activeRegionName = region ? region.name : this.region.ActiveRegion.backEndHost;
  }

  public toggleRegionSelect() {
    this.showRegionSelection = !this.showRegionSelection;
  }

  public onRegionSelect(region: any) {
    this.selectedRegion = region;
    if (this.us.userPassword === '' || !this.loginConfirmed) {
      this.showPasswordMessagebox = true;
    } else {
      this.changeRegion();
    }
  }

  public performLogin() {
    this.api.post('/login', { email: this.us.currentUser.email, password: this.us.userPassword, }, false).subscribe(
      async (response) => {
        if (response.success) {
          await this.router.navigate(this.login.determineLoginDestination());
          this.loginConfirmed = true;
          this.changeRegion();
          this.loadNewRegionData(response);
        } else {
          this.us.userPassword = '';
          this.toaster.postError(response.error);
        }
      },
      (error) => {
        this.us.userPassword = '';
        this.toaster.postError(this.trans('auth.failed'));
      }
    );
  }

  private changeRegion() {
    if (this.selectedRegion.region_version < 2) {
      this.toaster.postError('Sena versija');
      return;
    }
    const beforeRegion = this.region.ActiveRegion;
    this.currentRegion = this.selectedRegion.api_host_to_use ? this.selectedRegion.api_host_to_use : this.selectedRegion.backEndHost;
    const bestRegion = AppSettings.regions.find((r) => r.backEndHost === this.currentRegion);

    if (bestRegion) {
      const regionToUse = bestRegion;
      this.region.useRegion(regionToUse);
    } else {
      this.region.useRegion({
        ...beforeRegion,
        id: this.currentRegion.substring(0, 5),
        backEndHost: this.currentRegion,
      });
    }
    this.doRegionLogin(beforeRegion);
  }

  public doRegionLogin(beforeRegion: AppRegion) {
    this.api.post('/login', { email: this.us.currentUser.email, password: this.us.userPassword, }, false).subscribe(
      async (response) => {
        if (response.success) {
          await this.router.navigate(this.login.determineLoginDestination());
          this.loadNewRegionData(response);
        } else {
          this.toaster.postError(response.error);
          this.region.useRegion(beforeRegion);
          this.currentRegion = beforeRegion.backEndHost;
          this.us.userPassword = '';
        }
      },
      (error) => {
        this.toaster.postError(this.trans('auth.failed'));
        this.region.useRegion(beforeRegion);
        this.currentRegion = beforeRegion.backEndHost;
      }
    );
  }

  private loadNewRegionData(response) {
    this.currentRegion = this.region.ActiveRegion.backEndHost;
    this.api.setToken(response.token);
    this.us.setCurrentUser(response);
    this.us.change();
    this.us.saveCurrentUser();
    this.us.setLoginType('default');
    this.auth.loadMergedRegionAccounts();
    this.router.navigate(this.login.determineLoginDestination());
    this.us.populateUserRegions();
    this.updateActiveRegionName();
  }

}
