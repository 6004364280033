import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { Subscription } from 'rxjs';
import { SystemsService } from 'src/app/services/systems.service';
import { TSystemData } from 'src/app/models/system-data';
import { ProfileComponent } from 'src/app/general/profile/profile.component';
import { LanguageListComponent } from 'src/app/general/language-list/language-list.component';
import { MessageBoxButtons } from 'src/app/popups/messagebox/messagebox-config';
import { PasswordChangeComponent } from 'src/app/general/password-change/password-change.component';
import { AccountDeleteComponent } from 'src/app/general/account-delete/account-delete.component';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { UserService } from 'src/app/api/user.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
type userSettingsData = {
  name: string;
  phone: string;
  DateFormat: number;
};

@Component({
  selector: 'app-company-account-settings',
  templateUrl: './company-account-settings.component.html',
  styleUrls: ['./company-account-settings.component.scss'],
  providers: [ProfileComponent, LanguageListComponent, PasswordChangeComponent, AccountDeleteComponent]
})
export class CompanyAccountSettingsComponent implements OnInit, OnDestroy {
  public userSettingsData: userSettingsData;
  private initialUserSettingsData: string;
  public dateTimeFormats: DropdownItem<number>[];
  private subscriptions: Subscription[] = [];
  public currentLanguage: string;
  public languageItems = [];
  public footerButtons = [];
  public inputChanged = false;
  public isRequestLoading = false;
  public showChangePasswordComponent = false;
  private delayDuration = 2000;
  public password = '';
  public newPassword = '';
  public changePasswordMessageboxButtons: MessageBoxButtons = MessageBoxButtons.CustomButton1Button2Link;
  public val = new ValidatorBuilder<{phone: string}>()
  .regex('phone', /^[0-9+\-\s]+$/, `${this.lang.get('validation.phoneInvalid')}`)
  .build().bindContext(this);

  constructor(
    private messagebox: MessageboxService,
    private systems: SystemsService,
    private languageListComponent: LanguageListComponent,
    private accountDelete: AccountDeleteComponent,
    public lang: LanguageService,
    public user: UserService,
    public profileComponent: ProfileComponent,
    public passwordChange: PasswordChangeComponent,
  ) {
    this.user.loadUserDataFromStorage();
    if(this.systems.systems.length === 0) { this.systems.loadSystems(); };
    this.userSettingsData = {
      name: this.user.currentUser.name,
      phone: this.user.currentUser.phone ? this.user.currentUser.phone : '',
      DateFormat: this.user.currentUser.date_format
    };
    this.currentLanguage = this.languageListComponent.currentLanguage;
    this.initialUserSettingsData = JSON.stringify(this.userSettingsData);
    this.languageItems = this.languageListComponent.availableLanguages.map(obj => ({ value: obj.code, label: obj.name }));
    this.dateTimeFormats = this.profileComponent.dateTimeFormats.map(date => ({ value: date.value, label: date.label }));
    this.footerButtons = [
      { label: this.lang.get('settings.buttons.save'),
        loadingLabel: this.lang.get('settings.buttons.saving'),
        loadingCompleteLabel: this.lang.get('settings.buttons.saved'),
        callback: async () => { this.onSettingsSave(); }
      },
      { label: this.lang.get('general.cancel'),  callback: () => this.inputChanged=false },
    ];
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
  }

  private async onSettingsSave(): Promise<void> {
    if ( ! await this.val.validate(this.userSettingsData) ) { return; }
    this.isRequestLoading = true;
    this.changeLanguage();
    if(this.initialUserSettingsData !== JSON.stringify(this.userSettingsData)) {
      const settingsSaved = this.profileComponent.saveUserSettings(this.userSettingsData);
      if(settingsSaved) {
        this.user.currentUser.name = this.userSettingsData.name;
        this.user.currentUser.phone = this.userSettingsData.phone;
        this.user.currentUser.date_format = this.userSettingsData.DateFormat;
        this.initialUserSettingsData = JSON.stringify(this.userSettingsData);
      }
    }
    setTimeout(() => {
      this.isRequestLoading = false;
    }, 50);
    await new Promise<void>(() => setTimeout(() => {
      this.onInputChange();
    }, this.delayDuration));
  }

  private changeLanguage(): void {
    if(this.currentLanguage !== this.languageListComponent.currentLanguage) {
      this.languageListComponent.currentLanguage = this.currentLanguage;
      this.languageListComponent.doSave();
    }
  }

  public onInputChange(): void {
    this.inputChanged = this.initialUserSettingsData !== JSON.stringify(this.userSettingsData) || this.currentLanguage !== this.languageListComponent.currentLanguage;
  }

  private revert(): void {
    this.userSettingsData = JSON.parse(this.initialUserSettingsData);
    this.onInputChange();
  }

  public async onDeleteProfileClick(): Promise<void> {
    const messageBody = `
      <p align='center'><b>${this.lang.get('users.labels.profileDeletionInfo')}</b></p>
      ${this.lang.get('users.labels.profileDeletionInfo2')}<br/>
      ${this.getOwnedSystems().map(system => `<div class="systemsWrap"><img class="icon" src="assets/images/area-general-icon.svg"/>${system.name}</div>`).join('')}
    `;
    this.messagebox.open({
      buttons: this.messagebox.buttons.CustomButton1Cancel,
      headerText: this.lang.get('users.labels.deleteProfile'),
      messageContent: messageBody,
      button1Text: this.lang.get('general.delete'),
      iconType: this.messagebox.iconType.Error
    }, (messagebox) => {
      const deleteClickSubscription = messagebox.button1Clicked.subscribe(() => {
        this.accountDelete.confirmedDeletion();
      });
      this.subscriptions.push(deleteClickSubscription);
    });
  }
  private getOwnedSystems(): TSystemData[] {
    return this.systems.systems.filter((s) => s.amIMaster);
  }
}

@Component({
  template: '<app-company-page-layout gridTemplateColumns="2fr 1fr"></app-company-page-layout>'
})
export class CompanyAccountSettingsLayoutComponent {}
