<div>
  <ol>
    <li role="button" tabindex="0" *ngFor="let step of steps; let i = index" (click)="setStep(i)" [ngClass]="[activeStep > i ? 'past' : '', activeStep === i ? 'current' : '']">
      {{ step }}
    </li>
  </ol>
  <ng-container [ngTemplateOutlet]="activeTemplate ?? stepTemplate"></ng-container>
</div>
<ng-template #stepTemplate>
  <h2>{{ trans('general.stepTemplate404') }}</h2>
</ng-template>
