import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { PlatformService } from '../api/platform.service';
import { ApiRequestService } from '../services/api-request.service';
import { GlobalService } from '../services/global.service';
import { UserService } from '../api/user.service';

@Injectable({
  providedIn: 'root'
})
export class MobileGuardService implements CanActivate {

  constructor(
    private platform: PlatformService,
    private router: Router,
    private g: GlobalService,
    private l: Location,
    private rs: ApiRequestService,
    private us: UserService,
  ) { }

  canActivate() {
    const url = this.l.path();
    if ( this.platform.isDesktop() && AppSettings.companyDesktopEnabled && !url.startsWith('/d/') ) {
      if (this.rs.hasToken() && !this.us.currentUser) {
        this.us.loadUserDataFromStorage();
      } else if ( !this.rs.hasToken() ) {
        return true;
      }
      this.router.navigate(this.g.getHomeUrl());
      return false;
    }

    return true;
  }
}
