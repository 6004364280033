<div class="page-wrapper">
  <h4>{{ trans('users.settings.menu.userSettings') }}</h4>
  <div id="userSettingsPanel" class="page">
    <app-string-input type="email" [label]="trans('users.labels.email')" [(value)]="vp.email" [maxLength]="255" [hasError]="val.hasError('email')" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('email')">{{ val.getError('email') }}</app-form-error>
    </app-string-input>
    <app-string-input type="text" [label]="trans('users.labels.nameProfile')" [(value)]="vp.name" [maxLength]="23" [hasError]="val.hasError('name')" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('name')">{{ val.getError('name') }}</app-form-error>
    </app-string-input>
    <app-string-input type="text" [label]="trans('users.labels.phoneNumber')" [(value)]="vp.phone" [hasError]="val.hasError('phone')" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('phone')">{{ val.getError('phone') }}</app-form-error>
    </app-string-input>
    <app-dropdown [label]="trans('users.labels.country')" [items]="countryList" [(value)]="vp.country" [hasError]="val.hasError('country')" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('country')">{{ val.getError('country') }}</app-form-error>
    </app-dropdown>
    <app-dropdown *ngIf="canSeePermission" [label]="trans('users.labels.permissionRule')" [items]="shownRuleList" [(value)]="vp.permissionRuleId" [hasError]="val.hasError('permissionId')" [disabled]="!canEdit || !canEditPermission" (valueChange)="onPermissionRuleChanged()">
      <app-form-error *ngIf="val.hasError('permissionId')">{{ val.getError('permissionId') }}</app-form-error>
    </app-dropdown>
    <app-string-input type="text" [label]="trans('users.labels.role')" [value]="getUserRoleText()" [disabled]="true"></app-string-input>
    <app-dropdown *ngIf="canEditCompany" [label]="trans('users.labels.company')" [items]="companyList" [(value)]="companyId" [disabled]="!canEdit"></app-dropdown>
    <div *ngIf="canSeeDeviceSetupTemplate && roleSupportsTemplates">
      <p class="heading">{{ trans('setup.device.userTemplates') }}</p>
      <div *ngFor="let iTemplate of templates" class="dropdown_with_delete">
        <app-dropdown [items]="deviceSetupTemplateService.listStorage" [value]="iTemplate.id" (valueChange)="onTemplateChanged($event, iTemplate.id)" [disabled]="!canEdit || !canEditDeviceSetupTemplate"></app-dropdown>
        <div [inlineSVG]="'assets/images/close.svg'" (click)="onTemplateDeleted(iTemplate.id)"></div>
      </div>
      <app-dropdown *ngIf="canEdit && canEditDeviceSetupTemplate && newTemplateSelected !== 0" [disabled]="true"></app-dropdown>
      <app-dropdown *ngIf="canEdit && canEditDeviceSetupTemplate && newTemplateSelected === 0" [items]="deviceSetupTemplateService.listStorage" [(value)]="newTemplateSelected" (valueChange)="onTemplateAdded($event)"></app-dropdown>
    </div>
    <app-boolean-input [label]="trans('users.labels.activeUser')" type="checkbox" [(value)]="vp.active" [disabled]="!canEdit"></app-boolean-input>
    <app-string-input *ngIf="isNew" type="password" [label]="trans('auth.form.password')" [(value)]="vp.password" [hasError]="val.hasError('password')" [disabled]="!canEdit">
      <app-form-error *ngIf="val.hasError('password')">{{ val.getError('password') }}</app-form-error>
    </app-string-input>
    <div class="mainButton" *ngIf="canEdit">
      <a href="#" *ngIf="!isNew" (click)="$event.preventDefault(); popup.show()">{{ trans('users.buttons.changePassword') }}</a>
      <i *ngIf="!isNew && password && !val.hasError('password')">{{ trans('users.labels.passwordWillBeChanged') }}</i>
      <br />
      <ng-template appTeleport="footer">
        <app-desktop-footer [buttons]="footerButtons" [loading]="isRequestLoading"></app-desktop-footer>
      </ng-template>
    </div>
  </div>
</div>
<app-popup2 #popup [content]="popupContent" (hidden)="vp.password = null"></app-popup2>
<ng-template #popupContent let-hide="hide">
  <div class="passwordChange">
    <h5 (click)="hide()">{{ trans('users.buttons.changePassword') }}</h5>
    <app-string-input type="password" [label]="trans('auth.form.password')" [(value)]="vp.password" [hasError]="val.hasError('password')">
      <app-form-error *ngIf="val.hasError('password')">{{ val.getError('password') }}</app-form-error>
    </app-string-input>
    <div class="buttons">
      <app-button type="bare-secondary" (click)="hide()">{{trans('general.cancel')}}</app-button>
      <app-button type="bare-secondary" (click)="hide(true)">{{trans('general.save')}}</app-button>
    </div>
  </div>
</ng-template>
