<app-company-page-layout gridTemplateColumns="1fr 1fr" *ngIf="desktopView">
  <ng-container *ngTemplateOutlet="innerContent"></ng-container>
</app-company-page-layout>

<ng-template #innerContent>
  <div class="header" *ngIf="desktopView">
    <div class="flex-1">
      <h4 class="title">{{trans('systems.menu.ipcom')}}</h4>
    </div>
    <app-icon-button *ngIf="us.currentUser.permissions.permissions.ipcom_settings.create && us.currentUser.company_id === 0" (click)="addIpcom()" [label]="trans('systems.titles.addNewIpComServer')" icon="assets/images/company/plus.svg"></app-icon-button>
    <app-company-columns [items]="columnConfig" [(value)]="columns.value" #cols></app-company-columns>
  </div>

  <app-ipcoms-data-table [columns]="columns.value" (modifyColumns)="modifyColumns.emit()" [searchLoader]="loader" [showHeaders]="desktopView"></app-ipcoms-data-table>
</ng-template>
<ng-container *ngIf="!desktopView">
  <div class="separator16"></div>
  <app-plus-button *ngIf="us.currentUser.permissions.permissions.ipcom_settings.create && us.currentUser.company_id === 0" (plusClicked)="addIpcom()"></app-plus-button>
</ng-container>
