import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegionService } from 'src/app/api/region.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupType } from 'src/app/models/popup-type';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { PopupService } from 'src/app/services/popup.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { ValidatorBuilder } from 'src/app/ui/validator';

@Component({
  selector: 'app-su-region',
  templateUrl: './su-region.component.html',
  styleUrls: ['./su-region.component.scss'],
})
export class SuRegionComponent extends LanguageAware implements OnInit, OnDestroy {
  public readonly desktopView = this.platform.isDesktopView();
  public readonly isNew = this.ar.routeConfig.path === 'new';
  public inputChanged = false;
  public isRequestLoading = false;
  public regionItem = null;
  public canEdit = this.us.currentUser.permissions?.permissions.regions.edit || this.us.currentUser.permissions?.permissions.regions.create;
  private original = null;
  private subscriptions: Subscription[] = [];
  public delayDuration = 2000;
  public footerButtons = [
    { label: this.trans('settings.buttons.save'),
      loadingLabel: this.trans('settings.buttons.saving'),
      loadingCompleteLabel: this.trans('settings.buttons.saved'),
      callback: () => this.onDesktopSave() },
    { label: this.trans('general.cancel'),  callback: () => this.navigateBack() },
  ];
  public val = new ValidatorBuilder<any>()
  .required('name', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.regionName'))}`)
  .required('api_host', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.regionHost'))}`)
  .required('api_path', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.regionPath'))}`)
  .required('region_version', `${this.trans('validation.required').replace(':attribute', this.trans('settings.labels.regionVersion'))}`)
  .regex('region_version', /^[0-9]+$/, `${this.trans('validation.numeric').replace(':attribute', this.trans('settings.labels.regionVersion'))}`)
  .build().bindContext(this);
  private regionIdChangeSubscription = this.ar.params.subscribe(async (params) => {
    if ( !this.desktopView ) { return; }
    const regionId = Number(params.regionId);
    if ( this.inputChanged || this.es.getId(TEditableComponent.Region) !== -1) {
      this.revert();
    }
    if (regionId && !isNaN(regionId)) {
      const region = this.us.currentUser.regions.find(reg => reg.id === regionId);
      if (!region) {
        throw new Error('Region not found');
      }
      this.es.beginComponentEdit(TEditableComponent.Region, region.id, region);
    }
    this.reinitVariables();
  });

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService,
    private router: Router,
    private ar: ActivatedRoute,
    private pp: PopupService,
    private messagebox: MessageboxService,
    private sanitizer: DomSanitizer,
    private region: RegionService,
  ) {
    super(cdRef);
    if(this.desktopView) {
      this.region.quickEditAllowed = false;
    }
    this.reinitVariables();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.es.endComponentEdit(TEditableComponent.Region);
    if(this.subscriptions.length > 0) {
      this.subscriptions.forEach(sub => {
        sub.unsubscribe();
      });
    }
    this.regionIdChangeSubscription?.unsubscribe();
    if(this.desktopView) {
      this.region.quickEditAllowed = true;
    }
  }

  public onDeleteClick(): void {
    const messageText = this.sanitizer.bypassSecurityTrustHtml(
      `${this.trans('settings.labels.deleteRegionInfo').replace(':region', this.regionItem.name)}: ${this.trans('general.areYouSure')}`);
    this.messagebox.open({
      buttons: this.messagebox.buttons.YesNo,
      headerText: this.trans('general.confirm'),
      messageContent: messageText,
      iconType: this.messagebox.iconType.Warning
    }, (messagebox) => {
      const yesClickSubscription = messagebox.yesClicked.subscribe(() => {
        this.delete();
      });
      this.subscriptions.push(yesClickSubscription);
    });
  }

  private delete() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api.delete('/region?id=' + this.regionItem.id, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.us.currentUser.regions = that.us.currentUser.regions.filter((r) => r.id !== that.regionItem.id);
          const navigationPath = that.platform.isDesktop() ? [companyRootRoute, 'settings', 'regions'] : [...that.g.getHomeUrl(), 'regions'] ;
          that.router.navigate(navigationPath);
        } else {
          that.messagebox.open({
            buttons: that.messagebox.buttons.Ok,
            headerText: that.trans('general.titleError'),
            iconType: that.messagebox.iconType.Error,
            messageContent: result.error
          });
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.messagebox.open({
          buttons: that.messagebox.buttons.Ok,
          headerText: that.trans('general.titleError'),
          iconType: that.messagebox.iconType.Error,
          messageContent: that.trans('auth.errors.serverSideError')
        });
      }
    );
  }

  private revert() {
    if (this.regionItem?.id === undefined) {
      return;
    }
    this.regionItem.name = this.original.name;
    this.regionItem.api_host = this.original.api_host;
    this.regionItem.api_path = this.original.api_path;
    this.regionItem.region_version = this.original.region_version;
    this.regionItem.api_host_to_use = this.original.api_host_to_use;
  }

  private async onDesktopSave() {
    const validationResult = await this.val.validate(this.regionItem);
    if(validationResult === false) { return; };
    const that = this;
    that.api.post('/region', that.regionItem, true).subscribe(
      async (result) => {
        if (result.success) {
          that.isRequestLoading = true;
          if (that.isNew) {
            that.regionItem.id = result.id;
            if (that.us.currentUser.regions === undefined) {
              that.us.currentUser.regions = [];
            }
            that.us.currentUser.regions.push(that.regionItem);
            that.router.navigate([companyRootRoute, 'settings', 'regions']);
          } else {
            Object.assign(that.original, that.regionItem);
            setTimeout(() => {
              that.isRequestLoading = false;
            }, 200);
            await new Promise<void>(() => setTimeout(() => {
              that.onValueChange();
            }, that.delayDuration));
          }
        } else {
          that.messagebox.open({
            buttons: that.messagebox.buttons.Ok,
            headerText: that.trans('general.titleError'),
            iconType: that.messagebox.iconType.Error,
            messageContent: result.error ? result.error : that.trans('general.errors.errorOccurred')
          });
          that.isRequestLoading = false;
        }
      },
      (error) => {
        that.messagebox.open({
          buttons: that.messagebox.buttons.Ok,
          headerText: that.trans('general.titleError'),
          iconType: that.messagebox.iconType.Error,
          messageContent: that.trans('auth.errors.serverSideError')
        });
        that.isRequestLoading = false;
      }
    );
  }

  private doSave() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api.post('/region', this.regionItem, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          if (that.regionItem.id === undefined) {
            that.regionItem.id = result.id;
            if (that.us.currentUser.regions === undefined) {
              that.us.currentUser.regions = [];
            }
            that.us.currentUser.regions.push(that.regionItem);
          }
          that.router.navigate([...this.g.getHomeUrl(), 'regions']);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      }
    );
  }

  public changeVersion() {
    const that = this;
    this.pp.showSlideout<DropdownItem<number>>(
      {
        headerText: this.trans('settings.labels.regionVersion'),
        items: [{ value: 0, label: '1' }, { value: 1, label: '2' }],
        onSubmit: (res) => {
          that.regionItem.region_version = res.value + 1;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public onValueChange() {
    if (this.isNew === false) {
      if(JSON.stringify(this.original) !== JSON.stringify(this.regionItem)) {
        this.inputChanged = true;
      } else {
        this.inputChanged = false;
      }
    }
  }

  private navigateBack() {
    this.revert();
    this.es.endComponentEdit(TEditableComponent.Region);
    this.router.navigate([companyRootRoute, 'settings', 'regions']);
  }

  private reinitVariables() {
    this.regionItem = this.isNew ? null : this.es.getEditableComponent(TEditableComponent.Region);
    this.original = this.es.getComponentBeforeModification(TEditableComponent.Region);
    this.headerBar.showHeader({
      headerText: this.regionItem === null ? this.trans('settings.labels.addRegion') : this.trans('settings.labels.editRegion'),
      backUrl: this.g.getHomeUrl().join('/') + '/regions',
      actionButtonText: this.regionItem === null || !this.us.currentUser.permissions?.permissions.regions.delete ? '' : this.trans('general.delete'),
    });
    if (this.regionItem !== null) {
      this.headerBar.onActionButtonClicked = () => {
        this.delete();
      };
    }
    if (this.regionItem === null) {
      this.regionItem = {
        id: undefined,
        name: '',
        api_host: '',
        api_path: '',
        region_version: 1,
        api_host_to_use: '',
      };
    }
    if ( this.canEdit ) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
      this.headerBar.onBackClicked = () => {
        this.revert();
      };
      this.footerBar.onButton1Click = () => {
        if (this.miniStatus.isVisible()) {
          this.miniStatus.flash();
          return;
        }
        this.revert();
        this.router.navigate([...this.g.getHomeUrl(), 'regions']);
      };
      this.footerBar.onButton2Click = () => {
        this.doSave();
      };
    }
    this.background.setGray();
  }

}
