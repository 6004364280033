import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { TDeviceUser } from 'src/app/models/device-user';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-transfer-from-list',
  templateUrl: './transfer-from-list.component.html',
  styleUrls: ['./transfer-from-list.component.scss'],
})
export class TransferFromListComponent extends LanguageAware implements OnInit {
  public people: TDeviceUser[] = [];
  constructor(
    cdRef: ChangeDetectorRef,
    private es: EditSystemService
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      backUrl: '*',
      headerText: this.trans('systems.labels.chooseFromList'),
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      history.back();
    };
    this.people = this.getPeople();
    this.background.setGray();
  }

  ngOnInit(): void {}

  public onPersonSelected(email: string) {
    this.es.emailToTransferTo = email;
    history.back();
  }

  private getPeople(): any[] {
    return this.systems.activeSystem.device_users.filter((u) => u.protegus_user_id !== 0 && u.email !== this.us.currentUser.email);
  }
}
