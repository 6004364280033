<ng-template #headerName let-class="className"><div [ngClass]="class">{{trans('tags.labels.name')}}</div></ng-template>
<ng-template #headerDescription let-class="className"><div [ngClass]="class">{{trans('tags.labels.description')}}</div></ng-template>
<ng-template #headerCompany let-class="className"><div [ngClass]="class">{{trans('tags.labels.company')}}</div></ng-template>
<ng-template #contentName let-value="item">
    <div [style.background-color]="value.color" [style.color]="value.textColor" class="tagName">{{value.name}}</div>
</ng-template>
<ng-template #deleteIcon>
    <div class='deleteIcon' [inlineSVG]="'assets/images/remove.svg'"></div>
</ng-template>
<ng-template #arrowIcon>
    <div class="arrow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.402 14.697">
            <path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/>
        </svg>
    </div>
</ng-template>
<ng-template #companyContent let-value="item.company_id">
    {{value === 0 ? '' : companyService.getCompany(value)?.name}}
</ng-template>
<app-data-table
    *ngIf="us.currentUser?.permissions.permissions.tags.view"
    class="tagsDatatable"
    [data]="loader"
    [slots]="{
      'header:name': headerName,
      'header:description': headerDescription,
      'header:company': headerCompany,
      name: contentName,
      'fake:delete': deleteIcon,
      'fake:arrow': arrowIcon,
      company: companyContent
    }"
    [columns]="columns"
    (modifyColumns)="modifyColumns.emit()"
    (rowClick)="onRowClick($event)"
    [dataSize]="this.tagService.tags.size"
    [showHeaders]="showHeaders"
>
</app-data-table>
