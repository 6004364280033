import { IPCom } from 'src/app/models/ipcom';
import http from '../http';
import { BasicSuccessResponse, objectToParams, Tag } from './common';

export type IPComSettings = {
    receivers: IPComReceiver[];
    outputs: IPComOutput[];
};

export type IPComReceiver = {
    id?: number;
    ipcom_id: number | 0;
    receiver_id: number;
    receiver_name: string;
    receiver_number: string;
    line_number: string;
    encryption_password: string;
    port: number;
    com: number[];
    smpp: number[];
    network_mode: 'Both' | 'TCP' | 'UDP' | 'Off';
    parent_id: number;
    outputs: number[];
    deviation_negative: number;
    deviation_positive: number;
    tags: Tag[];
    company_id: number;
    deleted?: boolean; // reikalingas tik trinant egzistuojantį
};

export type IPComOutput = {
    ipcom_id: number | 0;
    output_id: number;
    type: 0 | 1;
    ip: string;
    tcp_port: number;
    com_id: number;
    hb_enable: 'True' | 'False';
    hb_interval: number;
    active: 'True' | 'False';
    out_mode: IPComOutputMode;
    buffer_size: number;
    name: string;
    identificator: number;
    encryption_enabled?: 'True' | 'False';  // IPCom 4.64+
    sia_key?: string;                       // IPCom 4.64+
};

export type IPComReceiverForTemplate = Omit<IPComReceiver, 'id'> & { id: number; input_host: string; ipcom_name: string };

export enum IPComOutputMode {
    Surgard,
    Monas3,
    Surgard8,
    SurgardNoEnd,
    Ademco685,
    Ademco685Cid,
    Surgard2000,
    SiaDc09,
}

type ReadSettingsRequest = { id: number };
type IpcomRequest = { main: IPCom; settings: IPComSettings };
type WriteSettingsRequest = { id: number; settings: IPComSettings };
export type AddIpcomRequestResponse = BasicSuccessResponse & { id: number };
type TestIpcomRequest = Pick<IPCom, 'peer_name' | 'host' | 'port' | 'user_name'>;
type ReadSettingsRequestResponse = BasicSuccessResponse & { data: IPComSettings };
type TestIpcomRequestResponse = BasicSuccessResponse & { status: boolean; settings: IPComSettings | false };
type GetReceiversForTemplateRequestResponse = BasicSuccessResponse & { receivers: IPComReceiverForTemplate[] };

const saveIpcom = (req: IpcomRequest) => http.put<BasicSuccessResponse, IpcomRequest>('/v3/api/ipcom', req);
const addIpcom = (req: IpcomRequest) => http.post<AddIpcomRequestResponse, IpcomRequest>('/v3/api/ipcom', req);
const deleteIpcom = (req: {id: number}) => http.delete<BasicSuccessResponse, URLSearchParams>('/v3/api/ipcom', objectToParams(req));
const testIpcom = (req: TestIpcomRequest) => http.get<TestIpcomRequestResponse, URLSearchParams>('/v3/api/test-ipcom', objectToParams(req));
const getReceiversForTemplate = () => http.get<GetReceiversForTemplateRequestResponse, URLSearchParams>('/v3/api/ipcom-receivers', undefined);
const writeIPComSettings = (req: WriteSettingsRequest) => http.post<BasicSuccessResponse, WriteSettingsRequest>('/v3/api/ipcom-settings', req);
const readIPcomSettings = (req: ReadSettingsRequest) => http.get<ReadSettingsRequestResponse, URLSearchParams>('/v3/api/ipcom-settings', objectToParams(req), { silentOnError: true });

export default {
    ipcom: {
        add: addIpcom,
        save: saveIpcom,
        test: testIpcom,
        delete: deleteIpcom,
        read: readIPcomSettings,
        write: writeIPComSettings,
        getReceivers: getReceiversForTemplate,
    }
};
