import { Observable } from 'rxjs';
import http from '../http';
import { BasicSuccessResponse, ErrorResponse, CurrentUserData, WithLang, WithLastSystem } from './common';

type CheckEmailRequest = {
  email: string;
};
type CheckEmailResponse =
  | ErrorResponse
  | { success: true; isNew: true }
  | {
      success: true;
      isNew: false;
      isSoc: boolean;
      soc_type: string | null | 'google' | 'apple';
      isActive: boolean;
      isPasswordSet: boolean;
    };

export const checkEmail = (email: string): Observable<CheckEmailResponse> => http.post<CheckEmailResponse, CheckEmailRequest>('/v3/api/check-email', { email }, { auth: false });

type RegisterRequest = { email: string } & WithLang;
type RegisterResponse =
  | ErrorResponse
  | {
      // Super admin creation response
      success: true;
      activation_token: '';
      role: 0;
      password_hash: '-';
    }
  | { success: true; activation_token: string };

export const register = (email: string, lang?: string) =>
  http.post<RegisterResponse, RegisterRequest>(
    '/v3/api/register',
    {
      email,
      lang,
    },
    { auth: false }
  );

type ResendActivationRequest = ({ pinHash: string } | { hash: string } | { email: string }) & WithLang;
type ResendActivationResponse = ErrorResponse | { success: true; message: string; hash: string };

export const resendActivation = (req: ResendActivationRequest) => http.post<ResendActivationResponse, ResendActivationRequest>('/v3/api/resend-activation', req, { auth: false });

type ActivateRequest = ({ digitCode: string; pinHash: string } | { hash: string }) & WithLang;
type ActivateResponse = ErrorResponse | { success: true; password_hash: string; email: string };

export const activate = (req: ActivateRequest) =>
  http.post<ActivateResponse, ActivateRequest>('/v3/api/activate', req, {
    auth: false,
  });

type PasswordCreateRequest = {
  passHash: string;
  email: string;
  password: string;
};
type PasswordCreateReponse = ErrorResponse | BasicSuccessResponse;

export const passwordCreate = (req: PasswordCreateRequest) => http.post<PasswordCreateReponse, PasswordCreateRequest>('/v3/api/password-create', req, { auth: false });

type PasswordResetRequest = { email: string } & WithLang;
type PasswordResetResponse = ErrorResponse | BasicSuccessResponse;

export const passwordReset = (email: PasswordResetRequest['email']) => http.post<PasswordResetResponse, PasswordResetRequest>('/v3/api/reset', { email }, { auth: false });

type PasswordResetCompleteRequest = {
  token: string;
  password: string;
} & WithLang;
type PasswordResetCompleteResponse = ErrorResponse | (ErrorResponse & { errorType: 'password' }) | { success: true; message: string };

export const passwordResetComplete = (req: PasswordResetCompleteRequest) =>
  http.post<PasswordResetCompleteResponse, PasswordResetCompleteRequest>('/v3/api/reset/complete', req, { auth: false });

type LoginRequest = { email: string; password: string };
type LoginResponse = ErrorResponse | (BasicSuccessResponse & CurrentUserData & WithLastSystem);

export const login = (email: LoginRequest['email'], password: LoginRequest['password']) =>
  http.post<LoginResponse, LoginRequest>('/v3/api/login', { email, password }, { auth: false });

type NameCreateRequest = { name: string };
type NameCreateResponse = ErrorResponse | BasicSuccessResponse;

export const nameCreate = (name: NameCreateRequest['name']) =>
  http.post<NameCreateResponse, NameCreateRequest>('/v3/api/name-create', {
    name,
  });

export type GoogleLoginRequest = {
  id_token: string;
  ios?: boolean;
  at?: string;
  ac?: string;
  last_system?: number;
};
type GoogleLoginResponse =
  | ErrorResponse
  | (BasicSuccessResponse &
      CurrentUserData & {
        new_user: true;
        is_social_account: true;
      })
  | (BasicSuccessResponse & CurrentUserData & WithLastSystem);

export const googleLogin = (req: GoogleLoginRequest) => http.post<GoogleLoginResponse, GoogleLoginRequest>('/v3/api/check/google', req);

type AppleLoginRequest = {
  userId: string;
  email: string;
  authCode: string;
  name?: string;
  last_system?: number;
};

type AppleLoginResponse = GoogleLoginResponse;

export const appleLogin = (req: AppleLoginRequest) => http.post<AppleLoginResponse, AppleLoginRequest>('/v3/api/check/apple', req);

type AcceptInstallerInvitationRequest = { hash: string };
type AcceptInstallerInvitationResponse = ErrorResponse | (BasicSuccessResponse & { text: string });

export const acceptInstallerInvitation = (req: AcceptInstallerInvitationRequest) =>
  http.post<AcceptInstallerInvitationResponse, AcceptInstallerInvitationRequest>('/v3/api/accept-invitation', req);
