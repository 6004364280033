<div id='carea-grid'>
    <div class="group-background" id='carea-back'></div>
    <div id='carea-container'>
        <div class="list-item-v1 carea-item" *ngFor='let area of systems.activeSystem.areas'>
            <div [inlineSVG]="'assets/images/area-general-icon.svg'"></div>
            <div >{{area.queue_no}}</div>
            <div>
                <app-text-edit [isReadOnly]="area.status === panicButtonsAreaStatus" [value]='area.name' (exitedField)='submitNewName(area.id, $event)' [maxCharacters]="maxAreaLen"></app-text-edit>
            </div>
            <div></div>
        </div>
    </div>
</div>
