import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { PersistenceService } from 'src/app/api/persistence.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { TEventListItemData } from 'src/app/models/event-list-item-data';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { EventDescriptionService } from 'src/app/services/event-description.service';
import { companyRootRoute } from 'src/app/services/global.service';
import { ItemConfiguration } from '../company-columns/company-columns.component';
import { DataTableGetter } from '../data-table/data-table.component';
const defaultColumns = ['assign', 'default_name', 'active', 'cid_code'];

@Component({
  selector: 'app-event-assignment-data-table',
  templateUrl: './event-assignment-data-table.component.html',
  styleUrls: ['./event-assignment-data-table.component.scss']
})
export class EventAssignmentDataTableComponent extends LanguageAware implements OnInit, OnChanges {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: this.trans('settings.reactions.labels.assign'), value: 'assign' },
    { name: this.trans('events.settings.labels.nameEvent'), value: 'default_name' },
    { name: this.trans('general.enabled'), value: 'active' },
    { name: this.trans('events.settings.labels.cidCode'), value: 'cid_code' },
  ];
  @Input() reactionItem: any;
  @Input() assignments: Set<number>;
  @Output() public modifyColumns = new EventEmitter<void>();
  @Output() public eventToggled = new EventEmitter<any>();
  public readonly columns = this.persistance.bind('event_assignable_list_columns', defaultColumns);
  public searchPhrase: string;
  public searchVisible = false;
  public loader: DataTableGetter<TEventListItemData>;

  constructor(
    cdRef: ChangeDetectorRef,
    private persistance: PersistenceService,
    public edit: EditSystemService,
    public router: Router,
    public eventDescription: EventDescriptionService,
  ) {
    super(cdRef);
    this.eventDescription.loadDescriptions();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.assignments || changes.reactionItem) {
      this.loader = this.eventDescription.getEventDescriptionsGetter('', [...this.assignments]) as DataTableGetter<TEventListItemData>;
    }
  };

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.hideSearch();
  }

  public openEventDescriptionItem(item) {
    this.edit.beginComponentEdit(TEditableComponent.EventDescription, item.id, item);
    this.router.navigate([companyRootRoute, 'settings', 'event-descriptions', 'edit', item.id]);
  }

  public onSearchPhraseChange() {
    this.loader = this.eventDescription.getEventDescriptionsGetter(this.searchPhrase) as DataTableGetter<TEventListItemData>;
  }

  public showSearch(): void {
    this.searchVisible = true;
  }

  public hideSearch(): void {
    if(!this.searchVisible) { return; }
    this.searchVisible = false;
    this.searchPhrase = '';
    const searchInputField = document.getElementById('searchInputField');
    searchInputField.blur();
    this.onSearchPhraseChange();
  }

  public onToggleButtonClick(item) {
    this.eventToggled.emit(item);
  }

  public onRowClick(event) {}

}
