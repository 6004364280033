import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DisableMobileDataPopupComponent } from 'src/app/popups/disable-mobile-data-popup/disable-mobile-data-popup.component';
import { DeviceService } from 'src/app/services/device.service';
import { PopupService } from 'src/app/services/popup.service';
import { WifiService } from 'src/app/services/wifi.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-nearby-device-found',
  templateUrl: './nearby-device-found.component.html',
  styleUrls: ['./nearby-device-found.component.scss'],
})
export class NearbyDeviceFoundComponent extends LanguageAware implements OnInit, OnDestroy {
  private deviceNetworksListener: Subscription;
  public mobileDataIsOn = false;
  private networkStateListener = null;
  public deviceCount = 0;
  public singleDeviceWifiAP = '';
  private popup: DisableMobileDataPopupComponent = null;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    public wfs: WifiService,
    public ds: DeviceService,
    private pp: PopupService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    this.background.setGray();
    this.wfs.onDeviceNetworkFound.subscribe(() => {
      this.handleFoundDevices();
    });
    this.deviceNetworksListener = this.wfs.onDeviceNetworkFound.subscribe(() => {
      this.handleFoundDevices();
    });
    this.handleFoundDevices();
    this.headerBar.showHeader({
      headerText: this.trans('systems.labels.device'),
      backUrl: '../new-system',
      activatedRoute: this.r,
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.wfs.stopWifiDiscovery();
    this.deviceNetworksListener.unsubscribe();
    clearInterval(this.networkStateListener);
  }

  private handleFoundDevices() {
    this.deviceCount = this.wfs.getDeviceNetworks().length;
    if (this.wfs.getDeviceNetworks().length === 1) {
      this.footerBar.showFooter(this.trans('general.ok'), '', true, false);
      this.singleDeviceWifiAP = this.wfs.getDeviceNetworks()[0].ssid;
      this.wfs.selectDevice(this.singleDeviceWifiAP);
      this.footerBar.onButton1Click = () => {
        this.deviceSelected(this.singleDeviceWifiAP);
      };
    } else {
      //this.singleDeviceWifiAP = 'kazkas';
      this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
      this.footerBar.onButton1Click = () => {
        //that.deviceSelected(that.singleDeviceWifiAP);
        this.wfs.stopWifiDiscovery();
        this.router.navigate(['../new-system'], { relativeTo: this.r, replaceUrl: true });
      };
    }
  }

  private waitForMobileDataOff() {
    this.networkStateListener = setInterval(() => {
      const netStatus = window.jsi.getNetworkStatus();
      const statusObj: { mobile_data: boolean } = /*{ mobile_data: true };*/ JSON.parse(netStatus);
      if (statusObj.mobile_data !== undefined) {
        this.mobileDataIsOn = statusObj.mobile_data;
        if (!this.mobileDataIsOn) {
          if ( this.popup ) { this.popup.close(); }
          this.proceed();
        }
      }
    }, 2000);
  }

  public deviceSelected(ssid: string) {
    const netStatus = window.jsi.getNetworkStatus();
    const statusObj: { mobile_data: boolean } = /*{ mobile_data: false };*/ JSON.parse(netStatus);
    if (statusObj.mobile_data !== undefined) {
      this.mobileDataIsOn = statusObj.mobile_data;
    }
    this.wfs.selectDevice(ssid);
    if (this.mobileDataIsOn) {
      this.wfs.mobileDataIsOn(this.wfs.wasMobileDataOn() || this.mobileDataIsOn);
      this.pp.openPopup(DisableMobileDataPopupComponent, {
        paramSetter: (p) => {
          this.popup = p;
          p.closed.subscribe(() => {
            this.popup = null;
          });
          p.onCancel = () => {
            this.popup.close();
            this.wfs.stopWifiDiscovery();
            this.router.navigate(['../new-system'], { relativeTo: this.r, replaceUrl: true });
          };
        }
      });
      this.waitForMobileDataOff();
    } else {
      this.proceed();
    }
  }

  private proceed() {
    this.router.navigate(['../wifi-details'], { relativeTo: this.r, replaceUrl: true });
  }
}
