import { Injectable } from '@angular/core';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageService } from 'src/app/services/language.service';
import { LoggerService } from 'src/app/api/logger.service';
import { MiniStatusService } from 'src/app/services/mini-status.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { G16 } from '../models/g16-devices';
import { G16Device } from '../models/types';
import { HwfwService } from './hwfw.service';

@Injectable({
  providedIn: 'root',
})
export class ConfiguratorService {
  private changes = [];
  public currentConfiguration: G16Device = null;

  constructor(
    private lang: LanguageService,
    private api: ApiRequestService,
    private ms: MiniStatusService,
    private toaster: ToasterService,
    private g: GlobalService,
    private l: LoggerService,
    private fw: HwfwService,
  ) {}

  public hasChanges(): boolean {
    return this.changes.length > 0;
  }

  /**
   * Užregistruoja lauko pakeitimą istorijoje
   *
   * @param oldValue Sena reikšmė
   * @param newValue Nauja reikšmė
   * @param fieldName Lauko pavadinimas (išverstas)
   * @param fieldPath Kelias iki lauko duomenų struktūroje. (Kartu su pačiu lauku)
   */
  public change(oldValue, newValue, fieldName, fieldPath: string) {
    const found = this.changes.find((c) => c.path === fieldPath);
    if (found === undefined) {
      const theChange = {
        name: fieldName,
        old: oldValue,
        new: newValue,
        path: fieldPath,
      };
      this.changes.push(theChange);
      this.l.log('Pakeista lauko reikšmė. ', 'G16Config', theChange);
    } else if (found.old === newValue) {
      this.l.log('Lauko pakeitimas atšauktas. ', 'G16Config', found.path);
      this.changes = this.changes.filter((c) => c.path !== fieldPath);
    } else {
      found.new = newValue;
      this.l.log('Atnaujinta lauko reikšmė. ', 'G16Config', found);
    }
  }

  public clearChanges() {
    this.changes = [];
  }

  public setCurrentConfiguration(config: any) {
    this.currentConfiguration = config;
  }

  public isEDevice(): boolean {
    const stringHwId = this.g.configurableDevice.hwId;
    const intHwId = parseInt(stringHwId, 16);
    return intHwId === G16.E16_3A || intHwId === G16.E16T_3C;
  }

  public isTDevice(): boolean {
    const stringHwId = this.g.configurableDevice.hwId;
    const intHwId = parseInt(stringHwId, 16);
    return [G16.E16T_3C, G16.G16T_45, G16.G16T_46, G16.G16T_47, G16.G16T_48, G16.G16T_50].includes(intHwId);
  }

  public isDevice(dev: G16): boolean {
    const stringHwId = this.g.configurableDevice.hwId;
    const intHwId = parseInt(stringHwId, 16);
    return dev === intHwId;
  }

  /**
   * Išsaugo boolean lauko pakeitimą ir užregistruoja jį pakeitimų istorijoje.
   *
   * @param value Nauja lauko reikšmė
   * @param fieldName Lauko pavadinimas
   * @param basePath Lauko buvimo vieta duomenų struktūroje. Funkcija atlieka: __basePath__ + __fieldName__ gaunam pilną kelią.
   * @param keywordBasePath Vertimų rakto sekcijos pavadinimas su visais lygiais iki lauko pavadinimo.
   * Funkcija atlieka: __keywordBasePath__ + __fieldName__ gaunam pilną vertimo raktą.
   */
  public changeBoolean(value: boolean, fieldName: string, basePath: string, keywordBasePath: string, translationKeyDiffers?: boolean) {
    const parts = basePath.split('.');
    let holder = this.currentConfiguration;
    for (const iPart of parts) {
      holder = holder[iPart];
    }
    this.change(
      holder[fieldName],
      value,
      this.lang.get('config_g16.sections.' + (translationKeyDiffers === undefined || !translationKeyDiffers ? keywordBasePath + '.' + fieldName : keywordBasePath)),
      basePath + '.' + fieldName
    );
    holder[fieldName] = value;
  }

  public processInputEvents(data: any, inputCount: number) {
    if (data.code !== '29' || data.settings.events === undefined) {
      data.settings.events = [
        {
          queueNo: 0,
          name: 'BUS_FAULT',
          event: this.getDefaultEvent('333', data.queueNo + 1, 1, false),
          restore: this.getDefaultEvent('333', data.queueNo + 1, 1, true),
        },
      ];
    }
    if (data.code === '29') {
      for (let i = 1; i < inputCount + 1; i++) {
        if (data.settings.events.length < i + 1) {
          data.settings.events.push({
            queueNo: i,
            name: 'INPUT' + i,
            event: this.getDefaultEvent('130', data.queueNo + 1, i, false),
            restore: this.getDefaultEvent('130', data.queueNo + 1, i, true),
            objectId: '',
            inputType: 0,
          });
        }
      }
      if (data.settings.events.length > inputCount + 1) {
        // +1 nes BUS_FAULT
        for (let i = data.settings.events.length; i > inputCount + 1; i--) {
          data.settings.events.pop();
        }
      }
    }
  }

  private getDefaultEvent(code: string, queueNo: number, zoneNumber: number, isRestore: boolean) {
    return {
      queueNo,
      enabled: true,
      active: true,
      classificator: isRestore ? 1 : 0,
      cid: code,
      area: '9' + queueNo,
      zone: '00' + zoneNumber,
      siaCode: '',
      sia42Code: '',
    };
  }

  public fwIsGood(): boolean {
    if (this.g.configurableDevice.hwId === '47') {
      return this.fw.firmwareIsGreaterThanOrEqual(this.fw.minimumFwUSAG16T);
    } else {
      return this.fw.firmwareIsGreaterThanOrEqual(this.fw.minimumFwVersion);
    }
  }
}
