import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { BooleanFormInputComponent, NumberFormInputComponent, StringFormInputComponent } from './form-input/form-input.component';
import { FormsModule } from '@angular/forms';
import { PopupComponent as PopupComponent2 } from './popup/popup.component';
import { TeleportModule } from '../teleport/teleport.module';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DatetimeInputComponent } from './datetime-input/datetime-input.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { StepperComponent } from './stepper/stepper.component';
import { DirectivesModule } from '../directives/directives.module';
import { BackButtonComponent } from './back-button/back-button.component';
import { ChipComponent } from './chip/chip.component';
import { MessageboxComponent } from '../popups/messagebox/messagebox.component';
import { BasePopupComponent } from '../popups/base-popup/base-popup.component';
import { SelectInstallerPopupComponent } from '../popups/select-installer-popup/select-installer-popup.component';
import { RadioButtonComponent } from '../components/radio-button/radio-button.component';
import { DesktopFooterComponent } from './desktop-footer/desktop-footer.component';
import { ToggleButtonComponent } from '../components/toggle-button/toggle-button.component';
import { NameValueComponent } from '../components/name-value/name-value.component';
import { EventAssignmentDataTableComponent } from '../company/components/event-assignment-data-table/event-assignment-data-table.component';
import { DataTableComponent } from '../company/components/data-table/data-table.component';
import { CompanyColumnsComponent } from '../company/components/company-columns/company-columns.component';
import { AccountSwitcherComponent } from '../login/components/account-switcher/account-switcher.component';
import { GroupboxComponent } from '../components/groupbox/groupbox.component';
import { SettingsComponent } from '../sadmin/settings/settings.component';
import { CompaniesDataTableComponent } from '../company/components/companies-data-table/companies-data-table.component';
import { CompanyListComponent } from '../sadmin/company-list/company-list.component';
import { PlusButtonComponent } from '../components/plus-button/plus-button.component';
import { PermissionsDataTableComponent } from '../company/components/permissions-data-table/permissions-data-table.component';
import { PermissionListComponent } from '../sadmin/permission-list/permission-list.component';
import { TagListComponent } from '../sadmin/tag-list/tag-list.component';
import { TagsDataTableComponent } from '../company/components/tags-data-table/tags-data-table.component';
import { TagInputComponent } from './tag-input/tag-input.component';
import { InlineTagListComponent } from './inline-tag-list/inline-tag-list.component';
import { EventsDatetimePickerComponent } from './events-datetime-picker/events-datetime-picker.component';
import { ActiveSystemComponent } from '../system/active-system/active-system.component';
import { RouterModule } from '@angular/router';
import { TabControlComponent } from './tab-control/tab-control.component';
import { IpcomReceiversDataTableComponent } from '../company/components/ipcom-receivers-data-table/ipcom-receivers-data-table.component';
import { PopupComponent } from '../popups/popup/popup.component';

@NgModule({
  declarations: [
    ButtonComponent,
    StringFormInputComponent,
    NumberFormInputComponent,
    BooleanFormInputComponent,
    PopupComponent2,
    IconButtonComponent,
    DropdownComponent,
    DatetimeInputComponent,
    FormErrorComponent,
    StepperComponent,
    BackButtonComponent,
    ChipComponent,
    BasePopupComponent,
    MessageboxComponent,
    SelectInstallerPopupComponent,
    RadioButtonComponent,
    DesktopFooterComponent,
    ToggleButtonComponent,
    NameValueComponent,
    DataTableComponent,
    EventAssignmentDataTableComponent,
    AccountSwitcherComponent,
    CompanyColumnsComponent,
    GroupboxComponent,
    SettingsComponent,
    CompaniesDataTableComponent,
    CompanyListComponent,
    PlusButtonComponent,
    PermissionsDataTableComponent,
    PermissionListComponent,
    TagListComponent,
    TagsDataTableComponent,
    TagInputComponent,
    InlineTagListComponent,
    EventsDatetimePickerComponent,
    ActiveSystemComponent,
    TabControlComponent,
    IpcomReceiversDataTableComponent,
    PopupComponent,
  ],
  imports: [CommonModule, FormsModule, TeleportModule, DirectivesModule, RouterModule],
  exports: [
    CommonModule,
    ButtonComponent,
    StringFormInputComponent,
    NumberFormInputComponent,
    BooleanFormInputComponent,
    PopupComponent2,
    IconButtonComponent,
    DropdownComponent,
    DatetimeInputComponent,
    FormErrorComponent,
    StepperComponent,
    DirectivesModule,
    BackButtonComponent,
    ChipComponent,
    BasePopupComponent,
    MessageboxComponent,
    SelectInstallerPopupComponent,
    RadioButtonComponent,
    DesktopFooterComponent,
    ToggleButtonComponent,
    NameValueComponent,
    DataTableComponent,
    EventAssignmentDataTableComponent,
    AccountSwitcherComponent,
    CompanyColumnsComponent,
    GroupboxComponent,
    SettingsComponent,
    CompaniesDataTableComponent,
    CompanyListComponent,
    PlusButtonComponent,
    PermissionsDataTableComponent,
    PermissionListComponent,
    TagListComponent,
    TagsDataTableComponent,
    TagInputComponent,
    InlineTagListComponent,
    EventsDatetimePickerComponent,
    ActiveSystemComponent,
    TabControlComponent,
    IpcomReceiversDataTableComponent,
    PopupComponent,
  ],
})
export class UiModule {}
