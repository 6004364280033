import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PermissionRole } from 'src/api/v3/common';
import { UserService } from 'src/app/api/user.service';
import { LanguageService } from 'src/app/services/language.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';
import { IPopup } from '../IPopup';

@Component({
  selector: 'app-select-installer-popup',
  templateUrl: './select-installer-popup.component.html',
  styleUrls: ['./select-installer-popup.component.scss']
})
export class SelectInstallerPopupComponent implements OnInit, AfterViewInit, IPopup {
  @ViewChild('basePopup') basePopup: BasePopupComponent;
  public popupName = 'SelectInstaller';
  public selection = -1;
  public closed: EventEmitter<void> = new EventEmitter();
  @Input() installerList: any[] = [];
  @Output() selectionCancelled: EventEmitter<void> = new EventEmitter();
  @Output() selectionConfirmed: EventEmitter<number> = new EventEmitter();
  public PermissionRole = PermissionRole;

  constructor(
    public us: UserService,
    public ls: LanguageService,
  ) { }

  ngOnInit(): void { }
  ngAfterViewInit() { }

  public close() {
    this.basePopup.hidePopup();
  }
}
