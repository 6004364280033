import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { EventDescriptionService } from 'src/app/services/event-description.service';

@Component({
  selector: 'app-cp-edit-events',
  templateUrl: './cp-edit-events.component.html',
  styleUrls: ['./cp-edit-events.component.scss'],
})
export class CpEditEventsComponent extends LanguageAware implements OnInit {

  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService, public eventDescription: EventDescriptionService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.eventDescriptions'),
      backUrl: this.g.getMobileSettingsUrl().join('/'),
      showPlus: this.us.currentUser.permissions?.permissions.reactions.create,
    });
    this.background.setGray();
    this.headerBar.onActionButtonClicked = () => {
      this.es.beginComponentEdit(TEditableComponent.EventDescription, 0, {
        default_name: '',
        id: 0,
        qualifier: 'E',
        event_code: '000',
        reaction_id: this.us.currentUser.reactions[0].id,
        active: false,
        company_id: this.us.currentUser.company_id,
        area_event: true,
      });
      this.router.navigate([...this.g.getHomeUrl(), 'add-event']);
    };
    this.eventDescription.loadDescriptions();
    this.es.endComponentEdit(TEditableComponent.EventDescription);
  }

  ngOnInit(): void {}

  public openEventForEditing(event: any) {
    this.es.beginComponentEdit(TEditableComponent.EventDescription, event.id, event);
    this.router.navigate([...this.g.getHomeUrl(), 'edit-event']);
  }

}
