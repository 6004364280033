import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { isDesktop } from 'src/app/api/platform.service';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { DeviceService } from 'src/app/services/device.service';
import { MessageboxService } from 'src/app/services/messagebox.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-new-system-offline',
  templateUrl: './new-system-offline.component.html',
  styleUrls: ['./new-system-offline.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewSystemOfflineComponent extends LanguageAware implements OnInit {
  public AppSettings = AppSettings;
  public isDesktop = isDesktop();

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    public ns: NewSystemService,
    private ds: DeviceService,
    private r: ActivatedRoute,
    private pp: PopupService,
    private msg: MessageboxService,
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.checkDevice'),
      backUrl: '*',
    });
    this.background.setGray();
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      history.back();
    };
  }

  ngOnInit(): void {}

  public retry() {
    this.miniStatus.show(this.trans('general.checking'));
    this.api.get('/online?checkAll=true&imei=' + this.ns.getUID() + '&mpass=' + this.ns.getMpass(), true).subscribe(
      (result) => {
        if (result.success && result.online) {
          this.checkIfInForeign(result.srv);
        } else if (!result.success) {
          this.miniStatus.hide();
          this.toaster.postError(result.error);
        } else if (result.errorType !== undefined && result.errorType === 'MPASS_IS_INCORRECT') {
          this.pp.showPopup(
            {
              headerText: this.trans('systems.labels.mpass'),
              field1: {
                maxLen: 6,
                oldValue: '',
              },
              onSubmit: (res) => {
                this.ns.setMpass(res.value1.toString(10));
                this.retry();
              },
              onCancel: () => {
                this.miniStatus.hide();
              },
            },
            PopupType.Number
          );
        } else if (result.hwId && result.hwId > 0 && !isDesktop()) {
          this.miniStatus.hide();
          if (result.hwId === 0x37) {
            this.ds.setHwId(result.hwId);
            this.router.navigate(['../scan-nearby-devices'], { relativeTo: this.r });
          } else if (result.hwId === 0x3b && result.revision && this.ds.revisionStartsWith(result.revision, ['1', '2', '3E', '52'])) {
            this.ds.setHwId(result.hwId);
            this.router.navigate(['../scan-nearby-devices'], { relativeTo: this.r });
          }
        } else {
          this.miniStatus.hide();
        }
      },
      (error) => {
        this.miniStatus.hide();
        this.toaster.postError(this.trans('auth.errors.serverSideError'));
      }
    );
  }

  public startWifiPath(hwId: number) {
    this.ds.setHwId(hwId);
    this.router.navigate(['../scan-nearby-devices'], { relativeTo: this.r });
  }

  private checkIfInForeign(ipcom: number | undefined) {
    if (ipcom !== undefined) {
      this.ns.setIpcom(ipcom);
    }
    this.api.post('/get-system-info-new', {
      systemUid: this.ns.getUID(),
      mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
      srv: ipcom === undefined ? 0 : ipcom,
    }, true).subscribe(
      (result) => {
        this.miniStatus.hide();
        if ( !result.success ) {
          this.toaster.postError(result.error);
          return;
        }

        this.ns.setDeviceInfo(result.data);
        if ( !result.data.isInForeignRegion ) {
          this.log('Modulis musu regione, einam toliau');
          if ( this.us.currentUser.permissions.permissions.dev_setup_templates.execute ) {
            this.router.navigate(['../select-template'], { relativeTo: this.r });
          } else {
            this.router.navigate(['../add-system-details'], { relativeTo: this.r });
          }
          return;
        }
        this.log('Modulis kitame regione. Tikrinam ar galima perardersuot.');
        const foreignRegionName = result.data.foreignRegion;
        if (
          result.data.supported_commands.indexOf('.18.') === -1 &&
          !result.data.supported_commands.endsWith('.18') &&
          result.data.supported_commands.indexOf('.22.') === -1 &&
          !result.data.supported_commands.endsWith('.22')
        ) {
          this.log('Modulis nepalaiko reikiamos komandos');
          this.msg.open({
            buttons: this.msg.buttons.Ok,
            headerText: this.trans('systems.titles.notSupported'),
            messageContent: this.trans('systems.errors.devRegUnsupported').replace(':region', foreignRegionName)
          });
        } else {
          if (result.foreignSystem !== null && result.ownRegion !== null && result.foreignSystem.length > 0) {
            this.log('Modulis palaiko komandą, bet sename regione rasta sistema.');
            this.ns.foreignSystem = result.foreignSystem[0];
            this.ns.foreignRegionName = foreignRegionName;
            this.ns.ownRegion = result.ownRegion;
            if ( this.us.currentUser.permissions.permissions.dev_setup_templates.execute ) {
              this.router.navigate(['../select-template'], { relativeTo: this.r });
            } else {
              this.router.navigate(['../confirm-device-transfer'], { relativeTo: this.r });
            }
          } else {
            this.log('Modulis palaiko komandą. Einam toliau.');
            if ( this.us.currentUser.permissions.permissions.dev_setup_templates.execute ) {
              this.router.navigate(['../select-template'], { relativeTo: this.r });
            } else {
              this.router.navigate(['../add-system-details'], { relativeTo: this.r });
            }
          }
        }
      },
      (error) => {
        this.miniStatus.hide();
      }
    );
  }
}
