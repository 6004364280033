<div class="bold_header">{{trans('users.labels.superAdmins')}}</div>
<div class="white_round suau_item" (click)="goToProfile()">
    <div *ngFor="let iSu of us.superAdmins; let i = index">
        <div [ngClass]="{ 'button-double-line-arrow': true, 'button-double-line-arrow-border': i !== (us.superAdmins.length - 1) }" (click)="editUser(iSu.id)">
            <div class="dbla_text">
                <div>{{iSu.name}}<span class="suau_active" *ngIf="iSu.active === 1"><div [inlineSVG]="'assets/images/check.svg'"></div></span></div>
                <div>{{iSu.email}}</div>
            </div>
            <div class="dbla_delete" *ngIf="canShowDelete && us.currentUser.id !== iSu.id"><svg viewBox="0 0 13.426 13.423" (click)="deleteUser(iSu, $event)"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#666"/></svg></div>
            <div class="dbla_arrow"><div [inlineSVG]="'assets/images/forward.svg'"></div></div>
        </div>
    </div>
</div>
<div class="bold_header">{{trans('users.labels.companies')}}</div>
<div class="white_round suau_item">
    <div *ngFor="let iCompany of us.companies; let i = index">
        <div [ngClass]="{ 'button-double-line-arrow': true, 'button-double-line-arrow-border': i !== (us.companies.length - 1) }" (click)="editUser(iCompany.id)">
            <div class="dbla_text">
                <div>{{iCompany.name}}<span class="suau_active" *ngIf="iCompany.active === 1"><div [inlineSVG]="'assets/images/check.svg'"></div></span></div>
                <div>{{iCompany.email}}</div>
            </div>
            <div class="dbla_delete" *ngIf="canShowDelete"><svg viewBox="0 0 13.426 13.423" (click)="deleteUser(iCompany, $event)"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#666"/></svg></div>
            <div class="dbla_arrow"><div [inlineSVG]="'assets/images/forward.svg'"></div></div>
        </div>
    </div>
</div>
<div *ngIf='us.companyInstallers.length > 0'>
    <div class="bold_header">{{trans('users.labels.companyInstallers')}}</div>
    <div class="white_round suau_item">
        <div *ngFor="let iInstaller of us.companyInstallers; let i = index">
            <div [ngClass]="{ 'button-double-line-arrow': true, 'button-double-line-arrow-border': i !== (us.companyInstallers.length - 1) }" (click)="editUser(iInstaller.id)">
                <div class="dbla_text">
                    <div>{{iInstaller.name}}<span class="suau_active" *ngIf="iInstaller.active === 1"><div [inlineSVG]="'assets/images/check.svg'"></div></span></div>
                    <div>{{iInstaller.email}}</div>
                </div>
                <div class="dbla_delete" *ngIf="canShowDelete"><svg viewBox="0 0 13.426 13.423" (click)="deleteUser(iInstaller, $event)"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#666"/></svg></div>
                <div class="dbla_arrow"><div [inlineSVG]="'assets/images/forward.svg'"></div></div>
            </div>
        </div>
    </div>
</div>
<div *ngIf='us.simpleUsers.length > 0'>
    <div class="bold_header">{{trans('systems.titles.users')}}</div>
    <div class="white_round suau_item">
        <div *ngFor="let iUser of us.simpleUsers; let i = index">
            <div [ngClass]="{ 'button-double-line-arrow': true, 'button-double-line-arrow-border': i !== (us.simpleUsers.length - 1) }" (click)="editUser(iUser.id)">
                <div class="dbla_text">
                    <div>{{iUser.name}}<span class="suau_active" *ngIf="iUser.active === 1"><div [inlineSVG]="'assets/images/check.svg'"></div></span></div>
                    <div>{{iUser.email}}</div>
                </div>
                <div class="dbla_delete" *ngIf="canShowDelete"><svg viewBox="0 0 13.426 13.423" (click)="deleteUser(iUser, $event)"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#666"/></svg></div>
                <div class="dbla_arrow"><div [inlineSVG]="'assets/images/forward.svg'"></div></div>
            </div>
        </div>
    </div>
</div>
<div class="separator16"></div>
<app-plus-button *ngIf="us.currentUser.permissions?.permissions.users.create" (plusClicked)="addUser()"></app-plus-button>