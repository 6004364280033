import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { DeviceService } from 'src/app/services/device.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { WifiService } from 'src/app/services/wifi.service';
import { LanguageAware } from '../language-aware';
import wp17animation from '../../../assets/animations/wp17_pair.json';
import sp3animation from '../../../assets/animations/sp3_pair.json';
import { replaceProtegusColorWithBrandColor } from '../../general/lottie-helper';
import { AppSettings } from 'src/app-settings';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-scan-nearby-devices',
  templateUrl: './scan-nearby-devices.component.html',
  styleUrls: ['./scan-nearby-devices.component.scss'],
})
export class ScanNearbyDevicesComponent extends LanguageAware implements OnInit, OnDestroy {
  public AppSettings = AppSettings;
  private troubleshootTimer = null;
  public showTroubleshoot = false;
  public deviceNameText = '';
  private hwId = 0;
  public deviceType = '';
  private deviceNetworksListener: Subscription;
  public wifiAnimationOptions: AnimationOptions = {
    animationData: replaceProtegusColorWithBrandColor(wp17animation),
  };
  public sp3wifiAnimationOptions: AnimationOptions = {
    animationData: replaceProtegusColorWithBrandColor(sp3animation),
  };
  private wifiErrorSubscriber: Subscription;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private ns: NewSystemService,
    private ds: DeviceService,
    private wfs: WifiService,
    private r: ActivatedRoute,
  ) {
    super(cdRef);
    if (ns.getUID() === '') {
      this.router.navigate(['../new-system'], { relativeTo: this.r, replaceUrl: true });
      return;
    }

    this.headerBar.showHeader({
      headerText: this.platform.isApple() ? '' : this.trans('systems.titles.scanNearbyDevices'),
      backUrl: '../new-system-offline',
      activatedRoute: this.r,
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.wfs.stopWifiDiscovery();
      this.router.navigate(['../new-system'], { relativeTo: this.r, replaceUrl: true });
    };
    this.headerBar.onBackClicked = () => {
      this.wfs.stopWifiDiscovery();
    };

    this.wfs.selectDevice('');
    this.wfs.selectOther('');
    setTimeout(() => {
      this.wfs.startWifiDiscovery();
    }, 1000);
    if (!this.platform.isApple()) {
      this.troubleshootTimer = setTimeout(() => {
        this.showTroubleshoot = true;
        this.stopTroubleshootTimer();
      }, 250);
    } else {
      this.showTroubleshoot = true;
      this.miniStatus.hide();
    }

    this.hwId = this.ds.getHwId();
    this.deviceType = this.ds.getDeviceTypeById(this.hwId);
    this.deviceNameText = this.ds.getDeviceNameById(this.hwId);
    this.background.setGray();
    this.deviceNetworksListener = this.wfs.onDeviceNetworkFound.subscribe(() => {
      this.router.navigate(['../nearby-device-found'], { relativeTo: this.r });
    });
    this.wifiErrorSubscriber = this.wfs.onWifiError.subscribe(() => {
      this.wfs.stopWifiDiscovery();
      this.router.navigate(['../new-system-offline'], { relativeTo: this.r, replaceUrl: true });
    });
  }

  ngOnInit(): void {}

  private stopTroubleshootTimer() {
    clearTimeout(this.troubleshootTimer);
    this.troubleshootTimer = null;
  }

  ngOnDestroy() {
    this.stopTroubleshootTimer();
    this.deviceNetworksListener?.unsubscribe();
    this.wifiErrorSubscriber?.unsubscribe();
  }

  public openAppleWifiSettings() {
    this.platform.appleHandler().openWifiWindow.postMessage('');
  }

  public performCommunicationWithDevice() {
    this.router.navigate(['../wifi-details'], { relativeTo: this.r, replaceUrl: true });
  }
}
