<div class="page" [ngClass]="[columns.value.length === 0 ? 'nocols' : '']">
  <div>
    <div class="header">
      <div class="flex-1">
        <h4>{{trans('systems.menu.events')}}</h4>
        <ng-container *ngIf="columns.value.length !== 0">
          <app-company-events-filter class="full-width" [contextCriteria]="contextCriteria" (criteriaChanged)="criteria = $event"></app-company-events-filter>
        </ng-container>
      </div>
      <app-company-columns [items]="columnConfig" [(value)]="columns.value" #cols></app-company-columns>
    </div>
    <app-events-data-table [columns]="columns.value" [criteria]="criteria" (modifyColumns)="cols.show()" (contextCriteria)="contextCriteria = $event"></app-events-data-table>
  </div>
</div>
