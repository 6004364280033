import { URLSearchParams } from 'url';
import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToParams, SystemData, SystemPgmData, WithWarning } from './common';
import { AreaState } from './system.area';

type GetSystemInfoNewRequest = {
  systemUid: string;
  mpass?: string;
  srv?: number;
};
export type GetSystemInfoNewResponse =
  | ErrorResponse
  | {
      success: true;
      foreignSystem: null | object[]; //TODO: better type
      srv: number;
      ownRegion: {
        id: number;
        name: string;
        api_host: string;
        api_path: string;
        region_version: number;
      }; //TODO: better type
      data: {
        bt: string;
        central_panel: number;
        dr: string;
        fireReset: boolean;
        foreignRegion: string;
        hwId: string;
        ipcom: number;
        isInForeignRegion: boolean;
        ns: string;
        objectId: string;
        outputs: string;
        signalLevel: string;
        sn: string;
        supported_commands: string;
        supports_custom_outputs: string;
        uid: string;
        version: string;
        zones: string;
        panel?: any; // TODO: better type
      }; //TODO: This is only GV17
    };

export const getSystemInfoNew = (req: GetSystemInfoNewRequest) => http.post<GetSystemInfoNewResponse, GetSystemInfoNewRequest>('v3/api/get-system-info-new', req);

type GetSystemStatusNewRequest = {
  systemUid: string;
  mpass?: string;
  srv?: number;
};
export type GetSystemStatusNewResponseData = {
  areas: Record<number, string>;
  error: string;
  newType: boolean;
  pgms: Record<
    number,
    {
      data2?: string;
      enabled: boolean;
      on: boolean;
      io_type?: '0' | '1' | '2';
    } & Partial<Pick<SystemPgmData, 'io_ability' | 'schedule_no'>>
  >;
  sensors: [];
  signal: string;
  troubles: {
    battery: boolean;
    ac_loss: boolean;
    aux_current: boolean;
    bell: boolean;
    clock: boolean;
    communication: boolean;
    com_bus: boolean;
    mci: boolean;
    zn_tamper: boolean;
    fire: boolean;
  };
  zones: Record<
    number,
    {
      enabled: boolean;
      alarm: boolean;
      failure: boolean;
      bypass: boolean;
    }
  >;
}; //TODO: better type

export type GetSystemStatusNewResponse = ErrorResponse | { success: true; data: GetSystemStatusNewResponseData };

export const getSystemStatusNew = (req: GetSystemStatusNewRequest) => http.post<GetSystemStatusNewResponse, GetSystemStatusNewRequest>('/v3/api/get-system-status-new', req);

// eslint-disable-next-line no-shadow
enum CharSets {
  Windows1252 = 0,
  Iso885913,
  Windows1251,
  Iso88592,
  Iso88597,
  Windows1254,
  Iso88598,
  Arabic,
  Unknown,
}

type NamesResponse = { success: true; data: string[] };
type NamesRequest = {
  systemUid: string;
  mpass?: string;
  srv?: number;
  hwId: string;
  charset: CharSets;
};

type GetSystemZoneNamesNewRequest = NamesRequest;
type GetSystemZoneNamesNewResponse = ErrorResponse | NamesResponse;

export const getSystemZoneNamesNew = (req: GetSystemZoneNamesNewRequest) =>
  http.post<GetSystemZoneNamesNewResponse, GetSystemZoneNamesNewRequest>('/v3/api/get-system-zone-names-new', req);

type GetSystemAreasNamesNewRequest = NamesRequest;
type GetSystemAreasNamesNewResponse = ErrorResponse | NamesResponse;

export const getSystemAreasNamesNew = (req: GetSystemAreasNamesNewRequest) =>
  http.post<GetSystemAreasNamesNewResponse, GetSystemAreasNamesNewRequest>('/v3/api/get-system-area-names-new', req);

type GetSystemSensorNamesNewRequest = NamesRequest;
type GetSystemSensorNamesNewResponse = ErrorResponse | NamesResponse;

export const getSystemSensorNamesNew = (req: GetSystemSensorNamesNewRequest) =>
  http.post<GetSystemSensorNamesNewResponse, GetSystemSensorNamesNewRequest>('/v3/api/get-system-sensor-names-new', req);

type GetSystemOutputNamesNewRequest = NamesRequest;
type GetSystemOutputNamesNewResponse = ErrorResponse | NamesResponse;

export const getSystemOutputNamesNew = (req: GetSystemOutputNamesNewRequest) =>
  http.post<GetSystemOutputNamesNewResponse, GetSystemOutputNamesNewRequest>('/v3/api/get-system-output-names-new', req);

export type CreateSystemRequest = {
  systemUid: string;
  deviceInfo: object | any | { signalLevel: any }; // Object?
  deviceStatus:
    | any
    | {
        zones: {
          queue_no: number | any;
          areas: number[];
          name: string;
          native: boolean | any;
          visible: boolean | any;
        }[];
        areas: {
          queue_no: number | any;
          name: string | any;
          status: AreaState;
        }[];
        pgms: {
          queue_no: number | any;
          name: string;
          control_area: boolean | any;
          area_no?: number | any;
          type: number | any;
          pulseTime: number | any;
        }[];
        sensors: object[] | any;
      };
  mpass?: string;
  systemTimeZone?: string;
  systemName: string;
  systemLocationAddress?: string;
  systemLocationCoords?: string;
  systemTheme?: {
    startColor?: string;
    endColor?: string;
    fullBackground: string | '';
  };
  direct: boolean | number | any; // TODO: Decide witch one it is.
  cgUsers?: {
    zone_number: number;
    areas: string;
    name: string;
    email: string;
  }[];
  maxUsers?: number;
  charset?: CharSets;
};
type CreateSystemResponse = ErrorResponse | { success: true; system: SystemData };

export const createSystem = (req: CreateSystemRequest) => http.post<CreateSystemResponse, CreateSystemRequest>('/v3/api/create-system', req);

type GetSystemsRequest = {
  returnSystemCount?: boolean;
  all?: boolean | 'true';
  offsetName?: string;
  offsetCount?: number;
};
type GetSystemsResponse<GetSystemsRequestType extends GetSystemsRequest> = {
  success: true;
  systems: SystemData[];
  totalSystems: GetSystemsRequestType['returnSystemCount'] extends true ? number : undefined;
  bookmarks?: number[];
};

export const getSystems = <TReq extends GetSystemsRequest = GetSystemsRequest>(req: TReq) =>
  http.get<GetSystemsResponse<TReq>, URLSearchParams>('/v3/api/systems', objectToParams(req));

type SaveSystemRequest = {
  systemId: number;
  name?: string;
  address?: string;
  timeZone?: string;
  backgroundStart?: string;
  backgroundEnd?: string;
  fullBackground?: string;
  mpass?: string;
};
type SaveSystemResponse = ErrorResponse | BasicSuccessResponse;

export const saveSystem = (req: SaveSystemRequest) => http.post<SaveSystemResponse, SaveSystemRequest>('/v3/api/system/save', req);

type TransferSystemRequest = { system_id: number } & ({ transfer_to_email: string } | { transfer_to_user: number });

type TransferSystemResponse = ErrorResponse | BasicSuccessResponse;

export const transferSystem = (req: TransferSystemRequest) => http.post<TransferSystemResponse, TransferSystemRequest>('/v3/api/transfer', req);

type DeleteSystemsRequest = { systems: number[] };
type DeleteSystemsResponse = ErrorResponse | (BasicSuccessResponse & WithWarning) | BasicSuccessResponse;

export const deleteSystems = (req: DeleteSystemsRequest) => http.post<DeleteSystemsResponse, DeleteSystemsRequest>('/v3/api/delete', req);

type GetDeviceUsersRequest = {
  systemUid: string;
  mpass: string;
  supportedCommands: string;
  srv?: number;
  hwId: string;
};
type GetDeviceUsersResponse =
  | ErrorResponse
  | {
      success: true;
      users: {
        name?: string;
        email?: string;
        canEdit: boolean;
        master: boolean;
        id: string;
        phone?: string;
        enabled?: boolean;
        eval?: number;
        schedule_no?: number;
        code?: string;
        areas?: string;
        pin?: string;
        present?: string;
      }[];
      max_packet_size: null | string;
      charset: CharSets;
      maxUsers: number;
    };
export const getDeviceUsers = (req: GetDeviceUsersRequest) => http.post<GetDeviceUsersResponse, GetDeviceUsersRequest>('/v3/api/get-system-users-new', req);

type SetSystemUserRequest = {
  user: {
    name?: string;
    phone?: string;
    email?: string;
    code?: string;
    pgms?: number;
    areas?: string; // Maybe
    zone_number?: number;
    enable_data?: number | boolean;
  };
  system_id: number;
};
type SetSystemUserResponseErrorWithUser = ErrorResponse & { user: number };
type SetSystemUserResponse = ErrorResponse | SetSystemUserResponseErrorWithUser | (BasicSuccessResponse & { protegus_user_id: number; id: number });

export const editSystemUser = (req: SetSystemUserRequest) => http.post<SetSystemUserResponse, SetSystemUserRequest>('/v3/api/edit/user', req);
export const addSystemUser = (req: SetSystemUserRequest) => http.post<SetSystemUserResponse, SetSystemUserRequest>('/v3/api/add/user', req);

type DeleteSystemUserRequest = { system_id: number; user_id: number };
type DeleteSystemUserResponse = ErrorResponse | SetSystemUserResponseErrorWithUser | BasicSuccessResponse;

export const deleteSystemUser = (req: DeleteSystemUserRequest) => http.delete<DeleteSystemUserResponse, DeleteSystemUserRequest>('/v3/api/user', req);

type SetSystemHomeConfigurationRequest = {
  systemId: number;
  elementOrder: {
    position: number;
    type: 'events' | 'areas' | 'sensors' | 'outputs' | 'cameras' | 'thermostats';
    visible: boolean;
  }[];
  // visibleAreas?: any[]
  // visibleOutputs?: any[]
  // visibleSensors?: any[]
  // visibleCameras?: any[]
  // visibleThermostats?: any[]
};
type SetSystemHomeConfigurationResponse = ErrorResponse | BasicSuccessResponse;

export const setSystemHomeConfiguration = (req: SetSystemHomeConfigurationRequest) =>
  http.post<SetSystemHomeConfigurationResponse, SetSystemHomeConfigurationRequest>('/v3/api/edit-home-configuration', req);

type SaveEventConfigurationRequest = {
  systemId: number;
  configuration: string; // JSOM string
};
type SaveEventConfigurationResponse = ErrorResponse | BasicSuccessResponse;

export const editEventConfiguration = (req: SaveEventConfigurationRequest) =>
  http.post<SaveEventConfigurationResponse, SaveEventConfigurationRequest>('/v3/api/edit-event-configuration', req);

type ForgetPinRequest = { area_id: number };
type ForgetPinResponse = ErrorResponse | BasicSuccessResponse;

export const forgetPin = (req: ForgetPinRequest) => http.post<ForgetPinResponse, ForgetPinRequest>('/v3/api/forget-pin', req);

type GetSystemRequest = {
  system_id: number;
};
type GetSystemResponse = ErrorResponse | (BasicSuccessResponse & { system: SystemData });

export const getSystem = (req: GetSystemRequest) => http.post<GetSystemResponse, GetSystemRequest>('/v3/api/get-system', req);

type CheckSystemRequest = {
  imei: string;
  mpass?: string;
  checkAll?: boolean;
};
type CheckSystemResponse =
  | ErrorResponse
  | (BasicSuccessResponse &
      (
        | { online: false; errorType: string }
        | {
            online: true;
            srv: number;
            own_region: boolean;
            local_version?: number;
            remote_version?: number;
          }
      ));

export const checkIfSystemIsOnline = (req: CheckSystemRequest) => http.get<CheckSystemResponse, URLSearchParams>('/v3/api/online', objectToParams(req));

type TransferDeviceRequest = {
  systemUid: string;
  mpass?: string;
  srv?: number;
  targetsrv?: number;
  customPort?: number;
  customHost?: string;
  toVersion?: number;
};
type TransferDeviceResponse = ErrorResponse | BasicSuccessResponse;

export const transferDevice = (req: TransferDeviceRequest) => http.post<TransferDeviceResponse, TransferDeviceRequest>('/v3/api/transfer-device', req);

type ResetFireSensorsRequest = { systemId: number };
type ResetFireSensorsResponse = ErrorResponse | BasicSuccessResponse;

export const resetFireSensors = (req: ResetFireSensorsRequest) => http.post<ResetFireSensorsResponse, ResetFireSensorsRequest>('/v3/api/reset-fire-sensors', req);

type GetForeignSystemRequest = { imei: string };

export const getForeignSystem = (req: GetForeignSystemRequest) => http.get<any, URLSearchParams>('/v3/api/foreign-system', objectToParams(req));

type GetSystemsLiteRequestJoins = 'pgms' | 'zones' | 'areas' | 'sensors' | 'cameras' | 'thermostats';

type GetSystemsLiteRequestSystemCollumns =
  | 'id'
  | 'imei'
  | 'name'
  | 'address'
  | 'mpass'
  | 'installer_id'
  | 'time_zone'
  | 'supported_commands'
  | 'supports_custom_outputs'
  | 'supports_fire_reset'
  | 'ns'
  | 'has_real_sensors'
  | 'hw_type'
  | 'device_id'
  | 'company_id';

type GetSystemsLiteRequestColumns = GetSystemsLiteRequestJoins | GetSystemsLiteRequestSystemCollumns;

export type DbSystemData = {
  id: number;
  imei: string;
  name: string;
  address: string;
  mpass: string;
  installer_id: number;
  time_zone: string;
  supported_commands: string;
  supports_custom_outputs: boolean;
  supports_fire_reset: boolean;
  ns: boolean;
  has_real_sensors: boolean;
  hw_type: string;
  device_id: string;
  company_id: number;
  areas: any[]; // TODO: Better type
  cameras: any[]; // TODO: Better type
  pgms: any[]; // TODO: Better type
  sensors: any[]; // TODO: Better type
  thermostats: any[]; // TODO: Better type
  zones: any[]; // TODO: Better type
};

type ArrayValue<T> = T extends (infer U)[] ? U : never;

type GetSystemsLiteRequest<TColumns extends GetSystemsLiteRequestColumns[] | undefined = undefined> = {
  offset?: number;
  limit?: number;
  columns: TColumns;
};

type SystemDataWithCollumns<TColumns extends GetSystemsLiteRequestColumns[] | undefined> = TColumns extends undefined ? DbSystemData : Pick<DbSystemData, ArrayValue<TColumns>>;

type GetSystemsLiteResponse<TColumns extends GetSystemsLiteRequestColumns[] | undefined> =
  | ErrorResponse
  | (BasicSuccessResponse & { systems: SystemDataWithCollumns<TColumns>[]; totalSystems: number });

export const getSystemsLite = <TColumns extends GetSystemsLiteRequestColumns[] | undefined>(req: GetSystemsLiteRequest<TColumns>) =>
  http.get<GetSystemsLiteResponse<TColumns>, URLSearchParams>('/v3/api/systems-lite', objectToParams(req));

type FilterSystemsRequest = {
  searchPhrase: string;
  searchFields: string[];
  paginationPage?: number;
};

type FilterSystemsResponse =
| ErrorResponse
| (BasicSuccessResponse & {
    list: {
      total: number;
      per_page: number;
      current_page: number;
      last_page: number;
      next_page_url: string | null;
      prev_page_url: string | null;
      from: number;
      to: number;
      data: SystemData[];
    };
  });

export const filterSystems = (req: FilterSystemsRequest) => http.post<FilterSystemsResponse, FilterSystemsRequest>('/v3/api/filter-systems', req);

type TransferToCompanyRequest = {
  system_id: number;
  company_alias: string;
};

const transferToCompany = (req: TransferToCompanyRequest) => http.post<BasicSuccessResponse, TransferToCompanyRequest>('/v3/api/transfer-to-company', req);

export default {
  transferToCompany,
};
