<app-base-popup #basePopup (popupCancelled)="selectionCancelled.emit()" (closed)="closed.emit()">
    <div class="white_round padding16">
        <div id="cpeu_inst_header">{{ls.get('users.labels.selectInstaller')}}</div>
        <div id="cpeu_inst_desc" *ngIf='us.currentUser.permissions?.role === PermissionRole.SuperAdmin' [innerHtml]="ls.get('users.labels.selectInstallerDesc')"></div>
        <div id="cpeu_inst_desc" *ngIf='us.currentUser.permissions?.role !== PermissionRole.SuperAdmin'>{{ls.get('users.labels.selectInstallerDescCompany')}}</div>
        <div>
            <div class="cpeu_installer_item" *ngFor="let iInstaller of installerList; let i = index" (click)="selection = iInstaller.id">
                <app-radio-button class="cpeu_insta_radio" [groupName]="'selectInstaller'" [checkValue]="selection === iInstaller.id"></app-radio-button>
                <div class="cpeu_inst_name">{{iInstaller.name === '' ? ls.get('users.labels.noName') : iInstaller.name}}</div>
                <div class="cpeu_inst_email">{{iInstaller.email}}&nbsp;</div>
            </div>
        </div>
        <div class="button" (click)="selectionConfirmed.emit(selection); close()">{{ls.get('users.buttons.confirm')}}</div>
    </div>
</app-base-popup>